import { BsFilePdf } from "react-icons/bs";
import { LuListTree } from "react-icons/lu";
import { BiNews, BiChip } from "react-icons/bi";
import { VscOpenPreview } from "react-icons/vsc";
import { GoDotFill } from "react-icons/go";
import { HiShoppingBag } from "react-icons/hi";
import { getDate } from "../utility/hepler";
import UserImage1 from "../Assets/Images/User/User.png"
import UserImage2 from "../Assets/Images/User/User2.png"
import UserImage3 from "../Assets/Images/User/User3.png"
import { dashboardIcon, sideBarIcons } from "../Assets/Icons";

const AzgoMenuItems = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 22, name: "azgo Newsletter", icon: <div><BiNews size={18} /></div>, children: [], to: "/travel-newsletter" }
]

const CMIItem = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 19, name: "CMI Newsletter", icon: <div><BiNews size={18} /></div>, children: [], to: "/cmi-newsletters" },
]
// FOR OONA ADMIN
const OonaMenuItems = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 1, name: "History", icon: sideBarIcons.history, children: [], to: "/history" },
  {
    name: "1-Click Blog", icon: sideBarIcons.cardHeading,
    children: [
      { id: 2, name: "Oona 1-Click", to: "/oona-1-click/short-blog" },
      // { id: 2, name: "Oona Long Blog", to: "/oona-1-click/long-blog" },
      { id: 2, name: "Addlly 1-Click", to: "/1-click-blog" },
    ],
  },
  {
    name: "SEO Blog Co-Pilot", icon: sideBarIcons.share,
    children: [
      { id: 3, name: "Oona Co-Pilot", to: "/oona-blog-co-pilot" },
      { id: 3, name: "Addlly Co-Pilot", to: "/blog-co-pilot" },
    ],
  },
  { id: 14, name: "PDF-to-Article", icon: <div><BsFilePdf size={18} /></div>, children: [], to: "/pdf-to-article" },
  { id: 15, name: "Review Articles", icon: <div><VscOpenPreview size={18} /></div>, children: [], to: "/review-history" },
  { id: 20, name: "AI Overview", icon: <div><BiChip size={18} /></div>, children: [], to: "/ai-overview" },
];

const ThePurestMenuItems = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 1, name: "History", icon: sideBarIcons.history, children: [], to: "/history" },
  {
    name: "Blog", icon: sideBarIcons.cardHeading, isNew: true,
    children: [
      { id: 2, name: "1-Click Blog Writer", to: "/1-click-blog" },
      { id: 3, name: "SEO Blog Co-Pilot", to: "/blog-co-pilot" },
    ],
  },
  {
    id: 4, name: "Social Media Posts", icon: sideBarIcons.share, to: "/social-media-post-writer",
    children: [
      { name: "LinkedIn", to: "/social-media-post-writer", value: "LinkedIn", defaultCard: "topic" },
      { name: "Facebook", to: "/social-media-post-writer", value: "Facebook", defaultCard: "topic" },
      { name: "X (Twitter)", to: "/social-media-post-writer", value: "Twitter", defaultCard: "topic" },
      { name: "Instagram", to: "/social-media-post-writer", value: "Instagram", defaultCard: "topic" },
    ],
  },
  { id: 5, name: "E-Commerce Blog", to: "/e-commerce-blog", icon: <div><HiShoppingBag size={18} /></div>, children: [], },
];

const BCAMenuItems = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 1, name: "History", icon: sideBarIcons.history, children: [], to: "/history" },
  {
    name: "Blog", icon: sideBarIcons.cardHeading, isNew: true,
    children: [
      { id: 2, name: "1-Click Blog Writer", to: "/1-click-blog" },
      { id: 3, name: "SEO Blog Co-Pilot", to: "/blog-co-pilot" },
    ],
  },
  {
    id: 4, name: "Social Media Posts", icon: sideBarIcons.share, to: "/social-media-post-writer",
    children: [
      { name: "LinkedIn", to: "/social-media-post-writer", value: "LinkedIn", defaultCard: "topic" },
      { name: "Facebook", to: "/social-media-post-writer", value: "Facebook", defaultCard: "topic" },
      { name: "X (Twitter)", to: "/social-media-post-writer", value: "Twitter", defaultCard: "topic" },
      { name: "Instagram", to: "/social-media-post-writer", value: "Instagram", defaultCard: "topic" },
    ],
  },
  { id: 11, name: "Newsletter Builder", icon: sideBarIcons.newspaper, isNew: true, children: [], to: "/newsletters" },
  { id: 12, name: "Press Release Quick Draft", icon: sideBarIcons.megaPhone, children: [], to: "/press-release" },
  { id: 20, name: "AI Overview", icon: <div><BiChip size={18} /></div>, children: [], to: "/ai-overview" },
  { id: 13, name: "Topic Suggestions", icon: <div><LuListTree size={18} /></div>, children: [], to: "/topic-suggestions" },
];

// Dashboard
const MenuItems = [
  { name: "Home", icon: sideBarIcons.houseDoor, children: [], to: "/dashboard" },
  { id: 1, name: "History", icon: sideBarIcons.history, children: [], to: "/history" },
  {
    name: "Blog", icon: sideBarIcons.cardHeading, isNew: true,
    children: [
      { id: 2, name: "1-Click Blog Writer", to: "/1-click-blog" },
      { id: 3, name: "SEO Blog Co-Pilot", to: "/blog-co-pilot" },
    ],
  },
  {
    id: 4, name: "Social Media Posts", icon: sideBarIcons.share, to: "/social-media-post-writer",
    children: [
      { name: 'LinkedIn', to: '/social-media-post-writer', value: 'LinkedIn', defaultCard: 'topic' },
      { name: 'Facebook', to: '/social-media-post-writer', value: 'Facebook', defaultCard: 'topic' },
      { name: 'X (Twitter)', to: '/social-media-post-writer', value: 'Twitter', defaultCard: 'topic' },
      { name: 'Instagram', to: '/social-media-post-writer', value: 'Instagram', defaultCard: 'topic' },
    ],
  },
  { id: 10, name: "Image Gallery", icon: sideBarIcons.images, children: [], to: "/product-image-library" },
  { id: 11, name: "Newsletter Builder", icon: sideBarIcons.newspaper, isNew: true, children: [], to: "/newsletters" },
  { id: 12, name: "Press Release Quick Draft", icon: sideBarIcons.megaPhone, children: [], to: "/press-release" },
  { id: 15, name: "E-Commerce Blog", icon: <div><HiShoppingBag size={18} /></div>, children: [], to: "/e-commerce-blog" },
  { id: 13, name: "Topic Suggestions", icon: <div><LuListTree size={18} /></div>, children: [], to: "/topic-suggestions" },
  { id: 20, name: "AI Overview", icon: <div><BiChip size={18} /></div>, children: [], to: "/ai-overview" },
  // { id: 14, name: "PDF-to-Article", icon: <div><BsFilePdf size={18} /></div>, children: [], to: "/pdf-to-article" },
];

const cardsDetails = [
  {
    icon: dashboardIcon.messageIcon,
    title: "SEO Blog Co-Pilot",
    color: 'linear-gradient(180deg, rgba(55, 1, 201, 0.10) 0%, rgba(55, 1, 201, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(55, 1, 201, 0.20)"
  },
  {
    icon: dashboardIcon.click,
    title: "1-Click Blogs",
    color: 'linear-gradient(180deg, rgba(55, 1, 201, 0.10) 0%, rgba(55, 1, 201, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(55, 1, 201, 0.20)"
  },
  {
    icon: dashboardIcon.pdf,
    title: "Generate a blog using PDF",
    color: 'linear-gradient(180deg, rgba(55, 1, 201, 0.10) 0%, rgba(55, 1, 201, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(55, 1, 201, 0.20)"
  },
  {
    icon: dashboardIcon.hasTag,
    title: "Social Media Posts",
    color: 'linear-gradient(180deg, rgba(55, 1, 201, 0.10) 0%, rgba(55, 1, 201, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(55, 1, 201, 0.20)"
  },
  {
    icon: dashboardIcon.instagram,
    title: "Instagram Post Writer",
    color: 'linear-gradient(180deg, rgba(137, 1, 119, 0.10) 0%, rgba(137, 1, 119, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(100, 1, 156, 0.10)"
  },
  {
    icon: dashboardIcon.facebook,
    title: "Facebook Post Writer",
    color: 'linear-gradient(180deg, rgba(137, 1, 119, 0.10) 0%, rgba(137, 1, 119, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(100, 1, 156, 0.10)"
  },
  {
    icon: dashboardIcon.linkedin,
    title: "Linkedin Post Writer",
    color: 'linear-gradient(180deg, rgba(137, 1, 119, 0.10) 0%, rgba(137, 1, 119, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(100, 1, 156, 0.10)"
  },
  {
    icon: dashboardIcon.messageIcon,
    title: "Twitter (X) Post Writer",
    color: 'linear-gradient(180deg, rgba(137, 1, 119, 0.10) 0%, rgba(137, 1, 119, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(100, 1, 156, 0.10)"
  },
  {
    icon: dashboardIcon.newsletter,
    title: "Newsletter Builder",
    color: 'linear-gradient(180deg, rgba(195, 1, 61, 0.10) 0%, rgba(195, 1, 61, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(137, 1, 119, 0.10)"
  },
  {
    icon: dashboardIcon.loudSpeaker,
    title: "Press Release Quick Draft",
    color: 'linear-gradient(180deg, rgba(195, 1, 61, 0.10) 0%, rgba(195, 1, 61, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(137, 1, 119, 0.10)"
  },
  {
    icon: dashboardIcon.images,
    title: "Image Gallery",
    color: 'linear-gradient(180deg, rgba(195, 1, 61, 0.10) 0%, rgba(195, 1, 61, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(137, 1, 119, 0.10)"
  },
  {
    icon: dashboardIcon.newsletter,
    title: "Travel Newsletter",
    color: 'linear-gradient(180deg, rgba(195, 1, 61, 0.10) 0%, rgba(195, 1, 61, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(137, 1, 119, 0.10)"
  },
  {
    icon: dashboardIcon.newsletter,
    title: "CMI Newsletter",
    color: 'linear-gradient(180deg, rgba(195, 1, 61, 0.10) 0%, rgba(195, 1, 61, 0.00) 100%), #FFF',
    borderColor: '1px solid #E4E4E7',
    imageBackGround: "rgba(137, 1, 119, 0.10)"
  },
];

const OonaNavigationOption = [
  {
    image: dashboardIcon.cardHeadings,
    name: "1-Click Blog",
    to: "/1-click-blog"
  },
  {
    image: dashboardIcon.pencilSquare,
    name: "SEO Blog Co-Pilot",
    to: "/blog-co-pilot"
  },
  {
    image: dashboardIcon.newspaperIcon,
    name: "PDF-to-article",
    to: "/pdf-to-article"
  },
];

const navigationsOption = [
  {
    image: dashboardIcon.cardHeadings,
    name: "1-Click Blog",
    to: "/1-click-blog"
  },
  {
    image: dashboardIcon.pencilSquare,
    name: "SEO Blog Co-Pilot",
    to: "/blog-co-pilot"
  },
  {
    image: dashboardIcon.shareIcon,
    name: "Social Media Post",
    to: "/social-media-post-writer"
  },
  {
    image: dashboardIcon.newspaperIcon,
    name: "Newsletter Builder",
    to: "/newsletters"
  },
  {
    image: dashboardIcon.megaPhoneIcon,
    name: "Press Release Quick Draft",
    to: "/press-release"
  },
];

const ThePurestNavigationOption = [
  {
    image: dashboardIcon.cardHeadings,
    name: "1-Click Blog",
    to: "/1-click-blog",
  },
  {
    image: dashboardIcon.pencilSquare,
    name: "SEO Blog Co-Pilot",
    to: "/blog-co-pilot",
  },
  {
    image: dashboardIcon.shareIcon,
    name: "Social Media Post",
    to: "/social-media-post-writer",
  }
];

// HistoryTableFilters
const OonaCategories = [
  {
    name: (
      <span>All</span>
    ),
    value: "all",
  },
  {
    name: "1-Click Blog",
    value: "short_flow",
  },
  {
    name: "SEO Blog Co-Pilot",
    value: "long_flow",
  },
  {
    name: "PDF-to-article",
    value: "pdf_flow",
  }
];

const ThePurestCategories = [
  {
    name: <span>All</span>,
    value: "all",
  },
  {
    name: "1-Click Blog",
    value: "short_flow",
  },
  {
    name: "SEO Blog Co-Pilot",
    value: "long_flow",
  },
  {
    name: "Social Media Post",
    value: "social_media",
  }
];

const categories = [
  {
    name: (
      <span>All</span>
    ),
    value: "all",
  },
  {
    name: "1-Click Blog",
    value: "short_flow",
  },
  {
    name: "SEO Blog Co-Pilot",
    value: "long_flow",
  },
  {
    name: "Social Media Post",
    value: 'social_media',
  },
  {
    name: "Newsletter Builder",
    value: "newsletter",
  },
  {
    name: "Press Release Quick Draft",
    value: 'press-release',
  },
];

const Duration = [
  {
    name: (
      <span>All</span>
    ),
    value: "all",
  },
  {
    name: "Today",
    value: getDate(0),
  },
  {
    name: "Yesterday",
    value: getDate(1),
  },
  {
    name: "7 Day Ago",
    value: getDate(7),
  },
  {
    name: "30 Day Ago",
    value: getDate(30),
  },
  {
    name: "90 Day Ago",
    value: getDate(90),
  },
  {
    name: "Custom",
    value: "Custom",
  },
];

const status = [
  {
    name: (
      <span>All</span>
    ),
    value: "all",
  },
  {
    name: (
      <span>
        <GoDotFill className="text-success" fill="#84CC16" style={{ height: '16px', width: "16px" }} />Done
      </span>
    ),

    value: "completed",
  },
  {
    name: (
      <span>
        <GoDotFill className="text-warning" />In progress
      </span>
    ),
    value: "pending",
  },
  {
    name: (
      <span>
        <GoDotFill className="text-danger" />Error
      </span>
    ),
    value: "error",
  },
];

const AIModel = [
  {
    name: (
      <span>All</span>
    ),
    value: "all",
  },
  {
    name: "GPT 4",
    value: "GPT 4",
  },
  {
    name: "Claude",
    value: "Claude",
  },
  {
    name: "GPT 3.5",
    value: "GPT 3.5",
  },
  {
    name: "GPT 4 Omni",
    value: "GPT 4 Omni",
  }
];

const users = [
  {
    name: "Shubham Gujarathi",
    email: "shubham@websitevikreta.com",
  },
  {
    name: "Harshit",
    email: "harshit@addlly.com",
  },
  {
    name: "Tina",
    email: "tina@addlly.com",
  },
  {
    name: "Maulik Kanani",
    email: "maulik@addlly.com",
  },
];

const dateFilters = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Today",
    value: getDate(),
  },
  {
    name: "Yesterday",
    value: getDate(1),
  },
  {
    name: "7 Day Ago",
    value: getDate(7),
  },
  {
    name: "30 Day Ago",
    value: getDate(30),
  },
  {
    name: "90 Day Ago",
    value: getDate(90),
  },
];

const AiTypeOptions = [
  { label: "GPT 3.5", value: "GPT 3.5" },
  { label: "GPT 4", value: "GPT 4" },
  { label: "GPT 4 Omni", value: "GPT 4 Omni" },
  { label: "Claude", value: "Claude" },
  { label: "Claude 3 Opus", value: "Claude 3 Opus" },
  { label: "Claude 3 Sonnet", value: "Claude 3 Sonnet" },
  { label: "Claude 3.5 Sonnet", value: "Claude 3.5 Sonnet" },
  { label: "Llama 3", value: "Llama 3" },
  { label: "Mistral 8", value: "Mistral 8" },
  // { label: "OpenAi Updated", value: "OpenAi Updated" },
  // { label: "Sustainability", value: "ft:gpt-3.5-turbo-0613:script-consultants::81XIDnDI" },
];

const OonaAiTypeOptions = [
  { label: "GPT 4", value: "GPT 4" },
  { label: "GPT 4 Omni", value: "GPT 4 Omni" },
];

const versionList = [
  {
    id: 1,
    name: 'Version Number (ASC)',
    direction: 'asc'
  },
  {
    id: 2,
    name: 'Version Number (DESC)',
    direction: 'desc'
  },
];

const filterBy = [
  {
    id: 1,
    name: 'All',
    value: 'all'
  },
  {
    id: 2,
    name: 'Re-Generated',
    value: 'reGenerated'
  },
];

const signUpText = [
  { userName: "Madeleine Beck", userImage: UserImage1, userDesc: "Partner & Chief of Staff At Credilinq", text: "I can confidently say that the Addlly.Ai has been an absolute game-changer for me! It has saved me countless hours of researching topics and gathering information from various sources. With this tool, I can easily consolidate all my ideas and thoughts into one well-written and relevant SEO-optimised article that is up-to-date with the latest insights. It's truly a lifesaver, especially for someone like me who is constantly pressed for time to generate weekly content. I can now focus on other aspects of my business knowing that I have a reliable partner to help me with my content needs. Thank you for this incredible tool!" },
  { userName: "Abhijeet Kulkarni", userImage: UserImage3, userDesc: "CEO & Founder of Appistoki group", text: "Boutique technology firms like ours have a limited bandwidth to create content, but Addlly Al Smart Writer fully satisfies our requirements. It's the perfect platform to craft a personalised, search engine optimised outreach campaign. We can reach a much wider audience for the perfect price point." },
  { userName: "Bogdan M", userImage: UserImage2, userDesc: "Founder & COO of Sendhelper", text: "Addlly Al has been a good resource for my marketing team at Send helper. The writing tool is very easy to use with enormous cost saving potential. We use it to produce content for growing our marketing outreach without compromising quality." }
];

export {
  CMIItem, AzgoMenuItems, OonaMenuItems, ThePurestMenuItems, BCAMenuItems, MenuItems, cardsDetails, OonaNavigationOption,
  navigationsOption, ThePurestNavigationOption, OonaCategories, ThePurestCategories, categories, Duration,
  status, AIModel, users, dateFilters, AiTypeOptions, OonaAiTypeOptions, versionList, filterBy, signUpText,
}
