import React from "react";
import { useParams } from "react-router-dom";
import FirstStep from "../Components/1ClickBlogWriter/first-step/FirstStep";
import SecondStep from "../Components/1ClickBlogWriter/second-step/SecondStep";
import LoadingScreen from "../../Components/SeoBlogWriterNew/first-step/LoadingScreen";
import OneBlogWriterHoc from "../Hoc/OneBlogWriterHoc";

const OneClickBlogWriter = (props) => {
  const { type } = useParams();
  const {
    loading, historyData, textWrap, setTextWrap, starred, setStarred, setSearch, filters, setFilters, selectedRows, setSelectedRows,
    handleArchiveAll, setPage, limit, page, isSeoScoreDisplay, totalCount, topResultsStep, handleSubmit, onSubmit, register, watch, setValue, errors,
    onContinue, control, handlePinArticle, handleDelete, show, handleClose, active, setTab, tab, setToggle, toggle, htmlText,
    handleGetAllImagesAndOpenModal, edit, finalHtmlText, setHtmlText, editorRef, editorKey, handleCopyHtmlArticleText, search,
    contentScore, generatedFullArticle, setEdit, postImagesModal, setPostImagesModal, handleAutoFactChecker, handleSaveArticleOrPostText,
    getFullData, selectedSubTab, setSelectedSubTab, postGeneratedImages, setPostGeneratedImages, handleGetArticleByIdAndEdit,
    handleShareSocialMediaPost, setSelectedCurrentTab, articleType, handleSort, sortField, userRes, handleSetSelectedHashtagsOnPosts, postImagesCarousel,
    setPostImagesCarousel, handleSetRestoredOldVersionText, getHistoryShortFlowData, isFetching, clearRow, savedModal, setSavedModal, reset
  } = props;

  const steps = [
    <FirstStep
      loading={loading} historyData={historyData} setStarred={setStarred} starred={starred} setSearchFilter={setSearch} filters={filters}
      setFilters={setFilters} setSelectedRows={setSelectedRows} selectedRows={selectedRows} handleArchiveAll={handleArchiveAll}
      getArticleAndPostHistoryData={() => { }} totalCount={totalCount} setPage={setPage} page={page} limit={limit} handleSort={handleSort}
      textWrap={textWrap} setTextWrap={setTextWrap} handleSubmit={handleSubmit} register={register} watch={watch} setValue={setValue} errors={errors}
      onSubmit={onSubmit} onContinue={onContinue} control={control} handlePinArticle={handlePinArticle} show={show} handleClose={handleClose}
      handleDelete={handleDelete} active={active} setTab={setTab} handleGetArticleByIdAndEdit={handleGetArticleByIdAndEdit} tab={tab} sortField={sortField}
      userRes={userRes} getHistoryShortFlowData={getHistoryShortFlowData} isFetching={isFetching} clearRow={clearRow} search={search} topResultsStep={topResultsStep}
      reset={reset}
    />,
    <LoadingScreen
      show={active} type="short" progress={tab.progress} tab={tab.tab}
      setTab={(e) => setTab((prev) => ({ ...prev, tab: e }))}
    />,
    <SecondStep
      setToggle={setToggle} toggle={toggle} htmlText={htmlText} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal} edit={edit} setEdit={setEdit}
      finalHtmlText={finalHtmlText} setHtmlText={setHtmlText} editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
      contentScore={contentScore} generatedFullArticle={generatedFullArticle} isSeoScoreDisplay={isSeoScoreDisplay} postImagesModal={postImagesModal}
      setPostImagesModal={setPostImagesModal} handleAutoFactChecker={handleAutoFactChecker} handleSaveArticleOrPostText={handleSaveArticleOrPostText}
      getFullData={getFullData} selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab}
      postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages} articleType={articleType} userRes={userRes}
      handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts} handleShareSocialMediaPost={handleShareSocialMediaPost}
      setSelectedCurrentTab={setSelectedCurrentTab} postImagesCarousel={postImagesCarousel} setPostImagesCarousel={setPostImagesCarousel}
      handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} savedModal={savedModal} setSavedModal={setSavedModal}
    />,
  ];

  return (
    <React.Fragment>
      {type && (type === "short-blog" || type === "long-blog") ? (
        <React.Fragment>
          {steps[topResultsStep - 1]}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default OneBlogWriterHoc(OneClickBlogWriter);
