import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import DropdownMenu from "../ui/Menu";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TableLoader from "../ui/TableLoader";
import CustomDataTable from "../ui/DataTable";
import { AxiosApi } from "../../utility/axios";
import { articleHistoryIcons, dataTableIcons } from "../../Assets/Icons";
import { SortArrow, sorting, tableDateFormatter } from "../../utility/common";

const AzgoNewsletterHistory = ({ handleGetArticleById }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [progressPending, setProgressPending] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    getAzgoNewsletterHistory();
  }, []);

  const getAzgoNewsletterHistory = async () => {
    setProgressPending(true); setData([]);
    const url = `/api/article/history-or-details/byType/get-azgo-newsletter-history?userId=${userRes.id}`;
    AxiosApi.get(url).then((response) => {
      if (response.data && response.data.length > 0) {
        setData(response.data);
      }
      setProgressPending(false);
    }).catch((error) => {
      setProgressPending(false);
    });
  };

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(data, column?.sortField, sortDirection);
    setData(newData);
  };

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2">
            <div className={classNames("tableValues", { showFullDes: false })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => tableDateFormatter(row?.created_at),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "status",
        name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => (
          <div className="d-flex justify-content-center align-items-center">
            <div className="dotStatus">
              <span
                className={`d-block`}
                style={{
                  width: "8px", height: "8px", borderRadius: "50%",
                  backgroundColor: row.status === "Done" ? "#84CC16" : row.status === "pending" ? "#F59E0B" : "#EF4444",
                }}
              />
            </div>
            {row.status = "Done"}
          </div>
        ),
        sortable: true,
        sortField: "status",
      },
      {
        id: "sort",
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                <MenuItem onClick={() => handleGetArticleById(row.id, row.type)}>
                  <ReactSVG src={dataTableIcons.pencil} /> Edit
                </MenuItem>
              </DropdownMenu>
            </div>
          );
        },
      },
    ];
    return tempColumns;
});
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between gap-2 mb-3">
        <span className="mb-2">Recent History</span>
      </div>
      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={progressPending} progressComponent={<TableLoader limit={10} />}
          columns={columns} totalCount={data.length || 0} pagination={true} data={data}
          itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
          onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={() => { }} clearSelectedRows={() => { }}
        />
      </div>
    </React.Fragment>
  )
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};

export default AzgoNewsletterHistory; 
