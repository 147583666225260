import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AxiosApi } from "../../utility/axios";
import { sorting } from "../../utility/common";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { handleGetOrGenerateAllTypeImages, removeSpecificSpecialCharacters } from "../../utility/hepler";
import { setGeneratedFullArticle, setLoader, setTopResultsStep, setUserRes } from "../../store/reducer";
import { articleHistoryShortFlowData, deleteOrArchiveArticle, getHistory, pinArticles } from "../../utility/apiService";
const beautify_html = require("js-beautify").html;

const validationSchema = Yup.object().shape({
  topic: Yup.string()
    .required("Topic is required").min(2, "Topic must be at least 2 characters")
    .test("no-white-space", "Topic should not contain white spaces", (value) => !/\s{2,}/.test(value))
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  keywords: Yup.string()
    .required("Keyword is required").min(2, "Keyword must be at least 2 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Keyword should not contain multiple consecutive white spaces")
    .test("no-white-space", "Keyword should not contain white spaces", (value) => !/\s{2,}/.test(value)),
});

const OneBlogWriterHoc = (WrappedComponents) => {
  const OneBlogWriter = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { type, id } = useParams();
    const editorRef = useRef(null);
    const userRes = useSelector(({ userRes }) => userRes);
    const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);
    const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);
    const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
    const historyFiltersType = useMemo(() => ({ categories: [], durations: [], status: [], AIModels: [], users: [], isCustomDate: false, customDate: { startDate: '', endDate: '' } }), []);

    const [historyData, setHistoryData] = useState({ data: [], totalCount: 0 });
    const [filters, setFilters] = useState(historyFiltersType);
    const [textWrap, setTextWrap] = useState(false);
    const [starred, setStarred] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortField, setSortField] = useState({ column: {}, direction: '' });
    const [page, setPage] = useState(1);
    const [limit] = useState(10);

    const [tab, setTab] = useState({ tab: 1, progress: 0 });
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [contentScore, setContentScore] = useState({});
    const [htmlText, setHtmlText] = useState("");
    const [finalHtmlText, setFinalHtmlText] = useState("");
    const [toggle, setToggle] = useState(false);
    const [editorKey, setEditorKey] = useState(0);
    const [edit, setEdit] = useState({});
    const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
    const [postImagesCarousel, setPostImagesCarousel] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
    const [clearRow, setClearRow] = useState(false);
    const [postGeneratedImages, setPostGeneratedImages] = useState({});
    const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get("tab") ? searchParams.get("tab") : "article");
    const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get("subTab") ? searchParams.get("subTab") : "seoScore");
    const [isSeoScoreDisplay, setIsSeoScoreDisplay] = useState(true);
    const articleType = "1-Click Blog";
    const [savedModal, setSavedModal] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, getValues, setValue, reset, control } = useForm({
      defaultValues: {
        topic: "", keywords: "", aiType: "GPT 4", lan: "Bahasa Indonesia",
        geoLocation: "ID", generateChart: false,
      },
      resolver: yupResolver(validationSchema),
    });

    const searchParam = {};

    if (starred) searchParam.starred = 'starred';
    if (filters.status?.length) searchParam.status = filters.status.join(",");
    if (filters.AIModels?.length) searchParam.aiModel = filters.AIModels.join(",");
    if (filters.durations?.length) searchParam.date = filters.durations.join(",");

    if (pathname.includes("/1-click-blog")) searchParam.type = "short_flow";
    else if (pathname.includes("/oona-1-click")) searchParam.type = type === "long-blog" ? "oona_long" : "oona_short";
    else if (pathname.includes("/oona-blog-co-pilot")) searchParam.type = "oona_blog";
    else if (pathname.includes("/blog-co-pilot")) searchParam.type = "long_flow";

    if (filters.isCustomDate) {
      searchParam.isCustomDate = true;
      searchParam.startDate = filters.customDate.startDate;
      searchParam.endDate = filters.customDate.endDate;
    }

    const { refetch, isFetching } = useQuery({
      queryKey: [searchParam.type || "all", socialMediaPostInfo?.PostType || ""],
      queryFn: async (params) => {
        if (
          params && params.queryKey && params.queryKey[0] && params.queryKey[0] === "social_media" &&
          params.queryKey[1] && params.queryKey[1] !== ""
        ) {
          searchParam.postType = params.queryKey[1];
        } else {
          searchParam.postType = "";
        }

        const query = new URLSearchParams({ is_archived: 0, ...searchParam }).toString();
        const response = await articleHistoryShortFlowData({ userId: userRes?.id, query });
        setHistoryData(response);
        return response;
      },
    });

    const { mutate } = useMutation({
      mutationFn: (values) => {
        pinArticles({ data: values }).then(() => {
          refetch();
        });
      },
    });

    const handleSort = (column, sortDirection) => {
      setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
      setPage(1);
      const newData = sorting(historyData?.data, column?.sortField, sortDirection);
      setHistoryData(pre => ({ ...pre, data: newData }));
    };

    const handleSelectedRows = (e) => {
      setSelectedRows(e?.selectedRows?.map(({ type, id }) => ({ id, type })));
    };

    const handlePinArticle = (type, articleId, value) => {
      const data = { type, articleId, value: !value };
      mutate(data);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
      setTimeout(() => {
        setEditorKey(editorKey + 1);
      }, 500);
    }, [selectedCurrentTab])

    useEffect(() => {
      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      setFilters(historyFiltersType);

      if (pathname.includes('/1-click-blog') || pathname.includes('/oona-1-click/')) {
        setPage(1); refetch();
      }

      reset({
        ...getValues(),
        aiType: (getValues("aiType") || "GPT 4"),
        lan: (getValues("lan") || "Bahasa Indonesia"),
        geoLocation: (getValues("geoLocation") || "ID"),
      })
    }, [pathname, historyFiltersType]);

    useEffect(() => {
      setPage(1);
      refetch();
    }, [filters, starred, refetch]);

    useEffect(() => {
      if (pathname.includes('/1-click-blog') || pathname.includes('/oona-1-click/short-blog') || pathname.includes('/oona-1-click/long-blog')) {
        if (userRes?.id && !id) {
          dispatch(setTopResultsStep(1));
          dispatch(setGeneratedFullArticle({}));
          setPostImagesModal({ open: false, type: "", currentTab: "AI Generated Image" });
          setPostGeneratedImages({});
        } else if (userRes?.id && id) {
          if (generatedFullArticle && generatedFullArticle.articleId) {
            const item = { ...generatedFullArticle };
            const setTempObj = {
              ...item, articleId: item?.id,
              FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
            };

            setIsSeoScoreDisplay(true);
            setEdit({
              article_html: setTempObj?.article_html,
              article_detail_html: setTempObj?.article_detail_html,
              FAQHTML: setTempObj?.FAQHTML, FAQschema: setTempObj?.FAQschema,
            });
            setHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");
            setFinalHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");

            try {
              if (item?.SEO_score_response && item?.SEO_score_response !== "") {
                setContentScore(JSON.parse(item?.SEO_score_response).data);
              }
            } catch (error) {
            }
          } else {
            handleGetArticleByIdAndEdit(id);
          }
        }
      }

      if (localStorage.getItem("set_topic") && localStorage.getItem("set_topic") !== "") {
        setValue("topic", localStorage.getItem("set_topic"));
        localStorage.removeItem("set_topic");
      }

      if (localStorage.getItem("set_keyword") && localStorage.getItem("set_keyword") !== "") {
        setValue("keywords", localStorage.getItem("set_keyword"));
        localStorage.removeItem("set_keyword");
      }

      if (localStorage.getItem("set_geoLocation") && localStorage.getItem("set_geoLocation") !== "") {
        setValue("geoLocation", localStorage.getItem("set_geoLocation"))
        localStorage.removeItem("set_geoLocation");
      }
    }, [id]);

    const filteredData = useMemo(() => (
      historyData?.data?.filter(item =>
        Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('')?.toLowerCase().includes(search?.split(' ').join('')?.toLowerCase()))
      )
    ), [historyData, search])

    const onContinue = async (formData) => {
      try {
        if (Number(userRes.credits) < (userRes?.deductCredits?.["1-Click Blog"] || 2)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return;
        }

        setTab({ tab: 1, progress: 0 });
        setActive(true);
        dispatch(setTopResultsStep(2));

        const topicStr = await removeSpecificSpecialCharacters(formData?.topic);
        const keywordStr = await removeSpecificSpecialCharacters(formData?.keywords);

        const payload = {
          body: JSON.stringify({
            topic: topicStr, keyword: keywordStr, user_id: userRes.id,
            aiType: formData?.aiType ? formData.aiType : "GPT 4",
            geoLocation: formData?.geoLocation ? formData.geoLocation?.toLowerCase() : "ID",
            selectedProduct: formData?.selectedProduct ? formData.selectedProduct : "",
            generateType: type === "long-blog" ? "oona_long" : "oona_short",
            lan: formData?.lan ? formData.lan : "Bahasa Indonesia",
            type: "generate-1-click-article"
          })
        };

        const response = await invokeLambdaFunction("generateOonaArticleOrSaveByType", payload);
        if (response && response.success === 1) {
          reset({ topic: "" });
          setTab((prev) => ({ ...prev, progress: 100 }));
          setTimeout(() => setActive(false), 2000);

          if (formData?.generateChart) {
            await handleSetEditDefaultData({
              ...response.data, articleId: response.data?.id, shortArticleType: "false", uploadChart: "true",
              article_html_chart: response.data?.article_html_chart ? JSON.parse(response.data?.article_html_chart)?.data : null,
            });
          } else {
            await handleSetEditDefaultData({ ...response.data, articleId: response.data?.id, shortArticleType: "false" });
          }

          dispatch(setTopResultsStep(3));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["1-Click Blog"] || 2) }));
          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }

          if (response.data?.type && response.data.type === "oona_long") {
            navigate(`/oona-1-click/long-blog/${response.data?.id}`);
          } else if (response.data?.type && response.data.type === "oona_short") {
            navigate(`/oona-1-click/short-blog/${response.data?.id}`);
          } else {
            navigate(`/1-click-blog/${response.data?.id}`);
          }
        } else {
          setActive(false); dispatch(setTopResultsStep(1));
          reset({ aiType: "GPT 4", lan: "Bahasa Indonesia", geoLocation: "ID" });
          let message = response.error;
          if (message.indexOf("Azure") !== -1 || message.indexOf("filtered") !== -1) {
            message = "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic.";
          }
          toast.error(message, { id: "Toast-01" });
          dispatch(getHistory(userRes?.id));
        }
      } catch (error) {
        setActive(false); dispatch(setTopResultsStep(1));
        reset({ aiType: "GPT 4", lan: "Bahasa Indonesia", geoLocation: "ID" });
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
      }
    };

    const handleGetArticleByIdAndEdit = async (id, tab) => {
      if (id) {
        dispatch(setLoader(true));
        dispatch(setGeneratedFullArticle({}));
        setSelectedSubTab("");

        AxiosApi.get(`/api/articleHistory/byId/${id}`).then(async (res) => {
          const item = res.data && res.data.data ? res.data.data : {};
          await handleSetEditDefaultData(item);
          dispatch(setTopResultsStep(3));
          dispatch(setLoader(false));
          setSelectedSubTab(selectedSubTab);

          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); };
          let tabVal = "";
          if (tab && tab === "refundRequests") {
            tabVal = "?tab=refundRequests";
          }

          if (item?.type && item.type === "oona_long") {
            navigate(`/oona-1-click/long-blog/${id}${tabVal}`);
          } else if (item?.type && item.type === "oona_short") {
            navigate(`/oona-1-click/short-blog/${id}${tabVal}`);
          } else {
            navigate(`/1-click-blog/${id}${tabVal}`);
          }
        }).catch((e) => {
          console.log(e, "eee");
          dispatch(setLoader(false));
          setSelectedSubTab(selectedSubTab);
        });
      }
    };

    const handleSetEditDefaultData = async (item) => {
      const setTempObj = {
        ...item, articleId: item?.id, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
        popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
        article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
      };

      dispatch(setGeneratedFullArticle({ ...setTempObj }));
      setIsSeoScoreDisplay(true);
      setEdit({
        article_html: setTempObj?.article_html,
        article_detail_html: setTempObj?.article_detail_html,
        FAQHTML: setTempObj?.FAQHTML, FAQschema: setTempObj?.FAQschema,
      });
      setHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");
      setFinalHtmlText(setTempObj?.article_detail_html || setTempObj?.article_html || "");

      if (setTempObj?.articleId && setTempObj?.outline_html !== "") {
        handleGenerateShortArticleSeoScore(setTempObj);
      }

      if (setTempObj?.articleId && setTempObj?.h2_images !== "") {
        handleGenerateAiImageForH2Tag(setTempObj);
      }

      if (setTempObj?.isShowReviewInfo && setTempObj?.isShowReviewInfo === true) {
        handleOpenReviewArticleTabInfoModal();
      }

      return item;
    }

    const handleDelete = (id, type, eType) => {
      Swal({
        title: "Are you sure?",
        text: `Do you want to ${eType} the selected item?`,
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, ${eType} it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = () => {
            setPage(1); refetch(); setSelectedRows([]); setClearRow(pre => !pre)
          };
          deleteOrArchiveArticle([{ id, type }], eType, callback);
        }
      });
    };

    const handleArchiveAll = () => {
      Swal({
        title: "Are you sure?",
        text: "Do you want to archive the selected item?",
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, archive ${selectedRows.length > 1 ? "all" : ""}!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = (resp) => {
            setPage(1); refetch(); setSelectedRows([]); setClearRow(pre => !pre);
          }
          deleteOrArchiveArticle(selectedRows, 'archive', callback);
        }
      });
    };

    const handleSetHtmlElementsOnArticle = () => {
      const Obj = {
        article_html: edit.article_html,
        article_detail_html: edit.article_detail_html,
        FAQHTML: edit.FAQHTML, FAQschema: edit.FAQschema,
      };

      if (generatedFullArticle && generatedFullArticle.article_html && generatedFullArticle.article_html.indexOf("<html>") !== -1) {
        const htmlStr = edit.article_html.indexOf("<body>") !== -1 ? edit.article_html.substring(edit.article_html.indexOf("<body>") + 6, edit.article_html.indexOf("</body>")) : edit.article_html;
        Obj.article_html = `${generatedFullArticle.article_html.substring(0, generatedFullArticle.article_html.indexOf("<body>"))}
            <body> ${htmlStr} </body>
          </html>`.replaceAll("\n", "").replaceAll("\t", "");
      }

      if (generatedFullArticle && generatedFullArticle.article_detail_html && generatedFullArticle.article_detail_html.indexOf("<html>") !== -1) {
        const htmlStr1 = edit.article_detail_html.indexOf("<body>") !== -1 ? edit.article_detail_html.substring(edit.article_detail_html.indexOf("<body>") + 6, edit.article_detail_html.indexOf("</body>")) : edit.article_detail_html;
        Obj.article_detail_html = `${generatedFullArticle.article_detail_html.substring(0, generatedFullArticle.article_detail_html.indexOf("<body>"))}
            <body> ${htmlStr1} </body>
          </html>`.replaceAll("\n", "").replaceAll("\t", "");
      }

      return Obj;
    };

    const handleSaveArticleOrPostText = async () => {
      handleShow(true);
      dispatch(setLoader(true));
      setSelectedSubTab("");

      const updateObj = await handleSetHtmlElementsOnArticle();
      await dispatch(setGeneratedFullArticle({ ...generatedFullArticle, ...updateObj }));

      await AxiosApi.post(`/api/articleHistory/update`, {
        id: generatedFullArticle?.articleId,
        currentField: selectedCurrentTab,
        ...updateObj
      }).then(async () => {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        setSavedModal(true);
      }).catch(function (error) {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
        console.error(error);
      });
    };

    const getFullData = async (name, reGenerated = 0, feedback = "") => {
      try {
        if (name === "shortArticle") {
          if (generatedFullArticle.article_regenerate_left <= 0) {
            toast.error("Sorry, you have used all three available generations");
            return;
          }
        } else if (name === "faq") {
          if (generatedFullArticle.faq_regenerate_left <= 0) {
            toast.error("Sorry, you have used all three available generations");
            return;
          }
        }

        dispatch(setLoader(true));
        const articleId = generatedFullArticle?.articleId ? generatedFullArticle.articleId : "";
        const payload = { pathParameters: { id: articleId } };

        if (reGenerated) {
          payload.pathParameters.feedback = feedback || "";
        }

        setSelectedSubTab("");
        if (name === "shortArticle") {
          await invokeLambdaFunction("generateOonaArticleOrSaveByType", {
            body: JSON.stringify({
              feedback: feedback ? feedback : "", articleId: articleId,
              type: "regenerate-1-click-article"
            }),
          });
        } else if (name === "readability") {
          await invokeLambdaFunction("generateOonaArticleOrSaveByType", {
            body: JSON.stringify({ articleId: articleId, type: "readability-1-click-article" }),
          });
        } else if (name === "faq") {
          await invokeLambdaFunction("generateFAQSchema", payload);
        }
        setSelectedSubTab(selectedSubTab);

        const response = await AxiosApi.get(`/api/articleHistory/byId/${articleId}`);
        const responseData = response && response.data && response.data.data ? response.data.data : {};
        responseData.articleId = responseData?.id ? responseData.id : "";

        const setTempObj = {
          ...generatedFullArticle, article_regenerate_left: responseData.article_regenerate_left,
          article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
          FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema, googleAdCopy: responseData?.googleAdCopy,
          popular_hashtags: responseData?.popular_hashtags ? JSON.parse(responseData.popular_hashtags) : [],
          faq_regenerate_left: responseData.faq_regenerate_left, readability_used: responseData?.readability_used,
          h2_images: responseData?.h2_images || "",
        };
        await dispatch(setGeneratedFullArticle({ ...setTempObj }));

        dispatch(setLoader(false));
        setEdit({
          ...edit,
          article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
          FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema, googleAdCopy: responseData?.googleAdCopy,
        });

        if (name === "shortArticle" && isSeoScoreDisplay) {
          setContentScore({});
          handleGenerateShortArticleSeoScore(responseData);
          handleGenerateAiImageForH2Tag(setTempObj);
        } else if (name === "readability" && isSeoScoreDisplay) {
          setContentScore({});
          handleGenerateShortArticleSeoScore(responseData);
        }
      } catch (error) {
        dispatch(setLoader(false));
        setSelectedSubTab(selectedSubTab);
        toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
        console.log(error);
      }
    };

    const handleGenerateShortArticleSeoScore = async (setTempObj) => {
      if (setTempObj?.articleId) {
        const payload = {
          pathParameters: { id: setTempObj?.articleId },
          queryStringParameters: { articleType: "short" },
        };
        const response = await invokeLambdaFunction("getSEOScore", payload);
        if (response && response.success === 1 && response.data && response.data.data) {
          setContentScore(response.data.data);
        }
      }
    };

    const handleGenerateAiImageForH2Tag = async (responseObj) => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      let generateImageArray = [];
      if (responseObj?.h2_images && responseObj?.h2_images !== "") {
        try {
          const tempImgArray = JSON.parse(responseObj?.h2_images);
          for (let index = 0; index < tempImgArray.length; index++) {
            const element = tempImgArray[index];
            if (!element.image || element.image === "") {
              generateImageArray.push(element);
            }
          }
        } catch (error) { }
      }

      if (generateImageArray.length === 0) {
        return false;
      }

      try {
        const payload = {
          pathParameters: { articleId: responseObj?.id ? responseObj.id : "" },
          body: JSON.stringify({ articleType, generateType: "image-generate" })
        };

        const response = await invokeLambdaFunction("generateAiImagesForFinalArticle", payload);
        if (response && response.success === 1 && response.data && response.data.article && response.data.article !== "") {
          setEdit((pre) => ({ ...pre, article_html: response.data.article }));
          generateImageArray = response.data.generateImageArray;
          dispatch(setGeneratedFullArticle({
            ...responseObj, h2_images: JSON.stringify(generateImageArray),
            article_html: response.data.article
          }));
        } else {
        }
      } catch (error) {
      }
    };

    const handleCopyHtmlArticleText = (text) => {
      if (text && text !== "") {
        const options = { indent_size: 2, space_in_empty_paren: true };
        const modifiedText = beautify_html(text, options);
        var isPlainText = !modifiedText.match(/<[^>]+>/);
        const isCoped = copy(modifiedText, {
          debug: true,
          format: isPlainText ? 'text/plain' : 'text/html',
        });
        if (isCoped) {
          toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
        }
      }
    };

    const handleGetAllImagesAndOpenModal = async (subType, html, imageTab = "", selectedImage = "", imagePrompt = "") => {
      try {
        if (imageTab && imageTab !== "") {
          setPostImagesModal((prev) => ({ ...prev, currentTab: imageTab, selectedImage, imagePrompt }));
        }

        const params = {
          html, type: articleType, subType, userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
          topic: generatedFullArticle?.topic ? generatedFullArticle.topic : "",
          keyword: generatedFullArticle?.keyword ? generatedFullArticle.keyword : "",
        };

        const queryParams = new URLSearchParams({
          type: articleType, subType, userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        }).toString();

        const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
        await handleGetOrGenerateAllTypeImages(paramObj);
        setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
      } catch (error) {
        setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
      }
    };

    const handleAutoFactChecker = async () => {
      dispatch(setLoader(true));
      const payload = {
        body: JSON.stringify({
          userId: userRes.id,
          articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        }),
      };
      const response = await invokeLambdaFunction("generateFullArticleFactCheckerV2", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        if (response && response.data) {
          const data = JSON.parse(response.data);
          let getCurrArticle = edit?.article_html;

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let finalText = element.textWithUrl ? element.textWithUrl.replaceAll("\n", "").replace(/\s+/g, ' ') : "";
            if (finalText && finalText.indexOf("class=") === -1) {
              finalText = finalText.replace('rel="noopener"', 'rel="noopener" class="tooltip-editor"');
            }
            const replaceText = element.text.replaceAll("\n", "").replace(/\s+/g, ' ').trim();
            getCurrArticle = getCurrArticle.replace(replaceText, finalText);
          }
          // setFactCheckHtml(getCurrArticle);
          setEdit({ ...edit, article_html: getCurrArticle });
        }
      } else {
        toast.error(response?.error, { id: "Toast-01" });
      }
    };

    const handleOpenReviewArticleTabInfoModal = async () => {
      await new Promise((resolve) => setTimeout(resolve, 10000));

      Swal({
        title: "Review Article",
        text: "Utilize our Review System as a fallback for ensuring article validity and reliability.",
        icon: "info", dangerMode: true,
        buttons: { cancel: "Not Now", confirm: `Yes, Review it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          setSelectedCurrentTab("reviewArticle");
          setSearchParams("tab=reviewArticle&&subTab=comments");
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, isShowReviewInfo: false }));
        } else {
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, isShowReviewInfo: false }));
        }
      });
    };

    const handleSetSelectedHashtagsOnPosts = (e, tag, type, isExist) => { };

    const handleShareSocialMediaPost = async () => { }

    const handleSetRestoredOldVersionText = async (fieldName, text) => {
      await dispatch(setGeneratedFullArticle({ ...generatedFullArticle, [fieldName]: text }));
      setEdit({ ...edit, [fieldName]: text });
    }

    return (
      <WrappedComponents
        {...props} handleSaveArticleOrPostText={handleSaveArticleOrPostText} savedModal={savedModal} setSavedModal={setSavedModal} historyData={filteredData ?? []} userRes={userRes}
        handleAutoFactChecker={handleAutoFactChecker} loading={isFetching} filters={filters} setFilters={setFilters} isFetching={isFetching}
        textWrap={textWrap} setTextWrap={setTextWrap} starred={starred} setStarred={setStarred} setSearch={setSearch} articleType={articleType}
        selectedRows={selectedRows} setSelectedRows={handleSelectedRows} handleArchiveAll={handleArchiveAll} totalCount={historyData?.totalCount || 0}
        page={page} setPage={setPage} limit={limit} topResultsStep={topResultsStep} handleSubmit={handleSubmit} register={register} watch={watch} setValue={setValue}
        errors={errors} onContinue={onContinue} control={control} handlePinArticle={handlePinArticle} handleDelete={handleDelete}
        show={show} handleClose={handleClose} active={active} setTab={setTab} tab={tab} setToggle={setToggle} toggle={toggle} handleSort={handleSort}
        htmlText={htmlText} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal} edit={edit} setEdit={setEdit} search={search}
        finalHtmlText={finalHtmlText} setHtmlText={setHtmlText} editorRef={editorRef} editorKey={editorKey} sortField={sortField} clearRow={clearRow}
        handleCopyHtmlArticleText={handleCopyHtmlArticleText} contentScore={contentScore} generatedFullArticle={generatedFullArticle}
        isSeoScoreDisplay={isSeoScoreDisplay} postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} getFullData={getFullData}
        selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
        handleGetArticleByIdAndEdit={handleGetArticleByIdAndEdit} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts} reset={reset}
        handleShareSocialMediaPost={handleShareSocialMediaPost} setSelectedCurrentTab={setSelectedCurrentTab} postImagesCarousel={postImagesCarousel}
        setPostImagesCarousel={setPostImagesCarousel} handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} getHistoryShortFlowData={useCallback(() => refetch(), [refetch])}
      />
    );
  };

  return OneBlogWriter;
};

export default OneBlogWriterHoc;
