import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    resetSEOFLow, setFinalContent, setGeneratedFullArticle, setGoogleSuggestKeyword, setHeadline, setKeywordTopic, setLoader,
    setNoOfClick, setOutlineRes, setSelectedGoogleWebsite, setSelectedTopic, setSeoGenerateTopic, setSeoOptBlogStep,
    setSeoOptBlogTopic, setSeoOptimizedSets, setSocialMediaPostStep, setSocialMediaStepsInfo, setTopResultsStep, setPressReleaseWriterInfo,
    setPressReleaseWriterStep, setECommerceBlogInfo, setECommerceBlogStep
} from "../store/reducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AxiosApi } from "../utility/axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import { deleteOrArchiveArticle, getUserArticleHistory, restoreArticleHistory } from "../utility/apiService";
import { sorting } from "../utility/common";
import { useQuery } from "@tanstack/react-query";
import { toasterSuccess } from "../Components/ui/toaster";

const withHistoryHandler = (WrappedComponent) => {
    const WithHistoryHandler = (props) => {
        const historyFiltersType = useMemo(() => ({ categories: [], durations: [], status: [], AIModels: [], users: [], isCustomDate: false, customDate: { startDate: '', endDate: '' } }), []);
        const { pathname } = useLocation();
        const navigate = useNavigate();
        const userRes = useSelector(({ userRes }) => userRes);
        const dispatch = useDispatch();
        const [historyData, setHistoryData] = useState({ data: [], totalCount: 0 });
        const [searchFilter, setSearchFilter] = useState("");
        const [textWrap, setTextWrap] = useState(false);
        const [type, setType] = useState("all");
        const [filters, setFilters] = useState(historyFiltersType);
        const [selectedRows, setSelectedRows] = useState([]);
        const [offset, setOffset] = useState(1);
        const [limit] = useState(10);
        const [sortBy, setSortBy] = useState([]);
        const [sortField, setSortField] = useState({ column: "", direction: "" });
        const [clearRow, setClearRow] = useState(false);
        const [loading, setLoading] = useState(false)
        const { refetch, isFetching, data } = useQuery({
            queryKey: ['historyData'],
            queryFn: async (e) => {
                const response = await getUserArticleHistory({
                    userId: userRes?.id, filters, sortBy, type, searchFilter,
                });
                return response;
            }
        })

        useEffect(() => {
            setHistoryData(data);
            // removing old filter from all child component when the path is changed
            setFilters(historyFiltersType);
        }, [pathname, data, historyFiltersType]);

        const handlePinArticle = (type, articleId, value) => {
            const url = `/api/articleHistory/pinArticleHistory`;
            AxiosApi.put(url, {
                type: type,
                articleId: articleId,
                value: !value,
            }).then((response) => {
                refetch();
                const toasterMessage = !value ? 'Article Added as Starred successfully.' : 'Article removed from the Starred successfully.'
                toasterSuccess(toasterMessage, 'toaster-43')
            }).catch((error) => {
                console.error(error);
            });
        };

        const handleNavigateArticleOrPostWriter = (id, type) => {
            if (type === "social_media") {
                handleGetSocialMediaPostByIdAndEdit(id);
            } else if (type === "long_flow" || type === "news_long_flow" || type === "oona_blog") {
                handleGetLongFlowArticleByIdAndEdit(id);
            } else if (type === "short_flow" || type === "news_short_flow" || type === "oona_short" || type === "oona_long") {
                handleGetArticleByIdAndEdit(id);
            } else if (type === 'newsletters_history') {
                navigate(`/newsletters/${id}`);
            } else if (type === 'press-release') {
                handleGetPressReleaseDetailsById(id);
            } else if (type === 'ecommerce_blog') {
                handleGetECommerceBlogDetailsById(id);
            } else if (type === "pdf_flow") {
                handleGetPdfToArticleById(id);
            } else {
                return false;
            }
        };

        const handleGetPressReleaseDetailsById = async (id) => {
            try {
                dispatch(setLoader(true));
                const response = await AxiosApi.get(`/api/press-release/get-detail?id=${id}`);
                if (response && response.data) {
                    dispatch(setLoader(false));
                    const item = response.data && response.data.id ? response.data : {};
                    const pressReleaseDataObj = { ...item };
                    pressReleaseDataObj.selectedCard = item.type;
                    pressReleaseDataObj.stepDone = item?.currentStep ? item.currentStep : 1;

                    dispatch(setPressReleaseWriterInfo({ ...pressReleaseDataObj }));
                    dispatch(setPressReleaseWriterStep(item?.currentStep ? item.currentStep : 1));
                    const scrollWrapper = document.getElementById("scroll-wrapper");
                    if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: "smooth" }); }
                    navigate(`/press-release/${item?.id}`);
                } else {
                    dispatch(setLoader(false));
                }
            } catch (error) {
                dispatch(setLoader(false));
            }
        };

        const handleGetECommerceBlogDetailsById = async (id) => {
            try {
                dispatch(setLoader(true));
                const response = await AxiosApi.get(`/api/product-catalog/article/history-details?id=${id}`);
                if (response && response.data) {
                    dispatch(setLoader(false));
                    const item = response.data && response.data.id ? response.data : {};
                    const eCommerceBlogDataObj = { ...item };
                    eCommerceBlogDataObj.stepDone = item?.status === "completed" ? 4 : (item?.topics && item?.topics.length > 0) ? 3 : 2;
                    dispatch(setECommerceBlogInfo({ ...eCommerceBlogDataObj }));
                    dispatch(setECommerceBlogStep(item?.status === "completed" ? 4 : (item?.topics && item?.topics.length > 0) ? 3 : 2));
                    const scrollWrapper = document.getElementById('scroll-wrapper');
                    if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
                    navigate(`/e-commerce-blog/${item?.id}`);
                } else {
                    dispatch(setLoader(false));
                }
            } catch (error) {
                dispatch(setLoader(false));
            }
        }

        const handleGetSocialMediaPostByIdAndEdit = (id) => {
            dispatch(setLoader(true));
            AxiosApi.get(`/api/post-writer/postHistory/byId/${id}`).then((res) => {
                dispatch(setLoader(false));
                const item = res.data && res.data.data ? res.data.data : {};
                item.current_step += 1;
                const postDataObj = {
                    postId: item?.id, PostType: item?.post_type, topic: item?.topic, keyword: item?.keyword,
                    link: item?.insert_link ? item.insert_link : item?.pdf_link ? item.pdf_link : "",
                    insert_link: item?.insert_link ? item.insert_link : "",
                    pdf_link: item?.pdf_link ? item.pdf_link : "",
                    toneOfVoice: item?.tone_of_voice ? item.tone_of_voice : "",
                    aiType: item?.AI_type ? item.AI_type : "",
                    top10WebRes: item?.search_response && JSON.parse(item?.search_response)?.length > 0 ? JSON.parse(item.search_response) : "",
                    selectedGoogleWebsite: item?.selectedGoogleWebsite && JSON.parse(item?.selectedGoogleWebsite)?.length > 0 ? JSON.parse(item.selectedGoogleWebsite) : "",
                    summary: item?.summary ? item.summary : "",
                    postRegenerateLeft: item?.post_regenerate_left ? item.post_regenerate_left : 0,
                    SetsOfPost: item?.sets_of_post ? item.sets_of_post : "",
                    selectedPost: item?.selected_post ? item.selected_post : "",
                    selected_set_post: item?.selected_set_post ? item.selected_set_post : "",
                    generated_hashtags: item?.generated_hashtags ? item.generated_hashtags : "",
                    oldHashtags: item?.oldHashtags && JSON.parse(item?.oldHashtags).length > 0 ? JSON.parse(item.oldHashtags) : [],
                    selected_hashtags: item?.selected_hashtags && JSON.parse(item?.selected_hashtags).length > 0 ? JSON.parse(item.selected_hashtags) : [],
                    upcomingEvents: item?.upcomingEvents ? item.upcomingEvents : [],
                    eventName: item?.selected_event ? item.selected_event : "",
                    final_post: item?.final_post ? item.final_post : "",
                    post_image: item?.post_image ? JSON.parse(item.post_image) : "",
                    stepDone: item?.current_step ? item.current_step : 2,
                    is_check_reviewer: item?.is_check_reviewer ? item.is_check_reviewer : 0,
                    geoLocation: item?.geoLocation ? item.geoLocation : "sg",
                    cta: item?.cta ? item.cta : ""
                };

                postDataObj.trendingPosts = null;
                if (item?.trending_posts && typeof JSON.parse(item.trending_posts) !== typeof undefined && JSON.parse(item.trending_posts)?.length > 0) {
                    postDataObj.trendingPosts = JSON.parse(item.trending_posts);
                }

                if (item?.insert_link) {
                    postDataObj.selectedCard = "link";
                } else if (item?.pdf_link) {
                    postDataObj.selectedCard = "pdf";
                } else {
                    postDataObj.selectedCard = "topic";
                }

                dispatch(setSocialMediaStepsInfo({ ...postDataObj }));
                dispatch(setSocialMediaPostStep(item?.current_step ? item.current_step : 2));
                const scrollWrapper = document.getElementById("scroll-wrapper");
                if (scrollWrapper) {
                    scrollWrapper.scrollTo({ top: 0, behavior: "smooth" });
                }
                navigate(`/social-media-post-writer/${item?.id}`);
            }).catch((e) => {
                console.log("Error : ", e);
                dispatch(setLoader(false));
            });
        };

        const handleGetLongFlowArticleByIdAndEdit = (id) => {
            dispatch(setLoader(true));
            AxiosApi.get(`/api/articleHistory/byId/${id}?tableName=artical_long_flow`).then((res) => {
                dispatch(setLoader(false));
                dispatch(resetSEOFLow());
                const item = res.data && res.data.data ? res.data.data : {};
                item.current_step += 1;
                if (item && item.current_step && item.current_step >= 3) {
                    dispatch(setSeoGenerateTopic(item?.topic));
                    dispatch(setSeoOptBlogTopic({
                        Topics: "",
                        data: item.search_response ? JSON.parse(item.search_response) : [],
                        articleId: item.id,
                        geoLocation: item?.geoLocation ? item.geoLocation : "sg",
                        blog_type: item?.blog_type ? item.blog_type : "Topic",
                        topic_desc: item?.topic_desc ? item.topic_desc : "",
                        keyword: item?.keyword ? item.keyword : "",
                        stepDone: item?.current_step ? item.current_step : 3,
                        generateLink: item?.generate_links,
                        pdf_link: item?.pdf_link ? item.pdf_link : "",
                        lan: item?.lan ? item.lan : "English",
                        aiType: item?.AI_type ? item.AI_type : "",
                        is_check_reviewer: item?.is_check_reviewer ? item.is_check_reviewer : 0,
                    }));

                    if (item.selectedGoogleWebsite) {
                        dispatch(setSelectedGoogleWebsite(JSON.parse(item.selectedGoogleWebsite)));
                    } else {
                        dispatch(setSelectedGoogleWebsite([]));
                    }
                }

                if (item && item.current_step && item.current_step >= 4) {
                    if (item.generated_keywords && typeof JSON.parse(item.generated_keywords) !== typeof undefined) {
                        dispatch(setKeywordTopic(JSON.parse(item.generated_keywords)));
                    }
                    dispatch(setSelectedTopic(item?.topic));
                    if (item.selectedGoogleWebsite) {
                        dispatch(setSelectedGoogleWebsite(JSON.parse(item.selectedGoogleWebsite)));
                    } else {
                        dispatch(setSelectedGoogleWebsite([]));
                    }
                }

                if (item && item.current_step && item.current_step >= 5) {
                    dispatch(setGoogleSuggestKeyword(item.keyword));
                    dispatch(setSeoOptimizedSets({
                        seoOptimizedData: JSON.parse(item.setsOfSeoOptimized),
                        summaryDescription: item.top_summary,
                    }));
                }

                if (item && item.current_step && item.current_step >= 6) {
                    dispatch(setSeoOptimizedSets({
                        seoOptimizedData: JSON.parse(item.setsOfSeoOptimized),
                        summaryDescription: item.top_summary,
                        headline: JSON.parse(item.selected_seoOptimized_set)?.headline,
                        keyword: JSON.parse(item.selected_seoOptimized_set)?.keyword,
                    }));
                    if (item.headline_sets && typeof JSON.parse(item.headline_sets) !== typeof undefined) {
                        dispatch(setHeadline(JSON.parse(item.headline_sets)));
                        dispatch(setOutlineRes(JSON.parse(item.headline_sets)));
                    }
                }

                if (item && item.current_step && item.current_step === 7) {
                    dispatch(setFinalContent({
                        article: item.article_html, article_html: item.article_html,
                        meta_title: item.meta_title, meta_dec: item.meta_dec, faq_regenerate_left: item.faq_regenerate_left,
                        SEO_score_response: item?.SEO_score_response ? JSON.parse(item?.SEO_score_response).data : "",
                        FAQschema: item?.FAQschema || "", FAQHTML: item?.FAQHTML || "",
                        linkedIn_post: item?.linkedIn_post, linkedIn_post_img: item?.linkedIn_post_img,
                        facebook_post: item?.facebook_post, facebook_post_img: item?.facebook_post_img,
                        twitter_post: item?.twitter_post, twitter_post_img: item?.twitter_post_img,
                        instagram_post: item?.instagram_post, instagram_post_img: item?.instagram_post_img,
                        instagram_reels: item?.instagram_reels, googleAdCopy: item?.googleAdCopy,
                        allSelectedOutline: item?.selected_headline_set ? JSON.parse(item?.selected_headline_set) : [],
                        optimizedArticle: item?.optimized_article ? item.optimized_article : "",
                        article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
                        toneOfVoice: item?.toneOfVoice ? item.toneOfVoice : "", h2_images: item?.h2_images ? item.h2_images : "",
                        logs: item?.logs ? item.logs : "", isTyping: false, isTypingDone: true,
                    }));
                }

                if (item && item.keyword_regenerate_left >= 0) {
                    dispatch(setNoOfClick(item.keyword_regenerate_left));
                }

                dispatch(setSeoOptBlogStep(item?.current_step ? item.current_step : 3));
                if (item.type === "oona_blog") {
                    navigate(`/oona-blog-co-pilot/${item?.id}`);
                } else {
                    navigate(`/blog-co-pilot/${item?.id}`);
                }

                const scrollWrapper = document.getElementById("scroll-wrapper");
                if (scrollWrapper) {
                    scrollWrapper.scrollTo({ top: 0, behavior: "smooth" });
                }
            }).catch((e) => {
                console.log("Error : ", e);
                dispatch(setLoader(false));
            });
        };

        const handleGetArticleByIdAndEdit = (id) => {
            dispatch(setLoader(true));
            AxiosApi.get(`/api/articleHistory/byId/${id}`).then((res) => {
                const item = res.data && res.data.data ? res.data.data : {};
                dispatch(setGeneratedFullArticle({
                    ...item, articleId: item?.id, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
                    linkedIn_post: item?.linkedIn_post, facebook_post: item?.facebook_post,
                    twitter_post: item?.twitter_post, instagram_post: item?.instagram_post, instagram_reels: item?.instagram_reels,
                    SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
                    popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
                    article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
                }));
                dispatch(setTopResultsStep(3)); dispatch(setLoader(false));

                if (item.type && item.type === "oona_long") {
                    navigate(`/oona-1-click/long-blog/${item?.id}`);
                } else if (item.type && item.type === "oona_short") {
                    navigate(`/oona-1-click/short-blog/${item?.id}`);
                } else {
                    navigate(`/1-click-blog/${item?.id}`);
                }
            }).catch((e) => {
                console.log("Error : ", e);
                dispatch(setLoader(false));
            });
        };

        const handleGetPdfToArticleById = (id) => {
            dispatch(setLoader(true));
            AxiosApi.get(`/api/articleHistory/byId/${id}`).then((res) => {
                const item = res.data && res.data.data ? res.data.data : {};
                dispatch(setGeneratedFullArticle({
                    ...item, articleId: item?.id, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
                    linkedIn_post: item?.linkedIn_post, facebook_post: item?.facebook_post,
                    twitter_post: item?.twitter_post, instagram_post: item?.instagram_post, instagram_reels: item?.instagram_reels,
                    SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
                    popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
                    article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
                }));
                dispatch(setTopResultsStep(2));
                dispatch(setLoader(false));
                const scrollWrapper = document.getElementById('scroll-wrapper');
                if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }

                if (item.pdf_info && item.pdf_info !== "") {
                    dispatch(setTopResultsStep(item.status === "completed" ? 3 : 2));
                    navigate(`/pdf-to-article/${item?.id}`);
                } else {
                    navigate(`/1-click-blog/${item?.id}`);
                }
            }).catch((e) => {
                console.log(e, "eee");
                dispatch(setLoader(false));
            });
        };

        const handleSelectedRows = (e) => {
            setSelectedRows(e?.selectedRows?.map(({ type, id }) => ({ id, type })));
        };

        const onDashboardCategorySelect = (e) => {
            const newFilter = { ...filters };
            newFilter.categories = [e.value];
            handleFilters(newFilter);
        };

        const onDashboardDateSelect = (e) => {
            const newFilter = { ...filters };
            newFilter.durations = [e.value];
            handleFilters(newFilter);
        };

        const handleArchive = () => {
            Swal({
                title: "Are you sure?",
                text: "Do you want to archive the selected item?",
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, archive ${selectedRows.length > 1 ? "all" : ""}!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callback = (resp) => {
                        setOffset(1);
                        refetch()
                        setSelectedRows([]);
                        setClearRow(pre => !pre);
                    }
                    deleteOrArchiveArticle(selectedRows, 'archive', callback);
                }
            });
        };

        const handleDelete = (id, type, eType) => {
            Swal({
                title: "Are you sure?",
                text: `Do you want to ${eType} the selected item?`,
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, ${eType} it!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callback = () => {
                        setOffset(1);
                        refetch()
                        setSelectedRows([]);
                        setClearRow(pre => !pre);
                    };
                    deleteOrArchiveArticle([{ id, type }], eType, callback);
                }
            });
        };

        const handleRestore = (id, type) => {
            Swal({
                title: "Are you sure?",
                text: "Do you want to restore the selected item?",
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, restore it!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callBack = () => {
                        setOffset(1);
                        refetch()
                        setSelectedRows([]);
                        setClearRow(pre => !pre);
                    };
                    restoreArticleHistory([{ id, type }], callBack);
                }
            });
        };

        const handleRestoreAll = () => {
            Swal({
                title: "Are you sure?",
                text: "Do you want to restore all the selected item?",
                icon: "warning",
                dangerMode: true,
                buttons: { cancel: "Cancel", confirm: "Yes, restore all!" },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callBack = () => {
                        setOffset(1);
                        refetch();
                        setSelectedRows([]);
                        setClearRow(pre => !pre);
                    };
                    restoreArticleHistory(selectedRows, callBack);
                }
            });
        };

        const handleDeleteAll = (eType) => {
            Swal({
                title: "Are you sure?",
                text: `Do you want to ${eType} all the selected item?`,
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, ${eType} all!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callBack = (resp) => {
                        setOffset(1);
                        refetch();
                        setSelectedRows([]);
                        setClearRow(pre => !pre);
                    }
                    deleteOrArchiveArticle(selectedRows, eType, callBack);
                }
            });
        };

        const handleSort = async (column, sortDirection) => {
            const newHistoryData = [...historyData?.data];
            setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
            setOffset(1);
            const newData = sorting(newHistoryData, column?.sortField, sortDirection);
            setHistoryData(pre => ({ ...pre, data: newData }));
        };

        const handleFilters = async (filters) => {
            setFilters((pre) => ({ ...pre, ...filters }));
            setOffset(1);
            setSelectedRows([]);
            await getUserArticleHistory({
                userId: userRes?.id, filters, sortBy, type, searchFilter,
            }).then((response) => {
                setHistoryData(response);
            })
        };

        const handleByType = async (type) => {
            setType(type)
            setLoading(true);
            await getUserArticleHistory({
                userId: userRes?.id, filters, sortBy, type, searchFilter,
            }).then((response) => {
                setHistoryData(response);
            }).finally(() => {
                setLoading(false);
            })
            setOffset(1);
            setSelectedRows([]);
            setClearRow(pre => !pre)
        };

        const filteredData = useMemo(() => (
            historyData?.data?.filter(item =>
                Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('').toLowerCase().includes(searchFilter?.split(' ').join('').toLowerCase()))
            )
        ), [historyData, searchFilter])

        const handleReArrangeData = useCallback(() => {
            setHistoryData(data);
        }, [data]);

        return (
            <div>
                <WrappedComponent
                    {...props}
                    handlePinArticle={handlePinArticle} historyData={filteredData} setHistoryData={setHistoryData} setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter} textWrap={textWrap} setTextWrap={setTextWrap} type={type} setType={handleByType}
                    handleNavigateArticleOrPostWriter={handleNavigateArticleOrPostWriter} handleGetSocialMediaPostByIdAndEdit={handleGetSocialMediaPostByIdAndEdit}
                    handleGetLongFlowArticleByIdAndEdit={handleGetLongFlowArticleByIdAndEdit} loading={isFetching} setPage={setOffset}
                    filters={filters} setFilters={handleFilters} selectedRows={selectedRows} setSelectedRows={handleSelectedRows}
                    limit={limit} getArticleAndPostHistoryData={refetch} totalCount={historyData?.totalCount} page={offset}
                    setSortBy={setSortBy} onCategorySelect={onDashboardCategorySelect} onDateSelect={onDashboardDateSelect} handleDelete={handleDelete}
                    handleArchive={handleArchive} handleSort={handleSort} handleRestore={handleRestore} handleRestoreAll={handleRestoreAll}
                    handleDeleteAll={handleDeleteAll} sortField={sortField} userRes={userRes} getHistoryData={refetch} setSortField={setSortField}
                    clearRow={clearRow} isFetching={loading} handleReArrangeData={handleReArrangeData}
                />
            </div>
        );
    };
    return WithHistoryHandler;
};

export default withHistoryHandler;
