import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Swal from 'sweetalert';
import copy from "copy-to-clipboard";
import { FiPlus, FiSkipForward } from "react-icons/fi";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosLink } from "react-icons/io";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { ImEarth } from "react-icons/im";
import { MdDeleteOutline } from "react-icons/md";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo } from "../../store/reducer";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { BsArrowLeft, BsArrowRight, BsPlusLg, BsStars, BsTrash } from "react-icons/bs";
import { BsInfoCircleFill } from "react-icons/bs";
import { ICONS } from "../../Assets/Icons/index";
import { ReactSVG } from "react-svg";

const SecondStepSocialMediaPost = () => {
  const dispatch = useDispatch();

  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const userRes = useSelector(({ userRes }) => userRes);
  const [generatedLinks, setGeneratedLinks] = useState([]);
  const [addedNewUrl, setAddNewUrl] = useState([]);
  const [selectedGoogleWebsite, setSelectedGoogleWebsite] = useState([]);
  const [oldSelectedGoogleWebsite, setOldSelectedGoogleWebsite] = useState([]);
  const [isRegeneratePost, setIsRegeneratePost] = useState(false);
  const [haveCustomLink, setHaveCustomLink] = useState(false);

  useEffect(() => {
    if (socialMediaPostInfo && socialMediaPostInfo.top10WebRes) {
      setGeneratedLinks([...socialMediaPostInfo.top10WebRes]);

      setAddNewUrl([]);
      socialMediaPostInfo.top10WebRes.map((item) => {
        if (item.content === '') {
          setAddNewUrl((prev) => ([...prev, item]));
        }
        return item;
      })
    }

    if (socialMediaPostInfo.selectedGoogleWebsite && socialMediaPostInfo.selectedGoogleWebsite.length > 0) {
      setSelectedGoogleWebsite(socialMediaPostInfo.selectedGoogleWebsite);
      setOldSelectedGoogleWebsite(socialMediaPostInfo.selectedGoogleWebsite);
    }
  }, []);

  useEffect(() => {
    const applyTooltipDataAttributes = (tooltipSpan) => {
      if (tooltipSpan) {
        tooltipSpan.setAttribute('data-tooltip-id', 'my-tooltip');
        tooltipSpan.setAttribute('data-tooltip-place', 'right');
        tooltipSpan.setAttribute('data-tooltip-content', 'Copy Link');
      }
    };
    setTimeout(() => {
      const tooltipSpans = document.querySelectorAll('.tooltip-span');
      tooltipSpans.forEach(applyTooltipDataAttributes);
    }, 0);
  }, [generatedLinks]);

  const handleAddInput = () => {
    if (addedNewUrl && addedNewUrl.length >= 3) {
      return toast.error("You can add only 3 new url.", { id: "Toast-01" });
    }
    setHaveCustomLink(true);
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, top10WebRes: [{ content: '', url: '' }, ...generatedLinks] }));
    setGeneratedLinks([{ content: '', url: '' }, ...generatedLinks]);
    setAddNewUrl([{ content: '', url: '' }, ...addedNewUrl]);
  };

  const handleInputChange = (event, index, key) => {
    const newData = [...generatedLinks];
    newData[index] = { content: '', url: event.target.value };
    setGeneratedLinks([...newData]);

    const addedUrl = [...addedNewUrl];
    addedUrl[index] = { content: '', url: event.target.value };
    setAddNewUrl([...addedUrl]);
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, top10WebRes: newData }));
    handleSelectWebsiteLinks(newData);
  };

  const handleRemoveNewUrl = (index) => {
    const newData = [...generatedLinks.filter((ele, i) => i !== index)];
    setGeneratedLinks([...newData]);
    setHaveCustomLink(false);

    const addedUrl = [...addedNewUrl?.filter((ele, i) => i !== index)];
    setAddNewUrl([...addedUrl]);
    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, top10WebRes: newData }));
    handleSelectWebsiteLinks(newData);
  }

  const handleSelectWebsiteLinks = (newData) => {
    const newArray = [];
    newData.map((item1) => {
      selectedGoogleWebsite.map((item2) => {
        if (item1.url === item2.url) {
          newArray.push(item1);
        }
        return item2;
      })
      return item1;
    });
    setSelectedGoogleWebsite(newArray);
    handleChangeRegenerateStatus(newArray);
  }

  const handleOnSelectSearchWebUrl = (item, isCheck) => {
    if (item.url && item.url !== "") {
      if (selectedGoogleWebsite.length >= 3 && isCheck) {
        toast.error("Select at least 1 and at most 3 Links.", { id: "Toast-01" });
        return false;
      }

      let newArray = [];
      const getObjIndex = selectedGoogleWebsite?.findIndex((item1) => item1.url === item.url);
      if (getObjIndex === -1 && selectedGoogleWebsite.length < 3) {
        newArray = [...selectedGoogleWebsite, { ...item }];
      } else {
        newArray = selectedGoogleWebsite.filter((item1) => item1.url !== item.url);
      }

      setSelectedGoogleWebsite(newArray);
      if (socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && JSON.parse(socialMediaPostInfo.SetsOfPost)?.length > 0) {
        handleChangeRegenerateStatus(newArray);
      }
    }
  }

  const handleChangeRegenerateStatus = (newData) => {
    let regeneratePostStatus = false;
    oldSelectedGoogleWebsite.map((item1) => {
      const findIndex = newData.findIndex((item2) => item1.url === item2.url);
      if (findIndex === -1) {
        regeneratePostStatus = true;
      }
      return item1;
    });

    newData.map((item1) => {
      const findIndex = oldSelectedGoogleWebsite.findIndex((item2) => item1.url === item2.url);
      if (findIndex === -1) {
        regeneratePostStatus = true;
      }
      return item1;
    });
    setIsRegeneratePost(regeneratePostStatus);
  }

  const handleNext = async (isCheck, isDeductCredit = true) => {
    if (!generatedLinks || generatedLinks.length === 0) {
      toast.error("Add at least 1 new url before process.", { id: "Toast-01" });
      return false;
    }

    if (selectedGoogleWebsite.length >= 1 || !isCheck) {
      dispatch(setLoader(true));

      const selectedArr = [...selectedGoogleWebsite];
      if (selectedGoogleWebsite && selectedGoogleWebsite.length === 0) {
        for (let index = 0; index < 3; index++) {
          const element = generatedLinks[index];
          selectedArr.push(element);
        }
      }

      const payload = {
        body: JSON.stringify({
          postId: socialMediaPostInfo.postId,
          isGenerateSummary: true,
          isSkipStep: !isCheck ? true : false,
          selectedGoogleWebsite: selectedArr,
          generatedLinks, addedNewUrl,
        })
      };

      const response = await invokeLambdaFunction("generateSummaryOrSaveHashtags", payload);
      dispatch(setLoader(false));
      const selectedWebsite = isCheck ? selectedArr : [];

      if (response && response.success === 1) {
        dispatch(setSocialMediaStepsInfo({
          ...socialMediaPostInfo, selectedGoogleWebsite: selectedWebsite, ...response.data, isCallApi: "true",
          stepDone: socialMediaPostInfo.stepDone < 4 ? 4 : socialMediaPostInfo.stepDone, isDeductCredit: isDeductCredit,
          postRegenerateLeft: socialMediaPostInfo.postRegenerateLeft > 0 && !isDeductCredit ? socialMediaPostInfo.postRegenerateLeft - 1 : socialMediaPostInfo.postRegenerateLeft,
        }));
        setSelectedGoogleWebsite(selectedWebsite);
        setOldSelectedGoogleWebsite(selectedWebsite);
        dispatch(setSocialMediaPostStep(4));
      } else {
        if (response?.error?.indexOf("link is not valid") !== -1) {
          Swal("Oops...", "Inserted links is not valid, please add another link.", "error");
        } else {
          toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
        }
      };
    } else {
      toast.error("Select at least 1 and at most 3.", { id: "Toast-01" });
    }
  };

  const handleContinueNextStep = async () => {
    const tempSelectedWebsite = selectedGoogleWebsite && selectedGoogleWebsite.length > 0 ? selectedGoogleWebsite : [];
    dispatch(setSocialMediaStepsInfo({
      ...socialMediaPostInfo, selectedGoogleWebsite, isCallApi: "false",
      stepDone: socialMediaPostInfo.stepDone < 4 ? 4 : socialMediaPostInfo.stepDone,
    }));
    setSelectedGoogleWebsite(tempSelectedWebsite);
    dispatch(setSocialMediaPostStep(4));
  };

  const handleCopyLink = (data) => {
    toast.success("Link has been copied to clipboard.");
    copy(data);
  };

  return (
    <React.Fragment>
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>Select Links (Please update the heading later) </b>
        </div>
        <div className="nxtGenBtn d-flex flex-wrap gap-3 align-items-center align-items-center gap-2">
          <button type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              dispatch(setSocialMediaPostStep(2));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <span><BsArrowLeft /> Previous</span>
          </button>
          {/* {!isRegeneratePost && ( */}
          <React.Fragment>
            <span style={{ opacity: 0.1 }}>|</span>
            <button type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
              disabled={selectedGoogleWebsite && selectedGoogleWebsite.length > 0 ? false : true}
              onClick={() => {
                if (socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && JSON.parse(socialMediaPostInfo.SetsOfPost)?.length > 0) {
                  handleContinueNextStep(true);
                } else {
                  handleNext(true, true);
                }
              }}
            >
              Generate Post <BsArrowRight />
            </button>
          </React.Fragment>
          {/* )} */}
        </div>
      </div>

      <div className="postDetails">
        <div className="textA">
          <p>Post Description</p>
          <span>{socialMediaPostInfo.topic}</span>
        </div>
        <div className="textA mb-0">
          <p>Post hashtags</p>
          <span className="text-black">{`#${socialMediaPostInfo.keyword}`}</span>
        </div>
      </div>

      <div className="SocialMediaPostWriter">
        <Col md={12}>
          <div className="">
            <div className={"mb-3 mt-3 addUrlBlockS gap-2"} style={{ display: 'flex', flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", padding: '0 32px' }}>
              <div className="d-flex gap-1" style={{ alignItems: "center" }}>
                <h5 className="mb-0">
                  You have the option to select from the links below to create more targeted social media posts (select at least 1 and 3 at most)
                </h5>
                <BsInfoCircleFill
                  data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
                  data-tooltip-content="AI model suggestions is based on user inputs"
                  className="ms-2 infoIconSvg"
                />
              </div>
              <div className="d-flex flex-wrap gap-2" style={{ whiteSpace: "nowrap" }}>
                {(socialMediaPostInfo && (!socialMediaPostInfo.SetsOfPost || JSON.parse(socialMediaPostInfo.SetsOfPost)?.length === 0) && generatedLinks && generatedLinks.length > 0 && !haveCustomLink) && (
                  <button className="btn btn-outline-primary me-3 blogButton border-0" onClick={() => {
                    if (socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && JSON.parse(socialMediaPostInfo.SetsOfPost)?.length > 0 && !isRegeneratePost) {
                      handleContinueNextStep();
                    } else {
                      handleNext(false, true);
                    }
                  }}>
                    <ReactSVG src={ICONS.skipIcon} useRequestCache /> Skip step
                  </button>
                )}
                {generatedLinks && generatedLinks.length > 0 && (
                  <button className="btn btn-primary" onClick={handleAddInput}>
                    <BsPlusLg /> Add New URL
                  </button>
                )}
              </div>
            </div>
            <Row className="">
              <Col md={12} >
                <div className="googleSearchResult">
                  {/* <div className={"mb-3 addUrlBar"} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <h6 className="font-weight-500 color-light-text mb-0">Top 10 Google Search Results (Select at least 1 and at most 4)</h6>
                    <button className="addlly-outline btn-sm py-2 px-3" onClick={handleAddInput}>
                      <FiPlus /> Add New URL
                    </button>
                  </div> */}
                  <ul className="seoOptBlogTopic google-web-wrapper">
                    {generatedLinks && generatedLinks.length > 0 ? (generatedLinks.map((item, ind) => (
                      <li key={ind} className="overflow-hidden">
                        <div className={`checkListItem mb-3 py-2 flex-column ${selectedGoogleWebsite?.find((item1) => item1.url === item.url) ? "active" : ""}`}>
                          <div className={`d-flex align-items-center w-100 linkImageBlock ${!item.content ? "border-0 m-0 p-0" : ""}`}>
                            <input type="checkbox" className="custom-checkbox cursor-pointer" disabled={!item.url || item.url === ""}
                              checked={selectedGoogleWebsite.find((item1) => item1.url === item.url) ? true : false}
                              onChange={(e) => item.url && item.url !== "" ? handleOnSelectSearchWebUrl(item, e.target.checked) : null}
                            />
                            <div className="ms-3 w-100 ">
                              {!item.content ? (
                                <input
                                  className="w-100 pe-4" placeholder="Place URL here" value={item.url} onClick={(e) => e.stopPropagation()}
                                  style={{ background: "transparent", outline: "none", border: '0', fontSize: "14px", marginBottom: "0" }}
                                  type="text" name={`url-${ind}`} onChange={(event) => handleInputChange(event, ind, 'url')}
                                />
                              ) : (
                                <a href={item.url} target="_blank" rel="noreferrer" className="link-content" onClick={(e) => e.stopPropagation()}>
                                  <div className="d-flex align-items-center mb-1">
                                    <div className="link-image">
                                      <DisplayWebUrlImage url={item.url} faviconLink={item?.faviconLink} />
                                    </div>
                                    <div>
                                      <p className="text-black">
                                        {item.url.split("://")[1].split("/")[0]?.replace("www.", "")}
                                        <IoIosLink className="ms-2 outline-0 tooltip-span"
                                          onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleCopyLink(item.url); }}
                                        />
                                      </p>
                                      <p>{item.url.split("?")[0]}</p>
                                    </div>
                                  </div>
                                </a>
                              )}
                            </div>
                            {!item.content && (
                              <button className="p-0 ms-3 btn" onClick={(e) => { e.stopPropagation(); handleRemoveNewUrl(ind, item.url); }}>
                                <BsTrash style={{
                                  fill: "#FF0000", width: '16px', height: "16px", position: 'relative',
                                  top: '-1px'
                                }} />
                              </button>
                            )}
                          </div>
                          {item?.title && item?.title !== "" && (
                            <h4 className="color-dark-blue fw-normal" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                          )}
                          {item?.description && item?.description !== "" && (
                            <h5 className="google-content mt-1" dangerouslySetInnerHTML={{ __html: item.description }}></h5>
                          )}
                        </div>
                      </li>
                    ))) : (
                      <li className="text-center my-5 py-4" style={{ color: "black" }}>
                        <h4 className="mb-1 fw-normal opacity-50" style={{ color: "black" }}>Link suggestions are not available.</h4>
                        <h4 className="mb-4 fw-normal opacity-50" style={{ color: "black" }}>Add your URL before generating post.</h4>
                        <button type="button" onClick={handleAddInput} className="btn btn-primary">
                          <BiPlus /> Add New URL
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </div>

      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row mt-0  gap-2 bottomButton">
        <button type="button" className="addlly-outline "
          onClick={() => {
            dispatch(setSocialMediaPostStep(2));
            const scrollWrapper = document.getElementById('scroll-wrapper');
            if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          }}
        >
          <span><BsArrowLeft /> Previous</span>
        </button>
        {isRegeneratePost ? (
          <React.Fragment>
            {socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && JSON.parse(socialMediaPostInfo.SetsOfPost)?.length > 0 ? (
              <React.Fragment>
                {socialMediaPostInfo && socialMediaPostInfo.postRegenerateLeft !== 0 ? (
                  <div className='d-flex align-items-center gap-3'>
                    <button type="submit" className="addlly-primary mw-100" style={{ whiteSpace: "nowrap" }} onClick={() => handleNext(true, false)}>
                      <span><BsStars />Generate Post ({socialMediaPostInfo.postRegenerateLeft} of 3 tries Left)<BsArrowRight /></span>
                    </button>
                  </div>
                ) : (
                  <div className='d-flex align-items-center gap-3'>
                    <button type="submit" className="addlly-primary mw-100" style={{ whiteSpace: "nowrap" }} onClick={() => handleNext(true, true)}>
                      <span> <BsStars /> Regenerate Posts<BsArrowRight /></span>
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className='d-flex align-items-center gap-3'>
                <button type="submit" className="addlly-primary" style={{ whiteSpace: "nowrap" }} onClick={() => handleNext(true, true)}>
                  <span> <BsStars />Generate Post<BsArrowRight /></span>
                </button>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className='d-flex align-items-center gap-3'>
            <div className="d-flex gap-1" style={{ alignItems: "center" }}>
              <button type="submit" className="addlly-primary" style={{ whiteSpace: "nowrap" }}
                disabled={selectedGoogleWebsite && selectedGoogleWebsite.length > 0 ? false : true}
                onClick={() => {
                  if (socialMediaPostInfo && socialMediaPostInfo.SetsOfPost && JSON.parse(socialMediaPostInfo.SetsOfPost)?.length > 0) {
                    handleContinueNextStep(true);
                  } else {
                    handleNext(true, true);
                  }
                }}
              >
                <span> <BsStars />Generate Post<BsArrowRight /></span>
              </button>
              <BsInfoCircleFill
                data-tooltip-id="my-tooltip" data-tooltip-place="right"
                data-tooltip-content="select at least 1 and 3 at most"
                className="ms-2 infoIconSvg"
              />
            </div>
          </div>
        )}
      </div >
    </React.Fragment >
  );
};

const DisplayWebUrlImage = ({ url, faviconLink }) => {
  const [isImageExit, setIsImageExit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (faviconLink && (faviconLink !== 'favicon.ico' && faviconLink !== '/favicon.ico')) {
      if (faviconLink[0] === '/' && faviconLink[1] !== '/') {
        const getOrigin = new URL(url)?.origin;
        setImageUrl(`${getOrigin}${faviconLink}`);
      } else {
        setImageUrl(faviconLink);
      }
      setIsImageExit(true);
    } else if (url) {
      const getOrigin = new URL(url)?.origin;
      setImageUrl(`${getOrigin}/favicon.ico`);
      setIsImageExit(true);
    }
  }, [])

  return (
    <React.Fragment>
      {isImageExit ? (
        <img src={imageUrl} onError={() => setIsImageExit(false)} alt="" />
      ) : (
        <ImEarth className="text-primary" />
      )}
    </React.Fragment>
  )
}

export default SecondStepSocialMediaPost;
