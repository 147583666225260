import React from "react";

import { BsChevronDown } from "react-icons/bs";
import { TiArrowUnsorted } from "react-icons/ti";
import Script from "react-load-script";

import User1 from "../Assets/Images/User/User1.jpg";

const RecentTrends = () => {
  const handleScriptLoad = () => {
    window.trends.embed.renderWidgetTo(document.getElementById("widget"), "dailytrends", "", {
      geo: "SG",
      guestPath: "https://trends.google.com:443/trends/embed/",
    });
    // window.trends.embed.renderExploreWidgetTo(
    //   document.getElementById("widget"),
    //   "dailytrends",
    //   { comparisonItem: [{ keyword: "dbs bank", geo: "", time: "today 12-m" }], category: 0, property: "" },
    //   { exploreQuery: "q=dbs%20bank&date=today 12-m", guestPath: "https://trends.google.com:443/trends/embed/" }
    // );
  };

  const renderGoogleTrend = (_) => {
    return <Script url={"https://ssl.gstatic.com/trends_nrtr/3309_RC01/embed_loader.js"} onLoad={handleScriptLoad} />;
  };

  return (
    <div className="mt-4 recent-trends border-radius-12 bg-white p-4">
      <h4 className="mb-0">Recent Trends</h4>
      {/* <div id="widget">{renderGoogleTrend()}</div> */}
      <div className="res-table">
        <table className="custom-table table mt-20 ">
          <thead>
            <tr>
              <th scope="col">
                Earlier <TiArrowUnsorted size={16} />
              </th>
              <th scope="col">
                Searches <TiArrowUnsorted size={16} />
              </th>
              <th scope="col">
                Trend Date & Time <TiArrowUnsorted size={16} />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="d-sm-flex align-items-center">
                  <img src={User1} alt="user1" />
                  <div className="ms-sm-3 ms-0">
                    <h4>Quitclaim Deed.doc</h4>
                    <p>
                      Bodhi, played by Patrick Swayze, is part mystic, part criminal, and over-all surfer. From clues
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <h3> 2K </h3>
              </td>
              <td>
                <h6 className="color-light-text"> Mar 27, 2023 - 1:34pm</h6>
              </td>
              <td>
                <BsChevronDown />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-sm-flex align-items-center">
                  <img src={User1} alt="user1" />
                  <div className="ms-sm-3 ms-0">
                    <h4>Quitclaim Deed.doc</h4>
                    <p>
                      Bodhi, played by Patrick Swayze, is part mystic, part criminal, and over-all surfer. From clues
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <h3> 2K </h3>
              </td>
              <td>
                <h6 className="color-light-text"> Mar 27, 2023 - 1:34pm</h6>
              </td>
              <td>
                <BsChevronDown />
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-sm-flex align-items-center">
                  <img src={User1} alt="user1" />
                  <div className="ms-sm-3 ms-0">
                    <h4>Quitclaim Deed.doc</h4>
                    <p>
                      Bodhi, played by Patrick Swayze, is part mystic, part criminal, and over-all surfer. From clues
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <h3> 2K </h3>
              </td>
              <td>
                <h6 className="color-light-text"> Mar 27, 2023 - 1:34pm</h6>
              </td>
              <td>
                <BsChevronDown />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTrends;
