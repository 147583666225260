import classNames from "classnames";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Col, Placeholder, Row } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { FaRegImage } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BsCloudUploadFill, BsInfoCircleFill, BsStars, BsTrashFill } from "react-icons/bs";
import ImageDisplayContent from "./ImageDisplayContent";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import { AxiosApi } from "../../../utility/axios";
import { invokeLambdaFunction } from "../../../utility/invokeLamdaFunction";
import { handleGetGeneratedImageHistory } from "../../../utility/apiService";
import NoDataAvailable from "../../NoDataAvailable";

const BrandsAIImagery = ({ props, handleSaveSelectedImage }) => {
  const {
    setPostImagesModal, postImagesModal, articleType, articleId = 0,
    postGeneratedImages, setPostGeneratedImages, userRes
  } = props;

  const mediaFileTypes = useMemo(() => (["JPG", "PNG", "JPEG"]), []);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [selectedTab, setSelectedTab] = useState("create");
  const [isGenerateBgImages, setIsGenerateBgImages] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["imageHistory"],
    queryFn: async () => await handleGetGeneratedImageHistory(userRes?.id),
  });

  const handleOnSelectImageFile = async (file) => {
    if (!file || file?.length === 0) {
      return false;
    }
    setUploadFile(file);
    setUploadFileError(null);
  };

  const tabes = useMemo(() => ([
    { name: "Create", value: "create" },
    { name: "Renderings", value: "renderings" },
    { name: "Gallery", value: "gallery" },
  ]), []);

  const handleOnUploadImage = (file, tabVal = "create") => {
    setUploadFileError(null); setUploadFileDragging(null);
    setUploadFile(file?.image ? file.image : file); setSelectedTab(tabVal);
    setPostImagesModal((prev) => ({ ...prev, image: "" }));
  };

  const FileUploaderCustomUi = useMemo(() => (
    <div className={`fileUploadBlock position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 image-types">
        Supported formats: jpg, jpeg, png; <br /> maximum file size: 5MB.
      </p>
      {uploadFileError && uploadFileError !== "" ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : null}
    </div>
  ), [uploadFileDragging, uploadFileError]);

  const handleGenerateBackgroundImages = async () => {
    handleOnUploadImage(null, "renderings");
    setIsGenerateBgImages(true);

    const getImageUrl = await handleGetProductImageUrl(uploadFile);
    if (!getImageUrl || getImageUrl === "") {
      return false;
    }

    if (!articleId || !postGeneratedImages.subType || !articleType || !userRes.id) {
      return false;
    }

    if (postImagesModal && postImagesModal.aiImageGenerating) {
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    const payload = {
      body: JSON.stringify({
        type: articleType, subType: postGeneratedImages.subType, articleId: articleId,
        userId: userRes.id, productImage: getImageUrl
      })
    };

    try {
      const response = await invokeLambdaFunction("removeBackGroundAndGenerateBackGroundImg", payload);
      setIsGenerateBgImages(false);
      if (response && response.success === 1 && response.data && response.data.backgroundImages && response.data.backgroundImages.length > 0) {
        if (response.data.backgroundImages[0].backgroundImage && response.data.backgroundImages[0].backgroundImage !== "") {
          const tempObj = { ...postGeneratedImages, ...response.data };
          setPostGeneratedImages(tempObj); refetch();
        } else {
          toast.error("Ai Brand Images are not generated, Please try again.");
        }
      } else {
        toast.error(response?.error || "Something went wrong");
      }
    } catch (error) {
      toast.error("Ai Brand Images are not generated, Please try again.");
      setIsGenerateBgImages(false);
    }
  };

  const handleGetProductImageUrl = (img) => {
    return new Promise((resolve, reject) => {
      if (img && typeof img === 'string' && img.indexOf("https://") !== -1) {
        resolve(img);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
          AxiosApi.post(`/api/product-image-library/save-upload-image`, {
            userId: userRes.id,
            imageBase64: reader.result,
            file_name: img.name,
          }).then((response) => {
            if (response && response.data && response.data.imageUrl && response.data.imageUrl !== "") {
              resolve(response.data.imageUrl);
            } else {
              resolve("");
            }
          }).catch((error) => {
            resolve("");
          });
        };
      }
    });
  };

  const handleOnSelectOrUploadImage = (postImgObj) => {
    if (postGeneratedImages && postGeneratedImages.backgroundImages && postGeneratedImages.backgroundImages.length > 0) {
      handleSaveSelectedImage(postImgObj);
    } else {
      handleOnUploadImage(postImgObj?.image ? postImgObj.image : null, "create");
    }
  }

  return (
    <div className="brandsAi">
      <div className="d-flex header justify-content-between align-items-center gap-3 mb-4">
        <div className="tabsClick">
          {tabes.map(({ name, value }, index) => (
            <button className={classNames({ active: selectedTab === value })} onClick={() => setSelectedTab(value)} key={name + " " + index}>
              {name}
              {name === "Renderings" ? (
                <span className="total-images-circle">
                  <span className="total-images">
                    {postGeneratedImages.backgroundImages && postGeneratedImages.backgroundImages.length > 0 ? (
                      `${postGeneratedImages.backgroundImages.length < 10 ? `0${postGeneratedImages.backgroundImages.length}` : postGeneratedImages.backgroundImages.length}`
                    ) : "00"}
                  </span>
                </span>
              ) : name === "Gallery" ? (
                <span className="total-images-circle">
                  <span className="total-images">
                    {Number(data?.images?.length || 0) + Number(data?.productImageData?.reduce((acc, subArray) => acc + subArray.length, 0) || 0)}
                  </span>
                </span>
              ) : null}
            </button>
          ))}
        </div>
        <div className="selectIamgesB d-flex align-items-center">
          {postImagesModal && postImagesModal.carouselImg && postImagesModal.carouselImg.length > 0 && (
            <button type="button" className="blogButton border-0" onClick={() => handleSaveSelectedImage(postImagesModal)}>
              <FaRegImage /> Upload Images
            </button>
          )}
        </div>
      </div>
      <div className="brandchooseFile ai model pe-2 overflow-auto" style={{ height: "calc(100vh - 275px)" }}>
        {selectedTab === "renderings" ? (
          <React.Fragment>
            {isGenerateBgImages ? (
              <Row>
                <Col sm="12" className="mb-3">
                  <div className="fieldSetText position-relative">
                    <p className="d-flex align-items-center gap-2 m-0 px-3">
                      <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Image Generating
                    </p>
                  </div>
                </Col>
                {[1, 2, 3, 4].map((ele, index) => (
                  <Col sm="6" key={index}>
                    <Placeholder as="p" animation="glow" className="overflow-hidden rounded-3 mb-3" style={{ height: "300px" }}>
                      <Placeholder xs={12} className="h-100" />
                    </Placeholder>
                  </Col>
                ))}
              </Row>
            ) : (
              <React.Fragment>
                {postGeneratedImages && postGeneratedImages.backgroundImages && postGeneratedImages.backgroundImages.length > 0 ? (
                  <React.Fragment>
                    <div className="fieldSetText position-relative">
                      <p className="d-flex align-items-center gap-2 m-0 px-3">
                        <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Generated Images
                      </p>
                    </div>
                    <div className="genrateImagesCards d-flex flex-wrap">
                      {postGeneratedImages.backgroundImages.map((element, index) => element.backgroundImage && element.backgroundImage !== "" && (
                        <ImageDisplayContent
                          image={element.backgroundImage} key={index} viewImgHistory={postGeneratedImages.backgroundImages}
                          postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} imageIndex={index}
                          handleSaveSelectedImage={handleSaveSelectedImage}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                ) : (
                  <NoDataAvailable text="Images not available yet!" />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : selectedTab === "gallery" ? (
          <React.Fragment>
            {data && data.productImageData && data.productImageData.length > 0 && (
              <React.Fragment>
                <div className="fieldSetText position-relative">
                  <p className="d-flex align-items-center gap-2 m-0 px-3">
                    <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Generated Ai Brand Images
                  </p>
                </div>
                {data.productImageData.map((images, index1) => (
                  <React.Fragment>
                    <div className="genrateImagesCards d-flex flex-wrap">
                      {images && images.length > 0 && images.map((image, index2) => (
                        <ImageDisplayContent
                          image={image} key={index2} viewImgHistory={images} imageIndex={index2}
                          postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                          handleSaveSelectedImage={handleOnSelectOrUploadImage}
                        />
                      ))}
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
            {data && data.images && data.images.length > 0 && (
              <React.Fragment>
                <div className="fieldSetText position-relative">
                  <p className="d-flex align-items-center gap-2 m-0 px-3">
                    <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Uploaded Images
                  </p>
                </div>
                <div className="genrateImagesCards d-flex flex-wrap mb-4">
                  {data.images.map((image, index) => (
                    <ImageDisplayContent
                      key={image + "" + index} image={image} imageIndex={index} viewImgHistory={data?.images}
                      postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                      handleSaveSelectedImage={handleOnSelectOrUploadImage}
                    />
                  ))}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div className="d-flex gap-3 flex-wrap genrateButton_">
            <div className="brandAi-filUploader chooseCard">
              <FileUploader
                name="file" types={mediaFileTypes} maxSize={5} children={FileUploaderCustomUi}
                multiple={false} classes={`w-100`} handleChange={handleOnSelectImageFile}
                onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
                disabled={postGeneratedImages.backgroundImages && postGeneratedImages.backgroundImages.length > 0 ? true : false}
              />
            </div>
            {uploadFile ? (
              <div className="image-preview-content chooseCard position-relative">
                <img
                  alt="Uploaded Img" className="w-100 bg-dark"
                  src={uploadFile && uploadFile.name ? URL.createObjectURL(uploadFile) : uploadFile}
                />
                <div className="remove-btn">
                  <button className="btn" type="button" onClick={(e) => handleOnUploadImage(null, "create")}>
                    <BsTrashFill />
                  </button>
                </div>
              </div>
            ) : (
              <div className="image-preview-content chooseCard position-relative d-flex justify-content-center">
                {postGeneratedImages.productImg && postGeneratedImages.productImg !== "" && (
                  <img alt="Uploaded Img" className="" src={postGeneratedImages.productImg} />
                )}
              </div>
            )}

            {postGeneratedImages.backgroundImages && postGeneratedImages.backgroundImages.length > 0 ? (
              <div className="genrateButton_ text-center d-flex justify-content-center align-items-center">
                <button type="button" className="blogButton border-0" onClick={() => setSelectedTab("renderings")}>
                  <BsStars /> <span>Show Images</span>
                </button>
              </div>
            ) : (
              <div className="genrateButton_ text-center d-flex justify-content-center align-items-center">
                <button className="blogButton border-0" onClick={handleGenerateBackgroundImages} disabled={!uploadFile ? true : false}>
                  <BsStars /> Confirm & Generate
                </button>
                <BsInfoCircleFill className="infoIconSvg" data-tooltip-id={"my-tooltip"}
                  data-tooltip-place="bottom" data-tooltip-content={`Generate AI images based on uploaded image`}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandsAIImagery;
