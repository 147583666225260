import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import classNames from "classnames";
import { MenuItem } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowRightLong } from "react-icons/fa6";
import { AiTypeOptions, OonaAiTypeOptions } from "../data";
import { AxiosApi } from "../utility/axios";
import Heading from "../Components/ui/Title";
import { setLoader, setUserRes } from "../store/reducer";
import DropdownMenu from "../Components/ui/Menu";
import TableLoader from "../Components/ui/TableLoader";
import CustomDataTable from "../Components/ui/DataTable";
import { invokeLambdaFunction } from "../utility/invokeLamdaFunction";
import { EditorInitObj, addEditorCustomButtons } from "../utility/hepler";
import { articleHistoryIcons } from "../Assets/Icons";
import { SortArrow, sorting, tableDateFormatter } from "../utility/common";
import { AiOutlineEdit } from "react-icons/ai";
import { BsInfoCircleFill, BsStars } from "react-icons/bs";
import VersionHistory from "../Components/commonComponents/VersionHistory";
import { FaRegSave } from "react-icons/fa";
const beautify_html = require("js-beautify").html;

const AiOverviewWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currStep, setCurrStep] = useState(1);
  const [aiOverviewInfo, setAiOverviewInfo] = useState({});

  const handleGetDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));
      const apiUrl = `/api/article/history-or-details/byType/get-ai-overview-details-byId?articleId=${id}`;
      const res = await AxiosApi.get(apiUrl);
      if (res && res.data && res.data.article && res.data.article !== "") {
        setCurrStep(2); setAiOverviewInfo({ ...res.data, stepDone: 2 });
      } else {
        toast.error("Ai overview content not generated.", { id: "Toast-01" });
      }

      const scrollWrapper = document.getElementById('scroll-wrapper');
      if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleOnChangeStep = (no) => {
    if (aiOverviewInfo?.stepDone && no <= aiOverviewInfo.stepDone) {
      if (no === 1) {
        setAiOverviewInfo({});
        navigate("/ai-overview");
      }
      setCurrStep(no);
    }
  };

  const steps = useMemo(() => [
    <FirstStepAiOverview setCurrStep={setCurrStep} aiOverviewInfo={aiOverviewInfo} setAiOverviewInfo={setAiOverviewInfo} />,
    <SecondStepAiOverview setCurrStep={setCurrStep} aiOverviewInfo={aiOverviewInfo} setAiOverviewInfo={setAiOverviewInfo} />,
  ], [currStep, aiOverviewInfo]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column pt-3">
        <div className="">
          <Heading className="fw-bolder mainHeading" size="3">AI Overview</Heading>
          <p>Create high-converting AI Overview with live links to your website.</p>
        </div>
      </div>
      <div className={`stepWraper mt-4`}>
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <AiOverviewHeader currStep={currStep} aiOverviewInfo={aiOverviewInfo} handleOnChangeStep={handleOnChangeStep} />
          {steps[currStep - 1]}
        </div>
      </div>
      {currStep === 1 && (
        <div className="d-flex gap-2 z-0 flex-column tableHeading mt-4">
          <div className="mt-2">
            <AiOverviewHistory handleGetDetailsById={handleGetDetailsById} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
};

const AiOverviewHeader = ({ currStep, aiOverviewInfo, handleOnChangeStep }) => {
  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        <ul>
          <li
            className={`${currStep === 1 ? "active" : ""} ${1 < currStep ? "complete" : ""} ${1 <= aiOverviewInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(1)} onKeyDown={() => handleOnChangeStep(1)}
          >
            <span className="d-flex justify-content-center align-items-center">01</span>
            <p className="">{"Insert Link"}</p>
          </li>
          <li
            className={`${currStep === 2 ? "active" : ""} ${2 < currStep ? "complete" : ""} ${2 <= aiOverviewInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(2)} onKeyDown={() => handleOnChangeStep(2)}
          >
            <span className="d-flex justify-content-center align-items-center">02</span>
            <p className="">{"Preview"}</p>
          </li>
        </ul>
      </div>
    </div>
  )
};

const FirstStepAiOverview = ({ setCurrStep, aiOverviewInfo, setAiOverviewInfo }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const [linkUrl, setLinkUrl] = useState("");
  const [aiType, setAiType] = useState(userRes.company === "Oona" ? "GPT 4" : "GPT 3.5");
  const TempAiTypeOptions = userRes.company === "Oona" ? [...OonaAiTypeOptions] : [...AiTypeOptions];

  const handleNextStep = async (link) => {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    if (link && link.match(regex)) {
      try {
        dispatch(setLoader(true));
        const payload = { body: JSON.stringify({ userId: userRes?.id, linkUrl: link, aiType, type: "generate-link-to-overview" }) };
        const response = await invokeLambdaFunction("generateArticleOrSaveByType", payload);
        dispatch(setLoader(false));

        if (response && response.success === 1 && response.data && response.data.content) {
          setCurrStep(2); setAiOverviewInfo({
            ...aiOverviewInfo, stepDone: 2, article: response.data.content,
            id: response.data.id, linkUrl: link, regenerateLeft: 3,
          });
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - 1 }));
        } else {
          toast.error(response?.data?.error || "Content not generated, Please try again", { id: "Toast-01" });
        }
      } catch (error) {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
        dispatch(setLoader(false));
      }
    } else {
      toast.error("Link is invalid, please insert a valid link");
    }
  };

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex align-items-center justify-content-between mx-2 mx-md-auto">
        <div><b>Describe URL to generate overview</b></div>
      </div>
      <Row className="genrateFields pb-0">
        <Col sm="12" md="6">
          <div className="form-group fields mb-4">
            <label>Insert URL <span className="required-astrick fs-6 ms-1">*</span></label>
            <div className="inputField">
              <input
                name={`linkUrl`} type="text" placeholder="Example: https://www.addlly.ai" value={linkUrl}
                className={`addllyForm-control w-100`} onChange={(e) => setLinkUrl(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col sm="12" md="6">
          <div className="form-group fields m-0">
            <label>Choose your AI Model</label>
            <Select
              name={"aiType"} placeholder="Select AI Type" isSearchable={false} isClearable={false}
              className={`custom-select cust-blog-se`} classNamePrefix="custom-select"
              options={TempAiTypeOptions} onChange={(e) => setAiType(e.value)}
              value={TempAiTypeOptions.find((c) => c.value === aiType)}
            />
          </div>
        </Col>
      </Row>
      <div className="buttonDiv">
        <Button className="button" onClick={() => handleNextStep(linkUrl)} disabled={linkUrl && linkUrl !== "" ? false : true}>
          Generate Overview <FaArrowRightLong className="ms-2" />
        </Button>
        <BsInfoCircleFill
          className="text-decoration-none outline-0  infoIconSvg " data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
          data-tooltip-content={`Will utilize 1 Addlly credits`}
        />
      </div>
    </div>
  )
};

const AiOverviewHistory = ({ handleGetDetailsById }) => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    getEdmArticleHistory();
  }, []);

  const getEdmArticleHistory = async () => {
    setTableLoader(true); setData([]);
    const url = `/api/article/history-or-details/byType/get-ai-overview-history`;
    AxiosApi.get(url).then((response) => {
      if (response.data && response.data.length > 0) {
        setData(response.data);
      }
      setTableLoader(false);
    }).catch((error) => {
      setTableLoader(false);
    });
  };

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(data, column?.sortField, sortDirection);
    setData(newData);
  };

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Detail {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{row.link_url || "-"}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "link_url",
      },
      {
        id: "status",
        name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => (
          <div className="d-flex justify-content-center align-items-center" onClick={() => handleGetDetailsById(row?.id)}>
            <div className="dotStatus">
              <span
                className={`d-block`}
                style={{
                  width: "8px", height: "8px", borderRadius: "50%",
                  backgroundColor: row.status === "completed" ? "#84CC16" : row.status === "pending" ? "#E7E721" : row.status === "processing" ? "#F59E0B" : "#EF4444",
                }}
              />
            </div>
            {row.status === "completed" ? "Done" : row.status === "pending" ? "Processing ..." : row.status === "processing" ? "In Progress" : "Error"}
          </div>
        ),
        sortable: true,
        sortField: "status",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => handleGetDetailsById(row?.id)}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{tableDateFormatter(row?.created_at)}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "sort",
        name: 'Action',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                <MenuItem eventKey="1" onClick={() => handleGetDetailsById(row?.id)}>
                  <AiOutlineEdit /> Edit
                </MenuItem>
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }
    return tempColumns;
  }, [sortField]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between gap-2 mb-3">
        <span className="mb-2">Recent History</span>
      </div>
      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
          columns={columns} totalCount={data.length || 0} pagination={true} data={data}
          itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
          onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={() => { }} clearSelectedRows={() => { }}
        />
      </div>
    </React.Fragment>
  )
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};

const SecondStepAiOverview = ({ setCurrStep, aiOverviewInfo, setAiOverviewInfo }) => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const articleType = "Ai Overview Writer";
  const articleSubType = "AI Overview";
  const editorRef = useRef(null);

  const [editorKey, setEditorKey] = useState(0);
  const [articleText, setArticleText] = useState(aiOverviewInfo?.article);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});

  useEffect(() => {
    if (aiOverviewInfo && aiOverviewInfo.article) {
      getArticleAllVersionHistory();
      setArticleText(aiOverviewInfo.article || "");
      setEditorKey(editorKey + 1);
    }
  }, [])

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const getArticleAllVersionHistory = async () => {
    try {
      setVersionHistory([]);
      const params = `articleId=${aiOverviewInfo.id}&type=${articleType}&subType=${articleSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    setAiOverviewInfo({ ...aiOverviewInfo, article: text });
    setArticleText(text); setEditorKey(editorKey + 1);
    getArticleAllVersionHistory();
  };

  const handleOnSaveArticleData = async () => {
    setAiOverviewInfo({ ...aiOverviewInfo, article: articleText });
    setArticleText(articleText || ""); setEditorKey(editorKey + 1);

    try {
      dispatch(setLoader(true));
      await AxiosApi.post(`/api/articleHistory/update?tableName=overview_article_history`, {
        id: aiOverviewInfo?.id, currentField: "article", article_html: articleText
      });
      getArticleAllVersionHistory();
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    };
  };

  const handleReGenerateAiOverview = async () => {
    try {
      if (aiOverviewInfo.regenerateLeft === 0) {
        return toast.error("You have already generate 3 times");
      }
      dispatch(setLoader(true));
      const paramsData = {
        userId: userRes?.id, overviewId: aiOverviewInfo.id, type: "generate-link-to-overview",
        isRegenerate: true, feedbackText: feedbackText || "",
      };
      const payload = { body: JSON.stringify(paramsData) };

      const response = await invokeLambdaFunction("generateArticleOrSaveByType", payload);
      dispatch(setLoader(false)); setShowFeedbackModel(false);

      if (response && response.success === 1 && response.data && response.data.content) {
        setArticleText(response.data.content); setEditorKey(editorKey + 1);
        setAiOverviewInfo({
          ...aiOverviewInfo, stepDone: 2, article: response.data.content,
          id: response.data.id, regenerateLeft: response.data.regenerateLeft,
        });
        getArticleAllVersionHistory();
      } else {
        toast.error(response?.data?.error || "Content not generated, Please try again", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      setShowFeedbackModel(false);
      dispatch(setLoader(false));
    }
  };

  return (
    <div className="topicBlockBottom px-4">
      <Row className="eCommerce-blog-wrapper p-0 mb-5" style={{ maxWidth: "none" }}>
        <Col sm="12" md="3" className="border-0 border-md-end p-0 pe-0 pe-md-3">
          <div className="optimizationBlock h-100">
            <div className="cardOptimiz">
              <div className="my-2 d-flex justify-content-between flex-column gap-3">
                <div className="topicBlock mb-2">
                  <span className="d-block mb-1">Inserted URL</span>
                  <a className="fw-normal text-decoration-none" href={aiOverviewInfo?.linkUrl} target="_blank" rel="noreferrer">
                    {aiOverviewInfo?.linkUrl || "-"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="9" className="p-0 pt-3 pt-md-0">
          <div
            className="selectBlock d-flex align-items-center justify-content-between"
            style={{ maxWidth: "calc(100% - 24px)", margin: "0 0 24px auto" }}
          >
            <div><b>AI Overview</b></div>
          </div>
          <div className="blogWriterArea border-0 ">
            <div className="blogWriterBlock p-0" style={{ maxWidth: "none" }}>
              <Row className="ps-4">
                <Col sm="12" className="">
                  <div className="navItemsBlock">
                    <div className={classNames(`navTopButton d-flex justify-content-between flex-wrap gap-4`)}>
                      <div className="leftButtons d-flex gap-3 align-items-center">
                        <VersionHistory
                          versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                          handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                          selectedSubTab={""} setSelectedSubTab={() => { }} articleType={articleType}
                        />
                        <button type="button" className={"blogButton border-0"} disabled={!aiOverviewInfo.regenerateLeft}
                          onClick={() => setShowFeedbackModel(true)}
                        >
                          <BsStars /> Re-Generate ( {aiOverviewInfo.regenerateLeft || 0} / 3 )
                        </button>
                      </div>
                      <div className="rightSideButton d-flex gap-2">
                        <button data-tooltip-id="my-tooltip" data-tooltip-content="Save" data-tooltip-place="bottom"
                          className="blogButton border-0 bg-black saveButton" onClick={handleOnSaveArticleData}
                        >
                          <FaRegSave />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="12" className="editableTextArea d-flex overflow-hidden position-relative h-100">
                  <div className="article-body-wrapper w-100">
                    <div className="position-relative custom-text-editor">
                      <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                        onInit={(evt, editor) => editorRef.current = editor} key={editorKey}
                        value={articleText} onEditorChange={(e) => setArticleText(e)}
                        init={{
                          ...EditorInitObj, height: "auto", plugins: [...EditorInitObj.plugins, "autoresize"],
                          autoresize_bottom_margin: 20, autoresize_min_height: 100, body_class: "body-wrapper",
                          toolbar: "copyContent | " + EditorInitObj.toolbar,
                          setup: function (editor) { addEditorCustomButtons(editor, () => { }, handleCopyHtmlArticleText); },
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      {showFeedbackModel && (
        <Modal size="lg" centered show={showFeedbackModel} onHide={() => setShowFeedbackModel(false)}>
          <Modal.Body className="text-start p-0 overflow-auto">
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => handleReGenerateAiOverview(true)}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
};

export default AiOverviewWriter;
