import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

import { fetchStep4thData } from "../../utility/apiService";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { AxiosApi } from "../../utility/axios";
import { blogCoPilotIcon } from "../../Assets/Icons";
import {
  setSeoOptBlogStep, setLoader, setNoOfClick, setSeoOptimizedSets, setHeadline,
  setSeoOptBlogTopic, setGoogleSuggestKeyword, setOutlineRes
} from "../../store/reducer";
import { trimedInputValue } from "../../utility/hepler";

const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Topic is required").min(2, "Topic must be at least 2 characters").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic not contain multiple consecutive white spaces"),
  suggestKeyword: Yup.string().required("keyword is required").min(2, "keyword must be at least 2 characters").matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "suggested keyword should not contain multiple consecutive white spaces"),
});

const FourthStepSeoOptBlog = () => {
  const dispatch = useDispatch();
  const noOfClick = useSelector(({ noOfClick }) => noOfClick);
  const selectedTopic = useSelector(({ selectedTopic }) => selectedTopic);
  const setsOfSeoOptimized = useSelector(({ setsOfSeoOptimized }) => setsOfSeoOptimized);
  const googleSuggestKeyword = useSelector(({ googleSuggestKeyword }) => googleSuggestKeyword);
  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const keywordTopic = useSelector(({ keywordTopic }) => keywordTopic);
  const headline = useSelector(({ headline }) => headline);

  const [selectedSetOfSeo, setSelectedSetOfSeo] = useState({ headline: "", keyword: "", intent: "", index: -1 });
  const [editSeoOptimizedData, setEditSeoOptimizedData] = useState([]);
  const [intentOptionData, setIntentOptionData] = useState([]);
  const [topSummary, setTopSummary] = useState(setsOfSeoOptimized?.summaryDescription);
  const [isDisabledInput, setIsDisabledInput] = useState(false);

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      topic: selectedTopic,
      suggestKeyword: googleSuggestKeyword,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (setsOfSeoOptimized && setsOfSeoOptimized.seoOptimizedData && setsOfSeoOptimized.seoOptimizedData.length > 0) {
      const seoOptimizedData = [];
      const optionData = [];
      setsOfSeoOptimized.seoOptimizedData.map((item, index) => {
        if (item.headline) {
          seoOptimizedData.push(item);
        }
        if (!optionData.includes(item.intent)) {
          optionData.push(item.intent);
        }

        if (item.isSelected === true) {
          setSelectedSetOfSeo({ ...item, index });
        }
        return item;
      });
      setEditSeoOptimizedData(seoOptimizedData);
      setIntentOptionData(optionData);
    }

    if (headline && Object.keys(headline)?.length > 0 && typeof headline[`Set-1`] !== typeof undefined) {
      setIsDisabledInput(true);
    }
  }, [setsOfSeoOptimized]);

  const onSubmit = async (value) => {
    const data = trimedInputValue(value)
    setSelectedSetOfSeo({ headline: "", keyword: "", intent: "", index: -1 })
    if (noOfClick === 0) {
      return false;
    }

    await dispatch(fetchStep4thData(data.topic, data.suggestKeyword, selectedGoogleWebsite, seoOptBlogTopic.articleId, keywordTopic));
    dispatch(setGoogleSuggestKeyword(data.suggestKeyword));
    dispatch(setNoOfClick(noOfClick - 1));

    AxiosApi.post(`/api/articleHistory/updateRegenerateKeywordCount`, {
      id: seoOptBlogTopic.articleId, noOfClick: noOfClick - 1
    });
  };

  const handleNext = async () => {
    if (headline && Object.keys(headline)?.length > 0 && typeof headline[`Set-1`] !== typeof undefined) {
      dispatch(setSeoOptBlogStep(6));
      return false;
    }

    if (selectedSetOfSeo && selectedSetOfSeo.headline && selectedSetOfSeo.headline !== "") {
      const seoOptimizedDataObj = editSeoOptimizedData.map((item, index) => {
        if (index === selectedSetOfSeo.index) {
          return Object.assign({}, item, { isSelected: true });
        } else {
          const { isSelected, ...itemRest } = item;
          return itemRest;
        }
      });

      dispatch(setLoader(true));
      const headlineStr = selectedSetOfSeo?.headline ? selectedSetOfSeo.headline : selectedTopic;
      const mainKeywordStr = selectedSetOfSeo?.keyword ? selectedSetOfSeo.keyword : googleSuggestKeyword;

      const payload = {
        body: JSON.stringify({
          topic: selectedTopic,
          headline: headlineStr,
          mainKeywords: mainKeywordStr,
          Description: topSummary,
          articleId: seoOptBlogTopic.articleId, seoOptimizedDataObj,
        })
      };

      const response = await invokeLambdaFunction("generateHeadlineSets", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1) {
        if (response.data && response.data.data && typeof response.data.data["Set-1"] !== typeof undefined) {
          dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, stepDone: seoOptBlogTopic.stepDone < 6 ? 6 : seoOptBlogTopic.stepDone }));
          dispatch(setSeoOptimizedSets({
            ...setsOfSeoOptimized, seoOptimizedData: seoOptimizedDataObj,
            headline: headlineStr, keyword: mainKeywordStr,
            summaryDescription: topSummary
          }));
          dispatch(setSeoOptBlogStep(6));
          dispatch(setHeadline(response.data?.data));
          dispatch(setOutlineRes(response.data?.data));
        } else {
          toast.error("Outline sets not generated, Please try again.", { id: "Toast-01" });
        }
      } else {
        toast.error(response?.error, { id: "Toast-01" });
      }
    } else {
      toast.error("Select any one Headline and Keyword to generate outline sets.", { id: "Toast-01" });
    }
  };

  const handleEditSetOfData = (event, index) => {
    const { name, value } = event.target;
    let obj = [...editSeoOptimizedData];
    obj[index] = { ...obj[index], [name]: value };
    setEditSeoOptimizedData(obj);

    if (selectedSetOfSeo && selectedSetOfSeo.index === index) {
      setSelectedSetOfSeo({ ...obj[index], index });
    }
  }

  return (
    <React.Fragment>
      <div className="topicLinks">
        <div className="selectBlock seoselected ms-0 mw-100">
          <h5>SEO Optimization (Please update the heading later)</h5>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="addllyFormWrap">
          <div className="form-group mb-3">
            <label>Your Topic<BsInfoCircleFill className="ms-2 infoIconSvg"
              data-tooltip-id={"my-tooltip"}
              data-tooltip-place="right" data-tooltip-content={`Edit topic to re-generate topic`}
            /> </label>
            <input
              name="topic" type="text" {...register("topic")} disabled={isDisabledInput}
              className={`addllyForm-control bg-white w-100 mw-100 ${errors.topic ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.topic?.message}</div>
          </div>
          <div className="form-group ">
            <label>Keyword (You can insert your own keyword too. Click to edit)</label>
            <div className="d-flex flex-wrap justify-content-between gap-1">
              <input
                name="suggestKeyword" type="text" {...register("suggestKeyword")}
                className={`addllyForm-control addllyForm-control-outline`} disabled={isDisabledInput}
                style={{ minWidth: '100px' }}
              />
              <button type="submit" className="addlly-primary" style={{ margin: 0 }}
                disabled={noOfClick === 0 || isDisabledInput ? true : false}
              >
                Regenerate ({noOfClick} of 3 tries Left)
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="topicBlockBottom">
        <Row>
          <Col md={4}>
            <div className="optimizationBlock h-auto m-0 mb-3">
              <div className="cardOptimiz">
                <div className="topicBlock">
                  <span className="d-block">Topic</span>
                  <h5 className="fw-normal">{selectedTopic}</h5>
                </div>
                <div className="topicBlock">
                  <span className="d-block">Keyword  <BsInfoCircleFill className="ms-2 infoIconSvg"
                    data-tooltip-id={"my-tooltip"}
                    data-tooltip-place="right" data-tooltip-content={`You can edit the Keyword`}
                  /></span>
                  {/* <h5 className="fw-normal">{googleSuggestKeyword}</h5> */}
                  <input type="text" value={googleSuggestKeyword} onChange={(e) => dispatch(setGoogleSuggestKeyword(e.target.value))} disabled={isDisabledInput} />
                </div>
                <div className="topicBlock">
                  <span className="d-flex align-items-center">
                    Summary Google Search results of the topic
                    <BsInfoCircleFill className="ms-2 infoIconSvg"
                      data-tooltip-id={"my-tooltip"}
                      data-tooltip-place="bottom-end" data-tooltip-content={`You can edit the Summery`}
                    />
                  </span>
                  <textarea col="6" value={topSummary} onChange={(e) => setTopSummary(e.target.value)} disabled={isDisabledInput}></textarea>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8} className=" ">
            <div className="optimaizeInput d-flex align-items-center">
              <p>Below are several Headline & Intent sets. Select one set that accurately reflects the desired purpose and message of your article.</p>
            </div>

            <div className="optomizeCardBlock">
              <div div className={classNames("card-header-content")}>
                <div className="first-block">
                  <h6>Intent</h6>
                </div>
                <div className="grayBox"></div>
                <div className="last-block">
                  <h6>Headline</h6>
                </div>
              </div>
            </div>
            {editSeoOptimizedData?.map((item, index) => (
              <div key={index} className="optomizeCardBlock">
                <div div className={classNames("opCard d-flex gap-3 mb-2 align-items-center", { active: selectedSetOfSeo?.headline === item?.headline })}>
                  <div className="radioTextBlock d-flex align-items-center gap-3">
                    <div className="radioType ">
                      <input type="radio"
                        name="checkMark" checked={selectedSetOfSeo?.headline === item?.headline ? true : false}
                        onChange={(e) => { setSelectedSetOfSeo({ ...item, index }); }} disabled={isDisabledInput}
                      />
                    </div>
                    <div className="">
                      <div className="iconB">
                        {(item.intent?.toLowerCase()?.indexOf("transactional") !== -1 || item.intent?.toLowerCase()?.indexOf("transaksional") !== -1 || item.intent?.toLowerCase()?.indexOf("交易性的") !== -1 || item.intent?.toLowerCase()?.indexOf("การทำธุรกรรม") !== -1) ? (
                          <ReactSVG src={blogCoPilotIcon.transaction} useRequestCache />
                        ) : (item.intent?.toLowerCase()?.indexOf("commercial") !== -1 || item.intent?.toLowerCase()?.indexOf("komersial") !== -1 || item.intent?.toLowerCase()?.indexOf("商业的") !== -1 || item.intent?.toLowerCase()?.indexOf("ทางการค้า") !== -1) ? (
                          <ReactSVG src={blogCoPilotIcon.Documents} useRequestCache />
                        ) : (
                          <ReactSVG src={blogCoPilotIcon.bubblesIcon} useRequestCache />
                        )}
                      </div>
                      <p>
                        {(item.intent?.toLowerCase()?.indexOf("transactional") !== -1 || item.intent?.toLowerCase()?.indexOf("transaksional") !== -1 || item.intent?.toLowerCase()?.indexOf("交易性的") !== -1 || item.intent?.toLowerCase()?.indexOf("การทำธุรกรรม") !== -1) ? (
                          "Transactional"
                        ) : (item.intent?.toLowerCase()?.indexOf("commercial") !== -1 || item.intent?.toLowerCase()?.indexOf("komersial") !== -1 || item.intent?.toLowerCase()?.indexOf("商业的") !== -1 || item.intent?.toLowerCase()?.indexOf("ทางการค้า") !== -1) ? (
                          "Commercial Investigation"
                        ) : (
                          "Informative"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="grayBox"></div>
                  <div className="inputBoxField">
                    <DynamicSizeTextArea value={item?.headline} onChange={handleEditSetOfData} index={index} disabled={isDisabledInput} />
                  </div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
        <div className="w-100">
          <div className="nextPrewBtn d-flex justify-content-center gap-3">
            <button
              type="button" className="addlly-outline"
              onClick={() => {
                dispatch(setSeoOptBlogStep(4));
                const scrollWrapper = document.getElementById('scroll-wrapper');
                if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
              }}
            >
              <BsArrowLeft /> <span>Previous</span>
            </button>
            <div className="buttonWinFo d-flex gap-2 align-items-center ">
              <button
                type="button" onClick={() => handleNext()} className="addlly-primary"
                disabled={!selectedSetOfSeo?.headline}
              >
                <span>Optimise for SEO</span> <BsArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FourthStepSeoOptBlog;

const DynamicSizeTextArea = ({ value, onChange, index, disabled = false }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
      textareaRef.current?.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  }, []);

  return (
    <textarea ref={textareaRef} name="headline" value={value} className="w-100"
      onChange={(e) => onChange(e, index)} disabled={disabled}
    />
  )
};
