import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import jwt_decode from "jwt-decode";

import { setGenerateData, setLoader, setUserRes, setUsername } from "../store/reducer";
import Logo from "../Assets/Images/Brand.png";
import "../Assets/scss/authentic.scss";
import { AxiosApi } from "../utility/axios";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import AllPolicyModal from "../Components/Modal/AllPolicyModal";
import { BsArrowRight, BsInfoCircleFill } from "react-icons/bs";
import AuthenticateLeft from "../Components/authenticate/AuthenticateLeft";

const workEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// List of disallowed free email domains
const disallowedDomains = [
  'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com', 'icloud.com', 'zoho.com', 'neo.space', 'brevo.com',
  'proton.me', 'mail.com', 'gmx.com', 'icloud.com', 'yandex.com', 'tutanota.com', 'live.com', 'yopmail.com'
];

const validationSchemaStep1 = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .trim("white-space", "First name should not contain white spaces")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First Name cannot be more than 20 Characters Long")
    .matches(/^[a-zA-Z]+$/, 'First Name should contain only letters'),
  last_name: Yup.string()
    .matches(/^\S*$/, "Last name cannot contain whitespace")
    .trim("white-space", "Last name should not contain white spaces")
    .max(20, "Last Name cannot be more than 20 Characters Long"),
  phone_number: Yup.string().test('phone_number', 'Phone number should contain only numbers and be between 8 and 13 digits.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[0-9]+$/).min(8).max(13).trim("white-space", "Contact should not contain white spaces");
      return schema.isValidSync(value);
    }
    return true;
  }),
});

const validationSchemaStep2 = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required")
    .trim("white-space", "Email should not contain white spaces")
    .test("is-work-email", "Only business emails are allowed", (value) => {
      const domain = value.split('@')[1];
      if (domain && disallowedDomains.includes(domain.toLowerCase())) {
        return false;
      } else if (domain && domain.toLowerCase().indexOf("mail") !== -1) {
        return false;
      }
      return workEmailRegex.test(value);
    }),
  company: Yup.string().optional().trim("white-space", "Company should not contain white spaces"),
});

const validationSchemaStep3 = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters")
    .max(20, "Username must not exceed 20 characters")
    .matches(/^\S*$/, "Username cannot contain whitespace")
    .trim("white-space", "Username should not contain white spaces"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, with Uppercase, Lowercase, Number and Special Case Character"
    ).trim("white-space", "Password should not contain white spaces"),
  checkbox: Yup.bool()
    .oneOf([true], "You must accept the terms and conditions")
});

const defaultValues = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  company: '',
  username: '',
  password: '',
  checkbox: false
}

const SignUp = () => {
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 576px)").matches);

  useEffect(() => {
    window.matchMedia("(min-width: 576px)").addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className="authenticateWrap">
      {matches && (
        <>
          <AuthenticateLeft />
          <SignUpForm />
        </>
      )}
      {!matches && (
        <div className="res-authenticateWrap">
          <img src={Logo} alt="addlly.ai" className="res-logo" />
          <SignUpForm />
          <AuthenticateLeft />
        </div>
      )}
    </div>
  );
};

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector(({ username }) => username);
  const [typePassword, setTypePassword] = useState(true);
  const [startWithFreePlan] = useState(false);

  const [userData, setUserData] = useState({});
  const [otp, setOtp] = useState("");
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [policyModal, setPolicyModal] = useState(null);
  const [step, setStep] = useState(0)
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const handleClose = () => {
    setPolicyModal(null);
  }

  const { register, handleSubmit, getValues, formState: { errors }, setError, clearErrors, reset, watch } = useForm({
    defaultValues,
    resolver: yupResolver(step === 0 ? validationSchemaStep1 : step === 1 ? validationSchemaStep2 : validationSchemaStep3),
  });

  useEffect(() => {
    if (username !== "") {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleSendOtp = async (data, e, isResend = false) => {
    if (step === 0) {
      setStep(pre => pre + 1)
      return
    }
    setButtonIsLoading(true);
    const userNameExist = await handleCheckUsernameExistandContinue();

    if (userNameExist) {
      setButtonIsLoading(false)
      return;
    } else {
      dispatch(setLoader(true));
      setUserData(data);

      const firstName = data?.first_name;
      const lastName = data?.last_name;

      const bodyData = {
        username: data?.username,
        password: data?.password,
        first_name: firstName,
        last_name: lastName,
        email: data?.email,
        phone_number: data?.phone_number,
        company: data?.company,
        isFreePlan: startWithFreePlan,
        isResend: isResend,
      }

      AxiosApi.post(`/user/register`, bodyData).then(async (res) => {
        dispatch(setLoader(false));
        toast.success("OTP sent on email successfully.", { id: "Toast-01" });
        setUserData((prev) => ({ ...prev, userId: res?.data?.data }));
        setIsSendOtp(true);
        setMinutes(1);
        setSeconds(60);
      }).catch((error) => {
        dispatch(setLoader(false));
        setError("username", { type: "manual", message: "Username is already exist, try different username." });
      });
    }
  };

  const verifyOTP = async (data) => {
    dispatch(setLoader(true));
    let res1 = { ip: "0.0.0.0" };
    try {
      const response = await axios.get("https://api.ipify.org?format=json", { timeout: 5000 });
      if (response && response.data) {
        res1 = response.data;
      }
    } catch (error) {
    }

    AxiosApi.post(`/user/verify-otp?type=signUp`, { userId: data?.userId, otp, ip: res1.ip }).then(async (response) => {
      if (response && response?.data && response?.data?.success === 1) {
        toast.success(response?.data?.message, { id: "Toast-01" });
        localStorage.setItem('token', response?.data?.data?.token);
        dispatch(setUserRes(response?.data?.data));
        dispatch(setUsername(data?.username));
        dispatch(setLoader(false));
        setStep(0)
        dispatch(setGenerateData({}));
        localStorage.setItem('isShowInfo', true);
        localStorage.setItem('isCheckDevice', true);

        if (!startWithFreePlan) {
          setTimeout(() => window.location.href = '/plans', 600);
        } else {
          setTimeout(() => window.location.href = '/dashboard', 600);
        }
      } else {
        dispatch(setLoader(false));
        toast.error(response?.data?.message || "", { id: "Toast-01" });
      }
    }).catch((error) => {
      console.log(error, "error");
      dispatch(setLoader(false));
      const errorMsg = error?.response?.data?.error?.message || error?.response?.data?.error || "Wrong username or password!";
      toast.error(errorMsg, { id: "Toast-01" });
    });
  }

  const responseGoogle = (response) => {
    const decoded = jwt_decode(response.credential);
    handleSendOtp({
      username: decoded?.sub,
      password: decoded?.sub,
      first_name: decoded?.given_name,
      last_name: decoded?.family_name,
      full_name: `${decoded?.given_name} ${decoded?.family_name}`,
      email: decoded?.email,
    });
  };

  const responseFailGoogle = (response) => {
    console.log(response, "response Fail");
  };

  const handleCheckEmailExistandContinue = async (e) => {
    const data = {
      username: getValues("username") || "",
      email: getValues("email") || "",
      first_name: getValues("first_name") || "",
      last_name: getValues("last_name") || "",
    };

    if (!workEmailRegex.test(data.email)) {
      setError("email", { type: "manual", message: "Must be a valid email" });
      return false;
    }
    setButtonIsLoading(true);
    const response = await AxiosApi.post(`/api/user/check-name-email`, data);
    if (response.data.mailExist) {
      setError("email", { type: "manual", message: "Email is already exist, try different email." });
      setButtonIsLoading(false);
    } else {
      if (!errors.email) {
        clearErrors("email");
        setButtonIsLoading(false);
        setStep(prev => prev + 1);
      }
    }
  }

  const handleCheckUsernameExistandContinue = async () => {
    const data = {
      username: getValues("username") || "",
      email: getValues("email") || "",
      first_name: getValues("first_name") || "",
      last_name: getValues("last_name") || "",
    };

    const response = await AxiosApi.post(`/api/user/check-name-email`, data);
    if (response && response.data) {
      if (response.data.userNameExist) {
        setError("username", { type: "manual", message: "Username is already exist, try different username." });
        return true;
      } else {
        if (!errors.username) {
          clearErrors("username");
          return false;
        }
      }
    }
  }

  const handleStepChange = (newStep) => {
    if (step >= newStep) {
      setStep(newStep);
      reset(getValues());
    }
  };

  return (
    <div className="rightPart maingenrateBlock">
      {isSendOtp ? (
        <div className="genrateFields w-50">
          <Row>
            <Col xl={12}>
              <div className="fields" >
                <label className=" color-light-text p-0">OTP</label>
                <div className="inputField">
                  <input
                    name="otp" placeholder="Enter OTP" type={"text"}
                    value={otp} onChange={(e) => setOtp(e.target.value)}
                  />
                  <div className="invalid">{errors.otp?.message}</div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="countdown-text d-flex align-items-center justify-content-between">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <p>Didn't receive code?</p>
            )}

            <button
              className="btn p-0 border-0" disabled={seconds > 0 || minutes > 0}
              onClick={() => seconds === 0 && minutes === 0 ? handleSendOtp(userData, '', true) : null}
              style={{ color: seconds > 0 || minutes > 0 ? "#A5A5A5" : "#FF5630" }}
            >
              Resend OTP
            </button>
          </div>

          <div className="authenticate-button  d-flex align-items-center">
            <button type="button" className="w-100 justify-content-center mt-4" disabled={!otp} onClick={() => verifyOTP(userData)}>Submit OTP</button>
          </div>
        </div>
      ) : (
        <div className="innerFields">
          {step === 0 ? (
            <div className="form-heading">
              <h4>Get started for free!</h4>
              <span>No credit card required!</span>
            </div>
          ) : step === 1 ? (
            <div className="form-heading">
              <h4>Hello, {getValues('first_name')}</h4>
              <span>It was nice meeting you</span>
            </div>
          ) : (
            <div className="form-heading">
              <h4>Almost there!</h4>
              <span>Awesome, Let’s create an account for you</span>
            </div>
          )}

          <div className="tabs">
            <ul className="d-flex align-items-center gap-2">
              {[1, 2, 3].map((num) => (
                <li key={num} onClick={() => handleStepChange(num - 1)} className={`cursor-pointer ${step >= num - 1 ? 'active' : ''}`}>
                  {num}. {num === 3 ? 'Create Account' : num === 2 ? 'Company Details' : 'Personal Details'}
                </li>
              ))}
            </ul>
          </div>

          <form onSubmit={handleSubmit(handleSendOtp)} className="w-100">
            <div className="genrateFields">
              {step === 0 ? (
                <Row className="m-0">
                  <Col xl={6} className="pe-2 p-0 ">
                    <div className="fields"  >
                      <label className=" color-light-text p-0">First Name <sup>*</sup></label>
                      <div className="inputField">
                        <input
                          autoComplete="false" type="text" {...register("first_name")}
                          className={`w-full ${errors.first_name ? "is-invalid" : ""}`}
                          placeholder="Enter first name"
                        />
                        <div className="invalid">{errors.first_name?.message}</div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} className="ps-2 p-0 ">
                    <div className="fields"  >
                      <label className=" color-light-text p-0">Last Name</label>
                      <div className=" inputField">
                        <input
                          autoComplete="false" type="text"  {...register("last_name")}
                          className={`w-full ${errors.last_name ? "is-invalid" : ""}`}
                          placeholder="Enter last name"
                        />
                        <div className="invalid">{errors.last_name?.message}</div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12} className="p-0 mt-3">
                    <div className="fields"  >
                      <label className=" color-light-text p-0">Phone Number </label>
                      <div className=" inputField">
                        <input
                          type="text" {...register("phone_number")}
                          className={`w-full ${errors.phone_number ? "is-invalid" : ""}`}
                          autoComplete="false" placeholder="Enter phone number"
                        />
                        <div className="invalid">{errors.phone_number?.message}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : step === 1 ? (
                <Row className="m-0">
                  <Col xl={12} className=" p-0 ">
                    <div className="fields"  >
                      <label className=" color-light-text p-0">Business Email <sup>*</sup></label>
                      <BsInfoCircleFill className="infoIconSvg"
                        data-tooltip-id={"my-tooltip"}
                        data-tooltip-place="right" data-tooltip-content={`Please enter an email address that includes your business's custom domain name`} />
                      <div className="iconFormGrp inputField">
                        <input
                          autoComplete="false" type="text"  {...register("email")}
                          className={`${errors.email ? "is-invalid" : ""}`}
                          placeholder="Enter business email"
                        />
                        <div className="invalid">{errors.email?.message}</div>
                      </div>
                    </div>

                  </Col>
                  <Col xl={12} className="p-0 mt-3">
                    <div className="fields"  >
                      <label className=" color-light-text p-0">Company Name</label>
                      <div className="iconFormGrp inputField">
                        <input
                          autoComplete="false" type="text" {...register("company")}
                          className={`${errors.company ? "is-invalid" : ""}`}
                          placeholder="Enter company name"
                        />
                        <div className="invalid">{errors.company?.message}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="m-0">
                  <Col xl={12} className="p-0">
                    <div className="fields">
                      <label className=" color-light-text ">User Name <sup>*</sup></label>
                      <div className="iconFormGrp inputField">
                        <input
                          autoComplete="false" name="username" type="text"  {...register("username")}
                          className={`${errors.username ? "is-invalid" : ""}`}
                          placeholder="Enter username"
                        />
                        <div className="invalid">{errors.username?.message}</div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12} className="p-0">
                    <div className="fields">
                      <label className=" color-light-text ">Password <sup>*</sup></label>
                      <div className="iconFormGrp inputField">
                        <input
                          autoComplete="false" {...register("password")}
                          className={`${errors.password ? "is-invalid" : ""}`}
                          type={typePassword ? "password" : "text"}
                          placeholder="Enter password"
                        />
                        {!typePassword ? (
                          <AiOutlineEyeInvisible size={16} color="#50506A" type="button" onClick={() => setTypePassword(!typePassword)} />
                        ) : (
                          <AiOutlineEye size={16} color="#50506A" type="button" onClick={() => setTypePassword(!typePassword)} />
                        )}
                      </div>
                      <div className="invalid">{errors.password?.message}</div>
                    </div>
                    <div className="d-flex terms-and-condition">
                      <input {...register('checkbox')} type="checkbox" />
                      <span>
                        By creating an account, you agree to Addlly.ai's <Link onClick={(e) => { e.preventDefault(); setPolicyModal("privacyPolicy"); }}
                          onKeyDown={(e) => { e.preventDefault(); setPolicyModal("privacyPolicy"); }}>Terms,<br />
                          Privacy Policy</Link> and <Link onClick={(e) => { e.preventDefault(); setPolicyModal("dataProcessingAgreement"); }}
                            onKeyDown={(e) => { e.preventDefault(); setPolicyModal("dataProcessingAgreement"); }} >Data Processing Agreement</Link>
                      </span>
                    </div>
                    <div className="invalid">{errors.checkbox?.message}</div>
                  </Col>
                </Row>
              )}
            </div>
            <div className="authenticate-button  d-flex align-items-center">
              {buttonIsLoading ? (
                <button type="button" className="w-100 justify-content-center mt-4" disabled={true}>
                  Loading...
                </button>
              ) : step === 0 ? (
                <button type="submit" className="w-100 justify-content-center mt-4" disabled={!watch('first_name')}>
                  Continue <BsArrowRight />
                </button>
              ) : step === 1 ? (
                <button type="button" className="w-100 justify-content-center mt-4" disabled={!watch('email')}
                  onClick={handleCheckEmailExistandContinue}>
                  Continue <BsArrowRight />
                </button>
              ) : (
                <button type="submit" className="w-100 justify-content-center mt-4" disabled={!watch('username') || !watch('password')}
                >
                  Get started for free <BsArrowRight />
                </button>
              )}
            </div>
            <span className="d-block text-center redirect-login">Already have an account? <Link to={"/"}>Login</Link></span>
          </form>
        </div>
      )}

      {policyModal && policyModal !== "" && (
        <AllPolicyModal type={policyModal} handleClose={handleClose} />
      )}
    </div>
  );
};

export default SignUp;
