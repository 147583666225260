import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { BiRefresh } from "react-icons/bi";

import RecentTrends from "../Components/RecentTrends";
import Realtime_SEO from "../Assets/Images/SEO_Blog_Writer/Realtime_SEO.png";
import Smart_Writer from "../Assets/Images/SEO_Blog_Writer/Smart_Writer.png";
import { resetSEOFLow, setSeoOptBlogStep, setSmartWriteStep } from "../store/reducer";

const TopResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="main-smart-ai-writer ">
      <div className="d-sm-flex justify-content-between align-items-center mb-4">
        <h2 className="mb-sm-0 mb-3">SEO Blog Writer</h2>
        <div className="d-flex justify-content-end">
          <button className="light-purpal-btn ms-sm-3 ms-0">
            <BiRefresh size={20} /> <h6 className="ms-1 "> Reinitiate the Tour</h6>
          </button>
        </div>
      </div>
      <div className="smart-ai-writer">
        <Row className="mobile-row justify-content-center">
          <Col md={6} sm={6} className="mobile-col">
            <button
              onClick={() => {
                dispatch(setSmartWriteStep(1));
                navigate("/1-click-blog");
              }}
              className="h-100 light-border-1 border-radius-12 w-100 bg-white"
            >
              <img src={Smart_Writer} alt="Smart_Writer" />
              <h3 className="fw-semibold mb-0">Smart Writer</h3>
              <h4 className="font-weight-500 mb-0 mt-3">
                Super fast seo optimised blog, meta tag, description & faqs
              </h4>
              <h5 className="color-light-text fw-normal mt-12 mb-0">
                Use this if you know the topic you want to write about
              </h5>
            </button>
          </Col>
          <Col md={6} sm={6} className="mobile-col">
            <button
              onClick={() => {
                dispatch(resetSEOFLow());
                dispatch(setSeoOptBlogStep(1));
                navigate("/blog-co-pilot");
              }}
              className="h-100 light-border-1 border-radius-12 w-100 bg-white"
            >
              <img src={Realtime_SEO} alt="Realtime_SEO" />
              <h3 className="fw-semibold mb-0">Realtime SEO Advanced Writer</h3>
              <h4 className="font-weight-500 mb-0 mt-3">Use our seo flow helper to customise each step</h4>
              <h5 className="color-light-text fw-normal mt-12 mb-0">
                Uses real-time data to generate an SEO-optimized outline and blog post, ensuring that your content
                ranks higher on search engines
              </h5>
            </button>
          </Col>
        </Row>
      </div>
      <RecentTrends />
    </div>
  );
};

export default TopResults;
