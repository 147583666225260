import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosApi } from "../../utility/axios";
import { getHistory } from "../../utility/apiService";
import Heading from "../../Components/ui/Title";
import { setGeneratedFullArticle, setLoader, setTopResultsStep } from "../../store/reducer";
import FirstStepPdfArticleWriter from "../Components/PdfArticleWriter/FirstStepPdfArticleWriter";
import SecondStepPdfArticleWriter from "../Components/PdfArticleWriter/SecondStepPdfArticleWriter";
import FirstStepLink from "../Components/PdfArticleWriter/FirstStepLink";

const PdfToArticleWriter = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);
  const userRes = useSelector(({ userRes }) => userRes);

  useEffect(() => {
    if (userRes?.id && !id) {
      dispatch(getHistory(userRes?.id, 10, 0, "artical_history", "PDF"));
      dispatch(setTopResultsStep(1));
      dispatch(setGeneratedFullArticle({}));
    }
  }, [id]);

  useEffect(() => {
    if (userRes?.id && id) {
      dispatch(setLoader(true));
      dispatch(setGeneratedFullArticle({}));
      AxiosApi.get(`/api/articleHistory/byId/${id}`).then((res) => {
        const item = res.data && res.data.data ? res.data.data : {};
        dispatch(setGeneratedFullArticle({
          ...item, articleId: item?.id, FAQschema: item?.FAQschema?.replace(/(\r\n|\n|\r)/gm, ""),
          top10WebRes: item?.search_response ? JSON.parse(item?.search_response) : [],
          SEO_score_long_article: item?.SEO_score_long_article ? JSON.parse(item?.SEO_score_long_article).data : "",
          popular_hashtags: item?.popular_hashtags ? JSON.parse(item.popular_hashtags) : [],
          article_html_chart: item?.article_html_chart ? JSON.parse(item?.article_html_chart)?.data : null,
        }));
        dispatch(setTopResultsStep(item.status === "completed" ? 3 : 2));
        dispatch(setLoader(false));
      }).catch((e) => {
        dispatch(setLoader(false));
      });
    }
  }, []);

  return (
    <React.Fragment>
      {topResultsStep === 1 && (
        <React.Fragment>
          <div className=" d-flex flex-column gap-3 pt-4">
            <div className="heading">
              <Heading className="fw-bolder mainHeading" size="3">PDF-to-Article Quick Writer</Heading>
              <p>Transform PDFs to concise articles with the latest insights</p>
            </div>
          </div>
          <FirstStepPdfArticleWriter />
        </React.Fragment>
      )}
      {topResultsStep === 3 && <SecondStepPdfArticleWriter />}
      {topResultsStep === 2 && (
        <React.Fragment>
          <div className=" d-flex flex-column gap-3 pt-4">
            <div className="heading">
              <Heading className="fw-bolder mainHeading" size="3">PDF-to-Article Quick Writer</Heading>
              <p>Transform PDFs to concise articles with the latest insights</p>
            </div>
          </div>
          <FirstStepLink />
        </React.Fragment>
      )}
    </React.Fragment >
  );
};

export default PdfToArticleWriter;
