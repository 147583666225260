import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, setUserPlan } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { headerIcons } from "../Assets/Icons";

const HeaderMenu = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const userRes = useSelector(({ userRes }) => userRes);
  const userPlan = useSelector(({ userPlan }) => userPlan);

  const handleCheckSubscription = async () => {
    try {
      const res = await AxiosApi.get(`/user/stripe/get-subscription/${userRes.id}`);
      if (res.status === 200) {
        dispatch(setUserPlan(res?.data?.data));
        if (res?.data && res?.data?.planInfo) {
          dispatch(setCurrentPlan(res.data.planInfo));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userRes?.id && (!userPlan || userPlan.length === 0)) {
      if (userRes.hasOwnProperty("current_plan") && userRes.current_plan === "Enterprise Pack") {
      } else {
        handleCheckSubscription();
      }
    }
  }, []);

  return (
    <React.Fragment>
      {pathname.includes("dashboard") && (!userPlan || userPlan.length === 0) && userRes.current_plan !== "Enterprise Pack" && userRes.company !== "CMI" && (
        <div className="header-menu align-items-center d-flex mt-0">
          <div className="d-flex align-items-center justify-content-end main-header w-100 justify-content-center pt-0">
            <div className="notificationBlock d-flex align-items-center justify-content-center rounded-pill">
              <div className="notifiText">
                <p className="m-0 text-white">
                  Your free trial is expiring soon. Hurry up, upgrade and avail great benefits.
                </p>
              </div>
              <div className="updateBtn">
                <Link to="/plans" className="text-white d-block text-decoration-none gap-2 d-flex align-items-center fw-bold">
                  Upgrade Now <img src={headerIcons.arrowIcon} alt="arrowIcon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HeaderMenu;
