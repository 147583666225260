import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setECommerceBlogStep } from "../../store/reducer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const ECommerceBlogHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eCommerceBlogStep = useSelector(({ eCommerceBlogStep }) => eCommerceBlogStep);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState(false);

  const stepArr = [
    { "step": 1, "label": "Select Products" },
    { "step": 2, "label": "Select Trends" },
    { "step": 3, "label": "Topic Optimization" },
    { "step": 4, "label": "Final Article" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 425);
    };

    setTimeout(() => {
      handleResize();
    }, 1000)

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    function mobileViewUpdate() {
      const viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  const handleOnChangeStep = (no) => {
    if (no >= 1 && no <= 4) {
      if (eCommerceBlogInfo?.stepDone && no <= eCommerceBlogInfo.stepDone) {
        dispatch(setECommerceBlogStep(no));
      }
    }
  }

  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        {isMobileHead ? (
          <ul>
            <li
              className={`${eCommerceBlogStep === stepArr[eCommerceBlogStep - 1].step ? "" : ""} ${stepArr[eCommerceBlogStep - 1].step < eCommerceBlogStep ? "complete" : ""} ${stepArr[eCommerceBlogStep - 1].step <= eCommerceBlogInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[eCommerceBlogStep - 1].step - 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleLeft />
              </span>
              <p>Previous</p>
            </li>
            <li
              className={`${eCommerceBlogStep === stepArr[eCommerceBlogStep - 1].step ? "active" : ""} ${stepArr[eCommerceBlogStep - 1].step < eCommerceBlogStep ? "complete" : ""} ${stepArr[eCommerceBlogStep - 1].step <= eCommerceBlogInfo?.stepDone ? "is-clickable" : ""}`}
            >
              <span className="d-flex justify-content-center align-items-center">
                {stepArr[eCommerceBlogStep - 1].step > 4 ? `0${stepArr[eCommerceBlogStep - 1].step - 1}` : `0${stepArr[eCommerceBlogStep - 1].step}`}
              </span>
              <p>{stepArr[eCommerceBlogStep - 1].label}</p>
            </li>
            <li
              className={`${eCommerceBlogStep === stepArr[eCommerceBlogStep - 1].step ? "" : ""} ${stepArr[eCommerceBlogStep - 1].step < eCommerceBlogStep ? "complete" : ""} ${stepArr[eCommerceBlogStep - 1].step <= eCommerceBlogInfo?.stepDone ? "is-clickable" : ""}`}
              onClick={() => handleOnChangeStep(stepArr[eCommerceBlogStep - 1].step + 1)}
            >
              <span className="d-flex justify-content-center align-items-center">
                <FaAngleRight />
              </span>
              <p className={``}>Next</p>
            </li>
          </ul>
        ) : (
          <ul>
            {stepArr && stepArr.map((step, index) => (
              <li
                className={`${eCommerceBlogStep === step.step ? "active" : ""} ${step.step < eCommerceBlogStep ? "complete" : ""} ${step.step <= eCommerceBlogInfo?.stepDone ? "is-clickable" : ""}`}
                onClick={() => handleOnChangeStep(step.step)} onKeyDown={() => handleOnChangeStep(step.step)} key={index}
              >
                <span className="d-flex justify-content-center align-items-center">
                  {step.step > 4 ? `0${step.step - 1}` : `0${step.step}`}
                </span>
                <p className={``}>{step.label}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ECommerceBlogHeader;
