import React, { useEffect, useState } from "react";
import Swal from "sweetalert";
import toast from "react-hot-toast";
import HtmlDiff from "htmldiff-js";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsClockHistory } from "react-icons/bs";
import { PiArrowCounterClockwiseLight } from "react-icons/pi";
import { AxiosApi } from "../../../utility/axios";
import { sorting } from "../../../utility/common";
import { setLoader } from "../../../store/reducer";
import { copyIcon } from "../../../Assets/Icons";

const VersionHistoryComponent = (props) => {
  const { articleId, articleType, articleSubType, editorText, setEditorText, oldArticleText, userRes, handleCopyHtmlArticleText } = props;

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFullScreen = searchParams.get("full-screen");
  const selectedTab = searchParams.get("tab") || "reviewArticle";
  const subTab = searchParams.get("subTab") || "comments";

  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [versionHistory, setVersionHistory] = useState([]);
  const [versionsHtml, setVersionsHtml] = useState("");

  useEffect(() => {
    if (subTab && subTab === "version") {
      handleGetAllVersionHistory();
    } else {
      setSelectedVersionItem({});
      setVersionHistory([]);
      setVersionsHtml("");
    }
  }, [subTab])

  const handleGetAllVersionHistory = () => {
    setVersionHistory([]);
    dispatch(setLoader(true));

    const queryParams = `articleType=${articleType}&articleSubType=${articleSubType}`;
    const url = `/api/editorial-version/history/byArticle/${articleId}?${queryParams}`;
    AxiosApi.get(url).then((response) => {
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setVersionHistory(response.data.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  };

  const removeSpanTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const spans = doc.querySelectorAll("span");
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return Promise.resolve(doc.body.innerHTML);
  };

  const handleSelectedCurrentVersionItem = async (version) => {
    setSelectedVersionItem(version);
    if (selectedVersionItem.id === version.id) {
      return false;
    }

    const oldHtmlString = await removeSpanTags(version.old_article);
    const newHtmlString = await removeSpanTags(version.new_article);

    let modifiedHTML = await HtmlDiff.execute(oldHtmlString, newHtmlString);
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">&nbsp;</ins>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">`, `<ins style="color: #00CC00; background-color: #e1f5e1; text-decoration: none;">`);
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">&nbsp;</ins>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">`, `<ins style="color: #00CC00; background-color: #e1f5e1; text-decoration: none;">`);

    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">&nbsp;</del>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">`, `<del style="color: #FF0000; background-color: #fbe1e1; text-decoration: line-through;">`);
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">&nbsp;</del>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">`, `<del style="color: #FF0000; background-color: #fbe1e1; text-decoration: line-through;">`);

    setVersionsHtml(modifiedHTML);
  };

  const handleRestoreVersionAndSetText = async (item) => {
    if (item.is_restored === 1) {
      Swal("Oops...", "Unable to restore version. The requested version has already been restored.", "error");
      return false;
    }

    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, restore it!" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));

          let modifiedEditorText = editorText;
          if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
            const parser = new DOMParser();
            const bodyText = parser.parseFromString(modifiedEditorText, "text/html").body.outerHTML;
            modifiedEditorText = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
              .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
          }

          const dataObj = {
            userName: `${userRes.first_name || ""} ${userRes.last_name || ""}`,
            articleType: articleType,
            articleSubType: articleSubType,
            articleId: articleId,
            oldArticle: modifiedEditorText,
            newArticle: item.old_article,
            isRestore: true,
            restoreVersion: item.name,
          };
          await AxiosApi.post("/api/editorial-version/save-text/add-version", dataObj);
          Swal("Restored!", "Version has been restored.", "success");
          dispatch(setLoader(false));
          setEditorText(item.old_article)
          handleGetAllVersionHistory();
        } catch (error) {
          toast.error("Something went wrong.");
          dispatch(setLoader(false));
        }
      }
    });
  };

  const handleCountChanges = (version) => {
    let count = 0
    const newParagraph = version?.new_article || "";
    const oldParagraph = version?.old_article || "";
    const newWords = newParagraph.split(" ");
    const oldWords = oldParagraph.split(" ");
    newWords.forEach(word => {
      if (!oldWords.includes(word)) {
        count++;
      }
    });
    return count;
  };

  const getCurrentVersion = (versionName) => {
    const newVersionHistory = [...versionHistory];
    const sortedValue = sorting(newVersionHistory, "name", "desc");
    if (sortedValue[0]?.name === versionName) {
      return <IoCheckmarkCircle data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Current version`} />
    };
  };

  const previousVersion = (selectedVersion) => {
    const currentIndex = versionHistory.findIndex(item => item.name === selectedVersion.name);
    if (currentIndex !== -1) {
      const previousIndex = currentIndex + 1;
      if (previousIndex <= versionHistory.length) {
        const previousItem = versionHistory[previousIndex];
        return previousItem?.name !== undefined ? previousItem?.name : 1;
      }
    }
  };

  const handleCloseVersionPopup = () => {
    setSelectedVersionItem({}); setVersionHistory([]); setVersionsHtml("");
    setSearchParams(isFullScreen ? "tab=" + selectedTab + "&subTab=comments&full-screen=" + isFullScreen : "tab=" + selectedTab + "&subTab=comments");
  };

  return (
    <div className="unselectable">
      <div className="verionBlock">
        <div className="offcanvas offcanvas-end " tabIndex="-1" id="commentVersionHistory" aria-labelledby="versionHistoryLabel">
          {selectedVersionItem?.id ? (
            <div className={classNames("selectedPopup", { activeArrow: selectedVersionItem?.id ? true : false })}>
              <div className="versionHeader d-flex justify-content-between align-items-center">
                <div className="leftButton d-flex gap-3 align-items-center">
                  <span>Version {selectedVersionItem?.name}</span>
                  <button className="blogButton changes-btn border-0">
                    {handleCountChanges(selectedVersionItem) < 10 ? "0" + handleCountChanges(selectedVersionItem) : handleCountChanges(selectedVersionItem)} changes
                  </button>
                  <BsInfoCircleFill
                    data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                    data-tooltip-content={`${handleCountChanges(selectedVersionItem) < 10 ? "0" + handleCountChanges(selectedVersionItem) : handleCountChanges(selectedVersionItem)} changes compare to version ${previousVersion(selectedVersionItem)}`}
                  />
                </div>
                <div className="rightButton d-flex gap-3 align-items-center">
                  <button
                    className="copyBtn" type='button' onClick={() => handleCopyHtmlArticleText(selectedVersionItem?.new_article)}
                    data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`Copy`}
                  >
                    <img src={copyIcon} alt='copyImage' />
                  </button>
                  <button
                    className="blogButton border-0" onClick={() => handleRestoreVersionAndSetText(selectedVersionItem)}
                    data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`Restore to the previous version`}
                  >
                    <PiArrowCounterClockwiseLight /> Restore
                  </button>
                  <button
                    type="button" className="btn-close text-reset"
                    onClick={() => { setSelectedVersionItem({}); }}
                  ></button>
                </div>
              </div>
              <div className="versionData position-relative">
                <div className="textarea-article-html rounded p-3" dangerouslySetInnerHTML={{ __html: versionsHtml }}></div>
              </div>
            </div>
          ) : null}
          <div className="verpopup py-4">
            <div className="offcanvas-header pb-4 mb-4">
              <h5 className="offcanvas-title d-flex align-items-center gap-2" id="versionHistoryLabel">
                <BsClockHistory /> Version History
              </h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleCloseVersionPopup}></button>
            </div>
            <div className="offcanvas-body">
              {versionHistory && versionHistory.length > 0 ? (
                <div className="versionNo" style={{ height: "calc(100vh - 130px)" }}>
                  {versionHistory.map((version, index) => (
                    <div key={version?.id + " " + index} className={classNames("versionCards mb-3", { active: selectedVersionItem?.id === version?.id })}>
                      <div className="headerV d-flex justify-content-between align-items-center">
                        <div className="currentVersion d-flex align-items-center">
                          {getCurrentVersion(version?.name)} <h4>Version {version?.name}</h4>
                        </div>
                        <div onClick={() => handleSelectedCurrentVersionItem(version)} className="viewIcon">
                          <FaEye data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View version`} />
                        </div>
                      </div>
                      <div className="versionDetails">
                        <div className="info d-grid align-items-center">
                          <p className="fst-italic">Created On</p>
                          <span className="text-black text-start d-block position-relative fw-normal">
                            {new Date(version?.created_at).toLocaleString()?.replace(",", " ")}
                          </span>
                        </div>
                        <div className="info d-grid align-items-center">
                          <p className="fst-italic">Created By</p>
                          <span className="text-black text-start d-block position-relative fw-normal">{version?.user_name}</span>
                        </div>
                        <div className="info d-grid align-items-center">
                          <p className="fst-italic"> Total Differences
                            <BsInfoCircleFill className="infoIconSvg"
                              data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                              data-tooltip-content={`${handleCountChanges(version) < 10 ? "0" + handleCountChanges(version) : handleCountChanges(version)} changes compare to version ${versionHistory[index + 1]?.name !== undefined ? versionHistory[index + 1]?.name : 1}`}
                            />
                          </p>
                          <span className="text-black  text-start d-block position-relative fw-normal">{handleCountChanges(version)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="d-flex align-items-center justify-content-center border p-5 rounded">
                  Version history is not available yet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VersionHistoryComponent;
