import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { sorting } from "../utility/common";
import { deleteOrArchiveArticle, eCommerceHistoryData } from "../utility/apiService";
import { AxiosApi } from "../utility/axios";
import { toasterSuccess } from "../Components/ui/toaster";

const withECommerceHandler = (WrappedComponent) => {
    const WithECommerceHandler = (props) => {
        const { pathname } = useLocation()
        const userRes = useSelector(({ userRes }) => userRes);
        const filtersType = useMemo(() => ({
            categories: [], durations: [], status: [], AIModels: [], users: [],
            isCustomDate: false, customDate: { startDate: '', endDate: '' }
        }), []);

        const [historyData, setHistoryData] = useState({ data: [], totalCount: 0 });
        const [filters, setFilters] = useState(filtersType);
        const [textWrap, setTextWrap] = useState(false);
        const [starred, setStarred] = useState(false);
        const [search, setSearch] = useState("");
        const [selectedRows, setSelectedRows] = useState([]);
        const [sortField, setSortField] = useState({ column: {}, direction: "" });
        const [page, setPage] = useState(1);
        const [limit] = useState(10);
        const searchParam = {};

        if (starred) { searchParam.starred = "starred"; }
        if (filters.status?.length) { searchParam.status = filters.status?.join(","); }
        if (filters.AIModels?.length) { searchParam.aiModel = filters.AIModels?.join(","); }
        if (filters.durations?.length) { searchParam.date = filters.durations?.join(","); }
        if (filters?.customDate?.startDate && filters?.customDate?.endDate) {
            searchParam.startDate = filters?.customDate.startDate;
            searchParam.endDate = filters?.customDate.endDate;
            searchParam.isCustomDate = true;
        }
        if (pathname.includes("/newsletters")) { searchParam.type = "newsletters_history"; }
        if (pathname.includes("/press-release")) { searchParam.type = "press-release"; }

        const query = new URLSearchParams(searchParam).toString();
        const { refetch, isFetching } = useQuery({
            queryKey: ['eCommerce'],
            queryFn: async () => {
                const response = await eCommerceHistoryData({ userId: userRes?.id, query });
                setHistoryData(response);
                return response;
            },
        });

        const handleSort = (column, sortDirection) => {
            setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
            setPage(1);
            const newData = sorting(historyData?.data, column?.sortField, sortDirection);
            setHistoryData(pre => ({ ...pre, data: newData }));
        };

        const handleSelectedRows = (e) => {
            setSelectedRows(e?.selectedRows?.map(({ type, id }) => ({ id, type })));
        };

        const handlePinArticle = (type, articleId, value) => {
            const url = `/api/articleHistory/pinArticleHistory`;
            AxiosApi.put(url, {
                type: type,
                articleId: articleId,
                value: !value,
            }).then((response) => {
                refetch();
                const toasterMessage = !value ? 'Article Added as Starred successfully.' : 'Article removed from the Starred successfully.';
                toasterSuccess(toasterMessage, 'toaster-43');
            }).catch((error) => {
                console.error(error);
            });
        };

        useEffect(() => {
            setFilters(filtersType);
        }, [pathname, filtersType]);

        useEffect(() => {
            setPage(1);
            refetch();
        }, [filters, starred, refetch]);

        const filteredData = useMemo(() => (
            historyData?.data?.filter(item =>
                Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('')?.toLowerCase().includes(search?.split(' ').join('')?.toLowerCase()))
            )
        ), [historyData, search])

        const handleDelete = (id, type, eType) => {
            Swal({
                title: "Are you sure?",
                text: `Do you want to ${eType} the selected item?`,
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, ${eType} it!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callback = () => {
                        refetch(); setSelectedRows([]);
                    };
                    deleteOrArchiveArticle([{ id, type }], eType, callback);
                }
            });
        };

        const handleArchiveAll = () => {
            Swal({
                title: "Are you sure?",
                text: "Do you want to archive the selected item?",
                icon: "warning", dangerMode: true,
                buttons: { cancel: "Cancel", confirm: `Yes, archive ${selectedRows.length > 1 ? "all" : ""}!` },
            }).then((isConfirmed) => {
                if (isConfirmed) {
                    const callback = (resp) => {
                        setPage(1); refetch(); setSelectedRows([]);
                    }
                    deleteOrArchiveArticle(selectedRows, 'archive', callback);
                }
            });
        };

        return (
            <WrappedComponent
                {...props} historyData={filteredData ?? []} userRes={userRes} loading={isFetching} filters={filters} setFilters={setFilters}
                textWrap={textWrap} setTextWrap={setTextWrap} starred={starred} setStarred={setStarred} setSearch={setSearch} search={search}
                selectedRows={selectedRows} setSelectedRows={handleSelectedRows} handleArchiveAll={handleArchiveAll} limit={limit}
                totalCount={historyData?.totalCount || 0} page={page} setPage={setPage} handlePinArticle={handlePinArticle} handleDelete={handleDelete}
                sortField={sortField} handleSort={handleSort} getHistoryShortFlowData={useCallback(() => refetch(), [refetch])}
            />
        )
    }

    return WithECommerceHandler;
}

export default withECommerceHandler;
