import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { GoSidebarCollapse } from "react-icons/go";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Footer from "./Footer";
import HeaderMenu from "./HeaderMenu";
import SideBarMenu from "./Sidebar/SideBarMenu";

const HeaderSidebar = ({ children }) => {
  const [toggle, setToggle] = useState(Number(localStorage.getItem("toggle-open")) || 0);
  const [toggleLogo, setToggleLogo] = useState(true);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const isFullScreen = searchParams.get("full-screen");
  const userRes = useSelector(({ userRes }) => userRes);
  const { credits } = userRes;

  useEffect(() => {
    window.scrollTo(0, 0);
    const checkPath = [
      "/1-click-blog", "/pdf-to-article", "/blog-co-pilot", "/social-media-post-writer", "/brand-voice", "/brand-product-image", "/press-release",
      "/oona-1-click", "/oona-blog-co-pilot"
    ];
    if (location && location.pathname) {
      const index = checkPath.findIndex((ele) => location.pathname.indexOf(ele) !== -1);
      if (index !== -1) {
        localStorage.setItem("toggle-open", 1);
        setToggle(1);
      } else {
        localStorage.setItem("toggle-open", 0);
        setToggle(0);
      }
    }
  }, [location]);

  const handleToggle = useCallback((value) => {
    setToggleLogo(value);
    if (value) {
      document.body.classList.remove('closeSlide')
      document.body.classList.add('openSlide');
    } else {
      document.body.classList.remove('openSlide')
      document.body.classList.add('closeSlide');
    }
  }, [])

  return (
    <div className={toggle ? "close" : "open"}>
      <header className="border headerMobile" style={{ padding: '11px' }}>
        <div className="d-flex justify-content-between align-items-center gap-3">
          <div className="sideButton d-flex justify-content-center align-items-center">
            <GoSidebarCollapse size={25} className="mobileSidebarButton text-muted" onClick={() => setIsMobileScreen(!isMobileScreen)} />
          </div>
          <div className="sideContent d-flex justify-content-center gap-3 align-items-center  ">
            <div className="d-flex gap-2 align-items-center border rounded-2 p-1 px-2">
              <h6 className={credits < 1 ? "text-danger" : ""}>{credits}</h6>
              <p>Addlly credits left</p>
            </div>
          </div>
        </div>
      </header>
      {/* {!toggle && <div onClick={() => setToggle(!toggle)} className="overlaySidebar"></div>} */}
      <div className={`d-flex sidebarContent justify-content-between ${isMobileScreen ? "" : toggleLogo ? "openSidebar" : "closeSideBar"} ${isFullScreen ? "fullScreenPage" : ""} `}>
        <SideBarMenu toggle={toggle} setToggle={setToggle} toggleLogo={toggleLogo} setToggleLogo={handleToggle} isMobileScreen={isMobileScreen} setIsMobileScreen={setIsMobileScreen} />
        <div id="scroll-wrapper"
          className={classNames(`contentWrapper content-with-footer ${toggle ? "isCloseSideBar" : "isOpenSideBar"}`,
            { ECommerceEdm: (location.pathname.indexOf("/e-commerce-edm/") !== -1 && location.pathname.indexOf("/e-commerce-edm/type") === -1) }
          )}
        >
          <HeaderMenu toggle={toggle} setToggle={setToggle} />
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HeaderSidebar;
