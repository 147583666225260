import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import { Col, Modal } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import VersionHistory from "./VersionHistory";
import { AxiosApi } from "../../utility/axios";
import { setLoader } from "../../store/reducer";
import HtmlViewer from "../SeoBlogWriter/HtmlViewer";
import { BsArrowsAngleContract, BsArrowsFullscreen, BsStars } from "react-icons/bs";
import { EditorInitObj, addEditorCustomButtons } from "../../utility/hepler";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
const beautify_html = require("js-beautify").html;

const AiOverviewTabComponent = (props) => {
  const { articleType, articleId, articleHtml, isFullScreen } = props;

  const dispatch = useDispatch();
  const editorRef = useRef();
  const userRes = useSelector(({ userRes }) => userRes);

  const [searchParams, setSearchParams] = useSearchParams();
  const [overviewId, setOverviewId] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [htmlText, setHtmlText] = useState("");
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [editorKey, setEditorKey] = useState(0);
  const [regenerateLeft, setRegenerateLeft] = useState(0);

  const [selectedSubTab, setSelectedSubTab] = useState("seoScore");
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});

  useEffect(() => {
    handleGetAiOverViewDetails();
  }, []);

  const handleFullScreen = () => {
    if (isFullScreen) {
      setSearchParams("tab=aiOverview");
    } else {
      setSearchParams("tab=aiOverview&full-screen=true");
    }
  };

  const handleGetAiOverViewDetails = () => {
    dispatch(setLoader(true));
    const apiUrl = "/api/article/history-or-details/byType/get-ai-overview-details";
    AxiosApi.get(`${apiUrl}?articleId=${articleId}&articleType=${articleType}`).then((res) => {
      dispatch(setLoader(false));
      const item = res.data ? res.data : {};
      setHtmlText(item.content || ""); setFinalHtmlText(item.content || "");
      setEditorKey(editorKey + 1); setRegenerateLeft(item.regenerateLeft || 0);
      getArticleAllVersionHistory(item.id);
    }).catch((e) => {
      dispatch(setLoader(false));
    });
  };

  const getArticleAllVersionHistory = async (viewId) => {
    try {
      if (!viewId || viewId === 0) {
        return false;
      }

      setOverviewId(viewId); setVersionHistory([]); setSelectedVersionItem({});
      const params = `articleId=${viewId}&type=AI Overview Writer&subType=AI Overview&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  }

  const handleGenerateAiOverviewByType = async (isRegenerate = false) => {
    try {
      if (isRegenerate && regenerateLeft === 0) {
        return toast.error("You have already generate 3 times");
      }
      dispatch(setLoader(true));
      const paramsData = { userId: userRes?.id, articleId, articleType, articleHtml, type: "generate-link-to-overview" };
      if (feedbackText && feedbackText !== "") { paramsData.feedback = feedbackText; };
      if (isRegenerate) { paramsData.isRegenerate = isRegenerate; };
      const payload = { body: JSON.stringify(paramsData) };

      const response = await invokeLambdaFunction("generateArticleOrSaveByType", payload);
      dispatch(setLoader(false)); setShowFeedbackModel(false);

      if (response && response.success === 1 && response.data && response.data.content) {
        setHtmlText(response.data.content); setFinalHtmlText(response.data.content);
        setEditorKey(editorKey + 1); setRegenerateLeft(response.data.regenerateLeft);
        getArticleAllVersionHistory(response.data.id);
      } else {
        toast.error(response?.data?.error || "Content not generated, Please try again", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      setShowFeedbackModel(false);
      dispatch(setLoader(false));
    }
  };

  const handleSaveAiOverviewText = async () => {
    dispatch(setLoader(true));
    setSelectedSubTab("");

    await AxiosApi.post(`/api/articleHistory/update?tableName=overview_article_history`, {
      id: overviewId, currentField: "aiOverview", article_html: htmlText
    }).then(async () => {
      dispatch(setLoader(false)); setSelectedSubTab(selectedSubTab);
      getArticleAllVersionHistory(overviewId);
    }).catch(function (error) {
      dispatch(setLoader(false)); setSelectedSubTab(selectedSubTab);
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    setFinalHtmlText(text);
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      const modifiedText = beautify_html(text, options);
      var isPlainText = !modifiedText.match(/<[^>]+>/);
      const isCoped = copy(modifiedText, {
        debug: true,
        format: isPlainText ? 'text/plain' : 'text/html',
      });
      if (isCoped) {
        toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
      }
    }
  };

  return (
    <div className={`blogWriterBlock position-relative`}>
      <div className="navItemsBlock">
        <div className={classNames(`navTopButton d-flex justify-content-between flex-wrap gap-4`)}>
          <div className="leftButtons d-flex gap-3 flex-wrap align-items-center">
            <VersionHistory
              versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
              handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
              selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
            />

            <div className="d-flex gap-3">
              <div className="regenrateBtn d-flex align-items-center">
                {htmlText && htmlText !== "" ? (
                  <button type="button" className={"blogButton border-0"} disabled={!regenerateLeft} onClick={() => setShowFeedbackModel(true)}>
                    <BsStars /> Re-Generate ( {regenerateLeft || 0} / 3 )
                  </button>
                ) : (
                  <button type="button" onClick={() => handleGenerateAiOverviewByType(false)} className={"blogButton border-0 primary-btn"}>
                    <BsStars /> Generate
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="rightSideButton d-flex gap-2 flex-wrap">
            <button
              className="blogButton border-0 bg-black saveButton" onClick={handleSaveAiOverviewText}
              data-tooltip-id="my-tooltip" data-tooltip-content="Save" data-tooltip-place="bottom" disabled={!htmlText}
            >
              <FaRegSave /> Save
            </button>

            {articleType !== "E-Commerce Blog" && (
              <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
                {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={classNames("editableTextArea d-flex overflow-hidden position-relative")}>
        {toggle ? (
          <HtmlViewer
            setToggle={setToggle} value={htmlText} html={finalHtmlText}
            onEditorChange={(text) => setHtmlText(text)}
          />
        ) : (
          <div className="textEditerArea mw-100">
            <div className="h-100">
              {htmlText && htmlText !== "" ? (
                <Col sm="12" md="8" className="position-relative w-100 h-100">
                  <div className="position-relative custom-text-editor h-100">
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                      onInit={(evt, editor) => editorRef.current = editor}
                      value={htmlText ? htmlText : ""} key={editorKey}
                      onEditorChange={(text) => setHtmlText(text)}
                      init={{
                        ...EditorInitObj,
                        content_style: `${finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || ""}`,
                        toolbar: 'viewHtml | copyContent | ' + EditorInitObj.toolbar,
                        setup: function (editor) {
                          addEditorCustomButtons(editor, setToggle, handleCopyHtmlArticleText);
                        },
                      }}
                    />
                  </div>
                </Col>
              ) : (
                <div className={`toggleData notContent mw-100`}></div>
              )}
            </div>
          </div>
        )}
      </div>

      {showFeedbackModel && (
        <Modal size="lg" centered show={showFeedbackModel} onHide={() => setShowFeedbackModel(false)}>
          <Modal.Body className="text-start p-0 overflow-auto">
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => handleGenerateAiOverviewByType(true)}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default AiOverviewTabComponent;
