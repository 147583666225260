import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import CommentsTextEditor from '../../../Components/commonComponents/ReviewTextEditor/CommentsTextEditor'
import CommentListComponent from '../../../Components/commonComponents/ReviewTextEditor/CommentListComponent'
import VersionHistoryComponent from '../../../Components/commonComponents/ReviewTextEditor/VersionHistoryComponent'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosApi } from '../../../utility/axios'
import { setLoader } from '../../../store/reducer'
import { v4 as uuidv4 } from "uuid";
import toast from 'react-hot-toast'
import copy from 'copy-to-clipboard'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'
import { oneClickBlogIcon } from '../../../Assets/Icons'
import { GoArrowLeft } from 'react-icons/go'
import Swal from "sweetalert";
import { IoIosList } from 'react-icons/io'
import VersionHistory from '../../../Components/commonComponents/VersionHistory'
import { BsArrowsAngleContract, BsArrowsFullscreen, BsStars } from 'react-icons/bs'
import { FaRegSave } from 'react-icons/fa'
const beautify_html = require("js-beautify").html;

const ReviewArticle = () => {
  const { articleId, articleType } = useParams();
  const editorRef = useRef(null);
  const userRes = useSelector(({ userRes }) => userRes);
  const dispatch = useDispatch();

  const [editorText, setEditorText] = useState("");
  const [oldArticleText, setOldArticleText] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [replyCmtText, setReplyCmtText] = useState("");
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [currentStatus, setCurrentStatus] = useState("all");
  const [URLSearchParams] = useSearchParams();
  const isFullScreen = URLSearchParams.get("full-screen");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = "article";
  const articleSubType = articleType === "Blog Co-Pilot" ? "Generate Article" : "Short Article";

  useEffect(() => {
    handleGetAllCommentList("all", true);
  }, [])

  const handleGetAllCommentList = (status, isLoader = false) => {
    setCurrentStatus(status);
    setSelectedCommentId("");
    if (isLoader) {
      setCommentList([]);
      dispatch(setLoader(true));
    }

    const queryParams = `status=${status}&articleType=${articleType}&articleSubType=${articleSubType}&checkReview=true`;
    const url = `/api/editorial-comment/list/byArticle/${articleId}?${queryParams}`;
    AxiosApi.get(url).then(async (response) => {
      if (response && response.data) {
        if (response.data.data && response.data.data.length > 0) {
          setCommentList(response.data.data);
        }

        if (response.data.articleText && response.data.articleText !== "") {
          let htmlText = response.data.articleText;
          if (status === "resolved") {
            htmlText = await removeSpanTags(htmlText);
          }

          if (response.data.articleText && response.data.articleText !== "" && /<html[^>]*>/.test(response.data.articleText)) {
            const parser = new DOMParser();
            const bodyText = parser.parseFromString(htmlText, "text/html").body.outerHTML;
            htmlText = `${response.data.articleText.substring(0, response.data.articleText.indexOf("<body"))} ${bodyText} </html>`
              .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
          }

          setEditorText(htmlText);
          setOldArticleText(htmlText);
        }

        setTimeout(() => {
          handleAddEventOnIframe();
        }, 1000);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  };

  const removeSpanTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const spans = doc.querySelectorAll("span");
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return Promise.resolve(doc.body.innerHTML);
  };

  const handleCloseSelectedComment = async () => {
    setSelectedCommentId("");
    setReplyCmtText("");

    const iframe = document.querySelector("iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll("span.active");
      activeSpans.forEach((span) => { span.classList.remove("active") });

      if (currentStatus === "resolved") {
        const htmlText = editorRef.current.getContent();
        const modifiedHTML = await removeSpanTags(htmlText);
        setEditorText(modifiedHTML);
      }
    }
  };

  const handleCloseAddCommentModal = () => {
    setCommentText("");
    setReplyCmtText("");
    setCommentModal(false);

    const elements = document.getElementsByClassName("custom-tooltip");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("top-arrow");
      elements[i].style.display = "";
      elements[i].style.top = "";
    }
    const elements2 = document.getElementsByClassName("arrow-content");
    elements2[0].style.left = "";
    const element2 = document.getElementsByClassName("reviewArticle");
    for (let i = 0; i < element2.length; i++) {
      element2[i].classList.add("overflow-hidden");
    }

    if (document.querySelector(".tox-edit-area")) {
      const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const selection = iframe.contentWindow.getSelection();
        if (selection && selection.toString().trim() !== "") {
          selection.removeAllRanges();
        }
      }
    }
  };

  const handleRemoveFirstAndLastElement = async (string, type) => {
    return new Promise((resolve, reject) => {
      if (type === "start") {
        if (string[0] === "<") {
          const elementPattern = "<[^>]+>[A-Za-z0-9]";
          const regexPattern = new RegExp(elementPattern, "i");
          const result = string.match(regexPattern);
          if (result && result.length > 0) {
            const matchStr = result[0];
            string = string.replace(matchStr, matchStr[matchStr.length - 1]);
          }
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      } else {
        if (string[string.length - 1] === ">") {
          const regex = /<[^>]*>$/;
          string = string.replace(regex, "");
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      }
    });
  };

  const handleHighLightSelectedEditorText = async (htmlText, selectedTextStr, id) => {
    let selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTextStr, "start");
    selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTempTextStr, "end");

    return new Promise((resolve, reject) => {
      const div = document.createElement("div");
      div.innerHTML = selectedTextStr;
      const elements = div.querySelectorAll("*");
      if (elements && elements.length > 0) {
        elements.forEach(element => {
          const span = document.createElement("span");
          span.innerHTML = element.innerHTML;
          span.classList.add("selected-wrapper");
          span.setAttribute("data-group", id);
          element.innerHTML = "";
          element.appendChild(span);
        });
        let modifiedHTML = div.innerHTML;
        modifiedHTML = modifiedHTML.replace(/^(<(?!span\s)[^>]*>)+/g, "");
        modifiedHTML = modifiedHTML.replace(/(<\/((?!span).)*>$)/g, "");

        const highlightedContent = htmlText.replace(selectedTempTextStr, modifiedHTML);
        resolve(highlightedContent);
      } else {
        const highlightedContent = htmlText.replace(selectedTextStr, `<span class="selected-wrapper" data-group="${id}">${selectedTextStr}</span>`);
        resolve(highlightedContent);
      }
    });
  };

  const handleAddNewComment = async () => {
    const id = uuidv4().split("-")[0];
    if (editorRef && editorRef.current) {
      dispatch(setLoader(true));
      const htmlText = editorRef.current.getContent();
      let selectedTextStr = editorRef.current.selection.getContent();
      selectedTextStr = selectedTextStr.replace(/<([^>\s]+)[^>]*>(?:\s*(?:<br \/>|&nbsp;|&thinsp;|&ensp;|&emsp;|&#8201;|&#8194;|&#8195;)\s*)*<\/\1>/gm, "");
      let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, id);

      if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(highlightedContent, "text/html").body.outerHTML;
        highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
      }

      const obj = {
        commentId: id,
        comment: commentText,
        selectedText: selectedTextStr,
        userId: userRes.id,
        user_info: JSON.stringify({
          fullName: `${userRes?.first_name || ""} ${userRes?.last_name || ""}`,
          userName: userRes?.username || "",
          profileUrl: userRes?.profile_url || "",
        }),
        isResolved: false,
        createAt: new Date(),
        status: "open",
      }

      try {
        const dataObj = {
          ...obj,
          articleId: articleId, status: "open",
          articleText: highlightedContent,
        };
        await AxiosApi.post("/api/editorial-comment/create-comment", dataObj);
        dispatch(setLoader(false));

        setEditorText(highlightedContent);
        setCommentList((prev) => ([...prev, obj]));
        handleCloseAddCommentModal();
      } catch (error) {
        toast.error("Something went wrong.");
        dispatch(setLoader(false));
      }
    }
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const handleOnSelectCommentById = async (e, id) => {
    e.stopPropagation();
    setSelectedCommentId(id);
    const iframe = document.querySelector("iframe");
    await new Promise(resolve => setTimeout(resolve, 200));
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll("span.active");
      activeSpans.forEach((span) => { span.classList.remove("active") });

      const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${id}"]`);
      elements.forEach(element => { element.classList.add("active") });

      if (elements.length === 0) {
        const index = commentList.findIndex((ele) => ele.commentId === id);
        if (index !== -1) {
          let htmlText = editorRef.current.getContent();
          if (currentStatus === "resolved") {
            htmlText = await removeSpanTags(htmlText);
          }

          const selectedTextStr = commentList[index]?.selectedText ? commentList[index].selectedText : "";
          const highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, id);
          setEditorText(highlightedContent);

          setTimeout(() => {
            const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${id}"]`);
            elements.forEach(element => { element.classList.add("active") });
          }, 500);
        }
      }
    }
  };

  const handleAddEventOnIframe = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (document.querySelector(".tox-edit-area") && editorRef && editorRef.current) {
        const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
        if (iframe && iframe.contentWindow && iframe.contentWindow.document && editorRef.current.selection) {
          const iframeDoc = iframe.contentWindow;
          iframeDoc.document.addEventListener("selectionchange", () => {
            const selection = iframeDoc.getSelection();
            if (selection && selection.toString().trim() !== "") {
              const range = selection.getRangeAt(0);
              const rect = range.getBoundingClientRect();
              const elements = document.getElementsByClassName("custom-tooltip");
              for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = "block";
                elements[i].style.top = rect.top + 30 + "px";
                elements[i].style.left = rect.left + (rect.width / 2) + "px";
              }
              const element2 = document.getElementsByClassName("reviewArticle");
              for (let i = 0; i < element2.length; i++) {
                element2[i].classList.remove("overflow-hidden");
              }
            } else {
              handleCloseAddCommentModal();
            }
          });
        }
      }
    } catch (error) {
    }
  };

  const handleAddCommentReply = async () => {
    const replyId = uuidv4().split("-")[0];
    const obj = {
      commentId: replyId,
      parentCommentId: selectedCommentId,
      comment: replyCmtText,
      senderType: "user",
      isResolved: false,
      createAt: new Date(),
    }

    const tempObj = [...commentList];
    const index = tempObj.findIndex((ele) => ele.commentId === selectedCommentId);
    if (index !== -1) {
      const replyCmtArray = tempObj[index].replyComments || [];
      replyCmtArray.push(obj);
      tempObj[index].replyComments = [...replyCmtArray];

      if (tempObj[index].status === "resolved") {
        tempObj[index].status = "open";
        const htmlText = editorRef.current.getContent();
        const selectedTextStr = tempObj[index].selectedText;
        let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, selectedCommentId);
        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(highlightedContent, "text/html").body.outerHTML;
          highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
        }

        setEditorText(highlightedContent);
        obj.articleText = highlightedContent;
      }
    }

    try {
      dispatch(setLoader(true));
      await AxiosApi.post(`/api/editorial-comment/reply-comment/${selectedCommentId}`, obj);
      dispatch(setLoader(false));
      setCommentList([...tempObj]);
      setReplyCmtText("");

      if (currentStatus === "resolved") {
        handleGetAllCommentList("all", false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    }
  };

  const handleResolvedOrDeleteComment = async (id, type) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!", icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${type === "reopen" ? "Re-Open" : type} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const htmlText = editorRef.current.getContent();
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlText;

        const spanElements = tempDiv.querySelectorAll(`span[data-group="${id}"]`);
        spanElements.forEach(spanElement => {
          const innerText = spanElement.innerText;
          spanElement.outerHTML = innerText;
        });

        let modifiedHTML = tempDiv.innerHTML;
        if (type === "reopen" || type === "restore") {
          const selectedTextStr = commentList.find((ele) => ele.commentId === id)?.selectedText;
          if (selectedTextStr && selectedTextStr !== "") {
            modifiedHTML = await handleHighLightSelectedEditorText(modifiedHTML, selectedTextStr, id);
          }
        }

        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(modifiedHTML, "text/html").body.outerHTML;
          modifiedHTML = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, " ").trim();
        }

        const tempObj = commentList.filter((ele) => {
          if (ele.commentId === id) {
            if (type === "restore") {
              ele.status = ele.is_resolved === 1 ? "resolved" : "open";
            } else if (type === "reopen") {
              ele.status = "open";
            } else {
              ele.status = type === "delete" ? "deleted" : "resolved";
            }
          }
          return ele;
        });

        try {
          dispatch(setLoader(true));
          const obj = { type, articleText: modifiedHTML, userName: `${userRes.first_name || ""} ${userRes.last_name || ""}` };
          await AxiosApi.post(`/api/editorial-comment/resolve-or-delete-comment/${id}`, obj);
          dispatch(setLoader(false));

          setEditorText(modifiedHTML);
          setCommentList([...tempObj]);
          handleGetAllCommentList("all", false);
        } catch (error) {
          toast.error("Something went wrong.");
          dispatch(setLoader(false));
        }
      }
    });
  };

  const handleCopy = (data) => {
    toast.success("Text has been copied to clipboard.");
    copy(data);
  };

  const handleFullScreen = () => {
    if (isFullScreen) {
      setSearchParams("");
    } else {
      setSearchParams(selectedTab ? "&full-screen=true" : "full-screen=true");
    }
  };

  const handleSetRestoredOldVersionText = () => { };

  const handleSaveArticleOrPostText = () => { };

  return (
    <React.Fragment>
      <div className={classNames("topBar d-flex gap-3 justify-content-between mb-4", { "d-none": isFullScreen })}>
        <div className="headingSide d-flex align-items-center">
          <strong className="d-flex align-items-center">
            <ReactSVG src={oneClickBlogIcon.clickOneClickBlog} useRequestCache /> Article Review
          </strong>
        </div>
        <div className="backtoHome d-flex gap-3 justify-content-between align-items-center text-center">
          <div className="backBtn">
            <Link to={"/review-history"} className="bg-transparent border-0">
              <GoArrowLeft /> Back to home
            </Link>
          </div>
        </div>
      </div>
      <div className="blogWriterArea card d-flex flex-row">
        <div className={`blogWriterBlock position-relative`} style={{ maxWidth: '100%' }}>
          <div className="navItemsBlock">
            <div className="navTopButton d-flex justify-content-between flex-wrap gap-4">
              <div className="leftButtons d-flex gap-3 align-items-center">
                <div>
                  <button
                    className="blogButton border-0"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
                  >
                    <IoIosList /> Metadata
                  </button>
                </div>
                <VersionHistory
                  versionHistory={versionHistory} selectedVersionItem={selectedVersionItem}
                  setSelectedVersionItem={setSelectedVersionItem} handleSetRestoredOldVersionText={handleSetRestoredOldVersionText}
                  handleCopyHtmlArticleText={handleCopyHtmlArticleText} articleType={articleType}
                />
              </div>
              <div className="rightSideButton d-flex gap-2">
                <button data-tooltip-id="save" data-tooltip-content="Save" data-tooltip-place="bottom"
                  className="blogButton border-0 bg-black saveButton" onClick={handleSaveArticleOrPostText}
                  disabled={""}
                >
                  <FaRegSave /> {isFullScreen ? "Save" : ""}
                </button>
                <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
                  {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
                </button>
              </div>
            </div>
          </div>
          <div className={classNames("editableTextArea d-flex position-relative")}>
            <Row className="review-article-wrapper w-100">
              <React.Fragment>
                <Col sm={8} className="editor-wrapper">
                  <CommentsTextEditor
                    oldArticleText={oldArticleText} editorText={editorText} setEditorText={setEditorText}
                    editorRef={editorRef} setSelectedCommentId={setSelectedCommentId} articleType={articleType}
                    handleCloseSelectedComment={handleCloseSelectedComment} commentModal={commentModal}
                    setCommentModal={setCommentModal} userRes={userRes} commentText={commentText}
                    setCommentText={setCommentText} handleAddNewComment={handleAddNewComment}
                    handleCloseAddCommentModal={handleCloseAddCommentModal}
                    handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  />
                </Col>
                <Col sm={4} className="">
                  <CommentListComponent
                    commentList={commentList} selectedCommentId={selectedCommentId} replyCmtText={replyCmtText}
                    setReplyCmtText={setReplyCmtText} currentStatus={currentStatus} articleType={articleType}
                    handleOnSelectCommentById={handleOnSelectCommentById} handleAddCommentReply={handleAddCommentReply}
                    handleResolvedOrDeleteComment={handleResolvedOrDeleteComment} handleGetAllCommentList={handleGetAllCommentList}
                    handleCloseSelectedComment={handleCloseSelectedComment}
                  />
                </Col>
              </React.Fragment>

              <VersionHistoryComponent
                articleId={articleId} articleType={articleType}
                editorText={editorText} setEditorText={setEditorText} oldArticleText={oldArticleText} userRes={userRes}
                handleCopyHtmlArticleText={handleCopyHtmlArticleText}
              />
            </Row>
          </div>
        </div>
      </div>

      <div className="offcanvas offcanvas-end " tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {'Blog’s'} Metadata
          </h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>What do you want to write about?</p>
              <span className=" ">{ }</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src="" alt="" onClick={() => handleCopy()} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>Keyword</p> <span className=" ">{ }</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src="" alt="" onClick={() => handleCopy()}
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>AI Model</p> <span className=" ">{ }</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src="" alt="" onClick={() => handleCopy()}
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div style={{ background: "var(--Black-B30, #EBEBED)", height: "1px" }} className="mb-4"></div>
          <div className="blogMetaInner">
            <p><BsStars /> Headline generated</p>
            <span className=" ">{ }</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta title generated</p>
            <span className=" ">{ }</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta description generated</p>
            <span className=" ">{ }</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReviewArticle;
