import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ count = 1, height = 15, width = 100 }) => {
  return (
    <SkeletonTheme baseColor="#e3e3e3" highlightColor="#bfbfbf">
      <p>
        <Skeleton count={count} height={height} width={width} />
      </p>
    </SkeletonTheme>
  );
};

export default SkeletonLoader;
