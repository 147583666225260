import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Tb123 } from "react-icons/tb";
import { BsFiletypeTxt } from "react-icons/bs";
import { MdChat, MdAbc } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { AxiosApi } from "../../utility/axios";

let articleId = 0;
const GoogleTrends = ({ show, type = "short", tab, setTab, id, progress }) => {
  const [progressNow, setProgressNow] = useState(0);
  const userRes = useSelector(({ userRes }) => userRes);
  const interval = useRef();
  const callApiInterval = useRef();

  useEffect(() => {
    if (progress && progressNow < progress) {
      setProgressNow(progress);
    }
  }, [progress])

  useEffect(() => {
    setTimeout(() => {
      setTab(2);
    }, 40000);
    setTimeout(() => {
      setTab(3);
    }, 80000);

    callApiInterval.current = setInterval(() => {
      if (!show && callApiInterval.current) {
        clearInterval(callApiInterval.current);
      }

      AxiosApi.get(`/api/generating-article-progress/${id || articleId}?user_id=${userRes.id}&type=${type}`).then((res) => {
        if (res && res.data && res.data.articleId) { articleId = res.data.articleId; }
        if (res && res.data && res.data.progress) {
          if (res.data.progress >= 100 && callApiInterval.current) {
            clearInterval(callApiInterval.current);
          } else {
            setProgressNow((count) => (count < res.data.progress && res.data.progress <= 95) ? res.data.progress : count);
          }
        }
      });
    }, 5000);

    return () => {
      if (callApiInterval.current) {
        clearInterval(callApiInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    function handleTimer() {
      interval.current = setInterval(() => {
        setProgressNow((count) => (count >= 95) ? 95 : count + 1);
      }, 3000);
    }

    if (progressNow >= 100 && interval.current) {
      clearInterval(interval.current);
    }
    if (progressNow === 0) {
      handleTimer();
    }
  }, [progressNow]);

  return (
    <Modal centered show={show} size="md">
      <Modal.Body className="text-center first-step-detail">
        <div className="google-trends">
          {tab === 1 && (
            <div>
              <div className="mobile-col">
                <div className="p-30 particular-box custom-particular-box  border-radius-12 bg-white h-100">
                  <div className="d-flex align-items-center justify-content-between pb-12">
                    <IoMdNotificationsOutline color="#0000ff" size={60} />
                    {/* <img className="google-trends-loader" src={Spinner} alt="Loading..." /> */}
                    <span className="mb-0 color-primary-main">1</span>
                  </div>
                  <h3 className="fw-semibold mt-20">STEP 1</h3>
                  <h5 className="font-weight-500 color-light-text mt-12">
                    Our smart writer is finding relevant semantic keywords for the best possible results.
                  </h5>
                  <div className="mt-4">
                    <MdChat color="#0000ff" size={60} />
                  </div>
                  <div className="mt-2">
                    <ProgressBar now={progressNow} label={`${progressNow}%`} animated />
                  </div>
                </div>
              </div>

              {/* <div className="d-flex justify-content-end mt-3">
                <button onClick={() => handleNext()} className="addlly-primary text-end">
                  <MdNavigateNext color="#ffffff" size={24} />
                </button>
              </div> */}
              {/* <GoogleTrendsScirpt type="TIMESERIES" keyword="best maternity insurance" /> */}
            </div>
          )}
          {tab === 2 && (
            <div>
              <div className="mobile-col">
                <div className="p-30 particular-box custom-particular-box  border-radius-12 bg-white  h-100">
                  <div className="d-flex align-items-center justify-content-between pb-12">
                    <IoMdNotificationsOutline color="#0000ff" size={60} />
                    {/* <img className="google-trends-loader" src={Spinner} alt="Loading..." /> */}
                    <span className="mb-0 color-primary-main">2</span>
                  </div>
                  <h3 className="fw-semibold mt-20">STEP 2</h3>
                  <h5 className="font-weight-500 color-light-text mt-12">
                    Our smart writer is optimising the word count to improve the search engine ranking of this article.{" "}
                  </h5>
                  <div className="mt-4">
                    <p style={{ marginBottom: "-24px" }}>
                      <MdAbc color="#0000ff" size={70} />
                    </p>
                    <p>
                      <Tb123 color="#0000ff" size={54} />
                    </p>
                  </div>
                  <div className="mt-2">
                    <ProgressBar now={progressNow} label={`${progressNow}%`} animated />
                  </div>
                </div>
              </div>

              {/* <div className="d-flex justify-content-between mt-3">
                <button onClick={() => handleBack()} className="addlly-primary py-1">
                  <MdNavigateBefore color="#ffffff" size={24} />
                </button>
                <button onClick={() => handleNext()} className="addlly-primary ">
                  <MdNavigateNext color="#ffffff" size={24} />
                </button>
              </div> */}
              {/* <GoogleTrendsScirpt type="GEO_MAP" keyword="best maternity insurance" /> */}
            </div>
          )}
          {tab === 3 && (
            <div>
              <div className="mobile-col">
                <div className="p-30 particular-box custom-particular-box  border-radius-12 bg-white h-100">
                  <div className="d-flex align-items-center justify-content-between pb-12">
                    <IoMdNotificationsOutline color="#0000ff" size={60} />
                    {/* <img className="google-trends-loader" src={Spinner} alt="Loading..." /> */}
                    <span className="mb-0 color-primary-main">3</span>
                  </div>
                  <h3 className="fw-semibold mt-20">STEP 3</h3>
                  <h5 className="font-weight-500 color-light-text mt-12">
                    Our smart writer is generating an eye-catching headline that will optimise your content for search engines.
                  </h5>
                  <div className="mt-4">
                    <BsFiletypeTxt color="#0000ff" size={60} />
                  </div>
                  <div className="mt-2">
                    <ProgressBar now={progressNow} label={`${progressNow}%`} animated />
                  </div>
                </div>
              </div>

              {/* <div className="d-flex justify-content-start mt-3">
                <button onClick={() => handleBack()} className="addlly-primary">
                  <MdNavigateBefore color="#ffffff" size={24} />
                </button>
              </div> */}
              {/* <GoogleTrendsScirpt type="RELATED_TOPICS" keyword="best maternity insurance" /> */}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GoogleTrends;
