import React from 'react';
import { Button } from 'react-bootstrap';

const CustomButton = ({ children, onClick, disabled, loading, className, variant, ...rest }) => {
    return (
        <Button className={`custom-button w-100 border-0  align-items-center justify-content-center text-center ${className}`} style={{ background: "linear-gradient(103deg, #00F 0%, #F00 121.74%)" }} {...rest} onClick={onClick} disabled={disabled || loading}>
            {loading ? 'Loading...' : children}
        </Button>
    );
};

export default CustomButton;
