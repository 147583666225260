import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo } from "../../store/reducer";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { IoIosArrowDown, IoIosWarning } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import SpeechToText from "../SpeechToText";
import { BsArrowLeft, BsArrowRight, BsCloudUploadFill, BsFileEarmarkPdf, BsInfoCircleFill } from "react-icons/bs";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { AxiosApi } from "../../utility/axios";
import ReactFlagsSelect from 'react-flags-select';
import { handleCustomSelectOption, removeSpecificSpecialCharacters, supportedLanguageList, trimedInputValue } from "../../utility/hepler";
import React, { useEffect, useRef, useState } from "react";
import { BsStars } from "react-icons/bs";
import { BiSolidErrorCircle } from "react-icons/bi";
import { FileUploader } from "react-drag-drop-files";
import { AiTypeOptions } from "../../data";
import { blogCoPilotIcon } from "../../Assets/Icons"
import { handleSetLanguageAndGeoLocation } from "../../utility/common";
import { AiOutlineFilePdf } from "react-icons/ai";

const validationSchema1 = Yup.object().shape({
  topic: Yup.string().required("Describe your points is required").min(2, "Describe your points must be at least 2 characters")
    .test('no-white-space', 'Describe your points should not contain white spaces', (value) => !/\s{2,}/.test(value)),
  keyword: Yup.string().required("HASHTAG is required")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Keyword should not contain multiple consecutive white spaces")
    .test('no-white-space', 'HASHTAG should not contain white spaces', (value) => !/\s{2,}/.test(value)),
  toneOfVoice: Yup.string().required("Tone of voice is required"),
  aiType: Yup.string().required("AI type is required"),
  upcomingEvent: Yup.string().optional(),
  cta: Yup.string().optional(),
  imageDesc: Yup.string().optional(),
});

const validationSchema2 = Yup.object().shape({
  // link: Yup.string().required("Insert Link is required").trim("white-space", "Link should not contain white spaces")
  //   .test('no-white-space', 'Insert Link should not contain white spaces', (value) => !/\s{2,}/.test(value)),
  link: Yup.string().url('Invalid URL').required('URL is required'),
  toneOfVoice: Yup.string().required("Tone of voice is required"),
  aiType: Yup.string().required("AI type is required"),
  upcomingEvent: Yup.string().optional(),
  cta: Yup.string().optional(),
  imageDesc: Yup.string().optional(),
});

const FirstStepSocialMediaDescribePost = ({ eventOptions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: postId } = useParams();
  const fileInputRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const [selectedCard, setSelectedCard] = useState(socialMediaPostInfo?.selectedCard);
  const [currentLan, setCurrentLan] = useState(socialMediaPostInfo?.lan || "English");
  const [generateLink, setGenerateLink] = useState(true);
  const [geoLocation, setGeoLocation] = useState("SG");
  const [isDisabledInput, setIsDisabledInput] = useState(postId ? true : false);
  const [toneOfVoiceOptions, setToneOfVoiceOptions] = useState([]);
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, reset, clearErrors, watch } = useForm({
    defaultValues: {
      topic: socialMediaPostInfo?.topic,
      keyword: socialMediaPostInfo?.keyword,
      link: socialMediaPostInfo?.link,
      toneOfVoice: socialMediaPostInfo?.toneOfVoice,
      aiType: (socialMediaPostInfo?.aiType || AiTypeOptions[0].value),
      upcomingEvent: socialMediaPostInfo?.eventName,
      cta: socialMediaPostInfo?.cta || "",
      imageDesc: socialMediaPostInfo?.imageDesc || "",
    },
    resolver: yupResolver((selectedCard === "link" || selectedCard === "pdf") ? validationSchema2 : validationSchema1),
  });

  useEffect(() => {
    reset({
      topic: socialMediaPostInfo?.topic || "",
      keyword: socialMediaPostInfo?.keyword || "",
      link: socialMediaPostInfo?.link || "",
      toneOfVoice: socialMediaPostInfo?.toneOfVoice || "",
      aiType: (socialMediaPostInfo?.aiType || AiTypeOptions[0].value),
      upcomingEvent: socialMediaPostInfo?.eventName || "",
      cta: socialMediaPostInfo?.cta || "",
      imageDesc: socialMediaPostInfo?.imageDesc || "",
    });
    setGeoLocation(socialMediaPostInfo?.geoLocation || "SG");
  }, [socialMediaPostInfo.PostType, postId])

  useEffect(() => {
    setIsDisabledInput(postId ? true : false);
  }, [postId])

  useEffect(() => {
    setSelectedCard(socialMediaPostInfo?.selectedCard);
  }, [socialMediaPostInfo?.selectedCard])

  useEffect(() => {
    if (toneOfVoiceOptions && toneOfVoiceOptions.length === 0) {
      handleGetToneOfVoiceOptionsList();
    }
    if (socialMediaPostInfo?.geoLocation) {
      setGeoLocation(socialMediaPostInfo?.geoLocation?.toUpperCase());
    }
  }, [socialMediaPostInfo?.geoLocation, socialMediaPostInfo?.toneOfVoice]);

  const handleGetToneOfVoiceOptionsList = async () => {
    try {
      setToneOfVoiceOptions([]);
      const response = await AxiosApi.get(`/api/tone-of-voice/get-options/byType?type=Social Media Post Writer`);
      if (response && response.data && response.data.length > 0) {
        if (socialMediaPostInfo?.PostType === "LinkedIns") {
          setToneOfVoiceOptions([...response.data]);
          getBrandVoiceHistoryList([...response.data]);
        } else {
          setToneOfVoiceOptions(response.data);
        }
      } else {
      }
    } catch (error) {
      setToneOfVoiceOptions([]);
    }
  }

  const getBrandVoiceHistoryList = async (defaultToneOfVoiceOptions = []) => {
    const url = `/api/brand-voice/list/${userRes.id}?type=companyId`;
    const customOptions = [];
    setToneOfVoiceOptions([...defaultToneOfVoiceOptions, ...customOptions]);

    AxiosApi.get(url).then((response) => {
      let lists = [];
      if ((response && response.data && response.data.data?.length > 0) || (response && response.data && response.data.otherList?.length > 0)) {
        lists = [...(response.data.data || []), ...(response.data.otherList || [])];
      }

      for (let index = 0; index < lists.length; index++) {
        const element = lists[index];
        if (element.is_active === 1 && element.fine_tuned_model && element.fine_tuned_model !== "") {
          customOptions.push({ label: element.name, value: element.fine_tuned_model });
        }
      }
      setToneOfVoiceOptions([...defaultToneOfVoiceOptions, ...customOptions]);
    }).catch((error) => { });
  }

  const handleOnChangeCardType = (type, value) => {
    if (socialMediaPostInfo?.selectedCard === type) {
      return false;
    }

    // dispatch(setLoader(true));
    setTimeout(() => { dispatch(setLoader(false)) }, 1000);

    setGenerateLink(true);
    if (type === "pdf") {
      setGenerateLink(false);
    }

    dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, selectedCard: type }));
    const toneVal = getValues("toneOfVoice");
    const upcomingEventVal = getValues("upcomingEvent");
    if (value) {
      setSelectedCard(type);
    }
    reset();
    if (toneVal) {
      setValue("toneOfVoice", toneVal, { shouldValidate: true });
    }
    if (upcomingEventVal) {
      setValue("upcomingEvent", upcomingEventVal, { shouldValidate: true });
    }
    clearErrors();
  }

  const handleNext = async (value) => {
    const data = trimedInputValue(value)
    if (postId) {
      if (selectedCard === "topic") {
        dispatch(setSocialMediaStepsInfo({
          ...socialMediaPostInfo, isCallApi: "false",
          stepDone: socialMediaPostInfo.stepDone < 3 ? 3 : socialMediaPostInfo.stepDone,
        }));
        dispatch(setSocialMediaPostStep(3));
      } else {
        dispatch(setSocialMediaStepsInfo({
          ...socialMediaPostInfo, isCallApi: "false",
          stepDone: socialMediaPostInfo.stepDone < 4 ? 4 : socialMediaPostInfo.stepDone,
        }));
        dispatch(setSocialMediaPostStep(4));
      }
    } else {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return;
      }

      const dataVal = {};
      Object.keys(data).map((ele) => {
        if (!data[ele]) {
          dataVal[ele] = "";
        } else {
          dataVal[ele] = data[ele];
        }
        return ele;
      });

      if (dataVal.topic && dataVal.keyword) {
        dataVal.topic = await removeSpecificSpecialCharacters(dataVal.topic);
        dataVal.keyword = await removeSpecificSpecialCharacters(dataVal.keyword);
      }

      dispatch(setLoader(true));
      const payload = {
        body: JSON.stringify({
          ...dataVal,
          user_id: userRes.id,
          postType: socialMediaPostInfo.PostType,
          postId: socialMediaPostInfo?.postId ? socialMediaPostInfo.postId : "",
          lan: currentLan, summaryType: selectedCard, geoLocation: geoLocation?.toLowerCase(),
        })
      };

      const response = await invokeLambdaFunction("generateSetsOfSocialMediaPost", payload);
      if (response && response.success === 1) {
        try {
          if (socialMediaPostInfo?.PostType && response.data && response.data.keyword) {
            const payload = {
              pathParameters: {
                postType: socialMediaPostInfo.PostType
              },
              body: JSON.stringify({
                postId: response.data?.postId,
                keyword: response.data.keyword
              })
            };
            invokeLambdaFunction("getSocialMediaTrendingPostByType", payload);
          }
        } catch (error) {
          console.log('Error: ', error);
        }

        dispatch(setLoader(false));
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
        navigate(`/social-media-post-writer/${response.data?.postId}`);
        // dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));

        if (selectedCard === "topic") {
          dispatch(setSocialMediaStepsInfo({
            ...socialMediaPostInfo, ...dataVal, ...response.data, lan: currentLan,
            geoLocation: geoLocation?.toLowerCase(),
            eventName: dataVal.upcomingEvent, selectedCard, isCallApi: "false",
            isDeductCredit: false, postRegenerateLeft: 3,
            stepDone: socialMediaPostInfo.stepDone < 3 ? 3 : socialMediaPostInfo.stepDone,
          }));
          dispatch(setSocialMediaPostStep(3));
        } else {
          dispatch(setSocialMediaStepsInfo({
            ...socialMediaPostInfo, ...dataVal, ...response.data, lan: currentLan,
            geoLocation: geoLocation?.toLowerCase(),
            eventName: dataVal.upcomingEvent, selectedCard, isCallApi: "true",
            isDeductCredit: true, postRegenerateLeft: 3,
            stepDone: socialMediaPostInfo.stepDone < 4 ? 4 : socialMediaPostInfo.stepDone,
            [selectedCard === "pdf" ? "pdf_link" : "insert_link"]: dataVal.link,
          }));
          dispatch(setSocialMediaPostStep(4));
        }
      } else {
        dispatch(setLoader(false));
        if (response?.error?.indexOf("link is not valid") !== -1) {
          toast.error("The link is not valid, please add another link.", {
            className: 'custom-toast-warning', icon: <IoIosWarning />, id: "Toast-01"
          });
        } else {
          toast.error(response?.error, { id: "Toast-01" });
        }
      }
    }
  }

  const handleOnSelectPdfFile = async (files) => {
    const file = files[0];
    setUploadedFile(files)
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 5 MB.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    if (file.type !== 'application/pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; }
      setValue("link", "");
      return false;
    }

    dispatch(setLoader(true));
    const filePath = await uploadPdfFileOnS3(file, "socialMedia");
    dispatch(setLoader(false));

    if (filePath && filePath !== "") {
      clearErrors("link");
      setValue("link", filePath);
      setUploadFileError(null);
    } else {
      toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      setValue("link", "");
    }
  }

  const formatGroupLabel = (data) => (
    <div className="select-group-header">
      <span className="select-group-title">{data.label}</span>
      <span className="select-group-badge">{data.options.length}</span>
    </div>
  );

  const FileUploaderCustomUi = (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: pdf <br /> maximum file size: 5MB.
      </p>
      {uploadFileError && uploadFileError !== "" ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : uploadedFile ? (
        <h5 className="fw-normal text-primary d-flex align-items-center justify-content-center text-center">
          {uploadedFile && uploadedFile[0]?.name ? uploadedFile[0]?.name : ""}
        </h5>
      ) : null}
    </div>
  );

  const handleDisableButton = () => {
    const cardTypeOne = ['pdf', 'link'];
    if (cardTypeOne.includes(selectedCard)) {
      if (!watch('toneOfVoice') || !watch('link') || !watch('aiType')) {
        return true;
      } else {
        return false;
      }
    } else if (selectedCard === 'topic') {
      if (!watch('toneOfVoice') || !watch('topic') || !watch('keyword') || !watch('aiType')) {
        return true;
      } else {
        return false;
      }
    }
    return false
  };

  return (
    <div className="socialMediaStepes">
      <div>
        {!selectedCard && (
          <React.Fragment>
            <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between ">
              <div className="">
                <b>Select how you want to write post </b>
                <span>(Select any 1)</span>
              </div>
              <button disabled={!socialMediaPostInfo?.selectedCard} type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton " onClick={() => setSelectedCard(socialMediaPostInfo?.selectedCard)} >Next <BsArrowRight /></button>
            </div>
            <div className="social-media-images">
              {[
                {
                  label: "Provide a Brief Description", value: "topic", icon: blogCoPilotIcon.contractIcon, iconColor: "#E6EBFF",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} Captions`} by describing your preferred content topic, or providing a description of the post’s photo in one sentence.`,
                  desc2: `Describe in one sentence the topic you’d like to write about.`,
                },
                {
                  label: "Insert Your Reference Link", value: "link", icon: blogCoPilotIcon.referenceLinkIcon, iconColor: "rgba(255, 190, 157, 0.20)",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} captions`} effortlessly by simply copying and pasting your desired reference website link to create perfect ${socialMediaPostInfo?.PostType === "Twitter" ? "tweets" : "captions"}.`,
                  desc2: `Add the URL to a website page, article, or post. The Social Media Writer will use the contents of the linked page as reference when generating your content.`,
                },
                {
                  label: "Upload Your PDF", value: "pdf", icon: blogCoPilotIcon.pdfIcon, iconColor: "rgba(167, 255, 23, 0.20)",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} captions`} by putting your preferred content of upload PDF.`,
                  desc2: `Add your PDF. The Social Media Writer will use the contents of the PDF as reference when generating your content.`,
                },
              ].map((ele, index) => (
                <div
                  className={`social-media-card text-center ${socialMediaPostInfo?.selectedCard === ele.value ? "active" : ""}`} key={index}
                  onClick={() => handleOnChangeCardType(ele.value, false)}
                >
                  <div className={`radioBtn`}>
                    <div className={`radioBtnIn`}></div>
                  </div>
                  <div className="imageFrame lightBlue ms-auto me-auto " style={{ backgroundColor: ele.iconColor, border: `1px solid ${ele.iconColor}` }}>
                    <img src={ele.icon} />
                  </div>
                  <h5
                    className="card-title w-100"
                    type="button"
                  >
                    {ele.label}
                  </h5>
                  <p className=" text-center">{ele.desc1}</p>
                  <p className="mt-2 text-center">{ele.desc2}</p>
                </div>
              ))}
            </div>
            <div className="d-flex align-items-start justify-content-center  align-items-center flex-row pt-0 gap-2 bottomButton">
              <button type="button" className="addlly-outline "
                onClick={() => {
                  dispatch(setSocialMediaStepsInfo({ PostType: "" }));
                  dispatch(setSocialMediaPostStep(1));
                  navigate("/social-media-post-writer");
                  const scrollWrapper = document.getElementById('scroll-wrapper');
                  if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
                }}
              >
                <span><BsArrowLeft /> Previous</span>
              </button>
              <div className='d-flex align-items-center gap-3'>
                <button disabled={!socialMediaPostInfo?.selectedCard} type="submit" className="addlly-primary" style={{ whiteSpace: "nowrap" }} onClick={() => setSelectedCard(socialMediaPostInfo?.selectedCard)}>
                  <span>Next</span> <BsArrowRight />
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {/* Enter Details Section */}
      <form onSubmit={handleSubmit(handleNext)} >
        {selectedCard && selectedCard !== "" ? (
          <React.Fragment>
            <div className="selectBlock d-flex flex-wrap align-items-center justify-content-between gap-3 mx-2 mx-md-auto">
              <div className="">
                <b>Select how you want to write post </b>
                <span>(Select any 1)</span>
              </div>
              <button type="submit" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton " disabled={!selectedCard || handleDisableButton()}
                onClick={() => { if (socialMediaPostInfo.stepDone > 2) { socialMediaPostInfo.selectedCard !== "topic" ? dispatch(setSocialMediaPostStep(4)) : dispatch(setSocialMediaPostStep(3)) } setSelectedCard(socialMediaPostInfo?.selectedCard) }}
              >{`Generate ${selectedCard === "topic" ? "Links" : "Post"}`}<BsArrowRight /></button>
            </div>
            <div className={`social-media-images ${socialMediaPostInfo?.stepDone > 3 ? "not-selectable" : ""}`}>
              {[
                {
                  label: "Provide a Brief Description", value: "topic", icon: blogCoPilotIcon.contractIcon, iconColor: "#E6EBFF",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} Captions`} by describing your preferred content topic, or providing a description of the post’s photo in one sentence.`,
                  desc2: `Describe in one sentence the topic you’d like to write about.`,
                },
                {
                  label: "Insert Your Reference Link", value: "link", icon: blogCoPilotIcon.referenceLinkIcon, iconColor: "rgba(255, 190, 157, 0.20)",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} captions`} effortlessly by simply copying and pasting your desired reference website link to create perfect ${socialMediaPostInfo?.PostType === "Twitter" ? "tweets" : "captions"}.`,
                  desc2: `Add the URL to a website page, article, or post. The Social Media Writer will use the contents of the linked page as reference when generating your content.`,
                },
                {
                  label: "Upload Your PDF", value: "pdf", icon: blogCoPilotIcon.pdfIcon, iconColor: "rgba(167, 255, 23, 0.20)",
                  desc1: `Generate ${socialMediaPostInfo?.PostType === "Twitter" ? "Tweets" : `${socialMediaPostInfo?.PostType} captions`} by putting your preferred content of upload PDF.`,
                  desc2: `Add your PDF. The Social Media Writer will use the contents of the PDF as reference when generating your content.`,
                },
              ].map((ele, index) => (
                <div
                  className={`social-media-card justify-content-center text-center ${socialMediaPostInfo?.selectedCard === ele.value || (index === 0 && !socialMediaPostInfo?.selectedCard) ? "active" : ""}`}
                  key={index}
                  onClick={() => handleOnChangeCardType(ele.value, true)}
                >
                  <div className="radioBtn">
                    <div className="radioBtnIn"></div>
                  </div>
                  <div className="imageFrame lightBlue ms-auto me-auto" style={{ backgroundColor: ele.iconColor, border: `1px solid ${ele.iconColor}` }}>
                    <img src={ele.icon} alt="icon" />
                  </div>
                  <h5 className="card-title w-100" type="button">
                    <strong>{ele.label}</strong>
                  </h5>
                </div>
              ))}
            </div>
            <div className="">
              <div className="selectBlock d-flex align-items-center justify-content-between ">
                <div className="">
                  <b>Enter Details to Generate {socialMediaPostInfo?.PostType === "Instagram" ? "an " : "a "}
                    {socialMediaPostInfo?.PostType ? socialMediaPostInfo?.PostType.replace("Twitter", "X ( Twitter )") : "Social Media"} Post</b>
                </div>
              </div>
              <div className="genrateFields pb-0">
                {selectedCard === "link" ? (
                  <Row>
                    <Col sm="12" md="12">
                      <div className="form-group mb-4">
                        <div className="fields">
                          <label>Need a post crafted around your website link?<span className="required-astrick"> *</span></label>
                          <BsInfoCircleFill
                            className="ms-2 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
                            data-tooltip-content="AI model suggestions is based on user inputs"
                          />
                          <div className="inputField">
                            <input
                              name="link" type="text" placeholder="Insert your link here and let's transform it into a compelling social media post!"
                              {...register("link")} className={`w-100 addllyForm-control ${errors.link ? "is-invalid" : ""}`} disabled={isDisabledInput}
                            />
                            <div className="invalid">{errors.link?.message}</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : selectedCard === "pdf" ? (
                  <Row>
                    <Col sm="12" md="12">
                      <div className="mb-4">
                        <div className='fileUploadBlock lightBg '>
                          <FileUploader
                            name="file" types={["pdf"]} maxSize={5} children={FileUploaderCustomUi}
                            multiple={true} classes={`w-100`} handleChange={handleOnSelectPdfFile}
                            disabled={socialMediaPostInfo?.link}
                            onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                            onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                            onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
                          />
                        </div>
                        {!uploadedFile && watch("link") && watch("link") !== "" && (
                          <div className="d-flex align-items-center history-table mt-2">
                            <AiOutlineFilePdf className="fs-5 me-3" style={{ minWidth: "24px" }} />
                            <a href={watch("link")} target="_blank" rel="noreferrer"
                              className="text-decoration-none text-width" style={{ maxWidth: "100%" }}
                            >
                              {watch("link").split("/")[watch("link").split("/").length - 1]}
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm="12" md="12" className='mb-3 summeryBlock'>
                      <div className="form-group fields">
                        <label>Describe your points<span className="text-danger fs-6 ms-1">*</span></label>
                        <div className="input-with-mic position-relative">
                          <textarea
                            name="topic" type="text" {...register("topic")} rows="4"
                            placeholder={socialMediaPostInfo?.PostType === "Instagram" ? "Example: First time trying..." : "Example: 1. Best way to get the most out of ..."}
                            className={`w-100 addllyForm-control ${errors.topic ? "is-invalid" : ""}`} disabled={isDisabledInput}
                            style={{ borderRadius: "5px" }}
                          />
                          {!isDisabledInput && <SpeechToText name={"topic"} setValue={setValue} />}
                          <div className="invalid">{errors.topic?.message}</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  {selectedCard !== "pdf" && <Col sm="12" md="6" lg="4">
                    <div className="form-group mb-4">
                      <div className="fields">
                        <label>One main, most relevant <b>HASHTAG</b><span className="required-astrick"> *</span></label>
                        <BsInfoCircleFill
                          data-tooltip-id="my-tooltip" data-tooltip-place="right"
                          data-tooltip-content="AI model suggestions is based on user inputs"
                          className="ms-2 infoIconSvg"
                        />
                        <div className="input-with-mic position-relative inputField">
                          <input
                            name="keyword" type="text" placeholder="Example: #Google" {...register("keyword")}
                            className={`w-100 addllyForm-control ${errors.keyword ? "is-invalid" : ""}`} disabled={isDisabledInput}
                          />
                          {!isDisabledInput && <SpeechToText name={"keyword"} setValue={setValue} />}
                          <div className="invalid">{errors.keyword?.message}</div>
                        </div>
                      </div>
                    </div>
                  </Col>}
                  <Col sm="12" md="6" lg={selectedCard === "pdf" ? '6' : '4'}>
                    <div className="form-group mb-4">
                      <label>Tone of Voice<span className="required-astrick"> *</span></label>
                      <BsInfoCircleFill
                        data-tooltip-id="my-tooltip" data-tooltip-place="right"
                        data-tooltip-content="AI model suggestions is based on user inputs"
                        className="ms-2 infoIconSvg"
                      />
                      <Controller
                        control={control} name="toneOfVoice" {...register("toneOfVoice")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className={`custom-select ${errors.toneOfVoice ? "is-invalid" : ""}`}
                            classNamePrefix="custom-select" placeholder="Select Tone of Voice"
                            options={toneOfVoiceOptions} isClearable={false} inputRef={ref}
                            value={toneOfVoiceOptions.find(c => c.value === value)} isSearchable={false}
                            onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                          />
                        )}
                      />
                      <div className="invalid">{errors.toneOfVoice?.message}</div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg={selectedCard === "pdf" ? '6' : '4'}>
                    <div className="mb-4">
                      <div className="form-group position-relative languageDrop">
                        <label>Select your geo-location </label>
                        <BsInfoCircleFill
                          data-tooltip-id="my-tooltip" data-tooltip-place="right"
                          data-tooltip-content="AI model suggestions is based on user inputs"
                          className="ms-2 infoIconSvg"
                        />
                        <ReactFlagsSelect
                          className={'reactFlag p-0 w-100 '}
                          searchable={true}
                          selected={geoLocation}
                          onSelect={(e) => setGeoLocation(e)}
                          disabled={isDisabledInput}
                        />
                        <div className='arrowIcon'>
                          <IoIosArrowDown className='downArrowIcon' />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg="4">
                    <div className="form-group mb-4">
                      <div className="d-flex align-items-center" style={{ marginBottom: '10px' }}>
                        <label className="me-2 mb-0">Key Events Calendar (Optional)</label>
                      </div>
                      <Controller
                        control={control} name="upcomingEvent" {...register("upcomingEvent")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className={`custom-select ${errors.upcomingEvent ? "is-invalid" : ""}`} isSearchable={false}
                            classNamePrefix="custom-select" placeholder="Select key calendar event"
                            options={eventOptions} isClearable={true} inputRef={ref} menuPlacement="top"
                            onChange={(e) => onChange(e ? e.value : "")} formatGroupLabel={formatGroupLabel} isDisabled={isDisabledInput}
                            value={value ? eventOptions.flatMap(group => group.options).find(option => option.value === value) : ''}
                          />
                        )}
                      />
                      <div className="invalid">{errors.upcomingEvent?.message}</div>
                    </div>
                  </Col>

                  {/* {socialMediaPostInfo?.PostType === "Instagram" && (
                    <Col sm="12" md="6">
                      <div className="form-group mb-4">
                        <label>Describe the Image (Optional)</label>
                        <input
                          name="imageDesc" type="text" placeholder="Insert image description" {...register("imageDesc")}
                          className={`addllyForm-control ${errors.imageDesc ? "is-invalid" : ""}`} disabled={isDisabledInput}
                        />
                        <div className="invalid">{errors.imageDesc?.message}</div>
                      </div>
                    </Col>
                  )} */}
                  <Col sm="12" md="4">
                    <div className="form-group mb-4">
                      <div className="fields">
                        <label>Add Call to Action (Optional)</label>
                        <div className="inputField">
                          <input
                            name="cta" type="text" placeholder="Example: Download Now" {...register("cta")}
                            className={`w-100 addllyForm-control ${errors.cta ? "is-invalid" : ""}`} disabled={isDisabledInput}
                          />
                          <div className="invalid">{errors.cta?.message}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="4" className="chooseModel ">
                    <div className=" fields form-group mb-4">
                      <label>Choose your AI Model</label>
                      <Controller
                        control={control} name="aiType" {...register("aiType")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className={`custom-select ${errors.aiType ? "is-invalid" : ""}`} inputRef={ref}
                            classNamePrefix="custom-select" placeholder="Select AI Type"
                            options={AiTypeOptions} isClearable={false} defaultValue={AiTypeOptions[0]}
                            value={AiTypeOptions.find(c => c.value === value)}
                            onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                            menuPosition="fixed" isSearchable={false}
                          />
                        )}
                      />
                      {/* {watch("aiType") !== "" && watch("aiType") !== "GPT 3.5" && (
                        <div className="selectedOptions">
                          <span>Addlly suggests using <strong>GPT 3.5</strong></span>
                        </div>
                      )} */}
                      <div className="invalid">{errors.aiType?.message}</div>
                    </div>
                  </Col>
                  <Col sm="12" md="4">
                    <div className="form-group mb-0">
                      <label>Choose a Language<span className="text-danger fs-6 ms-1">*</span></label>
                      <Controller
                        control={control} name="lan" {...register("lan")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                            classNamePrefix="custom-select" placeholder="Select Language"
                            options={supportedLanguageList} isClearable={false} isSearchable={false}
                            value={supportedLanguageList.find(c => c.value === currentLan) || ""}
                            getOptionLabel={handleCustomSelectOption} isDisabled={isDisabledInput}
                            onChange={(event) => {
                              onChange(event.value); setCurrentLan(event.value);
                              handleSetLanguageAndGeoLocation(event, setValue, setGeoLocation);
                            }}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-flex align-items-start justify-content-center  align-items-center flex-row gap-2 bottomButton">
                <button type="button" className="addlly-outline "
                  onClick={() => {
                    // dispatch(setSocialMediaStepsInfo({ PostType: "" }));
                    dispatch(setSocialMediaPostStep(1));
                    // navigate("/social-media-post-writer");
                    const scrollWrapper = document.getElementById('scroll-wrapper');
                    if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
                  }}
                >
                  <span><BsArrowLeft /> Previous</span>
                </button>
                <div className='d-flex align-items-center'>
                  <button
                    type="submit" disabled={!selectedCard || handleDisableButton()} className="addlly-primary"
                    style={{ whiteSpace: "nowrap" }} onClick={() => { if (socialMediaPostInfo.stepDone > 2) { socialMediaPostInfo.selectedCard !== "topic" ? dispatch(setSocialMediaPostStep(4)) : dispatch(setSocialMediaPostStep(3)) } setSelectedCard(socialMediaPostInfo?.selectedCard) }}
                  >
                    <span><BsStars /> {`Generate ${selectedCard === "topic" ? "Links" : "Post"}`}<BsArrowRight /></span>
                  </button>
                  {!postId && (
                    <BsInfoCircleFill
                      className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
                      data-tooltip-content={`Will Utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly Credits`}
                    />
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </form>
    </div>
  )
}

export default FirstStepSocialMediaDescribePost;
