import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import Swal from "sweetalert";
import { Tooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsInfoCircleFill } from "react-icons/bs";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Sidebar from "./Sidebar";
import NavItems from "./NavItems";
import { AxiosApi } from "../../../../utility/axios";
import { oneClickBlogIcon } from "../../../../Assets/Icons";
import GoDashboard from "../../../../Components/Modal/GoDashboard";
import HtmlViewer from "../../../../Components/SeoBlogWriter/HtmlViewer";
import SeoScorePreview from "../../../../Components/commonComponents/SeoScorePreview";
import FaqSchemaArtical from "../../../../Components/commonComponents/FaqSchemaArticle";
import MediaLibraryDrawer from "../../../../Components/commonComponents/MediaLibrary/MediaLibraryDrawer";
import FactCheckerPreview from "../../../../Components/commonComponents/FactCheckerPreview";
import ReviewTextEditor from "../../../../Components/commonComponents/ReviewTextEditor/Index";
import RefundRequestsHistory from "../../../../Components/commonComponents/RefundRequestsHistory";
import AiOverviewTabComponent from "../../../../Components/commonComponents/AiOverviewTabComponent";
import MainContent from "../../../../Components/SeoBlogWriterNew/second-step/MainContent";
import { setGeneratedFullArticle, setLoader, setUserRes } from "../../../../store/reducer";

const SecondStep = (props) => {
  const {
    setToggle, toggle, htmlText, handleGetAllImagesAndOpenModal, edit, setEdit, finalHtmlText, setHtmlText, editorRef, editorKey,
    handleCopyHtmlArticleText, contentScore, generatedFullArticle, isSeoScoreDisplay, postImagesModal, setPostImagesModal, handleAutoFactChecker,
    handleSaveArticleOrPostText, postGeneratedImages, setPostGeneratedImages, getFullData, selectedSubTab, setSelectedSubTab,
    articleType, userRes, handleShareSocialMediaPost, setSelectedCurrentTab, setPostImagesCarousel,
    getBlogVersionHistory, handleSetRestoredOldVersionText, savedModal, setSavedModal
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const [URLSearchParams] = useSearchParams();
  const isFullScreen = URLSearchParams.get("full-screen");
  const selectedTab = URLSearchParams.get("tab") || "article";
  const [toggleRightSideBar, setToggleRightSideBar] = useState(false);
  const [sendToEditorForm, setSendToEditorForm] = useState(false);
  const [seoScoreMobile, setSeoScoreMobile] = useState(window.innerWidth <= 1024);
  const [show, setShow] = useState(false);
  const reviewEditorChildRef = useRef();
  const editorRef3 = useRef();

  useEffect(() => {
    setToggleRightSideBar(false);
    setToggle(false);
  }, [selectedTab]);

  useEffect(() => {
    const handleResize = () => {
      setShow(false);
      setToggleRightSideBar(window.innerWidth <= 1024);
      setSeoScoreMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const BlogGeneratedTime = useMemo(() => {
    try {
      const articleLogs = generatedFullArticle?.logs ? JSON.parse(generatedFullArticle?.logs || '') : [];
      const timestamps = articleLogs.slice(0, 9).filter((str) => (str?.startsWith(generatedFullArticle?.topic)));
      const startTime = timestamps?.[0]?.split('--')[1]?.replace(/"/g, '').trim()?.replace(/-/g, '');
      const endTime = timestamps?.[timestamps?.length - 1]?.split('--')[1]?.replace(/"/g, '').trim()?.replace(/-/g, '');
      return (new Date(endTime) - new Date(startTime)) / 1000;
    } catch (error) {
      return "";
    }
  }, [generatedFullArticle?.logs, generatedFullArticle?.topic]);

  const handleSideButton = () => {
    const tabs = ["article", "longArticle"];
    if (tabs.includes(selectedTab)) {
      let tempContentNewScore = null;
      if (selectedTab === "article" && contentScore && Object.keys(contentScore).length > 0 && isSeoScoreDisplay && generatedFullArticle?.article_html) {
        tempContentNewScore = contentScore;
      } else if (selectedTab === "longArticle" && contentScore && Object.keys(contentScore).length > 0 && isSeoScoreDisplay && generatedFullArticle?.SEO_score_long_article) {
        tempContentNewScore = generatedFullArticle?.SEO_score_long_article?.data ? generatedFullArticle?.SEO_score_long_article.data : generatedFullArticle?.SEO_score_long_article;
      }

      if (tempContentNewScore && Object.keys(tempContentNewScore).length > 0) {
        const word_score = (tempContentNewScore["Word count"]["SEO Score"] * 100) / tempContentNewScore["Word count"]["Max SEO score available"];
        const related_keywords_score = (tempContentNewScore["Related keywords"]["SEO Score"] * 100) / tempContentNewScore["Related keywords"]["Max SEO score available"];

        return (
          <div className={classNames("sideBarOpenBtn")}>
            <button
              className="border-0" onClick={() => { setToggleRightSideBar((prev) => seoScoreMobile ? true : !prev); setShow((prev) => seoScoreMobile ? true : false); }}
              data-tooltip-content={!toggleRightSideBar ? "Hide SEO Score" : "Show SEO Score"} data-tooltip-place="bottom"
            >
              {seoScoreMobile ? (
                <React.Fragment>
                  <GoArrowRight />
                  <p><strong><span className="textB">SEO Score</span></strong></p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <GoArrowRight />
                  <p className="">
                    <span>
                      <strong>{Math.round(word_score)} %</strong><span className="textB">Word Score</span>
                    </span>
                    <span>|</span>
                    <span>
                      <strong>{Math.round(related_keywords_score)} %</strong><span className="textB">Related Keywords</span>
                    </span>
                  </p>
                  <Tooltip id="sidebar-toogle" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
                </React.Fragment>
              )}
            </button>
            <Tooltip id="sidebar-toogle" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
          </div>
        );
      }
    } else if (selectedTab === "faqSchema") {
      return (
        <div className={classNames("sideBarOpenBtn")}>
          <button
            className="border-0" onClick={() => setToggleRightSideBar((prev) => !prev)} data-tooltip-id="sidebar-toogle-faq"
            data-tooltip-content={!toggleRightSideBar ? "Hide FAQ Schema" : "Show FAQ Schema"} data-tooltip-place="bottom"
          >
            <GoArrowRight />
            <p className="">
              <span>|</span>
              <span className="FAQ-OpenBtn-text">View FAQ's Markup Schema</span>
            </p>
          </button>
          <Tooltip id="sidebar-toogle-faq" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      );
    } else if (selectedTab === "reviewArticle") {
      return (
        <div className={classNames("sideBarOpenBtn")}>
          <button
            className="border-0" onClick={() => setToggleRightSideBar((prev) => !prev)} data-tooltip-id="sidebar-toogle-comment"
            data-tooltip-content={!toggleRightSideBar ? "Hide Comments" : "Show Comments"} data-tooltip-place="bottom"
          >
            <GoArrowRight />
            <p className="">
              <span>|</span> <span>View Comments</span>
            </p>
          </button>
          <Tooltip id="sidebar-toogle-comment" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      );
    }

    return "";
  };

  const handleSendArticleToReviewer = async (data) => {
    try {
      dispatch(setLoader(true));
      const payload = {
        userId: userRes.id, articleType: articleType, articleSubType: "Short Article",
        organizationId: userRes?.organization_id ? userRes.organization_id : "",
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        articleText: edit?.article_html ? edit.article_html : "", ...data
      };

      const response = await AxiosApi.post(`/api/editorial-comment/send-to-reviewer`, payload);
      if (response && response.data && response.data.success === 1) {
        dispatch(setLoader(false));
        if (data.isSentToReviewer && data.isSentToReviewer === true) {
          Swal({ icon: "success", title: "Your blog has been sent for review!", text: "Please give us 1 to 2 days to revert." });
          setSelectedCurrentTab("reviewArticle");
          dispatch(setGeneratedFullArticle({ ...generatedFullArticle, is_check_reviewer: 1 }));
          if (!userRes?.organization_id) {
            dispatch(setUserRes({ ...userRes, credits: userRes.credits - data.deductCredits }));
          }
        }

        return response.data;
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!", { id: "Toast-01" });
    }
  };

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  const handleSaveClose = () => setSavedModal(false);

  return (
    <React.Fragment>
      <div className={classNames("topBar d-flex gap-3 justify-content-between mb-4", { "d-none": isFullScreen })}>
        <div className="headingSide d-flex align-items-center">
          <strong className="d-flex align-items-center">
            <ReactSVG src={oneClickBlogIcon.clickOneClickBlog} useRequestCache /> {type === "long-blog" ? "Oona Long Blog Writer" : "Oona 1-Click Blog Writer"}
          </strong>
          <span>{BlogGeneratedTime ? `Blog generated in ${BlogGeneratedTime} seconds` : 'Pending....'}</span>
        </div>
        <div className="backtoHome d-flex gap-3 justify-content-between align-items-center text-center">
          <div className="backBtn">
            <Link to={"/oona-1-click/short-blog"} className="bg-transparent border-0">
              <GoArrowLeft /> Back to home
            </Link>
          </div>
        </div>
      </div>

      <div className="blogWriterArea card d-flex flex-row">
        <div className={classNames("sideBarB", { "d-none": isFullScreen })}>
          <Sidebar setSelectedCurrentTab={setSelectedCurrentTab} />
        </div>

        {selectedTab === "aiOverview" ? (
          <AiOverviewTabComponent
            articleType={articleType} articleId={generatedFullArticle?.articleId}
            articleHtml={edit?.article_html} isFullScreen={isFullScreen}
          />
        ) : (
          <div className={`blogWriterBlock position-relative`}>
            <div className="navItemsBlock">
              <NavItems
                generatedFullArticle={generatedFullArticle} handleAutoFactChecker={handleAutoFactChecker} handleSaveArticleOrPostText={handleSaveArticleOrPostText}
                getFullData={getFullData} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal} selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab}
                articleType={articleType} setPostImagesCarousel={setPostImagesCarousel} getBlogVersionHistory={getBlogVersionHistory}
                handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                handleShareSocialMediaPost={handleShareSocialMediaPost} setSendToEditorForm={setSendToEditorForm}
                handleSaveReviewArticleText={handleSaveReviewArticleText} edit={edit}
              />
            </div>

            <div className={classNames(
              "editableTextArea d-flex overflow-hidden position-relative", selectedTab,
              { removeSideBar: toggleRightSideBar && ["article", "faqSchema", "reviewArticle"].includes(selectedTab) },
              { 'faqTab f-50': selectedTab === "faqSchema" }, { 'f-50': selectedTab === "googleAdCopy" }
            )}>
              {selectedTab === "article" ? (
                <React.Fragment>
                  {toggle ? (
                    <HtmlViewer
                      setToggle={setToggle} value={edit?.article_html} html={finalHtmlText}
                      onEditorChange={(e) => setEdit((pre) => ({ ...pre, "article_html": e }))}
                    />
                  ) : (
                    <div className="textEditerArea">
                      <MainContent
                        column={"article_html"} setToggle={setToggle} toggle={toggle} htmlText={htmlText} setEdit={setEdit}
                        value={edit?.article_html} finalHtmlText={finalHtmlText} setHtmlText={setHtmlText}
                        editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                        articleId={generatedFullArticle?.articleId} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                      />
                    </div>
                  )}
                  <div className="toggleData">
                    <React.Fragment>
                      {contentScore && Object.keys(contentScore).length > 0 && isSeoScoreDisplay && generatedFullArticle?.article_html ? (
                        seoScoreMobile ? (
                          <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                SEO score <BsInfoCircleFill className="infoIconSvg" data-tooltip-id="seo-score"
                                  data-tooltip-content="Valid only for articles generated in “English”"
                                  data-tooltip-place="bottom" />
                                <Tooltip id="seo-score" style={{ borderRadius: "6px", fontSize: "11px", padding: "4px 12px", zIndex: 9999 }} />
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="offcanvas_seo_score">
                              <SeoScorePreview contentScore={contentScore} article={edit?.article_html} col={12} show={show} />
                            </Offcanvas.Body>
                          </Offcanvas>
                        ) : (
                          <SeoScorePreview contentScore={contentScore} article={edit?.article_html} col={12} show={show} />
                        )
                      ) : (
                        <div style={{ height: "100%", background: "var(--Black-B30)", borderRadius: "10px" }}></div>
                      )}
                    </React.Fragment>
                  </div>
                </React.Fragment>
              ) : selectedTab === "faqSchema" ? (
                <React.Fragment>
                  {toggle ? (
                    <HtmlViewer
                      setToggle={setToggle} value={edit?.FAQHTML} html={generatedFullArticle?.FAQHTML}
                      onEditorChange={(e) => setEdit((pre) => ({ ...pre, "FAQHTML": e }))}
                    />
                  ) : (
                    <div className={`textEditerArea ${!edit?.FAQHTML && 'notContent'}`}>
                      <MainContent
                        column={"FAQHTML"} setToggle={setToggle} toggle={toggle} htmlText={htmlText} setEdit={setEdit}
                        value={edit?.FAQHTML} finalHtmlText={finalHtmlText} setHtmlText={setHtmlText} articleId={generatedFullArticle?.articleId}
                        editorRef={editorRef3} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                      />
                    </div>
                  )}
                  <FaqSchemaArtical setEdit={setEdit} edit={edit} />
                </React.Fragment>
              ) : selectedTab === "factChecker" ? (
                <FactCheckerPreview searchResponse={generatedFullArticle?.search_response} col={12} type="top3" />
              ) : selectedTab === "reviewArticle" ? (
                <ReviewTextEditor
                  isSelectedCurrentTab={selectedTab === "reviewArticle" ? true : false} articleType={articleType}
                  articleId={generatedFullArticle?.articleId} articleSubType={"Short Article"} sendToEditorForm={sendToEditorForm}
                  isReviewArticle={generatedFullArticle?.is_check_reviewer || 0} toggleRightSideBar={toggleRightSideBar}
                  handleSendArticleToReviewer={handleSendArticleToReviewer} setSendToEditorForm={setSendToEditorForm}
                  ref={reviewEditorChildRef}
                />
              ) : selectedTab === "chart" ? (
                ""
              ) : selectedTab === "refundRequests" ? (
                <RefundRequestsHistory articleType={articleType} articleId={generatedFullArticle?.articleId} />
              ) : null}
            </div>
            {handleSideButton()}
          </div>
        )}
      </div>

      <GoDashboard
        show={savedModal} onHide={handleSaveClose}
        goDashboard={() => { handleSaveClose(); navigate("/oona-1-click/short-blog"); }}
      />
      {postImagesModal && postImagesModal.open && (
        <div className="genrateAiImages">
          <MediaLibraryDrawer
            postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} editorRef={editorRef}
            postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
            articleType={articleType} userRes={userRes} articleId={generatedFullArticle?.articleId}
          />
        </div>
      )}

    </React.Fragment>
  );
};

export default SecondStep;
