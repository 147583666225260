import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import Swal from "sweetalert";
import { Buffer } from "buffer";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import { Offcanvas, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { BsInfoCircleFill } from "react-icons/bs";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import GoDashboard from "../../Modal/GoDashboard";
import LeaveWithoutSaving from "../../Modal/LeaveWithoutSaving";
import { invokeLambdaFunction } from "../../../utility/invokeLamdaFunction";
import { AxiosApi } from "../../../utility/axios";
import { dashboardIcon } from "../../../Assets/Icons";
import HtmlViewer from "../../SeoBlogWriter/HtmlViewer";
import SeoOptimizedBlogHead from "../SeoOptimizedBlogHead";
import Sidebar from "../SeoOptSixthStepComponents/Sidebar";
import NavItems from "../SeoOptSixthStepComponents/NavItems";
import MainContent from "../SeoOptSixthStepComponents/MainContent";
import MediaLibraryDrawer from "../../commonComponents/MediaLibrary/MediaLibraryDrawer";
import SeoScorePreview from "../../commonComponents/SeoScorePreview";
import FactCheckerPreview from "../../commonComponents/FactCheckerPreview";
import GoogleAdCopy from "../../commonComponents/GoogleAdCopy";
import LinkedinArticle from "../../commonComponents/LinkedinArticle";
import FacebookArticle from "../../commonComponents/FacebookArticle";
import TwitterArticle from "../../commonComponents/TwitterArticle";
import InstagramArticle from "../../commonComponents/InstagramArticle";
import FaqSchemaArtical from "../../commonComponents/FaqSchemaArticle";
import ReviewTextEditor from "../../commonComponents/ReviewTextEditor/Index";
import RefundRequestsHistory from "../../commonComponents/RefundRequestsHistory";
import AiOverviewTabComponent from "../../commonComponents/AiOverviewTabComponent";
import { handleGetOrGenerateAllTypeImages } from "../../../utility/hepler";
import { handleShareSocialMediaTypePost } from "../../../utility/common";
import {
  resetSEOFLow, setFinalContent, setHistoryData, setHistoryDataLength,
  setLoader, setSeoOptBlogStep, setSeoOptBlogTopic, setUserRes
} from "../../../store/reducer";
const beautify_html = require("js-beautify").html;

const BcaCoPilotSevenStep = ({ handleGetArticleById }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const [searchParams] = useSearchParams();
  const finalContent = useSelector(({ finalContent }) => finalContent);
  const selectedTopic = useSelector(({ selectedTopic }) => selectedTopic);
  const googleSuggestKeyword = useSelector(({ googleSuggestKeyword }) => googleSuggestKeyword);
  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const setsOfSeoOptimized = useSelector(({ setsOfSeoOptimized }) => setsOfSeoOptimized);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const headline = useSelector(({ headline }) => headline);
  const userRes = useSelector(({ userRes }) => userRes);

  const [toggle, setToggle] = useState(false);
  const [conform, setConform] = useState(false);
  const [savedModal, setSavedModal] = useState(false);

  const [editorState, setEditorState] = useState("");
  const [displayContent, setDisplayContent] = useState({});
  const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "article");
  const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get('subTab') ? searchParams.get('subTab') : "seoScore");

  const [contentScore, setContentScore] = useState({});
  const [isTyping, setIsTyping] = useState({ linkedIn: false, facebook: false, twitter: false, instagram: false, AdCopyWrite: false });
  const [pdfBase64, setPdfBase64] = useState("");
  const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
  const [postGeneratedImages, setPostGeneratedImages] = useState({});
  const [regenerateBtnInfo, setRegenerateBtnInfo] = useState({ generating: false, text: "" });
  const [generateProgress, setGenerateProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [seoScoreMobile, setSeoScoreMobile] = useState(window.innerWidth <= 1024);
  const [seocommnetsMobile, setSeoCommentMobile] = useState(false);
  const articleType = "Blog Co-Pilot";

  useEffect(() => {
    setSelectedCurrentTab(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "article");
    setSelectedSubTab(searchParams && searchParams.get('subTab') ? searchParams.get('subTab') : "seoScore");
  }, [searchParams]);

  useEffect(() => {
    const handleResize = () => {
      setShow(false);
      setToggleRightSideBar(window.innerWidth <= 1024);
      setSeoScoreMobile(window.innerWidth <= 1024);
      setSeoCommentMobile(window.innerWidth <= 1269);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  useEffect(() => {
    setEditorState(finalContent.article_html);
    setDisplayContent({
      article_html: finalContent?.article_html,
      FAQHTML: finalContent?.FAQHTML, FAQschema: finalContent?.FAQschema,
      linkedIn_post: finalContent?.linkedIn_post, facebook_post: finalContent?.facebook_post,
      twitter_post: finalContent?.twitter_post, instagram_post: finalContent?.instagram_post,
      instagram_reels: finalContent?.instagram_reels, googleAdCopy: finalContent?.googleAdCopy,
      popular_hashtags: finalContent?.popular_hashtags
    })
  }, [finalContent]);

  useEffect(() => {
    if (seoOptBlogTopic && seoOptBlogTopic.blog_type && seoOptBlogTopic.blog_type === "Pdf") {
      if (seoOptBlogTopic.pdf_link && pdfBase64 === "") {
        axios.get(seoOptBlogTopic.pdf_link, { responseType: 'arraybuffer' }).then((pdf) => {
          if (pdf && pdf.data) {
            setPdfBase64(`data:application/pdf;base64,${Buffer.from(pdf.data).toString("base64")}`);
          }
        }).catch((error) => {
          console.log('Error : ', error);
        });
      }
    }
  }, []);

  const handleBeforeUnloadShowPopup = (event) => {
    event.preventDefault();
    event.returnValue = "";
    const message = "Are you sure you want to leave?";
    alert(message);
  };

  useEffect(() => {
    if (finalContent?.isTyping === true) {
      setRegenerateBtnInfo({ generating: true, text: "Generating ..." });
      handleGetHtmlAndTextTyping(finalContent?.oldArticleHtml && finalContent?.oldArticleHtml !== "" ? true : false);
    } else if (finalContent?.article_html) {
      setRegenerateBtnInfo({ generating: false, text: "" });
      setEditorState(finalContent.article_html);
      dispatch(setFinalContent({ ...finalContent, isTypingDone: true }));
    }
  }, []);

  const handleGetHtmlAndTextTyping = async (isRegenerate) => {
    if (isRegenerate) {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Blog Co-Pilot"] || 3)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return false;
      }
    }

    if (!finalContent?.allSelectedOutline || finalContent?.allSelectedOutline?.length === 0) {
      return false;
    }

    setGenerateProgress(0);
    window.addEventListener("beforeunload", handleBeforeUnloadShowPopup);
    let oldGeneratedArticle = finalContent.article_html ? finalContent.article_html : "";
    if (finalContent?.oldArticleHtml && finalContent.oldArticleHtml !== "") {
      oldGeneratedArticle = finalContent.oldArticleHtml;
    }
    const perOutlineCount = Math.round(100 / (finalContent.allSelectedOutline.length + 2));
    const progressInterval = setInterval(() => {
      setGenerateProgress((count) => (count >= 95) ? 95 : count + 1);
    }, 2500);

    setEditorState('');
    let finalContentObj = { ...finalContent, isTypingDone: false };
    const iFrameInterval = setInterval(() => {
      const iframe = document.querySelector('iframe');
      if (typeof iframe?.contentWindow.document.getElementsByTagName("html")[0] !== typeof undefined) {
        iframe.contentWindow.document.getElementsByTagName("html")[0].classList = "blink-cursor";
      }
    }, 2000);

    let articleHtml = ``;
    let isStopTyping = false, second = 0;
    (function typeWriter(index) {
      if (isStopTyping && index >= articleHtml.length) {
        clearInterval(iFrameInterval);
        clearInterval(progressInterval);
        setGenerateProgress(0);
        window.removeEventListener("beforeunload", handleBeforeUnloadShowPopup);
        setRegenerateBtnInfo({ generating: false, text: "" });
        finalContentObj = { ...finalContentObj, isTyping: false, isTypingDone: true };
        dispatch(setFinalContent({ ...finalContentObj }));
        setEditorState(finalContentObj.article_html);
        handleGetSeoScore(finalContentObj);
        const iframe = document.querySelector('iframe');
        if (typeof iframe?.contentWindow.document.getElementsByTagName("html")[0] !== typeof undefined) {
          iframe.contentWindow.document.getElementsByTagName("html")[0].classList = "";
        }
        return false;
      }

      setTimeout(() => {
        if (articleHtml[index] !== undefined) {
          second = 0;
          let newIndex = index + 8;
          if (articleHtml[newIndex] === undefined) {
            newIndex = articleHtml.length;
          }
          const val = articleHtml.substring(index, newIndex);
          setEditorState((prev) => prev + val);
          typeWriter(newIndex);
        } else {
          second = 4000;
          typeWriter(index);
        }
      }, second);
    }(0));

    let oldAssistantResponses = [];
    let oldConversation = [];

    const payloadTempObj = {
      type: "generate-co-pilot-article",
      headline: setsOfSeoOptimized?.headline,
      summary: setsOfSeoOptimized?.summaryDescription,
      outline: finalContent.allSelectedOutline,
      articleId: seoOptBlogTopic.articleId,
      toneOfVoice: finalContent.toneOfVoice, headlineSets: headline,
      isDeductCredits: isRegenerate ? isRegenerate : false,
      old_article: isRegenerate ? oldGeneratedArticle : "",
    };

    // Expand each outline section
    for (let index = 0; index < finalContent.allSelectedOutline.length; index++) {
      const payload = {
        body: JSON.stringify({
          current_index: index, oldAssistantResponses, oldConversation,
          generateType: "expand_outline", ...payloadTempObj,
        })
      };
      const response = await invokeLambdaFunction("generateBcaBlogCustomArticle", payload);

      let progress = perOutlineCount * (index + 1);
      if (progress > 100) { progress = 100 };
      setGenerateProgress((count) => (count < progress) ? progress : count);
      if (index === 0 && isRegenerate) {
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Blog Co-Pilot"] || 3) }));
      }

      finalContentObj = { ...finalContentObj, isTyping: false, isTypingDone: false };
      dispatch(setFinalContent({ ...finalContentObj }));
      if (response && response.success === 1) {
        const response1 = response.data;
        oldAssistantResponses = response1.assistantResponses;
        oldConversation = response1.conversation;
        articleHtml += ` ${response1.currentExpandHtml}`.replaceAll("\n", "").replace(/\s+/g, ' ');
      }
    }

    // Generate Conclusion
    const conclusionPayload = {
      body: JSON.stringify({
        current_index: -1, oldAssistantResponses, oldConversation,
        generateType: "conclusion", ...payloadTempObj,
      })
    };
    const generateConclusion = await invokeLambdaFunction("generateBcaBlogCustomArticle", conclusionPayload);

    let progress1 = perOutlineCount * (finalContent.allSelectedOutline.length + 1);
    if (progress1 > 100) { progress1 = 100 };
    setGenerateProgress((count) => (count < progress1) ? progress1 : count);

    if (generateConclusion && generateConclusion.success === 1) {
      const conclusionRes = generateConclusion.data;
      oldAssistantResponses = conclusionRes.assistantResponses;
      oldConversation = conclusionRes.conversation;
      articleHtml += `${conclusionRes.currentExpandHtml}`.replaceAll("\n", "").replace(/\s+/g, ' ');
    }

    // Generate Summary
    const summaryPayload = {
      body: JSON.stringify({
        current_index: -1, oldAssistantResponses, oldConversation,
        generateType: "summary", ...payloadTempObj,
      })
    };
    const generateSummary = await invokeLambdaFunction("generateBcaBlogCustomArticle", summaryPayload);
    setGenerateProgress(100);

    if (generateSummary && generateSummary.success === 1) {
      const summaryRes = generateSummary.data;
      finalContentObj = {
        ...finalContentObj, article_html: summaryRes.article,
        h2_images: summaryRes.h2_images, logs: summaryRes.logs,
      };
    }

    clearInterval(progressInterval);
    finalContentObj = { ...finalContentObj, isTyping: false, isTypingDone: false };
    dispatch(setFinalContent({ ...finalContentObj }));
    isStopTyping = true;
  };

  useEffect(() => {
    if (seoOptBlogTopic?.articleId && finalContent?.article_html && !finalContent?.SEO_score_response) {
      handleGetSeoScore(finalContent);
    } else if (finalContent?.SEO_score_response) {
      setContentScore(finalContent?.SEO_score_response);
      handleGenerateAiImageForH2Tag(finalContent);
    }
  }, [seoOptBlogTopic?.articleId]);

  const handleGetSeoScore = async (finalContentObj) => {
    setSelectedSubTab("");
    if (!seoOptBlogTopic?.articleId) {
      return false;
    }

    const payload = {
      pathParameters: {
        id: seoOptBlogTopic?.articleId,
      },
      queryStringParameters: {
        articleType: "longFlow",
      }
    };
    const response = await invokeLambdaFunction("getSEOScore", payload);
    handleGetArticleById();
    setSelectedSubTab(searchParams.get('subTab') || "seoScore");
    if (response && response.success === 1 && response.data?.data) {
      const seoScore = typeof response.data.data === 'string' ? JSON.parse(response.data.data) : response.data.data

      setContentScore(seoScore);
      dispatch(setFinalContent({ ...finalContentObj, SEO_score_response: response.data.data }));
      dispatch(setLoader(false));
    }

    handleGenerateAiImageForH2Tag(finalContentObj);
  };

  const handleGenerateAiImageForH2Tag = async (finalContentObj) => {
    let generateImageArray = [];
    if (finalContentObj?.h2_images && finalContentObj?.h2_images !== "") {
      try {
        const tempImgArray = JSON.parse(finalContentObj?.h2_images);
        for (let index = 0; index < tempImgArray.length; index++) {
          const element = tempImgArray[index];
          if (!element.image || element.image === "") {
            generateImageArray.push(element);
          }
        }
      } catch (error) { }
    }

    if (generateImageArray.length === 0) {
      return false;
    }

    try {
      window.addEventListener("beforeunload", handleBeforeUnloadShowPopup);
      const payload = {
        pathParameters: { articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "" },
        body: JSON.stringify({ articleType, generateType: "image-generate" })
      };

      const response = await invokeLambdaFunction("generateAiImagesForFinalArticle", payload);
      if (response && response.success === 1 && response.data && response.data.article && response.data.article !== "") {
        setDisplayContent((pre) => ({ ...pre, article_html: response.data.article }));
        setEditorState(response.data.article);
        generateImageArray = response.data.generateImageArray;
        dispatch(setFinalContent({
          ...finalContentObj, h2_images: JSON.stringify(generateImageArray),
          article_html: response.data.article,
        }));
      } else {
      }
      window.removeEventListener("beforeunload", handleBeforeUnloadShowPopup);
    } catch (error) {
      window.removeEventListener("beforeunload", handleBeforeUnloadShowPopup);
    }
  };

  const handleSaveClose = () => setSavedModal(false);
  const handleConformClose = () => setConform(false);

  const getFullData = async (name, feedback = "") => {
    try {
      if (name === "linkedIn_post" || name === "facebook_post" || name === "twitter_post" || name === "instagram_post" || name === "instagram_reels" || name === "googleAdCopy") {
        if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return;
        }
      } else if (name === "FAQHTML") {
        if (finalContent.faq_regenerate_left <= 0) {
          toast.error("Sorry, you have used all three available generations");
          return;
        }
      }

      dispatch(setLoader(true)); setSelectedSubTab("");
      const articleId = seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "";
      const queryParams = "tableName=artical_long_flow";

      const payload = {
        pathParameters: { id: articleId },
        queryStringParameters: { tableName: "artical_long_flow" },
      };

      if (feedback && feedback !== "") {
        payload.pathParameters.feedback = feedback || "";
      }

      if (name === "FAQHTML") {
        await invokeLambdaFunction("generateFAQSchema", payload);
      } else if (name === "linkedIn_post" || name === "facebook_post" || name === "twitter_post" || name === "instagram_post" || name === "instagram_reels") {
        payload.pathParameters.type = name.replace("_post", "");
        await invokeLambdaFunction("generateSocialPostForBlog", payload);
      } else if (name === "googleAdCopy") {
        await invokeLambdaFunction("generateAdCopyWrite", payload);
      } else if (name === "popular_hashtags") {
        await AxiosApi.get(`/api/chatGPT/generate-popularHashtags/${articleId}?${queryParams}`);
      }

      const response = await AxiosApi.get(`/api/articleHistory/byId/${articleId}?${queryParams}`);
      const responseData = response && response.data && response.data.data ? response.data.data : {};

      if (name === "linkedIn_post" || name === "facebook_post" || name === "twitter_post" || name === "instagram_post" || name === "instagram_reels" || name === "googleAdCopy") {
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
      }

      dispatch(setLoader(false));
      setSelectedSubTab(selectedSubTab);
      dispatch(setFinalContent({
        ...finalContent, faq_regenerate_left: responseData.faq_regenerate_left,
        FAQschema: responseData?.FAQschema, FAQHTML: responseData?.FAQHTML,
        linkedIn_post: responseData?.linkedIn_post, linkedIn_post_img: responseData?.linkedIn_post_img,
        facebook_post: responseData?.facebook_post, facebook_post_img: responseData?.facebook_post_img,
        twitter_post: responseData?.twitter_post, twitter_post_img: responseData?.twitter_post_img,
        instagram_post: responseData?.instagram_post, instagram_post_img: responseData?.instagram_post_img,
        instagram_reels: responseData?.instagram_reels, googleAdCopy: responseData?.googleAdCopy,
        popular_hashtags: responseData?.popular_hashtags ? JSON.parse(responseData.popular_hashtags) : [],
      }));

      setDisplayContent({
        FAQschema: responseData?.FAQschema, FAQHTML: responseData?.FAQHTML,
        linkedIn_post: responseData?.linkedIn_post, facebook_post: responseData?.facebook_post,
        twitter_post: responseData?.twitter_post, instagram_post: responseData?.instagram_post,
        instagram_reels: responseData?.instagram_reels, googleAdCopy: responseData?.googleAdCopy,
      });

      if (name === "linkedIn_post" || name === "facebook_post" || name === "twitter_post" || name === "instagram_post" || name === "instagram_reels" || name === "googleAdCopy") {
        const key = name === "facebook_post" ? "facebook" : name === "twitter_post" ? "twitter" : name === "instagram_post" ? "instagram" : name === "instagram_reels" ? "igReels" : name === "googleAdCopy" ? "AdCopyWrite" : "linkedIn";
        setIsTyping({ ...isTyping, [key]: true });
      }
    } catch (error) {
      dispatch(setLoader(false)); setSelectedSubTab(selectedSubTab);
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const onSubmit = async () => {
    dispatch(setLoader(true));
    setSelectedSubTab("");

    const Obj = {
      article_html: editorState,
      FAQHTML: displayContent.FAQHTML, FAQschema: displayContent.FAQschema,
      linkedIn_post: displayContent.linkedIn_post, facebook_post: displayContent.facebook_post,
      twitter_post: displayContent.twitter_post, instagram_post: displayContent.instagram_post,
      instagram_reels: displayContent.instagram_reels, googleAdCopy: displayContent.googleAdCopy,
    };

    if (finalContent && finalContent.article_html && finalContent.article_html.indexOf("<html>") !== -1) {
      const htmlStr = editorState.indexOf("<body>") !== -1 ? editorState.substring(editorState.indexOf("<body>") + 6, editorState.indexOf("</body>")) : editorState;
      Obj.article_html = `${finalContent.article_html.substring(0, finalContent.article_html.indexOf("<body>"))}
          <body> ${htmlStr} </body>
        </html>`.replaceAll("\n", "").replaceAll("\t", "");
    }

    const currentSelectedTab = selectedSubTab && selectedSubTab !== "" ? selectedSubTab : "";

    await AxiosApi.post(`/api/articleHistory/update?tableName=artical_long_flow`, {
      id: seoOptBlogTopic?.articleId, currentField: selectedCurrentTab, ...Obj
    }).then(async () => {
      dispatch(setFinalContent({ ...finalContent, ...Obj }));
      setSelectedSubTab(currentSelectedTab);
      setSavedModal(true);
      dispatch(setLoader(false));
    }).catch(function (error) {
      dispatch(setLoader(false));
      setSelectedSubTab(currentSelectedTab);
      console.log(error);
    });
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const handleGetAllImagesAndOpenModal = async (subType, html, imageTab = "") => {
    try {
      if (imageTab && imageTab !== "") {
        setPostImagesModal((prev) => ({ ...prev, currentTab: "Upload Image" }));
      }

      const params = {
        html, type: articleType, subType, userId: userRes.id,
        articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "",
        topic: selectedTopic, keyword: googleSuggestKeyword,
      };

      if (subType === "Article Images") {
        params.html = editorState;
      };

      const queryParams = new URLSearchParams({
        type: articleType, subType, userId: userRes.id,
        articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "",
      }).toString();

      const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
      await handleGetOrGenerateAllTypeImages(paramObj);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    } catch (error) {
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    }
  };

  const handleShareSocialMediaPost = async () => {
    if (selectedCurrentTab === "linkedIn") {
      const uploadImage = finalContent?.linkedIn_post_img || "";
      const params = {
        postType: "LinkedIn", text: displayContent?.linkedIn_post || "",
        articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "",
        keyword: googleSuggestKeyword || "", userId: userRes.id, dispatch,
        image: uploadImage ? JSON.stringify({ data: uploadImage }) : "",
      };
      await handleShareSocialMediaTypePost(params);
    }
  };

  const handleAutoFactChecker = async () => {
    dispatch(setLoader(true));
    const payload = {
      body: JSON.stringify({
        userId: userRes.id, type: "long_flow",
        articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "",
      }),
    };
    const response = await invokeLambdaFunction("generateFullArticleFactCheckerV2", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      if (response && response.data) {
        const data = JSON.parse(response.data);
        let getCurrArticle = editorState;

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let finalText = element.textWithUrl ? element.textWithUrl.replaceAll("\n", "").replace(/\s+/g, ' ') : "";
          if (finalText && finalText.indexOf("class=") === -1) {
            finalText = finalText.replace('rel="noopener"', 'rel="noopener" class="tooltip-editor"');
          }
          const replaceText = element.text.replaceAll("\n", "").replace(/\s+/g, ' ').trim();
          getCurrArticle = getCurrArticle.replace(replaceText, finalText);
        }
        setEditorState(getCurrArticle);
      }
    } else {
      toast.error(response?.error, { id: "Toast-01" });
    }
  };

  const handleSendArticleToReviewer = async (data) => {
    try {
      dispatch(setLoader(true));
      const payload = {
        userId: userRes.id, articleType: articleType, articleSubType: "Generate Article",
        articleId: seoOptBlogTopic?.articleId ? seoOptBlogTopic.articleId : "",
        articleText: editorState ? editorState : "", ...data
      };

      const response = await AxiosApi.post(`/api/editorial-comment/send-to-reviewer`, payload);
      if (response && response.data && response.data.success === 1) {
        dispatch(setLoader(false));
        if (data.isSentToReviewer && data.isSentToReviewer === true) {
          Swal({ icon: "success", title: "Your blog has been sent for review!", text: "Please give us 1 to 2 days to revert." });
          setSelectedCurrentTab("reviewArticle");
          dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, is_check_reviewer: 1 }));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - data.deductCredits }));
        }

        return response.data;
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!", { id: "Toast-01" });
    }
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    dispatch(setFinalContent({ ...finalContent, [fieldName]: text }));
    setDisplayContent({ ...displayContent, [fieldName]: text });

    if (fieldName === "article_html") {
      setEditorState(text);
    }
  };

  const isFullScreen = searchParams.get("full-screen");
  const BlogGeneratedTime = useMemo(() => {
    try {
      const articleLogs = finalContent?.logs ? JSON.parse(finalContent?.logs || '') : [];
      const getStartIndex = articleLogs.findLastIndex((element) => element.indexOf("Step 6 Process start") !== -1);
      const getEndIndex = articleLogs.findLastIndex((element) => element.indexOf("Final Article ") !== -1);
      const timestamps = articleLogs.slice(getStartIndex, getEndIndex + 1);
      const startTime = timestamps?.[0]?.split('--')[1]?.replace(/"/g, '').trim()?.replace(/-/g, '');
      const endTime = timestamps?.[timestamps?.length - 1]?.split('--')[1]?.replace(/"/g, '').trim()?.replace(/-/g, '');
      return (new Date(endTime) - new Date(startTime)) / 1000;
    } catch (error) {
      return "";
    }
  }, [finalContent]);

  const [toggleRightSideBar, setToggleRightSideBar] = useState(false);
  const [sendToEditorForm, setSendToEditorForm] = useState(false);
  const reviewEditorChildRef = useRef();

  useEffect(() => {
    setToggleRightSideBar(false);
  }, [selectedCurrentTab])

  const handleSideButton = () => {
    const tabs = ["article"];
    if (tabs.includes(selectedCurrentTab)) {
      let tempContentNewScore = null;
      if (selectedCurrentTab === "article" && contentScore && Object.keys(contentScore).length > 0 && finalContent?.article_html) {
        tempContentNewScore = contentScore;
      }

      if (tempContentNewScore && Object.keys(tempContentNewScore).length > 0) {
        const word_score = (tempContentNewScore["Word count"]["SEO Score"] * 100) / tempContentNewScore["Word count"]["Max SEO score available"];
        const related_keywords_score = (tempContentNewScore["Related keywords"]["SEO Score"] * 100) / tempContentNewScore["Related keywords"]["Max SEO score available"];

        return (
          <div className={classNames("sideBarOpenBtn")}>
            <button
              className="border-0" onClick={() => { setToggleRightSideBar((prev) => seoScoreMobile ? true : !prev); setShow((prev) => seoScoreMobile ? true : false); }}
              data-tooltip-id="sidebar-toogle" data-tooltip-content={!toggleRightSideBar ? "Hide SEO Score" : "Show SEO Score"} data-tooltip-place="bottom"
            >
              {seoScoreMobile ? (
                <React.Fragment>
                  <GoArrowRight /> <p><span><strong><span className="textB">SEO Score</span></strong></span></p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <GoArrowRight />
                  <p className="">
                    <span>
                      <strong>{Math.round(word_score)} %</strong><span className="textB">Word Score</span>
                    </span>
                    <span>|</span>
                    <span>
                      <strong>{Math.round(related_keywords_score)} %</strong><span className="textB">Related Keywords</span>
                    </span>
                  </p>
                  <Tooltip id="sidebar-toogle" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
                </React.Fragment>
              )}
            </button>
          </div>
        );
      }
    } else if (selectedCurrentTab === "faqSchema") {
      return (
        <div className={classNames("sideBarOpenBtn")}>
          {!seoScoreMobile && (
            <button className="border-0" onClick={() => setToggleRightSideBar((prev) => !prev)} data-tooltip-id="sidebar-toogle-faq" data-tooltip-content={!toggleRightSideBar ? "Hide FAQ Schema" : "Show FAQ Schema"} data-tooltip-place="bottom">
              <GoArrowRight />
              <p className="">
                <span>|</span>
                <span className="FAQ-OpenBtn-text">View FAQ's Markup Schema</span>
              </p>
            </button>
          )}
          <Tooltip id="sidebar-toogle-faq" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      );
    } else if (selectedCurrentTab === "reviewArticle") {
      return (
        <div className={classNames("sideBarOpenBtn")}>
          <button
            className="border-0" onClick={() => setToggleRightSideBar((prev) => !prev)} data-tooltip-id="sidebar-toogle-comment"
            data-tooltip-content={!toggleRightSideBar && seocommnetsMobile ? "Show comments in bottom" : !toggleRightSideBar && !seocommnetsMobile ? "Hide Comments" : "Show Comments"} data-tooltip-place="bottom"
          >
            <GoArrowRight />
            <p className="">
              <span>|</span> <span>View Comments</span>
            </p>
          </button>
          <Tooltip id="sidebar-toogle-comment" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      );
    }

    return "";
  };

  const handleSetSelectedHashtagsOnPosts = (e, tag, type, isExist) => {
    const post = type === "fb" ? "facebook_post" : type === "tw" ? "twitter_post" : type === "ig" ? "instagram_post" : "linkedIn_post";
    if (!isExist) {
      setDisplayContent({ ...displayContent, [post]: displayContent[post] + ` <span class="hash-tag-text">${tag}</span> ` });
    } else {
      setDisplayContent({ ...displayContent, [post]: displayContent[post]?.replace(` <span class="hash-tag-text">${tag}</span> `, "") });
    }
  };

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  return (
    <React.Fragment>
      <div className="BlogCopilot">
        <div className={classNames("topBar d-flex gap-3 justify-content-between mb-4 ", { "d-none ,pt-0": isFullScreen })}>
          <div className="headingSide d-flex align-items-center">
            <strong className={"d-flex align-items-center justify-content-center"}>
              <span className="m-0">
                <ReactSVG className="d-inline" src={dashboardIcon.messageIcon} alt="click" />
              </span>
              SEO Blog Co-Pilot
            </strong>
            <span>{BlogGeneratedTime ? `Blog generated in ${BlogGeneratedTime} seconds` : 'Pending....'}</span>
          </div>
          <div className="backtoHome d-flex gap-3 justify-content-between align-items-center text-center">
            <div className="backBtn">
              <Link to={"/blog-co-pilot"} className="bg-transparent border-0"
                onClick={() => {
                  dispatch(resetSEOFLow()); dispatch(setHistoryData([]));
                  dispatch(setHistoryDataLength(0)); dispatch(setSeoOptBlogStep(1));
                }}
              >
                <GoArrowLeft /> Back to home
              </Link>
            </div>
          </div>
        </div>
        <div className="border-radius-12 bg-white AIWriterBlog blog-writer-content mb-3 p-0 m-0">
          <SeoOptimizedBlogHead />
        </div>
      </div>

      <div className="blogWriterArea card d-flex flex-row">
        <div className={classNames("sideBarB", { "d-none": isFullScreen, "pe-none": regenerateBtnInfo?.generating })}>
          <Sidebar setSelectedCurrentTab={setSelectedCurrentTab} regenerateBtnInfo={regenerateBtnInfo} />
        </div>

        {selectedCurrentTab === "aiOverview" ? (
          <AiOverviewTabComponent
            articleType={articleType} articleId={seoOptBlogTopic?.articleId}
            articleHtml={finalContent?.article_html} isFullScreen={isFullScreen}
          />
        ) : (
          <div className={`blogWriterBlock position-relative`}>
            <div className="navItemsBlock">
              <NavItems
                generatedFullArticle={finalContent} handleAutoFactChecker={handleAutoFactChecker} handleSaveArticleOrPostText={onSubmit}
                getFullData={getFullData} generateProgress={generateProgress} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType} setSelectedCurrentTab={setSelectedCurrentTab}
                setPostImagesCarousel={() => { }} getBlogVersionHistory={() => { }} handleSetRestoredOldVersionText={handleSetRestoredOldVersionText}
                handleCopyHtmlArticleText={handleCopyHtmlArticleText} handleShareSocialMediaPost={handleShareSocialMediaPost} regenerateBtnInfo={regenerateBtnInfo}
                setRegenerateBtnInfo={setRegenerateBtnInfo} setContentScore={setContentScore} handleGetHtmlAndTextTyping={handleGetHtmlAndTextTyping}
                setSendToEditorForm={setSendToEditorForm} handleSaveReviewArticleText={handleSaveReviewArticleText}
              />
            </div>

            <div className={classNames(
              "editableTextArea d-flex align-items-start overflow-hidden position-relative", selectedCurrentTab,
              { removeSideBar: toggleRightSideBar && ["article", "faqSchema", "reviewArticle"].includes(selectedCurrentTab) },
              { 'faqTab f-50': selectedCurrentTab === "faqSchema" }, { 'f-50': selectedCurrentTab === "googleAdCopy" }
            )}>
              {selectedCurrentTab === "article" ? (
                <React.Fragment>
                  {toggle ? (
                    <HtmlViewer setToggle={setToggle} value={editorState} html={finalContent?.article_html} onEditorChange={(e) => setEditorState(e)} />
                  ) : (
                    <div className="textEditerArea">
                      <MainContent
                        column={"article"} setToggle={setToggle} toggle={toggle} value={editorState}
                        finalHtmlText={finalContent?.article_html} setFunctionType={'article'} editorRef={editorRef}
                        editorKey={0} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                        setEdit={(e) => (finalContent && finalContent?.isTypingDone === true ? setEditorState(e) : null)}
                        articleId={seoOptBlogTopic?.articleId} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                      />
                    </div>
                  )}
                  <div className="toggleData">
                    {contentScore && Object.keys(contentScore)?.length > 0 && finalContent?.article_html ? (
                      seoScoreMobile ? (
                        <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              SEO score <BsInfoCircleFill className="infoIconSvg" data-tooltip-id="seo-score"
                                data-tooltip-content="Valid only for articles generated in “English”"
                                data-tooltip-place="bottom" />
                              <Tooltip id="seo-score" style={{ borderRadius: "6px", fontSize: "11px", padding: "4px 12px", zIndex: 9999 }} />
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body className="offcanvas_seo_score">
                            <SeoScorePreview contentScore={contentScore} article={editorState} col={12} show={show} />
                          </Offcanvas.Body>
                        </Offcanvas>
                      ) : (
                        <SeoScorePreview contentScore={contentScore} article={editorState} col={12} show={show} />
                      )
                    ) : (
                      <div style={{ height: "100%", background: "var(--Black-B30)", borderRadius: "10px" }}></div>
                    )}
                  </div>
                </React.Fragment>
              ) : selectedCurrentTab === "faqSchema" ? (
                <React.Fragment>
                  {toggle ? (
                    <HtmlViewer setToggle={setToggle} value={displayContent?.FAQHTML} html={finalContent?.FAQHTML} onEditorChange={(e) => setEditorState(e)} />
                  ) : (
                    <div className={`textEditerArea ${!displayContent?.FAQHTML && 'notContent'}`}>
                      <MainContent
                        column={"FAQHTML"} setToggle={setToggle} toggle={toggle} setEdit={setDisplayContent}
                        value={displayContent?.FAQHTML} finalHtmlText={''}
                        editorRef={editorRef} editorKey={0} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                      />
                    </div>
                  )}
                  <FaqSchemaArtical setEdit={setDisplayContent} edit={displayContent} />
                </React.Fragment>
              ) : selectedCurrentTab === "linkedIn" ? (
                <LinkedinArticle
                  setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setDisplayContent} value={displayContent?.linkedIn_post} getFullData={getFullData}
                  generatedFullArticle={finalContent} edit={displayContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("LinkedIn Post", displayContent?.linkedIn_post)}
                  handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                />
              ) : selectedCurrentTab === "facebook" ? (
                <FacebookArticle
                  setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setDisplayContent} value={displayContent?.facebook_post} getFullData={getFullData}
                  generatedFullArticle={finalContent} edit={displayContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Facebook Post", displayContent?.facebook_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                />
              ) : selectedCurrentTab === "twitter" ? (
                <TwitterArticle
                  setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setDisplayContent} value={displayContent?.twitter_post} getFullData={getFullData}
                  generatedFullArticle={finalContent} edit={displayContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Twitter Post", displayContent?.twitter_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                />
              ) : selectedCurrentTab === "instagram" ? (
                <InstagramArticle
                  setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setDisplayContent} value={displayContent?.instagram_post} getFullData={getFullData}
                  generatedFullArticle={finalContent} edit={displayContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Instagram Post", displayContent?.instagram_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                />
              ) : selectedCurrentTab === "googleAdCopy" ? (
                <GoogleAdCopy handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes} edit={displayContent} selectedTab={selectedCurrentTab} generatedFullArticle={finalContent} />
              ) : selectedCurrentTab === "factChecker" ? (
                <Row className="w-100">
                  <FactCheckerPreview searchResponse={seoOptBlogTopic?.data} col={12} type="selected" selected={selectedGoogleWebsite} />
                </Row>
              ) : selectedCurrentTab === "reviewArticle" ? (
                <ReviewTextEditor
                  isSelectedCurrentTab={selectedCurrentTab === "reviewArticle" ? true : false} articleType={articleType}
                  articleId={seoOptBlogTopic?.articleId} articleSubType={"Generate Article"} sendToEditorForm={sendToEditorForm}
                  isReviewArticle={seoOptBlogTopic?.is_check_reviewer || 0} toggleRightSideBar={toggleRightSideBar}
                  handleSendArticleToReviewer={handleSendArticleToReviewer} setSendToEditorForm={setSendToEditorForm}
                  ref={reviewEditorChildRef}
                />
              ) : selectedCurrentTab === "chart" ? (
                ""
              ) : selectedCurrentTab === "refundRequests" ? (
                <RefundRequestsHistory articleType={articleType} articleId={seoOptBlogTopic?.articleId} />
              ) : null}
            </div>
            {handleSideButton()}
          </div>
        )}

        <LeaveWithoutSaving show={conform} onHide={handleConformClose} leavePage={() => dispatch(setSeoOptBlogStep(7))} />
        <GoDashboard
          show={savedModal}
          onHide={handleSaveClose}
          goDashboard={() => {
            handleSaveClose(); dispatch(resetSEOFLow()); dispatch(setHistoryData([]));
            dispatch(setHistoryDataLength(0)); dispatch(setSeoOptBlogStep(1)); navigate("/blog-co-pilot");
          }}
        />

        {postImagesModal && postImagesModal.open && (
          <div className="genrateAiImages">
            <MediaLibraryDrawer
              postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} editorRef={editorRef}
              postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
              articleType={articleType} userRes={userRes} articleId={seoOptBlogTopic?.articleId}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BcaCoPilotSevenStep;
