import React, { useEffect, useMemo, useState } from 'react'
import Heading from '../../../Components/ui/Title'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomDataTable from '../../../Components/ui/DataTable'
import TableLoader from '../../../Components/ui/TableLoader'
import { useDispatch, useSelector } from 'react-redux'
import { SortArrow, sorting, tableDateFormatter } from '../../../utility/common'
import classNames from 'classnames'
import { setHistoryData } from '../../../store/reducer'
import { AxiosApi } from '../../../utility/axios'
import { articleHistoryIcons } from '../../../Assets/Icons'

const ReviewHistory = () => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [historyBlogType, setHistoryBlogType] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [historyData, setHisroryData] = useState([]);

  useEffect(() => {
    async function getData() {
      setTableLoader(true)
      try {
        const response = await AxiosApi.get(`/api/oona/history-or-details/byType/get-review-article-history?id=${userRes.id}`);
        setHisroryData(response.data.data);
        setTableLoader(false)
        return response.data;
      } catch (error) {
        setTableLoader(false)
        throw new Error(error?.response);
      }
    }
    getData();
  }, [])

  useEffect(() => {
    if (location && location.pathname && location.pathname.indexOf("/pdf-to-article") !== -1) {
      setHistoryBlogType("PDF");
    }
  }, [location]);

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(historyData, column?.sortField, sortDirection);
    dispatch(setHistoryData(newData));
  };

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Detail {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => { }}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{row.topic || "-"}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "remaining_comments",
        name: <div className="d-flex">Remaining Comments {SortArrow(sortField, "remaining_comments")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center text-center justify-content-center gap-3" onClick={() => { }}>
            <div className={classNames('tableValues d-flex align-items-center text-center justify-content-center', { showFullDes: false })}>
              {row.remaining_comments && row.remaining_comments > 0 ? (
                <h6 className='px-2 rounded text-white' style={{ background: 'linear-gradient(103deg, rgb(0, 0, 255) 0%, rgb(255, 0, 0) 121.74%)' }}>{row.remaining_comments}</h6>
              ) : "-"}
            </div>
          </div>
        ),
        sortable: true,
        sortField: "remaining_comments",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => { }}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{tableDateFormatter(row?.created_at)}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "updated_at",
        name: <div className="d-flex">Updated On {SortArrow(sortField, "updated_at")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-3" onClick={() => { }}>
            <div className={classNames('tableValues', { showFullDes: false })}>
              <h6>{tableDateFormatter(row?.updated_at)}</h6>
            </div>
          </div>
        ),
        sortable: true,
        sortField: "updated_at",
      },
      {
        id: "sort",
        name: 'Action',
        selector: (row) => {
          return (
            <div className="d-flex justify-content-end" onClick={() => { navigate(`/review-article/${row.article_type}/${row.article_id}`) }}>
              <button className='refund-button'>View</button>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.first_name?.[0]}</div>
            )}
            <span>{row?.first_name + " " + row?.last_name || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "first_name",
      });
    }
    return tempColumns;
  }, []);

  return (
    <React.Fragment>
      <div className=" d-flex flex-column gap-3 pt-4">
        <div className="heading">
          <Heading className="fw-bolder mainHeading" size="3">Article Reviewer</Heading>
          <p>Transform PDFs to concise articles with the latest insights</p>
        </div>
      </div>
      <div className="d-flex gap-2 flex-column tableHeading mt-4">
        <div className="historyTableBlock mt-2">
          <CustomDataTable
            progressPending={tableLoader} progressComponent={<TableLoader limit={10} />} columns={columns}
            totalCount={historyData.length || 0} pagination={true} data={historyData}
            itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
            onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
          // handleTableRowClick={(row) => { handleGetArticleById(row?.id) }} clearSelectedRows={() => { }}
          />
        </div>
      </div>
    </React.Fragment>
  )
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};

export default ReviewHistory;
