import React from "react";
import Select from "react-select";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ReactFlagsSelect from "react-flags-select";
import { IoIosArrowDown } from "react-icons/io";
import { BsInfoCircleFill, BsStars } from "react-icons/bs";
import ProductTreeView from "../ProductTreeView";
import { oneClickBlogIcon } from "../../../../Assets/Icons";
import { handleSetLanguageAndGeoLocation } from "../../../../utility/common";
import { handleCustomSelectOption, OonaLanguageList } from "../../../../utility/hepler";

const ContentForm = (props) => {
  const { register, watch, setValue, errors, onContinue, handleSubmit, control } = props;
  const userRes = useSelector(({ userRes }) => userRes);

  const handleItemClick = (itemLabel) => {
    setValue("selectedProduct", itemLabel);
  };

  return (
    <Row className="Oona">
      <Col sm="12" md="3" className="border-end py-3 OonaTree">
        <p className="text-muted mb-2" style={{ fontSize: "14px", fontWeight: "500" }}>Select Products</p>
        <ProductTreeView handleItemClick={handleItemClick} />
      </Col>
      <Col sm="12" md="9" className="ps-2 pt-4 ps-md-4">
        <form onSubmit={handleSubmit(onContinue)}>
          <div className="genrateFields">
            <div className="fields">
              <label htmlFor="">
                Tell me your topic, and let's create an engaging article together. <span className="astrick">*</span>
              </label>
              <div className="inputField">
                <input
                  name="topic" type="text" {...register("topic")} className={classNames("w-100 ", { 'is-invalid': !!errors?.topic?.message })}
                  placeholder="Enter your topic (e.g. Impact of Technology on Financial Growth)"
                />
                <div className="invalid">{errors?.topic?.message}</div>
              </div>
            </div>
            <div className="fields">
              <label htmlFor="">
                Provide one or two word keyword for effective assistance. <span className="astrick">*</span>
              </label>
              <div className="inputField">
                <input
                  name="keywords" type="text" {...register("keywords")} className={`w-100 ${errors.topic ? "is-invalid" : ""}`}
                  placeholder="Enter keywords (e.g. Financial growth)"
                />
                <div className="text-danger">{errors?.keywords?.message}</div>
              </div>
            </div>
            <Row className="chooseModel">
              {/* <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
                <div className="form-group">
                  <label>Choose your AI Model</label>
                  <Controller
                    control={control} name="aiType" {...register("aiType")}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name} placeholder="Select AI Type" isSearchable={false}
                        options={OonaAiTypeOptions} isClearable={false} inputRef={ref}
                        className={`custom-select ${errors.aiType ? "is-invalid" : ""} cust-blog-se`}
                        classNamePrefix="custom-select" onChange={(e) => onChange(e.value)}
                        value={OonaAiTypeOptions.find((c) => c.value === value)}
                      />
                    )}
                  />
                </div>
              </Col> */}
              <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
                <div className="form-group position-relative languageDrop">
                  <label>Select your geo-location</label>
                  <Controller
                    control={control} name="geoLocation" {...register("geoLocation")}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <ReactFlagsSelect
                        countries={['ID', 'PH']}
                        className={'reactFlag p-0 w-100'} searchable={true} selected={value}
                        onSelect={(val) => onChange(val)}
                      />
                    )}
                  />
                  <div className='arrowIcon'>
                    <IoIosArrowDown className='downArrowIcon' />
                  </div>
                </div>
              </Col>
              <Col className="fields m-0 mb-3" sm={12} md={6} lg={4}>
                <label htmlFor="">Choose a Language <span className="astrick">*</span></label>
                <Controller
                  control={control} name="lan" {...register("lan")}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                      classNamePrefix="custom-select" placeholder="Select Language"
                      options={OonaLanguageList} isClearable={false} isSearchable={false}
                      value={OonaLanguageList.find(c => c.value === value) || ""}
                      onChange={(event) => { onChange(event.value); handleSetLanguageAndGeoLocation(event, setValue); }}
                      getOptionLabel={handleCustomSelectOption}
                    />
                  )}
                />
                <div className="invalid">{errors.lan?.message}</div>
              </Col>
            </Row>
          </div>
          <div className="genrateBtn activeBtn d-flex align-items-center mt-1">
            <button type="submit"
              className={classNames({ activeBtn: watch("topic") !== "" && watch("keywords") !== "" && Object.keys(errors).length === 0 ? true : false })}
              disabled={watch("topic") !== "" && watch("keywords") !== "" && Object.keys(errors).length === 0 ? false : true}
            >
              {true ? <BsStars /> : <img src={oneClickBlogIcon.spaceRocket} alt="spaceRocket" />}
              Generate a blog
            </button>
            <BsInfoCircleFill
              className="text-decoration-none outline-0  infoIconSvg " data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
              data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["1-Click Blog"] || 2} Addlly credits`}
            />
          </div>
        </form>
      </Col>
    </Row>
  );
};

export default ContentForm;
