import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BiDollar } from "react-icons/bi";
import { SlSettings } from "react-icons/sl";
import { BsArrowLeft } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { BsListTask, BsBuilding, BsFileEarmark } from "react-icons/bs";
import MyProfileTab from "../Components/accountTabComponents/MyProfileTab";
import InvoiceListTab from "../Components/accountTabComponents/InvoiceListTab";
import TransactionHistoryTab from "../Components/accountTabComponents/TransactionHistoryTab";
import SocialConnectionsTab from "../Components/accountTabComponents/SocialConnectionsTab";
import TwitterConnectStep from "../Components/accountTabComponents/TwitterConnectStep";
import CompanyPreferenceTab from "../Components/accountTabComponents/CompanyPreferanceTab";
import RefundHistoryTab from "../Components/accountTabComponents/RefundHistoryTab";

const Account = () => {
  const navigate = useNavigate();
  const { tab, type } = useParams();
  const userRes = useSelector(({ userRes }) => userRes);
  const [currTab, setCurrTab] = useState(tab);

  useEffect(() => {
    setCurrTab(tab);
  }, [tab])

  const handleChangeCurrentTab = (key) => {
    setCurrTab(key);
    navigate(`/account/${key}`);
  }

  if (type && type === "twitter-step") {
    return (
      <TwitterConnectStep />
    )
  };

  return (
    <div className="profile-page">
      <div className="top-left-circle"></div>
      <div className="top-right-circle"></div>
      <div className="topBar">
        <div className="d-flex align-items-center gap-3">
          <SlSettings className="svg" />
          <h1 className="mb-0">Manage Account</h1>
        </div>
        <button type="button" className="btn back-button" onClick={() => navigate("/dashboard")}>
          <BsArrowLeft className="" /> Back to home
        </button>
      </div>
      <Row style={{ zIndex: "10", position: "relative" }}>
        <Col md="12" lg="2">
          <div className="sideMenu d-md-flex">
            <div className={`menuComponents ${currTab === "my-profile" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("my-profile")} onKeyDown={() => handleChangeCurrentTab("my-profile")}>
              <FaRegUserCircle /> <h5 className="">My Profile</h5>
            </div>
            <div className={`menuComponents ${currTab === "company-preference" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("company-preference")} onKeyDown={() => handleChangeCurrentTab("company-preference")}>
              <BsBuilding /> <h5 className="">Company Preference</h5>
            </div>
            {userRes?.company !== "Oona" && userRes?.company !== "CMI" && (
              <div className={`menuComponents ${currTab === "connections" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("connections")} onKeyDown={() => handleChangeCurrentTab("connections")}>
                <IoShareSocialOutline /> <h5 className="">Social Connections</h5>
              </div>
            )}
            <div className={`menuComponents ${currTab === "transaction-history" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("transaction-history")} onKeyDown={() => handleChangeCurrentTab("transaction-history")}>
              <BsListTask />  <h5 className="">Transaction History</h5>
            </div>
            {userRes?.current_plan !== "Enterprise Pack" && userRes?.company !== "CMI" && (
              <div className={`menuComponents ${currTab === "invoices" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("invoices")} onKeyDown={() => handleChangeCurrentTab("invoices")}>
                <BsFileEarmark /> <h5 className="">Invoice History</h5>
              </div>
            )}
            <div className={`menuComponents ${currTab === "refund-requests" ? "active" : ""}`} onClick={() => handleChangeCurrentTab("refund-requests")} onKeyDown={() => handleChangeCurrentTab("refund-requests")}>
              <BiDollar /> <h5 className="">Refund History</h5>
            </div>
          </div>
        </Col>
        <Col sm="12" md="10" className="main-content">
          <div className="accountCardContainer">
            {currTab === "company-preference" ? (
              <CompanyPreferenceTab />
            ) : currTab === "transaction-history" ? (
              <TransactionHistoryTab />
            ) : currTab === "connections" ? (
              <SocialConnectionsTab />
            ) : currTab === "invoices" ? (
              <InvoiceListTab />
            ) : currTab === "refund-requests" ? (
              <RefundHistoryTab />
            ) : (
              <MyProfileTab />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Account;
