import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Slider from "react-slick";
import { ImFire } from "react-icons/im";
import { BsArrowLeft, BsArrowRight, BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill, BsInfoCircle, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { RiHeartsFill } from "react-icons/ri";
import { IoMdCall } from "react-icons/io";
import NoDataAvailable from "../NoDataAvailable";
import { AxiosApi } from "../../utility/axios";
import AddllyPrimaryLoader from "../../Assets/Gif/addlly-primary-loader.gif";
import AddllyLogo from "../../Assets/Images/AddllyLogo.png";
import { setLoader, setSocialMediaPostStep, setSocialMediaStepsInfo } from "../../store/reducer";
import { SuggestionPopularPostsPreview } from "../SeoBlogWriter/PostPreview";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import TrendingPostKnowMoreImg from "../../Assets/Images/Social_media/trending_post_know_more.png";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1530,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const FifthStepSocialMediaPost = () => {
  const dispatch = useDispatch();

  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const [editFinalPost, setEditFinalPost] = useState(socialMediaPostInfo?.final_post);
  const [selectedSuggestionPost, setSelectedSuggestionPost] = useState({ index: null, text: null });
  const [isRegeneratedPost, setIsRegeneratedPost] = useState(false);
  const [isRevertButtonDisabled, setIsRevertButtonDisabled] = useState(false);
  const [oldGeneratedPost, setOldGeneratedPost] = useState(socialMediaPostInfo?.selectedPost);
  const [trendingPostLoader, setTrendingPostLoader] = useState(true);
  const [trendingPostModal, setTrendingPostModal] = useState(false);

  useEffect(() => {
    if (socialMediaPostInfo?.PostType && !(socialMediaPostInfo?.trendingPosts)) {
      handleGetTrendingSocialPostData();
    } else {
      setTrendingPostLoader(false);
    }
  }, []);

  const handleGetTrendingSocialPostData = async () => {
    try {
      if (socialMediaPostInfo && socialMediaPostInfo.PostType !== "" && socialMediaPostInfo?.keyword !== "") {
        setTrendingPostLoader(true);
        const payload = {
          pathParameters: {
            postType: socialMediaPostInfo.PostType
          },
          body: JSON.stringify({
            postId: socialMediaPostInfo.postId,
            keyword: socialMediaPostInfo.keyword,
          })
        };
        const response = await invokeLambdaFunction("getSocialMediaTrendingPostByType", payload);
        setTrendingPostLoader(false);
        if (response && response.success === 1 && response.data && response.data.posts) {
          dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, trendingPosts: response.data.posts }));
        } else {
          toast.error(response?.error || "Something went wrong.", { id: "Toast-01" });
        }
      }
    } catch (error) {
      setTrendingPostLoader(false);
      console.log('Error: ', error);
    }
  }

  const handleNext = async () => {
    dispatch(setLoader(true));
    AxiosApi.post(`/api/post-writer/save-final-post`, {
      postId: socialMediaPostInfo?.postId,
      finalPost: editFinalPost,
    }).then((response) => {
      dispatch(setLoader(false));
      dispatch(setSocialMediaStepsInfo({ ...socialMediaPostInfo, final_post: editFinalPost, stepDone: 7 }));
      dispatch(setSocialMediaPostStep(7));
      setSelectedSuggestionPost({ index: null, text: null });
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  }

  return (
    <div>
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>
            Review, Edit and Enhance the post
            <BsInfoCircleFill
              className="infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="bottom-start"
              data-tooltip-content={`Use references from the trending posts to improve your content`}
            />
          </b>
        </div>
        <div className="nxtGenBtn flex-wrap gap-3 d-flex align-items-center align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              dispatch(setSocialMediaPostStep(5));
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
            }}
          >
            <BsArrowLeft /> Previous
          </button>
          <span style={{ opacity: 0.1 }}>|</span>
          <button type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton" onClick={() => handleNext()}>
            Preview & Share <BsArrowRight />
          </button>
        </div>
      </div>
      <div className="p-32">
        <div className="reviewArticalBlock">
          <Row>
            {socialMediaPostInfo.PostType === "Facebook" ? (
              <Col sm={12}>
                <div className="hashTagBlock h-100">
                  <div className="hashTagTextArea h-100">
                    <div className="form-group mb-4 ">
                      <div className="custom-textarea-editor position-relative h-100 ">
                        <textarea
                          className="w-100 h-100 outlineTextarea textarea-content" value={editFinalPost}
                          onChange={(e) => setEditFinalPost(e.target.value)} rows={24}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <React.Fragment>
                <Col sm={12} lg={3} md={4}>
                  <div className="hashTagBlock h-100">
                    <div className="hashTagTextArea h-100">
                      <div className="form-group mb-4 ">
                        <div className="custom-textarea-editor position-relative h-100 ">
                          <textarea
                            className="w-100 h-100 outlineTextarea textarea-content" value={editFinalPost}
                            onChange={(e) => setEditFinalPost(e.target.value)} rows={24}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} lg={9} md={8} className="socialMediaSlider">
                  {/* <div className="d-flex align-items-center mb-2">
                    <h4 className="text-primary fw-normal me-3">Trending Post</h4>
                    <button className="btn btn-primary" style={{ padding: '0.1rem 0.75rem' }} onClick={() => setTrendingPostModal(true)}>Know more</button>
                  </div>
                  <label className="mb-2 text-start w-100">
                    Use references from the trending posts to improve your content. Click here to learn more.
                  </label> */}
                  {trendingPostLoader ? (
                    <div className="d-flex justify-content-center align-items-center h-75">
                      <div
                        className="d-flex mx-auto align-items-center justify-content-center flex-column py-5"
                        style={{ width: '350px' }}
                      >
                        <img src={AddllyPrimaryLoader} alt="loading..." className="w-100" />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {socialMediaPostInfo && socialMediaPostInfo.trendingPosts && socialMediaPostInfo.trendingPosts.length > 1 ? (
                        <Slider {...settings} nextArrow={<BsFillArrowRightCircleFill />} prevArrow={<BsFillArrowLeftCircleFill />} >
                          {socialMediaPostInfo.trendingPosts.filter((item) => item.text).slice(0, 5).map((post, index) => (
                            <div className={`suggestion-post-wrapper d-flex justify-content-center`} key={index}>
                              <SuggestionPopularPostsPreview
                                postInfo={post} postType={socialMediaPostInfo.PostType} index={index}
                                selectedSuggestionPost={selectedSuggestionPost} setSelectedSuggestionPost={setSelectedSuggestionPost}
                              />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <NoDataAvailable text={"Trending post not available"} />
                      )}
                    </React.Fragment>
                  )}
                </Col>
              </React.Fragment>
            )}
          </Row>
        </div>
      </div>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row pb-0  gap-2 pb-4 bottomButton">
        <button type="button" className="addlly-outline "
          onClick={() => {
            dispatch(setSocialMediaPostStep(5));
            const scrollWrapper = document.getElementById('scroll-wrapper');
            if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          }}
        >
          <span><BsArrowLeft /> Previous</span>
        </button>
        <div className='d-flex align-items-center gap-3'>
          <button type="button" onClick={() => handleNext()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
            <span>Preview & Share <BsArrowRight /></span>
          </button>
        </div>
      </div>

      <Modal className="note-modal know-more-modal" size="xl" centered show={trendingPostModal} onHide={() => setTrendingPostModal(false)}>
        <Modal.Body className="text-start">
          <div className="mt-3">
            <Row>
              <Col lg="6">
                <div className="mb-4">
                  <img src={AddllyLogo} alt="addlly logo" />
                </div>
                <div className="text-start px-5">
                  <h1 className="mb-2">Tips to make your post go viral</h1>
                  <h3 className="fw-normal">
                    A quick guide to use Addlly's features to make your post more viral.
                  </h3>
                </div>
                <div className="w-100 image-wrapper mt-5">
                  <img src={TrendingPostKnowMoreImg} alt="" className="sub-image" />
                  <img src={TrendingPostKnowMoreImg} alt="" className="main-image" />
                  <img src={TrendingPostKnowMoreImg} alt="" className="sub-image" />
                </div>
              </Col>
              <Col lg="6" className="px-5 mt-5 mt-lg-0">
                {[
                  {
                    title: "Include a Call To Action",
                    subTitle: "Add a compelling CTA that propels your readers into taking action, increasing engagement.",
                    icon: <IoMdCall className="fs-1" />
                  },
                  {
                    title: "Reference Trending Content",
                    subTitle: "Capitalise on recent events or trending posts and regenerate content to follow their structure.",
                    icon: <ImFire className="fs-1" />
                  },
                  {
                    title: "Use Correct Hashtags",
                    subTitle: "Find how many people follow hashtags relevant to your post's topic.",
                    icon: <BsInfoCircle className="fs-1" />
                  },
                  {
                    title: "Add Great Images",
                    subTitle: "Capture your audience's attention with appealing images.",
                    icon: <RiHeartsFill className="fs-1" />
                  },
                ].map((item, i) => (
                  <div className="modal-details d-flex" key={i}>
                    <div className="icon-box bg-primary-main">
                      {item.icon}
                    </div>
                    <div className="content">
                      <div className="inner-content">
                        <h2 className="inner-content-title">{item.title}</h2>
                        <h4 className="inner-content-subtitle">{item.subTitle}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
          <div className="mt-3">
            <button onClick={() => { setTrendingPostModal(false) }} type="button" className="addlly-primary ms-auto">
              <span>Close</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default FifthStepSocialMediaPost;
