import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-hot-toast";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeft, BsArrowRight, BsEyeFill, BsStars } from "react-icons/bs";
import ProductViewModal from "./ProductViewModal";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { setLoader, setECommerceBlogInfo, setECommerceBlogStep } from "../../store/reducer";

const ThirdStepECommerceBlog = () => {
  const dispatch = useDispatch();
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);

  const [productUserDesc, setProductUserDesc] = useState({});
  const [topicList, setTopicList] = useState(eCommerceBlogInfo?.topics || []);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [viewProduct, setViewProduct] = useState({ open: false, data: null });

  useEffect(() => {
    if (eCommerceBlogInfo && eCommerceBlogInfo.topics && eCommerceBlogInfo.topics.length > 0) {
      setTopicList(eCommerceBlogInfo.topics);
    }

    if (eCommerceBlogInfo && eCommerceBlogInfo.selectedTopic && eCommerceBlogInfo.selectedTopic !== "") {
      setSelectedTopic(eCommerceBlogInfo.selectedTopic);
      setIsDisabledInput(true);
    }

    const userDescObj = {};
    if (eCommerceBlogInfo && eCommerceBlogInfo.selectedProducts && eCommerceBlogInfo.selectedProducts.length > 0) {
      for (let index = 0; index < eCommerceBlogInfo.selectedProducts.length; index++) {
        const element = eCommerceBlogInfo.selectedProducts[index];
        if (element && element.name && element.userDesc && element.userDesc !== "") {
          userDescObj[element.name] = element.userDesc;
        }
      }
    }
    setProductUserDesc({ ...userDescObj });
  }, [])

  const handleTopicSelection = (topic = null) => {
    setSelectedTopic(topic);
    if (!topic || topic === "") {
      toast.error("Please insert a topic before selection", { id: "Toast-01" });
      setSelectedTopic("");
    }
  };

  const handleChangeTopicValue = (event, index) => {
    const { name, value } = event.target;
    let tempObj = [...topicList];
    tempObj[index] = { ...tempObj[index], [name]: value };
    setTopicList(tempObj);
    setSelectedTopic("");
  };

  const handleChangeTopic = (event, index) => {
    let updatedTopics = [...eCommerceBlogInfo.selectedSetTopics];
    updatedTopics[index] = event.target.value;
    dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, selectedSetTopics: updatedTopics }));
  };

  const handleRegenerateOptimizationSet = async () => {
    if (isDisabledInput || eCommerceBlogInfo.topicRegenerateLeft === 0) {
      return false;
    }

    if (eCommerceBlogInfo?.selectedSetTopics?.length === 0) {
      toast.error("Select any one topic to generate article.", { id: "Toast-01" });
      return false;
    }

    const selectedFinalProducts = [];
    if (eCommerceBlogInfo && eCommerceBlogInfo.selectedProducts && eCommerceBlogInfo.selectedProducts.length > 0) {
      for (let index = 0; index < eCommerceBlogInfo.selectedProducts.length; index++) {
        const element = { ...eCommerceBlogInfo.selectedProducts[index] };
        if (element.name && productUserDesc[element.name] && productUserDesc[element.name] !== "") {
          element.userDesc = productUserDesc[element.name];
        }
        selectedFinalProducts.push(element);
      }
    }

    try {
      dispatch(setLoader(true));
      const payload = {
        body: JSON.stringify({
          articleId: eCommerceBlogInfo.articleId,
          selectedTopics: eCommerceBlogInfo?.selectedSetTopics,
          allTopics: eCommerceBlogInfo.allTopics, isRegenerate: true,
          selectedProduct: selectedFinalProducts,
        })
      };

      const response = await invokeLambdaFunction("generateProductCatalogFinalTopics", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.topics && response.data.topics.length > 0) {
        dispatch(setECommerceBlogStep(3)); setTopicList(response.data.topics);
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...response.data, stepDone: 3, selectedProducts: selectedFinalProducts }));
      } else {
        toast.error("Topic not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleGenerateFinalArticle = async () => {
    try {
      if (!selectedTopic || selectedTopic === "") {
        toast.error("Select any one topic to generate article.", { id: "Toast-01" });
        return false;
      }

      if (eCommerceBlogInfo && eCommerceBlogInfo.article && eCommerceBlogInfo.article !== "") {
        dispatch(setECommerceBlogStep(4));
        return false;
      }

      const selectedFinalProducts = [];
      if (eCommerceBlogInfo && eCommerceBlogInfo.selectedProducts && eCommerceBlogInfo.selectedProducts.length > 0) {
        for (let index = 0; index < eCommerceBlogInfo.selectedProducts.length; index++) {
          const element = { ...eCommerceBlogInfo.selectedProducts[index] };
          if (element.name && productUserDesc[element.name] && productUserDesc[element.name] !== "") {
            element.userDesc = productUserDesc[element.name];
          }
          selectedFinalProducts.push(element);
        }
      }

      dispatch(setLoader(true));
      const payload = {
        body: JSON.stringify({
          articleId: eCommerceBlogInfo.articleId, topic: selectedTopic,
          topics: topicList, selectedProduct: selectedFinalProducts,
        })
      };

      const response = await invokeLambdaFunction("generateProductCatalogFinalArticle", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.article) {
        setSelectedTopic(""); setIsDisabledInput(true); dispatch(setECommerceBlogStep(4));
        dispatch(setECommerceBlogInfo({
          ...eCommerceBlogInfo, selectedTopic, ...response.data, stepDone: 4,
          topics: topicList, selectedProducts: selectedFinalProducts,
        }));
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Article not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  return (
    <div className="eCommerce-blog-wrapper">
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto mw-100">
        <div>
          <b> Based on the questions you selected, here are some headline options. Review these suggested headlines and choose the set that best aligns with the message and purpose of your blog.</b>
        </div>
        <button
          type="button" onClick={() => handleGenerateFinalArticle()}
          className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
        >
          Continue <BsArrowRight />
        </button>
      </div>

      <Row className="m-0">
        <Col sm="12" md="4" className="side-menu px-2 border-end">
          <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="selectedProductAccordion">
                <p>Selected Product</p>
                <p className="mx-1">({eCommerceBlogInfo?.selectedProducts?.length})</p>
              </Accordion.Header>
              <Accordion.Body className="px-1 py-2">
                <div className="sideBarInner addllyFormWrap px-1" style={{ maxHeight: "670px", overflow: "auto" }}>
                  <div className="d-flex align-items-center gap-3 flex-column justify-content-between backButton">
                    {eCommerceBlogInfo && eCommerceBlogInfo?.selectedProducts?.map((selectedProduct) => (
                      <div className={`image-wrapper d-flex align-items-center flex-column w-100 border`} key={selectedProduct?.id}>
                        <img src={selectedProduct?.image} alt="" style={{ objectFit: "contain" }} />
                        <div className="card-product-info text-left pb-2">
                          <p>{selectedProduct?.name}</p>
                        </div>
                        <div className="fields form-group w-100 px-3 mb-2">
                          <textarea
                            className="addllyForm-control m-0" placeholder="Insert own product description here"
                            rows={3} style={{ height: "auto" }} value={productUserDesc[selectedProduct?.name] || ""} disabled={isDisabledInput}
                            onChange={(e) => { setProductUserDesc((prev) => ({ ...prev, [selectedProduct?.name]: e.target.value })) }}
                          />
                        </div>
                        <div className="card-button">
                          <div className="sub-div gap-2">
                            <button
                              type="button" className="btn bg-white text-primary"
                              onClick={() => setViewProduct({ open: true, data: selectedProduct })}
                              data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Details`}
                            >
                              <BsEyeFill className='fs-5' />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="sideBarInner">
            <div className="mb-4">
              <label>Theme ( Promotion text )</label>
              <h5 className="pe-3">{eCommerceBlogInfo?.promotionText || "-"}</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between backButton">
              <label>Selected Trends</label>
            </div>
            <div className="d-flex align-items-center gap-2 mt-2 flex-column justify-content-between backButton">
              {eCommerceBlogInfo.selectedSetTopics && eCommerceBlogInfo.selectedSetTopics?.map((selectedTopicName, index) => (
                <div className="w-100 list-item-textarea overflow-auto border-0" key={index}>
                  <textarea
                    name="selectedTopicName" className="topicOptimisationTopics" disabled={isDisabledInput}
                    value={selectedTopicName} onChange={(event) => handleChangeTopic(event, index)}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button" className={"addlly-primary"} style={{ padding: "13px 5px" }} onClick={handleRegenerateOptimizationSet}
                disabled={isDisabledInput || eCommerceBlogInfo.topicRegenerateLeft === 0}
              >
                <BsStars /> Re-Generate ( {eCommerceBlogInfo.topicRegenerateLeft || 0} / 3 )
              </button>
            </div>
          </div>
        </Col>
        <Col sm="12" md="8" className="px-0 px-md-3">
          <div className="optomizeCardBlock mt-0">
            <div className={classNames("card-header-content")}>
              <div className="first-block p-1" style={{ width: "40%" }}>
                <h6 className="fw-bold fs-6">Headline <br></br><span className="ms-0 fw-light fst-italic" style={{ fontSize: "14px" }}>(You can edit the Headline)</span></h6>
              </div>
              <div className="grayBox"></div>
              <div className="last-block">
                <h6 className="fw-bold fs-6">Description <br></br><span className="ms-0 ms-0 fw-light fst-italic" style={{ fontSize: "14px" }}>(You can edit the Description)</span></h6>
              </div>
            </div>
          </div>
          {topicList && topicList.length > 0 && topicList.map((item, index) => (
            <div key={index} className="optomizeCardBlock list-item-table">
              <div className={classNames(`opCard d-flex gap-3 mb-2 align-items-center list-group-item ${selectedTopic === item.topicName && "active"}`)}>
                <div className="radioTextBlock d-flex align-items-center gap-3 inputBoxField" style={{ width: "40%" }}>
                  <Form.Check
                    className="radioType difRadio"
                    type={"radio"} checked={selectedTopic === item.topicName} disabled={isDisabledInput}
                    onChange={(e) => handleTopicSelection(item.topicName)}
                  />
                  <textarea
                    name="topicName" className="w-100 list-item-textarea overflow-auto" rows={3}
                    value={item.topicName} onChange={(event) => handleChangeTopicValue(event, index)}
                    disabled={isDisabledInput}
                  />
                </div>
                <div className="grayBox"></div>
                <div className="inputBoxField">
                  <textarea
                    name="explanation" className="w-100 list-item-textarea overflow-auto" rows={3}
                    value={item.explanation} onChange={(event) => handleChangeTopicValue(event, index)}
                    disabled={isDisabledInput}
                  />
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>

      {viewProduct && viewProduct.open && (
        <ProductViewModal viewProduct={viewProduct} setViewProduct={setViewProduct} />
      )}

      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
        <button type="button" className="addlly-outline "
          onClick={() => {
            dispatch(setECommerceBlogStep(2));
            const scrollWrapper = document.getElementById('scroll-wrapper');
            if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          }}
        >
          <span><BsArrowLeft /> Previous</span>
        </button>
        <div className='d-flex align-items-center'>
          <button type="button" disabled={!selectedTopic}
            onClick={() => handleGenerateFinalArticle()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
            <span>Continue</span> <BsArrowRight />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThirdStepECommerceBlog;
