import React, { useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { BsArrowRight, BsStars } from "react-icons/bs";
import SeoOptimizedBlogHead from "./SeoOptimizedBlogHead";

import {
  setLoader, setSeoOptBlogTopic, setSeoGenerateTopic, setSeoOptBlogStep,
  setKeywordTopic, setSelectedTopic, setSelectedGoogleWebsite, setUserRes
} from "../../../store/reducer";
import { OonaAiTypeOptions } from "../../../data";
import { invokeLambdaFunction } from "../../../utility/invokeLamdaFunction";
import { BsArrowLeft, BsInfoCircleFill } from "react-icons/bs";
import { HiOutlineChevronRight } from "react-icons/hi";
import { AxiosApi } from "../../../utility/axios";
import { handleCustomSelectOption, removeSpecificSpecialCharacters, trimedInputValue, OonaLanguageList } from "../../../utility/hepler";
import { IoIosArrowDown } from 'react-icons/io';
import { handleSetLanguageAndGeoLocation } from "../../../utility/common";
import ProductTreeView from "../1ClickBlogWriter/ProductTreeView";

const regexExpression = /^[A-Za-z0-9 \s._&-?,@!:]*[A-Za-z0-9][A-Za-z0-9 \s._&-?,@!:]*$/
const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Topic is required").min(2, "Topic must be at least 2 characters")
    .test('no-white-space', 'Topic should not contain white spaces', (value) => !/\s{2,}/.test(value))
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces")
    .test('alpha numeric characters', 'The title should only contain alpha numeric characters and & . _ - ? , @ ! :', (value) => regexExpression.test(value)),
  toneOfVoice: Yup.string().required("Tone of voice is required"),
});

export default function SecondStepSeoOptBlogType({ selectedCard }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();

  const seoGenerateTopic = useSelector(({ seoGenerateTopic }) => seoGenerateTopic);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const selectedGoogleWebsite = useSelector(({ selectedGoogleWebsite }) => selectedGoogleWebsite);
  const userRes = useSelector(({ userRes }) => userRes);

  const [geoLocation, setGeoLocation] = useState("ID");
  const [currentLan, setCurrentLan] = useState("Bahasa Indonesia");
  const [toneOfVoiceOptions, setToneOfVoiceOptions] = useState([]);

  const [pdfBase64, setPdfBase64] = useState('');
  const [generateLink, setGenerateLink] = useState(true);
  const [isDisabledInput, setIsDisabledInput] = useState(articleId ? true : false);
  const [bahasaText, setBahasaText] = useState("");

  const { register, handleSubmit, formState: { errors }, setValue, getValues, values, control, watch } = useForm({
    defaultValues: {
      topic: (seoOptBlogTopic?.topic_desc || seoGenerateTopic),
      aiType: (seoOptBlogTopic?.aiType || "GPT 4 Omni"),
      toneOfVoice: (seoOptBlogTopic?.toneOfVoice || ""),
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setIsDisabledInput(articleId ? true : false);
  }, [articleId])

  useEffect(() => {
    dispatch(setSeoOptBlogTopic({ ...seoOptBlogTopic, selectedCard: seoOptBlogTopic?.selectedCard || seoOptBlogTopic?.blog_type }));
    setGenerateLink(seoOptBlogTopic?.generateLink ? true : false);

    if (seoOptBlogTopic?.geoLocation) {
      setGeoLocation(seoOptBlogTopic?.geoLocation?.toUpperCase());
    }

    if (seoOptBlogTopic?.lan) {
      setCurrentLan(seoOptBlogTopic?.lan);
      if (seoOptBlogTopic?.lan && seoOptBlogTopic?.lan === "Bahasa Indonesia") {
        handleTranslateTextEngToBahasa(seoOptBlogTopic?.lan);
      }
    }
  }, [seoOptBlogTopic?.selectedCard, seoOptBlogTopic?.blog_type])

  useEffect(() => {
    if (toneOfVoiceOptions && toneOfVoiceOptions.length === 0) {
      handleGetToneOfVoiceOptionsList();
    }
  }, []);

  const handleGetToneOfVoiceOptionsList = async () => {
    try {
      setToneOfVoiceOptions([]);
      dispatch(setLoader(true));
      const response = await AxiosApi.get(`/api/tone-of-voice/get-options/byType?type=Blog Co-Pilot`);
      if (response && response.data && response.data.length > 0) {
        setToneOfVoiceOptions(response.data);
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      setToneOfVoiceOptions([]);
    }
  }

  const handleOnChange = (value) => {
    setGeoLocation(value);
  }

  const onContinue = async (formValue) => {
    const formData = trimedInputValue(formValue)
    try {
      if (articleId) {
        if (!selectedGoogleWebsite || selectedGoogleWebsite?.length === 0) {
          dispatch(setSelectedGoogleWebsite([]));
        }

        if (!generateLink) {
          dispatch(setSeoOptBlogStep(4));
          dispatch(setSeoOptBlogTopic({
            ...seoOptBlogTopic, generateLink: generateLink ? 1 : 0,
            stepDone: seoOptBlogTopic.stepDone < 4 ? 4 : seoOptBlogTopic.stepDone,
          }));
        } else {
          dispatch(setSeoOptBlogStep(3));
          dispatch(setSeoOptBlogTopic({
            ...seoOptBlogTopic, generateLink: generateLink ? 1 : 0,
            stepDone: seoOptBlogTopic.stepDone < 3 ? 3 : seoOptBlogTopic.stepDone,
          }));
        }
      } else {
        if (!formData?.topic || formData?.topic === "") {
          return false;
        }

        if (Number(userRes.credits) < (userRes?.deductCredits?.["Blog Co-Pilot"] || 3)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return false;
        }

        if (selectedCard === "Pdf" && !pdfBase64) {
          toast.error("Please upload PDF file.", { id: "Toast-01" });
          return false;
        }

        dispatch(setLoader(true));
        const topicStr = await removeSpecificSpecialCharacters(formData?.topic);

        const payload = {
          body: JSON.stringify({
            topic: topicStr || "", user_id: userRes.id, geoLocation: geoLocation?.toLowerCase(),
            blog_type: selectedCard, pdfBase64: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "",
            aiType: (formData?.aiType) ? formData.aiType : "GPT 4 Omni",
            toneOfVoice: (formData?.toneOfVoice) ? formData.toneOfVoice : "",
            isGenerateLinks: generateLink, lan: currentLan, generateType: "oona_blog",
            selectedProduct: formData?.selectedProduct ? formData.selectedProduct : "",
          })
        };

        const response = await invokeLambdaFunction("generateTopics", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1) {
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Blog Co-Pilot"] || 3) })); // 3 credit points for long flow
          const scrollWrapper = document.getElementById('scroll-wrapper');
          if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
          navigate(`/oona-blog-co-pilot/${response?.data?.articleId}`);

          if (!generateLink) {
            generateKeywordsAndMoveNextStep(response.data, formData);
          } else {
            dispatch(setSeoGenerateTopic(response.data?.topic));
            dispatch(setSelectedGoogleWebsite([]));
            dispatch(setSeoOptBlogStep(3));
            dispatch(setSeoOptBlogTopic({
              ...response.data, stepDone: 3, blog_type: selectedCard, topic_desc: formData?.topic, lan: currentLan,
              pdf_link: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "", generateLink: generateLink ? 1 : 0,
              aiType: (formData?.aiType) ? formData.aiType : "GPT 4 Omni",
              toneOfVoice: (formData?.toneOfVoice) ? formData.toneOfVoice : "",
            }));
          }
        } else {
          toast.error(response?.error, { id: "Toast-01" });
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong, try again after sometime.", { id: "Toast-01" });
    }
  };

  const generateKeywordsAndMoveNextStep = async (data, formData) => {
    dispatch(setLoader(true));
    const payload = {
      queryStringParameters: {
        articleId: data.articleId,
        topic: data?.keyword ? data.keyword : data.topic,
        geoLocation: data.geoLocation,
      }
    };
    const response = await invokeLambdaFunction("getKeywords", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      dispatch(setKeywordTopic(response.data));
      dispatch(setSelectedTopic(data.topic));
      dispatch(setSelectedGoogleWebsite([]));
      dispatch(setSeoGenerateTopic(data?.topic));
      dispatch(setSeoOptBlogTopic({
        ...seoOptBlogTopic, ...data, generateLink: generateLink ? 1 : 0,
        blog_type: selectedCard, topic_desc: formData?.topic,
        pdf_link: (selectedCard === "Pdf" && pdfBase64) ? pdfBase64 : "",
        stepDone: seoOptBlogTopic.stepDone < 4 ? 4 : seoOptBlogTopic.stepDone,
      }));
      dispatch(setSeoOptBlogStep(4));
    } else {
      toast.error(response?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    };
  }


  const handleTranslateTextEngToBahasa = async (language) => {
    if (getValues("topic") && getValues("topic") !== "" && language && language === "Bahasa Indonesia") {
      setBahasaText(bahasaText ? `${bahasaText} ...` : "Translating ...");
      AxiosApi.post(`/api/chatGPT/generate-headline`, {
        textTranslate: getValues("topic"),
      }).then((res) => {
        if (res.data && res.data.text && res.data.text !== "") {
          setBahasaText(res.data.text);
        } else {
          setBahasaText("");
        }
      }).catch((error) => {
        setBahasaText("");
      });
    } else {
      setBahasaText("");
    }
  };

  const CaretDownIcon = () => {
    return <IoIosArrowDown className='downArrowIcon' />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const handleItemClick = (itemLabel) => {
    setValue("selectedProduct", itemLabel);
  };

  return (
    <React.Fragment>
      <div className='selectBlock'>
        <b>Generate a Blog using {selectedCard}</b>
      </div>
      <Row className="Oona" style={{ margin: 0, padding: "0px 20px 20px" }}>
        <Col sm="12" md="3" className="border-end m-0 OonaTree pb-2">
          <p className="text-muted mb-2 p-2" style={{ fontSize: "14px", fontWeight: "500" }}>Select Products</p>
          <ProductTreeView handleItemClick={handleItemClick} />
        </Col>
        <Col sm="12" md="9">
          <div className="genrateFields pb-0">
            {selectedCard && (
              <form onSubmit={handleSubmit(onContinue)} className="">
                <Row className="mt-3">
                  {selectedCard === "Summary" ? (
                    <Col sm="12" className='mb-3 summeryBlock'>
                      <div className="form-group fields">
                        <label>Describe your points <span className="astrick">*</span></label>
                        <div className="input-with-mic position-relative">
                          <textarea
                            name="topic" type="text" {...register("topic")} rows="3"
                            placeholder="Example: 1. Best way to get the most out of ..."
                            className={`w-100 addllyForm-control ${errors.topic ? "is-invalid" : ""}`}
                            style={{ borderRadius: "10px" }} disabled={isDisabledInput}
                          />
                          <div className="invalid">{errors.topic?.message}</div>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <React.Fragment>
                      <Col sm="12" md="12" className='margin-bottom-18px'>
                        <div className="form-group fields">
                          <label>Tell me your topic, and let's create an engaging article together. <span className="astrick">*</span></label>
                          <div className="inputField">
                            <input
                              name="topic" type="text" {...register("topic")} disabled={isDisabledInput}
                              className={`addllyForm-control w-100 ${errors.topic ? "is-invalid" : ""}`}
                              placeholder="Enter your topic" onBlur={() => handleTranslateTextEngToBahasa(currentLan)}
                            />
                            {/* <div className="glitterStar cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Get Suggestions" data-tooltip-place="bottom"></div> */}
                            <div className="invalid">{errors.topic?.message}</div>
                          </div>
                        </div>
                      </Col>
                      {currentLan && currentLan === "Bahasa Indonesia" && bahasaText && bahasaText !== "" && (
                        <Col sm="12" md="12" className='mb-4'>
                          <div className="form-group fields">
                            <label>Translated Topic To Bahasa Indonesia</label>
                            <div className="inputField">
                              <input
                                name="topic" type="text" disabled placeholder=""
                                className={`addllyForm-control w-100`} value={bahasaText}
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </React.Fragment>
                  )}
                  <Col sm="12" md="6" className="seofield mb-3">
                    <div className="form-group position-relative languageDrop">
                      <label>Select your geo-location </label>
                      <BsInfoCircleFill
                        data-tooltip-id="my-tooltip" data-tooltip-place="right"
                        data-tooltip-content="AI model suggestions is based on user inputs"
                        className='infoIconSvg'
                      />
                      <ReactFlagsSelect
                        countries={['PH', 'ID']}
                        className={'reactFlag p-0 w-100'} searchable={true} selected={geoLocation}
                        onSelect={handleOnChange} disabled={isDisabledInput}
                      />
                      <div className='arrowIcon'>
                        <IoIosArrowDown className='downArrowIcon' />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="seofield mb-3">
                    <div className="fields form-group">
                      <label>Choose a Language <span className="astrick">*</span></label>
                      <Select
                        className={`custom-select ${errors.language ? "is-invalid" : ""}`}
                        classNamePrefix="custom-select" placeholder="Select Language"
                        options={OonaLanguageList} isClearable={false} isSearchable={false}
                        value={OonaLanguageList.find(c => c.value === currentLan) || ""}
                        getOptionLabel={handleCustomSelectOption} isDisabled={isDisabledInput}
                        onChange={(event) => {
                          setCurrentLan(event.value);
                          handleSetLanguageAndGeoLocation(event, setValue, setGeoLocation, handleTranslateTextEngToBahasa, setBahasaText);
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="seofield mb-3">
                    <div className="chooseModel d-flex gap-5 align-items-baseline">
                      <div className="fields form-group">
                        <label>Choose your AI Model</label>
                        <Controller
                          control={control} name="aiType" {...register("aiType")}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              className={`custom-select ${errors.aiType ? "is-invalid" : ""}`} inputRef={ref}
                              classNamePrefix="custom-select" placeholder="Select AI Type"
                              options={OonaAiTypeOptions} isClearable={false} isSearchable={false}
                              value={OonaAiTypeOptions.find(c => c.value === value)}
                              onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                              components={{ DropdownIndicator }} menuPosition="fixed"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" className="seofield">
                    <div className="chooseModel d-flex gap-5 align-items-baseline">
                      <div className="fields form-group">
                        <label>
                          <span>Tone of Voice <span className="text-danger">*</span></span>
                          <BsInfoCircleFill className="infoIconSvg" data-tooltip-id={"my-tooltip"}
                            data-tooltip-place="bottom-start" data-tooltip-content={`Feel free to adjust tone to better suit our needs or audience!`}
                          />
                        </label>
                        <Controller
                          control={control} name="toneOfVoice" {...register("toneOfVoice")}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              className={`custom-select ${errors.toneOfVoice ? "is-invalid" : ""}`}
                              classNamePrefix="custom-select" placeholder="Select Tone of Voice"
                              isClearable={false} name="toneOfVoice" options={toneOfVoiceOptions}
                              value={toneOfVoiceOptions.find(c => c.value === value)}
                              onChange={(e) => onChange(e.value)} isDisabled={isDisabledInput}
                              components={{ DropdownIndicator }} isSearchable={false}
                            />
                          )}
                        />
                        <div className="invalid">{errors.toneOfVoice?.message}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex align-items-start justify-content-center  align-items-center flex-row  gap-2 bottomButton">
                  <button type="button" className="addlly-outline "
                    onClick={() => {
                      dispatch(setSeoOptBlogStep(1));
                      // setValue("topic", ""); setGenerateLink(true); dispatch(resetSEOFLow());
                      // dispatch(setSeoGenerateTopic("")); dispatch(setSeoOptBlogTopic({}));
                      // setGeoLocation("SG"); setCurrentLan("English"); navigate(`/oona-blog-co-pilot`);
                    }}
                  >
                    <span><BsArrowLeft /> Previous</span>
                  </button>
                  <div className='d-flex align-items-center'>
                    <button type="submit" disabled={!watch('topic').trim()} className="addlly-primary" style={{ whiteSpace: "nowrap" }}>
                      {!generateLink ? (
                        <React.Fragment>
                          <span>Next</span> <HiOutlineChevronRight />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span><BsStars />Generate Links <BsArrowRight /></span>
                        </React.Fragment>
                      )}
                    </button>
                    <BsInfoCircleFill
                      className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
                      data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["Blog Co-Pilot"] || 3} Addlly credits`}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}
