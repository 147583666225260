import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../Components/ui/Title";
import FirstStepAzgoNewsletter from "../Components/AzgoNewsletterComponents/FirstStepAzgoNewsletter";
import AzgoNewsletterHead from "../Components/AzgoNewsletterComponents/AzgoNewsletterHead";
import SecondStepAzgoNewsletter from "../Components/AzgoNewsletterComponents/SecondStepAzgoNewsletter";
import ThirdStepAzgoNewsletter from "../Components/AzgoNewsletterComponents/ThirdStepAzgoNewsletter";
import AzgoNewsletterHistory from "../Components/AzgoNewsletterComponents/AzgoNewsletterHistory";
import { setLoader, setAzgoNewsletterInfo, setAzgoNewsletterStep } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import toast from "react-hot-toast";

const AzgoNewsletter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const azgoNewsletterStep = useSelector(({ azgoNewsletterStep }) => azgoNewsletterStep)
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      dispatch(setAzgoNewsletterInfo({}));
      dispatch(setAzgoNewsletterStep(1));
      navigate("/travel-newsletter");
    } else {
      handleGetArticleById(id, false)
    }
  }, [id]);

  const handleGetArticleById = async (id, isSetStep = false) => {
    try {
      dispatch(setLoader(true));
      dispatch(setAzgoNewsletterInfo({}));
      const response = await AxiosApi.get(`/api/article/history-or-details/byType/get-azgo-newsletter-byID?articleId=${id}`);
      if (response && response.data) {
        dispatch(setLoader(false));
        const item = response.data && response.data.id ? response.data : {};
        dispatch(
          setAzgoNewsletterInfo({
            resposeHTML: item.article,
            stepDone: 3,
            template: item.template,
            products: item.selected_products,
            inputText: item.topic,
            articleId: item.id,
            generationsLeft: item.generationsLeft
          })
        )
        dispatch(setAzgoNewsletterStep(3));
        navigate(`/travel-newsletter/${item?.id}`);
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
      dispatch(setAzgoNewsletterInfo({}));
      dispatch(setAzgoNewsletterStep(1));
      navigate("/travel-newsletter");
    }
  }

  const steps = [
    <FirstStepAzgoNewsletter />,
    <SecondStepAzgoNewsletter />,
    <ThirdStepAzgoNewsletter />
  ];

  const stepsClassList = [
    "position-relative border-radius-12 bg-white AIWriterBlog blog-writer-content",
    "border-radius-12 bg-white AIWriterBlog maingenrateBlock p-0 m-0",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
    "border-radius-12 bg-white AIWriterBlog overflow-hidden",
  ];

  return (
    <React.Fragment>
      <div className="oneClickHead d-flex flex-column gap-3">
        <div className="heading pt-4">
          <Heading className="fw-bolder mainHeading" size="3">Travel Newsletter</Heading>
          <p>Custom create Newsletters in minutes</p>
        </div>
      </div>
      <div className="stepWraper">
        <div className={stepsClassList[azgoNewsletterStep - 1]}>
          <AzgoNewsletterHead />
          {steps[azgoNewsletterStep - 1]}
        </div>
      </div>
      {(azgoNewsletterStep === 1 && !id) && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <AzgoNewsletterHistory handleGetArticleById={handleGetArticleById} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default AzgoNewsletter;
