import React from "react";
import { Modal } from "react-bootstrap";
import AddllyLogo from "../../Assets/Images/AddllyLogo.png";

const AllPolicyModal = ({ type, handleClose }) => {
  return (
    <React.Fragment>
      {type && type === "cancellationPolicy" && (
        <Modal className="note-modal full-width" size="lg" centered show={true} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-end my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="modal-content-body text-start">
              <h2 className="text-primary">Cancel Your Account</h2>
              <p className="h6">We're sorry to see you leave! 😢</p>

              <h2 className="text-primary mt-4 mb-2">How to Cancel Your Account</h2>
              <ol>
                <li>Navigate to Settings within the <a href="/">Addlly.AI</a> app.</li>
                <li>Select Settings from Profile Menu. Under My Profile, clicks on Cancel My Account button.</li>
                <li>Follow the steps to finalize the cancellation, ensuring each step is completed.</li>
                <li>Your cancellation will take effect at the end of your current billing cycle, allowing you to use Addlly.AI until then.</li>
              </ol>

              <h2 className="text-primary mt-4 mb-2">Requesting a Refund</h2>
              <ol>
                <li>Ensure your account is set to cancel.</li>
                <li>Contact our support team via email at <a href="mailto:support@addlly.ai">support@addlly.ai</a> or through live chat to request a refund.</li>
                <li>Refunds are processed upon request, provided it's within our 7-day money-back guarantee period.</li>
              </ol>

              <h2 className="text-primary mt-4 mb-2">Changed Your Mind?</h2>
              <p className="h6">To revert a cancellation, email us at <a href="mailto:support@addlly.ai">support@addlly.ai</a> with your request. Our support team will manually remove the pending cancellation from your account.</p>

              <h2 className="text-primary mt-4 mb-2">Encountering Issues?</h2>
              <p className="h6">If you're facing any issues while attempting to cancel, contact our support team for assistance.</p>
              <p className="h6">Ensure you follow all steps in the app to finalize your cancellation.</p>
              <p className="h6">For further assistance, feel free to reach out to our support team at any time. We're here to help!</p>

              {/* <h2 className="text-primary mt-4 mb-2">Pausing Your Subscription (not allowed now)</h2>
              <p className="h6">You may opt to pause your subscription for up to 60 days.</p>
              <p className="h6">Navigate through the cancellation flow within the app, selecting Pause Subscription instead of cancellation.</p>
              <p className="h6">Your billing cycle will resume automatically after the pause period, or you can resume it manually in the Subscription Management section.</p> */}
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {type && type === "refundPolicy" && (
        <Modal className="note-modal full-width" size="lg" centered show={true} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-end my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="modal-content-body text-start">
              <h2 className="text-primary">Refund Policy for Addlly.AI</h2>
              <p className="h6">
                At Addlly.AI, customer satisfaction is our priority. Hence, if you are not
                satisfied with our tool, we’ll offer a risk-free, 7-day refund policy for our
                services under these conditions:
              </p>

              <ol>
                <li>Refund requests must be made within seven (7) days of the original purchase.</li>
                <li>Service usage should be minimal (less than 2 Addlly credits and 2 social media credits) within this period.</li>
                <li>
                  We will not issue a refund if you have used your account after initiating a refund request.
                  Instead, please cancel your account to avoid any future subscription charges.
                </li>
                <li>
                  Refunds are only applicable on your current subscription plan. We won’t be making the refunds for previous months or
                  any non-usage of account. Our users will be responsible for managing their accounts and active subscriptions.
                </li>
              </ol>

              <h2 className="text-primary mt-4 mb-2">To request a refund:</h2>
              <p className="h6">
                If you meet the above-mentioned conditions, cancel your account. When you cancel your account, refunds are not made automatically.
                You’ll have to write us an Email on <a href="mailto:support@addlly.ai">support@addlly.ai</a> to get the refund process initiated.
              </p>
              <p className="h6">
                We'll address your request within 24-48 hours. Your satisfaction is our
                priority, and we strive to ensure a positive experience with our services.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {type && type === "termsAndConditions" && (
        <Modal className="note-modal full-width" size="lg" centered show={true} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-end my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="modal-content-body text-start">
              <h2 className="text-primary">Terms of Service</h2>
              <ol style={{ paddingLeft: "2.5rem" }}>
                <li className="h5">Introduction</li>
                <p className="h6 mb-3">Welcome to Addlly AI Pte. Ltd., a private limited company registered in Singapore (hereafter referred to as “Company”, “we”, “our”, “us”). These Terms of Service (“Terms”, “Terms of Service”) govern the use of our website located at <a href="https://addlly.ai/" target="_blank" rel="noreferrer">https://addlly.ai/</a> and the services provided through it (collectively, the "Service").</p>
                <p className="h6 mb-3">Our Privacy Policy, which can be found at <a href="https://addlly.ai/privacy" target="_blank" rel="noreferrer" className="mx-1">https://addlly.ai/privacy</a>, also governs your use of our Service. It outlines how we collect, safeguard, and disclose information that results from your use of our web pages. By using our Service, you signify your acceptance of this Privacy Policy.</p>
                <p className="h6 mb-3"> By agreeing to these Terms, and our Privacy Policy (collectively referred to as the "Agreements"), you acknowledge that you have read and understood the Agreements, and agree to be bound by them. If you do not agree with (or cannot comply with) the Agreements, you may not use the Service. Please notify us at <a href="mailto:hello@addlly.ai">hello@addlly.ai</a> so we can attempt to resolve your concerns. These Terms apply to all visitors, users, and others who wish to access or use our Service.</p>

                <li className="h5 mt-5">Communications</li>
                <p className="h6 mb-3">By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you have the option to opt out of receiving such communications by following the unsubscribe link or instructions provided in any email we send or by contacting us at <a href="mailto:hello@addlly.ai">hello@addlly.ai</a>.</p>

                <li className="h5 mt-5">Purchases</li>
                <p className="h6 mb-3">If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
                <p className="h6 mb-3">By making a Purchase, you represent and warrant that you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase and that all information you provide is accurate, complete, and current.</p>
                <p className="h6 mb-3">We may employ third-party services to facilitate payment and the completion of Purchases. By submitting your information, you grant us the right to provide this information to these third parties in line with our Privacy Policy.</p>
                <p className="h6 mb-3">We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order, or for other reasons we deem appropriate. We also reserve the right to refuse or cancel your order if we suspect any fraudulent, unauthorized, or illegal transaction, and we may report such incidents to the appropriate authorities.</p>

                <li className="h5 mt-5">Subscriptions</li>
                <p className="h6 mb-3">Some parts of the Service may be provided on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
                <p className="h6 mb-3">At the end of each Billing Cycle, your Subscription will automatically renew under the same conditions unless you cancel it or Addlly Pte. Ltd. cancels it. You may cancel your Subscription renewal through your online account management page or by contacting our customer support team.</p>
                <p className="h6 mb-3">You must provide us with a valid payment method (e.g., credit card) to pay for your Subscription. You shall provide Addlly Pte. Ltd. with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. </p>
                <p className="h6 mb-3">By submitting such payment information, you automatically authorize Addlly Pte. Ltd. to charge all Subscription fees incurred through your account to any such payment instruments.</p>
                <p className="h6 mb-3">Should automatic billing fail to occur for any reason, Addlly Pte. Ltd. will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

                <li className="h5 mt-5">Free Trial</li>
                <p className="h6 mb-3">Addlly Pte. Ltd. (Addlly AI)may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").</p>
                <p className="h6 mb-3">You may be required to enter your billing information in order to sign up for the Free Trial. If you do enter your billing information when signing up for the Free Trial, you will not be charged by Addlly Pte. Ltd (Addlly AI). until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
                <p className="h6 mb-3">At any time and without notice, Addlly Pte. Ltd. (Addlly AI)reserves the right to (i) modify the Terms of Service of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>

                <li className="h5 mt-5">Contests, Sweepstakes and Promotions</li>
                <p className="h6 mb-3">Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Service may be governed by rules separate from these Terms of Service.</p>
                <p className="h6 mb-3">If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, the Promotion rules shall prevail.</p>

                <li className="h5 mt-5">Governing Law and Dispute Resolution</li>
                <p className="h6 mb-3">These Terms shall be governed by, and interpreted in accordance with, the laws of Singapore. Any disputes arising out of or in connection with these Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC") for the time being in force, which rules are deemed to be incorporated by reference in this clause.</p>
                <p className="h6 mb-3">Please note, this is just a suggestion. It's important to engage a legal professional to ensure these terms are right for your particular situation, especially given the potential complexities and variations in law across different jurisdictions.</p>

                <li className="h5 mt-5">Fee Changes </li>
                <p className="h6 mb-3">Addlly Pte. Ltd. (Addlly AI) reserves the right to modify Subscription fees for the Subscriptions at any time, at its sole discretion. Any changes to Subscription fees will take effect at the end of the current Billing Cycle. </p>
                <p className="h6 mb-3">We will provide you with reasonable prior notice of any changes in Subscription fees to afford you the opportunity to cancel your Subscription before such changes become effective. Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the adjusted Subscription fee amount.</p>

                <li className="h5 mt-5">Refunds</li>
                <p className="h6 mb-3">Refunds for contracts are issued within seven (7) days of the original purchase of the contract. After the seven-day period, refunds will not be provided.</p>

                <li className="h5 mt-5">Fair Use Policy</li>
                <p className="h6 mb-3">To ensure the reliability and quality of our Service, Addlly Pte. Ltd. (Addllly AI) employs a Fair Use Policy ("FUP"). The FUP maintains a balanced service for all users by preventing excessive use that could impact the availability of the Service for others.</p>
                <p className="h6 mb-3">Our FUP applies to the Service provided by Addlly Pte. Ltd (Addlly AI)., particularly regarding the volume and nature of content generated. Although we strive to provide generous content generation for all Users, the FUP may apply if unusually large amounts of content are generated for a single User, or if automated behavior is detected.</p>
                <p className="h6 mb-3">Our Fair Usage for any Service with unlimited usage is based on regular usage of 1 User, defined as 200 content pieces in a month (unless on a custom plan). If your usage exceeds this limit, or if we determine that your login details are being shared outside of the original User with the Subscription, we reserve the right to restrict your generation limit, block, or permanently delete your account without notice. No refunds will be issued in such cases. By using our Service, you agree that any disputes in this regard raised by you or by your bank will be automatically rejected.</p>

                <li className="h5 mt-5">Content</li>
                <p className="h6 mb-3">Our Service allows you to post, link, store, share, and make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for the Content you post through the Service, including its legality, reliability, and appropriateness.</p>
                <p className="h6 mb-3">You retain all rights to the Content you submit, post, or display through the Service, and are responsible for protecting those rights. However, by posting Content through the Service, you grant us the right to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. This license includes the right for us to make your Content available to other users of the Service, who may use your Content subject to these Terms.</p>
                <p className="h6 mb-3">Despite the above, we have the right to collect and analyze data relating to the use and performance of the Services and related systems (including information concerning Content and data derived therefrom). We may use such information to improve the Services and for other development, diagnostic, and corrective purposes.</p>
                <p className="h6 mb-3">Content generated through our Service must be reviewed and edited by you or your representative. While we make every effort to ensure the accuracy of our AI model and the Content on our application, we disclaim any warranties or representations, express or implied, about its accuracy, completeness, or appropriateness for your purposes.</p>
                <p className="h6 mb-3">You assume full responsibility for any generated Content and agree that neither Addlly Pte. Ltd. (Addlly AI) nor its employees are responsible or liable for any claims, losses, or costs arising from its use. </p>
                <p className="h6 mb-3">We reserve the right to remove, monitor, and edit user content but will not be held liable for any incomplete or inaccurate content produced through our Service.</p>
                <p className="h6 mb-3">We also reserve the right to determine what Content is appropriate in accordance with the Prohibited Uses (as detailed in a separate section), and may limit or revoke your use of the Service if it is found to be inappropriate in accordance with these provisions.</p>

                <li className="h5 mt-5">Prohibited Uses</li>
                <p className="h6 mb-1">You agree not to use the Service:</p>
                <ol type="a" className="mb-3">
                  <li className="h6">For any unlawful purposes or in furtherance of illegal activities.</li>
                  <li className="h6">To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability. </li>
                  <li className="h6">To submit false or misleading information. </li>
                  <li className="h6">To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service.</li>
                  <li className="h6">To infringe upon or violate our intellectual property rights or the intellectual property rights of others. </li>
                  <li className="h6">To collect or track the personal information of others.</li>
                </ol>
                <p className="h6 mb-3">Failure to comply with these prohibitions constitutes a breach of these Terms and may result in termination or suspension of your rights to use the Service.</p>

                <li className="h5 mt-5">Restricted Conduct</li>
                <p className="h6 mb-1">Your use of the Service is to be compliant with the law and governed by these Terms. You are expressly prohibited from employing the Service for the following:</p>
                <ol type="a" className="mb-3">
                  <li className="h6 fw-normal"><b>Illegal Activity:</b> Violating national or international law, including but not limited to activities such as gambling, weapons development, cybercrime, and unauthorised surveillance.</li>
                  <li className="h6 fw-normal"><b>Exploitation of Minors:</b> Seeking to harm, exploit, or expose minors to unsuitable content.</li>
                  <li className="h6 fw-normal"><b>Hate and Harassment:</b> Creating content that incites hate against a group based on factors like race, religion, sexual orientation, or ethnicity, or content that intimidates, threatens, or bullies an individual.</li>
                  <li className="h6 fw-normal"><b>Promotion of Violence:</b> Developing content that glorifies violence or revels in the suffering or humiliation of others.</li>
                  <li className="h6 fw-normal"><b>Self-harm:</b> Developing content that promotes self-harm, including but not limited to suicide, self-mutilation, and eating disorders.</li>
                  <li className="h6 fw-normal"><b>Adult Content:</b> Creating content intended to sexually excite, or promoting sexual services. Note: This excludes content on sex education or wellness.</li>
                  <li className="h6 fw-normal"><b>Political Manipulation:</b> Creating content with the intention of influencing political processes or used for campaigning.</li>
                  <li className="h6 fw-normal"><b>Deceptive Content:</b> Producing content that is false or misleading, such as fraudulent schemes or spreading disinformation.</li>
                  <li className="h6 fw-normal"><b>Malware:</b> Developing content that can harm or interfere with someone else's software, hardware, or telecommunications equipment, or unauthorized access to another person's data.</li>
                  <li className="h6 fw-normal"><b>Spam:</b> Transmitting or encouraging the sending of unsolicited promotional material like “junk mail”, “chain letters”, “spam”, or similar solicitations.</li>
                  <li className="h6 fw-normal"><b>Abuse-Prone Business Models:</b> Facilitating content that enables payday lending or multi-level marketing, or content promoting pseudo-pharmaceuticals.</li>
                  <li className="h6 fw-normal"><b>Impersonation:</b> Impersonating or attempting to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
                  <li className="h6 fw-normal"><b>Infringement of Rights:</b> Infringing upon the rights of others, or engaging in illegal, threatening, fraudulent, or harmful activities.</li>
                  <li className="h6 fw-normal"><b>Disruption of Service:</b> Engaging in conduct that restricts or inhibits others' use or enjoyment of the Service, or any action that, in our opinion, could harm, offend the Company or the users of the Service or expose them to liability.</li>
                </ol>

                <p className="h6 mb-1">Furthermore, you are prohibited from:</p>
                <ol type="a" className="mb-3">
                  <li className="h6 fw-normal"><b>Disabling the Service:</b> Using the Service in a way that could disable, overburden, damage, or impair the Service, or interfere with any other party’s use of the Service.</li>
                  <li className="h6 fw-normal"><b>Automated Access:</b> Employing any robot, spider, or other automated device, process, or means to access the Service for any purpose, including monitoring or copying any material on the Service.</li>
                  <li className="h6 fw-normal"><b>Manual Monitoring:</b> Using any manual process to monitor or copy any material on the Service or for any other unauthorized purpose without our prior written consent.</li>
                  <li className="h6 fw-normal"><b>Disruption of Service Functionality:</b> Utilising any device, software, or routine that interferes with the proper functioning of the Service.</li>
                  <li className="h6 fw-normal"><b>Spreading Malware:</b> Introducing any viruses, trojan horses, worms, logic bombs, or other malicious or technologically harmful material.</li>
                  <li className="h6 fw-normal"><b>Unauthorized Access:</b> Attempting to gain unauthorized access to any part of the Service, the server where the Service is stored, or any server, computer, or database connected to the Service.</li>
                  <li className="h6 fw-normal"><b>Denial-of-Service Attack:</b> Attacking the Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
                  <li className="h6 fw-normal"><b>Falsifying Ratings:</b> Taking any action that may falsify or damage the Company's rating.</li>
                  <li className="h6 fw-normal"><b>Interference:</b> Attempting in any way to disrupt the proper operation of the Service.</li>
                </ol>

                <li className="h5 mt-5">Usage Analysis</li>
                <p className="h6 mb-3">We might engage third-party providers to scrutinize and evaluate the use of our Service.</p>
                <p className="h6 mb-3">Google Analytics Google provides this web analytics service that gathers and reports website traffic. Google leverages the data to monitor our Service's usage. The collected data might be shared with other Google services and utilized to customize and personalize the advertisements in Google's ad network.</p>
                <p className="h6 mb-3">To understand Google's privacy practices, please refer to the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a></p>
                <p className="h6 mb-3">We also recommend you review Google's data safeguarding policy: <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">https://support.google.com/analytics/answer/6004245</a>.</p>

                <li className="h5 mt-5">Age Restriction</li>
                <p className="h6 mb-3">Our Service is intended for individuals who are at least eighteen (18) years old. By using our Service, you affirm that you are at least eighteen (18) years of age and have the legal capacity to comply with these Terms. If you are under eighteen (18) years old, you are forbidden from accessing and using the Service.</p>

                <li className="h5 mt-5">Account Obligations </li>
                <p className="h6 mb-3">Upon creating an account with us, you assure us that you are over 18 and that the details you provide are accurate, complete, and up-to-date. Inaccurate, incomplete, or outdated information could lead to the immediate termination of your account on the Service.</p>
                <p className="h6 mb-3">You are obliged to secure your account and password, including limiting access to your devices and/or account. You agree to take responsibility for any activities or actions happening under your account and/or password, even if your password is with our Service or a third-party service. You must immediately notify us of any security breaches or unauthorized usage of your account.</p>
                <p className="h6 mb-3">You are forbidden from using a username that belongs to another person or entity, that isn't legally available, that infringes on the rights of others without appropriate authorization, or that is offensive, vulgar, or obscene.</p>
                <p className="h6 mb-3">We maintain the right to deny service, terminate accounts, alter or remove content, or cancel orders at our sole discretion.</p>

                <li className="h5 mt-5">Proprietary Rights</li>
                <p className="h6 mb-3">The Service and its features and functionality are the exclusive property of Addlly Pte. Ltd. and its licensors, and are safeguarded by copyright, trademark, and other laws of the United States and foreign countries. The use of our trademarks and trade address in connection with any product or service requires the prior written consent of Addlly Pte. Ltd..</p>

                <li className="h5 mt-5">Reporting Issues and Sharing Suggestions </li>
                <p className="h6 mb-3">You have the option to provide us directly at <a href="mailto:hello@addlly.ai" sty>hello@addlly.ai</a> or through third-party sites and tools with information and feedback related to errors, improvement suggestions, ideas, issues, complaints, and other matters concerning our Service ("Feedback"). </p>
                <p className="h6 mb-1">By doing so, you understand and consent to: </p>
                <ol type="i" className="mb-3">
                  <li className="h6">you will not obtain or claim any intellectual property right or any other right, title, or interest in the Feedback; </li>
                  <li className="h6">the Company might already be considering similar ideas to the Feedback;</li>
                  <li className="h6">the Feedback does not hold confidential or proprietary information from you or a third party; </li>
                  <li className="h6">the Company is not obligated to maintain confidentiality regarding the Feedback. If the transfer of Feedback ownership is not possible due to mandatory laws, you provide the Company and its affiliates with an exclusive, transferable, irrevocable, free, sub-licensable, unlimited, and perpetual right to use (including copying, modifying, publishing, distributing, and commercializing) the feedback in any way and for any purpose.</li>
                </ol>
                <p className="h6 mb-1">Third-party sites and tools mentioned above include:</p>
                <p className="h6 mb-3">Sentry Sentry is an open-source solution for error tracking offered by Functional Software Inc. Further details can be found here: <a href="https://sentry.io/privacy/" target="_blank" rel="noreferrer">https://sentry.io/privacy/</a></p>

                <li className="h5 mt-5">Links to External Websites </li>
                <p className="h6 mb-3">Our Service may include links to third-party websites or services, which are not owned or controlled by Addlly Pte. Ltd..</p>
                <p className="h6 mb-3">Addlly Pte. Ltd. does not oversee, nor is responsible for, the content, privacy policies, or practices of any third-party websites or services. We do not endorse the offerings of these entities/individuals or their websites.</p>
                <p className="h6 mb-3">You Agree That Addlly Pte. Ltd. Is Not Responsible Or Liable, Directly Or Indirectly, For Any Damage Or Loss Arising From Or Related To The Use Of Or Reliance On Any Content, Goods, Or Services Available Through These Third-Party Websites Or Services.</p>
                <p className="h6 mb-3">We Strongly Urge You To Review The Terms Of Service And Privacy Policies Of Any Third-Party Websites Or Services That You Visit.</p>

                <li className="h5 mt-5">Warranty Disclaimer</li>
                <p className="h6 mb-3">Our services are provided by the company "as is" and "as available". The company does not offer any express or implied warranties or representations about the operation of their services, or the information, content or materials included within. You consent to use these services and their content, and any services or items obtained from us, at your own discretion and risk.</p>
                <p className="h6 mb-3">Neither the company nor any person associated with the company provides any warranties or representations about the completeness, security, reliability, quality, accuracy, or availability of the services. </p>
                <p className="h6 mb-3">This includes, but is not limited to, no claims from the company or anyone associated with the company that the services, their content, or any services or items obtained through the services will be accurate, reliable, free of errors, uninterrupted, that defects will be fixed, that the services or the server that hosts them are free of viruses or other harmful components, or that the services or any services or items obtained through the services will meet your needs or expectations.</p>
                <p className="h6 mb-3">The company explicitly rejects all warranties, whether express, implied, statutory, or otherwise, including, but not limited to, warranties of merchantability, non-infringement, and fitness for a particular purpose.</p>
                <p className="h6 mb-3">The aforementioned does not affect any warranties that cannot be excluded or limited under applicable law.</p>

                <li className="h5 mt-5">Liability Limitation </li>
                <p className="h6 mb-3">Unless forbidden by law, you agree to hold us and our officers, directors, employees, and agents free from any indirect, punitive, special, incidental, or consequential damage, arising in any way (including attorneys' fees and all related costs and expenses of litigation and arbitration, or at trial or on appeal, if any, whether or not litigation or arbitration has been initiated), whether from contract, negligence, or other tortious action, or related to this agreement, including, but not limited to, any personal injury or property damage claims, arising from this agreement and any violation by you of any federal, state, or local laws, statutes, rules, or regulations, even if the company has been previously advised of the possibility of such damage. Unless prohibited by law, if the company is found liable, it will be limited to the amount paid for the products and/or services, and under no circumstances will there be consequential or punitive damages. Some states do not allow the exclusion or limitation of punitive, incidental, or consequential damages, so the previous limitation or exclusion may not apply to you.</p>

                <li className="h5 mt-5">Termination</li>
                <p className="h6 mb-3">We reserve the right to terminate or suspend your account and restrict access to our Service immediately, without prior notice or liability, under our sole discretion, for any reason, including, but not limited to, a breach of the Terms.</p>
                <p className="h6 mb-3">If you want to terminate your account, you can simply stop using our Service.</p>
                <p className="h6 mb-3">Any provisions of the Terms that, by their nature, should persist after termination will continue to apply post-termination, including, but not limited to, ownership provisions, warranty disclaimers, indemnification, and limitations of liability.</p>

                <li className="h5 mt-5">Lawful Jurisdiction </li>
                <p className="h6 mb-3">The guidelines stated in these Terms are under and will be construed in harmony with the laws of Singapore, disregarding its conflict of law provisions.</p>
                <p className="h6 mb-3">In case we fail to execute any right or provision of these Terms, it shall not be regarded as a waiver of those rights. If a court declares any provision of these Terms invalid or unenforceable, the rest of the Terms will remain in force. These Terms present the comprehensive agreement between us concerning our Service and substitute and supersede any former agreements we might have had regarding the Service.</p>

                <li className="h5 mt-5">Service Modifications </li>
                <p className="h6 mb-3">We hold the exclusive right to withdraw, amend our Service, or any service or material we provide via the Service, without prior notification at our sole discretion. We will not bear any responsibility if, for any reason, all or any part of the Service is unavailable for any duration. Occasionally, we may limit access to some sections of the Service, or the entire Service, to users, including registered users.</p>

                <li className="h5 mt-5">Terms Adjustment </li>
                <p className="h6 mb-3">We may adjust these Terms anytime by posting the modified terms on this site. It is your duty to review these Terms periodically.</p>
                <p className="h6 mb-3">Your sustained use of the Platform post the posting of revised Terms implies that you accept and consent to the changes. You are advised to check this page regularly to be aware of any changes, as they are obligatory for you.</p>
                <p className="h6 mb-3">By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

                <li className="h5 mt-5">Waiver and Severability </li>
                <p className="h6 mb-3">No act of leniency by the Company of any term or condition specified in Terms will be considered a continuing waiver of such term or condition or a waiver of any other term or condition. Any failure of the Company to assert a right or provision under Terms will not constitute a waiver of such right or provision.</p>
                <p className="h6 mb-3">If a competent jurisdictional court or other tribunal declares any provision of Terms as invalid, illegal or unenforceable for any reason, such provision shall be minimized or eliminated to the least extent so that the remaining provisions of the Terms continue in full force and effect.</p>

                <li className="h5 mt-5">Acceptance of Terms </li>
                <p className="h6 mb-3">By Utilizing The Service Or Other Services Provided By Us, You Acknowledge That You Have Perused These Terms Of Service And Agree To Abide By Them.</p>
              </ol>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {type && type === "privacyPolicy" && (
        <Modal className="note-modal full-width" size="lg" centered show={true} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-end my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="modal-content-body text-start">
              <h2 className="text-primary">Privacy Policy of Addlly AI Pte. Ltd. (Addlly AI)</h2>
              <h4 className="mb-3">Last updated: Oct 14, 2023</h4>

              <ol style={{ paddingLeft: "2.5rem" }}>
                <li className="h5">Introduction and General Terms</li>
                <p className="h6 mb-3">At Addlly AI Pte. Ltd (Addlly AI)., we value the privacy of our users. This Privacy Policy outlines our commitment to protecting your privacy and your data. By using our Service, you are accepting the practices described in this Privacy Policy.</p>

                <li className="h5 mt-5">Definitions</li>
                <ul className="mb-3">
                  <li>'Service' refers to any product or service provided by Addlly AI Pte. Ltd (Addlly AI).</li>
                  <li>'Personal data' refers to any information that can be used to identify an individual.</li>
                  <li>'User' refers to anyone who accesses or uses our Service.</li>
                </ul>

                <li className="h5 mt-5">Information We Collect</li>
                <p className="h6 mb-3">We collect a range of data, including but not limited to, personal data, usage data, and tracking & cookies data. We collect this data to provide and improve our Service, contact users, and maintain a secure environment. We also use or are likely to use tools like Sentry, an open-source error tracking solution.</p>
                <h4 className="mb-2">Section 3: Third-Party Service Providers</h4>
                <p className="h6 mb-3">Third-party Service Providers that we engage may have access to Your Personal Data. These vendors collect, store, use, process, and transfer information about Your activity on Our Service in accordance with their respective Privacy Policies.</p>
                <ol type="a">
                  <li className="fw-bold">Analytics</li>
                  <p className="h6 mb-2">Third-party Service Providers may be used to monitor and analyze the use of our Service. Below are some of the examples of services that we currently use or intend to use.</p>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">Google Analytics</li>
                    <ul>
                      <li>Google Analytics, a web analytics service provided by Google, tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service, sharing this data with other Google services.</li>
                      <li>You can opt-out of having your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on.</li>
                      <li>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en-SG" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en-SG</a></li>
                    </ul>

                    <li className="fw-bold">Firebase</li>
                    <ul>
                      <li>Firebase is an analytics service provided by Google Inc.</li>
                      <li>You can opt-out of certain Firebase features through your mobile device settings, or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy?hl=en-SG" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en-SG</a></li>
                    </ul>

                    <li className="fw-bold">Mixpanel</li>
                    <ul>
                      <li>Mixpanel is provided by Mixpanel Inc.</li>
                      <li>You can prevent Mixpanel from using your information for analytics purposes by opting-out.</li>
                      <li>For more information on what type of information Mixpanel collects, please visit the Mixpanel Terms of Use page: <a href="https://mixpanel.com/terms/" target="_blank" rel="noreferrer">https://mixpanel.com/terms/</a></li>
                    </ul>

                    <li className="fw-bold">Amplitude</li>
                    <ul>
                      <li>Their Privacy Policy can be viewed at <a href="https://amplitude.com/privacy" target="_blank" rel="noreferrer">https://amplitude.com/privacy</a></li>
                    </ul>
                  </ol>

                  <li className="fw-bold">Advertising</li>
                  <p className="h6 mb-2">We may engage Service Providers to show advertisements to You, which helps support and maintain Our Service.</p>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">Google Ads</li>
                    <p className="h6 mb-2">Google's use of the DoubleClick cookie allows it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.</p>
                    <ul className="mb-3">
                      <li>You can opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: <a href="https://www.google.com/settings/u/0/ads/authenticated?hl=en-SG" target="_blank" rel="noreferrer">https://www.google.com/settings/u/0/ads/authenticated?hl=en-SG</a></li>
                    </ul>

                    <li className="fw-bold">Facebook</li>
                    <ul className="mb-3"><li>Their Privacy Policy can be viewed at <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">https://www.facebook.com/policy.php</a></li></ul>

                    <li className="fw-bold">LinkedIn</li>
                    <ul className="mb-3"><li>Their Privacy Policy can be viewed at <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer">https://www.linkedin.com/legal/privacy-policy</a></li></ul>
                  </ol>

                  <li className="fw-bold">Email Marketing</li>
                  <p className="h6 mb-2">We may use Your Personal Data to contact You with newsletters, marketing, or promotional materials. This is done in collaboration with a service like User or comparable player. You can opt-out of receiving these communications from Us by following the instructions provided in any email We send or by contacting us.</p>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">User</li>
                    <ul className="mb-3"><li>Their Privacy Policy can be viewed at <a href="https://user.com/en/privacy-policy/" target="_blank" rel="noreferrer">https://user.com/en/privacy-policy/</a></li></ul>
                  </ol>

                  <li className="fw-bold">Payments</li>
                  <p className="h6 mb-2">We may provide paid products and/or services within the Service. In such cases, we use third-party services for payment processing (e.g., payment processors).</p>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">Stripe</li>
                    <ul className="mb-3"><li>Their Privacy Policy can be viewed at <a href="https://stripe.com/sg/privacy" target="_blank" rel="noreferrer">https://stripe.com/sg/privacy</a></li></ul>
                  </ol>

                  <li className="fw-bold">Behavioral Remarketing</li>
                  <p className="h6 mb-2">We use remarketing services to advertise to You after You have accessed or visited our Service.</p>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">Google Ads (AdWords)</li>
                    <ul className="mb-3">
                      <li>Google Ads (AdWords) remarketing service is provided by Google Inc.</li>
                      <li>You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <a href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">https://www.google.com/settings/ads</a></li>
                    </ul>

                    <li className="fw-bold">Twitter (or X)</li>
                    <ul className="mb-3">
                      <li>Twitter remarketing service is provided by Twitter Inc.</li>
                      <li>You can opt-out from Twitter's interest-based ads by following their instructions: <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads" target="_blank" rel="noreferrer">https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads</a></li>
                    </ul>

                    <li className="fw-bold">Facebook</li>
                    <ul className="mb-3">
                      <li>Facebook remarketing service is provided by Facebook Inc.</li>
                      <li>To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217" target="_blank" rel="noreferrer">https://www.facebook.com/help/568137493302217</a></li>
                    </ul>
                  </ol>
                  <p className="h6 mb-3">For all vendors, we recommend that you review their respective privacy policies for a full understanding of their privacy practices and any updates since our last update. For queries or complaints about our data collection or processing practices, please contact us at <a href="mailto:hello@addlly.ai">hello@addlly.ai</a>.</p>

                  <li className="fw-bold">AI Developer Platforms and LLMs</li>
                  <ol type="i" className="mb-3">
                    <li className="fw-bold">Azure Open AI</li>
                    <ul className="mb-3">
                      <li>We use Azure Open AI models for fine tuning our models or generating completions among other steps in the process. The models are stateless: no prompts or generations are stored in the model. Additionally, prompts and generations are not used to train, retrain, or improve the base models. The full details can be found here - <a href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy" target="_blank" rel="noreferrer">https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy</a></li>
                    </ul>

                    <li className="fw-bold">Stability AI</li>
                    <ul className="mb-3">
                      <li>While our platform is modular, we currently use services of Stability AI for image generation. We retain flexibility to move to other similar service providers over time. The privacy policy of Stability can be found here - <a href="https://platform.stability.ai/legal/privacy-policy" target="_blank" rel="noreferrer">https://platform.stability.ai/legal/privacy-policy</a></li>
                    </ul>

                    <li className="fw-bold">Open AI</li>
                    <ul className="mb-3">
                      <li>We use Open AI models for some fine tuning services and generations from time to time. Their privacy policy can be found here - <a href="https://openai.com/policies/privacy-policy" target="_blank" rel="noreferrer">https://openai.com/policies/privacy-policy</a></li>
                    </ul>
                  </ol>
                </ol>

                <li className="h5 mt-5">Use of Your Information</li>
                <p className="h6 mb-3">We use this information to provide and improve our Service, to contact you about our Service, to detect, prevent, and address technical issues, and to provide customer support. We may use the information for developing new features or services that align with your needs. We will respect all restrictions under applicable law regarding use of your data.</p>

                <li className="h5 mt-5">Sharing of Personal Data</li>
                <p className="h6 mb-3">We may share your personal data with third-party vendors and service providers that provide services on our behalf, but under strict conditions to respect and protect your privacy. We will not sell or rent your personal data to third parties unless specifically authorized by you.</p>
                <h4>GDPR Privacy:</h4>
                <ol type="a" className="mb-3">
                  <li className="fw-bold">Grounds for Personal Data Processing Under GDPR</li>
                  <p className="h6 mb-2">We have the right to process your Personal Data under the following scenarios:</p>
                  <ul className="mb-2">
                    <li><b>Consent:</b> You have provided your explicit consent for us to process your Personal Data for specific reason(s).</li>
                    <li><b>Contractual Obligations:</b> The processing of Personal Data is required to fulfil an agreement with you or to meet pre-contractual obligations.</li>
                    <li><b>Legal Obligations:</b> We need to process Personal Data to comply with the legal obligations to which our company is bound.</li>
                    <li><b>Vital Interests:</b> The processing of Personal Data is critical for protecting your vital interests or those of another individual.</li>
                    <li><b>Public Interests:</b> The processing of Personal Data is connected to a task carried out for public interest or in the execution of an official authority vested in our company.</li>
                    <li><b>Legitimate Interests:</b> We need to process Personal Data for the sake of legitimate interests pursued by our company.</li>
                  </ul>
                  <p className="h6 mb-3">In any case, we are more than happy to clarify the precise legal ground applicable to the processing, particularly whether providing your Personal Data is a legal or contractual requirement or a requirement necessary to enter into a contract.</p>

                  <li className="fw-bold">Your GDPR Rights</li>
                  <p className="h6 mb-2">We commit to respecting the confidentiality of your Personal Data and ensuring you can exercise your rights.</p>
                  <p className="h6 mb-2">Under this Privacy Policy, and by law if you reside within the EU, you have the right to:</p>
                  <ul className="mb-3">
                    <li><b>Access:</b> You can request access to, update, or delete the information we have about you. If possible, you can access, update, or request deletion of your Personal Data directly within your account settings. If you cannot perform these actions yourself, please contact us for assistance. This right also allows you to receive a copy of the Personal Data we have about you.</li>
                    <li><b>Correction:</b> If we hold any incomplete or inaccurate information about you, you have the right to have it corrected.</li>
                    <li><b>Objection:</b> You can object to the processing of your Personal Data when we are relying on a legitimate interest and there is something about your particular situation that makes you want to object to the processing. You also have the right to object if we are processing your Personal Data for direct marketing purposes.</li>
                    <li><b>Erasure:</b> You can ask us to delete or remove your Personal Data when there is no valid reason for us to continue processing it.</li>
                    <li><b>Data Transfer:</b> Upon request, we will provide you, or a third party you have selected, your Personal Data in a structured, commonly used, and machine-readable format. This right only applies to automated information for which you initially provided consent or we used to perform a contract with you.</li>
                    <li><b>Consent Withdrawal:</b> You have the right to withdraw your consent for using your Personal Data. If you withdraw your consent, we may not be able to provide you with access to certain specific functionalities of the Service.</li>
                  </ul>

                  <li className="fw-bold">Exercise of Your GDPR Data Protection Rights</li>
                  <p className="h6 mb-3">You can exercise your rights to access, rectification, cancellation, and opposition by contacting us. We may ask you to verify your identity before processing such requests. We will do our best to respond to your request as quickly as possible.</p>
                  <p className="h6 mb-3">You also have the right to lodge a complaint with a Data Protection Authority about our collection and use of your Personal Data. For more information, if you are within the European Economic Area (EEA), please get in touch with your local data protection authority in the EEA.</p>
                </ol>

                <h4>PDPA Privacy</h4>
                <ol type="a" className="mb-3">
                  <li className="fw-bold">Grounds for Personal Data Processing Under PDPA</li>
                  <p className="h6 mb-3"></p>
                  <ul>
                    <li><b>Consent:</b> You have explicitly given us your consent to process your Personal Data for one or more specific purposes.</li>
                    <li><b>Contractual Necessity:</b> The provision and processing of Personal Data is essential to fulfil an agreement with you or to meet pre-contractual obligations.</li>
                    <li><b>Legal Obligations:</b> The processing of Personal Data is necessary to comply with the legal obligations to which our company is subject.</li>
                    <li><b>Legitimate Interests:</b> We need to process Personal Data for the sake of the legitimate interests pursued by our company.</li>
                  </ul>
                  <p className="h6 mb-3">We are always available to clarify the specific legal ground that applies to the processing of your Personal Data, particularly whether providing Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>

                  <li className="fw-bold">Your Rights under the PDPA</li>
                  <p className="h6 mb-3">We commit to respecting the confidentiality of your Personal Data and ensuring you can exercise your rights.</p>
                  <p className="h6 mb-2">Under this Privacy Policy, and by law if you are resident in Singapore, you have the right to:</p>
                  <ul>
                    <li><b>Access:</b> You can request access to, update, or withdraw consent to process the Personal Data we have about you. You may also request information about the ways in which your Personal Data has been or may have been used or disclosed within a year prior to the request. If you cannot perform these actions yourself, please contact us for assistance.</li>
                    <li><b>Correction:</b> If we hold any incorrect or incomplete information about you, you have the right to request correction.</li>
                    <li><b>Withdrawal of Consent:</b> You can withdraw your consent for the collection, use, or disclosure of your Personal Data. If you withdraw your consent, please note that we may not be able to provide you with our full range of services.</li>
                  </ul>

                  <li className="fw-bold">Exercise of Your PDPA Rights</li>
                  <p className="h6 mb-3">You can exercise your rights of access, correction, and withdrawal of consent by contacting us. We may ask you to verify your identity before proceeding with such requests. We will respond to your request as per the PDPA guidelines.</p>
                  <p className="h6 mb-3">If you have any complaints about how we are handling your Personal Data, you have the right to lodge a complaint with the Personal Data Protection Commission (PDPC) in Singapore.</p>
                </ol>

                <li className="h5 mt-5">Security</li>
                <p className="h6 mb-3">We take your security seriously and implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage. However, we cannot guarantee that the Service will be error-free, uninterrupted, or free of harmful components.</p>

                <li className="h5 mt-5">User Rights</li>
                <p className="h6 mb-3">As a user, you have certain rights in relation to your personal data, including the right to access, correct, update, or request deletion of your personal data. You also have the right to object to our processing of your personal data or ask us to restrict processing.</p>

                <li className="h5 mt-5">Third-Party Links</li>
                <p className="h6 mb-3">Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. We do not warrant the offerings of any of these third-party entities or their websites.</p>
                <h4>Disclosure of Personal Information Under PDPA</h4>
                <p className="h6 mb-2">We may distribute your Personal Data identified in our earlier categories to the following types of third parties:</p>
                <p className="h6 mb-2">· Service Providers · Payment processors · Our affiliates · Our business partners · Third party vendors authorised by you or your representatives for us to share your Personal Data with in connection with products or services we provide to you.</p>

                <ol type="a" className="mb-3">
                  <li className="fw-bold">Personal Information of Minors</li>
                  <p className="h6 mb-3">We do not sell the Personal Data of individuals we are aware are under the age of 18. The service is restricted to adults and subject to Terms of Services which can be found HERE (INSERT LINK ON HERE).</p>
                  <p className="h6 mb-3">If you believe that a child under the stated age has provided us with Personal Data, please contact us with enough detail for us to be able to delete that information.</p>

                  <li className="fw-bold">Your Rights under PDPA</li>
                  <p className="h6 mb-2">As outlined in Singapore's Personal Data Protection Act (PDPA), you are entitled to:</p>
                  <ul className="mb-3">
                    <li><b>The right to be informed:</b> You have the right to be informed about the types of Personal Data we are collecting and the intended purpose of use.</li>
                    <li><b>The right to access and correct:</b> You have the right to access and correct the Personal Data we have collected about you. Upon receipt and verification of your request, we will provide you with the information you requested.</li>
                    <li><b>The right to withdraw consent:</b> You have the right to withdraw your consent for the collection, use, or disclosure of your Personal Data at any time. To submit an opt-out request, please contact us.</li>
                    <li><b>The right to delete Personal Data:</b> Subject to certain exceptions, you have the right to request the deletion of your Personal Data. Once we receive and confirm your request, we will delete your Personal Data from our records unless an exception applies.</li>
                  </ul>

                  <li className="fw-bold">Exercising Your PDPA Rights</li>
                  <p className="h6 mb-3">To exercise any of your rights under the PDPA, you can contact us at <a href="mailto:hello@addlly.ai">hello@addlly.ai</a> We may request additional information to verify your identity or authority to make the request.</p>
                  <p className="h6 mb-3">Upon receiving a valid request, we will endeavour to respond as soon as reasonably practicable, in compliance with the PDPA. Please note that we may charge a reasonable fee for the processing of any data access request.</p>

                  <li className="fw-bold">Non-Disclosure of Personal Data</li>
                  <p className="h6 mb-3">You have the right to request that we do not disclose your Personal Data. Once we receive and confirm a verifiable consumer request from you, we will cease to disclose your Personal Data. To exercise your right to non-disclosure, please contact us.</p>
                  <p className="h6 mb-3">Please note that our Service Providers may use technology on our Service that discloses Personal Data as defined by the PDPA law. If you wish to opt out of the use of your Personal Data for interest-based advertising purposes, you may do so by following the instructions provided by the relevant service provider.</p>
                </ol>

                <li className="h5 mt-5">Changes to This Privacy Policy</li>
                <p className="h6 mb-3">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is your responsibility to review this Privacy Policy periodically.</p>

                <li className="h5 mt-5"> Limitation of Liability</li>
                <p className="h6 mb-3">Except as prohibited by law, we are not liable for any indirect, punitive, special, incidental, or consequential damage that may arise from the use of our Service.</p>

                <li className="h5 mt-5">Contact Us</li>
                <p className="h6 mb-3">If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@addlly.ai">hello@addlly.ai</a>.</p>

                <li className="h5 mt-5">Governing Law</li>
                <p className="h6 mb-3">This Privacy Policy and any disputes or claims arising out of or in connection with it are governed by and construed in accordance with the laws of Singapore.</p>

                <li className="h5 mt-5">Acknowledgement</li>
                <p className="h6 mb-3">By using our Service, you acknowledge that you have read this Privacy Policy and agree to its terms.</p>
              </ol>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {type && type === "dataProcessingAgreement" && (
        <Modal className="note-modal full-width" size="lg" centered show={true} onHide={handleClose}>
          <Modal.Body className="text-center p-4">
            <div className="h-100 text-end my-3 mb-4">
              <img src={AddllyLogo} alt="addlly logo" />
            </div>
            <div className="modal-content-body text-start">
              <h2 className="text-primary">Data Processing Agreement</h2>
              <p className="h6">This Data Processing Agreement ("Agreement") is entered into by and between the entity agreeing to these terms ("Customer", "You", or "Controller") and Addlly AI Pte Ltd ("Addlly AI", "We", "Us", "Our", or "Processor"), collectively referred to as the "Parties".</p>

              <ol className="mt-4" style={{ paddingLeft: "2.5rem" }}>
                <li className="h5">Definitions and Interpretations</li>
                <p className="h6">For the purposes of this Agreement:</p>
                <ol type="a" className="mb-3">
                  <li>"Controller" refers to the Customer, who determines the purposes and means of the Processing of Personal Data.</li>
                  <li>"Processor" refers to Addlly AI, who Processes Personal Data on behalf of the Controller.</li>
                  <li>"Personal Data", "Data Subject", "Processing", "Consent", and "Appropriate Safeguards" shall have the same meaning as in the Personal Data Protection Act 2012 (PDPA) of Singapore, General Data Protection Regulation (GDPR) of European Union, the California Consumer Privacy Act (CCPA), and any other relevant jurisdiction.</li>
                  <li>"Services" refers to the services provided by Addlly AI through our online platform.</li>
                </ol>

                <li className="h5 mt-5">Scope and Purpose</li>
                <ol type="a" className="mb-3">
                  <li>This Agreement applies where You are using our Services that involve the Processing of Personal Data.</li>
                  <li>We agree to Process Personal Data on behalf of the Controller, in accordance with the terms set forth in this Agreement and in compliance with applicable data protection laws.</li>
                  <li>The types of Personal Data and the categories of Data Subjects Processed under this Agreement are determined and controlled by the Controller in its sole discretion.</li>
                </ol>

                <li className="h5 mt-5">Processor's Obligations</li>
                <ol type="a" className="mb-3">
                  <li>We shall Process Personal Data only based on documented instructions from the Controller, unless required by law.</li>
                  <li>We ensure that persons authorized to process the Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.</li>
                  <li>We shall implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, in compliance with PDPA, GDPR, CCPA, and other relevant jurisdiction.</li>
                  <li>We respect the conditions for engaging another Processor as set out in the relevant laws and regulations and shall inform the Controller of any intended changes concerning the addition or replacement of other Processors, giving the Controller the opportunity to object to such changes.</li>
                </ol>

                <li className="h5 mt-5">Sub-Processing</li>
                <ol type="a" className="mb-3">
                  <li>We may engage Sub-Processors to process Personal Data. The Sub-Processors currently engaged by Us and authorized by Controller include but not limited to:</li>
                  <ol type="i" className="mb-3">
                    <li>Google Analytics</li>
                    <li>Firebase</li>
                    <li>Mixpanel</li>
                    <li>Amplitude</li>
                    <li>Google AdSense Cookie</li>
                    <li>Facebook</li>
                    <li>LinkedIn</li>
                    <li>User</li>
                    <li>Stripe</li>
                    <li>Google Ads (AdWords)</li>
                    <li>Twitter</li>
                    <li>Azure Open AI </li>
                    <li>Open AI</li>
                    <li>Stability AI</li>
                  </ol>
                  <li>We shall enter into a written agreement with the Sub-Processor imposing data protection obligations comparable to those set out in this Agreement.</li>
                </ol>

                <li className="h5 mt-5">Data Subjects' Rights</li>
                <ol type="a" className="mb-3">
                  <li>We shall assist the Controller, insofar as this is possible, in fulfilling the Controller’s obligation to respond to requests for exercising the Data Subject’s rights under the PDPA, GDPR, CCPA, and other relevant laws.</li>
                  <li>This includes rights to access, rectification, erasure, data portability, restriction of processing, objection to processing, and rights related to automated decision making and profiling.</li>
                </ol>

                <li className="h5 mt-5">Data Breaches</li>
                <ol type="a" className="mb-3">
                  <li>In the event of a data breach, We shall promptly inform the Controller of any Personal Data breach upon becoming aware of it.</li>
                  <li>We will assist the Controller in ensuring compliance with the Controller's obligations regarding the notification of personal data breaches, taking into account the nature of Processing and the information available to us.</li>
                </ol>

                <li className="h5 mt-5">Audit Rights</li>
                <ol type="a" className="mb-3">
                  <li>We shall make available to the Controller all information necessary to demonstrate compliance with the obligations laid down in this Agreement and allow for and contribute to audits, including inspections, conducted by the Controller or another auditor mandated by the Controller.</li>
                </ol>

                <li className="h5 mt-5">Data Transfers</li>
                <ol type="a" className="mb-3">
                  <li>We shall not transfer or authorize the transfer of Data to countries outside the EU or EEA without prior written consent of the Controller. If a data transfer occurs, it must be based on appropriate safeguards as described in applicable data protection laws.</li>
                </ol>

                <li className="h5 mt-5">Term and Termination</li>
                <ol type="a" className="mb-3">
                  <li>This Agreement shall remain in effect until the termination of the Principal Agreement between You and Addlly AI.</li>
                  <li>Upon termination of the Agreement, We shall, at the choice of the Controller, delete or return all the Personal Data to the Controller and delete existing copies unless required by law to store the Personal Data.</li>
                </ol>

                <li className="h5 mt-5">Governing Law and Jurisdiction</li>
                <ol type="a" className="mb-3">
                  <li>This Agreement shall be governed by the laws of Singapore, notwithstanding the jurisdiction where You are based.</li>
                  <li>If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable.</li>
                </ol>
              </ol>

              <h5 className="mt-4">This Agreement is intended to comply with the requirements of the PDPA, GDPR, CCPA, and other relevant data protection laws. </h5>
              <p className="mt-3 h6">Nevertheless, You acknowledge that You are responsible for conducting an independent evaluation of whether our Services and the terms of this Agreement meet your requirements and legal obligations. It's strongly recommended to consult with a legal advisor to ensure compliance with all applicable laws and regulations, including data protection and privacy laws specific to your situation.</p>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <button onClick={handleClose} type="button" className="addlly-primary ms-3">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default AllPolicyModal;
