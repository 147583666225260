import SignIn from "./Page/SignIn";
import Signup from "./Page/Signup";
import Support from "./Page/Support";
import ResetPassword from "./Page/ResetPassword";
import ForgotPassword from "./Page/ForgotPassword";
import PlanDashboard from "./Page/PlanDashboard";
import BuyMoreCredits from "./Page/BuyMoreCredits";
// import BrandVoice from "./Page/BrandVoice";
// import BrandProductImage from "./Page/BrandProductImage";
import ProductImageLibrary from "./Page/ProductImageLibrary";
import Newsletters from "./Page/Newsletters";
import ShiseidoNewsletters from "./Page/ShiseidoNewsletters";
import AzgoNewsletter from "./Page/AzgoNewsletter";
import PressReleaseWriter from "./Page/PressReleaseWriter";
// import ECommerceEdm from "./Page/ECommerceEdm";
// import EdmTemplateGenerate from "./Page/EdmTemplateGenerate";
// import ECommerceLittleFarms from "./Page/ECommerceLittleFarms";
import Account from "./Page/Account";
import Dashboard from "./Page/Dashboard";
import History from "./Page/History";
import SeoOptBlog from "./Page/SeoOptBlog";
// import SeoBlogWriter from "./Page/SeoBlogWriter-old";
import TopResults from "./Page/TopResults";
import SocialMediaPostWriter from "./Page/SocialMediaPostWriter";
import SeoBlogWriter from "./Page/SeoBlogWriter";
import PdfArticleWriter from "./Page/PdfArticleWriter";
// import TatlerSeoBlog from "./Page/TatlerSeoBlog";
import TopicSuggestions from "./Page/TopicSuggestions";
import ECommerceBlogWriter from "./Page/ECommerceBlogWriter";
import AiOverviewWriter from "./Page/AiOverviewWriter";
import OneClickBlogWriter from "./OonaComponents/page/OneClickBlogWriter";
import BlogCoPilotWriter from "./OonaComponents/page/BlogCoPilotWriter";
import PdfToArticleWriter from "./OonaComponents/page/PdfToArticleWriter";
import ReviewHistory from "./OonaComponents/Components/ReviewerWriter/ReviewHistory";
import ReviewArticle from "./OonaComponents/Components/ReviewerWriter/ReviewArticle";

const CommonRoutesForAllRoles = [
  {
    path: "/",
    Component: SignIn,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/support",
    Component: Support,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/history",
    Component: History,
    isPrivate: true,
  },
  {
    path: "/1-click-blog",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  {
    path: "/1-click-blog/:id",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot/:id",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/account/:tab",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/account/:tab/:type",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/buy-more-credits",
    Component: BuyMoreCredits,
    isPrivate: true,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
  },
];

const BCARoutes = [
  ...CommonRoutesForAllRoles,
  {
    path: "/social-media-post-writer",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/social-media-post-writer/:id",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/newsletters",
    Component: Newsletters,
    isPrivate: true,
  },
  {
    path: "/newsletters/:id",
    Component: Newsletters,
    isPrivate: true,
  },
  {
    path: "/press-release",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  {
    path: "/press-release/:id",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  {
    path: "/topic-suggestions",
    Component: TopicSuggestions,
    isPrivate: true,
  },
  {
    path: "/ai-overview",
    Component: AiOverviewWriter,
    isPrivate: true,
  },
];

const ThePurestRoutes = [
  ...CommonRoutesForAllRoles,
  {
    path: "/social-media-post-writer",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/social-media-post-writer/:id",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/e-commerce-blog",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
  {
    path: "/e-commerce-blog/:id",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
];

const OonaRoutes = [
  ...CommonRoutesForAllRoles,
  {
    path: "/oona-1-click/:type",
    Component: OneClickBlogWriter,
    isPrivate: true,
  },
  {
    path: "/oona-1-click/:type/:id",
    Component: OneClickBlogWriter,
    isPrivate: true,
  },
  {
    path: "/oona-blog-co-pilot",
    Component: BlogCoPilotWriter,
    isPrivate: true,
  },
  {
    path: "/oona-blog-co-pilot/:id",
    Component: BlogCoPilotWriter,
    isPrivate: true,
  },
  {
    path: "/pdf-to-article",
    Component: PdfToArticleWriter,
    isPrivate: true,
  },
  {
    path: "/pdf-to-article/:id",
    Component: PdfToArticleWriter,
    isPrivate: true,
  },
  {
    path: "/review-history",
    Component: ReviewHistory,
    isPrivate: true,
  },
  {
    path: "/review-article/:articleType/:articleId",
    Component: ReviewArticle,
    isPrivate: true,
  },
  {
    path: "/ai-overview",
    Component: AiOverviewWriter,
    isPrivate: true,
  },
];

const AzgoRoutes = [
  {
    path: "/",
    Component: SignIn,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/support",
    Component: Support,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/account/:tab",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/account/:tab/:type",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/plans",
    Component: PlanDashboard,
    isPrivate: true,
  },
  {
    path: "/buy-more-credits",
    Component: BuyMoreCredits,
    isPrivate: true,
  },
  {
    path: "/travel-newsletter",
    Component: AzgoNewsletter,
    isPrivate: true,
  },
  {
    path: "/travel-newsletter/:id",
    Component: AzgoNewsletter,
    isPrivate: true,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
  },
];

const CMIRoutes = [
  {
    path: "/",
    Component: SignIn,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/support",
    Component: Support,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/cmi-newsletters",
    Component: ShiseidoNewsletters,
    isPrivate: true,
  },
  {
    path: "/cmi-newsletters/:id",
    Component: ShiseidoNewsletters,
    isPrivate: true,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
  },
  {
    path: "/account/:tab",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/account/:tab/:type",
    Component: Account,
    isPrivate: true,
  },
];

const ROUTES = [
  {
    path: "/",
    Component: SignIn,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/support",
    Component: Support,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/history",
    Component: History,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/blog-co-pilot/:id",
    Component: SeoOptBlog,
    isPrivate: true,
  },
  {
    path: "/1-click-blog",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  {
    path: "/1-click-blog/:id",
    Component: SeoBlogWriter,
    isPrivate: true,
  },
  /* {
    path: "/pdf-to-article",
    Component: PdfArticleWriter,
    isPrivate: true,
  },
  {
    path: "/pdf-to-article/:id",
    Component: PdfArticleWriter,
    isPrivate: true,
  }, */
  {
    path: "/social-media-post-writer",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/social-media-post-writer/:id",
    Component: SocialMediaPostWriter,
    isPrivate: true,
  },
  {
    path: "/account/:tab",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/account/:tab/:type",
    Component: Account,
    isPrivate: true,
  },
  {
    path: "/top-results",
    Component: TopResults,
    isPrivate: true,
  },
  {
    path: "/plans",
    Component: PlanDashboard,
    isPrivate: true,
  },
  {
    path: "/buy-more-credits",
    Component: BuyMoreCredits,
    isPrivate: true,
  },
  /* {
    path: "/brand-voice",
    Component: BrandVoice,
    isPrivate: true,
  },
  {
    path: "/brand-voice/:id",
    Component: BrandVoice,
    isPrivate: true,
  }, */
  {
    path: "/product-image-library",
    Component: ProductImageLibrary,
    isPrivate: true,
  },
  {
    path: "/newsletters",
    Component: Newsletters,
    isPrivate: true,
  },
  {
    path: "/newsletters/:id",
    Component: Newsletters,
    isPrivate: true,
  },
  /* {
    path: "/cmi-newsletters",
    Component: ShiseidoNewsletters,
    isPrivate: true,
  },
  {
    path: "/cmi-newsletters/:id",
    Component: ShiseidoNewsletters,
    isPrivate: true,
  }, */
  {
    path: "/press-release",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  {
    path: "/press-release/:id",
    Component: PressReleaseWriter,
    isPrivate: true,
  },
  /* {
    path: "/e-commerce-edm",
    Component: ECommerceEdm,
    isPrivate: true,
  },
  {
    path: "/e-commerce-edm/:id",
    Component: ECommerceEdm,
    isPrivate: true,
  },
  {
    path: "/edm-template",
    Component: EdmTemplateGenerate,
    isPrivate: true,
  },
  {
    path: "/edm-template/:id",
    Component: EdmTemplateGenerate,
    isPrivate: true,
  },
  {
    path: "/ecom-little-farms",
    Component: ECommerceLittleFarms,
    isPrivate: true,
  },
  {
    path: "/ecom-little-farms/:id",
    Component: ECommerceLittleFarms,
    isPrivate: true,
  },
  {
    path: "/tatler-seo-blog",
    Component: TatlerSeoBlog,
    isPrivate: true,
  },
  {
    path: "/tatler-seo-blog/:id",
    Component: TatlerSeoBlog,
    isPrivate: true,
  }, */
  {
    path: "/e-commerce-blog",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
  {
    path: "/e-commerce-blog/:id",
    Component: ECommerceBlogWriter,
    isPrivate: true,
  },
  {
    path: "/topic-suggestions",
    Component: TopicSuggestions,
    isPrivate: true,
  },
  {
    path: "/ai-overview",
    Component: AiOverviewWriter,
    isPrivate: true,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
  },
  /* {
    path: "/brand-product-image",
    Component: BrandProductImage,
    isPrivate: true,
  },
  {
    path: "/brand-product-image/:id",
    Component: BrandProductImage,
    isPrivate: true,
  }, */
];

export { ROUTES, OonaRoutes, ThePurestRoutes, BCARoutes, AzgoRoutes, CMIRoutes };
