import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../Components/ui/Title";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import DropdownMenu from "../Components/ui/Menu";
import CustomDataTable from "../Components/ui/DataTable";
import TableLoader from "../Components/ui/TableLoader";
import { articleHistoryIcons, dataTableIcons } from "../Assets/Icons";
import { SortArrow, sorting, tableDateFormatter } from "../utility/common";
import FirstStepNewsletters from "../Components/ShisedioComponents/FirstStepNewsletter";
import SecondStepNewsletters from "../Components/ShisedioComponents/SecondStepNewsletters";
import ThirdStepNewsletters from "../Components/ShisedioComponents/ThirdStepNewsletters";

const ShiseidoNewsletters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [currStep, setCurrStep] = useState(1);
  const [shiseidoNewslettersInfo, setShiseidoNewslettersInfo] = useState({});

  useEffect(() => {
    if (id && id !== "") {
      if (id !== "type") {
        handleGetDetailsById(id);
      }
    } else {
      setCurrStep(1);
      setShiseidoNewslettersInfo({});
      navigate("/cmi-newsletters");
    }
  }, [id])

  const handleOnChangeStep = (no) => {
    if (shiseidoNewslettersInfo?.stepDone && no <= shiseidoNewslettersInfo.stepDone) {
      if (no === 1) {
        setShiseidoNewslettersInfo({});
        navigate("/cmi-newsletters");
      }
      setCurrStep(no);
    }
  };

  const handleGetDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await AxiosApi.get(`/api/cmi-shiseido/history-or-details/byType/shiseido-content?id=${id}`);
      if (res && res.data && res.data.article) {
        setCurrStep(3); setShiseidoNewslettersInfo({ ...res.data, stepDone: 3 });
        navigate(`/cmi-newsletters/${res.data?.id}`);
      } else {
        toast.error("Newsletter content not generated.", { id: "Toast-01" });
      }

      const scrollWrapper = document.getElementById("scroll-wrapper");
      if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: "smooth" }); }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const steps = useMemo(() => [
    <FirstStepNewsletters currStep={currStep} setCurrStep={setCurrStep} setShiseidoNewslettersInfo={setShiseidoNewslettersInfo} />,
    <SecondStepNewsletters setCurrStep={setCurrStep} shiseidoNewslettersInfo={shiseidoNewslettersInfo} setShiseidoNewslettersInfo={setShiseidoNewslettersInfo} />,
    <ThirdStepNewsletters setCurrStep={setCurrStep} shiseidoNewslettersInfo={shiseidoNewslettersInfo} setShiseidoNewslettersInfo={setShiseidoNewslettersInfo} />,
  ], [currStep, shiseidoNewslettersInfo]);

  return (
    <React.Fragment>
      {currStep !== 3 && (
        <div className="oneClickHead d-flex flex-column pt-3">
          <div className="">
            <Heading className="fw-bolder mainHeading" size="3">CMI Newsletter Builder</Heading>
            <p>Create high-converting CMI newsletters with easy templates and live links to your website.</p>
          </div>
        </div>
      )}
      <div className={`stepWraper ${currStep === 3 ? "mt-2" : "mt-4"}`}>
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <NewslettersHeader currStep={currStep} shiseidoNewslettersInfo={shiseidoNewslettersInfo} handleOnChangeStep={handleOnChangeStep} />
          {steps[currStep - 1]}
        </div>
      </div>
      {currStep === 1 && (
        <div className="d-flex gap-2 flex-column tableHeading mt-4">
          <div className="mt-2">
            <NewslettersHistory handleGetDetailsById={handleGetDetailsById} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const NewslettersHeader = ({ currStep, shiseidoNewslettersInfo, handleOnChangeStep }) => {

  const [isMobileHead, setIsMobileHead] = useState()

  useEffect(() => {
    const handleResize = () => {
      setIsMobileHead(window.innerWidth <= 320);
    };

    setTimeout(() => {
      handleResize();
    }, 1000)

    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  }, []);

  return (
    <div className="AIWriterBlogHead SocialMediaHeader">
      <div className="AiWriterBlogHeadTop justify-content-center AIWriterStepper">
        <ul>
          <li
            className={`${currStep === 1 ? "active" : ""} ${1 < currStep ? "complete" : ""} ${1 <= shiseidoNewslettersInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(1)} onKeyDown={() => handleOnChangeStep(1)}
          >
            <span className="d-flex justify-content-center align-items-center">01</span>
            <p className="" style={{ fontSize: isMobileHead && "10px" }}>{"Select Category"}</p>
          </li>
          <li
            className={`${currStep === 2 ? "active" : ""} ${2 < currStep ? "complete" : ""} ${2 <= shiseidoNewslettersInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(2)} onKeyDown={() => handleOnChangeStep(2)}
          >
            <span className="d-flex justify-content-center align-items-center">02</span>
            <p className="" style={{ fontSize: isMobileHead && "10px" }}>{"Select Article"}</p>
          </li>
          <li
            className={`${currStep === 3 ? "active" : ""} ${3 < currStep ? "complete" : ""} ${3 <= shiseidoNewslettersInfo.stepDone ? "is-clickable" : ""}`}
            onClick={() => handleOnChangeStep(3)} onKeyDown={() => handleOnChangeStep(3)}
          >
            <span className="d-flex justify-content-center align-items-center">03</span>
            <p className="" style={{ fontSize: isMobileHead && "10px" }}>{"Edit Newsletters"}</p>
          </li>
        </ul>
      </div>
    </div>
  )
};

const NewslettersHistory = ({ handleGetDetailsById }) => {
  const userRes = useSelector(({ userRes }) => userRes);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    getNewslettersHistory();
  }, []);

  const getNewslettersHistory = async () => {
    setTableLoader(true); setData([]);
    const url = `/api/cmi-shiseido/history-or-details/byType/shiseido-content?userId=${userRes.id}`;
    AxiosApi.get(url).then((response) => {
      if (response.data && response.data.length > 0) {
        setData(response.data);
      }
      setTableLoader(false);
    }).catch((error) => {
      setTableLoader(false);
    });
  };

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(data, column?.sortField, sortDirection);
    setData(newData);
  };

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
      selector: (row) => (
        <div className="d-flex align-items-center gap-3">
          <div className={classNames("tableValues", { showFullDes: false })}>
            <h6>{row.title || "-"}</h6>
          </div>
        </div>
      ),
      sortable: true,
      sortField: "title",
    },
    {
      id: "category",
      name: <div className="d-flex">category {SortArrow(sortField, "category")}</div>,
      selector: (row) => row?.category,
      sortable: true,
      sortField: "category",
    },
    {
      id: "created_at",
      name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
      selector: (row) => tableDateFormatter(row?.created_at),
      sortable: true,
      sortField: "created_at",
    },
    {
      id: "status",
      name: <div className="d-flex ">Status {SortArrow(sortField, "status")}</div>,
      selector: (row) => (
        <div className="d-flex justify-content-center align-items-center">
          <div className="dotStatus">
            <span className={`d-block`}
              style={{
                width: "8px", height: "8px", borderRadius: "50%",
                backgroundColor: row.status === "completed" ? "#84CC16" : row.status === "pending" ? "#F59E0B" : "#EF4444",
              }}
            />
          </div>
          {row.status === "completed" ? "Done" : row.status === "pending" ? "In Progress" : "Error"}
        </div>
      ),
      sortable: true,
      sortField: "status",
    },
    {
      id: "sort",
      name: "Action",
      selector: (row) => {
        return (
          <div className="popup">
            <DropdownMenu>
              <MenuItem onClick={() => handleGetDetailsById(row.id)}>
                <ReactSVG src={dataTableIcons.pencil} /> Edit
              </MenuItem>
            </DropdownMenu>
          </div>
        );
      },
    },
  ]), [sortField]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between gap-2 mb-3">
        <span className="mb-2">Recent History</span>
      </div>
      <div className="historyTableBlock mt-2">
        <CustomDataTable
          progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
          columns={columns} totalCount={data.length || 0} pagination={true} data={data}
          itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
          onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
          handleTableRowClick={() => { }} clearSelectedRows={() => { }}
        />
      </div>
    </React.Fragment>
  )
};

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};

export default ShiseidoNewsletters;
