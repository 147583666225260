import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { setAzgoNewsletterStep, setAzgoNewsletterInfo } from "../../store/reducer";

const AzgoNewsletterHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const azgoNewsletterStep = useSelector(({ azgoNewsletterStep }) => azgoNewsletterStep);
  const azgoNewsletterInfo = useSelector(({ azgoNewsletterInfo }) => azgoNewsletterInfo);
  const isShowLinks = azgoNewsletterInfo && azgoNewsletterInfo.generateLink !== 0 ? true : false;
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileHead, setIsMobileHead] = useState(false);
  const stepArr = [
    { "step": 1, "label": "Select Product" },
    { "step": 2, "label": "Select Template" },
    { "step": 3, "label": "Final Step" },
  ];

  useEffect(() => {
    function mobileViewUpdate() {
      const viewportWidth = window.innerWidth;
      if (viewportWidth <= 770) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const parentDiv = document.getElementsByClassName('AIWriterBlog')[0];
      if (parentDiv) {
        const parentRect = parentDiv.getBoundingClientRect();
        const parentWidth = parentRect.width;
        const childDiv = document.getElementsByClassName('AIWriterStepper')[0];
        if (childDiv) {
          const childRect = childDiv.getBoundingClientRect();
          const childWidth = childRect.width;
          if (parentWidth <= childWidth) {
            setIsMobileHead(true);
          } else {
            setIsMobileHead(false);
          }
        } else {
          console.log('childDiv not found');
        }
      } else {
        console.log('parentDiv not found');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const handleOnChangeStep = async (no) => {
    if (no >= 1 && no <= 3) {
      if (azgoNewsletterInfo?.stepDone && no <= azgoNewsletterInfo?.stepDone) {
        dispatch(setAzgoNewsletterStep(no));
      }
    }
  }

  return (
    <div className="AIWriterBlogHead">
      <div className="AIWriterBlogHeadTop justify-content-center">
        <div className="AIWriterStepper justify-content-center">
          <ul>
            {stepArr && stepArr.map((step, index) => (
              (!isShowLinks && step.step === 3) ? null : (
                <li
                  className={`${azgoNewsletterStep === step.step ? "active" : ""} ${step.step < azgoNewsletterStep ? "complete" : ""} ${step.step <= azgoNewsletterInfo?.stepDone ? "is-clickable" : ""}`}
                  onClick={() => handleOnChangeStep(step.step)} onKeyDown={() => handleOnChangeStep(step.step)} key={index}
                >
                  <span className="d-flex justify-content-center align-items-center">
                    {!isShowLinks && step.step > 3 ? `0${step.step - 1}` : `0${step.step}`}
                  </span>
                  <p className={``}>{step.label}</p>
                </li>
              )
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AzgoNewsletterHead;
