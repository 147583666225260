import React from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { ReactSVG } from "react-svg";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AxiosApi } from "../utility/axios";
import { authenticateIcon } from "../Assets/Icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { GoArrowLeft } from "react-icons/go";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  description: Yup.string().required("Description is required")
});

const Support = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const params = { ...data, type: "support" };
    const res = await AxiosApi.post("/api/user/contact-us/create/support", params);
    if (res.data && res.data === "success") {
      toast.success("Response send successfully");
      navigate("/");
    } else {
      toast.error("Oops!! Something went wrong, please try again later.");
    }
  }

  return (
    <div className="Reset-password maingenrateBlock">
      <div className="d-flex align-items-center justify-content-between logos">
        <ReactSVG src={authenticateIcon.AddllyBlueLogo} alt="addlly logo" className="cursor-pointer" onClick={() => navigate("/")} />
        <Link to={"/ecom-little-farms"} className="d-flex gap-2 align-items-center border-0 text-decoration-none" onClick={() => navigate("/")}>
          <GoArrowLeft /> Back to home
        </Link>
      </div>
      <div className="d-flex align-items-center justify-content-center password-section flex-column">
        <h5>Need Help?</h5>
        <p>The support will be available during business hours and can be reached at <a href="mailto:support@addlly.ai">support@addlly.ai</a>.</p>
        <p className="pb-3">
          The support team will acknowledge any issues within [12] business hours (Singapore time) <br />
          and resolve critical issues within [24] hours and other issues within [72] hours.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
          <div className="genrateFields">
            <Row className="m-0">
              <Col xl={12} className="p-0">
                <div className="fields mb-3">
                  <label className="color-light-text p-0">Name <sup>*</sup></label>
                  <div className="inputField pb-2">
                    <input
                      name="name" type="text" {...register("name")} placeholder="Enter Name"
                      className={`w-100 ${errors?.name ? "is-invalid" : ""}`}
                    />
                    {errors.name && <Row>
                      <div className="invalid">{errors.name.message}</div>
                    </Row>}
                  </div>
                </div>
                <div className="fields mb-3">
                  <label className=" color-light-text p-0">Email  <sup>*</sup></label>
                  <div className=" inputField pb-2">
                    <input
                      name="email" type="text" {...register("email")} placeholder="Enter Email"
                      className={`w-100 ${errors?.email ? "is-invalid" : ""}`}
                    />
                    {errors.email && <Row>
                      <div className="invalid">{errors.email.message}</div>
                    </Row>}
                  </div>
                </div>
                <div className="fields mb-3">
                  <label className=" color-light-text p-0">Describe the issue you are facing?  <sup>*</sup></label>
                  <div className=" inputField pb-2">
                    <textarea
                      name="description" type="text"  {...register("description")}
                      className={`w-100 ${errors?.description ? "is-invalid" : ""}`}
                      placeholder="Enter Description"
                    />
                    {errors.description && <Row>
                      <div className="invalid">{errors.description.message}</div>
                    </Row>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="authenticate-button  d-flex align-items-center pb-4 pt-2">
            <button type="submit" disabled={!watch("name") || !watch("email") || !watch("description")}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Support;
