import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Accordion, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RxImage } from "react-icons/rx";
import { BiSolidErrorCircle } from "react-icons/bi";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { LuDownload } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import { MdOutlineDeleteForever } from "react-icons/md";
import classNames from "classnames";
import { BsDownload, BsEyeFill } from "react-icons/bs";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import GIFLoader from "../Assets/Gif/8080laoder.gif";
import Heading from "../Components/ui/Title";

const fileTypes = ["JPG", "PNG", "JPEG"];

const ProductImageLibrary = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const [uploadImages, setUploadImages] = useState({ files: [], previewUrl: [] });
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [imagesHistory, setImagesHistory] = useState(null);
  const [productImageHistory, setProductImageHistory] = useState(null);
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0 });

  useEffect(() => {
    handleGetGeneratedImageHistory();
  }, [])

  const handleGetGeneratedImageHistory = () => {
    dispatch(setLoader(true));
    setImagesHistory([]);
    setProductImageHistory([]);
    const url = `/api/product-image-library/list/byUser/${userRes.id}?isGetHistory=true`;
    AxiosApi.get(url).then((response) => {
      if (response && response.data && response.data.images && response.data.images.length > 0) {
        setImagesHistory(response.data.images);
      }
      if (response && response.data && response.data.productImageData && response.data.productImageData.length > 0) {
        setProductImageHistory(response.data.productImageData);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  }

  const handleOnSelectImageFile = async (files) => {
    if (!files || files?.length === 0) {
      return false;
    }

    setUploadFileDragging(null);
    setUploadFileError(null);
    const allFiles = [...uploadImages.files], previewUrl = [...uploadImages.previewUrl];
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      allFiles.push(element);
      previewUrl.push(URL.createObjectURL(element));
    }

    setUploadImages({ files: allFiles, previewUrl: previewUrl });
  }

  const handleOnUploadSelectedImageFile = async (data) => {
    if (uploadImages.files && uploadImages.files.length === 0) {
      return false;
    }
    const tempImgArray = [...imagesHistory];

    const uploadSingleFile = (index) => {
      if (uploadImages.files && (uploadImages.files.length <= index)) {
        toast.success('Images uploaded successfully.', { id: "Toast-01" });
        setUploadImages({ files: [], previewUrl: [] });
        setUploadFileDragging(null);
        setUploadFileError(null);
        dispatch(setLoader(false));
        return false;
      }

      const file = uploadImages.files[index];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        AxiosApi.post(`/api/product-image-library/save-upload-image`, {
          userId: userRes.id,
          imageBase64: reader.result,
          file_name: file.name,
        }).then((response) => {
          if (response && response.data && response.data.imageUrl && response.data.imageUrl !== "") {
            tempImgArray.unshift(response.data.imageUrl);
            setImagesHistory([...tempImgArray]);
          }
          uploadSingleFile(index + 1);
        }).catch((error) => {
          uploadSingleFile(uploadImages.files.length + 2);
          dispatch(setLoader(false));
        });
      };
    }

    dispatch(setLoader(true));
    uploadSingleFile(0);
  }

  const FileUploaderCustomUi = (
    <div className="file-upload-content">
      <RxImage className="image-icon mb-4" />
      <h3 className="mb-3">Upload your product image to get started</h3>
      <h4 className="mb-1 fw-normal">Click to upload or drag and drop</h4>
      <p>Formats: JPG, PNG, or JPEG (max. 5MB)</p>
      {uploadFileError ? (
        <React.Fragment>
          <hr className="w-100 mt-4" />
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" />  {uploadFileError}
          </h5>
        </React.Fragment>
      ) : null}
    </div>
  );

  const handleDownloadImage = (e, imgUrl) => {
    e.stopPropagation();
    dispatch(setLoader(true));
    const link = document.createElement('a');
    link.download = `image-${Date.now()}.png`;

    fetch(imgUrl).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    }).then(blob => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href)
    }).catch(() => {
      dispatch(setLoader(false));
    });
  }

  const handleOnChangeViewImage = (index) => {
    if (viewImgModal && viewImgModal.history && viewImgModal.history.length > 0) {
      if (index !== -1 && viewImgModal.history.length >= index) {
        setViewImgModal({ open: true, img: viewImgModal.history[index], index, history: viewImgModal.history });
      }
    }
  }

  return (
    <div>
      <div className="d-flex flex-column py-4">
        <div className="">
          <Heading className="fw-bolder mainHeading" size="3">Image Gallery</Heading>
          <p>Choose from AI generated or royalty free image libraries to add stunning images to your blogs and social posts.</p>
        </div>
      </div>

      <div className="border-radius-12 AIWriterBlog AISocialMediaWriter">
        <div className="addllyFormWrap product-image-wrapper">
          <Row>
            <div className="right-penal w-100 mb-4">
              <FileUploader
                name="file" types={fileTypes} maxSize={5} children={FileUploaderCustomUi} multiple={true}
                classes={`file-upload-wrapper ${uploadFileDragging ? "active" : ""}`} handleChange={handleOnSelectImageFile}
                onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: 'none' }}
                onSizeError={() => setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file.")}
                onTypeError={() => setUploadFileError("Invalid file type. Please upload a file with a supported file type.")}
              />

              {uploadImages && uploadImages.previewUrl && uploadImages.previewUrl.length > 0 ? (
                <div className="file-preview-wrapper mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="mb-1 fw-normal">Preview Images</h4>
                    <button onClick={handleOnUploadSelectedImageFile} type="button" className="addlly-primary px-3 py-2">
                      <span>Upload Images</span>
                    </button>
                  </div>
                  <div className="image-preview-wrapper mb-3">
                    {uploadImages.previewUrl.map((file, index) => (
                      <div className="image-preview-content" key={index}>
                        <img src={file} alt="" />
                        <div className="remove-btn">
                          <button className="btn" type="button" onClick={(e) => {
                            setUploadImages({
                              files: uploadImages.files.filter((e1, i1) => i1 !== index),
                              previewUrl: uploadImages.previewUrl.filter((e1, i1) => i1 !== index),
                            })
                          }}>
                            <MdOutlineDeleteForever />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {imagesHistory && imagesHistory.length > 0 && (
                <React.Fragment>
                  <hr className="my-4" />
                  <Accordion className="dashboard-accordion image-history-wrapper">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="">
                        <h4 className="fw-normal mb-0">Uploaded Image History ( {imagesHistory.length || 0} )</h4>
                      </Accordion.Header>
                      <Accordion.Body className="p-3">
                        <div className="genrateImagesCards">
                          {imagesHistory && imagesHistory.map((image, index) => (
                            <ProductImgWithLoader
                              previewImg={image} key={index} index={index} history={imagesHistory}
                              handleDownloadImage={handleDownloadImage} setViewImgModal={setViewImgModal}
                            />
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {productImageHistory && productImageHistory.length > 0 && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="">
                          <h4 className="fw-normal mb-0">Generated Product Images ( {productImageHistory.reduce((acc, subArray) => acc + subArray.length, 0) || 0} )</h4>
                        </Accordion.Header>
                        <Accordion.Body className="p-3 overflow-auto" style={{ maxHeight: "488px" }}>
                          {productImageHistory && productImageHistory.map((images, index1) => (
                            <div className="genrateImagesCards d-flex flex-wrap">
                              {images && images.map((image, index2) => (
                                <div className="card border-0">
                                  <img src={images} alt="Post pictures" />
                                  <div className={classNames("showHoverButtons")}>
                                    <div className="vdButton d-flex">
                                      <button className="bg-white border-0 rounded ms-auto"
                                        data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`View`}
                                        onClick={(e) => {
                                          e.stopPropagation(); e.preventDefault();
                                          setViewImgModal({ open: true, img: image, index: index2 });
                                        }}
                                      >
                                        <BsEyeFill />
                                      </button>
                                      <button className="bg-white border-0 rounded ms-2" onClick={handleDownloadImage}
                                        data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom-end" data-tooltip-content={`Download`}
                                      >
                                        <BsDownload />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </React.Fragment>
              )}
            </div>
          </Row>
        </div>
      </div>

      {viewImgModal && viewImgModal.open && (
        <Modal centered fullscreen className="image-history-modal" backdrop="static"
          show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}
        >
          <Modal.Body className="">
            <div className="image-content">
              <button className="btn arrow-btn"
                onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}
                disabled={viewImgModal.index !== 0 ? false : true}
              >
                <FaCaretLeft className="" />
              </button>
              <div className="image-view-wrapper">
                <img src={viewImgModal.img} alt="" />
              </div>
              <button className="btn arrow-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}
                disabled={viewImgModal.index !== (viewImgModal.history.length - 1) ? false : true}
              >
                <FaCaretRight className="" />
              </button>
              <button className="btn download-btn me-3" onClick={(e) => handleDownloadImage(e, viewImgModal.img)}>
                <LuDownload className="fs-4" /> Download Image
              </button>
              <label className="img-label"><span className="activeSliderCount">0{viewImgModal.index + 1}</span> / 0{viewImgModal.history.length}</label>
            </div>
            <button className="btn close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
              <GrClose className="fs-4" />
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

const ProductImgWithLoader = ({ previewImg, index, history, handleDownloadImage, setViewImgModal }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="card border-0">
      <img src={previewImg} alt="Product Img" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
      {!loaded && previewImg && (
        <div className="custom-post-loader">
          <img src={GIFLoader} alt="loading..." className="" />
        </div>
      )}
      {loaded && previewImg && (
        <div className="download-btn">
          <button className="btn" type="button" onClick={(e) => handleDownloadImage(e, previewImg)}>
            <LuDownload />
          </button>
        </div>
      )}
      <div className={classNames("showHoverButtons")}>
        <div className="vdButton d-flex">
          <button className="bg-white border-0 rounded ms-auto"
            data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`View`}
            onClick={(e) => {
              e.stopPropagation(); e.preventDefault();
              setViewImgModal({ open: true, img: previewImg, index: index, history: history });
            }}
          >
            <BsEyeFill />
          </button>
          <button className="bg-white border-0 rounded ms-2" onClick={handleDownloadImage}
            data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom-end" data-tooltip-content={`Download`}
          >
            <BsDownload />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductImageLibrary;
