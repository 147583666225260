import React, { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert';
import toast from 'react-hot-toast';
import HtmlDiff from 'htmldiff-js';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { FaEye } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsClockHistory } from "react-icons/bs";
import { LuArrowDownUp } from "react-icons/lu";
import { BsFilterCircle } from "react-icons/bs";
import { PiArrowCounterClockwiseLight } from "react-icons/pi";
import DropdownMenu from '../ui/Dropdown';
import { AxiosApi } from '../../utility/axios';
import { sorting } from '../../utility/common';
import { setLoader } from '../../store/reducer';
import { copyIcon } from '../../Assets/Icons'
import { filterBy, versionList } from '../../data';
import { EditorInitObj } from '../../utility/hepler';

const VersionHistory = (props) => {
  const {
    versionHistory, selectedVersionItem, setSelectedVersionItem, handleSetRestoredOldVersionText,
    handleCopyHtmlArticleText, selectedSubTab, setSelectedSubTab, articleType = ""
  } = props;

  const dispatch = useDispatch();
  const [versionsHtml, setVersionsHtml] = useState('');
  const [versionsData, setVersionsData] = useState([]);

  useEffect(() => {
    if (versionHistory && versionHistory.length > 0) {
      setVersionsData(versionHistory);
    } else {
      setVersionsData([]);
      setVersionsHtml("");
    }
  }, [versionHistory])

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = 'hidden' ? 'auto' : 'hidden';
    };
    handleBodyOverflow();
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleRestoreArticleOrPostVersion = async () => {
    if (!selectedVersionItem?.id) {
      return false;
    }

    if (selectedVersionItem.is_restored === 1) {
      Swal("Oops...", "Unable to restore version. The requested version has already been restored.", "error");
      return false;
    }

    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, restore it!" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          setSelectedSubTab("");

          const dataObj = {};
          await AxiosApi.post(`/api/article-or-post/restore-version/${selectedVersionItem.id}`, dataObj);
          Swal("Restored!", "Version has been restored.", "success");

          let fieldName = "";
          if (selectedVersionItem.article_sub_type === "Short Article" && selectedVersionItem.article_type === "1-Click Blog") {
            fieldName = "article_html";
          } else if (selectedVersionItem.article_sub_type === "Co-Pilot Article" && selectedVersionItem.article_type === "Blog Co-Pilot") {
            fieldName = "article_html";
          } else if (selectedVersionItem.article_sub_type === "E-Commerce Blog Article" && selectedVersionItem.article_type === "E-Commerce Blog") {
            fieldName = "article_html";
          } else if (selectedVersionItem.article_sub_type === "E-Commerce Newsletter" && selectedVersionItem.article_type === "E-Commerce Blog") {
            fieldName = "newsletter_html";
          } else if (selectedVersionItem.article_sub_type === "Long Article") {
            fieldName = "article_detail_html";
          } else if (selectedVersionItem.article_sub_type === "FAQ and Schema Markup") {
            fieldName = "FAQHTML";
          } else if (selectedVersionItem.article_sub_type === "LinkedIn Post" && selectedVersionItem.article_type !== "Social Media Post Writer") {
            fieldName = "linkedIn_post";
          } else if (selectedVersionItem.article_sub_type === "Facebook Post" && selectedVersionItem.article_type !== "Social Media Post Writer") {
            fieldName = "facebook_post";
          } else if (selectedVersionItem.article_sub_type === "Twitter Post" && selectedVersionItem.article_type !== "Social Media Post Writer") {
            fieldName = "twitter_post";
          } else if (selectedVersionItem.article_sub_type === "Instagram Post" && selectedVersionItem.article_type !== "Social Media Post Writer") {
            fieldName = "instagram_post";
          } else if (selectedVersionItem.article_sub_type === "Instagram Reels" && selectedVersionItem.article_type !== "Social Media Post Writer") {
            fieldName = "instagram_reels";
          } else if (selectedVersionItem.article_sub_type === "Google Ad Copy") {
            fieldName = "googleAdCopy";
          } else if (selectedVersionItem.article_type === "Social Media Post Writer") {
            fieldName = "final_post";
          } else if (selectedVersionItem.article_type === "azgoNewsletter") {
            fieldName = "response"
          }

          handleSetRestoredOldVersionText(fieldName, selectedVersionItem.old_article);
          setSelectedVersionItem({});
          setSelectedSubTab(selectedSubTab && selectedSubTab !== "" ? selectedSubTab : "");
          dispatch(setLoader(false));
        } catch (error) {
          toast.error("Something went wrong, Please try again.");
          dispatch(setLoader(false));
        }
      }
    });
  }

  const removeSpanTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const spans = doc.querySelectorAll('span');
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return doc.body.innerHTML;
  };

  const handleScrollOnTextEditorByTag = async (selection) => {
    await new Promise(resolve => setTimeout(resolve, 200));
    try {
      const iframe = document.querySelector('iframe');
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const elements = iframe.contentWindow.document.querySelectorAll(selection);
        if (elements && elements.length > 0) {
          elements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectedCurrentVersionItem = async (version) => {
    setSelectedVersionItem(version);
    if (selectedVersionItem.id === version.id) {
      return false;
    }

    let modifiedHTML = "";
    if (version.is_regenerated) {
      const versionTextArray = [version?.new_article || "", version?.old_article || ""];
      for (let index = 0; index < versionTextArray.length; index++) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = versionTextArray[index];
        if (version.article_sub_type.indexOf("Article") === -1) {
          const sentences = versionTextArray[index].split('\n');
          let output = '<p>' + sentences.join('</p><p>') + '</p>';
          tempDiv.innerHTML = output.replaceAll("<p></p>", "");
        }
        if (version.article_sub_type.indexOf("EDM Article") !== -1) {
          tempDiv.innerHTML = '<p>' + versionTextArray[index] + '</p>';
        }
        const childNodes = tempDiv.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
          const node = childNodes[i];
          if (node && node.nodeType !== 1 && node.childNodes && node.childNodes.length > 0) {
            for (let i = 0; i < node.childNodes.length; i++) {
              const node2 = node.childNodes[i];
              if (node2.nodeType === 1) {
                const tempNode = document.createElement(index === 1 ? "del" : "ins");
                tempNode.innerHTML = node2.innerHTML;
                tempNode.style.color = index === 1 ? "#FF0000" : "#00CC00";
                tempNode.style.backgroundColor = index === 1 ? "#fbe1e1" : "#e1f5e1";
                tempNode.style.textDecoration = index === 1 ? "line-through" : "none";
                node2.innerHTML = "";
                node2.appendChild(tempNode);
              }
            }
          } else if (node.nodeType === 1) {
            const tempNode = document.createElement(index === 1 ? "del" : "ins");
            tempNode.innerHTML = node.innerHTML;
            tempNode.style.color = index === 1 ? "#FF0000" : "#00CC00";
            tempNode.style.backgroundColor = index === 1 ? "#fbe1e1" : "#e1f5e1";
            tempNode.style.textDecoration = index === 1 ? "line-through" : "none";
            node.innerHTML = "";
            node.appendChild(tempNode);
          }
        }

        if (index === 0) {
          modifiedHTML = tempDiv.innerHTML;
          modifiedHTML += `<br />`;
        } else {
          modifiedHTML += tempDiv.innerHTML;
        }
      }
    } else {
      const tempDiv1 = document.createElement("div");
      tempDiv1.innerHTML = version?.old_article;
      if (version.article_sub_type.indexOf("Article") === -1) {
        const sentences = version?.old_article.split('\n');
        let output = '<p>' + sentences.join('</p><p>') + '</p>';
        tempDiv1.innerHTML = output.replaceAll("<p></p>", "");
      }

      const tempDiv2 = document.createElement("div");
      tempDiv2.innerHTML = version?.new_article;
      if (version.article_sub_type.indexOf("Article") === -1) {
        const sentences = version?.new_article.split('\n');
        let output = '<p>' + sentences.join('</p><p>') + '</p>';
        tempDiv2.innerHTML = output.replaceAll("<p></p>", "");
      }

      const oldHtmlString = removeSpanTags(tempDiv1.innerHTML);
      const newHtmlString = removeSpanTags(tempDiv2.innerHTML);

      modifiedHTML = await HtmlDiff.execute(oldHtmlString, newHtmlString);
      modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">&nbsp;</ins>`, "");
      modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">`, `<ins style="color: #00CC00; background-color: #e1f5e1; text-decoration: none;">`);
      modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">&nbsp;</ins>`, "");
      modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">`, `<ins style="color: #00CC00; background-color: #e1f5e1; text-decoration: none;">`);

      modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">&nbsp;</del>`, "");
      modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">`, `<del style="color: #FF0000; background-color: #fbe1e1; text-decoration: line-through;">`);
      modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">&nbsp;</del>`, "");
      modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">`, `<del style="color: #FF0000; background-color: #fbe1e1; text-decoration: line-through;">`);

      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(modifiedHTML, 'text/html');
      const insTags = Array.from(parsedHtml.querySelectorAll('ins img, del img')).map(img => img.parentElement);
      insTags?.forEach(insTag => {
        insTag.style.display = 'block'; insTag.style.width = 'fit-content'; insTag.style.padding = '10px';
      });
      modifiedHTML = parsedHtml.documentElement.outerHTML
    }

    setVersionsHtml(modifiedHTML);
    handleScrollOnTextEditorByTag(`span`);
  }

  const handleCountChanges = (version) => {
    let count = 0
    const newParagraph = version?.new_article || '';
    const oldParagraph = version?.old_article || '';
    const newWords = newParagraph.split(' ');
    const oldWords = oldParagraph.split(' ');
    newWords.forEach(word => {
      if (!oldWords.includes(word)) {
        count++;
      }
    });
    return count;
  }

  const handleSort = (value) => {
    const sortedValue = sorting(versionsData, 'name', value?.direction);
    setVersionsData([...sortedValue]);
  }

  const handleFilter = (data) => {
    if (data.value === "reGenerated") {
      const reGeneratedData = versionHistory.filter(version => version.is_regenerated);
      setVersionsData([...reGeneratedData]);
    } else {
      setVersionsData([...versionHistory]);
    }
  }

  const getCurrentVersion = (versionName) => {
    const newVersionHistory = [...versionHistory];
    const sortedValue = sorting(newVersionHistory, 'name', 'desc');
    if (sortedValue[0]?.name === versionName) {
      return <IoCheckmarkCircle data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Current version`} />
    };
  }

  const previousVersion = (selectedVersion) => {
    const currentIndex = versionHistory.findIndex(item => item.name === selectedVersion.name);
    if (currentIndex !== -1) {
      const previousIndex = currentIndex + 1;
      if (previousIndex <= versionHistory.length) {
        const previousItem = versionHistory[previousIndex];
        return previousItem?.name !== undefined ? previousItem?.name : 1;
      } else {
        console.log("No previous item exists.");
      }
    } else {
      console.log("Selected item not found in the array.");
    }
  }

  return (
    <div className='unselectable'>
      <button className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#versionHistory" aria-controls="versionHistory">
        Version History
      </button>
      <div className='verionBlock'>
        <div className="offcanvas offcanvas-end " tabIndex="-1" id="versionHistory" aria-labelledby="versionHistoryLabel">
          {selectedVersionItem?.id ? (
            <div className={classNames('selectedPopup', { activeArrow: selectedVersionItem?.id ? true : false })}>
              <div className='versionHeader d-flex justify-content-between align-items-center'>
                <div className='leftButton d-flex gap-3 align-items-center'>
                  <span>Version {selectedVersionItem?.name}</span>
                  <div className='d-flex align-items-center'>
                    <button className="blogButton changes-btn border-0">
                      <span className='changes-number'>
                        {handleCountChanges(selectedVersionItem) < 10 ? '0' + handleCountChanges(selectedVersionItem) : handleCountChanges(selectedVersionItem)}
                      </span> changes
                    </button>
                    <BsInfoCircleFill className='infoIconSvg'
                      data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                      data-tooltip-content={`${handleCountChanges(selectedVersionItem) < 10 ? '0' + handleCountChanges(selectedVersionItem) : handleCountChanges(selectedVersionItem)} changes compare to version ${previousVersion(selectedVersionItem)}`}
                    />
                  </div>
                </div>
                <div className='rightButton d-flex gap-3 align-items-center'>
                  <button
                    className="copyBtn" type='button' onClick={() => handleCopyHtmlArticleText(selectedVersionItem?.new_article)}
                    data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`Copy`}
                  >
                    <img src={copyIcon} alt='copyImage' />
                  </button>
                  <button
                    className="blogButton border-0" type='button' onClick={handleRestoreArticleOrPostVersion}
                    data-tooltip-id={"my-tooltip"} data-tooltip-place="left" data-tooltip-content={`Restore to the previous version`}
                  >
                    <PiArrowCounterClockwiseLight /> Restore
                  </button>
                  <button
                    type="button" className="btn-close text-reset"
                    onClick={() => { setSelectedVersionItem({}); }}
                  ></button>
                </div>
              </div>
              {["NewsLetters", "E-Commerce Blog", "E-Commerce EDM", "EDM Template Writer"].includes(articleType) ? (
                <div className="content mt-4 pe-2" style={{ height: "calc(100vh - 200px)" }}>
                  <div className={`textarea-article-html h-100`}>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY} value={versionsHtml || ""} disabled
                      init={{
                        ...EditorInitObj, height: "100%",
                        content_style: `${(selectedVersionItem?.old_article?.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}`,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className='versionData position-relative'>
                  <div className='textarea-article-html rounded p-3' dangerouslySetInnerHTML={{ __html: versionsHtml }}></div>
                </div>
              )}
            </div>
          ) : null}
          <div className='verpopup'>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title version-history-title" id="versionHistoryLabel">
                <BsClockHistory /> Version History
              </h5>
              <button
                type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                onClick={() => setSelectedVersionItem({})}
              ></button>
            </div>
            {versionHistory && versionHistory.length > 0 && (
              <div className="filtersDropdown d-flex align-items-center justify-content-between  rounded ">
                <div className='dropdown'>
                  <label>Sort By<LuArrowDownUp /></label>
                  <DropdownMenu
                    options={versionList} onSelect={handleSort}
                    label={"name"} className="border-0" placeholder={"Version Number"}
                  />
                </div>
                <div className='dropdown'>
                  <label>Filter By<BsFilterCircle /></label>
                  <DropdownMenu
                    options={filterBy} onSelect={handleFilter}
                    label={"name"} className="border-0" placeholder={"All"}
                  />
                </div>
              </div>
            )}
            <div className="offcanvas-body">
              {versionsData && versionsData.length > 0 ? (
                <div className='versionNo'>
                  {versionsData.map((version, index) => (
                    <div key={version?.id + ' ' + index} className={classNames('versionCards mb-3', { active: selectedVersionItem?.id === version?.id })}>
                      <div className='headerV d-flex justify-content-between align-items-center'>
                        <div className='currentVersion d-flex align-items-center gap-2'>
                          <h4>{getCurrentVersion(version?.name)}Version {version?.name}</h4>
                          {version?.is_regenerated ? (
                            <div className="buttonUpgrade d-flex ">
                              <span
                                className="custom-button w-100 border-0  align-items-center justify-content-center text-center undefined btn btn-primary rounded-pill"
                                style={{ background: "linear-gradient(103deg, rgb(0, 0, 255) 0%, rgb(255, 0, 0) 121.74%)" }}
                              >Re-Generated</span>
                            </div>
                          ) : null}
                        </div>
                        <div onClick={() => handleSelectedCurrentVersionItem(version)} className="viewIcon">
                          <FaEye data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View version`} />
                        </div>
                      </div>
                      <div className='versionDetails'>
                        <div className="info d-grid align-items-center">
                          <p className='fst-italic'>Created On</p>
                          <span className='text-black text-start d-block position-relative fw-normal'>
                            {new Date(version?.created_at).toLocaleString()?.replace(',', ' ')}
                          </span>
                        </div>
                        <div className="info d-grid align-items-center">
                          <p className='fst-italic'>Created By</p>
                          <span className='text-black text-start d-block position-relative fw-normal'>{version?.user_name}</span>
                        </div>
                        <div className="info d-grid align-items-center">
                          <p className='fst-italic'> Total Differences
                            <BsInfoCircleFill className='infoIconSvg'
                              data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
                              data-tooltip-content={`${handleCountChanges(version) < 10 ? '0' + handleCountChanges(version) : handleCountChanges(version)} changes compare to version ${versionsData[index + 1]?.name !== undefined ? versionsData[index + 1]?.name : 1}`}
                            />
                          </p>
                          <span className='text-black  text-start d-block position-relative fw-normal'>{handleCountChanges(version)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className='d-flex align-items-center justify-content-center border p-5 rounded '>
                  Version history is not available yet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VersionHistory;
