import React, { memo, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { IoIosList } from "react-icons/io";
import { BsImages } from "react-icons/bs";
import { FaBookReader, FaRegSave } from "react-icons/fa";
import { BsArrowsFullscreen } from "react-icons/bs";
import { LuCheckCircle } from "react-icons/lu";
import { BsArrowsAngleContract, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { FiRefreshCw } from "react-icons/fi";
import DropdownMenu from "../../../../Components/ui/Dropdown";
import { AxiosApi } from "../../../../utility/axios";
import { setFinalContent, setLoader, setUserRes } from "../../../../store/reducer";
import useScreenSize from "../../../../hooks/useScreenSize";
import { copyIcon } from "../../../../Assets/Icons";
import VersionHistory from "../../../../Components/commonComponents/VersionHistory";

const menus = [
  { name: "Article", value: "article", subTab: "seoScore" },
  { name: "FAQs & Schema Markup", value: "faqSchema", subTab: "" },
  { name: "AI Overview", value: "aiOverview", subTab: "" },
  { name: "Fact Checker", value: "factChecker", subTab: "" },
  { name: "Review Article", value: "reviewArticle", subTab: "comments" },
  { name: "Refund Requests", value: "refundRequests", subTab: "" },
];

export default memo(function NavItems(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const userRes = useSelector(({ userRes }) => userRes);
  const seoGenerateTopic = useSelector(({ seoGenerateTopic }) => seoGenerateTopic);
  const seoOptBlogTopic = useSelector(({ seoOptBlogTopic }) => seoOptBlogTopic);
  const isFullScreen = searchParams.get("full-screen");
  const selectedTab = searchParams.get("tab") || "article";
  const subTab = searchParams.get("subTab") || "seoScore";
  const {
    handleAutoFactChecker, handleSaveArticleOrPostText, generatedFullArticle, getFullData, generateProgress,
    handleGetAllImagesAndOpenModal, selectedSubTab, setSelectedSubTab, articleType, setContentScore,
    handleSetRestoredOldVersionText, handleCopyHtmlArticleText, regenerateBtnInfo,
    setRegenerateBtnInfo, handleGetHtmlAndTextTyping, setSelectedCurrentTab, setSendToEditorForm, handleSaveReviewArticleText,
  } = props;

  const screenSize = useScreenSize();
  const { keyword, aiType: AI_type } = seoOptBlogTopic;
  const { meta_title, meta_dec } = generatedFullArticle;
  const [currentActiveTab, setCurrentActiveTab] = useState({ currentTab: "", imageSubType: "", versionSubType: "" });
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [showFeedbackModel, setShowFeedbackModel] = useState({ open: false, feedback: "" });

  const checkTab = useMemo(() => (["article", "faqSchema"]), []);

  useEffect(() => {
    let currentTab = "", imageSubType = "", versionSubType = "";
    if (selectedTab === "article") {
      currentTab = "article_html"; imageSubType = "Article Images"; versionSubType = "Co-Pilot Article";
    } else if (selectedTab === "faqSchema") {
      currentTab = "FAQHTML"; imageSubType = ""; versionSubType = "FAQ and Schema Markup";
    } else if (selectedTab === "refundRequests") {
      currentTab = "refundRequests"; imageSubType = ""; versionSubType = "";
    }

    handleTab(currentTab, imageSubType, versionSubType);
    if (selectedSubTab && selectedTab && checkTab.includes(selectedTab)) {
      setSelectedVersionItem({});
      getArticleAllVersionHistory(versionSubType);
    };
  }, [checkTab, selectedSubTab, selectedTab]);

  const handleFullScreen = () => {
    if (isFullScreen) {
      setSearchParams(selectedTab ? "tab=" + selectedTab + "&subTab=" + subTab : "");
    } else {
      setSearchParams(selectedTab ? "tab=" + selectedTab + "&subTab=" + subTab + "&full-screen=true" : "full-screen=true");
    }
  };

  const onTabSelect = (option) => {
    setSearchParams(isFullScreen ? ("tab=" + option.value + (option.subTab && "&subTab=" + option.subTab) + "&full-screen=" + isFullScreen)
      : ("tab=" + option.value + (option.subTab && "&subTab=" + option.subTab)));
    setSelectedCurrentTab(option.value);
  };

  const handleTab = (currentTabValue, imageSubTypeValue, versionSubTypeValue) => {
    setCurrentActiveTab(pre => ({ ...pre, currentTab: currentTabValue, imageSubType: imageSubTypeValue, versionSubType: versionSubTypeValue }));
  }

  const handleImageLibrary = () => {
    if (
      currentActiveTab.imageSubType && currentActiveTab.imageSubType !== "" &&
      generatedFullArticle[currentActiveTab.currentTab] && generatedFullArticle[currentActiveTab.currentTab] !== ""
    ) {
      handleGetAllImagesAndOpenModal(currentActiveTab.imageSubType, generatedFullArticle[currentActiveTab.currentTab]);
    }
  }

  const handleCopy = (data) => {
    toast.success("Text has been copied to clipboard.");
    copy(data);
  };

  const getArticleAllVersionHistory = async (versionSubType) => {
    try {
      if (!seoOptBlogTopic.articleId || !versionSubType) {
        return false;
      }

      setVersionHistory([]);
      const params = `articleId=${seoOptBlogTopic.articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  }

  const getGenerateButton = () => {
    const buttonClassNames = (tab) => classNames("blogButton border-0", { "primary-btn": !generatedFullArticle?.[tab] });
    if (selectedTab === "article") {
      return (
        <React.Fragment>
          {!generatedFullArticle?.readability_used && (
            <button type="button" onClick={() => getFullData("readability")} className={"blogButton border-0 me-2"}>
              <FaBookReader /> Readability
            </button>
          )}
          {!regenerateBtnInfo?.isOptimize && (
            <div className="text-center">
              <button
                className={buttonClassNames("article")} type="button" disabled={regenerateBtnInfo?.generating ? regenerateBtnInfo.generating : false}
                onClick={() => {
                  if (Number(userRes?.credits) < (userRes?.deductCredits?.["Blog Co-Pilot"] || 3)) {
                    toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
                    return;
                  }

                  dispatch(setFinalContent({ ...generatedFullArticle, isTypingDone: false, article_html: "", SEO_score_response: null }));
                  setContentScore({}); setRegenerateBtnInfo({ generating: true, text: "Regenerating ..." });
                  handleGetHtmlAndTextTyping(true);
                  dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Blog Co-Pilot"] || 3) }));
                }}
              >
                <FiRefreshCw className={`fs-5 ${regenerateBtnInfo?.generating ? "svg-rotate" : ""}`} />
                {regenerateBtnInfo?.text ? regenerateBtnInfo?.text : "Regenerate Article"}
                {regenerateBtnInfo?.generating && (
                  <span className="ms-2">{generateProgress > 0 && `${generateProgress} %`}</span>
                )}
              </button>
            </div>
          )}
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.article_html ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Blog Co-Pilot"] || 4} Addlly credits`}
          />
        </React.Fragment>
      );
    } else if (selectedTab === "faqSchema") {
      return (
        <React.Fragment>
          {generatedFullArticle?.FAQHTML && generatedFullArticle?.FAQHTML !== "" ? (
            <button
              type="button" onClick={() => setShowFeedbackModel({ open: true, type: "FAQHTML" })} className={"blogButton border-0"}
              disabled={!generatedFullArticle?.faq_regenerate_left}
            >
              <BsStars /> Re-Generate ( {generatedFullArticle?.faq_regenerate_left || 0} / 3 )
            </button>
          ) : (
            <button type="button" onClick={() => getFullData("FAQHTML")} className={buttonClassNames("FAQHTML")}>
              <BsStars /> Generate
            </button>
          )}
        </React.Fragment>
      );
    }
  };

  const handleImageLibraryButton = () => {
    if (["article"].includes(selectedTab)) {
      return (
        <button
          type="button" onClick={handleImageLibrary} className="blogButton border-0"
          disabled={!generatedFullArticle[currentActiveTab.currentTab] || regenerateBtnInfo?.generating}
        >
          <BsImages /> Image Library
        </button>
      )
    }
  };

  return (
    <div>
      <div className="navTopButton d-flex justify-content-between flex-wrap gap-4">
        <div className="leftButtons d-flex gap-3 align-items-center">
          {(isFullScreen || screenSize?.width < 1280) && (
            <div className="d-flex gap-3 dropdown">
              <DropdownMenu
                options={menus.filter((item) => item.name === "Review Article" && userRes.role === "OrgAdmin" ? false : item)}
                label={"name"} className="border-0" placeholder={"Select tabs"}
                selectedOption={menus.filter((menu) => menu.value === selectedTab)[0]}
                onSelect={onTabSelect} disabled={regenerateBtnInfo?.generating}
              />
            </div>
          )}

          {selectedTab === 'factChecker' ? (
            <React.Fragment>
              <h4 className="auto-fact-heading">Addlly AI Fact Finder</h4>
              <BsInfoCircleFill className="infoIconSvg"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
                data-tooltip-content={`Our tool collates factually correct information from whitelisted resources.`}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <button
                  className="blogButton border-0"
                  data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
                >
                  <IoIosList /> Metadata
                </button>
              </div>
              {checkTab.includes(selectedTab) ? (
                <VersionHistory
                  versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                  handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
                />
              ) : null}
              <span className="line"></span>
              {selectedTab === "reviewArticle" && (
                <button type="button" className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#commentVersionHistory"
                  aria-controls="commentVersionHistory" onClick={() => onTabSelect({ value: "reviewArticle", subTab: "version" })}
                >
                  Version History
                </button>
              )}
            </React.Fragment>
          )}

          <div className="d-flex gap-3">
            <div className="regenrateBtn d-flex flex-wrap gap-3 align-items-center">{getGenerateButton()}</div>
          </div>
        </div>
        <div className="rightSideButton d-flex gap-2">
          {handleImageLibraryButton()}

          {selectedTab === "article" && (
            <button
              className="blogButton border-0" onClick={handleAutoFactChecker}
              disabled={!generatedFullArticle[currentActiveTab.currentTab] || regenerateBtnInfo?.generating}
            >
              <LuCheckCircle /> Auto Citation
            </button>
          )}
          {seoOptBlogTopic?.is_check_reviewer === 0 && selectedTab === "reviewArticle" && (
            <button className="blogButton border-0" onClick={() => setSendToEditorForm(true)}>
              <LuCheckCircle /> Submit for Review
            </button>
          )}
          {!['factChecker', 'reviewArticle', 'chart', 'refundRequests'].includes(selectedTab) ? (
            <button data-tooltip-id="save" data-tooltip-content="Save" data-tooltip-place="bottom"
              className="blogButton border-0 bg-black saveButton" onClick={handleSaveArticleOrPostText}
              disabled={!generatedFullArticle[currentActiveTab.currentTab] || regenerateBtnInfo?.generating}
            >
              <FaRegSave /> {isFullScreen ? "Save" : ""}
            </button>
          ) : selectedTab === "reviewArticle" ? (
            <button data-tooltip-id="save" data-tooltip-content="Save" data-tooltip-place="bottom"
              className="blogButton border-0 bg-black saveButton" onClick={handleSaveReviewArticleText}
            >
              <FaRegSave /> {isFullScreen ? "Save" : ""}
            </button>
          ) : null}

          <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
            {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
          </button>
          <Tooltip id="save" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      </div>

      <div className="offcanvas offcanvas-end " tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {'Blog’s'} Metadata
          </h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>What do you want to write about?</p>
              <span className=" ">{seoGenerateTopic}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src={copyIcon} alt="" onClick={() => handleCopy(seoGenerateTopic)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>Keyword</p> <span className=" ">{keyword}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src={copyIcon} alt="" onClick={() => handleCopy(keyword)}
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>AI Model</p> <span className=" ">{AI_type}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img src={copyIcon} alt="" onClick={() => handleCopy(AI_type)}
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`} />
            </div>
          </div>
          <div style={{ background: "var(--Black-B30, #EBEBED)", height: "1px" }} className="mb-4"></div>
          <div className="blogMetaInner">
            <p><BsStars /> Headline generated</p>
            <span className=" ">{seoGenerateTopic}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta title generated</p>
            <span className=" ">{meta_title}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta description generated</p>
            <span className=" ">{meta_dec}</span>
          </div>
        </div>
      </div>

      {showFeedbackModel && showFeedbackModel.open && (
        <Modal size="lg" centered show={showFeedbackModel.open} onHide={() => setShowFeedbackModel({ open: false })}>
          <Modal.Body className="text-start p-0 overflow-auto" style={showFeedbackModel.article ? { height: "90vh" } : {}}>
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => {
                  getFullData(showFeedbackModel?.type, true, showFeedbackModel?.feedback);
                  setShowFeedbackModel({ open: false });
                }}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={showFeedbackModel?.feedback}
                    onChange={(e) => setShowFeedbackModel({ ...showFeedbackModel, feedback: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
})
