import React, { useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsCloudUploadFill, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FileUploader } from "react-drag-drop-files";
import { BiSolidErrorCircle } from "react-icons/bi";
import { OonaAiTypeOptions } from "../../../data";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import UserArticleHistory from "../../../Components/UserArticleHistory";
import { getHistory } from "../../../utility/apiService";
import ProductTreeView from "../1ClickBlogWriter/ProductTreeView";
import { uploadPdfFileOnS3 } from "../../../utility/uploadPdfFileOnS3";
import { invokeLambdaFunction } from "../../../utility/invokeLamdaFunction";
import { handleCustomSelectOption, OonaLanguageList } from "../../../utility/hepler";
import { setGeneratedFullArticle, setLoader, setTopResultsStep, setUserRes } from "../../../store/reducer";
import LoadingScreen from "../../../Components/SeoBlogWriterNew/first-step/LoadingScreen";

const FirstStepPdfArticleWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { id: articleId } = useParams();

  const userRes = useSelector(({ userRes }) => userRes);
  const topResultsStep = useSelector(({ topResultsStep }) => topResultsStep);

  const [tab, setTab] = useState({ tab: 1, progress: 0 });
  const [active, setActive] = useState(false);
  const [pdfFilePath, setPdfFilePath] = useState("");
  const [aiType, setAiType] = useState("GPT 4 Omni");
  const [currentLan, setCurrentLan] = useState("Bahasa Indonesia");
  const [uploadFileDragging, setUploadFileDragging] = useState(null);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [file, setFile] = useState(null);
  const [isDisabledInput, setIsDisabledInput] = useState(articleId ? true : false);
  const [showSelectedLink, setShowSelectedLink] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const onContinue = async () => {
    try {
      if (!pdfFilePath || pdfFilePath === "") {
        return false;
      }

      setTab({ tab: 1, progress: 0 });
      setActive(true);

      const payload = {
        body: JSON.stringify({
          pdfPath: pdfFilePath, aiType: aiType, user_id: userRes.id, lan: currentLan,
          type: "generate-pdf-to-article", selectedProduct: selectedProduct || "",
        })
      };

      const response = await invokeLambdaFunction("generateOonaArticleOrSaveByType", payload);
      if (response && response.success === 1) {
        if (fileInputRef.current) { fileInputRef.current.value = null; };
        setTab((prev) => ({ ...prev, progress: 100 }));
        await new Promise(resolve => setTimeout(resolve, 2000));

        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["1-Click Blog"] || 2) }));
        dispatch(setGeneratedFullArticle({ ...response.data, articleId: response.data?.id, shortArticleType: "false" }));
        setActive(false); dispatch(setTopResultsStep(3));
        navigate(`/pdf-to-article/${response.data?.id}`);
      } else {
        setActive(false);
        let message = response.error;
        if (message.indexOf("Azure") !== -1 || message.indexOf("filtered") !== -1) {
          message = "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic.";
        }
        toast.error(message, { id: "Toast-01" });
        dispatch(getHistory(userRes?.id, 10, 0, "artical_history", "PDF"));
      }
    } catch (error) {
      setActive(false);
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const generateGoogleTopLinks = async () => {
    try {
      if (!pdfFilePath || pdfFilePath === "") {
        return false;
      }

      const payload = {
        body: JSON.stringify({
          pdfPath: pdfFilePath, user_id: userRes.id, lan: currentLan, aiType: "GPT 4 Omni",
          type: "generate-pdf-google-links", selectedProduct: selectedProduct || "",
        })
      };

      const response = await invokeLambdaFunction("generateOonaArticleOrSaveByType", payload);
      if (response && response.success === 1) {
        if (fileInputRef.current) { fileInputRef.current.value = null; };
        dispatch(setGeneratedFullArticle({ ...response.data, articleId: response.data?.articleId, shortArticleType: "false" }));
        setActive(false); dispatch(setTopResultsStep(2));
        navigate(`/pdf-to-article/${response.data?.articleId}`);
      } else {
        toast.error(response.error, { id: "Toast-01" });
        dispatch(getHistory(userRes?.id, 10, 0, "artical_history", "PDF"));
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleOnUploadPdfFile = async (files) => {
    if (Number(userRes.credits) < (userRes?.deductCredits?.["1-Click Blog"] || 2)) {
      toast.error("You have used all your addlly credits.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; };
      return false;
    }
    const file = files[0];
    setFile(files);
    const MAX_FILE_SIZE = 6144; // 6 MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 6 MB.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; };
      return false;
    }

    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf' || file.type !== 'application/pdf') {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      if (fileInputRef.current) { fileInputRef.current.value = null; };
      return false;
    }

    dispatch(setLoader(true));
    setUploadFileError(null);
    try {
      const filePath = await uploadPdfFileOnS3(file, "long-flow");
      dispatch(setLoader(false));
      if (filePath && filePath !== "") {
        setPdfFilePath(filePath);
      } else {
        toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      fileInputRef.current.value = '';
    }
  };

  const FileUploaderCustomUi = useMemo(() => (
    <div className={` position-relative ${uploadFileDragging ? "active" : ""}`}>
      <BsCloudUploadFill className="uploadIcon" />
      <h4 className="fw-normal form-label file-upload-header">
        <strong>Choose a file</strong> or drag it here
      </h4>
      <p className="m-0 file-upload-pera">
        Supported formats: pdf <br /> maximum file size: 5MB.
      </p>
      {uploadFileError ? (
        <React.Fragment>
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {uploadFileError}
          </h5>
        </React.Fragment>
      ) : file ? (
        <h5 className="fw-normal text-primary text-center">
          {file && file[0].name ? file[0].name : ""}
        </h5>
      ) : null}
    </div>
  ), [file, uploadFileDragging, uploadFileError]);

  const handleItemClick = (itemLabel) => {
    setSelectedProduct(itemLabel);
  };

  return (
    <React.Fragment>
      <div className="maingenrateBlock bg-white card p-0 mb-2">
        {!active ? (
          <div className="border-radius-12 bg-white AIWriterBlog">
            <div className="AIWriterBlogHead d-flex justify-content-center">
              <div className="AIWriterBlogHeadTop justify-content-center">
                <div className="AIWriterStepper">
                  <ul>
                    <li className={`${topResultsStep === 1 ? "active" : ""} ${1 < topResultsStep ? "complete" : ""}`}>
                      {topResultsStep <= 1 ? <span>01</span> : <span>01</span>}
                      <p>Upload PDF</p>
                    </li>
                    {showSelectedLink ? (
                      <>
                        <li className={`${topResultsStep === 2 ? "active" : ""} ${2 < topResultsStep ? "complete" : ""}`}>
                          {topResultsStep <= 2 ? <span>02</span> : <span>02</span>}
                          <p>Select Links</p>
                        </li>
                        <li className={`${topResultsStep === 3 ? "active" : ""} ${2 < topResultsStep ? "complete" : ""}`}>
                          {topResultsStep <= 3 ? <span>03</span> : <span>03</span>}
                          <p>Preview Article</p>
                        </li>
                      </>
                    ) : (
                      <li className={`${topResultsStep === 2 ? "active" : ""} ${2 < topResultsStep ? "complete" : ""}`}>
                        {topResultsStep <= 2 ? <span>02</span> : <span>02</span>}
                        <p>Preview Article</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="genrateFields px-3">
              <Row className="Oona">
                <Col sm="12" md="3" className="border-end OonaTree pb-2">
                  <p className="text-muted mb-2" style={{ fontSize: "14px", fontWeight: "500" }}>Select Products</p>
                  <ProductTreeView handleItemClick={handleItemClick} />
                </Col>
                <Col sm="12" md="9" className="blog-writer-content overflow-visible px-2 ps-md-4 pt-3 pt-md-0">
                  <div className="fields form-group mb-4">
                    <label>Upload PDF for generate article (maximum upload file size: 6MB)</label>
                    <div className="fileUploadBlock" style={{ height: '200px' }}>
                      <FileUploader
                        name="file" types={["pdf"]} maxSize={5} children={FileUploaderCustomUi}
                        multiple={true} classes={`w-100`} handleChange={handleOnUploadPdfFile} isDisabled={isDisabledInput}
                        onDraggingStateChange={(dragging) => setUploadFileDragging(dragging)} dropMessageStyle={{ display: "none" }}
                        onSizeError={() => { setUploadFileError("File size exceeds the allowable limit. Please upload a smaller file."); }}
                        onTypeError={() => { setUploadFileError("Invalid file type. Please upload a file with a supported file type."); }}
                      />
                    </div>
                    {pdfFilePath && pdfFilePath != "" && (
                      <div className="d-flex align-items-center mt-2">
                        <AiOutlineFilePdf className="fs-5 me-3" />
                        <a href={pdfFilePath} target="_blank" rel="noreferrer" className="text-decoration-none">
                          {pdfFilePath.split("/")[pdfFilePath.split("/").length - 1]}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="pdf-select-checkbox d-flex gap-1 align-items-center mb-3">
                    <input
                      inline name={"post"} id={"post"} type="checkbox" disabled={isDisabledInput}
                      checked={showSelectedLink} onChange={(e) => {
                        setShowSelectedLink(!showSelectedLink)
                      }}
                    />
                    <label className="cursor-pointer ms-2" htmlFor="post">Take into consideration other related news and websites</label>
                  </div>
                  <Row>
                    <Col sm="12" md="6" className="fields form-group mb-4">
                      <div className="form-group">
                        <label>Choose your AI Model</label>
                        <Select
                          name={"aiType"} placeholder="Select AI Type" isSearchable={false}
                          options={OonaAiTypeOptions} isClearable={false} className={`custom-select cust-blog-se`}
                          classNamePrefix="custom-select" onChange={(e) => setAiType(e.value)}
                          value={OonaAiTypeOptions.find((c) => c.value === aiType)}
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6" className="fields form-group mb-4">
                      <label>Choose a Language</label>
                      <Select
                        className={`custom-select`} classNamePrefix="custom-select" placeholder="Select Language"
                        options={OonaLanguageList} isClearable={false} isSearchable={false}
                        value={OonaLanguageList.find(c => c.value === currentLan) || ""}
                        onChange={(e) => setCurrentLan(e.value)} getOptionLabel={handleCustomSelectOption}
                      />
                    </Col>
                  </Row>
                  <div className="genrateBtn activeBtn d-flex align-items-center mt-3">
                    <button
                      type="button" className={`addlly-primary ${pdfFilePath && pdfFilePath !== "" ? 'activeBtn' : ""}`}
                      onClick={() => { showSelectedLink ? generateGoogleTopLinks() : onContinue() }}
                      disabled={pdfFilePath && pdfFilePath !== "" ? false : true}
                    >
                      {true ? <BsStars /> : <img src={oneClickBlogIcon.spaceRocket} alt="spaceRocket" />}
                      {showSelectedLink ? 'Generate a Links' : 'Generate a Blog'}
                    </button>
                    <BsInfoCircleFill
                      className="text-decoration-none outline-0  infoIconSvg " data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
                      data-tooltip-content={`Will utilize ${userRes?.deductCredits?.["1-Click Blog"] || 2} Addlly credits`}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <LoadingScreen show={active} label="PDF to article" type="short" id={0} progress={tab.progress} isPdfArticle={true} />
        )}
      </div>
      {topResultsStep === 1 ? (
        <div className=" d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <UserArticleHistory />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default FirstStepPdfArticleWriter;
