import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAzgoNewsletterInfo, setAzgoNewsletterStep } from "../../store/reducer";
import { Col, Dropdown, DropdownButton, InputGroup, ListGroup, Row } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight, BsEyeFill, BsInfoCircleFill } from "react-icons/bs";
import azgoProducts from "../../data/Azgo-products";
import { FiCheck } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import ProductViewModal from "./ProductViewModal";

const FirstStepAzgoNewsletter = () => {
  const dispatch = useDispatch();

  const azgoNewsletterInfo = useSelector(({ azgoNewsletterInfo }) => azgoNewsletterInfo);

  const [promotionText, setPromotionTextText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const allProductList = azgoProducts;
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [viewProduct, setViewProduct] = useState({ open: false, data: null });
  const [isDisabledInput, setIsDisabledInput] = useState(azgoNewsletterInfo?.articleId ? true : false);
  const [category, setCategory] = useState(["Hotels", "Attractions"]);

  useEffect(() => {
    if (azgoNewsletterInfo.stepDone > 1 && azgoNewsletterInfo.articleId && azgoNewsletterInfo.articleId !== "") {
      const jsonString = JSON.parse(azgoNewsletterInfo?.products);
      setPromotionTextText(azgoNewsletterInfo.inputText);
      setSelectedProduct(() => [...jsonString]);
      handleSelectCategory(jsonString[0].category, allProductList);
    } else if (azgoNewsletterInfo?.stepDone > 1) {
      setPromotionTextText(azgoNewsletterInfo.inputText);
      setSelectedProduct([...azgoNewsletterInfo?.products]);
      handleSelectCategory(azgoNewsletterInfo?.products[0].category, allProductList);
      setIsDisabledInput(true);
    } else {
      setPromotionTextText("");
      setSelectedProduct([]);
      setIsDisabledInput(false);
      setSelectedCategory("");
      setProductList([]);
    }
  }, [azgoNewsletterInfo])

  const handleSelectCategory = (category, data) => {
    let filteredProducts = [];
    setSelectedCategory(category);
    filteredProducts = data.filter((element) => {
      return element.category === category;
    });
    setProductList(filteredProducts);
  };

  const handleSelectOrDeselect = (product, isCheck) => {
    if (selectedProduct && selectedProduct.includes(product)) {
      const newArray = selectedProduct.filter((item1) => item1 !== product);
      setSelectedProduct([...newArray]);
    } else {
      setSelectedProduct([...selectedProduct, product]);
    }
  };

  const handleGenerateNewsletter = async () => {
    if (azgoNewsletterInfo.stepDone > 1) {
      return dispatch(setAzgoNewsletterStep(2));
    }
    dispatch(
      setAzgoNewsletterInfo({ ...azgoNewsletterInfo, stepDone: 1, inputText: promotionText, products: selectedProduct, generationsLeft: 3 })
    );
    return dispatch(setAzgoNewsletterStep(2));
  };

  return (
    <>
      <div className="selectBlock seoselected d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <b>Select Products</b>
        </div>
      </div>
      <Row className={`eCommerce-blog-wrapper mt-3`}>
        <Col sm="12" lg="3" className="side-menu p-0">
          <React.Fragment>
            <div className="d-flex align-items-center justify-content-between companyBackbutton backButton">
              <label>Category's</label>
            </div>
            <ListGroup className="mb-3">
              {category &&
                category.length > 0 &&
                category.map((category, index) => (
                  <ListGroup.Item
                    action
                    onClick={() => {
                      handleSelectCategory(category, allProductList);
                    }}
                    active={selectedCategory === category}
                    key={index}
                  >
                    {category}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </React.Fragment>
          {selectedProduct && selectedProduct.length > 0 && (
            <div className=" selected-products mt-3">
              <div className="d-flex justify-content-between align-content-center mb-3">
                <label className="m-0">Selected Product</label>
                <button className="btn  p-0 " onClick={() => setSelectedProduct([])} disabled={isDisabledInput}>
                  Clear All
                </button>
              </div>
              <span>
                <b>Hotels</b>
              </span>
              <ol>
                {selectedProduct
                  .filter((product) => product.category === "Hotels")
                  .map((product, index) => (
                    <li className="mb-1" key={index}>
                      {product.name}
                    </li>
                  ))}
              </ol>
              <span>
                <b>Attractions</b>
              </span>
              <ol>
                {selectedProduct
                  .filter((product) => product.category === "Attractions")
                  .map((product, index) => (
                    <li className="mb-1" key={index}>
                      {product.name}
                    </li>
                  ))}
              </ol>
            </div>
          )}
        </Col>
        <Col sm="12" lg="9" className="genrateFields">
          <Row className="ps-4">
            <Col sm="12" md="12" className="addllyFormWrap mb-2 p-0 px-md-2">
              <div className="form-group fields">
                <label>Describe Promotions or Deals</label>
                <textarea
                  type="text"
                  className="addllyForm-control addedOutline w-100 h-auto"
                  placeholder="Insert your text here"
                  rows={2}
                  value={promotionText}
                  onChange={(e) => setPromotionTextText(e.target.value)}
                  disabled={isDisabledInput}
                />
              </div>
              {productList && productList.length > 0 && (<div style={{ fontSize: "14px", fontWeight: "500", color: "#757983", margin: "8px 0px" }}>Select between 1 to 3 Hotels/Attractions</div>)}
            </Col>
            {productList && productList.length > 0
              ? productList.map((product, index) => (
                <Col sm="6" md="6" lg="4" xxl="4" className="pb-3" key={index}>
                  <div
                    className={`image-wrapper ${selectedProduct && selectedProduct.includes(product) ? "active" : ""
                      }`}
                  >
                    <img src={product.image} alt={product.name} />
                    <div className="card-product-info">
                      <p>{product.name}</p>
                    </div>
                    <div className="card-button">
                      <div className="sub-div gap-2">
                        <button
                          type="button"
                          className="btn bg-white text-primary"
                          onClick={() => setViewProduct({ open: true, data: product })}
                          data-tooltip-id={"my-tooltip"}
                          data-tooltip-place="bottom"
                          data-tooltip-content={`View Details`}
                        >
                          <BsEyeFill className="fs-5" />
                        </button>

                        <button
                          type="button"
                          className={`btn bg-white text-primary ${selectedProduct && selectedProduct.includes(product) && "btn-primary"
                            }`}
                          onClick={() =>
                            handleSelectOrDeselect(product, selectedProduct.includes(product) ? false : true)
                          }
                          data-tooltip-id={"my-tooltip"}
                          data-tooltip-place="bottom"
                          data-tooltip-content={
                            selectedProduct.includes(product) ? "Unselect Product" : "Select Product"
                          }
                          disabled={isDisabledInput}
                        >
                          {selectedProduct && selectedProduct.includes(product) ? (
                            <MdOutlineClose className="fs-5" />
                          ) : (
                            <FiCheck className="fs-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              )) : null}
          </Row>
        </Col>
      </Row>
      <div className="d-flex align-items-start justify-content-center  align-items-center flex-row gap-2 bottomButton">
        <div className="d-flex align-items-center gap-2">
          <button
            disabled={selectedProduct && selectedProduct.length > 0 && promotionText.trim() !== "" ? false : true}
            type="button"
            onClick={() => handleGenerateNewsletter()}
            className="addlly-primary"
            style={{ whiteSpace: "nowrap" }}
          >
            <span>
              Start <BsArrowRight />
            </span>
          </button>
        </div>
        {viewProduct && viewProduct.open && (
          <ProductViewModal viewProduct={viewProduct} setViewProduct={setViewProduct} />
        )}
      </div>
    </>
  );
};

export default FirstStepAzgoNewsletter;
