import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import MenuItem from '@mui/material/MenuItem';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { TfiTrash } from "react-icons/tfi";
import { IoEyeOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import Heading from "../../ui/Title";
import ContentForm from "./ContentForm";
import DropdownMenu from "../../ui/Menu";
import TableLoader from "../../ui/TableLoader";
import CustomDataTable from "../../ui/DataTable";
import { AxiosApi } from "../../../utility/axios";
import { setLoader } from "../../../store/reducer";
import Filters from "../../commonComponents/Filters";
import ArticlePreview from "../../commonComponents/ArticlePreview";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../../../utility/common";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../../../Assets/Icons";
import RefundRequestsHistory from "../../commonComponents/RefundRequestsHistory";

const FirstStep = (props) => {
  const {
    historyData, textWrap, setTextWrap, starred, setStarred, setSearchFilter, loading, filters, setFilters, selectedRows, search,
    handleGetArticleByIdAndEdit, setSelectedRows, handleArchiveAll, getArticleAndPostHistoryData, totalCount, setPage, limit, page,
    handleSubmit, onSubmit, register, watch, setValue, errors, onContinue, control, handlePinArticle, handleDelete, handleSort, sortField, userRes,
    getHistoryShortFlowData, isFetching, clearRow, reset
  } = props;

  const dispatch = useDispatch();
  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState({ open: false });
  const [trainedHistoryData, setTrainedHistoryData] = useState([]);

  useEffect(() => {
    if (historyData && historyData.length > 0) {
      const historyTempData = [];
      for (let index = 0; index < historyData.length; index++) {
        const element = historyData[index];
        if (element.is_trained && element.is_trained === 1) {
          historyTempData.push(element);
        }
      }
      setTrainedHistoryData(historyTempData);
    }
  }, [historyData])

  const handleOpenArticle = (id, type) => {
    setArticle(pre => ({ ...pre, id: id, type }));
    setShowOffset(true);
  };

  const handleTrainOrUnTrainArticle = (id, type, trainedData) => {
    if (type === "train" && trainedData && trainedData.length >= 4) {
      toast.error("Training Limit reached, please remove old articles from training to train new article.");
      return false;
    }

    Swal({
      text: `Do you want to ${type} the article for 1-Click Blog? \n\n Currently you have ${trainedData?.length || 0} / 4 article selected for training.`,
      icon: "warning", dangerMode: true, buttons: { cancel: "Cancel", confirm: type === 'train' ? "Use article for training" : "Remove article for training" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          const payload = { type: type, userId: userRes.id, articleType: "1-Click Blog" };
          const response = await AxiosApi.post(`/api/articleHistory/train-or-untrained/${id}`, payload);
          if (response && response.data && response.data.success === 1) {
            dispatch(setLoader(false));
            toast.success(response.data.msg, { id: "Toast-01" });
            getHistoryShortFlowData();
          } else {
            dispatch(setLoader(false));
            toast.error(response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          }
        } catch (error) {
          toast.error(error?.response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          dispatch(setLoader(false));
        }
      }
    });
  };

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2" onClick={() => { handleTableRowClick(row) }}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
              <p>{row.keyword}</p>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={classNames({ 'hover-star': !starred })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => tableDateFormatter(row?.created_at),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "AI_type",
        name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
        selector: (row) => row.AI_type,
        sortable: true,
        sortField: "AI_type",
      },
      {
        id: "status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "refundRequest",
        name: "Refund Request",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => setShowRefundModal({ open: true, id: row?.id })}>
            Refund
          </button>
        ),
      },
      {
        id: "sort",
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row.status !== "pending" && row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleOpenArticle(row.id, row?.type)}><IoEyeOutline /> Preview article</MenuItem>
                )}
                {row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleGetArticleByIdAndEdit(row.id, row.type)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                )}
                {row.is_trained === 0 && row.status === "completed" && (
                  <MenuItem onClick={() => handleTrainOrUnTrainArticle(row?.id, "train", trainedHistoryData)}><MdModelTraining /> Use article for training</MenuItem>
                )}
                {row.is_archived && row.is_archived === 1 ? (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}><TfiTrash /> Delete</MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                )}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [handleDelete, handleGetArticleByIdAndEdit, handlePinArticle, sortField, textWrap, trainedHistoryData]);

  const trainedColumns = useMemo(() => {
    const tempColumns = [...columns];
    const getIndex = tempColumns.findIndex((column) => column.id === "refundRequest");
    if (getIndex !== -1) {
      tempColumns[getIndex] = {
        id: "trainedStatus",
        name: "Trained Status",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => handleTrainOrUnTrainArticle(row?.id, "unTrain", trainedHistoryData)}>
            UnTrain
          </button>
        ),
      };
    }
    return tempColumns;
  }, [columns])

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row.id, row?.type);
    } else if (row.status === "notAcceptable") {
    } else {
      handleGetArticleByIdAndEdit(row.id, row?.type);
    }
  };

  return (
    <React.Fragment>
      <div className="oneClickHead d-flex flex-column gap-3 pt-4">
        <div className="heading">
          <Heading className="fw-bolder mainHeading" size="3">1-Click Blog Writer</Heading>
          <p>Generate human-like, SEO-optimized blogs on any topic with just a single click.</p>
        </div>
      </div>

      <div className="maingenrateBlock bg-white card">
        <ContentForm
          handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} watch={watch}
          setValue={setValue} errors={errors} onContinue={onContinue} control={control} reset={reset}
        />
      </div>
      <div className=" d-flex gap-2 flex-column tableHeading">
        <div className=" d-flex justify-content-between mb-4">
          <span className=" ">Recent History</span>
          <Link to="/history" className="">
            View All
          </Link>
        </div>
        <div className="filters">
          <Filters
            starred={starred} setStarred={setStarred} textWrap={textWrap} setSearchFilter={setSearchFilter}
            setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
            getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
            handleArchiveAll={handleArchiveAll} setPage={setPage} loading={loading} isFetching={isFetching}
          />
        </div>

        <div className="historyTableBlock overflow-x-hidden">
          <CustomDataTable
            progressPending={loading} progressComponent={<TableLoader limit={limit} />}
            columns={columns} totalCount={totalCount} pagination={true} data={historyData}
            itemsPerPage={limit} selectableRows={true} noDataComponent={<NoDataFound search={search} />}
            onSelectedRowsChange={setSelectedRows} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
          />
        </div>
      </div>

      {trainedHistoryData && trainedHistoryData.length > 0 && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className=" d-flex justify-content-between mb-3">
            <span className="">Trained Article History ( {trainedHistoryData?.length || 0} / 4 Articles used for training )</span>
          </div>
          <div className="historyTableBlock">
            <CustomDataTable
              progressPending={loading} progressComponent={<TableLoader limit={limit} />}
              columns={trainedColumns} totalCount={totalCount} pagination={false} data={trainedHistoryData}
              itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound search={search} />}
              onSelectedRowsChange={() => { }} setPage={() => { }} page={1} onSort={() => { }}
              handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
            />
          </div>
        </div>
      )}

      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryShortFlowData}
        handleNavigateArticleOrPostWriter={(articleId, articleType) => handleGetArticleByIdAndEdit(articleId, articleType)}
      />

      {showRefundModal && showRefundModal.open && (
        <Modal className="" size="xl" centered show={showRefundModal.open} onHide={() => setShowRefundModal({ open: false })}>
          <Modal.Body className="maingenrateBlock m-0 p-3">
            <div className="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
              <strong>Refund Credit Request</strong>
              <button className="btn close-btn p-0" onClick={() => setShowRefundModal({ open: false })}><GrClose /></button>
            </div>
            <RefundRequestsHistory articleType={"1-Click Blog"} articleId={showRefundModal.id} />
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default FirstStep;


const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (
          <>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={articleHistoryIcons.add} alt="add"></img>
            </div>
            <p>Articles you create will be shown here</p>
          </>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};
