import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { IoIosWifi } from "react-icons/io";
import { FaSignal } from "react-icons/fa6";
import { RxAvatar } from "react-icons/rx";
import { IoBatteryFull } from "react-icons/io5";
import { BsStars, BsUpload } from "react-icons/bs";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { oneClickBlogIcon } from "../../Assets/Icons";
import GIFLoader from "../../Assets/Gif/8080laoder.gif";

const ImageLoader = () => {
  return (
    <div className="custom-post-loader">
      <img src={GIFLoader} alt="loading..." />
    </div>
  )
}

const LinkedinPostPreview = (props) => {
  const { setUploadFileModal, userRes, text, isUploadImage = false } = props;
  const [previewImg, setPreviewImg] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!props.preview_img) {
      setLoaded(true);
    } else if (props.preview_img && props.preview_img !== "") {
      try {
        const imageData = JSON.parse(props.preview_img);
        if (imageData.type === "url") {
          setPreviewImg(imageData.data);
        } else if (imageData.type === "carousel") {
          setPreviewImg(imageData.data[0]);
          setCarouselImages(imageData.data);
        }
      } catch (error) {
        setPreviewImg(props.preview_img);
      }
    }
  }, [props.preview_img]);

  return (
    <div className="linkedInMediaView text-center position-relative">
      <ReactSVG src={oneClickBlogIcon.mobilescreen} />
      <div className="mediaLayout">
        <div className="topBar d-flex justify-content-between align-items-center ">
          <div className="signalTime d-flex justify-content-between align-items-center">
            <div className="signal position-relative">
              <span>4g</span>
              <FaSignal />
            </div>
            <div className="timeDate">
              <p className="m-0">9:41</p>
            </div>
          </div>
          <div className="chargeing d-flex justify-content-between align-items-center gap-1">
            <IoIosWifi />
            <IoBatteryFull />
          </div>
        </div>
        <div className="searchBar d-flex justify-content-between gap-2">
          <div className="profileIcon">
            {userRes?.profile_url ? (
              <img src={userRes?.profile_url} alt="profile_pic" className="w-100" />
            ) : (
              <div className=" bg-white border-1 solid rounded-pill" id="dropdown-basic">
                <RxAvatar className="" />
              </div>
            )}
          </div>
          <div className="d-flex gap-2 align-items-center searchBarInner position-relative">
            <ReactSVG src={oneClickBlogIcon.searchIconSvg} useRequestCache />
            <span
              style={{
                fontSize: "11.65px",
                color: "#585C60",
                marginBottom: "-4px"
              }}
            >Search</span>
          </div>
          <div className="messageIcon">
            <ReactSVG src={oneClickBlogIcon.msgIconSvg} useRequestCache />
          </div>
        </div>
        <div className="postsBlock">
          <div className="userNameProfile d-flex justify-content-between align-items-center gap-3">
            <div className="userInfo d-flex justify-content-center align-items-center gap-3">
              <div className="profileIcon">
                {userRes?.profile_url ? (
                  <img src={userRes?.profile_url} alt="profile_pic" className="w-100" />
                ) : (
                  <div className=" bg-white border-1 solid rounded-pill" id="dropdown-basic">
                    <RxAvatar />
                  </div>
                )}
                <span className="onlineProfile"></span>
              </div>
              <div className="userName">
                <span>{userRes?.username}</span>
                <p>45 548 followers</p>
              </div>
            </div>
            <HiOutlineDotsVertical />
          </div>
          <div className={classNames("postedText ", { notContent: !text?.length })}>
            <div className="post-desc d-flex align-items-end">
              <pre
                className="m-0" style={isShowFullPost ? { display: "block", paddingRight: "0px" } : {}}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              <span className={classNames("more-btn", { "d-none": !text })} onClick={() => setIsShowFullPost(!isShowFullPost)}>
                {isShowFullPost ? "show less" : "see more"}
              </span>
            </div>
            <div className="genrateAiBlock position-relative">
              {carouselImages && carouselImages.length > 0 ? (
                <div className={`img-wrapper carousel-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}>
                  <div className="slides" id="scroll-div">
                    {carouselImages.map((image, index) => (
                      <div className="" key={index}>
                        <img src={carouselImages[index]} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                      </div>
                    ))}
                  </div>
                  <div className="arrow-button">
                    {carouselIndex !== 0 && (
                      <div className="left-arrow-button" type="button"
                        onClick={() => {
                          setCarouselIndex(carouselIndex - 1);
                          const content = document.querySelector('#scroll-div');
                          content.scrollLeft -= 300;
                        }}
                        onKeyDown={() => { }}
                      >
                        <MdKeyboardArrowLeft className='fs-3' />
                      </div>
                    )}
                    {(carouselIndex + 1) !== carouselImages.length && (
                      <div className="right-arrow-button" type="button"
                        onClick={() => {
                          setCarouselIndex(carouselIndex + 1);
                          const content = document.querySelector('#scroll-div');
                          content.scrollLeft += 300;
                        }} onKeyDown={() => { }}
                      >
                        <MdKeyboardArrowRight className='fs-3' />
                      </div>
                    )}
                  </div>
                </div>
              ) : previewImg ? (
                <img src={previewImg} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} className=" object-fit-cover w-100 h-100 " />
              ) : null}
              {!loaded && previewImg && (<ImageLoader />)}
              {text ? isUploadImage && !previewImg ? (
                <div className="fieldUpload position-relative">
                  <input className="form-control border-0 shadow" type="buttton" id="formFileMultiple" multiple />
                  <button className="blogButton border-0 bg-white" onClick={setUploadFileModal}>
                    <BsStars /> Generate AI Image
                    <div className="dot">
                      <div className="circle"></div>
                    </div>
                  </button>
                </div>
              ) : isUploadImage && previewImg ? (
                <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                  {/* <button type="button" onClick={() => setUploadFileModal(true)} className="image-upload-btn"> */}
                  <BsUpload />
                </button>
              ) : null : null}
            </div>
            <div className="blankImages">
              <ReactSVG src={oneClickBlogIcon.commentShare} />
              <ReactSVG src={oneClickBlogIcon.liked} />
              <div style={{
                width: "100%",
                margin: "5px 0px",
                background: "#E8E5DF",
                height: "4px"
              }}></div>
              <ReactSVG src={oneClickBlogIcon.linkedInMobileLoading} className="notContentImage" />
            </div>
          </div>
        </div>
        <div>
          <ReactSVG src={oneClickBlogIcon.tabMenu} />
        </div>
      </div>
    </div>
  );
};

export default LinkedinPostPreview;
