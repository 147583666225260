import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import classNames from "classnames";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { GrClose } from "react-icons/gr";
import { TfiTrash } from "react-icons/tfi";
import MenuItem from "@mui/material/MenuItem";
import { IoEyeOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DropdownMenu from "../ui/Menu";
import CustomDataTable from "../ui/DataTable";
import TableLoader from "../ui/TableLoader";
import { AxiosApi } from "../../utility/axios";
import { setLoader } from "../../store/reducer";
import SeoBlogWriterHoc from "../../Hoc/SeoBlogWriterHoc";
import Filters from "../commonComponents/Filters";
import ArticlePreview from "../commonComponents/ArticlePreview";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../../utility/common";
import RefundRequestsHistory from "../commonComponents/RefundRequestsHistory";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../../Assets/Icons";

const SocialMediaPostHistory = (props) => {
  const {
    historyData, textWrap, setTextWrap, starred, setStarred, setSearch, loading, filters, setFilters, selectedRows, clearRow,
    handleGetPostDetailsById, setSelectedRows, handleArchiveAll, getArticleAndPostHistoryData, totalCount, setPage, limit, page,
    handlePinArticle, handleDelete, handleSort, sortField, userRes, getHistoryShortFlowData, isFetching, search
  } = props;

  const dispatch = useDispatch();
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const socialMediaPostStep = useSelector(({ socialMediaPostStep }) => socialMediaPostStep);
  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState({ open: false });
  const [trainedHistoryData, setTrainedHistoryData] = useState([]);

  useEffect(() => {
    if (historyData && historyData.length > 0) {
      const historyTempData = [];
      for (let index = 0; index < historyData.length; index++) {
        const element = historyData[index];
        if (element.is_trained && element.is_trained === 1) {
          historyTempData.push(element);
        }
      }
      setTrainedHistoryData(historyTempData);
    }
  }, [historyData])

  useEffect(() => {
    if (socialMediaPostInfo && socialMediaPostInfo.PostType && socialMediaPostStep === 2) {
      getHistoryShortFlowData();
    }
  }, [socialMediaPostStep])

  const handleOpenArticle = (id, type) => {
    setArticle(pre => ({ ...pre, id: id, type }));
    setShowOffset(true);
  };

  const handleTrainOrUnTrainArticle = (id, type, postType, trainedData) => {
    let trainedPostLength = 0;
    if (trainedData && trainedData.length > 0) {
      trainedPostLength = trainedData.filter((item) => item.post_type === postType).length;
    }

    if (type === "train" && trainedPostLength >= 4) {
      toast.error("Training Limit reached, please remove old posts from training to train new post.");
      return false;
    }

    Swal({
      text: `Do you want to ${type} this post for ${postType}? \n\n Currently you have ${trainedPostLength || 0} / 4 posts selected for training.`,
      icon: "warning", dangerMode: true, buttons: { cancel: "Cancel", confirm: type === 'train' ? "Use post for training" : "Remove post for training" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          const payload = { type: type, userId: userRes.id, articleType: "Social Media Post Writer" };
          const response = await AxiosApi.post(`/api/articleHistory/train-or-untrained/${id}`, payload);
          if (response && response.data && response.data.success === 1) {
            dispatch(setLoader(false));
            toast.success(response.data.msg, { id: "Toast-01" });
            getHistoryShortFlowData();
          } else {
            dispatch(setLoader(false));
            toast.error(response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          }
        } catch (error) {
          toast.error(error?.response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          dispatch(setLoader(false));
        }
      }
    });
  };

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2" onClick={() => handleTableRowClick(row)}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
              <p>{row.keyword}</p>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={classNames({ 'hover-star': !starred })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortField: "topic",
        sortable: true,
      },
      {
        id: "Post Type",
        name: <div className="d-flex">Post Type {SortArrow(sortField, "Post Type")}</div>,
        selector: (row) => <div style={{ whiteSpace: "nowrap" }} onClick={() => handleTableRowClick(row)}>{row.post_type}</div>,
        sortable: true,
        sortField: "post_type",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => <div onClick={() => handleTableRowClick(row)}>{tableDateFormatter(row?.created_at)}</div>,
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "AI_type",
        name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
        selector: (row) => row.AI_type,
        sortable: true,
        sortField: "AI_type",
      },
      {
        id: "status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "refundRequest",
        name: "Refund Request",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => setShowRefundModal({ open: true, id: row?.id })}>
            Refund
          </button>
        ),
      },
      {
        id: "sort",
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row.status !== "pending" && row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleOpenArticle(row.id, row?.type)}><IoEyeOutline /> Preview article</MenuItem>
                )}
                {row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleGetPostDetailsById(row.id, true)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                )}
                {row.is_trained === 0 && row.status === "completed" && (
                  <MenuItem onClick={() => handleTrainOrUnTrainArticle(row?.id, "train", row?.post_type, trainedHistoryData)}><MdModelTraining /> Use post for training</MenuItem>
                )}
                {row.is_archived && row.is_archived === 1 ? (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}><TfiTrash /> Delete</MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                )}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(2, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [handleDelete, handleGetPostDetailsById, handlePinArticle, sortField, starred, textWrap, trainedHistoryData])

  const trainedColumns = useMemo(() => {
    const tempColumns = [...columns];
    const getIndex = tempColumns.findIndex((column) => column.id === "refundRequest");
    if (getIndex !== -1) {
      tempColumns[getIndex] = {
        id: "trainedStatus",
        name: "Trained Status",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => handleTrainOrUnTrainArticle(row?.id, "unTrain", row?.post_type, trainedHistoryData)}>
            UnTrain
          </button>
        ),
      };
    }
    return tempColumns;
  }, [columns])

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row.id, row?.type);
    } else if (row.status === "notAcceptable") {
    } else {
      handleGetPostDetailsById(row.id, true);
    }
  };

  const handleCheckTrainArticleLengthPostType = () => {
    const postType = socialMediaPostInfo?.PostType;
    const trainedPostLength = trainedHistoryData.filter((item) => item.post_type === postType).length;
    return trainedPostLength || "0";
  }

  return (
    <React.Fragment>
      <div className=" d-flex gap-2 flex-column tableHeading">
        <div className=" d-flex justify-content-between mb-4">
          <span className="">Recent History</span>
          <Link to="/history" className="">View All</Link>
        </div>
        <div className="filters">
          <Filters
            starred={starred} setStarred={setStarred} textWrap={textWrap} setSearchFilter={setSearch}
            setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
            getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
            handleArchiveAll={handleArchiveAll} setPage={setPage} isFetching={isFetching}
          />
        </div>

        <div className="historyTableBlock">
          <CustomDataTable
            progressPending={loading} progressComponent={<TableLoader limit={limit} />}
            columns={columns} totalCount={totalCount} pagination={true} data={historyData}
            itemsPerPage={limit} selectableRows={true} noDataComponent={<NoDataFound search={search} />}
            onSelectedRowsChange={setSelectedRows} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
          />
        </div>
      </div>

      {trainedHistoryData && trainedHistoryData.length > 0 && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className=" d-flex justify-content-between mb-3">
            {socialMediaPostStep === 1 ? (
              <span className="">Trained Post History ( Select Max 4 each social type )</span>
            ) : (
              <span>Trained Post History ( {handleCheckTrainArticleLengthPostType()} / 4 Posts used for training )</span>
            )}
          </div>
          <div className="historyTableBlock">
            <CustomDataTable
              progressPending={loading} progressComponent={<TableLoader limit={limit} />}
              columns={trainedColumns} totalCount={totalCount} pagination={false} data={trainedHistoryData}
              itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound search={search} />}
              onSelectedRowsChange={() => { }} setPage={() => { }} page={1} onSort={() => { }}
              handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
            />
          </div>
        </div>
      )}

      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryShortFlowData}
        handleNavigateArticleOrPostWriter={(articleId, articleType) => handleGetPostDetailsById(articleId, articleType)}
      />

      {showRefundModal && showRefundModal.open && (
        <Modal className="" size="xl" centered show={showRefundModal.open} onHide={() => setShowRefundModal({ open: false })}>
          <Modal.Body className="maingenrateBlock m-0 p-3">
            <div className="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
              <strong>Refund Credit Request</strong>
              <button className="btn close-btn p-0" onClick={() => setShowRefundModal({ open: false })}><GrClose /></button>
            </div>
            <RefundRequestsHistory articleType={"Social Media Post Writer"} articleId={showRefundModal.id} />
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default SeoBlogWriterHoc(SocialMediaPostHistory);

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (
          <>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={articleHistoryIcons.add} alt="add"></img>
            </div>
            <p>Articles you create will be shown here</p>
          </>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};