import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GrClose } from "react-icons/gr";
import { FiCheck } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsArrowRight, BsEyeFill } from "react-icons/bs";
import { FaArrowRightLong, FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { setAzgoNewsletterInfo, setAzgoNewsletterStep, setLoader, setUserRes } from "../../store/reducer";

const EdmSampleTemplates = [
  {
    id: 3,
    name: "template_3",
    image: "/AzgoTemplates/2.png"
  },
  {
    id: 2,
    name: "template_2",
    image: "/AzgoTemplates/1.png"
  },
  {
    id: 4,
    name: "template_4",
    image: "/AzgoTemplates/4.png"
  }
];

const textArray = ["Select for 1 Hotel/Attraction", "Select for 2 Hotels/Attractions", "Select for 3 Hotels/Attractions"];
const SecondStepAzgoNewsletter = () => {
  const navigate = useNavigate();
  const azgoNewsletterInfo = useSelector(({ azgoNewsletterInfo }) => azgoNewsletterInfo);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0 });
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const userRes = useSelector(({ userRes }) => userRes);

  const handleOnChangeViewImage = (index) => {
    if (EdmSampleTemplates && EdmSampleTemplates.length > 0) {
      if (index !== -1 && EdmSampleTemplates.length >= index) {
        if (EdmSampleTemplates[index] && EdmSampleTemplates[index] !== "" && EdmSampleTemplates[index].image) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: EdmSampleTemplates[index].image, index }));
        } else {
          setViewImgModal((prev) => ({ ...prev, open: true, img: EdmSampleTemplates[index], index }));
        }
      }
    }
  };

  useEffect(() => {
    if (azgoNewsletterInfo && azgoNewsletterInfo?.template && azgoNewsletterInfo?.template !== "") {
      setSelectedTemplate(azgoNewsletterInfo?.template);
      setIsDisabled(true);
    }
  }, [])

  const handleNextStep = async (templateName) => {
    if (azgoNewsletterInfo.stepDone > 2) {
      return dispatch(setAzgoNewsletterStep(3));
    }
    if (isDisabled) {
      return false;
    }
    if (Number(userRes.credits) < 3) {
      toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
      return false;
    }

    if (templateName && templateName !== "") {
      setViewImgModal({ open: false, img: "", index: 0 });
      const payload = {
        body: JSON.stringify({
          selectedProducts: azgoNewsletterInfo.products,
          template: templateName,
          OfferDescription: azgoNewsletterInfo.inputText,
          user_id: userRes.id
        })
      }
      try {
        dispatch(setLoader(true));
        const res = await invokeLambdaFunction("generateAzgoNewsletter", payload);
        if (res && res.success === 1 && res.data) {
          const dataString = res.data.article;
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - 3 }));
          dispatch(setAzgoNewsletterInfo({ ...azgoNewsletterInfo, resposeHTML: dataString, template: templateName, stepDone: 3, articleId: res.data.articleId }));
          dispatch(setAzgoNewsletterStep(3));
          dispatch(setLoader(false));
        } else {
          toast.error("Please try again");
          dispatch(setLoader(false));
        }
      } catch (error) {
        dispatch(setLoader(false));
      }
    } else {
      toast.error("Please select a template for generate Newsletter.", { id: "Toast-01" });
    }
  };

  const handleSelectPreviewTemplate = (index) => {
    const tempObj = EdmSampleTemplates[index];
    if (tempObj.name && tempObj.name !== "") {
      setSelectedTemplate(tempObj.name);
      handleNextStep(tempObj.name);
    }
  };

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div><b>Select Template</b> <span>(Select any 1)</span></div>
      </div>
      <div className="news-letters-templates">
        <div className="template-wrapper align-items-start">
          {EdmSampleTemplates && EdmSampleTemplates.length > 0 && EdmSampleTemplates.map((template, index) => (
            <div sm={12} md={4} lg={3} className={`image-main-wrapper ${selectedTemplate === template.name ? "active" : ""}`} key={index}>
              <div className="template-header">
                <div style={{ fontSize: "14px", fontWeight: "500", color: "#757983", margin: "8px 0px" }}>{textArray[index]}</div>
                <div className={`radioBtn`} onClick={() => { !isDisabled && setSelectedTemplate(template.name) }}>
                  <div className={`radioBtnIn`}></div>
                </div>
                {template.tag && template.tag !== "" && (
                  <div className="template-tags">{template.tag}</div>
                )}
              </div>
              <div className="image-content">
                <img src={template.image} alt={template.name} />
                <div className="img-btn-wrapper">
                  <div className="btn-sub-div gap-2">
                    <button
                      type="button" className="btn bg-white text-primary"
                      onClick={(e) => setViewImgModal({ open: true, img: template.image, index: index })}
                      data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Template`}
                    >
                      <BsEyeFill className='fs-5' />
                    </button>
                    <button
                      type="button" className={`btn bg-white text-primary ${selectedTemplate === template.name && "btn-primary"}`}
                      onClick={() => setSelectedTemplate(selectedTemplate !== template.name ? template.name : "")}
                      data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Select Template`}
                      disabled={isDisabled}
                    >
                      {selectedTemplate && selectedTemplate === template.name ? (
                        <MdOutlineClose className="fs-5" />
                      ) : (
                        <FiCheck className="fs-5" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="buttonDiv">
        <div className="d-flex justify-content-end align-items-center gap-2"></div>
        <Button
          className="button"
          onClick={() => handleNextStep(selectedTemplate)}
          disabled={!selectedTemplate || selectedTemplate === ""}
        >
          Generate Newsletter <FaArrowRightLong className="ms-2" />
        </Button>
        <BsInfoCircleFill
          className="text-decoration-none cursor-pointer outline-0 infoIconSvg" data-tooltip-id="my-tooltip" data-tooltip-place="right"
          data-tooltip-content={`Will utilize 3 Addlly credits`}
        />
      </div>
      {viewImgModal && viewImgModal.open && (
        <Modal centered fullscreen className="image-history-modal" backdrop="static"
          show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}
        >
          <Modal.Body className="">
            <div className="image-content">
              <button className="btn arrow-btn"
                onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}
                disabled={viewImgModal.index !== 0 ? false : true}
              >
                <FaCaretLeft className="" />
              </button>
              <div className="image-view-wrapper">
                <img src={viewImgModal.img} alt="" style={{ maxHeight: "75vh" }} />
              </div>
              <button className="btn arrow-btn"
                onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}
                disabled={viewImgModal.index !== (EdmSampleTemplates.length - 1) ? false : true}
              >
                <FaCaretRight className="" />
              </button>
              <button className="addlly-primary template-btn"
                onClick={() => handleSelectPreviewTemplate(viewImgModal.index)}
                disabled={isDisabled}
              >
                Use this template <BsArrowRight className="" />
              </button>
              <label className="img-label"><span className="activeSliderCount">0{viewImgModal.index + 1}</span> / 0{EdmSampleTemplates.length}</label>
            </div>
            <button className="btn close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
              <GrClose className="fs-4" />
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default SecondStepAzgoNewsletter;
