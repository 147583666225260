import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { MdOutlineClose } from "react-icons/md";
import { BsArrowRight, BsEyeFill } from "react-icons/bs";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { setECommerceBlogInfo, setLoader } from "../../store/reducer";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import "grapesjs/dist/css/grapes.min.css";

const SampleTemplates = [
  {
    id: 1,
    name: "template_1",
    image: "/NewsLetterTemplates/edm-template-1.jpg"
  },
  {
    id: 2,
    name: "template_2",
    image: "/NewsLetterTemplates/edm-template-2.jpg"
  },
  {
    id: 3,
    name: "template_3",
    image: "/NewsLetterTemplates/edm-template-3.jpg"
  },
  {
    id: 4,
    name: "template_4",
    image: "/NewsLetterTemplates/edm-template-4.jpg"
  },
];

const EComProductTemplate = ({ setTemplateEditor, isTemplateGenerated, setIsTemplateGenerated }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const userRes = useSelector(({ userRes }) => userRes);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0 });

  useEffect(() => {
    if (eCommerceBlogInfo?.templateHtml && eCommerceBlogInfo.templateHtml !== "") {
      handleSetHtmlTextIntoEditor(eCommerceBlogInfo.templateHtml);
    }
  }, [])

  const handleSetHtmlTextIntoEditor = (htmlText) => {
    setIsTemplateGenerated(true); window.scrollTo(0, 0);
    const doc = new DOMParser().parseFromString(htmlText, "text/html");
    const styleElement = doc.querySelectorAll("style")[0];
    const body = doc.body;
    const eComProductImages = eCommerceBlogInfo?.selectedProductImages ? eCommerceBlogInfo.selectedProductImages : [];

    const templateEditor = grapesjs.init({
      container: editorRef.current,
      storageManager: false,
      showDevices: false,
      plugins: ["gjs-blocks-basic", "gjs-preset-webpage", "gjs-plugin-fonts"],
      components: body.outerHTML,
      style: styleElement.innerHTML,
      blockManager: {
        blocks: [
          {
            id: "textBlock",
            label: "Text",
            media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z"></path></svg>`,
            activate: true,
            content: { type: "text", content: "Insert text here!" }
          },
          {
            id: "imgBlock",
            label: "Image",
            activate: true,
            media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z"></path></svg>`,
            content: { type: "image" }
          }
        ]
      },
      assetManager: {
        assets: [...eComProductImages],
      }
    });

    setTemplateEditor(templateEditor);

    setTimeout(() => {
      const clickableDiv = document.querySelectorAll(".gjs-field-color-picker");
      clickableDiv.forEach(div1 => {
        div1.addEventListener("click", () => {
          const getColorDiv = document.querySelectorAll(".sp-container.sp-light.sp-input-disabled.sp-alpha-enabled.sp-palette-buttons-disabled.sp-initial-disabled.gjs-one-bg.gjs-two-color");
          getColorDiv.forEach(div2 => {
            if (div2.className && div2.className.indexOf("sp-hidden") === -1) {
              if (div2.style.left) {
                console.log('#### div2.style.left --->', div2.style.left);
                const leftStyle = Number(div2.style.left.replace("px", ""));
                div2.style.left = (leftStyle - 178) + "px";
              }
            }
          });
        });
      });
    }, 2000);
  };

  const handleNextStep = async (templateName) => {
    if (templateName && templateName !== "") {
      try {
        setViewImgModal({ open: false, img: "", index: 0 });
        if (Number(userRes.credits) <= 0) {
          toast.error("You have used all your addlly credits.", { id: "Toast-01" });
          return false;
        }

        if (eCommerceBlogInfo?.templateHtml && eCommerceBlogInfo.templateHtml !== "") {
          return false;
        }

        dispatch(setLoader(true));
        const payload = {
          body: JSON.stringify({
            type: "generate-edm-article", template: templateName || "",
            articleId: eCommerceBlogInfo?.articleId || 0,
          })
        };

        const response = await invokeLambdaFunction("generateArticleOrSaveByType", payload);
        dispatch(setLoader(false));
        if (response && response.success === 1 && response.data && response.data.content) {
          dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, templateHtml: response.data.content }));
          handleSetHtmlTextIntoEditor(response.data.content);
        } else {
          toast.error("Product template not generated, Please try aging.", { id: "Toast-01" });
        }
      } catch (error) {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
        dispatch(setLoader(false));
      }
    } else {
      toast.error("Please any template for generate EDM.", { id: "Toast-01" });
    }
  };

  const handleSelectPreviewTemplate = (index) => {
    const tempObj = SampleTemplates[index];
    if (tempObj.name && tempObj.name !== "") {
      setSelectedTemplate(tempObj.name);
      handleNextStep(tempObj.name);
    }
  };

  const handleOnChangeViewImage = (index) => {
    if (SampleTemplates && SampleTemplates.length > 0) {
      if (index !== -1 && SampleTemplates.length >= index) {
        if (SampleTemplates[index] && SampleTemplates[index] !== "" && SampleTemplates[index].image) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: SampleTemplates[index].image, index }));
        } else {
          setViewImgModal((prev) => ({ ...prev, open: true, img: SampleTemplates[index], index }));
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div ref={editorRef}></div>
      {!isTemplateGenerated && (
        <div className="addllyFormWrap">
          <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-0 mw-100">
            <div><b>Select Template</b> <span>(Select any 1)</span></div>
            <button
              type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
              onClick={() => handleNextStep(selectedTemplate)} disabled={!selectedTemplate || selectedTemplate === ""}
            >
              Generate Template <BsArrowRight />
            </button>
          </div>
          <div className="news-letters-templates px-0">
            <div className="template-wrapper align-items-start">
              {SampleTemplates && SampleTemplates.length > 0 && SampleTemplates.map((template, index) => (
                <div sm={12} md={4} lg={3} className={`image-main-wrapper ${selectedTemplate === template.name ? "active" : ""}`} key={index}>
                  <div className="template-header">
                    <div className={`radioBtn`} onClick={() => setSelectedTemplate(template.name)}>
                      <div className={`radioBtnIn`}></div>
                    </div>
                    {template.tag && template.tag !== "" && (
                      <div className="template-tags">{template.tag}</div>
                    )}
                  </div>
                  <div className="image-content">
                    <img src={template.image} alt={template.name} />
                    <div className="img-btn-wrapper">
                      <div className="btn-sub-div gap-2">
                        <button
                          type="button" className="btn bg-white text-primary"
                          onClick={(e) => setViewImgModal({ open: true, img: template.image, index: index })}
                          data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`View Template`}
                        >
                          <BsEyeFill className='fs-5' />
                        </button>
                        <button
                          type="button" className={`btn bg-white text-primary ${selectedTemplate === template.name && "btn-primary"}`}
                          onClick={() => setSelectedTemplate(selectedTemplate !== template.name ? template.name : "")}
                          data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Select Template`}
                        >
                          {selectedTemplate && selectedTemplate === template.name ? (
                            <MdOutlineClose className="fs-5" />
                          ) : (
                            <FiCheck className="fs-5" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {viewImgModal && viewImgModal.open && (
            <Modal centered fullscreen className="image-history-modal" backdrop="static" show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}>
              <Modal.Body className="">
                <div className="image-content">
                  <button className="btn arrow-btn"
                    onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}
                    disabled={viewImgModal.index !== 0 ? false : true}
                  >
                    <FaCaretLeft className="" />
                  </button>
                  <div className="image-view-wrapper">
                    <img src={viewImgModal.img} alt="" style={{ maxHeight: "75vh" }} />
                  </div>
                  <button className="btn arrow-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}
                    disabled={viewImgModal.index !== (SampleTemplates.length - 1) ? false : true}
                  >
                    <FaCaretRight className="" />
                  </button>
                  <button className="addlly-primary template-btn" onClick={() => handleSelectPreviewTemplate(viewImgModal.index)}>
                    Use this template <BsArrowRight className="" />
                  </button>
                  <label className="img-label"><span className="activeSliderCount">0{viewImgModal.index + 1}</span> / 0{SampleTemplates.length}</label>
                </div>
                <button className="btn close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
                  <GrClose className="fs-4" />
                </button>
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default EComProductTemplate;
