import React from "react";
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { styled, alpha } from '@mui/material/styles';
import { OonaProductTreeList } from "../../../utility/hepler";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const ExpandIcon = (props) => {
  return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

const CollapseIcon = (props) => {
  return <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

const EndIcon = (props) => {
  return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
}

const ProductTreeView = ({ handleItemClick }) => {
  return (
    <Box sx={{ minHeight: "100%", minWidth: "100%", mr: 0 }}>
      <SimpleTreeView className="product-tree-list" aria-label="customized" slots={{ expandIcon: ExpandIcon, collapseIcon: CollapseIcon }}>
        {OonaProductTreeList.map((item) => (
          <CustomTreeItem key={item.id} itemId={item.label} label={item.label} onClick={() => handleItemClick(item.label)}>
            {item.children.map((child) => (
              <CustomTreeItem key={child.id} itemId={child.label} label={child.label} onClick={() => handleItemClick(child.label)}>
                {child.children.length !== 0 && child.children.map((subChild) => (
                  <CustomTreeItem key={subChild.id} itemId={subChild.label} label={subChild.label} onClick={() => handleItemClick(subChild.label)} />
                ))}
              </CustomTreeItem>
            ))}
          </CustomTreeItem>
        ))}
      </SimpleTreeView>
    </Box>
  )
}

export default ProductTreeView;
