import AWS from 'aws-sdk';
import { AxiosApi } from './axios';

const options = {
    maxRetries: 1,
    httpOptions: {
        timeout: 480000
    }
};

AWS.config.update({
    region: 'ap-southeast-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    }),
});

export const invokeLambdaFunction = async (functionName, data) => {
    try {
        const lambda = new AWS.Lambda(options);

        const payload = {
            headers: {
                'Authorization': localStorage.getItem('token') || '',
            },
            ...data,
        };

        const params = {
            FunctionName: `${process.env.REACT_APP_LAMDA_FUNCTION}-${functionName}`,
            Payload: JSON.stringify(payload),
        };
        let response = await lambda.invoke(params).promise();

        if (response && response.Payload && JSON.parse(response.Payload) && JSON.parse(response.Payload).body) {
            response = JSON.parse(response.Payload);
            const response1 = JSON.parse(response.body);
            if (response1 && response.statusCode === 200) {
                return { success: 1, data: response1 };
            } else if (response1 && response.statusCode === 401) {
                try {
                    const userId = localStorage.getItem('persist:root') ? JSON.parse(JSON.parse(localStorage.getItem('persist:root')).userRes).id : 0;
                    AxiosApi.post(`/user/login`, { id: userId, isLogOut: true });
                } catch (error) {
                }
                localStorage.removeItem("persist:root");
                localStorage.clear();
                setTimeout(() => {
                    window.location.href = '/';
                }, 600);
                return { success: 0, error: response1.error };
            } else {
                let errorMsg = response1.error; // "Server is overloaded, try again after sometime.";
                if (response1?.error?.indexOf("content policy") !== -1) {
                    errorMsg = response1.error;
                }
                return { success: 0, error: errorMsg };
            }
        } else {
            return { success: 0, error: "Server is overloaded, try again after sometime." };
        }
    } catch (error) {
        console.log('Error: ', error);
        return { success: 0, error: "Server is overloaded, try again after sometime." };
    }
};
