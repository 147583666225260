import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-hot-toast";
import copy from "copy-to-clipboard";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { Tooltip } from "react-tooltip";
import { BiChip, BiMoviePlay } from "react-icons/bi";
import * as htmlToImage from 'html-to-image';
import { Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { IoIosList } from "react-icons/io";
import { GoArrowRight, GoProjectTemplate } from "react-icons/go";
import { FaBookReader, FaLinkedin, FaRegSave } from "react-icons/fa";
import { SlSocialInstagram, SlSocialLinkedin } from "react-icons/sl";
import { BsCardHeading, BsDownload, BsImages, BsInfoCircleFill, BsStars, BsTwitterX } from "react-icons/bs";

import { AxiosApi } from "../../utility/axios";
import HtmlViewer from "../SeoBlogWriter/HtmlViewer";
import EComProductTemplate from "../ECommerceBlogWriter/EComProductTemplate";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { copyIcon, oneClickBlogIcon, sideBarIcons } from "../../Assets/Icons";
import VersionHistory from "../commonComponents/VersionHistory";
import LinkedinArticle from "../commonComponents/LinkedinArticle";
import FacebookArticle from "../commonComponents/FacebookArticle";
import TwitterArticle from "../commonComponents/TwitterArticle";
import InstagramArticle from "../commonComponents/InstagramArticle";
import SeoScorePreview from "../commonComponents/SeoScorePreview";
import AiOverviewTabComponent from "../commonComponents/AiOverviewTabComponent";
import MediaLibraryDrawer from "../commonComponents/MediaLibrary/MediaLibraryDrawer";
import { EditorInitObj, addEditorCustomButtons, handleGetOrGenerateAllTypeImages } from "../../utility/hepler";
import { setLoader, setECommerceBlogInfo, setECommerceBlogStep, setUserRes } from "../../store/reducer";
import { handleShareSocialMediaTypePost } from "../../utility/common";
import EdmMediaLibraryDrawer from "../ECommerceEdmWriter/EdmMediaLibraryDrawer";
import DropdownMenu from "../ui/Dropdown";
import useScreenSize from "../../hooks/useScreenSize";
const beautify_html = require("js-beautify").html;

const sidebarMenuOptions = [
  { name: "Article", value: "article", subTab: "seoScore", icon: <BsCardHeading /> },
  { name: "LinkedIn Post", value: "linkedIn", subTab: "postPreview", icon: <SlSocialLinkedin /> },
  { name: "Facebook Post", value: "facebook", subTab: "postPreview", icon: oneClickBlogIcon.facebookT },
  { name: "X (Twitter) Post", value: "twitter", subTab: "postPreview", icon: <BsTwitterX /> },
  { name: "Instagram Post", value: "instagram", subTab: "postPreview", icon: <SlSocialInstagram /> },
  { name: "Newsletter", value: "newsletter", subTab: "postPreview", icon: sideBarIcons.newspaper },
  { name: "AI Overview", value: "aiOverview", subTab: "", icon: <BiChip /> },
  { name: "Product Template", value: "productTemplate", subTab: "", icon: <GoProjectTemplate /> },
];

const FourthStepElixirEComBlog = () => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const screenSize = useScreenSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "article";
  const [currentTab, setCurrentTab] = useState(selectedTab);

  const userRes = useSelector(({ userRes }) => userRes);
  const eCommerceBlogInfo = useSelector(({ eCommerceBlogInfo }) => eCommerceBlogInfo);
  const [articleHtml, setArticleHtml] = useState(eCommerceBlogInfo?.article || "");
  const [newsletterHtml, setNewsletterHtml] = useState(eCommerceBlogInfo?.newsletter || "");
  const [toggle, setToggle] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [editData, setEditData] = useState(eCommerceBlogInfo);
  const [contentScore, setContentScore] = useState({});
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [showFeedbackModel, setShowFeedbackModel] = useState({ open: false, model: eCommerceBlogInfo?.AI_type });
  const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "AI Generated Image" });
  const [postGeneratedImages, setPostGeneratedImages] = useState({});
  const [htmlText, setHtmlText] = useState("");
  const [blogImagesModal, setBlogImagesModal] = useState({ open: false, type: "", currentTab: "Product Images" });
  const [seoScoreMobile, setSeoScoreMobile] = useState(window.innerWidth <= 1024);
  const [show, setShow] = useState(false);
  const [toggleRightSideBar, setToggleRightSideBar] = useState(false);
  const [isTemplateGenerated, setIsTemplateGenerated] = useState(false);
  const [templateEditor, setTemplateEditor] = useState(null);
  const articleType = "E-Commerce Blog";

  useEffect(() => {
    if (eCommerceBlogInfo && eCommerceBlogInfo.article && eCommerceBlogInfo.article !== "") {
      setHtmlText(eCommerceBlogInfo?.content || eCommerceBlogInfo?.article_html || "");
      setArticleHtml(eCommerceBlogInfo.article);
      setNewsletterHtml(eCommerceBlogInfo.newsletter);
      setEditData(eCommerceBlogInfo);
      setTimeout(() => setEditorKey(editorKey + 2), 1000);

      if (eCommerceBlogInfo?.id && eCommerceBlogInfo?.SEOScoreResponse && eCommerceBlogInfo.SEOScoreResponse !== "") {
        setContentScore(eCommerceBlogInfo.SEOScoreResponse?.data || eCommerceBlogInfo.SEOScore);
      } else if (eCommerceBlogInfo?.id && eCommerceBlogInfo?.language === "English") {
        handleGenerateOrGetSeoScore();
      }
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setShow(false);
      setToggleRightSideBar(window.innerWidth <= 1024);
      setSeoScoreMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);


  useEffect(() => {
    if (selectedTab) {
      setCurrentTab(selectedTab);
      setToggleRightSideBar(false);
      setSelectedVersionItem({});
      getArticleAllVersionHistory();
      setEditorKey(editorKey + 1);
    };
  }, [selectedTab])

  const handleCopy = (data) => {
    toast.success("Text has been copied to clipboard.");
    copy(data);
  };

  const getArticleAllVersionHistory = async () => {
    try {
      let versionSubType = "";
      if (selectedTab === "article") {
        versionSubType = "E-Commerce Blog Article";
      } else if (selectedTab === "linkedIn") {
        versionSubType = "LinkedIn Post";
      } else if (selectedTab === "facebook") {
        versionSubType = "Facebook Post";
      } else if (selectedTab === "twitter") {
        versionSubType = "Twitter Post";
      } else if (selectedTab === "instagram") {
        versionSubType = "Instagram Post";
      } else if (selectedTab === "newsletter") {
        versionSubType = "E-Commerce Newsletter";
      } else if (selectedTab === "productTemplate") {
        versionSubType = "E-Commerce Template";
      }

      if (!eCommerceBlogInfo?.articleId || !versionSubType) {
        return false;
      }

      setVersionHistory([]);
      const params = `articleId=${eCommerceBlogInfo?.articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleGenerateOrGetSeoScore = async () => {
    const payload = {
      pathParameters: { id: eCommerceBlogInfo?.id },
      queryStringParameters: { articleType: "EComBlog" },
    };
    const response = await invokeLambdaFunction("getSEOScore", payload);
    if (response && response.success === 1) {
      setContentScore(response.data.data);
    }
  }

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    if (fieldName === "article_html") {
      dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, article: text }));
      setArticleHtml(text); setEditorKey(editorKey + 1);
    } else if (fieldName === "newsletter_html") {
      dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, newsletter: text }));
      setNewsletterHtml(text); setEditorKey(editorKey + 1);
    } else {
      setEditData((pre) => ({ ...pre, [fieldName]: text }));
      dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, [fieldName]: text }));
    }
    getArticleAllVersionHistory();
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const handleSaveFinalArticle = async () => {
    let templateFinalHtml = eCommerceBlogInfo.templateHtml;
    if (templateEditor && templateEditor.getHtml() && templateEditor.getHtml() !== "") {
      templateFinalHtml = templateFinalHtml.replace(/<body[^>]*>[\s\S]*<\/body>/gi, templateEditor.getHtml());
      templateFinalHtml = templateFinalHtml.replace(/<style>[\s\S]*<\/style>/i, `<style>${templateEditor.getCss()}</style>`);
    }

    const Obj = {
      article_html: articleHtml, template_html: templateFinalHtml, newsletter_html: newsletterHtml,
      linkedIn_post: editData.linkedIn_post, facebook_post: editData.facebook_post,
      twitter_post: editData.twitter_post, instagram_post: editData.instagram_post,
    };

    if (eCommerceBlogInfo.article && eCommerceBlogInfo.article !== "" && /<html[^>]*>/.test(eCommerceBlogInfo.article)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(articleHtml, 'text/html').body.outerHTML;
      Obj.article_html = `${eCommerceBlogInfo.article.substring(0, eCommerceBlogInfo.article.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
    }

    if (eCommerceBlogInfo.newsletter && eCommerceBlogInfo.newsletter !== "" && /<html[^>]*>/.test(eCommerceBlogInfo.newsletter)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(newsletterHtml, 'text/html').body.outerHTML;
      Obj.newsletter_html = `${eCommerceBlogInfo.newsletter.substring(0, eCommerceBlogInfo.newsletter.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
    }

    dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...Obj, article: Obj.article_html, newsletter: Obj.newsletter_html }));
    setArticleHtml(Obj.article_html || ""); setNewsletterHtml(Obj.newsletter_html || ""); setEditorKey(editorKey + 1);

    try {
      dispatch(setLoader(true));
      await AxiosApi.post(`/api/articleHistory/update?tableName=catalog_product_articles`, {
        id: eCommerceBlogInfo?.articleId, currentField: selectedTab, ...Obj
      });
      dispatch(setLoader(false));
      setSelectedVersionItem({});
      getArticleAllVersionHistory();
    } catch (error) {
      dispatch(setLoader(false));
    };
  };

  const handleGenerateArticleForAiModel = async (modelObj) => {
    try {
      if (!eCommerceBlogInfo?.articleRegenerateLeft || eCommerceBlogInfo?.articleRegenerateLeft === 0) {
        toast.error("You can regenerate article only three times.");
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        pathParameters: {
          articleId: eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "",
        },
        body: JSON.stringify({
          subtype: "generate-article", aiTypeOption: eCommerceBlogInfo?.AI_type,
          feedback: modelObj.feedback, type: "generate-elixir-update-blog",
        }),
      };
      const response = await invokeLambdaFunction("generateElixirEComBLogByType", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.article) {
        setShowFeedbackModel({ open: false, model: eCommerceBlogInfo?.AI_type });
        dispatch(setECommerceBlogStep(4)); setArticleHtml(response.data.article);
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, ...response.data, AI_type: eCommerceBlogInfo?.AI_type }));

        setEditData({ ...eCommerceBlogInfo, ...response.data, AI_type: eCommerceBlogInfo?.AI_type });
        setTimeout(() => setEditorKey(editorKey + 1), 0); setContentScore({});
        setSelectedVersionItem({}); getArticleAllVersionHistory();
        handleGenerateOrGetSeoScore();
      } else {
        toast.error("Article not generated, Please try again!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const getFullData = async (name) => {
    try {
      if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
        toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
        return false;
      }

      dispatch(setLoader(true));
      const articleId = eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "";
      const payload = {
        pathParameters: { id: articleId },
        queryStringParameters: { tableName: "catalog_product_articles" },
      };

      let response = null;
      if (name === "linkedIn_post" || name === "facebook_post" || name === "twitter_post" || name === "instagram_post") {
        payload.pathParameters.type = name.replace("_post", "");
        response = await invokeLambdaFunction("generateSocialPostForBlog", payload);
      } else if (name === "readability") {
        const payload = {
          pathParameters: { articleId: eCommerceBlogInfo?.articleId || "" },
          body: JSON.stringify({ type: "generate-elixir-update-blog", subtype: "readability-article" }),
        };

        response = await invokeLambdaFunction("generateElixirEComBLogByType", payload);
      };

      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data) {
        if (name === "readability") {
          dispatch(setECommerceBlogStep(4)); setArticleHtml(response.data.article_html);
          dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, article: response.data.article_html, readability_used: 1 }));
          setEditData({ ...eCommerceBlogInfo, article: response.data.article_html });
          setTimeout(() => setEditorKey(editorKey + 1), 0); setContentScore({});
          setSelectedVersionItem({}); getArticleAllVersionHistory();
          handleGenerateOrGetSeoScore();
        } else {
          const post = response.data.post.replace(/\?{2,}/g, "");
          await dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, [name]: post }));
          setEditData((pre) => ({ ...pre, [name]: post })); setECommerceBlogStep(4);
          getArticleAllVersionHistory();

          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
        }
      } else {
        toast.error("Post not generated, Please try again.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      setCurrentTab(currentTab);
      toast.error("Post not generated, Please try again.", { id: "Toast-01" });
    }
  };

  const handleGenerateNewsletterArticle = async () => {
    try {
      dispatch(setLoader(true));
      const payload = { body: JSON.stringify({ id: eCommerceBlogInfo?.id, type: "generate-elixir-newsletter" }) };
      const response = await invokeLambdaFunction("generateElixirEComBLogByType", payload);
      dispatch(setLoader(false));

      if (response && response.success === 1 && response.data && response.data.content) {
        setNewsletterHtml(response.data.content); setEditorKey(editorKey + 1); getArticleAllVersionHistory();
        dispatch(setECommerceBlogInfo({ ...eCommerceBlogInfo, newsletter: response.data.content }));
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
      } else {
        toast.error(response?.data?.error || "Newsletter content not generated, Please try again", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleGetAllImagesAndOpenModal = async (subType, html) => {
    try {
      const params = {
        html, type: articleType, subType, userId: userRes.id,
        articleId: eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "",
        topic: eCommerceBlogInfo?.selectedTopic ? eCommerceBlogInfo.selectedTopic : "",
      };

      const queryParams = new URLSearchParams({
        type: articleType, subType, userId: userRes.id,
        articleId: eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "",
      }).toString();

      setPostImagesModal((prev) => ({ ...prev, eComProductImagesLoader: true }));
      const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
      await handleGetOrGenerateAllTypeImages(paramObj);

      if (eCommerceBlogInfo?.selectedProductImages && eCommerceBlogInfo?.selectedProductImages.length > 0) {
        setPostGeneratedImages((prev) => ({ ...prev, eComProductImages: eCommerceBlogInfo?.selectedProductImages }));
      }

      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false, eComProductImagesLoader: false }));
    } catch (error) {
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false, eComProductImagesLoader: false }));
    }
  };

  const handleSideButton = () => {
    const tabs = ["article"];
    if (tabs.includes(selectedTab)) {
      let tempContentNewScore = null;
      if (selectedTab === "article" && contentScore && Object.keys(contentScore).length > 0 && eCommerceBlogInfo.article) {
        tempContentNewScore = contentScore;
      }

      if (tempContentNewScore && Object.keys(tempContentNewScore).length > 0) {
        const word_score = (tempContentNewScore["Word count"]["SEO Score"] * 100) / tempContentNewScore["Word count"]["Max SEO score available"];
        const related_keywords_score = (tempContentNewScore["Related keywords"]["SEO Score"] * 100) / tempContentNewScore["Related keywords"]["Max SEO score available"];
        return (
          <div className={classNames("sideBarOpenBtn")}>
            <button
              className="border-0" onClick={() => { setToggleRightSideBar((prev) => seoScoreMobile ? true : !prev); setShow((prev) => seoScoreMobile ? true : false); }}
              data-tooltip-id="sidebar-toogle" data-tooltip-content={!toggleRightSideBar ? "Hide SEO Score" : "Show SEO Score"} data-tooltip-place="bottom"
            >
              {seoScoreMobile ? (
                <React.Fragment>
                  <GoArrowRight />
                  <p><strong><span className="textB">SEO Score</span></strong></p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <GoArrowRight />
                  <p className="">
                    <span>
                      <strong>{Math.round(word_score)} %</strong><span className="textB">Word Score</span>
                    </span>
                    <span>|</span>
                    <span>
                      <strong>{Math.round(related_keywords_score)} %</strong><span className="textB">Related Keywords</span>
                    </span>
                  </p>
                  <Tooltip id="sidebar-toogle" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
                </React.Fragment>
              )}
            </button>
          </div>
        );
      }
    }
    return "";
  };

  const getGenerateButton = () => {
    const buttonClassNames = (tab) => classNames("blogButton border-0", { "primary-btn": !eCommerceBlogInfo?.[tab] });
    if (currentTab === "linkedIn") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("linkedIn_post")} onClick={() => getFullData("linkedIn_post")}>
            <BsStars /> {!eCommerceBlogInfo?.linkedIn_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!eCommerceBlogInfo?.linkedIn_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (currentTab === "facebook") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("facebook_post")} onClick={() => getFullData("facebook_post")}>
            <BsStars /> {!eCommerceBlogInfo?.facebook_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!eCommerceBlogInfo?.facebook_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (currentTab === "twitter") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("twitter_post")} onClick={() => getFullData("twitter_post")}>
            <BsStars /> {!eCommerceBlogInfo?.twitter_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!eCommerceBlogInfo?.twitter_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (currentTab === "instagram") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("instagram_post")} onClick={() => getFullData("instagram_post")}>
            <BsStars /> {!eCommerceBlogInfo?.instagram_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!eCommerceBlogInfo?.instagram_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (currentTab === "newsletter") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("newsletter")} onClick={() => handleGenerateNewsletterArticle()}>
            <BsStars /> {!eCommerceBlogInfo?.newsletter ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!eCommerceBlogInfo?.newsletter ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    }
  };

  const handleShareSocialMediaPost = async () => {
    if (currentTab === "linkedIn") {
      const uploadImage = eCommerceBlogInfo?.linkedIn_post_img || "";
      const params = {
        postType: "LinkedIn", text: editData?.linkedIn_post || "",
        articleId: eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : "",
        keyword: eCommerceBlogInfo?.keyword || "", userId: userRes.id, dispatch,
        image: uploadImage ? JSON.stringify({ data: uploadImage }) : "",
      };
      await handleShareSocialMediaTypePost(params);
    }
  };

  const handleDownloadArticlePngFile = async () => {
    if (document.querySelector(".gjs-frame") && document.querySelector(".gjs-frame").contentDocument) {
      const viewComponents = document.querySelector("span[title='View components'].gjs-pn-active");
      if (viewComponents) { viewComponents.click(); }
      if (templateEditor) { templateEditor.select(null); }

      const editorBody = document.querySelector(".gjs-frame").contentDocument.body;
      const images = editorBody.querySelectorAll("img");
      dispatch(setLoader(true));

      for (const img of images) {
        const imageUrl = img.src;
        if (imageUrl.startsWith("data:")) { continue; }
        try {
          const response = await AxiosApi.get(`/api/post-writer/get-img-base64`, { params: { url: imageUrl } });
          if (response && response.data && response.data.data) {
            img.src = response.data.data;
          }
        } catch (error) {
        }
      }

      const childDiv = editorBody.querySelector(".container");
      htmlToImage.toPng(childDiv).then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement("a");
        link.download = `E-Commerce-Template-${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
        dispatch(setLoader(false));
      }).catch(function (error) {
        dispatch(setLoader(false));
      });
    }
  };

  const handleTabs = (tab) => {
    setSearchParams("tab=" + tab.value);
    setCurrentTab(tab.value);
  };

  const handleOnOpenMediaLibraryForBlog = async () => {
    const elements = document.getElementsByClassName("custom-tooltip");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style = "";
    }

    try {
      const html = htmlText, subType = "Article Images";
      const params = {
        html, type: articleType, subType, userId: userRes.id,
        articleId: eCommerceBlogInfo?.id ? eCommerceBlogInfo.id : "",
      };

      setPostGeneratedImages((prev) => ({ ...prev, subType }));
      setBlogImagesModal((prev) => ({
        ...prev, open: true, type: articleType, subType,
        imageHistoryLoader: true, apiParams: params,
      }));

      if (postGeneratedImages && postGeneratedImages.subType !== subType) {
        setPostGeneratedImages((prev) => ({ subType }));
        const res_1 = await AxiosApi.get(`/api/generate-images/get-uploaded-image/${userRes.id}?type=${articleType}`);
        setBlogImagesModal((prev) => ({ ...prev, imageHistoryLoader: false }));
        if (res_1 && res_1.data && res_1.data.images && res_1.data.images.length > 0) {
          setPostGeneratedImages((prev) => ({ ...prev, imageHistory: res_1.data.images, isAiImgGenerated: res_1.data.isAiImgGenerated }));
        }

        try {
          const selectedProducts = eCommerceBlogInfo?.selectedProducts;
          const selectedProductImages = [];
          for (let index = 0; index < selectedProducts.length; index++) {
            const element = selectedProducts[index];
            if (element && element.image) {
              selectedProductImages.push(element.image);
            }
          }
          setPostGeneratedImages((prev) => ({ ...prev, selectedProductImages }));
        } catch (error) {
          console.log('✌️error --->', error);
        }
      }
      setBlogImagesModal((prev) => ({ ...prev, imageHistoryLoader: false }));
    } catch (error) {
      setBlogImagesModal((prev) => ({ ...prev, imageHistoryLoader: false }));
    }
  };

  return (
    <React.Fragment>
      <div className="blogWriterArea d-flex flex-row border-0 finalArticle">
        {selectedTab !== "productTemplate" && (
          <div className={classNames("sideBarB")}>
            <div className="blogSideBar">
              <ul className="">
                {sidebarMenuOptions.map((item, index) => (
                  <div key={index}>
                    <li
                      className={classNames("d-flex align-items-center gap-3", { active: item.value === selectedTab })}
                      onClick={() => handleTabs(item)}
                    >
                      {typeof item.icon === "string" ? <ReactSVG src={item.icon} /> : item.icon}
                      <span className="d-block w-100">{item.name}</span>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        )}

        {selectedTab === "aiOverview" ? (
          <AiOverviewTabComponent
            articleType={articleType} articleId={eCommerceBlogInfo?.id} articleHtml={articleHtml} isFullScreen={false}
          />
        ) : (
          <div className={classNames("blogWriterBlock", { "mw-100": selectedTab === "productTemplate", "position-relative": selectedTab !== "productTemplate" })}>
            <div className="navItemsBlock">
              <div className={classNames(`navTopButton d-flex justify-content-between flex-wrap gap-4`)}>
                <div className="leftButtons d-flex gap-3 align-items-center">
                  {(selectedTab === "productTemplate" || screenSize?.width < 1280) && (
                    <div className="d-flex gap-3 dropdown">
                      <DropdownMenu
                        options={sidebarMenuOptions} label={"name"} className="border-0" placeholder={"Select tabs"}
                        selectedOption={sidebarMenuOptions.filter((menu) => menu.value === selectedTab)[0]} onSelect={handleTabs}
                      />
                    </div>
                  )}
                  <button className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <IoIosList /> Metadata
                  </button>
                  <span className="line"></span>
                  {selectedTab !== "igReels" && (
                    <VersionHistory
                      versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                      handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                      articleType={(selectedTab === "article" || selectedTab === "newsletter" || selectedTab === "productTemplate") ? articleType : ""}
                      selectedSubTab={""} setSelectedSubTab={() => { }}
                    />
                  )}
                  {selectedTab === "article" ? (
                    <React.Fragment>
                      <span className="line"></span>
                      <button
                        type="button" onClick={() => setShowFeedbackModel({ ...showFeedbackModel, open: true })}
                        className={"blogButton border-0"} disabled={!eCommerceBlogInfo?.articleRegenerateLeft}
                      >
                        <BsStars /> Re-Generate ( {eCommerceBlogInfo.articleRegenerateLeft || 0} / 3 )
                      </button>
                      {!eCommerceBlogInfo?.readability_used && (
                        <button type="button" onClick={() => getFullData("readability")} className={"blogButton border-0 ms-2"}>
                          <FaBookReader /> Readability
                        </button>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="d-flex gap-3">
                      <div className="regenrateBtn d-flex align-items-center">{getGenerateButton()}</div>
                    </div>
                  )}
                  {selectedTab === "productTemplate" && eCommerceBlogInfo?.templateHtml && (
                    <button className="blogButton border-0 text-nowrap" onClick={handleDownloadArticlePngFile}>
                      <BsDownload /> Download As PNG
                    </button>
                  )}
                </div>
                <div className="rightSideButton d-flex gap-2">
                  {selectedTab === "article" && (
                    <button type="button" onClick={handleOnOpenMediaLibraryForBlog} className="blogButton border-0">
                      <BsImages /> Image Library
                    </button>
                  )}
                  <button data-tooltip-id="my-tooltip" data-tooltip-content="Save" data-tooltip-place="bottom"
                    className="blogButton border-0 bg-black saveButton" onClick={handleSaveFinalArticle}
                  >
                    <FaRegSave />
                  </button>
                  {selectedTab === "linkedIn" && (
                    <button type="button" className="blogButton border-0"
                      onClick={() => eCommerceBlogInfo?.linkedIn_post ? handleShareSocialMediaPost() : null}
                      disabled={eCommerceBlogInfo?.linkedIn_post && eCommerceBlogInfo?.linkedIn_post !== "" ? false : true}
                    >
                      <FaLinkedin className="fs-5" /> Share Post
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames("editableTextArea d-flex overflow-hidden e-editableTextArea", selectedTab,
              { removeSideBar: toggleRightSideBar && ['longArticle', "article", "faqSchema", "reviewArticle"].includes(selectedTab) },
              { 'me-3': selectedTab === "article" && toggleRightSideBar, "position-relative": selectedTab !== "productTemplate" },
              { 'faqTab f-50': selectedTab === "faqSchema" }, { 'f-50': selectedTab === "googleAdCopy" },
            )}>
              {currentTab === "article" ? (
                <React.Fragment className="eCommerce-blog-wrapper m-0 w-100 mw-100">
                  {toggle ? (
                    <HtmlViewer
                      setToggle={setToggle} value={articleHtml} html={eCommerceBlogInfo?.article}
                      onEditorChange={(e) => setArticleHtml(e)} style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <div className="position-relative editableTextArea article w-100">
                      <div className="position-relative custom-text-editor h-100">
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={articleHtml ? articleHtml : ""} key={editorKey}
                          onEditorChange={(e) => setArticleHtml(e)}
                          init={{
                            ...EditorInitObj,
                            content_style: `${(eCommerceBlogInfo?.article?.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}`,
                            toolbar: "viewHtml | copyContent | " + EditorInitObj.toolbar,
                            setup: function (editor) {
                              addEditorCustomButtons(editor, setToggle, handleCopyHtmlArticleText);
                            },
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="toggleData eCommerce-seo-wrapper">
                    <React.Fragment>
                      {contentScore && Object.keys(contentScore).length > 0 && eCommerceBlogInfo?.article ? (
                        seoScoreMobile ? (
                          <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                SEO score <BsInfoCircleFill className="infoIconSvg" data-tooltip-id="seo-score"
                                  data-tooltip-content="Valid only for articles generated in “English”"
                                  data-tooltip-place="bottom" />
                                <Tooltip id="seo-score" style={{ borderRadius: "6px", fontSize: "11px", padding: "4px 12px", zIndex: 9999 }} />
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="offcanvas_seo_score">
                              <SeoScorePreview contentScore={contentScore} article={articleHtml} col={12} show={show} />
                            </Offcanvas.Body>
                          </Offcanvas>
                        ) : (
                          <SeoScorePreview contentScore={contentScore} article={articleHtml} col={12} show={show} />
                        )
                      ) : (
                        <div style={{ height: "100%", background: "var(--Black-B30)", borderRadius: "10px" }}></div>
                      )}
                    </React.Fragment>
                  </div>

                  {showFeedbackModel && showFeedbackModel.open && (
                    <Modal size="lg" centered show={showFeedbackModel.open} onHide={() => setShowFeedbackModel({ ...showFeedbackModel, open: false })}>
                      <Modal.Body className="text-start p-0 overflow-auto" style={showFeedbackModel.article ? { height: "90vh" } : {}}>
                        <div className="maingenrateBlock m-0 p-3 h-100">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4>What would you like to change?</h4>
                            <button className="btn btn-primary" onClick={() => handleGenerateArticleForAiModel(showFeedbackModel)}>
                              <BsStars /> Re-Generate
                            </button>
                          </div>
                          <div className="genrateFields mt-3">
                            <div className="fields m-0">
                              <label>Customize your content before hitting the 'Re-Generate' button. </label>
                              <textarea
                                name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                                className={`addlly-textarea w-100`} value={showFeedbackModel?.feedback}
                                onChange={(e) => setShowFeedbackModel({ ...showFeedbackModel, feedback: e.target.value })}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </React.Fragment>
              ) : selectedTab === "linkedIn" ? (
                <LinkedinArticle
                  postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                  edit={editData} setEdit={setEditData} value={editData?.linkedIn_post} userRes={userRes} generateHashtag={false}
                  generatedFullArticle={eCommerceBlogInfo} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("LinkedIn Post", eCommerceBlogInfo?.linkedIn_post)}
                />
              ) : selectedTab === "facebook" ? (
                <FacebookArticle
                  postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                  edit={editData} setEdit={setEditData} value={editData?.facebook_post} userRes={userRes} generateHashtag={false}
                  generatedFullArticle={eCommerceBlogInfo} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Facebook Post", eCommerceBlogInfo?.facebook_post)}
                />
              ) : selectedTab === "twitter" ? (
                <TwitterArticle
                  postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                  edit={editData} setEdit={setEditData} value={editData?.twitter_post} userRes={userRes} generateHashtag={false}
                  generatedFullArticle={eCommerceBlogInfo} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Twitter Post", eCommerceBlogInfo?.twitter_post)}
                />
              ) : selectedTab === "instagram" ? (
                <InstagramArticle
                  postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
                  edit={editData} setEdit={setEditData} value={editData?.instagram_post} userRes={userRes} generateHashtag={false}
                  generatedFullArticle={eCommerceBlogInfo} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  setUploadFileModal={() => handleGetAllImagesAndOpenModal("Instagram Post", eCommerceBlogInfo?.instagram_post)}
                />
              ) : selectedTab === "newsletter" ? (
                <React.Fragment className="eCommerce-blog-wrapper m-0 w-100 mw-100">
                  {(!newsletterHtml || newsletterHtml === "") ? (
                    <div className="w-100" style={{ height: "100vh", background: "var(--Black-B30)", borderRadius: "10px" }} />
                  ) : toggle ? (
                    <HtmlViewer
                      setToggle={setToggle} value={newsletterHtml} html={eCommerceBlogInfo?.newsletter}
                      onEditorChange={(e) => setNewsletterHtml(e)} style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <div className="position-relative editableTextArea article me-3 w-100">
                      <div className="position-relative custom-text-editor h-100">
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={newsletterHtml ? newsletterHtml : ""} key={editorKey}
                          onEditorChange={(e) => setNewsletterHtml(e)}
                          init={{
                            ...EditorInitObj, toolbar: "viewHtml | copyContent | " + EditorInitObj.toolbar,
                            content_style: `${(eCommerceBlogInfo?.newsletter?.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}`,
                            setup: function (editor) { addEditorCustomButtons(editor, setToggle, handleCopyHtmlArticleText); },
                          }}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : selectedTab === "productTemplate" ? (
                <EComProductTemplate
                  templateEditor={templateEditor} setTemplateEditor={setTemplateEditor}
                  isTemplateGenerated={isTemplateGenerated} setIsTemplateGenerated={setIsTemplateGenerated}
                />
              ) : null}
            </div>
            {handleSideButton()}
          </div>
        )}
      </div>

      {blogImagesModal && blogImagesModal.open && (
        <div className="genrateAiImages">
          <EdmMediaLibraryDrawer
            blogImagesModal={blogImagesModal} setBlogImagesModal={setBlogImagesModal} editorRef={editorRef}
            postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
            isDisplayProductImgTab={true} articleId={eCommerceBlogInfo?.id ? eCommerceBlogInfo.id : ""}
          />
        </div>
      )}

      {/* Meta Data */}
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            E-Commerce Blog’s Metadata
          </h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>What do you want to write about?</p>
              <span className=" ">{eCommerceBlogInfo?.selectedTopic}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(eCommerceBlogInfo?.selectedTopic)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>AI Model</p>
              <span className=" ">{eCommerceBlogInfo?.AI_type}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(eCommerceBlogInfo?.AI_type)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>Content Tone</p>
              <span className="">{eCommerceBlogInfo?.content_tone !== "" ? eCommerceBlogInfo?.content_tone : "-"}</span>
            </div>
            {eCommerceBlogInfo.content_tone !== "" && (
              <div className="copyIcon cursor-pointer">
                <img
                  src={copyIcon} alt="copy" onClick={() => handleCopy(eCommerceBlogInfo?.content_tone)} className="text-info"
                  data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
                />
              </div>
            )}
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>Language</p>
              <span className=" ">{eCommerceBlogInfo?.language}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(eCommerceBlogInfo?.language)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="offcanvas-header p-0 m-0 my-3"></div>
          <div className="blogMetaInner">
            <p><BsStars /> Headline generated</p>
            <span className=" ">{eCommerceBlogInfo?.selectedTopic}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta description generated</p>
            <span className=" ">{eCommerceBlogInfo?.meta_dec}</span>
          </div>
          <div className="offcanvas-header p-0 m-0 my-3"></div>
          <div className="blogMetaInner">
            <p><BsStars /> Theme ( Promotion text )</p>
            <span className=" ">{eCommerceBlogInfo?.promotionText || "-"}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Selected trends</p>
            <ul>
              {eCommerceBlogInfo?.selectedSetTopics?.map((item) => <li>{item}</li>)}
            </ul>
          </div>
          <div className="offcanvas-header p-0 m-0 my-3"></div>
          <div className="blogMetaInner">
            <p><BsStars /> Selected Products</p>
            <div className="sideBarInner px-1">
              <div className="d-flex align-items-center gap-3 flex-column justify-content-between backButton">
                {eCommerceBlogInfo && eCommerceBlogInfo?.selectedProducts?.map((selectedProduct) => (
                  <div className={`image-wrapper d-flex align-items-center flex-column w-100 border cursor-pointer`} key={selectedProduct?.id}>
                    <img src={selectedProduct?.image} alt="" style={{ objectFit: 'contain', width: '100%' }} />
                    <div className="card-product-info text-left p-2" style={{ backgroundColor: "#F4F4F9" }}>
                      <p>{selectedProduct?.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {postImagesModal && postImagesModal.open && (
        <div className="genrateAiImages">
          <MediaLibraryDrawer
            postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} userRes={userRes}
            postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
            articleType={articleType} articleId={eCommerceBlogInfo?.articleId ? eCommerceBlogInfo.articleId : ""}
            isDisplayEComImgTab={true}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default FourthStepElixirEComBlog;
