import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toasterSuccess } from "../Components/ui/toaster";

import {
  resetSEOFLow, setActiveMenu, setFeaturesData, setLoader, setPinnedFeaturesData, setSeoOptBlogStep, setSeoOptBlogTopic,
  setSocialMediaPostHistory, setSocialMediaPostStep, setSocialMediaStepsInfo, setTopResultsStep,
} from "../store/reducer";

import { AxiosApi } from "../utility/axios";
import { endPointes } from "../utility/endPointes";
import { useSelector } from "react-redux";

const azgoCardDetails = [
  {
    id: 1,
    title: "Travel Newsletter",
    icon: "newsletter.svg",
    to: "/travel-newsletter",
    is_new: 1,
    type: "others",
    value: "Travel Newsletter",
    description: "Custom create Travel newsletter in minutes",
  }
];

const CMICardDetails = [
  {
    id: 1,
    title: "CMI Newsletter",
    icon: "newsletter.svg",
    to: "/cmi-newsletters",
    is_new: 1,
    type: "others",
    value: "CMI Newsletter",
    description: "Custom create CMI newsletter in minutes",
  }
];

const OonaCards = [
  {
    id: 1,
    title: "SEO Blog Co-Pilot",
    icon: "messageIcon.svg",
    to: "/oona-blog-co-pilot",
    is_new: 1,
    type: "others",
    value: "SEO Blog Co-Pilot",
    description: "Custom create in-depth, SEO-friendly blogs with geolocation targeted topics, keyword data, and personalized writing styles in minutes.",
  },
  {
    id: 2,
    title: "1-Click Blogs",
    icon: "click.svg",
    to: "/oona-1-click/short-blog",
    is_new: 0,
    type: "1-click-blog",
    value: "1-Click Blogs",
    description: "Generate human-like, SEO-optimized blogs on any topic with just a single click.",
  }
];

const ThePurestCards = [
  {
    "id": 1,
    "icon": "messageIcon.svg",
    "title": "SEO Blog Co-Pilot",
    "description": "Custom create in-depth, SEO-friendly blogs with geolocation targeted topics, keyword data, and personalized writing styles in minutes.",
    "is_new": 1,
    "type": "others",
    "to": "/blog-co-pilot",
    "value": "SEO Blog Co-Pilot",
    "created_at": "2024-01-30T10:29:45.000Z",
    "updated_at": "2024-01-30T10:29:45.000Z"
  },
  {
    "id": 2,
    "icon": "click.svg",
    "title": "1-Click Blogs",
    "description": "Generate human-like, SEO-optimized blogs on any topic with just a single click.",
    "is_new": 0,
    "type": "1-click-blog",
    "to": null,
    "value": "1-Click Blogs",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 3,
    "icon": "downloadPdf.svg",
    "title": "Generate a blog using PDF",
    "description": "Craft standout posts effortlessly, turning ideas into captivating content in a single click.",
    "is_new": 0,
    "type": "others",
    "to": null,
    "value": "Pdf",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 4,
    "icon": "hastag.svg",
    "title": "Social Media Posts",
    "description": "Boost brand awareness with powerful social media posts, complete with real-time analytics and trending hashtags.",
    "is_new": 0,
    "type": "others",
    "to": "/social-media-post-writer",
    "value": "Social Media Posts",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 5,
    "icon": "instagram.svg",
    "title": "Instagram Post Writer",
    "description": "Engage your followers with catchy instagram captions and trending hashtags.",
    "is_new": 0,
    "type": "social-media",
    "to": null,
    "value": "Instagram",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 6,
    "icon": "facebook.svg",
    "title": "Facebook Post Writer",
    "description": "Generate Facebook posts designed to grow your Facebook community.",
    "is_new": 0,
    "type": "social-media",
    "to": null,
    "value": "Facebook",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 7,
    "icon": "linkedin.svg",
    "title": "Linkedin Post Writer",
    "description": "Write professional LinkedIn posts that build thought leadership with high authority hashtags and real-time data.",
    "is_new": 0,
    "type": "social-media",
    "to": null,
    "value": "Linkedin",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 8,
    "icon": "newsletter.svg",
    "title": "Twitter (X) Post Writer",
    "description": "Craft concise, impactful posts that get retweeted and shared.",
    "is_new": 0,
    "type": "social-media",
    "to": null,
    "value": "Twitter",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
];

const BCACardsDetails = [
  ...ThePurestCards,
  {
    "id": 9,
    "icon": "loudSpeaker.svg",
    "title": "Newsletter Builder",
    "description": "Create high-converting newsletters with easy templates and live links to your website.",
    "is_new": 0,
    "type": "others",
    "to": "/newsletters",
    "value": "Newsletter",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
  {
    "id": 10,
    "icon": "images.svg",
    "title": "Press Release Quick Draft",
    "description": "Get the word out fast. Generate impactful, SEO-optmized press releases in minutes.",
    "is_new": 0,
    "type": "others",
    "to": "/press-release",
    "value": "Press Release",
    "created_at": "2024-01-30T10:37:23.000Z",
    "updated_at": "2024-01-30T10:37:23.000Z"
  },
];

const withDashboardHandler = (WrappedComponent) => {
  const WithDashboardHandler = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRes = useSelector(({ userRes }) => userRes);
    const featuresData = useSelector(({ featuresData }) => featuresData);
    const pinnedFeaturesData = useSelector(({ pinnedFeaturesData }) => pinnedFeaturesData);
    const [showMobileModal, setShowMobileModal] = useState(false);
    const [isLoadingCards, setIsLoadingCards] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");

    const handleGetAllFeaturesCard = async () => {
      try {
        let topFeatures = {};
        setIsLoadingCards(true);
        if (userRes.company === "Oona") {
          topFeatures = { data: { data: [...OonaCards] } };
        } else if (userRes.company === "ThePurest") {
          topFeatures = { data: { data: [...ThePurestCards] } };
        } else if (userRes.company === "BCA") {
          topFeatures = { data: { data: [...BCACardsDetails] } }
        } else if (userRes.company === "azgo") {
          topFeatures = { data: { data: [...azgoCardDetails] } };
        } else if (userRes.company === "CMI") {
          topFeatures = { data: { data: [...CMICardDetails] } }
        } else if (userRes.first_name) {
          topFeatures = await AxiosApi.get(endPointes.allFeatures);
        }

        const pinnedFeature = await AxiosApi.get(endPointes.pinnedFeature);
        dispatch(setFeaturesData(topFeatures?.data?.data));
        dispatch(setPinnedFeaturesData(pinnedFeature.data?.data));
        setIsLoadingCards(false);
      } catch (error) {
        setIsLoadingCards(false);
      }
    };

    const handleClose = () => {
      setShowMobileModal(false);
      localStorage.removeItem("isCheckDevice");
    };

    useEffect(() => {
      if (userRes.first_name) {
        handleGetAllFeaturesCard();
      }
    }, [userRes.first_name]);

    useEffect(() => {
      (() => {
        const val = localStorage.getItem("isCheckDevice");
        if (val && val === "true") {
          const detectDeviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
            ? "Mobile"
            : "Desktop";
          if (detectDeviceType === "Mobile") {
            setShowMobileModal(true);
          } else {
            handleClose();
          }
        }
      })()
    }, []);

    const searchedData = useMemo(() => {
      // const topFeatures = pinnedFeaturesData?.length ? featuresData?.filter((topFeature) => !pinnedFeaturesData?.some((pinnedFeature) => pinnedFeature.feature_id === topFeature.id)
      // ) : featuresData;

      return featuresData?.filter(item =>
        Object.values(item).some(value => typeof value === "string" && value?.toLowerCase().includes(searchFilter?.toLowerCase()))
      )
    }, [featuresData, searchFilter])

    const handleOnChangeCustomWriterCardType = (type, value) => {
      dispatch(setLoader(true));
      dispatch(resetSEOFLow());
      setTimeout(() => dispatch(setLoader(false)), 1000);

      let generateLink = true;
      if (type === "Pdf") {
        generateLink = false;
      }
      dispatch(setActiveMenu(3));
      dispatch(setSeoOptBlogStep(2));
      dispatch(setSeoOptBlogTopic({ selectedCard: type, generateLink: generateLink ? 1 : 0, isDefaultSet: true }));
      navigate("/blog-co-pilot");
    };

    const handleOnChangeSocialMediaCardType = (type) => {
      dispatch(setActiveMenu(4));
      dispatch(setSocialMediaStepsInfo({ PostType: type, selectedCard: "", isDefaultSet: true, stepDone: 2 }));
      dispatch(setSocialMediaPostHistory({}));
      dispatch(setSocialMediaPostStep(2));
      navigate("/social-media-post-writer");
    };

    const handleNavigate = useCallback((value, type) => {
      if (String(type).toLowerCase() === "social-media") {
        handleOnChangeSocialMediaCardType(value);
      } else if (String(type).toLowerCase() === "1-click-blog") {
        dispatch(setTopResultsStep(1));
        dispatch(setActiveMenu(2));
        navigate("/1-click-blog");
      } else {
        handleOnChangeCustomWriterCardType(value, type);
      }
    }, [dispatch, navigate]);

    const handlePinnedFeature = (feature_id) => {
      dispatch(setLoader(true));
      AxiosApi.post(endPointes.addToPinnedList, { feature_id }).then((response) => {
        dispatch(setLoader(false));
        const { message } = response.data;
        if (message) {
          toasterSuccess(message, "toaster-2");
          handleGetAllFeaturesCard();
        }
      }).catch((error) => {
        dispatch(setLoader(false));
        console.error("There is error while adding feature to the pinned list", error);
      });
    };

    const handleRemovePinnedFeature = (feature_id) => {
      dispatch(setLoader(true));
      AxiosApi.delete(endPointes.removeFromPinned + feature_id).then((response) => {
        dispatch(setLoader(false));
        const { message } = response.data;
        if (message) {
          toasterSuccess(message, "toaster-2");
          handleGetAllFeaturesCard();
        }
      }).catch((error) => {
        dispatch(setLoader(false));
        console.error("There is error while Removing feature from the pinned list", error);
      });
    };

    const filteredPinnedFeatures = useMemo(() => {
      const pinnedFeatures = featuresData?.filter((topFeature) =>
        pinnedFeaturesData?.some((pinnedFeature) => pinnedFeature.feature_id === topFeature.id)
      )
      return pinnedFeatures?.filter(item =>
        Object.values(item).some(value => typeof value === "string" && value?.toLowerCase().includes(searchFilter?.toLowerCase()))
      )
    }, [featuresData, pinnedFeaturesData, searchFilter])

    return (
      <div>
        <WrappedComponent
          {...props} handleNavigate={handleNavigate} handleClose={handleClose} setSearchFilter={setSearchFilter} showMobileModal={showMobileModal}
          userRes={userRes} filteredFeaturesData={searchedData} pinnedFeaturesData={pinnedFeaturesData} filteredPinnedFeatures={filteredPinnedFeatures}
          handlePinnedFeature={handlePinnedFeature} handleRemovePinnedFeature={handleRemovePinnedFeature} isLoadingCards={isLoadingCards}
        />
      </div>
    );
  };

  return WithDashboardHandler;
};

export default withDashboardHandler;
