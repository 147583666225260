import React, { memo, useMemo, useState } from "react";
import addIcons from "../../Assets/Icons/DashboardIcons/Add.svg";
import { FiPlus } from "react-icons/fi";
import { IoCaretDownSharp, IoEyeOutline } from "react-icons/io5";
import TableFilters from "./TableFilters";
import withHistoryHandler from "../../Hoc/HistoryHoc";
import classNames from "classnames";
import CustomDataTable from "../ui/DataTable";
import Heading from "../ui/Title";
import { TfiTrash } from "react-icons/tfi";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import TableLoader from "../ui/TableLoader";
import { navigationsOption, OonaNavigationOption, ThePurestNavigationOption } from "../../data";
import Navigations from "./Navigations";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../../utility/common";
import ArticlePreview from "../commonComponents/ArticlePreview";
import MenuItem from '@mui/material/MenuItem';
import DropdownMenu from "../ui/Menu";
import { ReactSVG } from "react-svg";
import { dashboardIcon, dataTableIcons } from "../../Assets/Icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RecentArticles = memo((props) => {
  const {
    historyData, textWrap, setTextWrap, handleNavigateArticleOrPostWriter,
    loading, setPage, limit, sortField, selectedRows, setSelectedRows, searchFilter,
    totalCount, page, onCategorySelect, onDateSelect, handleArchive, getHistoryData,
    handleDelete, handleSort, setSearchFilter, userRes, clearRow, handlePinArticle
  } = props;
  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);

  const handleOpenArticle = (row) => {
    setArticle(pre => ({ ...pre, id: row?.id, type: row?.type }));
    setShowOffset(true);
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className="d-flex align-items-center gap-2" onClick={() => handleTableRowClick(row)}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
              <p>{row.keyword}</p>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={'hover-star'} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "type",
        name: <div className="d-flex">Type {SortArrow(sortField, "type")}</div>,
        selector: (row) => <div style={{ whiteSpace: "nowrap" }} onClick={() => handleTableRowClick(row)}>{row?.display_type}</div>,
        sortable: true,
        sortField: "display_type",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => <div onClick={() => handleTableRowClick(row)}>{tableDateFormatter(row?.created_at)}</div>,
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "AI_type",
        name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
        selector: (row) => row.AI_type,
        sortable: true,
        sortField: "AI_type",
      },
      {
        id: "Status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "Status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "sort",
        // <BsArrowDownUp onClick={handleReArrangeData} cursor={'pointer'} className="w-auto" />
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row.status !== "pending" && row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleOpenArticle(row)}><IoEyeOutline /> Preview article</MenuItem>
                )}
                {row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleNavigateArticleOrPostWriter(row.id, row.type)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                )}
                {row.is_archived && row.is_archived === 1 ? (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}><TfiTrash /> Delete</MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                )}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [handleDelete, handleNavigateArticleOrPostWriter, sortField, textWrap]);

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row);
    } else if (row.status === "notAcceptable") {
    } else {
      handleNavigateArticleOrPostWriter(row.id, row?.type);
    }
  };

  return (
    <div className="dashboard-history border-radius-12">
      <div className="filterRow d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <Heading size={5} className={'recent-article-title'}>Recent Articles</Heading>
        <div className="recent-arcticle-filters mb-2">
          <TableFilters
            setTextWrap={setTextWrap} selectedRows={selectedRows} textWrap={textWrap} onCategorySelect={onCategorySelect} onDateSelect={onDateSelect}
            handleArchive={handleArchive} setSearchFilter={setSearchFilter}
          />
        </div>
      </div>
      <div className="">
        <CustomDataTable
          progressPending={loading} progressComponent={<TableLoader limit={limit} />} columns={columns} data={historyData} selectableRows={true}
          pagination={true} itemsPerPage={limit} setPage={setPage} noDataComponent={<NoDataFound searchFilter={searchFilter} userRes={userRes} />} onSelectedRowsChange={setSelectedRows}
          totalCount={totalCount} page={page} onSort={handleSort} handleTableRowClick={handleTableRowClick}
          clearSelectedRows={clearRow}
        />
      </div>
      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type} show={showOffset}
        setShow={setShowOffset} getHistoryData={getHistoryData}
        handleNavigateArticleOrPostWriter={handleNavigateArticleOrPostWriter}
      />
    </div>
  );
});

export default withHistoryHandler(RecentArticles);

const NoDataFound = ({ searchFilter, userRes }) => {
  return (
    <div className="withoutData m-0">
      <div className={`genrateNewBlock d-flex justify-content-center flex-column align-items-center ${searchFilter && 'border-0'}`}>
        {!searchFilter ? (
          <React.Fragment>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={addIcons} alt="icons"></img>
            </div>
            <p>Articles you create will be shown here</p>
            <Navigations
              navigationsOption={userRes.company === "Oona" ? OonaNavigationOption : userRes.company === "ThePurest" ? ThePurestNavigationOption : navigationsOption}
              button={
                <button>
                  <FiPlus /> Generate New
                  <span className="">
                    <IoCaretDownSharp className="downArrow ml-2" />
                  </span>
                </button>
              }
            />
          </React.Fragment>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};
