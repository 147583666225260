import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Swal from "sweetalert";
import { ReactSVG } from "react-svg";
import { Modal, Offcanvas, Tooltip } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import Sidebar from "./Sidebar";
import { AxiosApi } from "../../../utility/axios";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import NavItems from "../1ClickBlogWriter/second-step/NavItems";
import HtmlViewer from "../../../Components/SeoBlogWriter/HtmlViewer";
import { handleGetOrGenerateAllTypeImages } from "../../../utility/hepler";
import { invokeLambdaFunction } from "../../../utility/invokeLamdaFunction";
import GoogleAdStepImage from "../../../Assets/Images/Social_media/google-ad-step.png"
import FactCheckerPreview from "../../../Components/commonComponents/FactCheckerPreview";
import MainContent from "../../../Components/SeoBlogWriterNew/second-step/MainContent";
import FaqSchemaArtical from "../../../Components/commonComponents/FaqSchemaArticle";
import SeoScorePreview from "../../../Components/commonComponents/SeoScorePreview";
import ReviewTextEditor from "../../../Components/commonComponents/ReviewTextEditor/Index";
import AiOverviewTabComponent from "../../../Components/commonComponents/AiOverviewTabComponent";
import MediaLibraryDrawer from "../../../Components/commonComponents/MediaLibrary/MediaLibraryDrawer";
import { setGeneratedFullArticle, setLoader, setSeoOptBlogTopic, setTopResultsStep, setUserRes } from "../../../store/reducer";
import { BsInfoCircleFill } from "react-icons/bs";
const beautify_html = require("js-beautify").html;

const SecondStepPdfArticleWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);

  const [searchParams] = useSearchParams();
  const [toggle, setToggle] = useState(false);
  const [URLSearchParamsFull] = useSearchParams();
  const isFullScreen = URLSearchParamsFull.get("full-screen");
  const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "article");
  const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "Upload Image" });
  const [postGeneratedImages, setPostGeneratedImages] = useState({});
  const [googleAdCopyInfo, setGoogleAdCopyInfo] = useState(false);
  const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get("subTab") ? searchParams.get("subTab") : "seoScore");
  const articleType = "1-Click Blog";
  const reviewEditorChildRef = useRef();
  const [toggleRightSideBar, setToggleRightSideBar] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [contentScore, setContentScore] = useState({});
  const [isSeoScoreDisplay, setIsSeoScoreDisplay] = useState(true);
  const [sendToEditorForm, setSendToEditorForm] = useState(false);
  const [seoScoreMobile, setSeoScoreMobile] = useState(window.innerWidth <= 1024);
  const [show, setShow] = useState(false);

  const [editContent, setEditContent] = useState({
    article_html: generatedFullArticle?.article_html,
    article_detail_html: generatedFullArticle?.article_detail_html,
    FAQHTML: generatedFullArticle?.FAQHTML,
    FAQschema: generatedFullArticle?.FAQschema,
  });

  useEffect(() => {
    const handleResize = () => {
      setShow(false);
      setToggleRightSideBar(window.innerWidth <= 1024);
      setSeoScoreMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  const handleSetHtmlElementsOnArticle = () => {
    const Obj = {
      article_html: editContent.article_html,
      article_detail_html: editContent.article_detail_html,
      FAQHTML: editContent.FAQHTML,
      FAQschema: editContent.FAQschema,
    };

    if (generatedFullArticle && generatedFullArticle.article_html && generatedFullArticle.article_html.indexOf("<html>") !== -1) {
      const htmlStr = editContent.article_html.indexOf("<body>") !== -1 ? editContent.article_html.substring(editContent.article_html.indexOf("<body>") + 6, editContent.article_html.indexOf("</body>")) : editContent.article_html;
      Obj.article_html = `${generatedFullArticle.article_html.substring(0, generatedFullArticle.article_html.indexOf("<body>"))}
          <body> ${htmlStr} </body>
        </html>`.replaceAll("\n", "").replaceAll("\t", "");
    }

    if (generatedFullArticle && generatedFullArticle.article_detail_html && generatedFullArticle.article_detail_html.indexOf("<html>") !== -1) {
      const htmlStr1 = editContent.article_detail_html.indexOf("<body>") !== -1 ? editContent.article_detail_html.substring(editContent.article_detail_html.indexOf("<body>") + 6, editContent.article_detail_html.indexOf("</body>")) : editContent.article_detail_html;
      Obj.article_detail_html = `${generatedFullArticle.article_detail_html.substring(0, generatedFullArticle.article_detail_html.indexOf("<body>"))}
          <body> ${htmlStr1} </body>
        </html>`.replaceAll("\n", "").replaceAll("\t", "");
    }

    return Promise.resolve(Obj);
  };

  useEffect(() => {
    setToggleRightSideBar(false);
    setToggle(false);
    setTimeout(() => {
      setEditorKey(editorKey + 1);
    }, 500);
  }, [selectedCurrentTab])

  useEffect(() => {
    handleGenerateAiImageForH2Tag(generatedFullArticle);

    if (generatedFullArticle?.articleId && generatedFullArticle?.outline_html !== "") {
      handleGenerateShortArticleSeoScore(generatedFullArticle);
    }
  }, [])

  const handleSaveArticleOrPostText = async () => {
    dispatch(setLoader(true));

    const updateObj = await handleSetHtmlElementsOnArticle();
    dispatch(setGeneratedFullArticle({ ...generatedFullArticle, ...updateObj }));

    await AxiosApi.post(`/api/articleHistory/update`, {
      id: generatedFullArticle?.articleId,
      currentField: selectedCurrentTab,
      ...updateObj
    }).then(async () => {
      dispatch(setLoader(false));
      dispatch(setTopResultsStep(2));
      toast.success("Saved successfully", { id: "Toast-01" });
    }).catch(function (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  };

  const handleCopyHtmlArticleText = (e, text, msgType, copyType) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      if (copyType === "html") {
        copy(beautify_html(text, options), { format: "text/html" });
      } else {
        navigator.clipboard.writeText(beautify_html(text, options));
      }

      if (msgType === "toast") {
        toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
      } else {
        e.target.innerText = "Copied Text";
        setTimeout(() => {
          e.target.innerText = "Copy Text";
        }, 4000);
      }
    }
  };

  const getFullData = async (name, reGenerated = 0, feedback = "") => {
    try {
      if (name === "shortArticle") {
        if (generatedFullArticle.article_regenerate_left <= 0) {
          toast.error("Sorry, you have used all three available generations");
          return;
        }
      } else if (name === "faq") {
        if (generatedFullArticle.faq_regenerate_left <= 0) {
          toast.error("Sorry, you have used all three available generations");
          return;
        }
      }

      dispatch(setLoader(true));
      const articleId = generatedFullArticle?.articleId ? generatedFullArticle.articleId : "";
      const payload = { pathParameters: { id: articleId } };

      if (reGenerated) {
        payload.pathParameters.feedback = feedback || "";
      }

      setSelectedSubTab("");
      if (name === "shortArticle") {
        await invokeLambdaFunction("generateOonaArticleOrSaveByType", {
          body: JSON.stringify({
            feedback: feedback ? feedback : "", articleId: articleId,
            type: "regenerate-1-click-article"
          }),
        });
      } else if (name === "readability") {
        await invokeLambdaFunction("generateOonaArticleOrSaveByType", {
          body: JSON.stringify({ articleId: articleId, type: "readability-1-click-article" }),
        });
      } else if (name === "faq") {
        await invokeLambdaFunction("generateFAQSchema", payload);
      }
      setSelectedSubTab(selectedSubTab);

      const response = await AxiosApi.get(`/api/articleHistory/byId/${articleId}`);
      const responseData = response && response.data && response.data.data ? response.data.data : {};

      const setTempObj = {
        ...generatedFullArticle, article_regenerate_left: responseData.article_regenerate_left,
        article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
        FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema,
        faq_regenerate_left: responseData.faq_regenerate_left, readability_used: responseData?.readability_used,
      };
      await dispatch(setGeneratedFullArticle({ ...setTempObj }));
      dispatch(setLoader(false));
      setEditContent({
        ...editContent, FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema,
        article_html: responseData?.article_html, article_detail_html: responseData?.article_detail_html,
      });

      if (name === "shortArticle" && isSeoScoreDisplay) {
        setContentScore({});
        handleGenerateShortArticleSeoScore(responseData);
        handleGenerateAiImageForH2Tag(setTempObj);
      } else if (name === "readability" && isSeoScoreDisplay) {
        setContentScore({});
        handleGenerateShortArticleSeoScore(responseData);
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleGenerateAiImageForH2Tag = async (responseObj) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    let generateImageArray = [];
    if (responseObj?.h2_images && responseObj?.h2_images !== "") {
      try {
        const tempImgArray = JSON.parse(responseObj?.h2_images);
        for (let index = 0; index < tempImgArray.length; index++) {
          const element = tempImgArray[index];
          if (!element.image || element.image === "") {
            generateImageArray.push(element);
          }
        }
      } catch (error) { }
    }

    if (generateImageArray.length === 0) {
      return false;
    }

    try {
      const payload = {
        pathParameters: { articleId: responseObj?.id ? responseObj.id : "" },
        body: JSON.stringify({ articleType, generateType: "image-generate" })
      };

      const response = await invokeLambdaFunction("generateAiImagesForFinalArticle", payload);
      if (response && response.success === 1 && response.data && response.data.article && response.data.article !== "") {
        setEditContent((pre) => ({ ...pre, article_html: response.data.article }));
        generateImageArray = response.data.generateImageArray;
        dispatch(setGeneratedFullArticle({
          ...responseObj, h2_images: JSON.stringify(generateImageArray),
          article_html: response.data.article
        }));
      } else {
      }
    } catch (error) {
    }
  };

  const handleGenerateShortArticleSeoScore = async (setTempObj) => {
    if (setTempObj.SEO_score_response && setTempObj.SEO_score_response !== "") {
      try {
        const tempData = JSON.parse(setTempObj.SEO_score_response);
        setContentScore(tempData.data);
      } catch (error) {
      }
    } else if (setTempObj?.articleId) {
      const payload = {
        pathParameters: { id: setTempObj?.articleId },
        queryStringParameters: { articleType: "short" },
      };
      const response = await invokeLambdaFunction("getSEOScore", payload);
      if (response && response.success === 1 && response.data && response.data.data) {
        setContentScore(response.data.data);
      }
    }
  };

  const handleGetAllImagesAndOpenModal = async (subType, html, imageTab = "") => {
    try {
      if (imageTab && imageTab !== "") {
        setPostImagesModal((prev) => ({ ...prev, currentTab: "Upload Image" }));
      }

      const params = {
        html, type: articleType, subType, userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        topic: generatedFullArticle?.topic ? generatedFullArticle.topic : "",
        keyword: generatedFullArticle?.keyword ? generatedFullArticle.keyword : "",
      };

      const queryParams = new URLSearchParams({
        type: articleType, subType, userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
      }).toString();

      const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
      await handleGetOrGenerateAllTypeImages(paramObj);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    } catch (error) {
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    }
  };

  const handleShareSocialMediaPost = async () => { };

  const handleAutoFactChecker = async () => {
    dispatch(setLoader(true));
    const payload = {
      body: JSON.stringify({
        userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : ""
      }),
    };
    const response = await invokeLambdaFunction("generateFullArticleFactCheckerV2", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      if (response && response.data) {
        const data = JSON.parse(response.data);
        let getCurrArticle = editContent?.article_html;

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const finalText = element.textWithUrl ? element.textWithUrl.replaceAll("\n", "").replace(/\s+/g, ' ') : "";
          getCurrArticle = getCurrArticle.replace(element.text, finalText);
        }
        setEditContent({ ...editContent, article_html: getCurrArticle });
      }
    } else {
      toast.error(response?.error, { id: "Toast-01" });
    }
  };

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  const handleSendArticleToReviewer = async (data) => {
    try {
      dispatch(setLoader(true));
      const payload = {
        userId: userRes.id, articleType: articleType, articleSubType: "Short Article",
        organizationId: userRes?.organization_id ? userRes.organization_id : "",
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        articleText: editContent.article_html ? editContent.article_html : "", ...data
      };

      const response = await AxiosApi.post(`/api/editorial-comment/send-to-reviewer`, payload);
      if (response && response.data && response.data.success === 1) {
        dispatch(setLoader(false));
        if (data.isSentToReviewer && data.isSentToReviewer === true) {
          Swal({ icon: "success", title: "Your blog has been sent for review!", text: "Please give us 1 to 2 days to revert." });
          setSelectedCurrentTab("reviewArticle");
          dispatch(setSeoOptBlogTopic({ ...generatedFullArticle, is_check_reviewer: 1 }));
          if (!userRes?.organization_id) {
            dispatch(setUserRes({ ...userRes, credits: userRes.credits - data.deductCredits }));
          }
        }

        return response.data;
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!", { id: "Toast-01" });
    }
  };

  const handleSideButton = () => {
    const tabs = ["article"];
    if (tabs.includes(selectedCurrentTab)) {
      let tempContentNewScore = null;
      if (selectedCurrentTab === "article" && contentScore && Object.keys(contentScore).length > 0 && generatedFullArticle?.article_html) {
        tempContentNewScore = contentScore;
      }

      if (tempContentNewScore && Object.keys(tempContentNewScore).length > 0) {
        const word_score = (tempContentNewScore["Word count"]["SEO Score"] * 100) / tempContentNewScore["Word count"]["Max SEO score available"];
        const related_keywords_score = (tempContentNewScore["Related keywords"]["SEO Score"] * 100) / tempContentNewScore["Related keywords"]["Max SEO score available"];
        return (
          <div className={classNames("sideBarOpenBtn")}>
            <button
              className="border-0" onClick={() => { setToggleRightSideBar((prev) => seoScoreMobile ? true : !prev); setShow((prev) => seoScoreMobile ? true : false); }}
              data-tooltip-id="sidebar-toogle" data-tooltip-content={!toggleRightSideBar ? "Hide SEO Score" : "Show SEO Score"} data-tooltip-place="bottom"
            >
              {seoScoreMobile ? (
                <React.Fragment>
                  <GoArrowRight />
                  <p><strong><span className="textB">SEO Score</span></strong></p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <GoArrowRight />
                  <p className="">
                    <span>
                      <strong>{Math.round(word_score)} %</strong><span className="textB">Word Score</span>
                    </span>
                    <span>|</span>
                    <span>
                      <strong>{Math.round(related_keywords_score)} %</strong><span className="textB">Related Keywords</span>
                    </span>
                  </p>
                  {/* <Tooltip id="sidebar-toogle" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} /> */}
                </React.Fragment>
              )}
            </button>
          </div>
        );
      }
    }
    return "";
  };

  return (
    <React.Fragment>
      <div className={classNames("topBar d-flex gap-3 justify-content-between mb-4", { "d-none": isFullScreen })}>
        <div className="headingSide d-flex align-items-center">
          <strong className="d-flex align-items-center">
            <ReactSVG src={oneClickBlogIcon.clickOneClickBlog} useRequestCache /> PDF-to-Article Quick Writer
          </strong>
        </div>
        <div className="backtoHome d-flex gap-3 justify-content-between align-items-center text-center">
          <div className="backBtn">
            <Link to={"/pdf-to-article"} className="bg-transparent border-0">
              <GoArrowLeft /> Back to home
            </Link>
          </div>
        </div>
      </div>
      <div className="maingenrateBlock bg-white p-0 mb-2 mt-0">
        <div className="border-radius-12 bg-white AIWriterBlog">
          <div className="blogWriterArea border-0 d-flex flex-row">
            <div className={classNames("sideBarB", { "d-none": isFullScreen })}>
              <Sidebar setSelectedCurrentTab={setSelectedCurrentTab} />
            </div>

            {selectedCurrentTab === "aiOverview" ? (
              <AiOverviewTabComponent
                articleType={articleType} articleId={generatedFullArticle?.articleId}
                articleHtml={editContent?.article_html} isFullScreen={isFullScreen}
              />
            ) : (
              <div className={`blogWriterBlock position-relative`}>
                <div className="navItemsBlock">
                  <NavItems
                    generatedFullArticle={generatedFullArticle} handleAutoFactChecker={handleAutoFactChecker} getFullData={getFullData}
                    handleSaveArticleOrPostText={handleSaveArticleOrPostText} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                    selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
                    handleCopyHtmlArticleText={handleCopyHtmlArticleText} handleShareSocialMediaPost={handleShareSocialMediaPost}
                    handleSaveReviewArticleText={handleSaveReviewArticleText} edit={editContent}
                  />
                </div>
                <div className={classNames(
                  "editableTextArea d-flex overflow-hidden position-relative", selectedCurrentTab,
                  { removeSideBar: toggleRightSideBar && ['longArticle', "article", "faqSchema", "reviewArticle"].includes(selectedCurrentTab) },
                  { 'faqTab f-50': selectedCurrentTab === "faqSchema" }, { 'f-50': selectedCurrentTab === "googleAdCopy" }
                )}>
                  {selectedCurrentTab === "article" ? (
                    <React.Fragment>
                      {toggle ? (
                        <HtmlViewer
                          setToggle={setToggle} value={editContent?.article_html} html={generatedFullArticle?.article_html}
                          onEditorChange={(e) => setEditContent((pre) => ({ ...pre, "article_html": e }))}
                        />
                      ) : (
                        <div className="textEditerArea">
                          <MainContent
                            column={"article_html"} setToggle={setToggle} toggle={toggle} htmlText={generatedFullArticle?.article_html} setEdit={setEditContent}
                            value={editContent?.article_html} finalHtmlText={generatedFullArticle?.article_html || ""} setHtmlText={() => { }}
                            editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                            articleId={generatedFullArticle?.articleId} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                          />
                        </div>
                      )}
                      <div className="toggleData">
                        <React.Fragment>
                          {contentScore && Object.keys(contentScore).length > 0 && isSeoScoreDisplay && generatedFullArticle?.article_html ? (
                            seoScoreMobile ? (
                              <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                                <Offcanvas.Header closeButton>
                                  <Offcanvas.Title>
                                    SEO score <BsInfoCircleFill className="infoIconSvg" data-tooltip-id="seo-score"
                                      data-tooltip-content="Valid only for articles generated in “English”"
                                      data-tooltip-place="bottom" />
                                    <Tooltip id="seo-score" style={{ borderRadius: "6px", fontSize: "11px", padding: "4px 12px", zIndex: 9999 }} />
                                  </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className="offcanvas_seo_score">
                                  <SeoScorePreview contentScore={contentScore} article={editContent?.article_html} col={12} show={show} />
                                </Offcanvas.Body>
                              </Offcanvas>
                            ) : (
                              <SeoScorePreview contentScore={contentScore} article={editContent?.article_html} col={12} show={show} />
                            )
                          ) : (
                            <div style={{ height: "100%", background: "var(--Black-B30)", borderRadius: "10px" }}></div>
                          )}
                        </React.Fragment>
                      </div>
                    </React.Fragment>
                  ) : selectedCurrentTab === "faqSchema" ? (
                    <React.Fragment>
                      {toggle ? (
                        <HtmlViewer
                          setToggle={setToggle} value={editContent?.FAQHTML} html={generatedFullArticle?.FAQHTML}
                          onEditorChange={(e) => setEditContent((pre) => ({ ...pre, "FAQHTML": e }))}
                        />
                      ) : (
                        <div className={`textEditerArea ${!editContent?.FAQHTML && 'notContent'}`}>
                          <MainContent
                            column={"FAQHTML"} setToggle={setToggle} toggle={toggle} htmlText={generatedFullArticle?.FAQHTML} setEdit={setEditContent}
                            value={editContent?.FAQHTML} finalHtmlText={generatedFullArticle?.FAQHTML || ""} setHtmlText={() => { }} articleId={generatedFullArticle?.articleId}
                            editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                          />
                        </div>
                      )}
                      <FaqSchemaArtical setEdit={setEditContent} edit={editContent} />
                    </React.Fragment>
                  ) : selectedCurrentTab === "factChecker" ? (
                    <FactCheckerPreview searchResponse={generatedFullArticle?.search_response} col={12} type="top3" />
                  ) : selectedCurrentTab === "reviewArticle" ? (
                    <ReviewTextEditor
                      isSelectedCurrentTab={selectedCurrentTab === "reviewArticle" ? true : false} articleType={articleType}
                      articleId={generatedFullArticle?.articleId} articleSubType={"Short Article"} sendToEditorForm={sendToEditorForm}
                      isReviewArticle={generatedFullArticle?.is_check_reviewer || 0} toggleRightSideBar={toggleRightSideBar}
                      handleSendArticleToReviewer={handleSendArticleToReviewer} setSendToEditorForm={setSendToEditorForm}
                      ref={reviewEditorChildRef}
                    />
                  ) : null}
                </div>
                {handleSideButton()}
              </div>
            )}
          </div>
        </div>

        <Modal className="" centered show={googleAdCopyInfo} onHide={() => setGoogleAdCopyInfo(false)}>
          <Modal.Body className="text-start">
            <div className="mt-3">
              <img src={GoogleAdStepImage} alt="" width={"100%"} />
            </div>
            <div className="mt-3">
              <button onClick={() => { setGoogleAdCopyInfo(false) }} type="button" className="addlly-primary ms-auto">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {postImagesModal && postImagesModal.open && (
          <div className="genrateAiImages">
            <MediaLibraryDrawer
              postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} editorRef={editorRef}
              postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
              articleType={articleType} userRes={userRes} articleId={generatedFullArticle?.articleId}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default SecondStepPdfArticleWriter;
