import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { GoArrowLeft } from "react-icons/go";
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlineChevronRight } from "react-icons/hi";
import Sidebar from "./Sidebar";
import classNames from "classnames";
import { AxiosApi } from "../../utility/axios";
import HtmlViewer from "../SeoBlogWriter/HtmlViewer";
import { getHistory } from "../../utility/apiService";
import { oneClickBlogIcon } from "../../Assets/Icons";
import { handleGetOrGenerateAllTypeImages } from "../../utility/hepler";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import GoogleAdStepImage from "../../Assets/Images/Social_media/google-ad-step.png"
import FactCheckerPreview from "../commonComponents/FactCheckerPreview";
import { handleShareSocialMediaTypePost } from "../../utility/common";
import NavItems from "../SeoBlogWriterNew/second-step/NavItems";
import MainContent from "../SeoBlogWriterNew/second-step/MainContent";
import FaqSchemaArtical from "../commonComponents/FaqSchemaArticle";
import LinkedinArticle from "../commonComponents/LinkedinArticle";
import FacebookArticle from "../commonComponents/FacebookArticle";
import TwitterArticle from "../commonComponents/TwitterArticle";
import InstagramArticle from "../commonComponents/InstagramArticle";
import GoogleAdCopy from "../commonComponents/GoogleAdCopy";
import SeoScorePreview from "../commonComponents/SeoScorePreview";
import MediaLibraryDrawer from "../commonComponents/MediaLibrary/MediaLibraryDrawer";
import { setGeneratedFullArticle, setHistoryData, setHistoryDataLength, setLoader, setTopResultsStep, setUserRes } from "../../store/reducer";
const beautify_html = require("js-beautify").html;

const SecondStepPdfArticleWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const userRes = useSelector(({ userRes }) => userRes);
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);

  const [searchParams] = useSearchParams();
  const [toggle, setToggle] = useState(false);
  const [URLSearchParamsFull] = useSearchParams();
  const isFullScreen = URLSearchParamsFull.get("full-screen");
  const [selectedCurrentTab, setSelectedCurrentTab] = useState(searchParams && searchParams.get('tab') ? searchParams.get('tab') : "article");
  const [postImagesModal, setPostImagesModal] = useState({ open: false, type: "", currentTab: "Upload Image" });
  const [postGeneratedImages, setPostGeneratedImages] = useState({});
  const [googleAdCopyInfo, setGoogleAdCopyInfo] = useState(false);
  const [selectedSubTab, setSelectedSubTab] = useState(searchParams && searchParams.get("subTab") ? searchParams.get("subTab") : "seoScore");
  const articleType = "1-Click Blog";
  const reviewEditorChildRef = useRef();
  const [toggleRightSideBar, setToggleRightSideBar] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [contentScore, setContentScore] = useState({});
  const [isSeoScoreDisplay, setIsSeoScoreDisplay] = useState(generatedFullArticle?.lan === "English" ? true : false);
  const [editContent, setEditContent] = useState({
    article_html: generatedFullArticle?.article_html,
    article_detail_html: generatedFullArticle?.article_detail_html,
    FAQHTML: generatedFullArticle?.FAQHTML, FAQschema: generatedFullArticle?.FAQschema,
    linkedIn_post: generatedFullArticle?.linkedIn_post, facebook_post: generatedFullArticle?.facebook_post,
    twitter_post: generatedFullArticle?.twitter_post, instagram_post: generatedFullArticle?.instagram_post,
    instagram_reels: generatedFullArticle?.instagram_reels, googleAdCopy: generatedFullArticle?.googleAdCopy,
  });

  const handleSetHtmlElementsOnArticle = () => {
    const Obj = {
      article_html: editContent.article_html, article_detail_html: editContent.article_detail_html,
      FAQHTML: editContent.FAQHTML, FAQschema: editContent.FAQschema,
      linkedIn_post: editContent.linkedIn_post, facebook_post: editContent.facebook_post,
      twitter_post: editContent.twitter_post, instagram_post: editContent.instagram_post,
      instagram_reels: editContent.instagram_reels, googleAdCopy: editContent.googleAdCopy,
    };

    if (generatedFullArticle && generatedFullArticle.article_html && generatedFullArticle.article_html.indexOf("<html>") !== -1) {
      const htmlStr = editContent.article_html.indexOf("<body>") !== -1 ? editContent.article_html.substring(editContent.article_html.indexOf("<body>") + 6, editContent.article_html.indexOf("</body>")) : editContent.article_html;
      Obj.article_html = `${generatedFullArticle.article_html.substring(0, generatedFullArticle.article_html.indexOf("<body>"))}
          <body> ${htmlStr} </body>
        </html>`.replaceAll("\n", "").replaceAll("\t", "");
    }

    if (generatedFullArticle && generatedFullArticle.article_detail_html && generatedFullArticle.article_detail_html.indexOf("<html>") !== -1) {
      const htmlStr1 = editContent.article_detail_html.indexOf("<body>") !== -1 ? editContent.article_detail_html.substring(editContent.article_detail_html.indexOf("<body>") + 6, editContent.article_detail_html.indexOf("</body>")) : editContent.article_detail_html;
      Obj.article_detail_html = `${generatedFullArticle.article_detail_html.substring(0, generatedFullArticle.article_detail_html.indexOf("<body>"))}
          <body> ${htmlStr1} </body>
        </html>`.replaceAll("\n", "").replaceAll("\t", "");
    }

    return Promise.resolve(Obj);
  };
  useEffect(() => {
    setToggleRightSideBar(false);
    setToggle(false);
    setTimeout(() => {
      setEditorKey(editorKey + 1);
    }, 500);
  }, [selectedCurrentTab])


  const handleSaveArticleOrPostText = async () => {
    dispatch(setLoader(true));

    const updateObj = await handleSetHtmlElementsOnArticle();
    dispatch(setGeneratedFullArticle({ ...generatedFullArticle, ...updateObj }));

    await AxiosApi.post(`/api/articleHistory/update`, {
      id: generatedFullArticle?.articleId,
      currentField: selectedCurrentTab,
      ...updateObj
    }).then(async () => {
      dispatch(setLoader(false));
      dispatch(setTopResultsStep(3));
      toast.success("Saved successfully", { id: "Toast-01" });
    }).catch(function (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    });
  };

  const handleCopyHtmlArticleText = (e, text, msgType, copyType) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      if (copyType === "html") {
        copy(beautify_html(text, options), { format: "text/html" });
      } else {
        navigator.clipboard.writeText(beautify_html(text, options));
      }

      if (msgType === "toast") {
        toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
      } else {
        e.target.innerText = "Copied Text";
        setTimeout(() => {
          e.target.innerText = "Copy Text";
        }, 4000);
      }
    }
  };

  const handleOnArticleChangeValue = async (name, value) => {
    setEditContent({ ...editContent, [name]: value });
  };

  const getFullData = async (name) => {
    try {
      if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels" || name === "AdCopyWrite") {
        if (Number(userRes.credits) < (userRes?.deductCredits?.["Social Media Post"] || 1)) {
          toast.error("Sorry, you don't have enough credits to perform this action.", { id: "Toast-01" });
          return false;
        }
      }

      dispatch(setLoader(true));
      const articleId = generatedFullArticle?.articleId ? generatedFullArticle.articleId : "";
      const payload = {
        pathParameters: { id: articleId }
      };

      if (name === "faq") {
        await invokeLambdaFunction("generateFAQSchema", payload);
      } else if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels") {
        payload.pathParameters.type = name.replace("_post", "");
        await invokeLambdaFunction("generateSocialPostForBlog", payload);
      } else if (name === "AdCopyWrite") {
        await invokeLambdaFunction("generateAdCopyWrite", payload);
      }

      const response = await AxiosApi.get(`/api/articleHistory/byId/${articleId}`);
      const responseData = response && response.data && response.data.data ? response.data.data : {};

      if (name === "linkedIn" || name === "facebook" || name === "twitter" || name === "instagram" || name === "instagram_reels" || name === "AdCopyWrite") {
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Social Media Post"] || 1) }));
      }

      dispatch(setGeneratedFullArticle({
        ...generatedFullArticle, article_detail_html: responseData?.article_detail_html,
        facebook_post: responseData?.facebook_post, facebook_post_img: responseData?.facebook_post_img,
        twitter_post: responseData?.twitter_post, twitter_post_img: responseData?.twitter_post_img,
        linkedIn_post: responseData?.linkedIn_post, linkedIn_post_img: responseData?.linkedIn_post_img,
        instagram_post: responseData?.instagram_post, instagram_post_img: responseData?.instagram_post_img,
        instagram_reels: responseData?.instagram_reels, googleAdCopy: responseData?.googleAdCopy,
        FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema,
        popular_hashtags: responseData?.popular_hashtags ? JSON.parse(responseData.popular_hashtags) : [],
      }));
      dispatch(setLoader(false));
      setEditContent({
        ...editContent, article_detail_html: responseData?.article_detail_html,
        facebook_post: responseData?.facebook_post, twitter_post: responseData?.twitter_post,
        linkedIn_post: responseData?.linkedIn_post, instagram_post: responseData?.instagram_post,
        instagram_reels: responseData?.instagram_reels, googleAdCopy: responseData?.googleAdCopy,
        FAQHTML: responseData?.FAQHTML, FAQschema: responseData?.FAQschema,
      });
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
    }
  };

  const handleGetAllImagesAndOpenModal = async (subType, html) => {
    try {
      const params = {
        html, type: articleType, subType, userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        topic: generatedFullArticle?.topic ? generatedFullArticle.topic : "",
        keyword: generatedFullArticle?.keyword ? generatedFullArticle.keyword : "",
      };

      const queryParams = new URLSearchParams({
        type: articleType, subType, userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
      }).toString();

      const paramObj = { setPostImagesModal, postGeneratedImages, setPostGeneratedImages, articleType, subType, userRes, params, queryParams };
      await handleGetOrGenerateAllTypeImages(paramObj);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    } catch (error) {
      console.log('✌️error --->', error);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
    }
  };

  const handleShareSocialMediaPost = async () => {
    if (selectedCurrentTab === "linkedIn") {
      const uploadImage = generatedFullArticle?.linkedIn_post_img || "";
      const params = {
        postType: "LinkedIn", text: editContent?.linkedIn_post || "",
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : "",
        keyword: generatedFullArticle?.keyword || "", userId: userRes.id, dispatch,
        image: uploadImage ? JSON.stringify({ data: uploadImage }) : "",
      };
      await handleShareSocialMediaTypePost(params);
    }
  };

  const handleAutoFactChecker = async () => {
    dispatch(setLoader(true));
    const payload = {
      body: JSON.stringify({
        userId: userRes.id,
        articleId: generatedFullArticle?.articleId ? generatedFullArticle.articleId : ""
      }),
    };
    const response = await invokeLambdaFunction("generateFullArticleFactCheckerV2", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      if (response && response.data) {
        const data = JSON.parse(response.data);
        let getCurrArticle = editContent?.article_html;

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const finalText = element.textWithUrl ? element.textWithUrl.replaceAll("\n", "").replace(/\s+/g, ' ') : "";
          getCurrArticle = getCurrArticle.replace(element.text, finalText);
        }
        setEditContent({ ...editContent, article_html: getCurrArticle });
      }
    } else {
      toast.error(response?.error, { id: "Toast-01" });
    }
  };

  const handleOnChangeWriterTabOrSubTab = (tab) => {
    let tabVal = selectedCurrentTab;
    if (tab && tab !== "") {
      tabVal = tab;
    }

    setSelectedCurrentTab(tabVal);

    let queryParams = `tab=${tabVal}`;
    navigate(`/pdf-to-article/${generatedFullArticle?.articleId}?${queryParams}`);
  };

  const handleSaveReviewArticleText = () => {
    if (reviewEditorChildRef.current) {
      reviewEditorChildRef.current.handleSaveArticleTextAndNewVersion();
    }
  };

  const handleSetSelectedHashtagsOnPosts = (e, tag, type, isExist) => {
    const post = type === "fb" ? "facebook_post" : type === "tw" ? "twitter_post" : type === "ig" ? "instagram_post" : "linkedIn_post";
    const index = editContent[post]?.indexOf(tag);
    if (!isExist) {
      setEditContent({ ...editContent, [post]: editContent[post] + ` <span class="hash-tag-text">${tag}</span> ` });
    } else {
      setEditContent({ ...editContent, [post]: editContent[post]?.replace(` <span class="hash-tag-text">${tag}</span> `, "") });
    }
  };

  return (
    <React.Fragment>
      <div className={classNames("topBar d-flex gap-3 justify-content-between mb-4", { "d-none": isFullScreen })}>
        <div className="headingSide d-flex align-items-center">
          <strong className="d-flex align-items-center">
            <ReactSVG src={oneClickBlogIcon.clickOneClickBlog} useRequestCache /> PDF-to-Article Quick Writer
          </strong>
        </div>
        <div className="backtoHome d-flex gap-3 justify-content-between align-items-center text-center">
          <div className="backBtn">
            <Link to={"/pdf-to-article"} className="bg-transparent border-0">
              <GoArrowLeft /> Back to home
            </Link>
          </div>
        </div>
      </div>
      <div className="maingenrateBlock bg-white p-0 mb-2 mt-0">
        <div className="border-radius-12 bg-white AIWriterBlog">
          <div className="blogWriterArea border-0 d-flex flex-row">
            <div className={classNames("sideBarB", { "d-none": isFullScreen })}>
              <Sidebar setSelectedCurrentTab={setSelectedCurrentTab} />
            </div>
            <div className={`blogWriterBlock position-relative`}>
              <div className="navItemsBlock">
                <NavItems
                  generatedFullArticle={generatedFullArticle} handleAutoFactChecker={handleAutoFactChecker} getFullData={getFullData}
                  handleSaveArticleOrPostText={handleSaveArticleOrPostText} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                  selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
                  handleCopyHtmlArticleText={handleCopyHtmlArticleText} handleShareSocialMediaPost={handleShareSocialMediaPost}
                  handleSaveReviewArticleText={handleSaveReviewArticleText} edit={editContent}
                />
              </div>
              <div className={classNames(
                "editableTextArea d-flex overflow-hidden position-relative", selectedCurrentTab,
                { removeSideBar: toggleRightSideBar && ['longArticle', "article", "faqSchema", "reviewArticle"].includes(selectedCurrentTab) },
                { 'faqTab f-50': selectedCurrentTab === "faqSchema" }, { 'f-50': selectedCurrentTab === "googleAdCopy" }
              )}>
                {selectedCurrentTab === "article" ? (
                  <React.Fragment>
                    {toggle ? (
                      <HtmlViewer
                        setToggle={setToggle} value={editContent?.article_html} html={generatedFullArticle?.article_html}
                        onEditorChange={(e) => setEditContent((pre) => ({ ...pre, "article_html": e }))}
                      />
                    ) : (
                      <div className="textEditerArea">
                        <MainContent
                          column={"article_html"} setToggle={setToggle} toggle={toggle} htmlText={generatedFullArticle?.article_html} setEdit={setEditContent}
                          value={editContent?.article_html} finalHtmlText={generatedFullArticle?.article_html || ""} setHtmlText={() => { }}
                          editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                          articleId={generatedFullArticle?.articleId} handleGetAllImagesAndOpenModal={handleGetAllImagesAndOpenModal}
                        />
                      </div>
                    )}
                    <div className="toggleData">
                      <React.Fragment>
                        {contentScore && Object.keys(contentScore).length > 0 && isSeoScoreDisplay && generatedFullArticle?.article_html ? (
                          <SeoScorePreview contentScore={contentScore} article={editContent?.article_html} col={12} />
                        ) : (
                          <div style={{ height: "100%", background: "var(--Black-B30)", borderRadius: "10px" }}></div>
                        )}
                      </React.Fragment>
                    </div>
                  </React.Fragment>
                ) : selectedCurrentTab === "faqSchema" ? (
                  <React.Fragment>
                    {toggle ? (
                      <HtmlViewer
                        setToggle={setToggle} value={editContent?.FAQHTML} html={generatedFullArticle?.FAQHTML}
                        onEditorChange={(e) => setEditContent((pre) => ({ ...pre, "FAQHTML": e }))}
                      />
                    ) : (
                      <div className={`textEditerArea ${!editContent?.FAQHTML && 'notContent'}`}>
                        <MainContent
                          column={"FAQHTML"} setToggle={setToggle} toggle={toggle} htmlText={generatedFullArticle?.FAQHTML} setEdit={setEditContent}
                          value={editContent?.FAQHTML} finalHtmlText={generatedFullArticle?.FAQHTML || ""} setHtmlText={() => { }} articleId={generatedFullArticle?.articleId}
                          editorRef={editorRef} editorKey={editorKey} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                        />
                      </div>
                    )}
                    <FaqSchemaArtical setEdit={setEditContent} edit={editContent} />
                  </React.Fragment>
                ) : selectedCurrentTab === "linkedIn" ? (
                  <LinkedinArticle
                    setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setEditContent} value={editContent?.linkedIn_post} getFullData={getFullData}
                    generatedFullArticle={generatedFullArticle} edit={editContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                    setUploadFileModal={() => handleGetAllImagesAndOpenModal("LinkedIn Post", generatedFullArticle?.linkedIn_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                  />
                ) : selectedCurrentTab === "facebook" ? (
                  <FacebookArticle
                    setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setEditContent} value={editContent?.facebook_post} getFullData={getFullData}
                    generatedFullArticle={generatedFullArticle} edit={editContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                    setUploadFileModal={() => handleGetAllImagesAndOpenModal("Facebook Post", generatedFullArticle?.facebook_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                  />
                ) : selectedCurrentTab === "twitter" ? (
                  <TwitterArticle
                    setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setEditContent} value={editContent?.twitter_post} getFullData={getFullData}
                    generatedFullArticle={generatedFullArticle} edit={editContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                    setUploadFileModal={() => handleGetAllImagesAndOpenModal("Twitter Post", generatedFullArticle?.twitter_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                  />
                ) : selectedCurrentTab === "instagram" ? (
                  <InstagramArticle
                    setPostImagesModal={setPostImagesModal} postImagesModal={postImagesModal} setEdit={setEditContent} value={editContent?.instagram_post} getFullData={getFullData}
                    generatedFullArticle={generatedFullArticle} edit={editContent} handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes}
                    setUploadFileModal={() => handleGetAllImagesAndOpenModal("Instagram Post", generatedFullArticle?.instagram_post)} handleSetSelectedHashtagsOnPosts={handleSetSelectedHashtagsOnPosts}
                  />
                ) : selectedCurrentTab === "googleAdCopy" ? (
                  <GoogleAdCopy handleCopyHtmlArticleText={handleCopyHtmlArticleText} userRes={userRes} edit={editContent} setEdit={setEditContent} selectedTab={selectedCurrentTab} generatedFullArticle={generatedFullArticle} />
                ) : selectedCurrentTab === "factChecker" ? (
                  <FactCheckerPreview searchResponse={generatedFullArticle?.search_response} col={12} type="top3" />
                ) : null}
                {/* {selectedCurrentTab !== "linkedinCarousel" && selectedCurrentTab !== "reviewArticle" && (
                  <div className="d-flex justify-content-center mt-4 gap-3">
                    <button
                      type="button" className="addlly-outline"
                      onClick={() => {
                        const scrollWrapper = document.getElementById('scroll-wrapper');
                        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
                        dispatch(setTopResultsStep(1)); dispatch(setGeneratedFullArticle({}));
                        dispatch(setHistoryData([])); dispatch(setHistoryDataLength(0));
                        dispatch(getHistory(userRes?.id, 10, 0, "artical_history", "PDF"));
                        navigate(`/pdf-to-article`);
                      }}
                    >
                      <IoIosArrowBack /> <span>Back</span>
                    </button>
                    <div className="d-flex gap-3">
                      <button type="button" className="addlly-primary" onClick={() => handleSaveArticleOrPostText()}>
                        <span>Save</span> <HiOutlineChevronRight />
                      </button>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <Modal className="" centered show={googleAdCopyInfo} onHide={() => setGoogleAdCopyInfo(false)}>
          <Modal.Body className="text-start">
            <div className="mt-3">
              <img src={GoogleAdStepImage} alt="" width={"100%"} />
            </div>
            <div className="mt-3">
              <button onClick={() => { setGoogleAdCopyInfo(false) }} type="button" className="addlly-primary ms-auto">
                <span>Close</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {postImagesModal && postImagesModal.open && (
          <div className="genrateAiImages">
            <MediaLibraryDrawer
              postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} editorRef={editorRef}
              postGeneratedImages={postGeneratedImages} setPostGeneratedImages={setPostGeneratedImages}
              articleType={articleType} userRes={userRes} articleId={generatedFullArticle?.articleId}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default SecondStepPdfArticleWriter;
