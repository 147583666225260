import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Swal from "sweetalert";
import { useNavigate } from "react-router";
import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDataTable from "../Components/ui/DataTable";
import TableLoader from "../Components/ui/TableLoader";
import { setLoader, setUserRes } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { tableDateFormatter } from "../utility/common";
import { SortArrow, sorting } from "../utility/common";
import { articleHistoryIcons, ICONS } from "../Assets/Icons";

const validationSchema = Yup.object().shape({
  credits: Yup.number('Addlly credit must be a number')
    .typeError("Addlly credit must be a number")
    .positive("Addlly credit can't start with a minus")
    .min(0, "Addlly credit must be a number greater than 0")
    .integer("Addlly credit can't include a decimal point").optional(),
});

const BuyMoreCredits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [creditsRate, setCreditsRate] = useState(userRes && userRes?.credits_rate ? userRes.credits_rate : 10);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  const { register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  const watchAllFields = watch();
  if (watchAllFields.credits < 0) {
    setValue("credits", 0);
  }

  useEffect(() => {
    getRequestMoreCreditList();
    handleOpenBuyMoreCreditPopup();
    setCreditsRate(Number(userRes?.credits_rate || 10));
  }, []);

  const handleOpenBuyMoreCreditPopup = () => {
    const val = localStorage.getItem("openBuyMoreCredit");
    if (!val || val !== 'true') {
      return false;
    }

    localStorage.removeItem("openBuyMoreCredit");
    handleShow();
  }

  const getRequestMoreCreditList = async () => {
    setTableLoader(true);
    const url = `/user/request-credit?userId=${userRes.id}`;
    AxiosApi.get(url).then((response) => {
      setData(response?.data);
      setTableLoader(false);
    }).catch((error) => {
      setTableLoader(false);
    });
  };

  const handleShow = () => {
    if (!userRes?.current_plan || userRes?.current_plan === "Starter Pack") {
      Swal({
        icon: "error",
        title: "Oops ...",
        button: "Choose Plans",
        text: "Please subscribe to basic or pro plan to purchase additional credits. Thanks!",
      }).then((result) => {
        navigate("/plans");
      });
    } else {
      setShow(true);
      reset({ credits: 0 });
    }
  };

  const handleClose = () => {
    setShow(false)
    reset({ credits: 0 });
  };

  const onSubmit = async (data) => {
    if (Number(data.credits) === 0) {
      return false;
    }

    try {
      dispatch(setLoader(true));
      const bodyData = {
        credits: data.credits,
        successUrl: `${window.location.origin}/buy-more-credits`,
        cancelUrl: `${window.location.origin}/buy-more-credits`,
        user_id: userRes.id,
        strip_customer_id: userRes?.stripe_customer_id,
        metadata: { credits: data.credits, name: "buy-more-credits" },
        payment_method: "card",
      };

      const response = await AxiosApi.post(`/api/buy-more-credits/stripe/checkout`, bodyData);
      if (response && response.data && response.data.success === 1) {
        handleClose();
        dispatch(setUserRes({ ...userRes, username: null }));
        window.location.assign(response.data.data.url);
      } else {
        handleClose();
        dispatch(setLoader(false));
        toast.error("Something went wrong. Please try again!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong", { id: "Toast-01" });
    }
  };

  const handleSort = (column, sortDirection) => {
    setPage(1); setData([]);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(data, column?.sortField, sortDirection);
    setData(newData);
  };

  const filteredData = useMemo(() => (
    data?.filter(item =>
      Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('').toLowerCase().includes(searchTxt?.split(' ').join('').toLowerCase()))
    )
  ), [data, sortField, searchTxt]);

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex px-3">Addlly credit {SortArrow(sortField, "title")}</div>,
      selector: (row) => row?.credits || "-",
      sortField: "credits",
      sortable: true,
    },
    {
      id: "total_amount",
      // name: <div className="d-flex px-2">Total Amount{SortArrow(sortField, "total_amount")}</div>,
      name: <div className="d-flex px-2">Total Amount</div>,
      selector: (row) => `$ ${row?.total_amount || "-"}`,
      // sortField: "total_amount",
      sortable: false,
    },
    {
      id: "created_at",
      name: <div className="d-flex px-1">Create On {SortArrow(sortField, "created_at")}</div>,
      selector: (row) => <div>{tableDateFormatter(row?.created_at)}</div>,
      sortField: "created_at",
      sortable: true,
    },
    {
      id: "status",
      name: <div className="d-flex">Status {SortArrow(sortField, "status")}</div>,
      selector: (row) => (
        <div className="d-flex justify-content-center align-items-center">
          <div className="dotStatus">
            <span className={`d-block`}
              style={{
                width: "8px", height: "8px", borderRadius: "50%",
                backgroundColor: row.status === "completed" ? "#84CC16" : "#EF4444",
              }}
            />
          </div>
          {row.status === "completed" ? "Success" : "Failed"}
        </div>
      ),
      sortField: "status",
      sortable: true,
    },
  ]), [sortField]);

  return (
    <React.Fragment>
      <div className="profile-page">
        <div className="top-left-circle"></div>
        <div className="top-right-circle"></div>
        <div className="topBar">
          <div className="d-flex align-items-center justify-content-between gap-3">
            <img src={ICONS.ClockHistory} alt=""></img>
            <h1 className="mb-0">Credit Purchase History</h1>
          </div>
          <div className="d-flex gap-4">
            <button type="button" className="btn back-button" onClick={() => navigate("../dashboard")}>
              <BsArrowLeft />
              Back
            </button>
            <button className="addlly-primary w-auto px-3 py-2" onClick={handleShow} style={{ fontSize: "14px", zIndex: 1, fontWeight: 500 }}>
              <FaPlus className="" /> Buy More
            </button>
          </div>
        </div>
        <div className="accountCardContainer">
          <div className="accountCard" style={{ position: "relative" }}>
            <div className="cardBody">
              <div className="filters" style={{ minHeight: "0px" }}>
                <div className="historyTopBar d-flex justify-content-end bg-white align-items-center gap-x-3 flex-wrap p-0">
                  <div className="searchField position-relative">
                    <input type="search" placeholder="Type to search" onChange={(e) => setSearchTxt(e.target.value)} />
                    <div className="searchIcon position-absolute">
                      <BsSearch />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 flex-column tableHeading mt-3">
                <div className="historyTableBlock mt-2">
                  <CustomDataTable
                    progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
                    columns={columns} totalCount={filteredData?.length} pagination={true} data={filteredData}
                    itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
                    onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
                    handleTableRowClick={() => { }} clearSelectedRows={() => { }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="buy-credits-modal" centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>
          <div className="modal-header-content">
            <div className="header-title">Buy More Credits</div>
            <button className="btn close-btn" onClick={() => handleClose()}>
              <AiOutlineClose />
            </button>
          </div>
          <div className="addllyFormWrap w-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-4">
                <label>Addlly Credits ( $ {creditsRate} Per credit )</label>
                <input
                  name="credits" type="number" {...register("credits")}
                  placeholder="Insert no of addlly credits buy" defaultValue={0} step={1}
                  className={`addllyForm-control ${errors.credits ? "is-invalid" : ""}`}
                  onWheel={(e) => { e.preventDefault(); e.target.blur(); }}
                  onKeyDown={(event) => {
                    if (
                      (event.key >= '0' && event.key <= '9') || event.key === 'Backspace' ||
                      event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight'
                    ) {
                      return event;
                    } else {
                      event.preventDefault();
                    }
                  }}
                />
                <div className="invalid-feedback">{errors.credits?.message}</div>
              </div>
              <hr />
              <div>
                <h4 className="fw-normal">Summary</h4>
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Credits</th>
                      <th>Price</th>
                      <th className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-50">Addlly Credits</td>
                      <td>{watchAllFields.credits || 0}</td>
                      <td>{creditsRate}</td>
                      <td className="text-end fw-bold">{((watchAllFields.credits * creditsRate) || 0)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Total Amount</td>
                      <td className="text-end fw-bold" style={{ whiteSpace: "nowrap" }}>
                        $ {(watchAllFields.credits * creditsRate) || 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <button
                className="addlly-primary" type="submit" variant="primary"
                disabled={(Number(watchAllFields.credits) === 0) || (errors && Object.keys(errors)?.length > 0)}
              >
                Buy Credits
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default BuyMoreCredits

const NoDataFound = () => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>History is not available yet!</p>
      </div>
    </div>
  );
};
