import React, { memo, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { IoIosList } from "react-icons/io";
import { BsImages } from "react-icons/bs";
import { FaRegSave } from "react-icons/fa";
import { BsArrowsFullscreen } from "react-icons/bs";
import { LuCheckCircle } from "react-icons/lu";
import { MdModelTraining } from "react-icons/md";
import { BsArrowsAngleContract, BsInfoCircleFill, BsStars } from "react-icons/bs";
import { BiCarousel } from "react-icons/bi";
import { FaLinkedin } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import DropdownMenu from "../../ui/Dropdown";
import { AxiosApi } from "../../../utility/axios";
import { setGeneratedFullArticle, setLoader } from "../../../store/reducer";
import useScreenSize from "../../../hooks/useScreenSize";
import { copyIcon } from "../../../Assets/Icons";
import VersionHistory from "../../commonComponents/VersionHistory";

const menus = [
  { name: "Article", value: "article", subTab: "seoScore" },
  // { name: "Long Article", value: "longArticle", subTab: "seoScore" },
  { name: "FAQs & Schema Markup", value: "faqSchema", subTab: "" },
  { name: "LinkedIn Post", value: "linkedIn", subTab: "postPreview" },
  { name: "Facebook Post", value: "facebook", subTab: "postPreview" },
  { name: "X (Twitter) Post", value: "twitter", subTab: "postPreview" },
  { name: "Instagram Post", value: "instagram", subTab: "postPreview" },
  { name: "GoogleAd Copy", value: "googleAdCopy", subTab: "postPreview" },
  { name: "Fact Checker", value: "factChecker", subTab: "" },
  { name: "Review Article", value: "reviewArticle", subTab: "comments" },
  // { name: "Chart", value: "chart", subTab: "" },
  { name: "Refund Requests", value: "refundRequests", subTab: "" },
];

export default memo(function NavItems(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const userRes = useSelector(({ userRes }) => userRes);
  const isFullScreen = searchParams.get("full-screen");
  const selectedTab = searchParams.get("tab") || "article";
  const subTab = searchParams.get("subTab") || "seoScore";
  const {
    handleAutoFactChecker, handleSaveArticleOrPostText, generatedFullArticle, setShowLongArticle, getFullData,
    showLongArticle, handleGetAllImagesAndOpenModal, selectedSubTab, setSelectedSubTab, articleType, edit,
    handleShareSocialMediaPost, setPostImagesCarousel, handleSetRestoredOldVersionText, handleCopyHtmlArticleText,
    setSendToEditorForm, handleSaveReviewArticleText,
  } = props

  const screenSize = useScreenSize();
  const { articleId, keyword, AI_type, generated_topic, meta_title, meta_dec } = generatedFullArticle;
  const [currentActiveTab, setCurrentActiveTab] = useState({ currentTab: "", imageSubType: "", versionSubType: "" });
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [showFeedbackModel, setShowFeedbackModel] = useState({ open: false, feedback: "" });

  const checkTab = useMemo(() => (["article", "longArticle", "faqSchema", "facebook", "linkedIn", "twitter", "instagram", "googleAdCopy"]), []);

  useEffect(() => {
    let currentTab = "", imageSubType = "", versionSubType = "";
    if (selectedTab === "article") {
      currentTab = "article_html"; imageSubType = "Short Article Images"; versionSubType = "Short Article";
      // } else if (selectedTab === "longArticle") {
      //   currentTab = "article_detail_html"; imageSubType = "Long Article Images"; versionSubType = "Long Article";
    } else if (selectedTab === "faqSchema") {
      currentTab = "FAQHTML"; imageSubType = ""; versionSubType = "FAQ and Schema Markup";
    } else if (selectedTab === "linkedIn") {
      currentTab = "linkedIn_post"; imageSubType = "LinkedIn Post"; versionSubType = "LinkedIn Post";
    } else if (selectedTab === "facebook") {
      currentTab = "facebook_post"; imageSubType = "Facebook Post"; versionSubType = "Facebook Post";
    } else if (selectedTab === "twitter") {
      currentTab = "twitter_post"; imageSubType = "Twitter Post"; versionSubType = "Twitter Post";
    } else if (selectedTab === "instagram") {
      currentTab = "instagram_post"; imageSubType = "Instagram Post"; versionSubType = "Instagram Post";
    } else if (selectedTab === "googleAdCopy") {
      currentTab = "googleAdCopy"; imageSubType = ""; versionSubType = "Google Ad Copy";
    }

    handleTab(currentTab, imageSubType, versionSubType);
    if (selectedSubTab && selectedTab && checkTab.includes(selectedTab)) {
      setSelectedVersionItem({});
      getArticleAllVersionHistory(versionSubType);
    };
  }, [checkTab, selectedSubTab, selectedTab]);

  const handleFullScreen = () => {
    if (isFullScreen) {
      setSearchParams(selectedTab ? "tab=" + selectedTab + "&subTab=" + subTab : "");
    } else {
      setSearchParams(selectedTab ? "tab=" + selectedTab + "&subTab=" + subTab + "&full-screen=true" : "full-screen=true");
    }
  };

  const onTabSelect = (option) => {
    setSearchParams(isFullScreen ? ("tab=" + option.value + (option.subTab && "&subTab=" + option.subTab) + "&full-screen=" + isFullScreen)
      : ("tab=" + option.value + (option.subTab && "&subTab=" + option.subTab)));
  };

  const handleTab = (currentTabValue, imageSubTypeValue, versionSubTypeValue) => {
    setCurrentActiveTab(pre => ({ ...pre, currentTab: currentTabValue, imageSubType: imageSubTypeValue, versionSubType: versionSubTypeValue }));
  }

  const handleImageLibrary = () => {
    if (
      currentActiveTab.imageSubType && currentActiveTab.imageSubType !== "" &&
      generatedFullArticle[currentActiveTab.currentTab] && generatedFullArticle[currentActiveTab.currentTab] !== ""
    ) {
      handleGetAllImagesAndOpenModal(currentActiveTab.imageSubType, generatedFullArticle[currentActiveTab.currentTab]);
    }
  }

  const handleCopy = (data) => {
    toast.success("Text has been copied to clipboard.");
    copy(data);
  };

  const getArticleAllVersionHistory = async (versionSubType) => {
    try {
      if (!articleId || !versionSubType) {
        return false;
      }

      setVersionHistory([]);
      const params = `articleId=${articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  }

  const getGenerateButton = () => {
    const buttonClassNames = (tab) => classNames("blogButton border-0", { "primary-btn": !generatedFullArticle?.[tab] });
    if (selectedTab === "article") {
      return (
        <button
          type="button" onClick={() => setShowFeedbackModel({ open: true, type: "shortArticle" })} className={"blogButton border-0"}
          disabled={!generatedFullArticle?.article_regenerate_left}
        >
          <BsStars /> Re-Generate ( {generatedFullArticle?.article_regenerate_left || 0} / 3 )
        </button>
      );
    } else if (selectedTab === "longArticle") {
      return (
        <React.Fragment>
          <button type="button" className={buttonClassNames("article_detail_html")} onClick={() => { getFullData("longArticle", generatedFullArticle?.article_detail_html ? 1 : 0); setShowLongArticle(false) }}>
            <BsStars /> {!generatedFullArticle?.article_detail_html ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.article_detail_html ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["1-Click Blog"] || 2} addlly credits`}
          />
        </React.Fragment>
      );
    } else if (selectedTab === "faqSchema") {
      return (
        <React.Fragment>
          {generatedFullArticle?.FAQHTML && generatedFullArticle?.FAQHTML !== "" ? (
            <button
              type="button" onClick={() => setShowFeedbackModel({ open: true, type: "faq" })} className={"blogButton border-0"}
              disabled={!generatedFullArticle?.faq_regenerate_left}
            >
              <BsStars /> Re-Generate ( {generatedFullArticle?.faq_regenerate_left || 0} / 3 )
            </button>
          ) : (
            <button type="button" onClick={() => getFullData("faq", false)} className={buttonClassNames("FAQHTML")}>
              <BsStars /> Generate
            </button>
          )}
        </React.Fragment>
      );
    } else if (selectedTab === "linkedIn") {
      return (
        <React.Fragment>
          <button
            type="button" className={buttonClassNames("linkedIn_post")}
            onClick={() => {
              if (generatedFullArticle?.linkedIn_post) { setShowFeedbackModel({ open: true, type: "linkedIn" }); }
              else { getFullData("linkedIn", generatedFullArticle?.linkedIn_post ? true : false); }
            }}
          >
            <BsStars /> {!generatedFullArticle?.linkedIn_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.linkedIn_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (selectedTab === "facebook") {
      return (
        <React.Fragment>
          <button
            type="button" className={buttonClassNames("facebook_post")}
            onClick={() => {
              if (generatedFullArticle?.facebook_post) { setShowFeedbackModel({ open: true, type: "facebook" }); }
              else { getFullData("facebook", generatedFullArticle?.facebook_post ? true : false); }
            }}
          >
            <BsStars /> {!generatedFullArticle?.facebook_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.facebook_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (selectedTab === "twitter") {
      return (
        <React.Fragment>
          <button
            type="button" className={buttonClassNames("twitter_post")}
            onClick={() => {
              if (generatedFullArticle?.twitter_post) { setShowFeedbackModel({ open: true, type: "twitter" }); }
              else { getFullData("twitter", generatedFullArticle?.twitter_post ? true : false); }
            }}
          >
            <BsStars /> {!generatedFullArticle?.twitter_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.twitter_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (selectedTab === "instagram") {
      return (
        <React.Fragment>
          <button
            type="button" className={buttonClassNames("instagram_post")}
            onClick={() => {
              if (generatedFullArticle?.instagram_post) { setShowFeedbackModel({ open: true, type: "instagram" }); }
              else { getFullData("instagram", generatedFullArticle?.instagram_post ? true : false); }
            }}
          >
            <BsStars /> {!generatedFullArticle?.instagram_post ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.instagram_post ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    } else if (selectedTab === "googleAdCopy") {
      return (
        <React.Fragment>
          <button
            type="button" className={buttonClassNames("googleAdCopy")}
            onClick={() => {
              if (generatedFullArticle?.googleAdCopy) { setShowFeedbackModel({ open: true, type: "AdCopyWrite" }); }
              else { getFullData("AdCopyWrite", generatedFullArticle?.googleAdCopy ? true : false); }
            }}
          >
            <BsStars /> {!generatedFullArticle?.googleAdCopy ? "Generate" : "Re-Generate"}
          </button>
          <BsInfoCircleFill
            className="text-decoration-none outline-0 ms-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom"
            data-tooltip-content={`${!generatedFullArticle?.googleAdCopy ? "Generate" : "Re-Generate"} will utilize ${userRes?.deductCredits?.["Social Media Post"] || 1} Addlly credit`}
          />
        </React.Fragment>
      )
    }
  };

  const handleImageLibraryButton = () => {
    if (["linkedIn"].includes(selectedTab)) {
      return (
        <div className="d-flex justify-content-center align-content-center gap-2">
          {/* <button
            onClick={() => setPostImagesCarousel(pre => ({ ...pre, open: !pre.open }))} type="button"
            className="blogButton border-0" disabled={!generatedFullArticle[currentActiveTab.currentTab]}
          >
            <BiCarousel className="fa-10x" /> Create Carousel
          </button> */}
          {selectedTab === "linkedIn" && (
            <button className="blogButton border-0" onClick={() => handleShareSocialMediaPost()}
              disabled={!generatedFullArticle[currentActiveTab.currentTab]}
            >
              <FaLinkedin className="fs-5" /> Share Post
            </button>
          )}
        </div>
      )
    } else if (["article", "longArticle"].includes(selectedTab)) {
      return (
        <button type="button" onClick={handleImageLibrary} className="blogButton border-0"
          disabled={!generatedFullArticle[currentActiveTab.currentTab]}>
          <BsImages /> Image Library
        </button>
      )
    }
  };

  const handleTrainOrUnTrainArticle = () => {
    const id = generatedFullArticle?.articleId ? generatedFullArticle.articleId : "";
    const type = generatedFullArticle?.is_trained ? "unTrain" : "train";

    Swal({
      title: "Are you sure?", text: `Do you want to ${type} the article for 1-Click Blog?`, icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: type === 'train' ? "Use article for training" : "Remove article for training" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));
          const payload = { type: type, userId: userRes.id, articleType: "1-Click Blog" };
          const response = await AxiosApi.post(`/api/articleHistory/train-or-untrained/${id}`, payload);
          if (response && response.data && response.data.success === 1) {
            dispatch(setLoader(false)); toast.success(response.data.msg, { id: "Toast-01" });
            dispatch(setGeneratedFullArticle({
              ...generatedFullArticle, is_trained: generatedFullArticle?.is_trained ? 0 : 1
            }));
          } else {
            dispatch(setLoader(false));
            toast.error(response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          }
        } catch (error) {
          toast.error(error?.response?.data?.error || "Something went wrong, Please try again!", { id: "Toast-01" });
          dispatch(setLoader(false));
        }
      }
    });
  };

  return (
    <div>
      <div className={classNames(`navTopButton d-flex justify-content-between flex-wrap gap-4 ${selectedTab}`)}>
        <div className="leftButtons d-flex gap-3 flex-wrap align-items-center">
          {(isFullScreen || screenSize?.width < 1280) && (
            <div className="d-flex gap-3 dropdown">
              <DropdownMenu
                options={menus}
                selectedOption={menus.filter((menu) => menu.value === selectedTab)[0]}
                label={"name"} className="border-0" placeholder={"Select tabs"}
                onSelect={onTabSelect}
              />
            </div>
          )}

          {selectedTab === 'factChecker' ? (
            <div className="d-flex justify-content-center align-items-center">
              <h4 className="auto-fact-heading">Addlly AI Fact Finder</h4>
              <BsInfoCircleFill className="infoIconSvg"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
                data-tooltip-content={`Our tool collates factually correct information from whitelisted resources.`}
              />
            </div>
          ) : (
            <React.Fragment>
              <div>
                <button className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <IoIosList /> Metadata
                </button>
              </div>
              {checkTab.includes(selectedTab) ? (
                <VersionHistory
                  versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                  handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} articleType={articleType}
                />
              ) : null}
              {selectedTab === "reviewArticle" ? (
                <React.Fragment>
                  <span className="line"></span>
                  <button type="button" className="blogButton border-0" data-bs-toggle="offcanvas" data-bs-target="#commentVersionHistory"
                    aria-controls="commentVersionHistory" onClick={() => onTabSelect({ value: "reviewArticle", subTab: "version" })}
                  >
                    Version History
                  </button>
                </React.Fragment>
              ) : null}
              {selectedTab === "article" && (
                <button type="button" className="blogButton border-0" onClick={() => handleTrainOrUnTrainArticle()}>
                  <MdModelTraining /> {generatedFullArticle.is_trained ? "Remove article for training" : "Use article for training"}
                </button>
              )}
            </React.Fragment>
          )}

          <div className="d-flex gap-3">
            <div className="regenrateBtn d-flex  align-items-center">{getGenerateButton()}</div>
          </div>
        </div>
        <div className="rightSideButton d-flex gap-2 flex-wrap">
          {handleImageLibraryButton()}

          {selectedTab === "article" && (
            <button className="blogButton border-0" onClick={handleAutoFactChecker}>
              <LuCheckCircle /> Auto Citation
            </button>
          )}
          {generatedFullArticle?.is_check_reviewer === 0 && selectedTab === "reviewArticle" && (
            <button className="blogButton border-0" onClick={() => setSendToEditorForm(true)}>
              <LuCheckCircle /> Submit for Review
            </button>
          )}
          {!['factChecker', 'reviewArticle', 'chart', 'refundRequests'].includes(selectedTab) ? (
            <button data-tooltip-id="save" data-tooltip-content="Save" data-tooltip-place="bottom"
              className="blogButton border-0 bg-black saveButton" onClick={handleSaveArticleOrPostText}
              disabled={!edit[currentActiveTab.currentTab]}
            >
              <FaRegSave /> {isFullScreen ? "Save" : ""}
            </button>
          ) : selectedTab === "reviewArticle" ? (
            <button data-tooltip-id="save" data-tooltip-content="Save" data-tooltip-place="bottom"
              className="blogButton border-0 bg-black saveButton" onClick={handleSaveReviewArticleText}
            >
              <FaRegSave /> {isFullScreen ? "Save" : ""}
            </button>
          ) : null}

          <button onClick={handleFullScreen} className="blogButton border-1 bg-white fulscreen">
            {!isFullScreen ? <BsArrowsFullscreen /> : <BsArrowsAngleContract />}
          </button>
          <Tooltip id="save" style={{ borderRadius: "6px", fontSize: "13px", padding: "4px 12px", zIndex: 9999 }} />
        </div>
      </div>

      {/* Meta Data */}
      <div className="offcanvas offcanvas-end " tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {'Blog’s'} Metadata
          </h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>What do you want to write about?</p>
              <span className=" ">{generatedFullArticle?.topic}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(generatedFullArticle?.topic)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>Keyword</p>
              <span className=" ">{keyword}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(keyword)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="blogMetaInner d-flex justify-content-between gap-2 align-items-end">
            <div className="textBlog">
              <p>AI Model</p>
              <span className=" ">{AI_type}</span>
            </div>
            <div className="copyIcon cursor-pointer">
              <img
                src={copyIcon} alt="copy" onClick={() => handleCopy(AI_type)} className="text-info"
                data-tooltip-id={"my-tooltip"} data-tooltip-place="bottom" data-tooltip-content={`Copy`}
              />
            </div>
          </div>
          <div className="offcanvas-header p-0 m-0 my-3"></div>

          <div className="blogMetaInner">
            <p><BsStars /> Headline generated</p>
            <span className=" ">{generated_topic}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta title generated</p>
            <span className=" ">{meta_title}</span>
          </div>
          <div className="blogMetaInner">
            <p><BsStars /> Meta description generated</p>
            <span className=" ">{meta_dec}</span>
          </div>
        </div>
      </div>

      {showFeedbackModel && showFeedbackModel.open && (
        <Modal size="lg" centered show={showFeedbackModel.open} onHide={() => setShowFeedbackModel({ open: false })}>
          <Modal.Body className="text-start p-0 overflow-auto" style={showFeedbackModel.article ? { height: "90vh" } : {}}>
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => {
                  getFullData(showFeedbackModel?.type, true, showFeedbackModel?.feedback);
                  setShowFeedbackModel({ open: false });
                }}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={showFeedbackModel?.feedback}
                    onChange={(e) => setShowFeedbackModel({ ...showFeedbackModel, feedback: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal className="" centered show={showLongArticle} onHide={() => setShowLongArticle(false)}>
        <Modal.Body className="text-start">
          <span className="my-3 fw-normal long-article">
            {!generatedFullArticle?.article_detail_html ? `Generating the Long Article will deduct 1 credit from your account. It may not be regenerated.`
              : `Re-generating the article will incur a charge of 1 credit. However, there's a possibility that the regenerated content may not differ from the original.
             Please be aware of this before opting for regeneration.`}
          </span>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <button onClick={() => setShowLongArticle(false)} type="button" className="btn btn-danger">
            <span>Close</span>
          </button>
          <button onClick={() => { getFullData("longArticle", generatedFullArticle?.article_detail_html ? 1 : 0); setShowLongArticle(false) }} type="button" className="btn btn-primary me-3">
            <span> <BsStars /> {!generatedFullArticle?.article_detail_html ? "Generate" : "Re-Generate"}</span>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
})
