import React, { useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { setSocialMediaPostHistory, setSocialMediaPostStep, setSocialMediaStepsInfo } from "../../store/reducer";
import AccessDenied from "../AccessDenied";
import { BsArrowRight } from "react-icons/bs";
import { dashboardIcon } from "../../Assets/Icons";

const FirstStepSocialMediaPost = () => {
  const dispatch = useDispatch();
  const socialMediaPostInfo = useSelector(({ socialMediaPostInfo }) => socialMediaPostInfo);
  const { userRes, currentPlan } = useSelector(({ userRes, currentPlan }) => ({ userRes, currentPlan }));
  const [selectedCard, setSelectedCard] = useState(socialMediaPostInfo?.PostType || "");

  const handleNext = (selectedType) => {
    if (Number(userRes.social_credit) <= 0) {
      toast.error("You have used all your social credits.", { id: "Toast-01" });
      return;
    }

    if (selectedType) {
      dispatch(setSocialMediaPostHistory({}));
      dispatch(setSocialMediaStepsInfo({
        ...socialMediaPostInfo, PostType: selectedType,
        stepDone: socialMediaPostInfo.stepDone < 2 ? 2 : (socialMediaPostInfo?.stepDone || 2)
      }));
      dispatch(setSocialMediaPostStep(2));
    } else {
      toast.error("Please Select Social Media Post Type.", { id: "Toast-01" });
    }
  }

  const handleOnChangeCardType = (val) => {
    setSelectedCard(val);
  }

  return (
    <div className="addllyFormWrap">
      {currentPlan && currentPlan.linkedin_post === 0 && currentPlan.facebook_post === 0 && currentPlan.twitter_post === 0 && currentPlan.instagram_post === 0 ? (
        <AccessDenied text={"Social media post writer"} />
      ) : (
        <div>
          <div className="selectBlock d-flex flex-wrap gap-3 justify-content-between mx-2 mx-md-auto">
            <div>
              <b>Select social media post type </b>
              <span>(Select any 1)</span>
            </div>
            <button onClick={() => handleNext(selectedCard)} disabled={!selectedCard} type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            >
              Start Writing <BsArrowRight />
            </button>
          </div>
          <div className={`social-media-images ${socialMediaPostInfo?.stepDone > 2 ? "not-selectable" : ""}`}>
            {currentPlan && currentPlan.linkedin_post > 0 && (
              <div className={`social-media-card ${selectedCard === "LinkedIn" ? "active" : ""}`} onClick={() => { handleOnChangeCardType("LinkedIn") }}>
                <div className={`radioBtn`}>
                  <div className={`radioBtnIn`}></div>
                </div>
                <div className="imageFrame frameLindekdin">
                  <FaLinkedinIn className="lnsvg" />
                </div>
                <h5 className="card-title">Generate a <b>LinkedIn Post</b></h5>
                <p className="card-description">Inspiring LinkedIn posts that will help you build trust and authority in your industry.</p>
              </div>
            )}
            {currentPlan && currentPlan.facebook_post > 0 && (
              <div className={`social-media-card ${selectedCard === "Facebook" ? "active" : ""}`} onClick={() => handleOnChangeCardType("Facebook")}>
                <div className={`radioBtn`}>
                  <div className={`radioBtnIn`}></div>
                </div>
                <div className="imageFrame frameFacebook">
                  <FaSquareFacebook className="fbsvg" />
                </div>
                <h5 className="card-title">Generate a <b>Facebook Post</b></h5>
                <p className="card-description">Facebook posts that spark conversations, nurture communities, and elevate your brand.</p>
              </div>
            )}
            {currentPlan && currentPlan.twitter_post > 0 && (
              <div className={`social-media-card ${selectedCard === "Twitter" ? "active" : ""}`} onClick={() => handleOnChangeCardType("Twitter")}>
                <div className={`radioBtn`}>
                  <div className={`radioBtnIn`}></div>
                </div>
                <div className="imageFrame frameX">
                  <img src={dashboardIcon.twitter} alt="" />
                </div>
                <h5 className="card-title">Generate a <b>X(Twitter) Post</b></h5>
                <p className="card-description">Compelling Twitter threads to tell your brand's story in tweets that resonate and trend.</p>
              </div>
            )}
            {currentPlan && currentPlan.instagram_post > 0 && (
              <div className={`social-media-card ${selectedCard === "Instagram" ? "active" : ""}`} onClick={() => handleOnChangeCardType("Instagram")}>
                <div className={`radioBtn`}>
                  <div className={`radioBtnIn`}></div>
                </div>
                <div className="imageFrame frameInsta">
                  <FaInstagram className="instasvg" />
                </div>
                <h5 className="card-title">Generate a <b>Instagram Post</b></h5>
                <p className="card-description">Catchy Instagram captions to engage audiences and make your brand unforgettable.</p>
              </div>
            )}
          </div>
          <div className="buttonDiv ">
            <Button className="button" onClick={() => handleNext(selectedCard)} disabled={!selectedCard}>Start Writing <FaArrowRightLong /></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstStepSocialMediaPost;
