const endPointes = {
    pinnedFeature: `/api/features/get-pinned-features/`,
    allFeatures: `/api/features/get-features`,
    addToPinnedList: '/api/features/add-pinned-features',
    removeFromPinned: '/api/features/delete-pinned-features/',
    articleHistory: '/api/articleHistory/byUser/',
    archive: '/api/articleHistory/archive-article-history',
    restoreArticle: '/api/articleHistory/restore-article-history',
    deleteArticleHistory: '/api/articleHistory/delete-article-history',
    ArticleHistory: '/api/articleHistory/delete-article-history',
    articleHistoryById: '/api/articleHistory/byId/',
    pinArticle: '/api/articleHistory/pinArticleHistory'
};

export { endPointes };
