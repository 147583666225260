import React, { useEffect, useState } from "react"
import Swal from "sweetalert";
import { Alert, Offcanvas } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { setLoader } from "../../store/reducer";
import { AxiosApi } from "../../utility/axios";
import { dashboardIcon } from "../../Assets/Icons"

const appConfig = {
  "LinkedIn": {
    client_id: "77pm89uc4rbc67",
    client_secret: "whqjPyyBysop3Mbq",
  },
  "Instagram": {
    client_id: "672693184347539",
    client_secret: "da170cdc6e8eff42b5ae6324057eb79e",
  }
}

const formFieldArray = {
  "Twitter": [
    { name: "appKey", label: "App Key", placeholder: "Enter your app key here" },
    { name: "appSecret", label: "App Secret", placeholder: "Enter your app secret here" },
    { name: "accessToken", label: "Access Token", placeholder: "Enter your access token here" },
    { name: "accessSecret", label: "Access Secret", placeholder: "Enter your access secret here" },
  ]
}

const SocialConnectionsTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const [accountList, setAccountList] = useState([]);
  const [searchParams] = useSearchParams();
  const { type } = useParams();

  const [inputModal, setInputModal] = useState({ open: false, PostType: "" });
  const [inputs, setInputs] = useState({ appKey: "", appSecret: "", accessToken: "", accessSecret: "" });
  const [errors, setErrors] = useState({});

  const handleInputModalClose = () => {
    setInputModal({ open: false, PostType: "" });
    setInputs({ appKey: "", appSecret: "", accessToken: "", accessSecret: "" });
    setErrors({});
  }

  useEffect(() => {
    const code = searchParams.get("code");
    if (type && code) {
      handleSaveSocialAccountToken(type, code);
    } else {
      getConnectedSocialAccounts();
    }
  }, []);

  useEffect(() => {
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "285198097956196",
          cookie: true,
          xfbml: true,
          version: "v19.0"
        });

        window.FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, "script", "facebook-jssdk"));
    };

    loadFacebookSDK();
  }, []);

  useState(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const params = hash.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = value;
        return acc;
      }, {});
      if (params.access_token) {
        try {
          (async () => {
            const getAccessToken = await AxiosApi.post(`/api/generate-token/Google/${userRes.id}`, { accessToken: params.access_token });
            dispatch(setLoader(false));
            if (getAccessToken && getAccessToken.data && getAccessToken.data.access_token) {
              toast.success(`Google account connected successfully.`, { id: "Toast-01" });
              window.location.href = `${window.location.origin}/account/connections`;
            } else {
              toast.error("Something went wrong. Please try again.", { id: "Toast-01" });
              window.location.href = `${window.location.origin}/account/connections`;
            }
          })()
        } catch (error) {
          dispatch(setLoader(false));
          toast.error("Something went wrong. Please try again.", { id: "Toast-01" });
          window.location.href = `${window.location.origin}/account/connections`;
        }
      }
    }
  }, []);

  const getConnectedSocialAccounts = () => {
    dispatch(setLoader(true));
    setAccountList([]);
    const url = `/api/social-accounts/list/${userRes.id}`;
    AxiosApi.get(url).then(async (response) => {
      if (response.data && response.data.length > 0) {
        setAccountList(response.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  }

  const handleSaveSocialAccountToken = async (socialType, code) => {
    try {
      dispatch(setLoader(true));
      let params = {};
      if (socialType === "Facebook" || socialType === "Google") {
        params = { accessToken: code };
      } else {
        params = {
          ...appConfig[socialType], grant_type: "authorization_code", code,
          redirect_uri: `${window.location.origin}/account/connections/${socialType}/`
        };
      }

      const getAccessToken = await AxiosApi.post(`/api/generate-token/${socialType}/${userRes.id}`, params);
      dispatch(setLoader(false));
      if (getAccessToken && getAccessToken.data && getAccessToken.data.access_token) {
        toast.success(`${socialType} account connected successfully.`, { id: "Toast-01" });
        window.location.href = `${window.location.origin}/account/connections`;
      } else {
        toast.error("Something went wrong. Please try again.", { id: "Toast-01" });
        window.location.href = `${window.location.origin}/account/connections`;
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong. Please try again.", { id: "Toast-01" });
      window.location.href = `${window.location.origin}/account/connections`;
    }
  }

  const handleConnectLinkedInAccount = () => {
    const queryStrings = new URLSearchParams({
      response_type: "code",
      client_id: appConfig.LinkedIn.client_id,
      scope: "r_liteprofile r_emailaddress w_member_social",
      state: "123456",
      redirect_uri: `${window.location.origin}/account/connections/LinkedIn/`
    }).toString();

    window.open(`https://www.linkedin.com/oauth/v2/authorization?${queryStrings}`, '_blank');
  }

  const handleConnectFacebookAccount = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        var accessToken = response.authResponse.accessToken;
        handleSaveSocialAccountToken("Facebook", accessToken);
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    }, { scope: "publish_actions, manage_pages, publish_to_groups, pages_read_engagement, pages_manage_posts, groups_access_member_info" });
  };

  const handleConnectGoogleAccount = () => {
    if (!localStorage.getItem("googleAccessToken")) {
      const clientId = "1091245985927-88g8udt295sgkircjlasdtudv5ii53is.apps.googleusercontent.com";
      const redirectUrl = window.location.origin + "/account/connections";
      const scope = "https://www.googleapis.com/auth/drive.file";
      const params = `client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=token&scope=${scope}&prompt=consent`;

      window.location.href = `https://accounts.google.com/o/oauth2/auth?${params}`;
    } else {
    }
  };

  const handleConnectInstagramAccount = () => {
    dispatch(setLoader(true));
    const queryStrings = new URLSearchParams({
      response_type: "code",
      client_id: appConfig.Instagram.client_id,
      scope: "user_profile,user_media",
      redirect_uri: `${window.location.origin}/account/connections/Instagram/`
    }).toString();

    window.location.href = `https://api.instagram.com/oauth/authorize?${queryStrings}`;
  }

  const handleOnInputChangeValue = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  }

  const handleValidateForm = () => {
    let isValidate = true;
    let errorObj = {};

    if (inputModal.PostType === "Twitter") {
      if (!inputs.accessSecret) {
        errorObj = { ...errorObj, accessSecret: { message: "Access secret is required" } };
        isValidate = false;
      }
      if (!inputs.appKey) {
        errorObj = { ...errorObj, appKey: { message: "App key is required" } };
        isValidate = false;
      }
      if (!inputs.appSecret) {
        errorObj = { ...errorObj, appSecret: { message: "App secret is required" } };
        isValidate = false;
      }
      if (!inputs.accessToken) {
        errorObj = { ...errorObj, accessToken: { message: "Access token is required" } };
        isValidate = false;
      }
    }

    setErrors(errorObj);
    return isValidate;
  }

  const handleShareSocialMediaPost = async () => {
    const isValidForm = await handleValidateForm();
    if (!isValidForm) {
      return false;
    }

    try {
      setErrors({});
      dispatch(setLoader(true));

      let payload = {};
      if (inputModal.PostType === "Twitter") {
        payload = {
          type: inputModal.PostType,
          twitter_credentials: JSON.stringify(inputs),
        };
      }

      const url = `/api/save-social-account/${userRes.id}`;
      AxiosApi.post(url, payload).then(async (response) => {
        toast.success(`${inputModal.PostType} account connected successfully.`, { id: "Toast-01" });
        dispatch(setLoader(false));
        handleInputModalClose();
        getConnectedSocialAccounts();
      }).catch((error) => {
        toast.error("Something went wrong.", { id: "Toast-01" });
        dispatch(setLoader(false));
      });
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Access token is not valid.", { id: "Toast-01" });
    }
  }

  const handleRemoveSocialAccount = async (id) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, delete it!" },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        dispatch(setLoader(true));
        await AxiosApi.delete(`/api/social-accounts/remove/${id}`);
        dispatch(setLoader(false));
        getConnectedSocialAccounts();
        Swal("Deleted!", "Your record has been deleted.", "success");
      }
    });
  }

  const handleRemoveSocialAccountGetId = (ele) => {
    const element = accountList.find(item => item.type === ele);
    handleRemoveSocialAccount(element.id);
  };

  return (
    <div className="accountCard">
      <div className="cardHead">
        <h3>Social Accounts</h3>
        <p>Connect social media account for a quick share.</p>
      </div>
      <div className="AISocialMediaWriter cardBody">
        <div className="social-media-images p-0">
          <div className="social-media-card w-100">
            <div className="imageFrame frameLindekdin">
              <FaLinkedinIn className="lnsvg" />
            </div>
            <h5 className="card-title">LinkedIn</h5>
            {accountList.find(({ type, is_connected, id }) => type === "LinkedIn" && is_connected) ? (
              <button className="social-connect-button connected" onClick={() => handleRemoveSocialAccountGetId("LinkedIn")}>
                Disconnect
              </button>
            ) : (
              <button className="social-connect-button connect" onClick={handleConnectLinkedInAccount}>
                Connect
              </button>
            )}
          </div>
          <div className="social-media-card w-100">
            <div className="imageFrame frameLindekdin">
              <FaSquareFacebook className="lnsvg" />
            </div>
            <h5 className="card-title">Facebook</h5>
            {accountList.find(({ type, is_connected, id }) => type === "Facebook" && is_connected) ? (
              <button className="social-connect-button connected" onClick={() => handleRemoveSocialAccountGetId("Facebook")}>
                Disconnect
              </button>
            ) : (
              <button className="social-connect-button connect" onClick={handleConnectFacebookAccount}>
                Connect
              </button>
            )}
          </div>
          <div className="social-media-card w-100">
            <div className="imageFrame frameX">
              <img src={dashboardIcon.twitter} alt="twitter-logo" />
            </div>
            <h5 className="card-title">X (Twitter)</h5>
            <button className="social-connect-button connect"
              onClick={() => setInputModal({ open: true, PostType: "Twitter", background: 'black', color: 'white', icon: dashboardIcon.twitter })}
            >
              Connect
            </button>
          </div>
          <div className="social-media-card w-100">
            <div className="imageFrame frameLindekdin">
              <FcGoogle className="lnsvg" />
            </div>
            <h5 className="card-title">Google</h5>
            {accountList.find(({ type, is_connected, id }) => type === "Google" && is_connected) ? (
              <button className="social-connect-button connected" onClick={handleConnectGoogleAccount}>
                Re-Connect
              </button>
            ) : (
              <button className="social-connect-button connect" onClick={handleConnectGoogleAccount}>
                Connect
              </button>
            )}
          </div>
          <div className="social-media-card w-100">
            <div className="imageFrame frameInsta">
              <FaInstagram className="instasvg" />
            </div>
            <h5 className="card-title">Instagram</h5>
            {/* <button className="social-connect-button connect" onClick={handleConnectInstagramAccount}>Connect</button> */}
            <button className="social-connect-button coming-soon">Coming soon</button>
          </div>
        </div>
      </div>

      {inputModal && inputModal.open && (
        <Offcanvas placement="end" show={inputModal.open} onHide={handleInputModalClose}>
          <Offcanvas.Header className="justify-content-between align-items-center p-0 border-0">
            <div className="d-flex align-item-center gap-3">
              <div><img src={inputModal.icon} alt="" /></div>
              <h4>Connect {inputModal.PostType}</h4>
            </div>
            <button type="button" className="btn-close text-reset p-0" onClick={handleInputModalClose} ></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="cardBody p-0">
            <div className="text-start">
              <Alert variant="warning" className="p-3 rounded-1" style={{ background: "rgba(237, 137, 62, 0.10)", fontSize: "14px", color: "black" }}>
                <h6 className="fw-normal">
                  Due to recent {inputModal.PostType} API Changes, you need to create a
                  A free developer account first and then add your developer app details here. Please find the steps
                  <Link className="color-primary-main cursor-pointer ms-1" to="/account/connections/twitter-step" target="_blank">here</Link>
                </h6>
              </Alert>
              <div className="addllyFormWrap mt-4">
                {formFieldArray && inputModal.PostType && formFieldArray[inputModal.PostType]?.map((field, index) => (
                  <div className="form-group mb-4" key={index}>
                    <label>{field.label}</label>
                    <input
                      name={field.name} type="text" placeholder={field.placeholder}
                      value={inputs[field.name]} onChange={handleOnInputChangeValue}
                      className={`addllyForm-control ${errors[field.name] ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors[field.name]?.message}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <button onClick={handleShareSocialMediaPost} type="button" className="addlly-primary border-0"
                style={{ background: inputModal.background, color: inputModal.color }}
              >
                <span>Connect</span>
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
}


export default SocialConnectionsTab;
