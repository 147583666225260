import { useState } from "react";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const FashionCategory = [
  { id: 1, name: "Beauty and Skincare Trends", image: "/Assets/images/shiseido-cat-01.png" },
  { id: 2, name: "K-Beauty Trends", image: "/Assets/images/shiseido-cat-02.png" },
  { id: 3, name: "Competitor Headlines", image: "/Assets/images/shiseido-cat-03.png" },
  { id: 4, name: "Ingredient and Innovation", image: "/Assets/images/shiseido-cat-04.png" },
  { id: 5, name: "Shiseido News", image: "/Assets/images/shiseido-cat-05.png" },
  { id: 6, name: "Studies and Surveys", image: "/Assets/images/studySurvay.jpg" },
];

const FirstStepNewsletters = ({ setCurrStep, setShiseidoNewslettersInfo }) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleNextStep = (categoryName) => {
    if (categoryName && categoryName !== "") {
      setShiseidoNewslettersInfo({ category: categoryName, stepDone: 2 });
      setCurrStep(2); navigate(`/cmi-newsletters/type`);
    } else {
      toast.error("Please any category for generate Newsletters.", { id: "Toast-01" });
    }
  };

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex flex-wrap align-items-center justify-content-between mx-2 mx-md-auto">
        <div><b>Select Category</b> <span>(Select any 1)</span></div>
        <button
          type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
          onClick={() => handleNextStep(selectedCategory)} disabled={!selectedCategory || selectedCategory === ""}
        >
          Select Article <BsArrowRight />
        </button>
      </div>
      <div className="news-letters-templates">
        <div className="template-wrapper align-items-start">
          {FashionCategory && FashionCategory.length > 0 && FashionCategory.map((category, index) => (
            <div
              key={index} onClick={() => setSelectedCategory(category.name)}
              className={`image-main-wrapper cursor-pointer ${selectedCategory === category.name ? "active" : ""}`}
            >
              <div className="template-header">
                <div className={`radioBtn`} onClick={() => setSelectedCategory(category.name)}>
                  <div className={`radioBtnIn`}></div>
                </div>
              </div>
              <div className="image-content"
                style={{
                  backgroundImage: `url(${category.image})`,
                  backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "10px"
                }}
              >
                <h2
                  className="d-flex align-items-center justify-content-center text-center mb-0 text-white"
                  style={{ height: "250px", background: "rgba(0, 0, 0, 0.5)", borderRadius: "10px" }}
                >
                  {category.name}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="buttonDiv">
        <Button
          className="button" onClick={() => handleNextStep(selectedCategory)}
          disabled={!selectedCategory || selectedCategory === ""}
        >
          Select Article <FaArrowRightLong className="ms-2" />
        </Button>
      </div>
    </div>
  )
};

export default FirstStepNewsletters;
