import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import toast from "react-hot-toast";
import { HiPlus } from "react-icons/hi";
import { BsArrowRight, BsArrowLeft, BsStars } from "react-icons/bs";
import ButtonLoader from "../ui/ButtonLoader";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { setPressReleaseWriterInfo, setPressReleaseWriterStep, setUserRes } from "../../store/reducer";

const ThirdStepPressRelease = ({ isButtonLoader, setIsButtonLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);
  const pressReleaseWriterInfo = useSelector(({ pressReleaseWriterInfo }) => pressReleaseWriterInfo);

  const [isAddedKeyword, setIsAddedKeyword] = useState(false);
  const [selectGoogleKeyword, setSelectGoogleKeyword] = useState(undefined);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [oldGeneratedKeyword] = useState(pressReleaseWriterInfo?.keywords || []);

  useEffect(() => {
    if (pressReleaseWriterInfo?.keywords && pressReleaseWriterInfo?.keywords.length > 0) {
      const findIndex = pressReleaseWriterInfo.keywords.findIndex((item) => item?.isSelected === true);
      setSelectGoogleKeyword(findIndex >= 0 ? findIndex : undefined);

      const findIndex1 = pressReleaseWriterInfo.keywords.findIndex((item) => item?.added === "true");
      setIsAddedKeyword(findIndex1 >= 0 ? true : false);
    }

    if (pressReleaseWriterInfo && pressReleaseWriterInfo.resText && pressReleaseWriterInfo.resText !== "") {
      setIsDisabledInput(true);
    }
  }, [])

  const handleKeywordSelection = (index, keyword = null) => {
    setSelectGoogleKeyword(index);
    if (!keyword || keyword === "") {
      toast.error("Please insert a keyword", { id: "Toast-01" });
      setSelectGoogleKeyword(undefined);
    }
  };

  const handleOnChangeKeywordStr = ({ index, txt }) => {
    const tempKeywords = [...pressReleaseWriterInfo.keywords];
    tempKeywords[index] = { ...tempKeywords[index], keyword: txt };
    dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, keywords: tempKeywords }));
  };

  const handleAddNewKeyword = () => {
    const tempObj = [{ "keyword": "", "added": "true" }, ...(pressReleaseWriterInfo?.keywords || [])];
    dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, keywords: tempObj }));
    setIsAddedKeyword(true);
    setSelectGoogleKeyword(undefined);
  };

  const handleNext = async () => {
    if (isButtonLoader) { return false; };

    if (selectGoogleKeyword >= 0 && selectGoogleKeyword !== undefined) {
      const keywordObj = pressReleaseWriterInfo.keywords.map((item, index) => {
        let tempItem = { ...item };
        if (oldGeneratedKeyword.findIndex((ele) => ele.keyword === item.keyword) === -1) {
          const { cpc, volume, ...itemRest } = tempItem;
          tempItem = { ...itemRest };
        }

        if (index === selectGoogleKeyword) {
          return Object.assign({}, tempItem, { isSelected: true });
        } else {
          const { isSelected, ...itemRest } = tempItem;
          return itemRest;
        }
      });
      dispatch(setPressReleaseWriterInfo({ ...pressReleaseWriterInfo, keywords: keywordObj }));

      if (pressReleaseWriterInfo && pressReleaseWriterInfo.resText && pressReleaseWriterInfo.resText !== "") {
        dispatch(setPressReleaseWriterStep(4));
        return false;
      }

      const selectedKeyword = (selectGoogleKeyword >= 0) ? keywordObj[selectGoogleKeyword]?.keyword : "";
      const reqObj = {
        id: (pressReleaseWriterInfo?.id || null),
        type: pressReleaseWriterInfo.selectedCard,
        subType: pressReleaseWriterInfo.subType,
        language: pressReleaseWriterInfo.language,
        inputInfo: pressReleaseWriterInfo.inputInfo,
        allKeywords: keywordObj, selectedKeyword,
        userId: userRes.id,
      }

      try {
        setIsButtonLoader(true); setIsDisabledInput(true);
        const payload = { body: JSON.stringify(reqObj) };
        const response = await invokeLambdaFunction("generateTextForPressRelease", payload);
        setIsButtonLoader(false);
        if (response && response.success === 1 && response.data && response.data.resText) {
          setIsDisabledInput(true);
          dispatch(setPressReleaseWriterInfo({
            ...pressReleaseWriterInfo, ...response.data,
            stepDone: pressReleaseWriterInfo.stepDone < 4 ? 4 : pressReleaseWriterInfo.stepDone
          }));
          dispatch(setPressReleaseWriterStep(4));
          dispatch(setUserRes({ ...userRes, credits: userRes.credits - (userRes?.deductCredits?.["Press Release"] || 3) }));
          navigate(`/press-release/${response.data?.id}`);
        } else {
          toast.error("Press release not generated, Please try again.", { id: "Toast-01" });
        }
      } catch (error) {
        setIsButtonLoader(false); setIsDisabledInput(false);
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    } else {
      toast.error("Select any one keyword to generate Press Release.", { id: "Toast-01" });
    }
  };

  return (
    <React.Fragment>
      <div className="selectBlock d-flex flex-wrap gap-3 align-items-center justify-content-between mx-2 mx-md-auto">
        <div className="">
          <b>Keyword Suggestions</b> <span>(Select any one)</span>
        </div>
        <div className="nxtGenBtn d-flex flex-wrap align-items-center align-items-center gap-2">
          <button
            type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
            onClick={() => {
              const scrollWrapper = document.getElementById('scroll-wrapper');
              if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
              dispatch(setPressReleaseWriterStep(2));
            }}
          >
            <BsArrowLeft className="" /> Previous
          </button>
          <span style={{ opacity: 0.1 }}>|</span>
          <button type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton" onClick={() => handleNext()}
            disabled={selectGoogleKeyword >= 0 && selectGoogleKeyword !== undefined && !isButtonLoader ? false : true}
          >
            Generate <BsArrowRight className="" />
          </button>
        </div>
      </div>

      <div className="googleSearchResult AIWriterBlog">
        <div className="d-flex flex-row-reverse addUrlBar mb-3">
          {pressReleaseWriterInfo && pressReleaseWriterInfo.keywords?.length > 0 && !isAddedKeyword && !isDisabledInput && (
            <p className="d-flex align-items-center text-primary cursor-pointer" onClick={handleAddNewKeyword} onKeyDown={handleAddNewKeyword} style={{ fontWeight: "500px" }}>
              <HiPlus className="me-2 my-1" /> <strong>Add Keyword</strong>
            </p>
          )}
        </div>
        <ul className="seoOptBlogTopic addllyFormWrap" style={{ listStyleType: "none" }}>
          {pressReleaseWriterInfo.keywords && pressReleaseWriterInfo.keywords.length > 0 ? (pressReleaseWriterInfo.keywords.slice(0, 5)?.map((item, index) => (
            <li key={index} className={classNames("suggestionBox", { active: selectGoogleKeyword === index })}>
              <div className="keyWordSuggestion d-flex justify-content-between align-items-center">
                <div className="suggestionInput d-flex align-items-center gap-2">
                  <div className="radioType">
                    <input type="radio" checked={selectGoogleKeyword === index} disabled={isDisabledInput}
                      onChange={(e) => e.target.checked ? handleKeywordSelection(index, item.keyword) : handleKeywordSelection(null, item.keyword)}>
                    </input>
                  </div>
                  <div className="keywordInput w-100">
                    <input
                      type="text" placeholder="Insert your keyword here" disabled={isDisabledInput}
                      className={`addllyForm-control ${selectGoogleKeyword !== index && "listInput"}`}
                      value={item.keyword} onChange={(e) => handleOnChangeKeywordStr({ index, txt: e.target.value })}
                    />
                  </div>
                </div>
                {item.keyword && (oldGeneratedKeyword.findIndex((ele) => ele.keyword === item.keyword) !== -1) && (
                  <div className="keyWVolum">
                    {item.cpc && (
                      <div className="innerKeyVBlock d-flex align-items-center mb-2 ">
                        <div className="keyText"><p>CPC</p></div>
                        <div className="valumPrice"><strong>{item.cpc}</strong></div>
                      </div>
                    )}
                    {item.volume && (
                      <div className="innerKeyVBlock d-flex align-items-center  ">
                        <div className="keyText">
                          <p>Keyword’s volume</p>
                        </div>
                        <div className="valumPrice">
                          <strong>{item.volume}</strong>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))) : (
            <li className="text-center my-5">
              {!isAddedKeyword && (
                <React.Fragment>
                  <h5 className="mb-3 fw-normal">
                    Keyword suggestions not available. Add your keyword to generate SEO Optimized Press Release.
                  </h5>
                  <button
                    type="button" onClick={handleAddNewKeyword} style={{ maxWidth: "fit-content" }}
                    className="addlly-outline btn-sm py-2 px-3 fs-6 mx-auto"
                  >
                    <HiPlus /> <span>Add Keyword</span>
                  </button>
                </React.Fragment>
              )}
            </li>
          )}
        </ul>
        <div className="w-100">
          <div className="nextPrewBtn d-flex justify-content-center gap-3 mb-3">
            <button
              type="button" onClick={() => handleNext()} className="addlly-primary"
              disabled={selectGoogleKeyword >= 0 && selectGoogleKeyword !== undefined && !isButtonLoader ? false : true}
            >
              {isButtonLoader ? (
                <><BsStars /> <span>Generating Press Release</span> <ButtonLoader /></>
              ) : <><BsStars /> <span>Generate Press Release</span> <BsArrowRight /></>}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default ThirdStepPressRelease;
