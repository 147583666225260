import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import * as htmlToImage from 'html-to-image';
import { BsDownload, BsStars } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { AxiosApi } from "../../utility/axios";
import { setLoader } from "../../store/reducer";
import VersionHistory from "../commonComponents/VersionHistory";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
import { EditorInitObj, addEditorCustomButtons } from "../../utility/hepler";
import { setAzgoNewsletterInfo, setAzgoNewsletterStep } from "../../store/reducer";
import "grapesjs/dist/css/grapes.min.css";
const beautify_html = require("js-beautify").html;
const articleType = "azgoNewsletter";

const ThirdStepAzgoNewsletter = () => {
  const editorRef = useRef(null);
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [editorKey, setEditorKey] = useState(0);
  const azgoNewsletterInfo = useSelector(({ azgoNewsletterInfo }) => azgoNewsletterInfo);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(azgoNewsletterInfo);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [versionHistory, setVersionHistory] = useState([]);
  const userRes = useSelector(({ userRes }) => userRes);

  useEffect(() => {
    if (azgoNewsletterInfo && azgoNewsletterInfo.resposeHTML) {
      setFinalHtmlText(azgoNewsletterInfo.resposeHTML);
      setEditData(azgoNewsletterInfo);
    } else {
      console.error("error");
    }
    setEditorKey(editorKey + 1);
  }, [azgoNewsletterInfo.template]);

  useEffect(() => {
    setSelectedVersionItem({});
    getArticleAllVersionHistory();
  }, [])

  const getArticleAllVersionHistory = async () => {
    try {
      let versionSubType = "azgoNewsletter"

      if (!azgoNewsletterInfo?.articleId || !versionSubType) {
        return false;
      }
      setVersionHistory([]);
      const params = `articleId=${azgoNewsletterInfo?.articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleDownloadArticlePngFile = async () => {
    if (document.querySelector(".tox-edit-area__iframe") && document.querySelector(".tox-edit-area__iframe").contentDocument) {
      const editorBody = document.querySelector(".tox-edit-area__iframe").contentDocument.body;
      const images = editorBody.querySelectorAll("img");
      dispatch(setLoader(true));

      for (const img of images) {
        const imageUrl = img.currentSrc;
        if (imageUrl.startsWith("data:")) { continue; }
        try {
          const response = await AxiosApi.get(`/api/post-writer/get-img-base64`, { params: { url: imageUrl } });
          if (response && response.data && response.data.data) {
            img.src = response.data.data;
          }
        } catch (error) {
        }
      }

      const childDiv = editorBody.querySelector(".container");
      htmlToImage.toPng(childDiv).then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement("a");
        link.download = `Newsletter-Image-${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
        dispatch(setLoader(false));
      }).catch(function (error) {
        dispatch(setLoader(false));
      });
    }
  };

  const handleGenerateArticleForAiModel = async () => {
    try {
      if (!azgoNewsletterInfo?.generationsLeft || azgoNewsletterInfo?.generationsLeft === 0) {
        toast.error("You can regenerate Newsletter only three times.");
        return false;
      }

      dispatch(setLoader(true));
      const payload = {
        pathParameters: {
          articleId: azgoNewsletterInfo?.articleId ? azgoNewsletterInfo.articleId : "",
        },
        body: JSON.stringify({ type: "generate-article" }),
      };
      const response = await invokeLambdaFunction("updateOrDeleteAzgoNewsletter", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data) {
        dispatch(setAzgoNewsletterStep(3)); setFinalHtmlText(response.data);
        dispatch(setAzgoNewsletterInfo({ ...azgoNewsletterInfo, resposeHTML: response.data, generationsLeft: azgoNewsletterInfo.generationsLeft - 1 }));

        setEditData({ ...azgoNewsletterInfo, resposeHTML: response.data });
        setTimeout(() => setEditorKey(editorKey + 1), 0);
        setSelectedVersionItem({}); getArticleAllVersionHistory();
      } else {
        toast.error("Article not generated, Please try again!", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    if (fieldName === "response") {
      dispatch(setAzgoNewsletterInfo({ ...azgoNewsletterInfo, resposeHTML: text }));
      setFinalHtmlText(text); setEditorKey(editorKey + 1);
    }
    getArticleAllVersionHistory();
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };


  return (
    <>
      <div className="selectBlock d-flex align-items-center justify-content-between">
        <div><b>Edit Newsletter</b></div>
      </div>

      <div className="blogWriterArea border-0" style={{ margin: "30px" }}>
        <div className="blogWriterBlock p-0" style={{ maxWidth: "none" }}>
          <div className="navItemsBlock">
            <div className="navTopButton">
              <div className="leftButtons d-flex gap-3 align-items-center">
                <button className="blogButton border-0 text-nowrap" onClick={handleDownloadArticlePngFile}>
                  <BsDownload />
                  Download As PNG
                </button>
                <span className="line"></span>
                <VersionHistory
                  versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                  handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                  articleType={"NewsLetters"}
                  selectedSubTab={""} setSelectedSubTab={() => { }}
                />
                <span className="line"></span>
                <button
                  type="button" className="blogButton border-0 text-nowrap"
                  onClick={handleGenerateArticleForAiModel}
                  disabled={azgoNewsletterInfo.generationsLeft === 0}
                >
                  <BsStars /> Re-Generate ( {azgoNewsletterInfo.generationsLeft || 0} / 3 )
                </button>
              </div>
            </div>
          </div>
          <Row>
            <Col sm="12" className="editableTextArea d-flex overflow-hidden position-relative h-100">
              <div className="article-body-wrapper w-100">
                <div className="position-relative custom-text-editor">
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={finalHtmlText}
                    onEditorChange={(e) => setFinalHtmlText(e)}
                    key={editorKey}
                    init={{
                      ...EditorInitObj,
                      height: "90vh",
                      content_style: `${(typeof finalHtmlText === 'string' && finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1]) || ""}`,
                      toolbar: EditorInitObj.toolbar,
                      setup: function (editor) {
                        addEditorCustomButtons(editor);
                      },
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ThirdStepAzgoNewsletter;
