import React from 'react'
import { Col, Row } from 'react-bootstrap';
import AccessDeniedImg from "../Assets/Images/access-denied.png";

const AccessDenied = ({ text }) => {
  return (
    <Row>
      <Col md="6" sm="12" className="mx-auto">
        <div className="border rounded shadow overflow-hidden">
          <div className="mx-auto col-8">
            <img src={AccessDeniedImg} alt="access-denied" className="w-100" />
          </div>
          <div className="m-4 text-center">
            <h4 className="fw-normal">You don't have access for {text}.</h4>
            <h4 className="fw-normal">Please upgrade your plan.</h4>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default AccessDenied;
