import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosApi } from "../utility/axios";
import Heading from "../Components/ui/Title";
import { setECommerceBlogInfo, setECommerceBlogStep, setLoader } from "../store/reducer";
import ECommerceBlogHeader from "../Components/ECommerceBlogWriter/ECommerceBlogHeader";
import FirstStepECommerceBlog from "../Components/ECommerceBlogWriter/FirstStepECommerceBlog";
import SecondStepECommerceBlog from "../Components/ECommerceBlogWriter/SecondStepECommerceBlog";
import ThirdStepECommerceBlog from "../Components/ECommerceBlogWriter/ThirdStepECommerceBlog";
import FourthStepECommerceBlog from "../Components/ECommerceBlogWriter/FourthStepECommerceBlog";
import ECommerceBlogHistory from "../Components/ECommerceBlogWriter/ECommerceBlogHistory";
import FirstStepElixirEComBlog from "../Components/ElixirECommerceBlog/FirstStepElixirEComBlog";
import SecondStepElixirEComBlog from "../Components/ElixirECommerceBlog/SecondStepElixirEComBlog";
import ThirdStepElixirEComBlog from "../Components/ElixirECommerceBlog/ThirdStepElixirEComBlog";
import FourthStepElixirEComBlog from "../Components/ElixirECommerceBlog/FourthStepElixirEComBlog";

const ECommerceBlogWriter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRes = useSelector(({ userRes }) => userRes);
  const eCommerceBlogStep = useSelector(({ eCommerceBlogStep }) => eCommerceBlogStep);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      handleGetProductDetailsById(id, false);
    } else {
      dispatch(setECommerceBlogInfo({}));
      dispatch(setECommerceBlogStep(1));
      navigate("/e-commerce-blog");
    }
  }, [id]);

  const handleGetProductDetailsById = async (id, isSetStep = false) => {
    try {
      dispatch(setLoader(true));
      dispatch(setECommerceBlogInfo({}));
      const response = await AxiosApi.get(`/api/product-catalog/article/history-details?id=${id}`);
      if (response && response.data) {
        dispatch(setLoader(false));
        const item = response.data && response.data.id ? response.data : {};
        const eCommerceBlogDataObj = { ...item };
        eCommerceBlogDataObj.stepDone = item?.status === "completed" ? 4 : (item?.topics && item?.topics.length > 0) ? 3 : 2;

        dispatch(setECommerceBlogInfo({ ...eCommerceBlogDataObj }));
        if (isSetStep) {
          dispatch(setECommerceBlogStep(item?.status === "completed" ? 4 : (item?.topics && item?.topics.length > 0) ? 3 : 2));
          navigate(`/e-commerce-blog/${item?.id}`);
        } else {
          dispatch(setECommerceBlogStep(eCommerceBlogStep));
        }
        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
      dispatch(setECommerceBlogInfo({}));
      dispatch(setECommerceBlogStep(1));
      navigate("/e-commerce-blog");
    }
  }

  const steps = useMemo(() => {
    // if ([494].includes(userRes.id)) {
    //   return [
    //     <FirstStepElixirEComBlog />,
    //     <SecondStepElixirEComBlog />,
    //     <ThirdStepElixirEComBlog />,
    //     <FourthStepElixirEComBlog />
    //   ];
    // } 

    return [
      <FirstStepECommerceBlog />,
      <SecondStepECommerceBlog />,
      <ThirdStepECommerceBlog />,
      <FourthStepECommerceBlog />
    ];
  }, []);

  return (
    <React.Fragment>
      <div className="oneClickHead d-flex flex-column pt-4">
        <div className="">
          <Heading className="fw-bolder mainHeading" size="3">
            E-Commerce Blog Writer
          </Heading>
          <p>Generate E-Commerce Blog on any selected product.</p>
        </div>
      </div>
      <div className="stepWraper">
        <div className="border-radius-12 bg-white AIWriterBlog AISocialMediaWriter SocialMediaWriter maingenrateBlock p-0 m-0">
          <ECommerceBlogHeader />
          {steps[eCommerceBlogStep - 1]}
        </div>
      </div>

      {(eCommerceBlogStep === 1 && !id) && (
        <div className="d-flex gap-2 flex-column tableHeading mt-5">
          <div className="mt-2">
            <ECommerceBlogHistory handleGetProductDetailsById={handleGetProductDetailsById} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ECommerceBlogWriter;
