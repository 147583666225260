import toast from "react-hot-toast";
import { setHistoryData, setHistoryDataLength, setLoader, setSeoOptimizedSets, setUserRes } from "../store/reducer";
import { invokeLambdaFunction } from "./invokeLamdaFunction";
import { AxiosApi } from "./axios";
import Swal from "sweetalert";
import { endPointes } from "./endPointes";

const fetchStep4thData = (topic, keyword, selectedGoogleWebsite, articleId, saveKeywordsObj, tableName = "") => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const payload = {
      body: JSON.stringify({
        topic: topic.split("\n").length >= 1 ? topic.split("\n")[0] : topic,
        keyword: keyword, tableName: tableName,
        selectedGoogleWebsite: selectedGoogleWebsite,
        articleId, saveKeywordsObj,
      })
    };
    const response = await invokeLambdaFunction("generateSeoOptimized", payload);
    if (response && response.success === 1 && tableName !== "tatler_SEO") {
      dispatch(setSeoOptimizedSets(response.data));
    }

    dispatch(setLoader(false));
    return Promise.resolve(response);
  } catch (error) {
    dispatch(setLoader(false));
    toast.error("Error fetching portfolios", { id: "Toast-01" });
    return Promise.resolve(true);
  }
};

export const getHistory = (userId, limit = 10, offset = 0, tableName = "artical_history", blogType = "all") => async (dispatch) => {
  dispatch(setLoader(true));
  const url = `/api/articleHistory/byUser/${userId}?limit=${limit}&offset=${offset}&tableName=${tableName}&blogType=${blogType}`;
  AxiosApi.get(url).then((response) => {
    dispatch(setHistoryData(response.data.data));
    dispatch(setHistoryDataLength(response.data.length));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    console.log(error);
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
  });
};

const getUserArticleHistory = async ({ userId, type, filters, searchFilter, sortBy }) => {
  const searchParams = {
    is_archived: type === "archive" ? 1 : 0,
    type: filters.categories?.join(","),
    search: searchFilter,
    starred: type,
    aiModel: filters.AIModels?.join(","),
    date: filters.durations?.length === 6 ? 'all' : filters.durations.includes("Custom") ? "" : filters.durations?.join(","),
    status: filters.status?.join(","),
    sortBy: sortBy.join(','),
  }
  if (filters.isCustomDate) {
    searchParams.isCustomDate = true;
    searchParams.startDate = filters.customDate.startDate;
    searchParams.endDate = filters.customDate.endDate;

  }
  const queryParams = new URLSearchParams(searchParams).toString();

  try {
    const response = await AxiosApi.get(endPointes.articleHistory + userId + '?' + queryParams);
    return response.data;
  } catch (error) {
    throw new Error(error?.response);
  }
};

export const getUserDetail = (userId) => async (dispatch) => {
  // dispatch(setLoader(true));
  AxiosApi.get(`/user/detail/${userId}`).then((response) => {
    dispatch(setUserRes(response.data));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    console.log(error);
    toast.error(error?.response?.data?.error?.message || error?.response?.data?.error || "Server is overloaded, try again after sometime.", { id: "Toast-01" });
  });
};

const deleteOrArchiveArticle = (data, eType, callBack) => {
  if (eType === 'delete') {
    AxiosApi.post(endPointes.deleteArticleHistory, { data: data }).then((response) => {
      if (response.data) {
        Swal('Deleted', `Your ${data.length > 1 ? 'records' : 'record'} has been deleted.`, "success").then((resp) => {
          callBack(resp)
        });
      }
    }).catch((error) => {
      console.error("There is Error");
    });
  } else {
    AxiosApi.put(endPointes.archive, { data: data }).then((response) => {
      if (response.data) {
        Swal('Archived', `Your ${data.length > 1 ? 'records' : 'record'} has been archived.`, "success").then((resp) => {
          callBack(resp)
        });
      }
    }).catch((error) => {
      console.error("There is Error");
    });
  }
}

const restoreArticleHistory = (data, callBack) => {
  AxiosApi.put(endPointes.restoreArticle, { data: data }).then((response) => {
    if (response.data) {
      Swal('Restored', `Your ${data.length > 1 ? 'records' : 'record'} has been restored.`, "success").then((resp) => {
        callBack(resp)
      });
    }
  }).catch((error) => {
    console.error("There is Error");
  });
}

const pinArticles = async ({ data }) => {
  try {
    const response = await AxiosApi.put(endPointes.pinArticle, data);
    return response.data;
  } catch (error) {
    throw Error(error?.response?.data)
  }
}

const articleHistoryShortFlowData = async ({ userId, query }) => {
  try {
    const response = await AxiosApi.get(`/api/articleHistory/byUser/${userId}?${query}`);
    return response.data ? response.data : {};
  } catch (error) {
    throw Error(error?.response?.data);
  }
}

const newslettersHistoryData = async ({ userId, query }) => {
  try {
    const response = await AxiosApi.get(`/api/news-letters/get-history?userId=${userId}&${query}`);
    return response.data ? response.data : {};
  } catch (error) {
    throw Error(error?.response?.data);
  }
}

const pressReleaseHistoryData = async ({ userId, query }) => {
  try {
    const response = await AxiosApi.get(`/api/press-release/get-history?userId=${userId}&${query}`);
    return response.data ? response.data : {};
  } catch (error) {
    throw Error(error?.response?.data);
  }
}

const eCommerceHistoryData = async ({ userId, query }) => {
  try {
    const url = `/api/product-catalog/article/history-details?${query}`;
    const response = await AxiosApi.get(url);
    return response.data ? response.data : {};
  } catch (error) {
    throw Error(error?.response?.data);
  }
}

const handleGetGeneratedImageHistory = async (userId) => {
  try {
    const url = `/api/product-image-library/list/byUser/${userId}?isGetHistory=true`;
    const response = await AxiosApi.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error?.response);
  }
}

export {
  fetchStep4thData, getUserArticleHistory, restoreArticleHistory, deleteOrArchiveArticle,
  articleHistoryShortFlowData, pinArticles, handleGetGeneratedImageHistory,
  newslettersHistoryData, pressReleaseHistoryData, eCommerceHistoryData
};
