import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert";
import Select from "react-select";
import { toast } from "react-hot-toast";
import momentTimezone from "moment-timezone";
import { Col, Row, Modal, Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { BsCameraFill, BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

import { AxiosApi } from "../../utility/axios";
import { getUserDetail } from "../../utility/apiService";
import { setLoader, setUserRes } from "../../store/reducer";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { trimedInputValue } from "../../utility/hepler";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Skeleton } from "@mui/material";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(20, "First Name cannot be more than 20 Characters Long")
    .matches(/^[a-zA-Z]+$/, 'First Name should contain only letters')
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Topic should not contain multiple consecutive white spaces"),
  username: Yup.string()
    .required("Username is required")
    .min(2, "Username must be at least 2 characters")
    .max(20, "Username must not exceed 20 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Username should not contain multiple consecutive white spaces"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, 'Phone number should contain only number')
    .min(8, "Phone number must be at least 8 digits")
    .max(13, "Phone number must not exceed 13 digits")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "phone Number should not contain multiple consecutive white spaces"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .matches(/^(?!\s)(?!.*\s{2})[^\s](.*[^\s])?$/, "Email should not contain multiple consecutive white spaces"),
  timezone: Yup.string().required("Timezone is required").min(2, "Timezone must be at least 2 characters"),
  industry: Yup.string().test('industry', 'Industry should contain only letters and be between 2 and 40 characters in length.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z ]+$/).min(2).max(40);
      return schema.isValidSync(value);
    }
    return true;
  }),
  // company: Yup.string().test('company', 'Company should contain only letters and be between 2 and 40 characters in length.', function (value) {
  //   if (!!value) {
  //     const schema = Yup.string().matches(/^[a-zA-Z ]+$/).min(2).max(40);
  //     return schema.isValidSync(value);
  //   }
  //   return true;
  // }),
});

const passwordValidateSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required")
    .trim("white-space", "Old password should not contain white spaces")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, with Uppercase, Lowercase, Number and Special Case Character"
    ),
  newPassword: Yup.string().required("New password is required")
    .trim("white-space", "New password should not contain white spaces")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, with Uppercase, Lowercase, Number and Special Case Character"
    ),
  confirmPassword: Yup.string().required("Confirm password is required")
    .trim("white-space", "Confirm password should not contain white spaces")
    .oneOf([Yup.ref("newPassword")], "Passwords do not match!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, with Uppercase, Lowercase, Number and Special Case Character"
    ),
});

const MyProfileTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);
  const username = useSelector(({ username }) => username);

  const [changePassword, setChangePassword] = useState({ open: false, data: {} });
  const [enabled2faOtpModal, setEnabled2faOtpModal] = useState({ open: false, otp: null });
  const [typePassword, setTypePassword] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    username: "", first_name: "", last_name: "", password: "", email: "", phone_number: "",
    organization_id: userRes.id, company: userRes.company, is_active: 1, role: "OrgUser", status: "active"
  });

  const { control, register, handleSubmit, formState: { errors }, setError, setValue, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });

  const getOrganizationMember = () => {
    setIsLoading(true);
    AxiosApi.get(`/api/organization/list?companyName=${userRes.company}`).then((res) => {
      setOrgUsers(res.data);
      setIsLoading(false);
    }).catch((errors) => {
      console.log('✌️errors --->', errors);
    })
  };

  useEffect(() => {
    if (userRes?.role === "OrgAdmin") {
      getOrganizationMember();
    }

    reset({
      first_name: userRes.first_name,
      last_name: userRes.last_name,
      username: userRes.username,
      email: userRes.email,
      phone_number: userRes.phone_number,
      timezone: userRes.timezone,
      industry: userRes.industry,
      company: userRes.company,
    });

    const timeZones = momentTimezone.tz.names();
    const offsetTmz = [];
    for (const i in timeZones) {
      const timeZone = `${timeZones[i]} (GMT ${momentTimezone.tz(timeZones[i]).format('Z')})`;
      offsetTmz.push({ label: timeZone, value: timeZone });
    }
    setTimezoneOptions(offsetTmz);

    if (!userRes?.timezone) {
      const getCurrTimezone = `${momentTimezone.tz.guess()} (GMT ${momentTimezone.tz(momentTimezone.tz.guess()).format('Z')})`;
      setValue("timezone", getCurrTimezone);
    }
  }, [])

  const {
    register: pwRegister, handleSubmit: pwHandleSubmit, formState: { errors: pwErrors },
    clearErrors: pwClearErrors, reset: pwReset, setError: pwSetError, watch: pwWatch
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(passwordValidateSchema),
  });

  const handleClose = () => {
    setChangePassword({ open: false, data: {} });
    setEnabled2faOtpModal({ open: false, otp: null });
    setTypePassword([]);
    pwClearErrors();
    pwReset({ oldPassword: "", newPassword: "", confirmPassword: "" });
  }

  const onSubmit = (data) => {
    dispatch(setLoader(true));
    for (let key in data) {
      if (key !== "enabled_2FA") {
        if (data.hasOwnProperty(key)) {
          data[key] = data[key].trim();
        }
      }
    }
    AxiosApi.post(`/user/update`, { ...data, id: userRes.id }).then((res) => {
      dispatch(setLoader(false));
      dispatch(getUserDetail(userRes?.id));
      toast.success("User profile updated successfully.");
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
      console.log(error);
    });
  };

  const handleAvatar = async (e) => {
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size is greater than maximum limit", { id: "Toast-01" });
      return;
    }

    dispatch(setLoader(true));
    const filePath = await uploadPdfFileOnS3(file, "profileImage");
    dispatch(setUserRes({ ...userRes, profile_url: filePath }));
    AxiosApi.post(`/user/profile`, { userId: userRes?.id, profile: filePath }).then((res) => {
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
    });
  };

  const handleShowOrHidePassword = (no) => {
    const typePasswordArray = [...typePassword];
    if (typePasswordArray.includes(no)) {
      typePasswordArray.splice(typePassword.indexOf(no), 1);
    } else {
      typePasswordArray.push(no);
    }
    setTypePassword(typePasswordArray);
  }

  const onSubmitChangePassword = (value) => {
    const data = trimedInputValue(value)
    if (data.oldPassword === data.newPassword) {
      toast.error("Password is same, please insert a different new password", { id: "Toast-01" });
      return false;
    }

    if (data.newPassword !== data.confirmPassword) {
      pwSetError("confirmPassword", { type: "manual", message: "Passwords do not match!" });
      return false;
    } else {
      pwClearErrors("confirmPassword");
    }

    dispatch(setLoader(true));
    const body = { ...data, id: userRes.id };
    AxiosApi.post(`/user/update-password`, body).then((res) => {
      dispatch(setLoader(false));
      handleClose();
      toast.success("Password updated successfully", { id: "Toast-01" });
    }).catch((error) => {
      dispatch(setLoader(false));
      const errorMsg = error?.response?.data?.error?.message || error?.response?.data?.error || "Wrong username or password!";
      toast.error(errorMsg, { id: "Toast-01" });
    });
  }

  const handleOnChangeEnabled2FA = (e) => {
    if (!userRes.email || userRes.email === "") {
      setError("email", { type: "manual", message: "Please enter a valid email address where you'd like to receive the OTP for verification!" });
      return false;
    }

    const enabled_2FA = e.target.checked ? 1 : 0;

    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${enabled_2FA ? "Enabled" : "Disabled"} it!` },
    }).then((isConfirmed) => {
      if (isConfirmed) {
        dispatch(setLoader(true));
        AxiosApi.post(`/user/profile`, { userId: userRes?.id, enabled_2FA }).then((res) => {
          if (enabled_2FA === 1) {
            dispatch(setLoader(false));
            setEnabled2faOtpModal({ open: true, otp: null });
          } else {
            dispatch(setLoader(false));
            dispatch(setUserRes({ ...userRes, enabled_2FA: 0 }));
            Swal("Disabled!", "Two-factor authentication disabled successfully.", "success");
          }
        }).catch((error) => {
          dispatch(setLoader(false));
          toast.error("Something went wrong.", { id: "Toast-01" });
        });
      }
    });
  }

  const handleVerifyEnabled2FAOtp = () => {
    dispatch(setLoader(true));
    AxiosApi.post(`/user/profile`, { userId: userRes?.id, otp: enabled2faOtpModal.otp }).then((res) => {
      dispatch(setLoader(false));
      if (res && res.data && res.data.success === 1) {
        handleClose();
        dispatch(setUserRes({ ...userRes, enabled_2FA: 1 }));
        Swal("Enabled!", "Two-factor authentication enabled successfully.", "success");
      } else {
        toast.error(res.data?.message || "Something went wrong.", { id: "Toast-01" });
      }
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
    });
  };

  const handleOnInputChangeValue = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  }

  const formFieldArray = [
    { name: "username", label: "Username", placeholder: "Enter your username", type: "text" },
    { name: "first_name", label: "First name", placeholder: "Enter your First name", type: "text" },
    { name: "last_name", label: "Last name", placeholder: "Enter your Last name", type: "text" },
    { name: "password", label: "Password", placeholder: "Enter your Password", type: "password" },
    { name: "email", label: "Email", placeholder: "Enter your Email", type: "email" },
    { name: "phone_number", label: "Phone number", placeholder: "Enter your Phone number", type: "phone" },
  ]

  const handleAddMember = () => {
    if (orgUsers.length === 5) {
      toast.error("You can't add more than 5 members", { id: "Toast-01" });
      return false;
    }

    dispatch(setLoader(true));
    AxiosApi.post(`/api/organization/create-or-update`, inputs).then(() => {
      getOrganizationMember();
      setIsOpen(false);
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      console.log('✌️error --->', error);
    })
  };

  return (
    <div className="d-flex flex-column" style={{ gap: "32px" }}>
      <div className="accountCard">
        <div className="cardHead">
          <h3>My Profile</h3>
        </div>
        <div className="cardBody">
          <div className="profile-card-header d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="profile-image">
                {userRes?.profile_url ? (
                  <img src={userRes?.profile_url} alt="user" className="img-fluid rounded-circle box-shadow-1" />
                ) : (
                  <div className="profile-avatar box-shadow-1">{username[0]?.toUpperCase()}</div>
                )}
                <div className="addImg">
                  <button className="rounded-circle">
                    <BsCameraFill />
                  </button>
                  <input type="file" onChange={(e) => handleAvatar(e)} accept="image/png, image/jpg, image/jpeg" />
                </div>
              </div>
              <div className="profile-name">
                <div className="name-title">{userRes?.first_name || ""} {userRes?.last_name || ""}</div>
                <div className="username-title">{username ? `@${username}` : ""}</div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end gap-2">
              {userRes?.role === "OrgAdmin" && (
                <React.Fragment>
                  <span onClick={() => { setIsOpen(true) }} style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold', fontSize: 14, cursor: 'pointer' }}>
                    + Add member <i style={{ fontWeight: "lighter" }}> ( {orgUsers.length} / 5 Members )</i>
                  </span>
                  <div>
                    {isLoading ? (
                      <div className="d-flex">
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="circular" width={40} height={40} style={{ margin: '0px 0px 0px -10px' }} />
                        <Skeleton variant="circular" width={40} height={40} style={{ margin: '0px 0px 0px -10px' }} />
                      </div>
                    ) : (
                      <AvatarGroup max={4}>
                        {orgUsers && orgUsers.map((item) => (
                          <Avatar alt={item.first_name?.toUpperCase()} src={item.profile_url === null ? item.first_name[0] : item.profile_url}
                            style={{ cursor: "pointer", background: "linear-gradient(210deg, rgb(182 3 66 / 87%) 0%, rgb(48 2 241 / 78%) 100%)" }}
                            data-tooltip-id="my-tooltip" data-tooltip-place="bottom" data-tooltip-content={item?.first_name + " " + item?.last_name}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <form className="addllyFormWrap profile-form" onSubmit={handleSubmit(onSubmit)}>
            <Row className="">
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>First Name <span className="required-astrick" style={{ color: "red" }}>*</span></label>
                  <input type="text" {...register("first_name")} className="addllyForm-control" placeholder="Enter First Name" />
                </div>
                <div className="text-danger">{errors.first_name?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" {...register("last_name")} className="addllyForm-control" placeholder="Enter Last Name" />
                </div>
                <div className="text-danger">{errors.last_name?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Username</label>
                  <input type="text" {...register("username")} value={userRes.username} className="addllyForm-control" placeholder="Enter Username" disabled />
                </div>
                <div className="text-danger">{errors.username?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" {...register("email")} value={userRes.email} className="addllyForm-control" placeholder="Enter Email" disabled />
                </div>
                <div className="text-danger">{errors.email?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Phone Number <span className="required-astrick" style={{ color: "red" }}>*</span></label>
                  <input type="text" {...register("phone_number")} className="addllyForm-control" placeholder="Enter Phone Number" />
                </div>
                <div className="text-danger">{errors.phone_number?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Timezone</label>
                  <Controller
                    control={control} {...register("timezone")}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        className={`custom-select ${errors.toneOfVoice ? "is-invalid" : ""}`}
                        classNamePrefix="custom-select" placeholder="Select Tone of Voice"
                        options={timezoneOptions} isClearable={false}
                        value={timezoneOptions.find(c => c.value === value)}
                        onChange={(e) => onChange(e.value)} isSearchable={false}
                      />
                    )}
                  />
                </div>
                <div className="text-danger">{errors.timezone?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Industry</label>
                  <input type="text" {...register("industry")} className="addllyForm-control" placeholder="Enter Industry" />
                </div>
                <div className="text-danger">{errors.industry?.message}</div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Company</label>
                  <input
                    type="text" {...register("company")} className="addllyForm-control" placeholder="Enter Company"
                    disabled={userRes?.organization_id || userRes?.role === "OrgAdmin" ? true : false}
                  />
                </div>
                <div className="text-danger">{errors.company?.message}</div>
              </Col>
              <Col sm={12}>
                <div className="form-group mb-4">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                      {...register("enabled_2FA")} checked={userRes.enabled_2FA === 1 ? true : false}
                      onChange={handleOnChangeEnabled2FA}
                    />
                    <div>
                      <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Enabled 2FA</label> <br />
                      <label className="form-check-label-2" htmlFor="flexSwitchCheckChecked">
                        Two-factor authentication is an extra layer of security that requires you to enter a code from your email every-time when logging into your account.
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex">
              <button type="submit" className="addlly-primary w-auto" style={{ padding: "16px 24px" }}>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="accountCard">
        <div className="cardHead">
          <h3>Manage Password</h3>
        </div>
        <div className="cardBody">
          <form className="addllyFormWrap profile-form" onSubmit={pwHandleSubmit(onSubmitChangePassword)}>
            <Row className="">
              <Col sm={12} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Old Password</label>
                  <div className="iconFormGrp">
                    <input
                      name="oldPassword" type={!typePassword.includes(1) ? "password" : "text"} placeholder="Enter old password"
                      defaultValue={changePassword.data.oldPassword} {...pwRegister("oldPassword")}
                      className={`addllyForm-control ${pwErrors.oldPassword ? "is-invalid" : ""}`}
                    />
                    <button type="button" onClick={() => handleShowOrHidePassword(1)} className={`passwordIcon ${typePassword.includes(1) ? "active" : ""}`}
                      data-tooltip-id="my-tooltip" data-tooltip-content={`${typePassword.includes(1) ? "Hide" : "Show"} Password`} data-tooltip-place="bottom"
                    >
                      {typePassword.includes(1) ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </button>
                    <div className="invalid-feedback">{pwErrors.oldPassword?.message}</div>
                  </div>
                </div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>New Password</label>
                  <div className="iconFormGrp">
                    <input
                      name="newPassword" type={!typePassword.includes(2) ? "password" : "text"} placeholder="Enter new password"
                      defaultValue={changePassword.data.newPassword} {...pwRegister("newPassword")}
                      className={`addllyForm-control ${pwErrors.newPassword ? "is-invalid" : ""}`}
                    />
                    <button type="button" onClick={() => handleShowOrHidePassword(2)} className={`passwordIcon ${typePassword.includes(2) ? "active" : ""}`}
                      data-tooltip-id="my-tooltip" data-tooltip-content={`${typePassword.includes(2) ? "Hide" : "Show"} Password`} data-tooltip-place="bottom"
                    >
                      {typePassword.includes(2) ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </button>
                    <div className="invalid-feedback">{pwErrors.newPassword?.message}</div>
                  </div>
                </div>
              </Col>
              <Col sm={6} className="mb-sm-4 mb-3">
                <div className="form-group">
                  <label>Confirm Password</label>
                  <div className="iconFormGrp">
                    <input
                      name="confirmPassword" type={!typePassword.includes(3) ? "password" : "text"} placeholder="Enter password again"
                      defaultValue={changePassword.data.confirmPassword} {...pwRegister("confirmPassword")}
                      className={`addllyForm-control ${pwErrors.confirmPassword ? "is-invalid" : ""}`}
                    />
                    <button type="button" onClick={() => handleShowOrHidePassword(3)} className={`passwordIcon ${typePassword.includes(3) ? "active" : ""}`}
                      data-tooltip-id="my-tooltip" data-tooltip-content={`${typePassword.includes(3) ? "Hide" : "Show"} Password`} data-tooltip-place="bottom"
                    >
                      {typePassword.includes(3) ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </button>
                    <div className="invalid-feedback">{pwErrors.confirmPassword?.message}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex gap-3">
              <button type="submit" className="addlly-primary w-auto" style={{ padding: "16px 24px" }}
                disabled={(!pwWatch("oldPassword") || !pwWatch("newPassword") || !pwWatch("confirmPassword")) ? true : false}
              >
                Change Password
              </button>
              <button type="button" className="btn btn-reset w-auto" style={{ padding: "16px 24px" }} onClick={handleClose}>
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>

      {isOpen && (
        <Offcanvas placement="end" show={isOpen} onHide={() => setIsOpen(false)}>
          <Offcanvas.Header className="justify-content-between align-items-center p-0 border-0">
            <div className="d-flex align-item-center gap-3">
              <h4>Add Member</h4>
            </div>
            <button type="button" className="btn-close text-reset p-0" onClick={() => setIsOpen(false)} ></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="cardBody p-0">
            <div className="text-start">
              <div className="addllyFormWrap mt-2">
                {formFieldArray && formFieldArray?.map((field, index) => (
                  <div className="form-group mb-4" key={index}>
                    <label className="text-muted">{field.label}</label>
                    <input
                      name={field.name} type={field.type} placeholder={field.placeholder}
                      value={inputs[field.name]} onChange={handleOnInputChangeValue} required
                      className={`addllyForm-control ${errors[field.name] ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors[field.name]?.message}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <button onClick={() => handleAddMember()} type="submit" className="addlly-primary border-0">
                <span>Add Member</span>
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}

      {enabled2faOtpModal && enabled2faOtpModal.open && (
        <Modal centered show={enabled2faOtpModal.open} onHide={handleClose}>
          <Modal.Header closeButton>
            <h4> Enabled 2FA OTP </h4>
          </Modal.Header>
          <Modal.Body className="">
            <Row className="addllyFormWrap">
              <Col sm="12">
                <div className="form-group mb-4">
                  <label>OTP Sent on Mail</label>
                  <div className="iconFormGrp">
                    <input
                      name="otp" type={"text"} placeholder="Enter OTP" value={enabled2faOtpModal.otp} className="addllyForm-control"
                      onChange={(e) => setEnabled2faOtpModal({ ...enabled2faOtpModal, otp: e.target.value })}
                    />
                    <div className="invalid-feedback">{pwErrors.oldPassword?.message}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <button className="addlly-primary ms-auto w-auto" type="button" variant="primary"
              disabled={enabled2faOtpModal.otp && enabled2faOtpModal.otp.length > 5 ? false : true}
              onClick={() => enabled2faOtpModal.otp && enabled2faOtpModal.otp.length > 5 ? handleVerifyEnabled2FAOtp() : null}
            >
              Verify
            </button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default MyProfileTab;
