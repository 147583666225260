import React, { useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "../../store/reducer";
import { getUserDetail } from "../../utility/apiService";
import { AxiosApi } from "../../utility/axios";
import { trimedInputValue } from "../../utility/hepler";

const validationSchema = Yup.object().shape({
  ceo_name: Yup.string().test('ceo_name', 'CEO name should contain only letters and be between 2 and 40 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(2).max(40);
      return schema.isValidSync(value);
    }
    return true;
  }),
  web_link: Yup.string().test('web_link', 'Must be a valid website link!', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/).trim("white-space", "Web link should not contain white spaces");
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_name: Yup.string().test('contact_name', 'Contact name should contain only letters and be between 2 and 40 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(2).max(40)
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_email: Yup.string().test('contact_email', 'Must be a valid contact email!', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/);
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_phone_number: Yup.string().test('contact_phone_number', 'Contact phone number should contain only numbers and be between 8 and 13 digits.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[0-9]+$/).min(8).max(13).trim("white-space", "Contact phone number should not contain white spaces");
      return schema.isValidSync(value);
    }
    return true;
  }),
  contact_address: Yup.string().test('contact_address', 'Contact address should contain only letters, numbers and be between 2 and 255 characters.', function (value) {
    if (!!value) {
      const schema = Yup.string().matches(/^[a-zA-Z0-9'\.\-\s\,]+$/).min(2).max(255);
      return schema.isValidSync(value);
    }
    return true;
  }),
});

const CompanyPreferenceTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {}, mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (userRes && userRes.companyDetails) {
      reset({ ...userRes.companyDetails });
    }
  }, [])

  const onSubmit = (value) => {
    const data = trimedInputValue(value)
    const companyPref = {
      id: userRes.id,
      companyDetails: { ...data }
    };

    dispatch(setLoader(true));
    AxiosApi.post(`/user/update`, companyPref).then((res) => {
      dispatch(setLoader(false));
      dispatch(getUserDetail(userRes?.id));
      toast.success("Company preferences updated successfully.");
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.", { id: "Toast-01" });
      console.log(error);
    });
  };

  return (
    <div className="accountCard">
      <div className="cardHead">
        <h3>Company Preference</h3>
        <p>Add your company and media contact details here</p>
      </div>
      <div className="cardBody">
        <form className="addllyFormWrap" onSubmit={handleSubmit(onSubmit)}>
          <Row sm="12" className="">
            <Col md="6">
              <div className="form-group mb-2">
                <label>Company’s CEO</label>
                <input type="text" {...register("ceo_name")}
                  className={`addllyForm-control ${errors.link ? "is-invalid" : ""}`} placeholder="Enter CEO’s Name"></input>
              </div>
              <div className="text-danger mt-1">{errors.ceo_name?.message}</div>
            </Col>
            <Col md={6}>
              <div className="form-group mb-2">
                <label>Website Link</label>
                <input type="text" {...register("web_link")}
                  className="addllyForm-control" placeholder="Enter website's URL" />
              </div>
              <div className="text-danger mt-1">{errors.web_link?.message}</div>
            </Col>
            <Col md={4}>
              <div className="form-group mb-2">
                <label>Point of Contact’s Name</label>
                <input type="text" {...register("contact_name")}
                  className="addllyForm-control" placeholder="Insert contact name" />
              </div>
              <div className="text-danger mt-1">{errors.contact_name?.message}</div>
            </Col>
            <Col md={4}>
              <div className="form-group mb-2">
                <label>Contact Email</label>
                <input type="text" {...register("contact_email")}
                  className="addllyForm-control" placeholder="Enter email" />
              </div>
              <div className="text-danger mt-1">{errors.contact_email?.message}</div>
            </Col>
            <Col md={4} >
              <div className="form-group mb-2">
                <label>Contact Phone Number</label>
                <input type="text" {...register("contact_phone_number")}
                  className="addllyForm-control" placeholder="Insert phone number" />
              </div>
              <div className="text-danger mt-1">{errors.contact_phone_number?.message}</div>
            </Col>
            <Col md={12}>
              <div className="form-group mb-2 addllyFormWrap">
                <label>Company’s Address</label>
                <textarea
                  style={{ resize: "none", padding: "16px", border: '1px solid #e4e4e7' }}
                  name="contact_address" type="text" {...register("contact_address")} rows="3"
                  className={`form-control shadow-none textArea-outlined`} placeholder="Enter address"
                  onFocus={(e) => e.target.style.border = '1px solid blue'}
                  onBlur={(e) => e.target.style.border = '1px solid #e4e4e7'}
                />
              </div>
              <div className="text-danger mt-1">{errors.contact_address?.message}</div>
            </Col>
          </Row>
          <div className="d-flex">
            <button type="submit" className="addlly-primary w-auto">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyPreferenceTab;
