import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { CiMicrochip } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import { FaArrowRightLong } from "react-icons/fa6";
import { BiSolidErrorCircle } from "react-icons/bi";
import { RxOpenInNewWindow } from "react-icons/rx";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PiArrowSquareDownFill, PiArrowSquareUpFill, PiFilePdfLight } from "react-icons/pi";
import { BsArrowRight, BsHandThumbsDown, BsHandThumbsDownFill, BsHandThumbsUp, BsHandThumbsUpFill, BsInfoCircleFill } from "react-icons/bs";
import { setLoader, setUserRes } from "../../store/reducer";
import { AxiosApi } from "../../utility/axios";
import { oneClickBlogIcon } from "../../Assets/Icons";
import { uploadPdfFileOnS3 } from "../../utility/uploadPdfFileOnS3";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";

const SecondStepNewsletters = ({ setCurrStep, shiseidoNewslettersInfo, setShiseidoNewslettersInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRes = useSelector(({ userRes }) => userRes);
  const { id: articleId } = useParams();

  const [articleList, setArticleList] = useState({});
  const [selectedArticle, setSelectedArticle] = useState([]);
  const [viewArticle, setViewArticle] = useState({ open: false, data: null });
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [isLoading, setIsLoading] = useState({ articleId: 0, activity: "", status: false });
  const [blogLink, setBlogLink] = useState("");
  const [accordionState, setAccordionState] = useState({});
  const [articlesState, setArticlesState] = useState([]);
  const [pdfFiles, setPdfFiles] = useState({});

  const handleCheckboxChange = (index) => {
    setArticlesState(prevState =>
      prevState.map((state, i) => i === index ? { ...state, open: !state.open } : state)
    );
  };

  const otherOptions = [
    { label: "Beauty and Skincare Trends", value: "Beauty and Skincare Trends" },
    { label: "K-Beauty Trends", value: "K-Beauty Trends" },
    { label: "Competitor Headlines", value: "Competitor Headlines" },
    { label: "Ingredient and Innovation", value: "Ingredient and Innovation" },
    { label: "Shiseido News", value: "Shiseido News" },
    { label: "Studies and Surveys", value: "Studies and Surveys" },
  ];

  const toggleAction = (category) => {
    setAccordionState((prev) => ({ ...prev, [category]: !prev[category] }));
  }

  useEffect(() => {
    if (shiseidoNewslettersInfo && shiseidoNewslettersInfo.category) {
      getWebArticleHistory(shiseidoNewslettersInfo.category);
    }

    if (shiseidoNewslettersInfo && shiseidoNewslettersInfo.article && shiseidoNewslettersInfo.article !== "") {
      setIsDisabledInput(true);
    }
    if (shiseidoNewslettersInfo && shiseidoNewslettersInfo.selectedArticle && shiseidoNewslettersInfo.selectedArticle.length > 0) {
      const tempArray = [];
      for (let index = 0; index < shiseidoNewslettersInfo.selectedArticle.length; index++) {
        const element = shiseidoNewslettersInfo.selectedArticle[index];
        tempArray.push(element.web_url);
      }
      setSelectedArticle(tempArray);
    }
  }, []);

  const getWebArticleHistory = async (category) => {
    dispatch(setLoader(true)); setArticleList({});
    if (category === "Beauty & Skincare Trends") {
      category = "Beauty and Skincare Trends";
    }
    const url = `/api/cmi-shiseido/history-or-details/byType/shiseido-category?category=${category}`;
    AxiosApi.get(url).then((response) => {
      if (response.data && response.data && Object.keys(response.data).length > 0) {
        setArticleList(response.data);
        setAccordionState((prev) => ({
          ...prev, [Object.keys(response.data)[0]]: true
        }));
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  };

  const handleTopicSelection = (event, webUrl = null) => {
    if (event.target.checked) {
      if (selectedArticle.length < 3) {
        setSelectedArticle((prevState) => [...prevState, webUrl]);
        setArticlesState((prevState) => ([...prevState, { open: false, url: webUrl, content: "", pdf: "" }]));
      }
    } else {
      const newArray = selectedArticle.filter((item) => item !== webUrl);
      setSelectedArticle(newArray);
      setArticlesState(articlesState.filter((item) => item.url !== webUrl));
    }
  };

  const handleNextStep = async (selectedData) => {
    try {
      if (articleId && articleId !== "" && articleId !== "type") {
        setCurrStep(3);
        return false;
      }

      if (!selectedData || selectedData.length === 0) {
        toast.error(`Select any 3 article to generate Newsletter.`, { id: "Toast-01" });
        return false;
      }

      dispatch(setLoader(true));
      const selectedFinalArticles = [];
      for (let index1 = 0; index1 < selectedData.length; index1++) {
        const element = selectedData[index1];
        for (let index2 = 0; index2 < Object.keys(articleList).length; index2++) {
          const articleTempList = articleList[Object.keys(articleList)[index2]];
          const article = articleTempList.find((article) => article.web_url === element);
          const articleObj = articlesState.find((article) => article.url === element);
          if (article && article.web_url) {
            if (articleObj && articleObj.url) {
              article.userDesc = articleObj.content || "";
              article.userPdf = articleObj.pdf || "";
            }
            selectedFinalArticles.push(article);
            break;
          }
        }
      }

      const payload = {
        body: JSON.stringify({
          userId: userRes.id,
          category: shiseidoNewslettersInfo?.category || "",
          selectedArticle: selectedFinalArticles,
        })
      };

      const response = await invokeLambdaFunction("generateShiseidoCMINewsletters", payload);
      dispatch(setLoader(false));
      if (response && response.success === 1 && response.data && response.data.content) {
        setShiseidoNewslettersInfo({
          ...shiseidoNewslettersInfo, stepDone: 3, article: response.data.content,
          id: response.data.id, category: shiseidoNewslettersInfo?.category || "",
          selectedArticle: selectedFinalArticles,
        });
        dispatch(setUserRes({ ...userRes, credits: userRes.credits - 3 }));
        setCurrStep(3); setIsDisabledInput(true); navigate(`/cmi-newsletters/${response.data?.id}`);
      } else {
        toast.error("Newsletter not generated, Please try with different article.", { id: "Toast-01" });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  };

  const handleCheckAndAddBlogLink = async (webLink, pdfLink, source) => {
    if (webLink && webLink !== "") {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      if (!urlRegex.test(webLink)) {
        toast.error("URL is not a valid, Please insert a valid URL.");
        return false;
      }
    }

    dispatch(setLoader(true));
    const payload = {
      pathParameters: { type: "add-article-by-category" },
      body: JSON.stringify({
        webLink: webLink || "", pdfLink: pdfLink || "", source: source,
        userId: userRes.id, category: shiseidoNewslettersInfo.category,
      })
    };

    const response = await invokeLambdaFunction("generateShiseidoOrSaveByType", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1) {
      if (response.data && response.data.data && Object.keys(response.data.data).length > 0) {
        setArticleList(response.data.data);
      }
      setBlogLink(""); setPdfFiles((prev) => ({ ...prev, article: {} }));
      toast.success("Article added successfully.", { id: "Toast-01" });
    } else {
      toast.error(response?.error || "Blog link or pdf file is not valid.", { id: "Toast-01" });
    }
  };

  const handleSetLikeOrDisLikeArticle = async (activity, articleId) => {
    try {
      setIsLoading({ articleId, activity, status: true });
      const payloadData = { articleId, activity, userId: userRes.id };
      const response = await AxiosApi.post(`/api/cmi-shiseido/generate-or-save/byType/handle-like-dislike-article`, payloadData);

      if (response && response.data && response.data.success === 1) {
        if (response.data && response.data.data && Object.keys(response.data.data).length > 0) {
          setArticleList(response.data.data);
          setIsLoading({ articleId: "", activity: "", status: false });
        } else {
          const like = activity === "like" ? 1 : activity === "unlike" ? 0 : 0;
          const dislike = activity === "dislike" ? 1 : activity === "undislike" ? 0 : 0;

          const articleTempList = { ...articleList };
          Object.keys(articleTempList).forEach(key => {
            const findIndex = articleTempList[key].findIndex((item) => item.id === articleId);
            if (findIndex !== -1) {
              const tempProduct = { ...articleTempList[key][findIndex] };
              tempProduct.like = like;
              tempProduct.dislike = dislike;
              articleTempList[key][findIndex] = tempProduct;
            }
          });
          setArticleList({ ...articleTempList });
          setIsLoading({ articleId: "", activity: "", status: false });
        }
      } else {
        toast.error("Something went wrong, Please try again!");
        setIsLoading({ articleId: "", activity: "", status: false });
      }
    } catch (error) {
      setIsLoading({ articleId: "", activity: "", status: false });
      toast.error("Server overload, Please try again!");
    }
  }

  const updateArticleCategory = (category, articleId) => {
    dispatch(setLoader(true));
    AxiosApi.post(`/api/cmi-shiseido/generate-or-save/byType/update-article-category`, { articleId, category }).then((response) => {
      const articleTempList = { ...articleList };
      Object.keys(articleTempList).forEach(key => {
        articleTempList[key] = articleTempList[key].filter((id) => id.id !== articleId);
      });
      setArticleList({ ...articleTempList });
      dispatch(setLoader(false));
    }).catch((error) => {
      console.log("#error --->", error);
      dispatch(setLoader(false));
    })
  }

  const FileUploaderCustomUi = ({ data, type }) => (
    <div className="file-upload-content">
      <PiFilePdfLight className="image-icon mb-4" />
      <h4 className="mb-3 fw-bold text-center">Upload your product PDF to get started</h4>
      <p className="mb-1 text-muted text-center">{type === "article" ? <>Click to upload or drag and drop <br /></> : ""} maximum file size: 6MB.</p>
      {data["uploadFileError"] ? (
        <React.Fragment>
          <h5 className="fw-normal text-danger d-flex align-items-center">
            <BiSolidErrorCircle className="fs-4 me-2" /> {data["uploadFileError"]}
          </h5>
        </React.Fragment>
      ) : data["file"] ? (
        <h5 className="fw-normal text-primary text-center">
          {data["file"] && data["file"].name ? data["file"].name : ""}
        </h5>
      ) : null}
    </div>
  );

  const handleOnUploadPdfFile = async (file, type, source) => {
    const MAX_FILE_SIZE = 6144; // 6 MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size exceeds limit. Please upload a file no larger than 6 MB.", { id: "Toast-01" });
      return false;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "pdf") {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      return false;
    }

    if (file.type !== "application/pdf") {
      toast.error("Uploaded file is not a PDF, Please upload a valid PDF file.", { id: "Toast-01" });
      return false;
    }

    setPdfFiles((prev) => ({ ...prev, [type]: { ...prev[type], file: file, uploadFileError: "" } }));
    if (type === "article") {
      dispatch(setLoader(true));
      const filePath = await uploadPdfFileOnS3(file, "pressRelease");
      if (filePath && filePath !== "") {
        handleCheckAndAddBlogLink("", filePath, source);
      } else {
        dispatch(setLoader(false));
        toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      }
    } else {
      dispatch(setLoader(true));
      const filePath = await uploadPdfFileOnS3(file, "pressRelease");
      if (filePath && filePath !== "") {
        dispatch(setLoader(false));
        setArticlesState(prevState => prevState.map((state) => state.url === type ? { ...state, pdf: filePath } : state));
      } else {
        dispatch(setLoader(false));
        toast.error("File not uploaded successfully, please try again.", { id: "Toast-01" });
      }
    }
  };

  return (
    <div className="addllyFormWrap">
      <div className="selectBlock d-flex align-items-center justify-content-between">
        <div><b>Select Articles</b> <span>(Select Max 3)</span></div>
        <button
          type="button" className="d-flex align-items-center gap-2 bg-transparent border-0 selectBbutton"
          onClick={() => handleNextStep(selectedArticle)} disabled={selectedArticle && selectedArticle.length > 0 ? false : true}
        >
          Generate Newsletter <BsArrowRight />
        </button>
      </div>
      <Row className="eCommerce-blog-wrapper m-0 mw-100 p-0 mb-3">
        <Col sm="12" md="3" className="side-menu ps-0 ps-md-3 pe-0 pe-md-3 border-end mb-3">
          <div className="selected-products mt-3 m-3 m-md-0" >
            <div className="d-flex justify-content-between flex-column align-content-center mb-3">
              <label className="mb-1">Selected Category</label>
              <h5 style={{ fontSize: "20px" }}>{shiseidoNewslettersInfo.category}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-between flex-wrap align-content-center mb-3">
              <label className="m-0">Selected Blogs</label>
              {selectedArticle && selectedArticle.length > 0 && (
                <button className="btn  p-0 " onClick={() => { setSelectedArticle([]); setArticlesState([]); }} disabled={isDisabledInput}>
                  Clear All
                </button>
              )}
            </div>
            {selectedArticle && selectedArticle.length > 0 ? (
              <div>
                {selectedArticle.map((article, index) => (
                  <p className="mb-3" key={index}>
                    <Link to={article} target="_blank" className="text-decoration-none">{article}</Link>
                    <div className={`py-3 ${selectedArticle.length !== index + 1 ? "border-bottom" : ""}`}>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          id={`add_desc_${index + 1}`} type="checkbox" style={{ cursor: 'pointer' }}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={shiseidoNewslettersInfo?.selectedArticle && shiseidoNewslettersInfo.selectedArticle?.length > 0 ? true : false}
                        />
                        <label htmlFor={`add_desc_${index + 1}`} className="cursor-pointer m-0 text-dark">Add Description</label>
                      </div>
                      {articlesState[index]?.open && (
                        <div className="pt-2">
                          <div className="fields form-group p-3 mb-2 p-md-0">
                            <label htmlFor="" className="text-muted">Insert description</label>
                            <textarea
                              className="addllyForm-control" placeholder="Insert your description here"
                              rows={2} style={{ height: "auto" }} value={articlesState[index]?.content || ""}
                              disabled={articlesState[index]?.pdf ? true : false}
                              onChange={(e) => {
                                setArticlesState(articlesState.map(item =>
                                  item.url === article ? ({ ...item, content: e.target.value, open: true }) : item
                                ))
                              }}
                            />
                          </div>
                          <div className="border-2">
                            <label htmlFor="" className="text-muted">Upload Pdf</label>
                            <FileUploader
                              name="file" types={["PDF"]} maxSize={5} multiple={false} dropMessageStyle={{ display: "none" }}
                              children={<FileUploaderCustomUi data={pdfFiles[article] || {}} type={article} />}
                              handleChange={(file) => handleOnUploadPdfFile(file, article)}
                              classes={`file-upload-wrapper py-3 ${pdfFiles[article] && pdfFiles[article]["uploadFileDragging"] ? "active" : ""}`}
                              onDraggingStateChange={(dragging) => setPdfFiles((prev) => ({ ...prev, [article]: { ...prev[article], uploadFileDragging: dragging } }))}
                              onSizeError={() => setPdfFiles((prev) => ({ ...prev, [article]: { ...prev[article], uploadFileError: "File size exceeds the allowable limit. Please upload a smaller file." } }))}
                              onTypeError={() => setPdfFiles((prev) => ({ ...prev, [article]: { ...prev[article], uploadFileError: "Invalid file type. Please upload a file with a supported file type." } }))}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </p>
                ))}
              </div>
            ) : (
              <h5 className="fw-normal text-danger">
                You don’t have any blog selected. Please select or add a few blogs to continue.
              </h5>
            )}
          </div>
        </Col>
        <Col sm="12" md="9" className="p-0">
          <div className="news-letters-templates e-com-templates">
            {articleList && Object.keys(articleList).length > 0 && (
              <React.Fragment>
                {Object.keys(articleList).map((category, index) => (
                  <div className="genrateFields p-0 mb-4 shadow-sm rounded-2" key={index}>
                    <div
                      className="d-flex align-items-center shiseido-label justify-content-between py-2 px-4 mb-3 shadow-sm rounded cursor-pointer"
                      style={{
                        background: category === "Irrelevant Articles" ? "linear-gradient(90deg, rgb(130 72 157 / 45%) 0%, rgb(198 49 47 / 48%) 100%)" : "linear-gradient(102.77deg, rgba(0, 0, 255, 0.05) 0%, rgba(255, 0, 0, 0.05) 121.74%)",
                        borderColor: "#dee2e6", color: category === "Irrelevant Articles" && "black"
                      }}
                      onClick={() => toggleAction(category)}
                    >
                      <h3 className="fw-normal" style={{ fontSize: "22px" }}>{category}</h3>
                      <button type="button" title={accordionState[category] ? "Collapse" : "Expand"} className="btn p-0" style={{ color: category === "Irrelevant Articles" ? "black" : "var(--primary-main)" }}>
                        {accordionState[category] ? <PiArrowSquareUpFill className="fs-3" /> : <PiArrowSquareDownFill className="fs-3" />}
                      </button>
                    </div>
                    {accordionState[category] && (
                      <Row className="eCommerce-blog-wrapper mb-3 mw-100 pb-0">
                        {category !== "Irrelevant Articles" && (
                          <Col sm="12" md="4" className="p-3 pt-0">
                            <div className="card border-0 h-100">
                              <div className=" card-body rounded-3 h-100 pt-3 p-0" style={{
                                background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.08) 0%, rgba(255, 0, 0, 0.05) 121.74%)",
                              }}>
                                <div className="fieldSetText position-relative">
                                  <p className="d-flex align-items-center gap-2 m-0 px-3">
                                    <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Add Own Blog Link
                                  </p>
                                </div>
                                <div className="border-2 p-3">
                                  <div className="fields form-group p-3 mb-2 p-md-0">
                                    <input
                                      type="text" className="addllyForm-control" placeholder="Insert your blog link here"
                                      value={blogLink} onChange={(e) => setBlogLink(e.target.value)}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      type="button" className="addlly-primary px-4 py-2" style={{ maxWidth: "fit-content" }}
                                      onClick={() => handleCheckAndAddBlogLink(blogLink, "", category)}
                                      disabled={blogLink && blogLink !== "" && /^(ftp|http|https):\/\/[^ "]+$/.test(blogLink) && selectedArticle.length < 3 ? false : true}
                                    >
                                      <span style={{ fontSize: "14px" }}>Add Blog</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="fieldSetText position-relative">
                                  <p className="d-flex align-items-center gap-2 m-0 px-3">
                                    <img src={oneClickBlogIcon.glitterStar} alt="glitterStar" /> Add Own Blog PDF
                                  </p>
                                </div>
                                <div className="border-2 p-3">
                                  <FileUploader
                                    name="file" types={["PDF"]} maxSize={5} multiple={false} dropMessageStyle={{ display: "none" }}
                                    handleChange={(file) => handleOnUploadPdfFile(file, "article", category)}
                                    children={<FileUploaderCustomUi data={pdfFiles["article"] || {}} type="article" />}
                                    classes={`file-upload-wrapper py-3 ${pdfFiles["article"] && pdfFiles["article"]["uploadFileDragging"] ? "active" : ""}`}
                                    onDraggingStateChange={(dragging) => setPdfFiles((prev) => ({ ...prev, article: { ...prev.article, uploadFileDragging: dragging } }))}
                                    onSizeError={() => setPdfFiles((prev) => ({ ...prev, article: { ...prev.article, uploadFileError: "File size exceeds the allowable limit. Please upload a smaller file." } }))}
                                    onTypeError={() => setPdfFiles((prev) => ({ ...prev, article: { ...prev.article, uploadFileError: "Invalid file type. Please upload a file with a supported file type." } }))}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                        {articleList && articleList[category] && articleList[category].length > 0 ? articleList[category].map((article, index) => (
                          <Col sm="6" md="6" lg="4" className="pb-3 article" key={index}>
                            <div className={`h-0 card-product image-wrapper rounded-3 p-2 pt-0 ${selectedArticle && selectedArticle.includes(article.web_url) ? "active" : ""}`}
                              style={{
                                // boxShadow: "0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1125)",
                                background: "linear-gradient(102.77deg, rgba(0, 0, 255, 0.08) 0%, rgba(255, 0, 0, 0.05) 121.74%)",
                                border: selectedArticle && selectedArticle.includes(article.web_url) ? "1px solid #4D3AFF" : ""
                              }}>
                              <div className="d-flex justify-content-between align-items-center">
                                <input
                                  type="checkbox" className="custom-checkbox customize-check cursor-pointer border-1"
                                  style={{ margin: "7px 0px 6px 3px" }} disabled={isDisabledInput || (selectedArticle.length >= 3 && !selectedArticle.includes(article.web_url))}
                                  onChange={(event) => handleTopicSelection(event, article.web_url)}
                                  checked={selectedArticle.includes(article.web_url) ? true : false}
                                />
                                <a href={article.web_url} target="_blank" rel="noreferrer" title="View Article Page"
                                  className="text-decoration-none d-flex align-items-center justify-content-center gap-1 rounded"
                                  style={{ color: "#4D3AFF", height: "42px" }}
                                >
                                  View Article <RxOpenInNewWindow size={18} />
                                </a>
                              </div>
                              <img src={article.image_url} alt={article.title} className="object-fit-cover rounded" />
                              <div className="card-product-info px-2 pb-1 d-flex flex-column h-auto" style={{ background: "none" }}>
                                <p className="mb-2" data-tooltip-id="my-tooltip" data-tooltip-content={article.title} data-tooltip-place="bottom">
                                  {article.title}
                                </p>
                                <p className="mb-2" style={{ "-webkit-line-clamp": "1" }}>{article.date}</p>
                                <div className="d-flex align-items-center flex-wrap justify-content-between mt-3">
                                  <div className="d-flex justify-content-between align-items-center w-100 gap-3">
                                    <p className="read-btn my-2 py-2 px-3 d-flex align-items-center w-100 justify-content-center gap-1 rounded"
                                      style={{ cursor: "pointer", backgroundColor: "#cad5fb", color: "blue", width: "65%" }}
                                      onClick={() => setViewArticle({ open: true, data: article })}> AI summary <i><CiMicrochip size={25} /></i>
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center gap-2 w-100">
                                    <div className="d-flex align-items-center w-75 thumbs-icon">
                                      <Select
                                        className={`custom-select cmi-dropdown`} isClearable={false} isSearchable={false}
                                        classNamePrefix="custom-select" placeholder="Change Category" menuPosition="fixed"
                                        options={otherOptions.filter((item) => item.value !== article.category)}
                                        onChange={(event) => { updateArticleCategory(event.value, article.id) }}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center gap-2 w-10 thumbs-icon">
                                      {isLoading.activity === "unlike" && isLoading.status && isLoading.articleId === article.id ? <div className="loaderDiv"></div> : <>
                                        {article.like ? (
                                          <BsHandThumbsUpFill title="Like" size={24} className="thumbs-up active" onClick={() => handleSetLikeOrDisLikeArticle("unlike", article.id)} />
                                        ) : isLoading.activity === "like" && isLoading.status && isLoading.articleId === article.id ? <div className="loaderDiv"></div> : (
                                          <BsHandThumbsUp title="Like" size={24} className="thumbs-up" onClick={() => handleSetLikeOrDisLikeArticle("like", article.id)} />
                                        )}
                                      </>}
                                      {isLoading.activity === "undislike" && isLoading.status && isLoading.articleId === article.id ? <div className="loaderDiv"></div> : <>
                                        {article.dislike ? (
                                          <BsHandThumbsDownFill title="Dislike" size={24} className="thumbs-down active" onClick={() => handleSetLikeOrDisLikeArticle("undislike", article.id)} />
                                        ) : isLoading.activity === "dislike" && isLoading.status && isLoading.articleId === article.id ? <div className="loaderDiv"></div> : (
                                          <BsHandThumbsDown title="Dislike" size={24} className="thumbs-down" onClick={() => handleSetLikeOrDisLikeArticle("dislike", article.id)} />
                                        )}
                                      </>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )) : null}
                      </Row>
                    )}
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
          <div className="buttonDiv gap-0">
            <Button
              className="button" onClick={() => handleNextStep(selectedArticle)}
              disabled={selectedArticle && selectedArticle.length > 0 ? false : true}
            >
              Generate Newsletter <FaArrowRightLong className="ms-2" />
            </Button>
            <BsInfoCircleFill
              className="text-decoration-none outline-0 infoIconSvg " data-tooltip-id={"my-tooltip"} data-tooltip-place="right"
              data-tooltip-content={`Will utilize 3 Addlly credits`}
            />
          </div>
        </Col>
      </Row>

      {viewArticle && viewArticle.open && (
        <ViewArticlePreviewModal viewArticle={viewArticle} setViewArticle={setViewArticle} />
      )}
    </div>
  )
};

const ViewArticlePreviewModal = ({ viewArticle, setViewArticle }) => {
  const handleClose = () => {
    setViewArticle({ open: false, data: null });
  };

  return (
    <React.Fragment>
      {viewArticle && viewArticle.open && (
        <Modal size="xl" centered show={viewArticle && viewArticle.open} onHide={handleClose} className="e-com-product-modal">
          <Modal.Body>
            <div className="product-view-modal">
              <div className="close-button" type="button" onClick={handleClose} onKeyDown={handleClose}>
                <MdOutlineClose className="fs-4" />
              </div>
              <Row className="mt-4">
                <Col sm="12" className="px-5">
                  <h3 className="fw-normal mb-3">
                    {(viewArticle?.data && viewArticle.data.title) || "-"}
                  </h3>
                  <h4 className="fw-normal mb-3">
                    {(viewArticle?.data && viewArticle.data.date) || "-"}
                  </h4>
                  <hr />
                  <h5 className="fw-normal mb-3 text-muted">
                    {(viewArticle?.data && viewArticle.data.summary_content) || "-"}
                  </h5>
                  <a href={viewArticle?.data && viewArticle.data.web_url} target="_blank" rel="noreferrer"
                    className="text-decoration-none d-flex align-items-center gap-2 my-2 py-2 px-3 rounded"
                    style={{ backgroundColor: "#E6EBFF", color: "blue", maxWidth: "max-content" }}>
                    View Article
                    <i><GoLinkExternal size={18} /></i>
                  </a>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
};

export default SecondStepNewsletters;
