import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import * as htmlToImage from "html-to-image";
import { Col, Modal, Row } from "react-bootstrap";
import { FaRegSave } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { PiFilePdfDuotone, PiFilePngDuotone } from "react-icons/pi";
import { setLoader } from "../../store/reducer";
import { AxiosApi } from "../../utility/axios";
import { handleExportToWordOrPdfArticle } from "../../utility/hepler";
import { BsStars } from "react-icons/bs";
import VersionHistory from "../commonComponents/VersionHistory";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { invokeLambdaFunction } from "../../utility/invokeLamdaFunction";
const beautify_html = require("js-beautify").html;
let editor = null;

const ThirdStepNewsletters = ({ setCurrStep, shiseidoNewslettersInfo, setShiseidoNewslettersInfo }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { article } = shiseidoNewslettersInfo;
  const { id: articleId } = useParams();
  const userRes = useSelector(({ userRes }) => userRes);

  const [editorKey, setEditorKey] = useState(0);
  const [viewArticle, setViewArticle] = useState({ open: false, data: null });
  const [articleText, setArticleText] = useState(article);
  const [feedbackText, setFeedbackText] = useState("");
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [templateEditor, setTemplateEditor] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [versionHistory, setVersionHistory] = useState([]);
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [viewRegenerateModel, setViewRegenerateModel] = useState(false);
  const articleType = "CMI NewsLetters";

  useEffect(() => {
    setSelectedVersionItem({});
    if (shiseidoNewslettersInfo && shiseidoNewslettersInfo.article) {
      setArticleText(shiseidoNewslettersInfo.article || "");
      editorInitialize(shiseidoNewslettersInfo.article || "");
      setFinalHtmlText(shiseidoNewslettersInfo.article || "");
      setEditorKey(editorKey + 1);
    }
  }, []);

  useEffect(() => {
    getArticleAllVersionHistory("NewsLetter Article");
  }, [articleId]);

  const getArticleAllVersionHistory = async (versionSubType) => {
    try {
      if (!articleId || !versionSubType) {
        return false;
      }
      setVersionHistory([]);
      const params = `articleId=${articleId}&type=${articleType}&subType=${versionSubType}&isVersionList=true`;
      const res = await AxiosApi.get(`/api/previous-generated-content/list/${userRes.id}?${params}`);
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setVersionHistory(res?.data?.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const editorInitialize = (articleText) => {
    const htmlText = articleText || "";
    const doc = new DOMParser().parseFromString(htmlText, "text/html");
    const styleElement = doc.querySelectorAll("style")[0];
    const body = doc.body;

    let cmiEditor = editor = grapesjs.init({
      container: editorRef.current,
      storageManager: false,
      showDevices: false,
      plugins: ["gjs-blocks-basic"],
      components: body.outerHTML,
      style: styleElement.innerHTML,

      blockManager: {
        blocks: [
          {
            id: "column1",
            label: '1 column',
            media: `<svg width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"></path></svg>`,
            activate: true,
            content: {
              type: 'Row',
              activate: true,
              components: [
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    display: 'flex',
                    padding: '10px'
                  },
                },
              ],
              style: {
                width: '100%',
              }
            }
          }, {
            id: "column2",
            label: '2 column',
            media: `<svg width="100" height="100" viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path></svg>`,
            content: {
              type: 'Row',
              activate: true,
              components: [
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                },
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                },
              ],
              style: {
                width: '100%',
                display: 'flex',
              }
            }
          }, {
            id: "column3",
            label: '3 column',
            media: `<svg width="100" height="100" viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h4V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM17 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1ZM9.5 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"></path></svg>`,
            activate: true,
            content: {
              type: 'Row',
              components: [
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                },
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                },
                {
                  type: 'Cell',
                  style: {
                    width: '100%',
                    padding: '10px'
                  },
                },
              ],
              style: {
                width: '100%',
                display: 'flex',
              }
            }
          }, {
            id: "textBlock",
            label: "Text",
            media: `<svg width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z"></path></svg>`,
            activate: true,
            content: { type: "text", content: '<div class="text-block-content">Insert text here!</div>' },
          }, {
            id: 'Header',
            label: 'Heading',
            media: `<svg width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M4 4H6V20H4V4ZM18 4H20V20H18V4ZM7 11H17V13H7V11Z" /><path d="M4 11H7V13H4V11ZM17 11H20V13H17V11Z" fill="currentColor"/></svg>`,
            activate: true,
            content: {
              type: "text",
              content: `<div style="color:black; font-size: 20px; font-weight: bold;"> Insert your Heading Here! </div>`,
              style: { color: '#0000EE' }
            }
          }, {
            id: "imgBlock",
            label: "Image",
            activate: true,
            media: `<svg width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z"></path></svg>`,
            content: { type: "image" }
          }, {
            id: 'linkBlock',
            label: 'Link',
            media: `<svg width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path></svg>`,
            activate: true,
            content: {
              type: 'link',
              content: 'Insert your link here!',
              style: { color: '#0000EE' }
            }
          }, {
            id: "column4",
            label: 'Bullet Points',
            media: `<svg fill="currentColor" height="100" width="100" viewBox="0 0 487.3 360.3"> <path d="M487.2,69.7c0,12.9-10.5,23.4-23.4,23.4h-322c-12.9,0-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4h322.1 C476.8,46.4,487.2,56.8,487.2,69.7z M463.9,162.3H141.8c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1 c12.9,0,23.4-10.5,23.4-23.4C487.2,172.8,476.8,162.3,463.9,162.3z M463.9,278.3H141.8c-12.9,0-23.4,10.5-23.4,23.4 s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,288.8,476.8,278.3,463.9,278.3z M463.9,394.3H141.8 M38.9,30.8C17.4,30.8,0,48.2,0,69.7s17.4,39,38.9,39s38.9-17.5,38.9-39S60.4,30.8,38.9,30.8z M38.9,146.8 C17.4,146.8,0,164.2,0,185.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,146.8,38.9,146.8z M38.9,262.8 C17.4,262.8,0,280.2,0,301.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,262.8,38.9,262.8z M38.9,378.7"/></svg>`,
            activate: true,
            content: {
              type: 'Text',
              content: `<ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
              </ul>`,
              style: { width: '100%' },
            }
          }
        ],
      },
    });

    //custom button
    const customButton = document.querySelector('#regenerate-editor');
    customButton.style.display = 'none';

    editor.on('component:selected', (component) => {
      const selectedComponent = editor.getSelected();

      if (selectedComponent && selectedComponent.view && selectedComponent.view.el) {
        if (selectedComponent.attributes.tagName !== "body" && selectedComponent.attributes.tagName !== "footer" && selectedComponent.attributes.tagName !== "image") {
          customButton.style.display = 'block';
        } else {
          editor.on('component:deselected', () => {
            customButton.style.display = 'none';
            setSelectedComponent("");
          });
        }
        const selectedComponentText = selectedComponent.view.el.textContent.trim();
        const element = findElementByText(shiseidoNewslettersInfo.article, selectedComponentText);

        function findElementByText(htmlStr, text) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlStr, 'text/html');
          const elements = doc.querySelectorAll('*');
          for (const element of elements) {
            if (element.textContent.trim() === text) {
              return element;
            };
          };
          return null;
        };

        function findParentElement(element, tags = ['DIV', 'HEADER']) {
          while (element) {
            if (tags.includes(element.tagName)) {
              return element;
            };
            element = element.parentElement;
          };
          return null;
        };

        if (element) {
          const parentElement = findParentElement(element);
          if (parentElement !== '' && parentElement !== null) {
            setSelectedComponent(parentElement.outerHTML);
          } else {
            console.log('Parent element not found');
          };
        } else {
          console.log('Element not found');
        };
      };

      editor.on('component:deselected', () => {
        customButton.style.display = 'none';
        setSelectedComponent("");
      });
    });

    //default select blocks
    cmiEditor.runCommand('open-blocks');
    let blockBtn = cmiEditor.Panels.getButton('views', 'open-blocks');
    blockBtn.set('active', 1);

    //select the style manaeger
    editor.on('block:drag:stop', () => {
      const styleBtn = editor.Panels.getButton('views', 'open-sm');
      styleBtn.set('active', 1);
      const openGeneralSector = () => {
        const sectors = editor.StyleManager.getSectors();
        const generalSector = sectors.find(sector => sector.get('name') === 'General');
        if (generalSector) {
          generalSector.set('open', true);
        };
      };
      setTimeout(openGeneralSector, 0);
    });

    const repositionColorPicker = () => {
      const colorPickers = document.querySelectorAll('.gjs-field-color-picker');
      colorPickers.forEach(picker => {
        picker.addEventListener('click', () => {
          setTimeout(() => {
            const colorPickerContainer = document.querySelector('.sp-container:not(.sp-hidden)');
            if (colorPickerContainer) {
              const editorContainer = editor.getContainer();
              const editorRect = editorContainer.getBoundingClientRect();
              const pickerRect = picker.getBoundingClientRect();

              const top = pickerRect.bottom - editorRect.top;
              const left = pickerRect.left - editorRect.left;

              colorPickerContainer.style.position = 'absolute';
              colorPickerContainer.style.top = `${top + 70}px`;
              colorPickerContainer.style.left = `${left}px`;
              colorPickerContainer.style.zIndex = '100000';

              // Ensure the color picker stays within the editor
              const rightEdge = left + colorPickerContainer.offsetWidth;
              if (rightEdge > editorRect.width) {
                colorPickerContainer.style.left = `${editorRect.width - colorPickerContainer.offsetWidth}px`;
              };

              editorContainer.appendChild(colorPickerContainer);
            };
          }, 0);
        });
      });
    };

    // Call the function when the Style Manager is rendered
    editor.on('component:selected', () => {
      setTimeout(repositionColorPicker, 100);
    });

    setTemplateEditor(cmiEditor);

    return () => {
      if (templateEditor) {
        templateEditor.destroy();
        setTemplateEditor(null);
      }
    };
  };

  const handleOnSaveArticleData = async () => {
    let articleTempText = articleText;
    if (templateEditor && templateEditor.getHtml() && templateEditor.getHtml() !== "") {
      articleTempText = articleTempText.replace(/<body[^>]*>[\s\S]*<\/body>/gi, templateEditor.getHtml());
      articleTempText = articleTempText.replace(/<style>[\s\S]*<\/style>/i, `<style>${templateEditor.getCss()}</style>`);
    };
    setShiseidoNewslettersInfo({ ...shiseidoNewslettersInfo, article: articleTempText });
    setArticleText(articleTempText || ""); setEditorKey(editorKey + 1);

    try {
      dispatch(setLoader(true));
      await AxiosApi.post(`/api/articleHistory/update?tableName=shiseido_newsletter_history`, {
        id: shiseidoNewslettersInfo?.id, currentField: "article", article_html: articleTempText
      });
      getArticleAllVersionHistory("NewsLetter Article");
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    };
  };

  const handleDownloadArticlePngFile = async () => {
    if (document.querySelector(".gjs-frame") && document.querySelector(".gjs-frame").contentDocument) {
      const viewComponents = document.querySelector("span[title='View components'].gjs-pn-active");
      if (viewComponents) { viewComponents.click(); }
      if (templateEditor) { templateEditor.select(null); }
      const editorBody = document.querySelector(".gjs-frame").contentDocument.body;
      const images = editorBody.querySelectorAll("img");
      if (editorBody && editorBody !== "") {
        editorBody.style.background = "#FAFAFA";
      }
      dispatch(setLoader(true));

      for (const img of images) {
        const imageUrl = img.src;
        if (imageUrl.startsWith("data:")) { continue; }
        try {
          const response = await AxiosApi.get(`/api/post-writer/get-img-base64`, { params: { url: imageUrl } });
          if (response && response.data && response.data.data) {
            img.src = response.data.data;
          }
        } catch (error) {
          console.log('#error --->', error);
        }
      }

      htmlToImage.toPng(editorBody).then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement("a");
        link.download = `CMI-Image-${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
        dispatch(setLoader(false));
      }).catch(function (error) {
        dispatch(setLoader(false));
      });
    }
  };

  const handleDownloadArticlePDFFile = () => {
    let articleTempText = articleText;
    if (editor && editor.getHtml() && editor.getHtml() !== "") {
      articleTempText = articleTempText.replace(/<body[^>]*>[\s\S]*<\/body>/gi, editor.getHtml());
      articleTempText = articleTempText.replace(/<style>[\s\S]*<\/style>/i, `<style>${editor.getCss()}</style>`);
    };

    const exportData = {
      text: articleTempText, finalText: finalHtmlText, dispatch, editorRef,
      finalHtmlText, articleId: shiseidoNewslettersInfo.id, userRes, type: "CMI"
    }

    handleExportToWordOrPdfArticle(exportData, "pdf", editor);
  };

  const handleRegenerateArticlePart = async () => {
    dispatch(setLoader(true));
    const payload = {
      pathParameters: { type: "regenerate-article-section" },
      body: JSON.stringify({
        articleId: shiseidoNewslettersInfo.id, feedback: feedbackText, component: selectedComponent
      })
    };

    const response = await invokeLambdaFunction("generateShiseidoOrSaveByType", payload);
    dispatch(setLoader(false));
    if (response && response.success === 1 && response.data) {
      setShiseidoNewslettersInfo({ ...shiseidoNewslettersInfo, article: response.data.content });
      editorInitialize(response.data.content || "");
      setViewRegenerateModel(false); setFeedbackText("");
      dispatch(setLoader(false));
      getArticleAllVersionHistory("NewsLetter Article");
    } else {
      console.log('#error --->', response.error);
      dispatch(setLoader(false));
    }
  };

  const handleCopyHtmlArticleText = (text) => {
    if (text && text !== "") {
      const options = { indent_size: 2, space_in_empty_paren: true };
      copy(beautify_html(text, options), { format: "text/html" });
      toast.success("Text has been copied to clipboard.", { id: "Toast-01" });
    }
  };

  const handleSetRestoredOldVersionText = async (fieldName, text) => {
    setFinalHtmlText(text);
    setShiseidoNewslettersInfo((prev) => ({ ...prev, article: text }));
    editorInitialize(text || "");
    getArticleAllVersionHistory("NewsLetter Article");
  };

  return (
    <div className="news-letters-last-step addllyFormWrap">
      <Row className="eCommerce-blog-wrapper m-0 mw-100 p-0 mb-3">
        <Col sm="12">
          <div className="header-content ms-3 ms-md-4 me-3 me-md-0 my-3 d-flex align-items-center justify-content-between">
            <div><b>Edit Newsletter</b></div>
            <div className="d-flex justify-content-center gap-3">
              <VersionHistory
                versionHistory={versionHistory} selectedVersionItem={selectedVersionItem} setSelectedVersionItem={setSelectedVersionItem}
                handleSetRestoredOldVersionText={handleSetRestoredOldVersionText} handleCopyHtmlArticleText={handleCopyHtmlArticleText}
                selectedSubTab={""} setSelectedSubTab={() => { }} articleType={articleType}
              />
              <button
                className="blogButton d-flex align-items-center gap-1 border-0 text-nowrap rounded-2 px-3"
                style={{ backgroundColor: "#E6EBFF", color: "blue" }} onClick={handleDownloadArticlePngFile}
              >
                <PiFilePngDuotone size={20} /> Export as PNG
              </button>
              <button
                className="blogButton d-flex align-items-center gap-1 border-0 text-nowrap rounded-2 px-3"
                style={{ backgroundColor: "#E6EBFF", color: "blue" }} onClick={handleDownloadArticlePDFFile}
              >
                <PiFilePdfDuotone size={20} /> Export as PDF
              </button>
              <button
                type="button" className="d-flex align-items-center gap-2 border-0 selectBbutton btn btn btn-dark"
                onClick={handleOnSaveArticleData} disabled={articleText && articleText !== "" ? false : true}
              >
                <FaRegSave /> Save
              </button>
            </div>
          </div>
        </Col>
        <Col sm="12" className="side-menu ps-0 ps-md-3 pe-0 pe-md-3 border-end">
          <button id="regenerate-editor"
            className="position-absolute border-0 addlly-primary m-1"
            style={{ zIndex: '999', padding: '6px 0px', background: '#E6EBFF', color: 'blue' }}
            onClick={() => setViewRegenerateModel(true)}> Regenerate </button>
          <div ref={editorRef}></div>
        </Col>
      </Row>

      {viewArticle && viewArticle.open && (
        <ViewArticlePreviewModal viewArticle={viewArticle} setViewArticle={setViewArticle} />
      )}

      {viewRegenerateModel && (
        <Modal size="lg" centered show={viewRegenerateModel} onHide={() => setViewRegenerateModel(false)}>
          <Modal.Body className="text-start p-0 overflow-auto">
            <div className="maingenrateBlock m-0 p-3 h-100">
              <div className="d-flex align-items-center justify-content-between">
                <h4>What would you like to change?</h4>
                <button className="btn btn-primary" onClick={() => handleRegenerateArticlePart()}>
                  <BsStars /> Re-Generate
                </button>
              </div>
              <div className="genrateFields mt-3">
                <div className="fields m-0">
                  <label>Customize your content before hitting the 'Re-Generate' button. </label>
                  <textarea
                    name="feedback" type="text" rows="3" placeholder="Insert feedback ..."
                    className={`addlly-textarea w-100`} value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
};

const ViewArticlePreviewModal = ({ viewArticle, setViewArticle }) => {
  const handleClose = () => {
    setViewArticle({ open: false, data: null });
  };

  return (
    <React.Fragment>
      {viewArticle && viewArticle.open && (
        <Modal size="xl" centered show={viewArticle && viewArticle.open} onHide={handleClose} className="e-com-product-modal">
          <Modal.Body>
            <div className="product-view-modal">
              <div className="close-button" type="button" onClick={handleClose} onKeyDown={handleClose}>
                <MdOutlineClose className="fs-4" />
              </div>
              <Row className="mt-4">
                <Col sm="12" className="px-5">
                  <h3 className="fw-normal mb-3">
                    {(viewArticle?.data && viewArticle.data.title) || "-"}
                  </h3>
                  <h4 className="fw-normal mb-3">
                    {(viewArticle?.data && viewArticle.data.date) || "-"}
                  </h4>
                  <hr />
                  <h5 className="fw-normal mb-3 text-muted">
                    {(viewArticle?.data && viewArticle.data.summary_content) || "-"}
                  </h5>
                  <a href={viewArticle?.data && viewArticle.data.web_url} target="_blank" rel="noreferrer"
                    className="text-decoration-none d-flex align-items-center gap-2 my-2 py-2 px-3 rounded"
                    style={{ backgroundColor: "#E6EBFF", color: "blue", maxWidth: "max-content" }}
                  >
                    View Article <i><GoLinkExternal size={18} /></i>
                  </a>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
};

export default ThirdStepNewsletters;
