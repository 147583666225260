import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import jwt_decode from "jwt-decode";

import AuthenticateLeft from "../Components/authenticate/AuthenticateLeft";
import { setGenerateData, setLoader, setStep, setUserRes, setUsername } from "../store/reducer";

import Logo from "../Assets/Images/Brand.png";
import "../Assets/scss/authentic.scss";
import { AxiosApi } from "../utility/axios";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Row } from "react-bootstrap";
import { BsBoxArrowRight } from "react-icons/bs";
import classNames from "classnames";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters")
    .trim("white-space", "Username should not contain white spaces")
    .matches(/^\S*$/, "Username cannot contain whitespace"),
  password: Yup.string().required("Password is required")
    .trim("white-space", "Password should not contain white spaces")
});

const SignIn = () => {
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 576px)").matches);

  useEffect(() => {
    window.matchMedia("(min-width: 576px)").addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div className="authenticateWrap">
      {matches && (
        <>
          <AuthenticateLeft />
          <SignInForm />
        </>
      )}
      {!matches && (
        <div className="res-authenticateWrap">
          <img src={Logo} alt="addlly.ai" className="res-logo" />
          <SignInForm />
          <AuthenticateLeft />
        </div>
      )}
    </div>
  );
};

export default SignIn;

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector(({ username }) => username);

  const [typePassword, setTypePassword] = useState(true);
  const [userData, setUserData] = useState({});
  const [otp, setOtp] = useState("");
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [reCaptcha, setReCaptcha] = useState(null);
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' })
  const { register, handleSubmit, formState: { errors }, watch, setError, clearErrors } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (username !== "") {
      navigate("/dashboard");
    }
  }, [username, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleSendOtp = async (data) => {
    if (!reCaptcha) {
      return false;
    }

    dispatch(setLoader(true));
    setUserData(data);
    clearErrors();
    setErrorMessage({ isError: false, message: '' });

    let res1 = { ip: "0.0.0.0" };
    try {
      const response = await axios.get("https://api.ipify.org?format=json", { timeout: 5000 });
      if (response && response.data) {
        res1 = response.data;
      }
    } catch (error) {
    }

    AxiosApi.post(`/user/login`, {
      username: data?.username,
      password: data?.password,
      ip: res1.ip,
    }).then(async (response) => {
      if (response && response.data) {
        if (response.data.otpSent && response.data.otpSent === true) {
          dispatch(setLoader(false));
          toast.success("OTP sent on email successfully.", { id: "Toast-01" });
          setUserData((prev) => ({ ...prev, userId: response?.data?.data }));
          setIsSendOtp(true);
          setMinutes(1);
          setSeconds(60);
        } else {
          handleNavigateToDashboard(response);
        }
      }
    }).catch((error) => {
      dispatch(setLoader(false));
      const errorMsg = error?.response?.data?.error?.message || error?.response?.data?.error || "Invalid username or password";
      setError('username'); setError('password');
      setErrorMessage(pre => ({ ...pre, isError: true, message: "Invalid username or password" }));
      setTimeout(() => {
        setErrorMessage({ isError: false, message: '' });
      }, 5000);
    });
  };

  const verifyOTP = async (data) => {
    dispatch(setLoader(true));
    let res1 = { ip: "0.0.0.0" };
    try {
      const response = await axios.get("https://api.ipify.org?format=json", { timeout: 5000 });
      if (response && response.data) {
        res1 = response.data;
      }
    } catch (error) {
    }

    AxiosApi.post(`/user/verify-otp`, { userId: data?.userId, otp, ip: res1.ip }).then(async (response) => {
      handleNavigateToDashboard(response);
    }).catch((error) => {
      dispatch(setLoader(false));
      const errorMsg = error?.response?.data?.error?.message || error?.response?.data?.error || "Invalid username or password";
      toast.error(errorMsg, { id: "Toast-01" });
    });
  }

  const handleNavigateToDashboard = async (response) => {
    if (response && response.data && response.data.success === 1) {
      toast.success(response.data.message, { id: "Toast-01" });
      localStorage.setItem('token', response.data.data?.token);
      dispatch(setUsername(response.data.data?.username));
      dispatch(setUserRes({ id: response.data.data?.id }));
      dispatch(setStep(1));
      dispatch(setGenerateData({}));
      const detectDeviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
      if (detectDeviceType === 'Mobile') {
        localStorage.setItem('isCheckDevice', true);
      }
      navigate("/dashboard");
    } else {
      dispatch(setLoader(false));
      toast.error(response?.data?.message || "", { id: "Toast-01" });
    }
  }

  const responseGoogle = (response) => {
    const decoded = jwt_decode(response.credential);
    handleSendOtp({
      username: decoded?.sub,
      password: decoded?.sub,
    });
  };

  const responseFailGoogle = (response) => {
    console.log(response, "response Fail");
  };

  return (
    <div className="rightPart maingenrateBlock">
      {isSendOtp ? (
        <div className="genrateFields w-50">
          <Row>
            <Col xl={12}>
              <div className="fields" >
                <label className=" color-light-text p-0">OTP</label>
                <div className="inputField">
                  <input
                    name="otp" placeholder="Enter OTP" type={"text"}
                    value={otp} onChange={(e) => setOtp(e.target.value)}
                  />
                  <div className="invalid">{errors.otp?.message}</div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="countdown-text d-flex align-items-center justify-content-between">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <p>Didn't receive code?</p>
            )}

            <button
              className="btn p-0 border-0" disabled={seconds > 0 || minutes > 0}
              onClick={() => seconds === 0 && minutes === 0 ? handleSendOtp(userData, '', true) : null}
              style={{ color: seconds > 0 || minutes > 0 ? "#A5A5A5" : "#FF5630" }}
            >
              Resend OTP
            </button>
          </div>

          <div className="authenticate-button  d-flex align-items-center">
            <button type="button" className="w-100 justify-content-center mt-4" disabled={!otp} onClick={() => verifyOTP(userData)}>Submit OTP</button>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="innerFields">
            <div className="form-heading">
              <h4>Welcome Back!</h4>
              <span>Sign in to continue</span>
            </div>

            <form onSubmit={handleSubmit(handleSendOtp)} className="w-100">
              <div className="genrateFields">
                <Row className="m-0">
                  <Col xl={12} className=" p-0">
                    <div className="fields">
                      <label className=" color-light-text p-0">Username / Email <sup>*</sup></label>
                      <div className=" inputField">
                        <input
                          type="text" name="username" placeholder="Enter username / email"
                          {...register("username")} className={`${errors.username ? "is-invalid" : ""}`}
                        />
                      </div>
                      <div className="invalid">{errors.username?.message}</div>
                    </div>
                  </Col>
                  <Col xl={12} className="p-0 mt-3">
                    <div className="fields">
                      <label className=" color-light-text p-0">Password <sup>*</sup></label>
                      <div className=" inputField">
                        <input
                          name="password" placeholder="Enter password" type={typePassword ? "password" : "text"}
                          {...register("password")} className={`${errors.password ? "is-invalid" : ""}`}
                        />
                        {!typePassword ? (
                          <AiOutlineEyeInvisible size={16} color="#50506A" type="button" onClick={() => setTypePassword(!typePassword)} />
                        ) : (
                          <AiOutlineEye size={16} color="#50506A" type="button" onClick={() => setTypePassword(!typePassword)} />
                        )}
                      </div>
                      <div className="invalid">{errors.password?.message}</div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mt-4">
                <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onChange={(e) => setReCaptcha(e)} />
              </div>
              <div className="d-flex justify-content-between mb-4">
                <Link to="/forgot-password" type="button" className="forgotBtn text-align-leftd-block mt-3 " >
                  Forgot Password ?
                </Link>
              </div>
              {errorMessage.isError && errors?.username && errors?.password ? (
                <div className="error-message">
                  <span className="error-message-text"> {errorMessage.message}</span>
                </div>
              ) : null}
              <div className="authenticate-button d-flex align-items-center">
                <button type="submit" disabled={reCaptcha ? false : true} className={classNames("w-100 justify-content-center align-items-center")}>
                  Sign In  <BsBoxArrowRight size={16} />
                </button>
              </div>
              {/* <div className="submitBtn">
                <button type="submit" disabled={reCaptcha ? false : true}>Sign In</button>
              </div> */}
              {/* <div className="orText">
                <span>Or</span>
              </div>
              <div className="googleBtn">
                <GoogleLogin
                  text="signin_with"
                  onSuccess={responseGoogle}
                  onFailure={responseFailGoogle}
                  size="large"
                  shape="circle"
                  width="400px"
                />
              </div> */}

              <div className="registerLink">
                <span>
                  Don't have an account? <Link type="button" to={"/signup"}>Register</Link> for free
                </span>
              </div>
            </form>
            <div className="registerLink mt-3">
              <span>Facing an issue? Reach out to our <Link type="button" to={"/support"}>support!</Link></span>
            </div>
          </div>
          <div className="privacy-policy">
            <span>
              By using addlly.ai you agree to the
            </span>
            <span>Terms <span className="endSign">&</span> Privacy Policy</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
