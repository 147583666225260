import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { sorting } from "../utility/common";
import { deleteOrArchiveArticle, newslettersHistoryData, pinArticles, pressReleaseHistoryData } from "../utility/apiService";

const NewsletterAndPressReleaseHoc = (WrappedComponents) => {
  const NewsletterAndPressReleaseWriter = (props) => {
    const { pathname } = useLocation()
    const userRes = useSelector(({ userRes }) => userRes);

    const [historyData, setHistoryData] = useState({ data: [], totalCount: 0 });
    const [filters, setFilters] = useState({ categories: [], durations: [], status: [], AIModels: [], users: [] });
    const [textWrap, setTextWrap] = useState(false);
    const [starred, setStarred] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortField, setSortField] = useState({ column: {}, direction: "" });
    const [page, setPage] = useState(1);
    const [limit] = useState(10);

    const searchParam = {};
    if (starred) { searchParam.starred = "starred"; }
    if (filters.status?.length) { searchParam.status = filters.status?.join(","); }
    if (filters.AIModels?.length) { searchParam.aiModel = filters.AIModels?.join(","); }
    if (filters.durations?.length) { searchParam.date = filters.durations?.join(","); }

    if (pathname.includes("/newsletters")) { searchParam.type = "newsletters_history"; }
    if (pathname.includes("/press-release")) { searchParam.type = "press-release"; }

    const query = new URLSearchParams({ is_archived: 0, ...searchParam }).toString();
    const { refetch, isFetching } = useQuery({
      queryKey: [searchParam.type ? searchParam.type : "all"],
      queryFn: async () => {
        let response = {};
        if (searchParam.type === "press-release") {
          response = await pressReleaseHistoryData({ userId: userRes?.id, query });
        } else {
          response = await newslettersHistoryData({ userId: userRes?.id, query });
        }
        setHistoryData(response);
        return response;
      },
    });

    const { mutate } = useMutation({
      mutationFn: (values) => {
        pinArticles({ data: values }).then(() => {
          refetch();
        });
      },
    });

    const handleSort = (column, sortDirection) => {
      setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
      setPage(1);
      const newData = sorting(historyData?.data, column?.sortField, sortDirection);
      setHistoryData(pre => ({ ...pre, data: newData }));
    };

    const handleSelectedRows = (e) => {
      setSelectedRows(e?.selectedRows?.map(({ type, id }) => ({ id, type })));
    };

    const handlePinArticle = (type, articleId, value) => {
      const data = { type, articleId, value: !value };
      mutate(data);
    };

    useEffect(() => {
      setFilters({ categories: [], durations: [], status: [], AIModels: [], users: [] })
    }, [pathname]);

    useEffect(() => {
      setPage(1);
      refetch();
    }, [filters, starred, refetch]);

    const filteredData = useMemo(() => (
      historyData?.data?.filter(item =>
        Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('')?.toLowerCase().includes(search?.split(' ').join('')?.toLowerCase()))
      )
    ), [historyData, search])

    const handleDelete = (id, type, eType) => {
      Swal({
        title: "Are you sure?",
        text: `Do you want to ${eType} the selected item?`,
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, ${eType} it!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = () => {
            setPage(1); refetch(); setSelectedRows([]);
          };
          deleteOrArchiveArticle([{ id, type }], eType, callback);
        }
      });
    };

    const handleArchiveAll = () => {
      Swal({
        title: "Are you sure?",
        text: "Do you want to archive the selected item?",
        icon: "warning", dangerMode: true,
        buttons: { cancel: "Cancel", confirm: `Yes, archive ${selectedRows.length > 1 ? "all" : ""}!` },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          const callback = (resp) => {
            setPage(1); refetch(); setSelectedRows([]);
          }
          deleteOrArchiveArticle(selectedRows, 'archive', callback);
        }
      });
    };

    return (
      <WrappedComponents
        {...props} historyData={filteredData ?? []} userRes={userRes} loading={isFetching} filters={filters} setFilters={setFilters}
        textWrap={textWrap} setTextWrap={setTextWrap} starred={starred} setStarred={setStarred} setSearch={setSearch} search={search}
        selectedRows={selectedRows} setSelectedRows={handleSelectedRows} handleArchiveAll={handleArchiveAll} limit={limit}
        totalCount={historyData?.totalCount || 0} page={page} setPage={setPage} handlePinArticle={handlePinArticle} handleDelete={handleDelete}
        sortField={sortField} handleSort={handleSort} getHistoryShortFlowData={useCallback(() => refetch(), [refetch])}
      />
    );
  };

  return NewsletterAndPressReleaseWriter;
}

export default NewsletterAndPressReleaseHoc;
