import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { BsSearch } from "react-icons/bs";
import { BiMinus, BiPlus } from "react-icons/bi";
import TableLoader from "../ui/TableLoader";
import CustomDataTable from "../ui/DataTable";
import { setLoader } from "../../store/reducer";
import { AxiosApi } from "../../utility/axios";
import { SortArrow, sorting } from "../../utility/common";
import { articleHistoryIcons } from "../../Assets/Icons";

const RefundHistoryTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const [currentTab, setCurrentTab] = useState("all");
  const [tickets, setTickets] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [textWrap, setTextWrap] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    getAllTickets(currentTab);
  }, []);

  const getAllTickets = async (status) => {
    setTableLoader(true);
    const url = `/api/user/credit-transactions/${userRes.id}?status=${status}`;
    AxiosApi.get(url).then((response) => {
      setTickets(response?.data?.data);
      setTableLoader(false);
    }).catch((error) => {
      setTableLoader(false);
    });
  };

  const handleOnChangeCurrentTab = async (val) => {
    setTickets([]); setPage(1);
    setCurrentTab(val);
    getAllTickets(val);
  };

  const handleSort = (column, sortDirection) => {
    setPage(1); setTickets([]);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(tickets, column?.sortField, sortDirection);
    setTickets(newData);
  };

  const filteredData = useMemo(() => (
    tickets?.filter(item =>
      Object.values(item).some(value => typeof value === "string" && value?.split(' ').join('').toLowerCase().includes(searchTxt?.split(' ').join('').toLowerCase()))
    )
  ), [tickets, sortField, searchTxt]);

  const handleTitle = (row) => {
    let title = '-';
    if (row?.type) {
      if (row?.type === '1-Click Blog') {
        title = '1-Click Blog Writer';
      } else if (row?.type === 'Blog Co-Pilot') {
        title = 'SEO Blog Co-Pilot';
      } else if (row?.type === 'NewsLetters') {
        title = 'Newsletter Builder';
      } else if (row?.type === 'Press Release') {
        title = 'Press Release Quick Draft';
      } else if (row?.type === 'Social Media Post Writer') {
        title = 'Social Media Posts';
      } else {
        title = row?.type;
      }
    }

    return title;
  }

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex px-3">Type {SortArrow(sortField, "title")}</div>,
      selector: (row) => <div className={classNames("tableValues", { showFullDes: textWrap })}><h6>{handleTitle(row)}</h6></div>,
      sortField: "type",
      sortable: true,
    },
    {
      id: "type",
      name: <div className="d-flex px-2" >Document {SortArrow(sortField, "type")}</div>,
      selector: (row) => (
        <div className=" d-flex align-items-center gap-2 tableValues">
          <div className={classNames("", { showFullDes: textWrap })}>
            {row.topic && row.topic !== "" ? (
              <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.topic} data-tooltip-place="bottom">{row.topic || "-"}</h6>
            ) : (
              <h6>-</h6>
            )}
            <p>{row.keyword || "-"}</p>
          </div>
        </div>
      ),
      sortField: "topic",
      sortable: true,
    },
    {
      id: "credit",
      name: <div className="d-flex px-3">Credit</div>,
      selector: (row) => <div>
        <div className="d-flex align-items-center gap-2">
          {row.refund_status === "approved" ? (
            <span className="credit-status">
              {row?.credit > 0 ? <BiMinus color="#f00" /> : <BiPlus color="#68a111" />}
              {" "} {row?.credit ? row.credit : row?.debit}
            </span>
          ) : (
            <span className="debit-status">
              {row?.credit > 0 ? <BiPlus color="#68a111" /> : <BiMinus color="#f00" />}
              {" "} {row?.credit ? row.credit : row?.debit}
            </span>
          )}
        </div>
      </div>,
      sortable: false,
    },
    {
      id: "comment",
      name: <div className="d-flex">Comment</div>,
      selector: (row) => (
        <div className={classNames("d-flex align-items-center gap-2 tableValues", { showFullDes: textWrap })}>
          {row.comment && row.comment !== "" ? (
            <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.comment} data-tooltip-place="bottom">{row.comment || "-"}</h6>
          ) : (
            <h6>-</h6>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "admin_comment",
      name: <div className="d-flex">Admin Comment</div>,
      selector: (row) => (
        <div className={classNames("d-flex align-items-center gap-2 tableValues", { showFullDes: textWrap })}>
          {row.admin_comment && row.admin_comment !== "" ? (
            <h6 className="w-100" data-tooltip-id="my-tooltip" data-tooltip-content={row.admin_comment} data-tooltip-place="bottom">{row.admin_comment || "-"}</h6>
          ) : (
            <h6>-</h6>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: <div className="d-flex">Status {SortArrow(sortField, "status")}</div>,
      selector: (row) => (
        <div className="d-flex justify-content-center align-items-center">
          <div className="dotStatus">
            <span className={`d-block`}
              style={{
                width: "8px", height: "8px", borderRadius: "50%",
                backgroundColor: row.refund_status === "approved" ? "#84CC16" : row.refund_status === "refund" ? "#84CC16" :
                  row.refund_status === "rejected" ? "#EF4444" : "#F59E0B",
              }}
            />
          </div>
          {row.refund_status === "approved" ? "Approved" : row.refund_status === "refund" ? "Refund" :
            row.refund_status === "rejected" ? "Rejected" : "Pending"}
        </div>
      ),
      sortField: "refund_status",
      sortable: true,
    },
  ]), [sortField, textWrap]);

  return (
    <div className="accountCard">
      <div className="cardHead">
        <h3>Refund History</h3>
      </div>
      <div className="card-note">
        <b>NOTE:</b> Refunds are only available for credits used within the past 24 hours.
        Please ensure your posts are less than a day old to be eligible for a refund request.
      </div>
      <div className="cardBody">
        <div className="filters">
          <div className="historyTopBar d-flex flex-wrap justify-content-between bg-white align-items-center gap-x-3 flex-wrap p-0">
            <div className="d-flex flex-wrap align-items-center gap-3">
              <div className="buttonTab flex-wrap overflow-hidden">
                {[
                  { label: "All", value: "all" },
                  { label: "Pending", value: "pending" },
                  { label: "Approved", value: "approved" },
                  { label: "Rejected", value: "rejected" },
                ].map((item, index) => (
                  <button type="button" key={index} onClick={() => handleOnChangeCurrentTab(item.value)}
                    className={`${item.value === currentTab ? "active" : ""} ${index !== 0 ? "border-start" : ""}`}
                    disabled={item.value === currentTab}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
              <span className="line d-none d-md-block"></span>
              <div className="filterRedu rounded overflow-hidden">
                <button
                  className={classNames({ active: !textWrap })} onClick={() => setTextWrap(false)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Unwrap Text" data-tooltip-place="bottom"
                >
                  <img src={articleHistoryIcons.filterIcon} alt="filterIcon" />
                </button>
                <button
                  className={classNames({ active: textWrap })} onClick={() => setTextWrap(true)}
                  data-tooltip-id="my-tooltip" data-tooltip-content="Wrap Text" data-tooltip-place="bottom"
                >
                  <img src={articleHistoryIcons.redoIcon} alt="redoIcon" />
                </button>
              </div>
            </div>
            <div className="searchField position-relative">
              <input type="search" placeholder="Type to search" onChange={(e) => setSearchTxt(e.target.value)} />
              <div className="searchIcon position-absolute">
                <BsSearch />
              </div>
            </div>
          </div>
        </div>
        <div className="historyTableBlock mt-2">
          <CustomDataTable
            progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
            columns={columns} totalCount={filteredData?.length} pagination={true} data={filteredData}
            itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound search={searchTxt} />}
            onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={() => { }} clearSelectedRows={() => { }}
          />
        </div>
      </div>
    </div>
  );
};

export default RefundHistoryTab;

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (<><div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
          <p>Refund history is not available yet!</p></>) : (<span>Oops... Search not found.</span>)}
      </div>
    </div>
  );
};
