import React, { Fragment, forwardRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import CustomButton from "../ui/Button";
import { sideBarIcons } from "../../Assets/Icons";
import { AxiosApi } from "../../utility/axios";
import { setCurrentPlan, setUserPlan } from "../../store/reducer";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <BsThreeDotsVertical
    id="dropdown-basic"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </BsThreeDotsVertical>
));

export default function CreditsCard({ userRes }) {
  const { credits } = userRes;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPlan = useSelector(({ userPlan }) => userPlan);
  const organizationId = useSelector(({ userRes }) => userRes.organization_id);

  useEffect(() => {
    if (userRes?.id && !userPlan) {
      if (userRes.hasOwnProperty("current_plan") && userRes.current_plan === "Enterprise Pack") {
      } else {
        handleCheckSubscription();
      }
    }
  }, [])

  const handleCheckSubscription = async () => {
    try {
      const res = await AxiosApi.get(`/user/stripe/get-subscription/${userRes.id}`);
      if (res.status === 200) {
        dispatch(setUserPlan(res?.data?.data));
        if (res?.data && res?.data?.planInfo) {
          dispatch(setCurrentPlan(res.data.planInfo));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="innerCardBlock d-flex justify-content-between">
        <div className="d-flex gap-2 align-items-center ">
          <h6 className={credits < 1 ? "text-danger" : ""}>{credits}</h6>
          <p>Addlly credits left</p>
        </div>
        <div >
          <Dropdown align={"end"}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" className="cursor-pointer"></Dropdown.Toggle>
            <Dropdown.Menu className="border-0 p-0 dropDownPopup shadow overflow-hidden">
              <Dropdown.Item as={Link} to="/account/refund-requests" className="border-bottom">
                Refund Request
              </Dropdown.Item>
              {organizationId ? null : <Dropdown.Item as={Link} to="/buy-more-credits">Buy More Credits</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="buttonUpgrade d-flex">
        {userRes.hasOwnProperty("current_plan") && userRes.current_plan === "Enterprise Pack" && userRes.company !== "CMI" ? (
          <CustomButton disabled>
            <img src={sideBarIcons.emptyWallet} alt="wallet_icon" /> Enterprise Pack
          </CustomButton>
        ) : (
          <CustomButton onClick={() => navigate("/plans")} disabled={userRes.company === "CMI" ? true : false}>
            <img src={sideBarIcons.emptyWallet} alt="wallet_icon" />
            {userPlan && userPlan.length > 0 ? userPlan[0].is_downgraded ? "Downgrade Plan" : "Upgrade Plan" : "Buy Plan"}
          </CustomButton>
        )}
      </div>
    </Fragment>
  );
}
