import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { FaRegImage } from "react-icons/fa";
import { BsInfoCircleFill, BsSearch } from "react-icons/bs";
import { Placeholder, Row } from "react-bootstrap";
import ImageDisplayContent from "./ImageDisplayContent";
import NoDataAvailable from "../../NoDataAvailable";
import { AxiosApi } from "../../../utility/axios";

const ImageLibrary = ({ props, handleSaveSelectedImage }) => {
  const { postImagesModal, setPostImagesModal, postGeneratedImages } = props;
  const inputRef = useRef();

  const [activeBtn, setActiveBtn] = useState("Pexels");
  const [activeBtnKey, setActiveBtnKey] = useState("pexelImages");
  const [searchValue, setSearchValue] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);

  useEffect(() => {
    handleChangeImageTab(activeBtn);
  }, [postGeneratedImages["pexelImages"]])

  const handleChangeImageTab = (val) => {
    setGeneratedImages([]);
    setSearchValue("");
    setActiveBtn(val);
    setPostImagesModal((prev) => ({ ...prev, imageSubTab: val }));

    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }

    let key = "";
    if (val === "Pexels") {
      key = "pexelImages";
    } else if (val === "UnSplash") {
      key = "unsplashImages";
    } else if (val === "Pixabay") {
      key = "pixabayImages";
    }

    setActiveBtnKey(key);
    if (postGeneratedImages && postGeneratedImages[key] && postGeneratedImages[key].length > 0) {
      setGeneratedImages([...new Set(postGeneratedImages[key])]);
    }
  }

  const handleGetImagesBySearched = async (event) => {
    setSearchValue(inputRef.current?.value);
    if (!inputRef.current?.value || inputRef.current?.value === "") {
      if (postGeneratedImages && postGeneratedImages[activeBtnKey] && postGeneratedImages[activeBtnKey].length > 0) {
        setGeneratedImages(postGeneratedImages[activeBtnKey]);
      }
      return false;
    }

    if (searchValue === inputRef.current?.value) {
      return false;
    }

    setGeneratedImages([]);
    setPostImagesModal((prev) => ({ ...prev, pexelsGenerating: true }));

    try {
      const searchedImages = [];
      const params = { type: "image", imgSubType: activeBtn, imgSearchVal: inputRef.current?.value };
      const imgResults = await AxiosApi.post(`/api/chart-or-image/generate`, params);
      if (imgResults && imgResults.data && imgResults.data.length > 0) {
        for (let index = 0; index < imgResults.data.length; index++) {
          const element = imgResults.data[index];
          if (element && element !== "") {
            searchedImages.push(element);
          }
        }
      };

      setGeneratedImages(searchedImages);
      setPostImagesModal((prev) => ({ ...prev, pexelsGenerating: false }));
    } catch (error) {
      setPostImagesModal((prev) => ({ ...prev, pexelsGenerating: false }));
    }
  }

  return (
    <div className="imageLab">
      <div className="topButtonBlock d-flex align-items-center gap-3 justify-content-between">
        <div className="rightButtons d-flex align-items-center">
          {["Pexels", "UnSplash", "Pixabay"].map((label, index) => (
            <button
              type="button" key={index} onClick={() => handleChangeImageTab(label)}
              className={classNames("blogButton tab-btn border-0", { "activeBtn": (activeBtn === label) })}
            >
              {label}
            </button>
          ))}
        </div>
        {postImagesModal && postImagesModal.carouselImg && postImagesModal.carouselImg.length > 0 && (
          <div className="selectIamgesB d-flex align-items-center">
            <BsInfoCircleFill
              type="button" className="text-decoration-none outline-0 me-2 fs-5 infoIconSvg" data-tooltip-id={"my-tooltip"}
              data-tooltip-place="bottom-end" data-tooltip-content={`Select multi image to upload`}
            />
            <button type="button" className="blogButton border-0" onClick={() => handleSaveSelectedImage(postImagesModal)}>
              <FaRegImage /> Upload Images
            </button>
          </div>
        )}
      </div>

      <div className="searchBar d-flex">
        <input onChange={(e) => e.target.value === '' && handleChangeImageTab(activeBtn)} type="text" placeholder={`Search ${activeBtn}`} ref={inputRef} />
        <div className="searchIcon position-absolute">
          <BsSearch />
        </div>
        <button onClick={handleGetImagesBySearched} className="border-0">Search</button>
      </div>

      {postImagesModal && postImagesModal.pexelsGenerating ? (
        <Row className="mt-4 justify-content-start gap-3">
          {[1, 2, 3, 4, 5].map((ele, index) => (
            <div className="placeholderI" key={index}>
              <Placeholder as="p" animation="glow" className="overflow-hidden rounded-3" style={{ height: "190px" }}>
                <Placeholder xs={12} className="h-100" />
              </Placeholder>
            </div>
          ))}
        </Row>
      ) : (
        <div className="genrateImagesCards d-flex flex-wrap">
          {(generatedImages && generatedImages.length > 0) ? (generatedImages.map((image, index) => (
            <ImageDisplayContent
              image={image} key={index} viewImgHistory={generatedImages} imageIndex={index}
              postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal}
              handleSaveSelectedImage={handleSaveSelectedImage}
            />
          ))) : (
            <NoDataAvailable text="Images Not Available." />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageLibrary;
