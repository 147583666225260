import React, { useMemo, useState } from "react"
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { TfiTrash } from "react-icons/tfi";
import { IoEyeOutline } from "react-icons/io5";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import DropdownMenu from "../../../Components/ui/Menu";
import TableLoader from "../../../Components/ui/TableLoader";
import CustomDataTable from "../../../Components/ui/DataTable";
import Filters from "../../../Components/commonComponents/Filters";
import ArticlePreview from "../../../Components/commonComponents/ArticlePreview";
import { SortArrow, tableArticleStatusHandle, tableDateFormatter } from "../../../utility/common";
import { articleHistoryIcons, dashboardIcon, dataTableIcons } from "../../../Assets/Icons";
import RefundRequestsHistory from "../../../Components/commonComponents/RefundRequestsHistory";
import OneBlogWriterHoc from "../../Hoc/OneBlogWriterHoc";

const SeoOptimizedBlogHistory = (props) => {
  const {
    historyData, textWrap, loading, setSelectedRows, totalCount, setPage, limit, page, starred, setStarred, setSearch, search,
    handlePinArticle, handleDelete, handleSort, sortField, handleGetArticleById, userRes, getHistoryShortFlowData, setTextWrap, setFilters, filters,
    selectedRows, getArticleAndPostHistoryData, handleArchiveAll, isFetching, clearRow
  } = props;

  const dispatch = useDispatch();
  const [article, setArticle] = useState({ id: '', type: '' });
  const [showOffset, setShowOffset] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState({ open: false });

  const handleOpenArticle = (id, type) => {
    setArticle(pre => ({ ...pre, id: id, type }));
    setShowOffset(true);
  }

  const columns = useMemo(() => {
    const tempColumns = [
      {
        id: "title",
        name: <div className="d-flex">Title {SortArrow(sortField, "title")}</div>,
        selector: (row) => (
          <div className=" d-flex align-items-center gap-2" onClick={() => { handleTableRowClick(row) }}>
            <div className={classNames("tableValues", { showFullDes: textWrap })}>
              <OverlayTrigger placement="bottom" flip overlay={<Tooltip id="tooltip">{row.topic}</Tooltip>}>
                <h6 >{row.topic || "-"}</h6>
              </OverlayTrigger>
              <p>{row.keyword}</p>
            </div>
            <OverlayTrigger placement="bottom" flip overlay={<Tooltip style={{ zIndex: 9999 }} id="tooltip">
              {row.is_pinned ? "Remove from Starred" : "Add to Starred"}
            </Tooltip>}>
              <img className={classNames({ 'hover-star': !starred })} onClick={(e) => { e.stopPropagation(); handlePinArticle(row.type, row.id, row?.is_pinned) }}
                src={row.is_pinned ? dashboardIcon.starFill : dashboardIcon.star}
                alt="star" loading="lazy" />
            </OverlayTrigger>
          </div>
        ),
        sortable: true,
        sortField: "topic",
      },
      {
        id: "created_at",
        name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
        selector: (row) => tableDateFormatter(row?.created_at),
        sortable: true,
        sortField: "created_at",
      },
      {
        id: "AI_type",
        name: <div className="d-flex"> AI Type {SortArrow(sortField, "AI_type")}</div>,
        selector: (row) => row.AI_type,
        sortable: true,
        sortField: "AI_type",
      },
      {
        id: "status",
        name: <div className="d-flex recentArticleHistoryStatus">Status {SortArrow(sortField, "status")}</div>,
        selector: (row) => tableArticleStatusHandle(row, handleTableRowClick),
        sortable: true,
        sortField: "status",
      },
      {
        id: "button",
        name: "Refund Request",
        selector: (row) => (
          <button className="refund-button" type="button" onClick={() => setShowRefundModal({ open: true, id: row?.id })}>
            Refund
          </button>
        ),
      },
      {
        id: "sort",
        name: 'Action',
        width: '60px',
        selector: (row) => {
          return (
            <div className="popup">
              <DropdownMenu>
                {row.status !== "pending" && row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleOpenArticle(row.id, row?.type)}><IoEyeOutline /> Preview article</MenuItem>
                )}
                {row.status !== "notAcceptable" && (
                  <MenuItem onClick={() => handleGetArticleById(row.id, row.type)}><ReactSVG src={dataTableIcons.pencil} /> Edit</MenuItem>
                )}
                {row.is_archived && row.is_archived === 1 ? (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "delete")}><TfiTrash /> Delete</MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDelete(row?.id, row?.type, "archive")}><RiInboxUnarchiveFill /> Archive</MenuItem>
                )}
              </DropdownMenu>
            </div>
          );
        },
      },
    ];

    if (userRes && userRes.role && userRes.role === "OrgAdmin") {
      tempColumns.splice(1, 0, {
        id: "created_by",
        name: <div className="d-flex">Created By {SortArrow(sortField, "created_by")}</div>,
        selector: (row) => (
          <div className="user-info-column">
            {row?.profileUrl && row?.profileUrl !== "" ? (
              <img src={row?.profileUrl} alt="user" className="userIcon" />
            ) : (
              <div className="avatar">{row?.username?.[0]}</div>
            )}
            <span>{row?.username || ""}</span>
          </div>
        ),
        sortable: true,
        sortField: "username",
      });
    }

    return tempColumns;
  }, [handleDelete, handleGetArticleById, handlePinArticle, sortField, textWrap]);

  const handleTableRowClick = (row) => {
    if (row.status === "completed") {
      handleOpenArticle(row.id, row?.type);
    } else if (row.status === "notAcceptable") {
    } else {
      handleGetArticleById(row.id, row?.type);
    }
  };

  return (
    <React.Fragment>
      <div className=" d-flex gap-2 flex-column tableHeading">
        <div className=" d-flex justify-content-between mb-4">
          <span className=" ">Recent History</span>
          <Link to="/history" className="">
            View All
          </Link>
        </div>
        <div className="filters">
          <Filters
            starred={starred} setStarred={setStarred} textWrap={textWrap} setSearchFilter={setSearch}
            setTextWrap={setTextWrap} setFilters={setFilters} filters={filters} selectedRows={selectedRows}
            getArticleAndPostHistoryData={getArticleAndPostHistoryData} setSelectedRows={setSelectedRows}
            handleArchiveAll={handleArchiveAll} setPage={setPage} isFetching={isFetching}
          />
        </div>
        <div className="historyTableBlock">
          <CustomDataTable
            progressPending={loading} progressComponent={<TableLoader limit={limit} />}
            columns={columns} totalCount={totalCount} pagination={true} data={historyData}
            itemsPerPage={limit} selectableRows={true} noDataComponent={<NoDataFound search={search} />}
            onSelectedRowsChange={setSelectedRows} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={handleTableRowClick} clearSelectedRows={clearRow}
          />
        </div>
      </div>
      <ArticlePreview
        userRes={userRes} articleId={article.id} articleType={article.type}
        show={showOffset} setShow={setShowOffset} getHistoryData={getHistoryShortFlowData}
        handleNavigateArticleOrPostWriter={(articleId, articleType) => handleGetArticleById(articleId, articleType)}
      />

      {showRefundModal && showRefundModal.open && (
        <Modal className="" size="xl" centered show={showRefundModal.open} onHide={() => setShowRefundModal({ open: false })}>
          <Modal.Body className="maingenrateBlock m-0 p-3">
            <div className="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
              <strong>Refund Credit Request</strong>
              <button className="btn close-btn p-0" onClick={() => setShowRefundModal({ open: false })}><GrClose /></button>
            </div>
            <RefundRequestsHistory articleType={"Blog Co-Pilot"} articleId={showRefundModal.id} />
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default OneBlogWriterHoc(SeoOptimizedBlogHistory);

const NoDataFound = ({ search }) => {
  return (
    <div className="withoutData  d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        {!search ? (
          <>
            <div className="icon d-flex justify-content-center flex-column align-items-center">
              <img src={articleHistoryIcons.add} alt="add"></img>
            </div>
            <p>Articles you create will be shown here</p>
          </>
        ) : (
          <span>Oops... Search not found.</span>
        )}
      </div>
    </div>
  );
};
