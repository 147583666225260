import React, { memo, useEffect, useRef, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { AxiosApi } from "../../../utility/axios";
import { oneClickBlogIcon } from "../../../Assets/Icons";
import { GoArrowLeft } from "react-icons/go";
import { Link } from "react-router-dom";
let articleId = 0;

const LoadingScreen = ({ show, type = "short", id, progress, isPdfArticle = false, label = "1-Click Blog Writer" }) => {
    const [progressNow, setProgressNow] = useState(0);
    const userRes = useSelector(({ userRes }) => userRes);
    const interval = useRef();
    const callApiInterval = useRef();

    useEffect(() => {
        if (progress && progressNow < progress) {
            setProgressNow(progress);
        }
    }, [progress])

    useEffect(() => {
        callApiInterval.current = setInterval(() => {
            if (!show && callApiInterval.current) {
                clearInterval(callApiInterval.current);
            }

            AxiosApi.get(`/api/generating-article-progress/${id || articleId}?user_id=${userRes.id}&type=${type}`).then((res) => {
                if (res && res.data && res.data.articleId) { articleId = res.data.articleId; }
                if (res && res.data && res.data.progress) {
                    if (res.data.progress >= 100 && callApiInterval.current) {
                        clearInterval(callApiInterval.current);
                    } else {
                        setProgressNow((count) => (count < res.data.progress && res.data.progress <= 95) ? res.data.progress : count);
                    }
                }
            });
        }, 5000);

        return () => {
            if (callApiInterval.current) {
                clearInterval(callApiInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        function handleTimer() {
            interval.current = setInterval(() => {
                setProgressNow((count) => (count >= 95) ? 95 : count + 1);
            }, 3000);
        }

        if (progressNow >= 100 && interval.current) {
            clearInterval(interval.current);
        }
        if (progressNow === 0) {
            handleTimer();
        }
    }, [progressNow]);

    return (
        <React.Fragment>
            {!isPdfArticle && (
                <div className="topBar d-flex gap-3 justify-content-between mb-4">
                    <div className="headingSide">
                        <strong>
                            <img src={oneClickBlogIcon.clickOneClickBlog} alt="click" /> {label}
                        </strong>
                    </div>
                    <div className="backtoHome d-flex gap-3 justify-content-between align-items-center">
                        <span className="line"></span>
                        <div className="backBtn">
                            <Link className="bg-transparent border-0" to={"/dashboard"}>
                                <GoArrowLeft /> Back to home
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <div className="first-step-detail text-center d-flex justify-content-center align-items-center bg-white w-100 flex-column">
                <div className='loading-tab-1 d-flex gap-3 flex-column'>
                    {progressNow <= 30 ? (
                        <React.Fragment>
                            <div className='loader-image'>
                                <img src={oneClickBlogIcon.loadingOne} alt="loading" loading="lazy" />
                            </div>
                            <div className='steps'>
                                <span><b>Step 01</b> <span>/ 03</span></span>
                            </div>
                            <p>Our smart writer is finding relevant semantic keywords for the best possible results.</p>
                        </React.Fragment>
                    ) : progressNow >= 30 && progressNow <= 60 ? (
                        <React.Fragment>
                            <div className='loader-image'>
                                <img src={oneClickBlogIcon.loadingTwo} alt="loading" loading="lazy" />
                            </div>
                            <div className='steps'>
                                <span><b>Step 02</b> <span>/ 03</span></span>
                            </div>
                            <p>Our smart writer is optimising the word count to improve the search engine ranking of this article.</p>
                        </React.Fragment>
                    ) : progressNow >= 60 ? (
                        <React.Fragment>
                            <div className='loader-image'>
                                <img src={oneClickBlogIcon.loadingThree} alt="loading" loading="lazy" />
                            </div>
                            <div className='steps'>
                                <span><b>Step 03</b> <span>/ 03</span></span>
                            </div>
                            <p>Our smart writer is generating an eye-catching headline that will optimise your content for search engines.</p>
                        </React.Fragment>
                    ) : null}
                    <div className='progress-bar'>
                        <ProgressBar now={progressNow} />
                    </div>
                    <b className=" text-black">{progressNow}%</b>
                </div>
            </div>
        </React.Fragment>
    );
};

export default memo(LoadingScreen);
