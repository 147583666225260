import Flag from "react-world-flags";
import { AxiosApi } from "./axios";
import { invokeLambdaFunction } from "./invokeLamdaFunction";
import { setLoader } from "../store/reducer";
import { handleExportWordToGoogleDoc } from "./common";

export const removeSpecificSpecialCharacters = (str) => {
  return str.replace(/[!@#$%^&*<>:;=+_|\\]/g, "");
};

export const OonaProductTreeList = [
  {
    id: 1,
    label: "Health & Illness",
    children: [
      { id: 5, label: "Big 3 Critical Illness", children: [] },
      { id: 6, label: "Cancer", children: [] },
      { id: 7, label: "Heart Attack", children: [] },
      { id: 8, label: "Stroke", children: [] }
    ]
  },
  {
    id: 2,
    label: "Travel",
    children: [
      { id: 9, label: "Domestic Travel", children: [] },
      { id: 10, label: "Flight Delay", children: [] },
      { id: 11, label: "International Travel", children: [] }
    ]
  },
  {
    id: 3,
    label: "Vehicle",
    children: [
      { id: 12, label: "Car", children: [] },
      { id: 13, label: "Motorcycle", children: [] }
    ]
  },
  {
    id: 4,
    label: "Others",
    children: [
      { id: 14, label: "Cargo", children: [] },
      {
        id: 15,
        label: "Group Insurance",
        children: [
          { id: 16, label: "Group Health", children: [] },
          { id: 17, label: "Group Health SME", children: [] }
        ]
      },
      { id: 18, label: "Personal Accident", children: [] }
    ]
  }
];

export const supportedLanguageList = [
  { label: "English", value: "English", code: "US" },
  { label: "Bahasa Indonesia", value: "Bahasa Indonesia", code: "ID" },
  { label: "Bahasa Melayu", value: "Bahasa Melayu", code: "MY" },
  { label: "Mandarin", value: "Mandarin", code: "CN" },
  { label: "Thai", value: "Thai", code: "TH" },
  { label: "Tagalog", value: "Tagalog", code: "PH" },
  { label: "Vietnamese", value: "Vietnamese", code: "VN" },
];

export const OonaLanguageList = [
  { label: "Bahasa Indonesia", value: "Bahasa Indonesia", code: "ID" },
  { label: "English", value: "English", code: "US" },
];

export const handleCustomSelectOption = (option) => (
  <div className="language-option">
    <Flag code={option.code} /> {option.label}
  </div>
);

export const EditorInitObj = {
  height: "100vh",
  menubar: false,
  placeholder: "Write something ...",
  plugins: [
    "advlist", "autolink", "lists", "link", "image", "charmap", "print", "anchor", "searchreplace", "visualblocks",
    "code", "fullscreen", "insertdatetime", "media", "table", "preview", "paste", "help", "wordcount",
  ],
  toolbar: "spellcheckdialog | blocks | fontfamily | fontsize | bold italic underline | image media link | forecolor backcolor | align | alignright | alignjustify" +
    "lineheight checklist bullist numlist indent outdent | removeformat typography | help",
  image_title: true, image_dimensions: true, image_resize: true,
  font_formats: "Inter=Inter,sans-serif; Arial=arial,helvetica,sans-serif; Times New Roman=times new roman,times,serif",
  automatic_uploads: true,
  file_picker_types: "image",
  file_picker_callback: function (cb, value, meta) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const id = "blobid" + (new Date()).getTime();
        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  },
  content_css: [
    "/Assets/TinyMceEditorStyle.css", "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
    "https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap",
    "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&display=swap",
    "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Quicksand:wght@300..700&display=swap",
    "https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Nanum+Myeongjo&family=Noto+Serif+JP&display=swap",
  ],
};

export const addEditorCustomButtons = (editor, setToggle, copyFunction, exportData) => {
  editor.ui.registry.addIcon(
    "my-custom-copy-icon",
    `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8323 0H5.11034C4.13392 0 3.3395 0.794414 3.3395 1.77083V1.98817H2.16829C1.19188 1.98817 0.397461 2.78258 0.397461 3.759V14.2292C0.397461 15.2057 1.19188 16.0001 2.16829 16.0001H8.89028C9.86669 16.0001 10.661 15.2057 10.661 14.2292V14.0119H11.8322C12.8087 14.0119 13.6031 13.2175 13.6031 12.2411V1.77083C13.6032 0.794414 12.8087 0 11.8323 0ZM9.51883 14.2292C9.51883 14.5758 9.23684 14.8578 8.89035 14.8578H2.16829C1.82173 14.8578 1.53974 14.5758 1.53974 14.2292V3.75892C1.53974 3.41236 1.82173 3.13037 2.16829 3.13037H8.89028C9.23684 3.13037 9.51876 3.41236 9.51876 3.75892V14.2292H9.51883ZM12.4609 12.2411C12.4609 12.5876 12.1789 12.8696 11.8323 12.8696H10.6611V3.75892C10.6611 2.78251 9.86669 1.98809 8.89035 1.98809H4.48178V1.77075C4.48178 1.42419 4.76377 1.1422 5.11034 1.1422H11.8323C12.1789 1.1422 12.4609 1.42419 12.4609 1.77075V12.2411Z" fill="#0039FF"></path></svg>`
  );

  editor.ui.registry.addIcon(
    "my-custom-pdf-icon",
    `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"></path><path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6"></path><path d="M17 18h2"></path><path d="M20 15h-3v6"></path><path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z"></path></svg>`
  );

  editor.ui.registry.addIcon(
    "my-custom-word-icon",
    `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"></path><path d="M2 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z"></path><path d="M17 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0"></path><path d="M9.5 15a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z"></path><path d="M19.5 15l3 6"></path><path d="M19.5 21l3 -6"></path></svg>`
  );

  editor.ui.registry.addIcon(
    "my-custom-google-icon",
    `<svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24 c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657 C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
    </svg>`
  );

  editor.ui.registry.addButton("viewHtml", {
    text: "View HTML",
    class: "MyCoolBtn",
    tooltip: "View HTML",
    classes: "ampforwp-copy-content-button",
    onAction: () => setToggle(true),
  });

  editor.ui.registry.addButton("copyContent", {
    icon: "my-custom-copy-icon",
    class: "MyCoolBtn",
    tooltip: "Copy Content",
    classes: "ampforwp-copy-content-button",
    onAction: () => copyFunction(editor.getContent()),
  });

  editor.ui.registry.addButton("exportToPdf", {
    icon: "my-custom-pdf-icon",
    class: "MyCoolBtn",
    tooltip: "Export to PDF",
    classes: "ampforwp-copy-content-button",
    onAction: () => handleExportToWordOrPdfArticle(exportData, "pdf", editor),
  });

  editor.ui.registry.addButton("exportToDocx", {
    icon: "my-custom-word-icon",
    class: "MyCoolBtn",
    tooltip: "Export to Docx",
    classes: "ampforwp-copy-content-button",
    onAction: () => handleExportToWordOrPdfArticle(exportData, "word", editor),
  });

  editor.ui.registry.addButton("exportToGoogle", {
    icon: "my-custom-google-icon",
    class: "MyCoolBtn",
    tooltip: "Export to Google Docx",
    classes: "ampforwp-copy-content-button",
    onAction: () => handleExportWordToGoogleDoc(exportData),
  });

  editor.on("NodeChange", function (e) {
    const node = e.element;
    const checkTitleArray = ["AI Generated Image", "AI Brand Images", "Pexels Free Images", "UnSplash Free Images", "Pixabay Free Images"];
    if (node && node.nodeName === "IMG" && !node.dataset.imageInserted && node.title && checkTitleArray.includes(node.title)) {
      const pElm = editor.dom.create("p", null, `<strong>Source :</strong> ${node.title}`);
      pElm.setAttribute("style", "font-size: 14px; font-weight: normal; margin: 1em 0;");
      node.dataset.imageInserted = true; node.title = "";
      editor.dom.insertAfter(pElm, node);

      const nextSibling = pElm.nextSibling;
      if (nextSibling && nextSibling.nodeName === "P" && nextSibling.textContent.includes("Source : ")) {
        nextSibling.parentNode.removeChild(nextSibling);
      } else {
        const parentNode = pElm.parentNode;
        const nextSibling = parentNode.nextSibling;
        if (nextSibling && nextSibling.nodeName === "P" && nextSibling.textContent.includes("Source : ")) {
          nextSibling.parentNode.removeChild(nextSibling);
        }
      }
    }

    if (node && node.nodeName === "IMG" && (node.width || node.height)) {
      node.style.width = `${node.getAttribute("width")}px`;
      node.style.height = `${node.getAttribute("height")}px`;

      if (node.getAttribute("data-mce-style")) {
        node.style = node.getAttribute("data-mce-style");
      }
    }
  });
};

export const handleExportToWordOrPdfArticle = async (data, type, editor) => {
  try {
    data.dispatch(setLoader(true));
    let articleTempText = `<body> ${data.type && data.type === "CMI" ? editor?.getHtml() : editor?.getContent()} </body>`;
    if (data.finalText && data.finalText !== "" && /<html[^>]*>/.test(data.finalText)) {
      const parser = new DOMParser();
      const bodyText = parser.parseFromString(articleTempText, 'text/html').body.outerHTML;
      articleTempText = `${data.finalText.substring(0, data.finalText.indexOf("<body"))} ${bodyText} </html>`
        .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
    }

    const defaultStyle = `html{ zoom: ${data.type && data.type === "CMI" ? '0.50' : '0.75'}; } body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.5; box-sizing: border-box; } h1 { display: block; font-size: 2em; margin-block-start: 0.67em; margin-block-end: 0.67em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    h2 { display: block; font-size: 1.5em; margin-block-start: 0.83em; margin-block-end: 0.83em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    h3 { display: block; font-size: 1.17em; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; font-weight: bold; }
    ul { display: block; list-style-type: disc; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; padding-inline-start: 40px; }
    p { display: block; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; }
    img { height: auto; max-width: 80%; object-fit: cover; }`.replace(/\s+/g, ' ').trim();
    if (articleTempText.indexOf("<style>") === -1) {
      articleTempText = articleTempText.replace("</head>", `<style> ${defaultStyle} </style> </head>`);
    } else {
      articleTempText = articleTempText.replace("<style>", `<style> ${defaultStyle}`);
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(articleTempText, 'text/html');
    const imgTags = doc.querySelectorAll('img');

    for (const img of imgTags) {
      const imageUrl = img.getAttribute('src');
      if (imageUrl.startsWith("data:")) { continue; }
      try {
        const response = await AxiosApi.get(`/api/post-writer/get-img-base64`, { params: { url: imageUrl } });
        if (response && response.data && response.data.data) {
          img.setAttribute("src", response.data.data.replace("data:application/", "data:image/"));
        }
      } catch (error) {
      }
    }

    const finalHtml = doc.documentElement.outerHTML;
    let apiUrl = "https://rx32utai90.execute-api.ap-southeast-1.amazonaws.com/packages/html-to-doc/download", filename = `Article-Docx-${Date.now()}.doc`;
    if (type === "pdf") {
      apiUrl = "https://rx32utai90.execute-api.ap-southeast-1.amazonaws.com/packages/html-to-pdf/download";
      filename = `Article-PDF-${Date.now()}.pdf`;
    }

    await AxiosApi.post(apiUrl, { htmlContent: finalHtml }).then((response) => {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: type === "pdf" ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      data.dispatch(setLoader(false));
    }).catch((err) => {
      console.log(err);
      data.dispatch(setLoader(false));
    })
  } catch (error) {
    console.log('#error --->', error);
    data.dispatch(setLoader(false));
  }
};

export const getDate = (date = 0) => {
  function format(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  if (typeof date === "string") return date;
  const currentDate = new Date();
  if (date) {
    return format(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - date));
  }
  return format(currentDate);
};

export const handleOnChangeTextareaHeight = async (event, setHeight = 100) => {
  const element = event.target;
  const height = element.scrollHeight < setHeight ? setHeight : element.scrollHeight;
  element.style.height = (height) + "px";
};

export const handleGetOrGenerateAllTypeImages = async ({
  setPostImagesModal, postGeneratedImages, setPostGeneratedImages,
  articleType, subType, userRes, params, queryParams
}) => {
  try {
    setPostGeneratedImages((prev) => ({ ...prev, subType }));
    setPostImagesModal((prev) => ({
      ...prev, open: true, type: articleType, subType,
      imageHistoryLoader: true, pexelsGenerating: true, aiImageGenerating: true
    }));

    setPostImagesModal((prev) => ({ ...prev, apiParams: params }));
    const payload = { body: JSON.stringify(params) };

    if (postGeneratedImages && postGeneratedImages.subType !== subType) {
      setPostGeneratedImages((prev) => ({ subType }));

      // Get uploaded images
      const res_1 = await AxiosApi.get(`/api/generate-images/get-uploaded-image/${userRes.id}?type=${articleType}`);
      setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false }));
      if (res_1 && res_1.data && res_1.data.images && res_1.data.images.length > 0) {
        setPostGeneratedImages((prev) => ({ ...prev, imageHistory: res_1.data.images, isAiImgGenerated: res_1.data.isAiImgGenerated }));
      }

      // Get uploaded product images Or generated background images
      const res_2 = await AxiosApi.get(`/api/product-image-library/get-product-and-bg-images?${queryParams}`);
      if (res_2 && res_2.data && res_2.data.productImg && res_2.data.backgroundImages) {
        setPostGeneratedImages((prev) => ({ ...prev, ...res_2.data }));
      }

      // Generate or get stability ai images
      const res_3 = await invokeLambdaFunction("getAndGenerateStabilityAiImages", payload);
      setPostImagesModal((prev) => ({ ...prev, aiImageGenerating: false }));
      if (res_3 && res_3.success === 1 && res_3.data && res_3.data.images && res_3.data.images.length > 0) {
        setPostGeneratedImages((prev) => ({
          ...prev, openAiImages: res_3.data.images, openAiImgRegenLeft: res_3.data.regenerateLeft,
          openAiImagesVersions: res_3.data?.oldImages?.length ? res_3.data?.oldImages : [],
          newAiImages: res_3.data?.newImages ? res_3.data.newImages : [],
        }));
      }

      // Generate or get pexels and pixabay images
      const res_4 = await invokeLambdaFunction("getAndGeneratePexelsAndPixabayImages", payload);
      setPostImagesModal((prev) => ({ ...prev, pexelsGenerating: false }));
      if (res_4 && res_4.success === 1 && res_4.data && res_4.data.images && res_4.data.images.length > 0) {
        setPostGeneratedImages((prev) => ({
          ...prev, pexelImages: res_4.data.images,
          pixabayImages: res_4.data.pixabayImages,
          unsplashImages: res_4.data.unsplashImages,
        }));
      }
    }
    setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
  } catch (error) {
    setPostImagesModal((prev) => ({ ...prev, imageHistoryLoader: false, pexelsGenerating: false, aiImageGenerating: false }));
  }
};

export const trimedInputValue = (value) => {
  let trimmedValue = {};
  for (let key in value) {
    if (typeof value[key] === 'string') {
      trimmedValue[key] = value[key].trim();
    } else {
      trimmedValue[key] = value[key];
    }
  }
  return trimmedValue;
};
