import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Swal from "sweetalert";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowLeft, BsSend } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import { TiFlash } from "react-icons/ti";
import AddllyLogo from "../Assets/Images/AddllyLogo.png";
import { planDashBoardIcon } from "../Assets/Icons";
import { AxiosApi } from "../utility/axios";
import { setCurrentPlan, setLoader, setUserPlan, setUserRes } from "../store/reducer";

const fewOptionsArray = [
  {
    label: "Credits",
    children: [
      { label: "Addlly Credits", value: "credits", isDisplayNo: true },
      { label: "Additional Addlly Credit Cost", value: "per_credit_rate", isDisplayPrice: true },
    ]
  },
  {
    label: "Products",
    children: [
      { label: "1-Click Blog Writer", value: "one_click_blog" },
      { label: "SEO Blog Co-Pilot", value: "blog_co_pilot" },
      { label: "Social Media Post Writer", value: "linkedin_post" },
      { label: "Newsletter", value: "blog_co_pilot" },
      { label: "Press Release", value: "blog_co_pilot" },
      { label: "Review System", value: "review_system" },
    ]
  },
  {
    label: "Features",
    children: [
      { label: "Customized Tone and Style", value: "brand_voice" },
      { label: "Editorial Support", value: "editorial_support" },
    ]
  },
];

const allOptionsArray = [
  {
    label: "Credits",
    children: [
      { label: "Addlly Credits", value: "credits", isDisplayNo: true },
      { label: "Additional Addlly Credit Cost", value: "per_credit_rate", isDisplayPrice: true },
    ]
  },
  {
    label: "Products",
    children: [
      { label: "1-Click Blog Writer", value: "one_click_blog" },
      { label: "SEO Blog Co-Pilot", value: "blog_co_pilot" },
      { label: "Social Media Post Writer", value: "linkedin_post" },
      { label: "Newsletter", value: "blog_co_pilot" },
      { label: "Press Release", value: "blog_co_pilot" },
    ]
  },
  {
    label: "Blog Features",
    children: [
      { label: "HTML view", value: "html_view" },
      { label: "Fact Checker", value: "fact_checker" },
      { label: "FAQs & Schema Markup", value: "faq" },
      { label: "Social Media Writers", value: "linkedin_post" },
      { label: "Google Ads", value: "google_ad" },
      { label: "Review System", value: "review_system" },
    ]
  },
  {
    label: "Social Media Writers",
    children: [
      { label: "LinkedIn Post", value: "linkedin_post" },
      { label: "LinkedIn Carousel", value: "linkedin_carousel" },
      { label: "Facebook Post", value: "facebook_post" },
      { label: "X (Twitter) Post", value: "twitter_post" },
      { label: "Instagram Post", value: "instagram_post" },
    ]
  },
  {
    label: "Language Support",
    children: [
      { label: "English", value: "english" },
      { label: "Bahasa Indonesia", value: "bahasa" },
    ]
  },
  {
    label: "Features",
    children: [
      { label: "Access to Media Library", value: "media_library" },
      { label: "Customized Tone and Style", value: "brand_voice" },
      { label: "Editorial Support", value: "editorial_support" },
    ]
  },
];

const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .trim("white-space", "First name should not contain white spaces")
    .max(20, "First Name cannot be more than 20 Characters Long")
    .matches(/^[a-zA-Z0-9 ]+$/, "First name can't contain special character"),
  last_name: Yup.string().trim().required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(20, "Last Name cannot be more than 20 Characters Long")
    .trim("white-space", "Last name should not contain white spaces")
    .matches(/^[a-zA-Z0-9 ]+$/, "Last name can't contain special character"),
  email: Yup.string().email("Must be a valid email").required("Email is required").trim("white-space", "Email should not contain white spaces"),
  phone_number: Yup.string().trim()
    .matches(/^[0-9]+$/, 'Phone number should contain only number')
    .min(8, "Phone number must be at least 8 digits")
    .trim("white-space", "Phone number should not contain white spaces")
    .max(13, "Phone number must not exceed 13 digits")
    .required("Phone number is required"),
  message: Yup.string().trim().required("Message is required"),
});

const PlanDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRes = useSelector(({ userRes }) => userRes);
  const userPlan = useSelector(({ userPlan }) => userPlan);

  const [planData, setPlanData] = useState([]);
  const [isPlanLoader, setIsPlanLoader] = useState(false);
  const [planOptionList, setPlanOptionList] = useState(fewOptionsArray);
  const [isShowFullList, setIsShowFullList] = useState(false);
  const [contactSalesModal, setContactSalesModal] = useState(false);

  const [activePlanName, setActivePlanName] = useState(null);
  const [upcomingPlanName, setUpcomingPlanName] = useState(null);
  const [activePlanAmount, setActivePlanAmount] = useState(null);

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  useEffect(() => {
    getAllPlans();
    getAllActivePlans();
  }, []);

  const getAllPlans = async () => {
    try {
      setIsPlanLoader(true);
      const res = await AxiosApi.get(`/user/stripe/plans`);
      if (res.status === 200) {
        setPlanData(res?.data);
        setTimeout(() => {
          setIsPlanLoader(false);
        }, 1000);
      }
    } catch (e) {
      setIsPlanLoader(false);
    }
  };

  const getAllActivePlans = async () => {
    try {
      const res = await AxiosApi.get(`/user/stripe/get-plans/list/${userRes.id}`);

      if (res.status === 200 && res.data.data.length > 0) {
        const data = res.data && res.data.data ? res.data.data : [];
        data.map((plan) => {
          if (plan.status === "active") {
            setActivePlanName(plan.planName);
            const findObj = res.data.planData.find((ele) => ele.name === plan.planName);
            if (findObj && findObj.amount) { setActivePlanAmount(Number(findObj.amount)); }
          }
          if (plan.status === "upcoming") { setUpcomingPlanName(plan.planName); }
          return plan;
        });
      } else {
        let name = null;
        if (userPlan && userPlan.length > 0 && userPlan[0].price && userPlan[0].price.length > 0 && userPlan[0].price[0].nickname) {
          name = userPlan[0].price[0].nickname;
          setActivePlanAmount((Number(userPlan[0].price[0].unit_amount) / 100));
        }
        setActivePlanName(name);
      }
    } catch (err) {
      let name = null;
      if (userPlan && userPlan.length > 0 && userPlan[0].price && userPlan[0].price.length > 0 && userPlan[0].price[0].nickname) {
        name = userPlan[0].price[0].nickname;
        setActivePlanAmount((Number(userPlan[0].price[0].unit_amount) / 100));
      }
      setActivePlanName(name);
    }
  };

  const handleCheckout = async (selectedTempPlan, selectedTempCredit) => {
    dispatch(setLoader(true));
    dispatch(setUserPlan(''));
    dispatch(setCurrentPlan(''));

    try {
      const metaData = {
        credits: selectedTempCredit.credits,
        name: selectedTempCredit.name,
        type: "BuyNewPlan",
      };

      const data = {
        items: [selectedTempPlan],
        successUrl: `${window.location.origin}/dashboard`,
        cancelUrl: `${window.location.origin}/plans`,
        strip_customer_id: userRes?.stripe_customer_id,
        metadata: metaData,
        payment_method: "card",
      };

      const res = await AxiosApi.post(`/user/stripe/checkout`, data);
      dispatch(setLoader(false));
      if (res.status === 200) {
        dispatch(setUserRes({ ...userRes, username: null }));
        window.location.assign(res.data.data.url);
      } else {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong", { id: "Toast-01" });
    }
  };

  const handleUpgradeOrDowngradePlan = async (selectedTempPlan, selectedTempCredit) => {
    if (!selectedTempPlan?.price) {
      return false;
    }

    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${activePlanAmount < selectedTempCredit.amount ? "Upgrade" : "Downgrade"} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const metaData = {
          credits: selectedTempCredit.credits, name: selectedTempCredit.name,
          type: activePlanAmount < selectedTempCredit.amount ? "UpgradePlan" : "DowngradePlan",
        };

        const data = {
          newPriceId: selectedTempPlan.price,
          strip_customer_id: userRes?.stripe_customer_id,
          metadata: metaData,
        };

        try {
          dispatch(setLoader(true));
          const res = await AxiosApi.post(`/user/stripe/checkout/credits`, data);
          dispatch(setLoader(false));

          if (res.status === 200) {
            dispatch(setUserRes({ ...userRes, username: null }));
            dispatch(setUserPlan(''));
            dispatch(setCurrentPlan(''));
            toast.success(`Plan has been ${activePlanAmount < selectedTempCredit.amount ? "upgraded" : "downgraded"} successfully.`, { id: "Toast-01" });

            setTimeout(() => {
              window.location.assign(`${window.location.origin}/account/my-profile`, { id: "Toast-01" });
            }, 2000);
          } else {
            toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
          }
        } catch (error) {
          dispatch(setLoader(false));
          toast.error("Something went wrong", { id: "Toast-01" });
        }
      }
    });
  };

  const handleStartWithFreePlan = async () => {
    try {
      dispatch(setLoader(true));
      const res = await AxiosApi.get(`/api/user/get-credits-available/${userRes.id}?type=startFreePlan`);
      dispatch(setLoader(false));

      if (res.status === 200 && res.data) {
        dispatch(setUserRes({ ...userRes, username: null }));
        dispatch(setUserPlan(''));
        dispatch(setCurrentPlan(''));

        setTimeout(() => {
          window.location.assign(`${window.location.origin}/dashboard`);
        }, 500);
      } else {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
    }
  };

  const handleSelectedPlan = (planData) => {
    if (planData && planData.amount > 0) {
      const selectedTempPlan = { price: planData.planId, quantity: 1 };
      const selectedTempCredit = { credits: planData.credits, name: planData.name, amount: planData.amount };

      if (activePlanName && activePlanName !== "" && activePlanAmount) {
        handleUpgradeOrDowngradePlan(selectedTempPlan, selectedTempCredit);
      } else {
        handleCheckout(selectedTempPlan, selectedTempCredit);
      }
    } else {
      toast.error("You are unable to select this plan. Please contact our customer support for further assistance.", { id: "Toast-01" });
    }
  };

  const onSubmit = async (data) => {
    dispatch(setLoader(true));
    try {
      const res = await AxiosApi.post(`/api/user/contact-us/create/${userRes.id}`, data);
      dispatch(setLoader(false));
      handleClose();

      if (res.status === 200 && res.data) {
        Swal("Thanks!", "Our sales team will be in contact with you shortly.", "success");
      } else {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
    }
  };

  const handleClose = () => {
    setContactSalesModal(false);
    reset({});
  };

  const handleShowOrHideOptionList = () => {
    if (isShowFullList) {
      setPlanOptionList(fewOptionsArray);
    } else {
      setPlanOptionList(allOptionsArray);
    }
    setIsShowFullList(!isShowFullList);
  };

  const handleNavigateOnDashboardPage = () => {
    if (userRes && userRes.used_free_plan === 0) {
      handleStartWithFreePlan();
    } else {
      navigate("/dashboard");
    }
  }

  const handleRenderPlanCardButton = (plan) => {
    return (
      <React.Fragment>
        {activePlanName && activePlanName === plan.name ? (
          <button className="btn buy-plan-btn" disabled>Current Plan</button>
        ) : upcomingPlanName && upcomingPlanName === plan.name ? (
          <button className="btn buy-plan-btn" disabled>Upcoming Plan</button>
        ) : plan.amount > 0 ? (
          <button className="btn buy-plan-btn" onClick={() => handleSelectedPlan(plan)}>
            <TiFlash /> {(activePlanAmount && activePlanAmount > plan.amount) ? "Downgrade Plan" :
              (activePlanAmount && activePlanAmount < plan.amount) ? "Upgrade Plan" : "Upgrade"}
          </button>
        ) : plan.name.indexOf("Enterprise") !== -1 ? (
          <button className="btn buy-plan-btn dark-button" onClick={() => { setContactSalesModal(true); setValue("email", userRes.email); }}>
            Contact Sales
          </button>
        ) : (
          <React.Fragment>
            {plan.name.indexOf("Starter") !== -1 ? (
              <React.Fragment>
                {userRes.used_free_plan === 0 ? (
                  <button className="btn buy-plan-btn" onClick={() => handleStartWithFreePlan()}>
                    Start With Free Plan
                  </button>
                ) : (
                  <button className="btn buy-plan-btn" disabled>Used Free Plan</button>
                )}
              </React.Fragment>
            ) : (
              <button className="btn buy-plan-btn" disabled>-</button>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const handleRenderPlanOptionSvgIcon = (element, plan) => {
    let iconSrc = planDashBoardIcon.PlanCheckedIcon;
    if (element.isDisplayNo) {
      iconSrc = planDashBoardIcon.RoundBlueDotIcon;
    } else if (element.isDisplayPrice) {
      iconSrc = plan[element.value] ? planDashBoardIcon.RoundBlueDotIcon : planDashBoardIcon.PlanUnCheckedIcon;
    } else if (plan[element.value] === 0) {
      iconSrc = plan[element.value] ? planDashBoardIcon.RoundBlueDotIcon : planDashBoardIcon.PlanUnCheckedIcon;
    }
    return iconSrc;
  };

  return (
    <React.Fragment>
      <div className="plan-dashboard-wrapper">
        <div className="top-left-circle"></div>
        <div className="top-right-circle"></div>
        <div className="home-btn-content">
          <button type="button" className="btn home-btn" onClick={handleNavigateOnDashboardPage}>
            <BsArrowLeft className="" /> Back to home
          </button>
        </div>
        <div className="plan-header-wrapper">
          <img
            src={AddllyLogo} alt="addlly logo" className="logo-content cursor-pointer"
            onClick={handleNavigateOnDashboardPage} onKeyDown={handleNavigateOnDashboardPage}
          />
          <div className="header-title mt-5">
            <h6>Upgrade Account</h6>
            <h4>Plans & Pricing</h4>
          </div>
          {/* <div className="switch-content">
            <label className="switch-label active">Monthly</label>
            <label className="custom-switch">
              <input type="checkbox" disabled />
              <span className="slider"></span>
            </label>
            <label className="switch-label">Annually</label>
            <div className="annually-content">
              <ReactSVG src={planDashBoardIcon.AnnuallyArrow} useRequestCache className="arrow-svg" />
              <h6 className="annually-title">Save 20% with annual plans</h6>
            </div>
          </div> */}
        </div>
        <div className="plan-body-wrapper justify-content-center flex-wrap mt-4" style={{ zIndex: '100' }}>
          {isPlanLoader && (
            <React.Fragment>
              {[1, 2, 3, 4].map((index) => <div className="plan-card-loader" key={index}></div>)}
            </React.Fragment>
          )}
          {!isPlanLoader && planData && planData.length > 0 && planData.map((plan, ind) => (
            <div className={`plan-main-wrapper ${plan.name && plan.name === "Pro Pack" ? "active" : ""}`}>
              <div className={`plan-card`} key={ind}>
                {plan.name && plan.name === "Pro Pack" && (
                  <div className="popular-tag">Popular</div>
                )}
                <div className="card-header">
                  <h4 className="header-title">{plan.name}</h4>
                  <h6 className="header-subtitle">Experience peace of mind with a free trial of Addlly.</h6>
                </div>
                {plan.amount > 0 ? (
                  <div className="price-content">
                    <h4 className="header-title">${plan.discount_amount ? (plan.amount - plan.discount_amount) : plan.amount}</h4>
                    <h6 className="header-subtitle">per month</h6>
                  </div>
                ) : (
                  <div className="price-content">
                    <h4 className="header-title">{plan.name.indexOf("Enterprise") !== -1 ? "Custom" : "FREE"}</h4>
                    <h6 className="header-subtitle">{plan.name.indexOf("Enterprise") !== -1 ? "as per requirements" : "forever"}</h6>
                  </div>
                )}
                {handleRenderPlanCardButton(plan)}
                {planOptionList && planOptionList.length > 0 && planOptionList.map((element1, index1) => (
                  <div className="plan-content-info" key={index1}>
                    <label className="label-content">{element1.label}</label>
                    <div className="content-body">
                      {element1.children && element1.children.length > 0 && element1.children.map((element2, index2) => (
                        <div className="content-sub-body" key={index2}>
                          <ReactSVG useRequestCache className="svg-icon" src={handleRenderPlanOptionSvgIcon(element2, plan)} />
                          <div className="content-sub-title">
                            {element2.isDisplayNo ? (
                              // <b className="me-2">{plan[element2.value] ? plan[element2.value] : "00"}</b>
                              <b className="me-2">{plan[element2.value] && plan[element2.value] > '0' && plan[element2.value] < '10' ? ("0" + plan[element2.value]) : plan[element2.value] ? (plan[element2.value]) : "00"}</b>
                            ) : element2.isDisplayPrice && plan[element2.value] ? (
                              <b className="me-2">${plan[element2.value] ? plan[element2.value] : ""}</b>
                            ) : null}
                            {element2.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {isShowFullList ? handleRenderPlanCardButton(plan) : null}
                <div className="info-view-btn" onClick={handleShowOrHideOptionList}>
                  {isShowFullList ? "View less" : "View all"}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="plan-note-info">
          <div className="note-main-title">
            <ReactSVG src={planDashBoardIcon.PlanNoteIcon} useRequestCache className="svg-icon" /> Note
          </div>
          <div className="note-sub-title">
            Each 1-Click Blog writer costs {userRes?.deductCredits?.["1-Click Blog"] || 2} credits,
            SEO Blog Co-Pilot costs {userRes?.deductCredits?.["Blog Co-Pilot"] || 3} credits,
            Social media post cost {userRes?.deductCredits?.["Social Media Post"] || 1} credits,
            And each Newsletters and Press Release costs {userRes?.deductCredits?.["Press Release"] || 3} credits.
          </div>
        </div>
        <div className="plan-footer-btn">
          <button type="button" className="btn footer-btn" onClick={handleNavigateOnDashboardPage}>
            <IoHomeOutline /> Go back to Home
          </button>
        </div>
      </div>

      {contactSalesModal && (
        <Modal centered show={contactSalesModal} onHide={handleClose} className="contact-sales-modal">
          <Modal.Body>
            <div className="modal-header-content">
              <div className="header-title">Contact Sales</div>
              <button className="btn close-btn" onClick={() => handleClose()}>
                <AiOutlineClose />
              </button>
            </div>
            <div className="addllyFormWrap">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm="6">
                    <div className="form-group mb-4">
                      <label>First Name <span className="text-danger fs-6 ms-1">*</span></label>
                      <input
                        name="first_name" type="text" {...register("first_name")} placeholder="Enter first name"
                        className={`addllyForm-control ${errors.first_name ? "is-invalid" : ""}`}
                      />
                      <div className="invalid-feedback">{errors.first_name?.message}</div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="form-group mb-4">
                      <label>Last Name <span className="text-danger fs-6 ms-1">*</span></label>
                      <input
                        name="last_name" type="text" {...register("last_name")} placeholder="Enter last name"
                        className={`addllyForm-control ${errors.last_name ? "is-invalid" : ""}`}
                      />
                      <div className="invalid-feedback">{errors.last_name?.message}</div>
                    </div>
                  </Col>
                  <div className="form-group mb-4">
                    <label>Email <span className="text-danger fs-6 ms-1">*</span></label>
                    <input
                      name="email" type="text" {...register("email")} value={userRes?.email}
                      placeholder="Enter email" disabled={userRes?.email ? true : false}
                      className={`addllyForm-control ${errors.email ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                  <div className="form-group mb-4">
                    <label>Phone Number <span className="text-danger fs-6 ms-1">*</span></label>
                    <input
                      name="phone_number" type="text" {...register("phone_number")} placeholder="Enter contact number"
                      className={`addllyForm-control ${errors.phone_number ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                  </div>
                  <div className="form-group mb-4">
                    <label>Message <span className="text-danger fs-6 ms-1">*</span></label>
                    <textarea
                      name="message" type="text" {...register("message")} rows="4" placeholder="Enter message"
                      className={`addllyForm-control h-auto ${errors.message ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.message?.message}</div>
                  </div>
                </Row>
                <button className="addlly-primary" type="submit" variant="primary" disabled={errors && Object.keys(errors)?.length > 0}>
                  Send Message <BsSend />
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default PlanDashboard;
