import React from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { BiChip } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { BsCardHeading, BsCheck2All, BsPatchQuestion, BsPersonCheck } from "react-icons/bs";
import "../../../Assets/scss/BlogSideBarStyle.scss";

export default function Sidebar(props) {
  const { setSelectedCurrentTab } = props;
  const userRes = useSelector(({ userRes }) => userRes);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "article";

  const menus = [
    { name: "Article", value: "article", subTab: "seoScore", icon: <BsCardHeading /> },
    { name: "FAQs", value: "faqSchema", subTab: "", icon: <BsPatchQuestion /> },
    { name: "AI Overview", value: "aiOverview", subTab: "", icon: <BiChip /> },
    { name: "Fact Checker", value: "factChecker", subTab: "", icon: <BsCheck2All /> },
    { name: "Review Article", value: "reviewArticle", subTab: "comments", icon: <BsPersonCheck /> },
  ];

  const handleTabs = (tab) => {
    setSearchParams("tab=" + tab.value + (tab.subTab && "&subTab=" + tab.subTab));
    setSelectedCurrentTab(tab.value)
  };

  return (
    <div className="blogSideBar">
      <ul className="">
        {menus.map((item, index) => (
          item.name === "Review Article" && userRes.role === "OrgAdmin" ? null : (
            <div key={index}>
              <li
                onClick={() => handleTabs(item)}
                className={classNames("d-flex align-items-center gap-3", { active: item.value === selectedTab })}
              >
                {typeof item.icon === 'string' ? <ReactSVG src={item.icon} /> : item.icon}
                <span className="d-block w-100">
                  {item.name}
                </span>
              </li>
            </div>
          )
        ))}
      </ul>
    </div>
  );
}
