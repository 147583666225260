import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsCloudDownloadFill } from "react-icons/bs";
import { AxiosApi } from "../../utility/axios";
import { setLoader } from "../../store/reducer";
import CustomDataTable from "../ui/DataTable";
import { articleHistoryIcons } from "../../Assets/Icons";
import TableLoader from "../ui/TableLoader";
import { SortArrow, sorting, tableDateFormatter } from "../../utility/common";

const InvoiceListTab = () => {
  const dispatch = useDispatch();
  const userRes = useSelector(({ userRes }) => userRes);

  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState({ column: {}, direction: "" });

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    try {
      setTableLoader(true);
      const { stripe_customer_id: customer_id } = userRes;
      const response = await AxiosApi.get(`/user/stripe/invoice/${customer_id}`);
      setTableLoader(false);

      if (response.status === 200) {
        setInvoices(response?.data?.data);
      };
    } catch (error) {
      setTableLoader(false);
    }
  };

  const downloadInvoice = (content) => {
    const element = document.createElement("a");
    element.href = content;
    document.body.appendChild(element);
    element.click();
  };

  const handleSort = (column, sortDirection) => {
    setPage(1);
    setSortField(pre => ({ ...pre, column: column, direction: sortDirection }));
    const newData = sorting(invoices, column?.sortField, sortDirection);
    setInvoices(newData);
  };

  const columns = useMemo(() => ([
    {
      id: "title",
      name: <div className="d-flex px-3">Invoice No {SortArrow(sortField, "title")}</div>,
      selector: (row) => <div className={"tableValues w-100"} data-tooltip-id="my-tooltip" data-tooltip-content={row?.number} data-tooltip-place="bottom">{row?.number || "-"}</div>,
      sortField: "number",
      sortable: true,
    },
    {
      id: "plan",
      name: <div className="d-flex px-2">Plan Name {SortArrow(sortField, "plan")}</div>,
      selector: (row) => <div className={"tableVlaues"} style={{ whiteSpace: "nowrap" }}>{row?.plan_name || "-"}</div>,
      sortField: "plan_name",
      sortable: true,
    },
    {
      id: "type",
      name: <div className="d-flex px-1">Type {SortArrow(sortField, "type")}</div>,
      selector: (row) => <div className={"tableVlaues"} style={{ whiteSpace: "nowrap", width: "100%" }}
        data-tooltip-id="my-tooltip" data-tooltip-content={row.type ? row.type === "subscription_create" ? "Subscription create" : row.type === "subscription_update" ? "Subscription update" : "-" : "-"} data-tooltip-place="bottom">
        {row.type ? row.type === "subscription_create" ? "Subscription create" : row.type === "subscription_update" ? "Subscription update" : "-" : "-"}
      </div>,
      sortField: "type",
      sortable: true,
    },
    {
      id: "amount",
      name: <div className="d-flex">Total Amount {SortArrow(sortField, "amount")}</div>,
      selector: (row) => <div className="d-flex align-items-center tableValues">
        <span>$</span>
        <span className="ms-2">{row?.amount}</span>
      </div>,
      sortField: "amount",
      sortable: true,
    },
    {
      id: "created_at",
      name: <div className="d-flex">Created On {SortArrow(sortField, "created_at")}</div>,
      selector: (row) => <div className={"tableVlaues"}>{tableDateFormatter(row?.created_at)}</div>,
      sortable: true,
      sortField: "created_at",
    },
    {
      id: "sort",
      selector: (row) => {
        return (
          <div className="action-btn d-flex justify-content-center gap-2 tableValues">
            <button className="addlly-primary download-btn" onClick={() => downloadInvoice(row?.invc_url)}
              data-tooltip-id="my-tooltip" data-tooltip-content="Download Invoice" data-tooltip-place="bottom"
            >
              <BsCloudDownloadFill />
            </button>
          </div>
        );
      },
    },
  ]), [sortField]);

  return (
    <div className="accountCard">
      <div className="cardHead">
        <h3>Invoice History</h3>
      </div>
      <div className="cardBody">
        <div className="historyTableBlock mt-2">
          <CustomDataTable
            progressPending={tableLoader} progressComponent={<TableLoader limit={10} />}
            columns={columns} totalCount={invoices?.length} pagination={true} data={invoices}
            itemsPerPage={10} selectableRows={false} noDataComponent={<NoDataFound />}
            onSelectedRowsChange={() => { }} setPage={setPage} page={page} onSort={handleSort}
            handleTableRowClick={() => { }} clearSelectedRows={() => { }}
          />
        </div>
      </div>
    </div>
  )
}

export default InvoiceListTab;

const NoDataFound = () => {
  return (
    <div className="withoutData d-flex justify-content-center align-items-center">
      <div className="genrateNewBlock d-flex justify-content-center flex-column align-items-center">
        <div className="icon d-flex justify-content-center flex-column align-items-center">
          <img src={articleHistoryIcons.add} alt="add"></img>
        </div>
        <p>Invoice history is not available yet!</p>
      </div>
    </div>
  );
};
